import { supportedFileTypes } from '@apps/purchases/settings/rangeOfNumbers/consts';

const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0');

export const getDocumentNumber = (rangeOfNumbers: any, type: string) => {
  if (rangeOfNumbers && type && rangeOfNumbers.fileTypes[type]) {
    let {
      abbreviation,
      minLength = 0,
      freeText,
      nextNumber = 0,
    } = rangeOfNumbers.fileTypes[type];
    const formattedNumber = zeroPad(nextNumber, minLength);
    return `${
      // @ts-ignore
      abbreviation ? abbreviation : supportedFileTypes[type]
    }${freeText ? '-' + freeText + '-' : '-'}${formattedNumber}`;
  } else {
    // @ts-ignore
    return `${supportedFileTypes[type]}-00`;
  }
};

export const svgString2Image = (
  svgString: string | number | boolean,
  width: number,
  height: number,
  format: string,
  callback: (arg0: string) => void,
) => {
  // set default for format parameter
  format = format ? format : 'png';
  // SVG data URL from SVG string
  const svgData =
    'data:image/svg+xml;base64,' +
    btoa(unescape(encodeURIComponent(svgString)));
  // create canvas in memory(not in DOM)
  const canvas = document.createElement('canvas');
  // get canvas context for drawing on canvas
  const context = canvas.getContext('2d');
  // set canvas size
  canvas.width = width;
  canvas.height = height;
  // create image in memory(not in DOM)
  const image = new Image();
  // later when image loads run this
  image.onload = function () {
    // async (happens later)
    // clear canvas
    // @ts-ignore
    context.clearRect(0, 0, width, height);
    // draw image with SVG data to canvas
    // @ts-ignore
    context.drawImage(image, 0, 0, width, height);
    // snapshot canvas as png
    const pngData = canvas.toDataURL('image/' + format);
    // pass png data URL to callback
    callback(pngData);
  }; // end async
  // start loading SVG data into in memory image
  image.src = svgData;
};
