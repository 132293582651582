import React, { FC, useCallback } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { DualAxes } from '@ant-design/charts';
import EmptyResult from '@components/EmptyResult';
import { HistoryTotalEntry } from '@apps/warehouse/services/namespace';

type ProductPlotProps = {
  entries: HistoryTotalEntry[];
};

let minInStock = 0;
let minValue = 0;

const ProductPlot: FC<ProductPlotProps> = ({ entries }) => {
  const intl = useIntl();
  const parseHistoryEntries = useCallback((entries: HistoryTotalEntry[]) => {
    minInStock = Math.min(...entries.map((en) => en.inStock || 0));
    minValue = Math.min(...entries.map((en) => en.value || 0));
    return entries.map((entry) => ({
      ...entry,
      timestamp: moment
        .unix(entry.timestamp as number)
        .format(moment.defaultFormat),
    }));
  }, []);
  const parsedData = parseHistoryEntries(entries);
  const config = {
    data: [parsedData, parsedData],
    height: 300,
    xField: 'timestamp',
    yField: ['inStock', 'value'],
    yAxis: {
      inStock: {
        min: minInStock > 0 ? 0 : minInStock,
      },
      value: {
        min: minValue > 0 ? 0 : minValue,
      },
    },
    meta: {
      inStock: {
        alias: intl.formatMessage({
          id: 'warehouse.create.table.header.amount',
          defaultMessage: 'Amount',
        }),
      },
      value: {
        alias: intl.formatMessage({
          id: 'warehouse.history.table.value.header',
          defaultMessage: 'Value',
        }),
      },
    },
    geometryOptions: [
      {
        geometry: 'line',
        color: '#5B8FF9',
      },
      {
        geometry: 'line',
        color: '#5AD8A6',
      },
    ],
  };

  return (
    <div style={{ minHeight: '300px', height: '300px' }}>
      {parsedData.length > 0 ? <DualAxes {...config} /> : <EmptyResult />}
    </div>
  );
};

export default ProductPlot;
