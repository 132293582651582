import React, { FC, useState, useCallback } from 'react';
import {
  getProductContactConstraints,
  getProductPurchasePrices,
  ProductContactConstraint,
  ProductPurchasePrice,
} from '@apps/products/services';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import { Spin, Form, Button } from 'antd';
import { Card } from '@todix/ui-components';
import { AppProductContactEvent } from '@apps/products/mainMenu/components/DetailedProduct/getExtraOptions';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import { useForm } from 'antd/lib/form/Form';
import SimpleProductNumber from '@apps/products/mainMenu/components/DetailedProduct/SimpleProductNumber';
import SimpleProductName from '@apps/products/mainMenu/components/DetailedProduct/SimpleProductName';
import intl from '../../../../../../intl';
import SelectedUnit from '@apps/products/mainMenu/components/SelectedUnit';
import StandardDeliveryTime from '@apps/products/mainMenu/components/DetailedProduct/StandardDeliveryTime';
import UnitAmount from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/priceForm/price/UnitAmount';
// import Currency from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/priceForm/price/Currency';
import ContactSearch from '@apps/sales/mainMenu/components/SaleCreator/components/ContactSearch';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import Tabs from '@components/Tabs';
import { createProductContactConstraint } from '@apps/products/services';
import IsDefault from '@apps/products/mainMenu/components/DetailedProduct/IsDefault';

const { TabPane } = Tabs;

export type RefreshConstraintEvent = {
  type: ProductContactConstraint['type'];
};

const AddProductContactConstraint: FC = () => {
  const [form] = useForm();
  const [show, setShow] = useState(false);
  const [type, setType] = useState<ProductContactConstraint['type'] | null>(
    null,
  );
  const [constraints, setConstraints] = useState<ProductContactConstraint[]>(
    [],
  );
  const [prices, setPrices] = useState<ProductPurchasePrice[]>([]);
  const [ecosystemId, setEcosystemId] = useState<string>('');
  const [productId, setProductId] = useState<string>('');

  useCustomEventListener<'addSupplier', AppProductContactEvent>(
    'addSupplier',
    async (data) => {
      if (data) {
        if (data.productId && data.ecosystemId) {
          try {
            const { ecosystemId, productId } = data;
            const fetchedConstraints = await getProductContactConstraints({
              ecosystemId,
              productId,
              type: 'supplier',
            });
            if (fetchedConstraints) {
              setConstraints(fetchedConstraints);
            }
            const fetchedPrices = await getProductPurchasePrices({
              ecosystemId,
              productId,
              skipArchived: true,
            });
            if (fetchedPrices) {
              setPrices(fetchedPrices);
            }
            setEcosystemId(ecosystemId);
            setType('supplier');
            setProductId(productId);
            setShow(true);
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
  );

  useCustomEventListener<'addCustomer', AppProductContactEvent>(
    'addCustomer',
    (data) => {
      if (data) {
        if (data.productId && data.ecosystemId) {
          getProductContactConstraints({
            ecosystemId: data.ecosystemId,
            productId: data.productId,
            type: 'customer',
          })
            ?.then(setConstraints)
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setEcosystemId(data.ecosystemId);
              setType('customer');
              setProductId(data.productId);
              setShow(true);
            });
        }
      }
    },
  );

  const onSubmit = useCallback(
    async (values: ProductContactConstraint) => {
      if (type) {
        await createProductContactConstraint({
          ...values,
          isDefault: !!values.isDefault,
        });
        form.resetFields();
        setShow(false);
        emitCustomEvent<'refreshConstraint', RefreshConstraintEvent>(
          'refreshConstraint',
          {
            type,
          },
        );
      }
    },
    [form, type],
  );

  if (!productId || !type || !ecosystemId) {
    return null;
  }

  const initialValues: Partial<ProductContactConstraint> = {
    productId,
    type,
    ecosystemId,
    unitAmount: prices.find((el) => el.isDefaultPrice)?.unitAmount || undefined,
    isDefault: false,
  };

  return (
    <DraggableModalProvider>
      <DraggableModal
        visible={show}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setShow(false);
          emitCustomEvent<'refreshTab', any>('refreshTab', {
            type,
          });
        }}
      >
        <Spin spinning={false}>
          <Card
            actions={[
              <Button
                type="primary"
                htmlType="submit"
                form="productContactConstraint"
              >
                {intl.formatMessage({
                  id: 'create.constraint',
                  defaultMessage: 'Create constraint',
                })}
              </Button>,
            ]}
          >
            <Tabs defaultActiveKey="1">
              <TabPane
                key="1"
                tab={intl.formatMessage({
                  id: `add.${type}`,
                  defaultMessage: `add.${type}`,
                })}
              >
                <AppEntityForm
                  appId={''}
                  autoComplete="off"
                  initialValues={initialValues}
                  name="productContactConstraint"
                  providedForm={form}
                  readOnly={false}
                  onSubmit={onSubmit}
                  hideButtons={true}
                >
                  {() => {
                    return (
                      <div>
                        <Form.Item name="productId" hidden />
                        <Form.Item name="type" hidden />
                        <Form.Item name="ecosystemId" hidden />
                        <Form.Item name="relatedContactSnapshot" hidden />
                        <SimpleProductNumber />
                        <SimpleProductName />
                        <UnitAmount />
                        <SelectedUnit ecosystemId={ecosystemId} />
                        <StandardDeliveryTime />
                        <ContactSearch
                          ecosystemId={ecosystemId}
                          usedContactIds={constraints.map(
                            (el) => el.relatedContact,
                          )}
                        />
                        {type === 'supplier' && (
                          <IsDefault
                            disabled={!!constraints.find((el) => el.isDefault)}
                          />
                        )}
                      </div>
                    );
                  }}
                </AppEntityForm>
              </TabPane>
            </Tabs>
          </Card>
        </Spin>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default AddProductContactConstraint;
