import { styled } from '@styles/themes';
import Board from 'react-trello';
import { Card, Button, Space } from 'antd';
import { ButtonsWrapper } from '@components/FormWithReminder/FormWithReminder.sc';

export const StyledBoard = styled(Board)`
  background-color: transparent;
  height: auto;
  > div > .smooth-dnd-container.horizontal {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
  }

  > div > .smooth-dnd-container.horizontal section:last-child > div {
    .smooth-dnd-container.vertical {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 20px;
    }
  }

  section .smooth-dnd-container {
    min-height: 150px;
    width: 100%;
  }
`;

export const Section = styled.section`
  background-color: #e3e3e3;
  border-radius: 3px;
  margin: 10px 5px 5px 5px;
  position: relative;
  padding: 10px;
  display: inline-flex;
  height: auto;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-width: 250px;
  width: 100%;
  overflow-x: hidden;
  align-self: stretch;
  max-height: 90vh;
  justify-content: space-between;

  > div {
    width: 100%;
  }
`;

export const CardWrapper = styled.article`
  background-color: transparent;
  position: relative;
  padding: 10px;
  cursor: pointer;
  max-width: 250px;
  margin-bottom: 7px;
  min-width: 230px;
`;

export const MovableCardWrapper = styled(CardWrapper)``;

export const CardForm = styled(Card)`
  position: fixed;
  width: 25vw;
  bottom: 25vh;
  right: 0;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  transition: all 0.5s ease;
  top: 0;
  right: 0;
  opacity: 0;
  border: 0;

  ${MovableCardWrapper}:hover & {
    opacity: 1;
  }
`;

export const WeekPlanContainer = styled.div`
  ${ButtonsWrapper} {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
`;

export const ResourceLabelSpace = styled(Space)`
  display: flex;
  overflow: hidden;
`;
