import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import { PageLayout } from '@apps/sales/services';
import EcosystemAddress from '@apps/sales/mainMenu/components/SaleCreator/components/EcosystemAddress';
import LogoWithName from '@apps/sales/mainMenu/components/SaleCreator/components/LogoWithName';
import DocNumber from '@apps/sales/mainMenu/components/SaleCreator/components/DocNumber';
import ContactDetails from '@apps/sales/mainMenu/components/SaleCreator/components/Contact';
import Products from '@apps/sales/mainMenu/components/SaleCreator/components/Products';
import DeliveryTerms from '@apps/sales/mainMenu/components/SaleCreator/components/DeliveryTerms';
import PaymentTerms from '@apps/sales/mainMenu/components/SaleCreator/components/PaymentTerms';
import { IntlShape } from 'react-intl';

type Props = {
  values: SalesFormValues;
  layout: PageLayout;
  intl: IntlShape;
  ecosystem?: Ecosystem;
  avatar: string;
};

const MainTemplate = ({ layout, values, avatar, intl, ecosystem }: Props) => {
  return (
    <Document>
      <Page size="A4" style={{ position: 'relative', padding: '40px' }} wrap>
        {(layout as PageLayout).map((el, index) => {
          if (el.field === 'ecosystemAddress') {
            return (
              <EcosystemAddress
                key={index}
                name={ecosystem?.name}
                address={ecosystem?.address}
                style={el.style}
                forPdf
              />
            );
          }
          if (el.field === 'logoWithName') {
            return (
              <LogoWithName style={el.style} key={index} url={avatar} forPdf />
            );
          }
          if (el.field === 'docNumber' && values.docNumber && values.type) {
            return (
              <DocNumber
                key={index}
                style={el.style}
                docNumber={values.docNumber}
                type={values.type}
                intl={intl}
                forPdf
              />
            );
          }
          if (el.field === 'contactDetails') {
            return (
              <ContactDetails
                style={el.style}
                contact={values.relatedContactSnapshot}
                intl={intl}
                forPdf
              />
            );
          }
          if (el.field === 'products') {
            return (
              <Products
                style={el.style}
                products={values.products}
                intl={intl}
                ecosystem={ecosystem?.id || ''}
                documentType={values.type as string}
                forPdf
              />
            );
          }
          if (el.field === 'deliveryTerms') {
            return (
              <DeliveryTerms
                key={index}
                style={el.style}
                intl={intl}
                terms={values.deliveryTerms}
                forPdf
              />
            );
          }
          if (el.field === 'paymentTerms') {
            return (
              <PaymentTerms
                key={index}
                style={el.style}
                intl={intl}
                terms={values.paymentTerms}
                forPdf
              />
            );
          }
          return null;
        })}
      </Page>
    </Document>
  );
};

export default MainTemplate;
