import React, { FC } from 'react';
import { Tooltip, Avatar } from 'antd';

export type AvatarSize = 'default' | 'large';

export type EcosystemAvatarProps = {
  name?: string;
  avatarUrl?: string;
  size?: AvatarSize;
};

const SIZES: Record<AvatarSize, number> = {
  default: 24,
  large: 40,
};

const EcosystemAvatar: FC<EcosystemAvatarProps> = ({
  name,
  avatarUrl,
  size = 'default',
  ...props
}) => {
  const imageSize = SIZES[size] || 24;
  return (
    <Tooltip placement="bottom" title={name} {...props}>
      <Avatar src={avatarUrl} size={imageSize} />
    </Tooltip>
  );
};

export default EcosystemAvatar;
