import React, { FC, useState } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import PdfViewer from '@components/PdfViewer';
import { useCustomEventListener } from '@core/services';

type Props = {
  element: ReactImageGalleryItem;
  width?: string;
  height?: string;
};

const ResponsivePDFViewer: FC<Props> = ({
  element,
  width = '260px',
  height = '260px',
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  useCustomEventListener<string, boolean>(
    'mediaViewerFullScreen',
    (fullScreen) => {
      setIsFullScreen(!!fullScreen);
    },
  );
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <PdfViewer
        documentUrl={element.original}
        height={isFullScreen ? '100vh' : height}
        width={isFullScreen ? '100vw' : width}
      />
    </div>
  );
};

export default ResponsivePDFViewer;
