import React, { useMemo, FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Modal, Input } from 'antd';
import intl from '../../../../../intl';
import { StyledSwitch } from '@pages/settings/components';
import {
  createProductGroup,
  updateProductGroup,
} from '@services/productGroups';
import { ProductGroup } from '@services/productGroups/namespace';
import { useEffect } from 'react';

type CreateNewButtonProps = {
  ecosystemId: string;
  refreshProductGroups: () => void;
  visible: boolean;
  setVisible: (arg: boolean) => void;
  selectedProductGroup: ProductGroup | undefined;
  setSelectedProductGroup: (arg: ProductGroup | undefined) => void;
};

export const ProductGroupFormModal: FC<CreateNewButtonProps> = ({
  ecosystemId,
  refreshProductGroups,
  visible,
  setVisible,
  selectedProductGroup,
  setSelectedProductGroup,
}) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  const initialValues = selectedProductGroup
    ? selectedProductGroup
    : { isActive: true };

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  return (
    <>
      <Modal
        visible={visible}
        title={intl.formatMessage({
          id: 'settings.products.groups.createNewButton',
          defaultMessage: 'Create new group',
        })}
        okText={
          selectedProductGroup
            ? intl.formatMessage({
                id: 'settings.products.groups.modal.edit',
                defaultMessage: 'Edit',
              })
            : intl.formatMessage({
                id: 'settings.products.groups.modal.create',
                defaultMessage: 'Create',
              })
        }
        cancelText={intl.formatMessage({
          id: 'button.cancel',
          defaultMessage: 'Cancel',
        })}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          if (selectedProductGroup) {
            setSelectedProductGroup(undefined);
          }
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setConfirmLoading(true);
              if (selectedProductGroup) {
                await updateProductGroup({
                  ...selectedProductGroup,
                  ...values,
                  ecosystemId,
                });
                setSelectedProductGroup(undefined);
              } else {
                await createProductGroup({ ...values, ecosystemId });
              }
              form.resetFields();
              setConfirmLoading(false);
              setVisible(false);
              refreshProductGroups();
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initialValues}
        >
          <Form.Item
            name="name"
            label={intl.formatMessage({
              id: 'settings.products.groups.creationModal.name.label',
              defaultMessage: 'Name',
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'settings.products.groups.creationModal.name.error',
                  defaultMessage: 'Name field is required',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="isActive"
            label={intl.formatMessage({
              id: 'settings.products.groups.creationModal.isActive.label',
              defaultMessage: 'Status',
            })}
          >
            <StyledSwitch
              onChange={(value) => form.setFieldsValue({ isActive: value })}
              checkedChildren={activated}
              unCheckedChildren={deactivated}
              defaultChecked={initialValues.isActive}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
