import { firebase } from '@services/Firebase';
import { PRODUCTS_NUMBERS } from '@apps/products/services/namespace';

const postProductNumber = async (ecosystem: string, productNumber: string) => {
  await firebase.firestore
    ?.collection(PRODUCTS_NUMBERS)
    .doc(`${ecosystem}_${productNumber}`)
    .set({});
};

export default postProductNumber;
