import * as React from 'react';
import { List, Avatar, Button, Row, Tooltip } from 'antd';
import { Text, Toggle } from '@todix/ui-components';
import { DispatchProps, StateProps } from '@components/EcosystemSwitches';
import {
  LiteItemMeta,
  Wrapper,
} from '@components/EcosystemSwitches/EcosystemSwitches.sc';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import {
  changeRelation,
  getEcosystemRelationsByMemberId,
} from '@services/ecosystems';
import { getOwnerEcosystem } from '@components/EcosystemIndicator/store';

type Props = StateProps &
  DispatchProps & {
    onClickCreate: () => void;
  };

export const EcosystemSwitches: React.FC<Props> = ({
  ecosystems,
  ecosystemsRelations,
  onClickCreate,
}: Props) => {
  // TODO: add prevent from deactivate all ecosystems
  const onlyOneLeft =
    ecosystemsRelations.filter((relation) => relation.isActive).length < 2;
  const { data: user } = useSelector(getUser);
  const ownerEcosystems = useSelector(getOwnerEcosystem(user?.uid as string));
  const history = useHistory();

  if (!user) {
    return null;
  }

  const createButtonDisabled = ownerEcosystems.length >= user.ecosystemLimit;

  return (
    <Wrapper>
      <Text level={1} disabled>
        <FormattedMessage
          id="ecosystemswitches.text"
          defaultMessage="Active ecosystems"
        />
      </Text>
      <List
        itemLayout="horizontal"
        dataSource={ecosystems}
        renderItem={(item: Ecosystem) => {
          const relation = ecosystemsRelations.find(
            (relation) => relation.ecosystem === item.id,
          );
          return (
            <List.Item>
              <LiteItemMeta
                avatar={<Avatar src={item.avatarUrl} />}
                title={item.name}
                description={item.email}
              />
              <Toggle
                disabled={relation?.isActive && onlyOneLeft}
                checked={relation?.isActive}
                onChange={async (value: boolean) => {
                  const relations = await getEcosystemRelationsByMemberId(
                    user?.uid as string,
                  );

                  const relationToChange = relations?.find(
                    (relation) =>
                      relation.ecosystem === item.id &&
                      relation.memberId === user?.uid,
                  );

                  if (relationToChange && relationToChange.id) {
                    await changeRelation(
                      {
                        ecosystem: relationToChange.ecosystem,
                        memberId: relationToChange.memberId,
                        role: relationToChange.role,
                        isActive: value,
                      },
                      relationToChange.id,
                    );
                  }
                }}
              />
            </List.Item>
          );
        }}
        footer={
          <Row justify="end">
            <Tooltip
              title={
                <FormattedMessage
                  id="ecosystem.limit"
                  defaultMessage="You have reached limit of ecosystems"
                />
              }
            >
              <Button
                type="primary"
                onClick={() => {
                  history.push('/settings/create');
                  onClickCreate();
                }}
                disabled={createButtonDisabled}
              >
                <FormattedMessage
                  id="settings.create.ecosystemform.title"
                  defaultMessage="Create Company Ecosystem"
                />
              </Button>
            </Tooltip>
          </Row>
        }
      />
    </Wrapper>
  );
};
