import { FilterDocumentNameObject } from '@services/filtering/index';
import { nameValueGetter } from '@apps/documents/mainMenu/Archive/valueGetters';
import { IntlShape } from 'react-intl';

const documentNameMatcher = (
  filterObject: FilterDocumentNameObject,
  row: any,
  intl: IntlShape,
) => {
  if (filterObject.type === 'contains') {
    const docName = nameValueGetter(row, intl);
    return docName.toLowerCase().includes(filterObject.filter.toLowerCase());
  }

  return false;
};

export default documentNameMatcher;
