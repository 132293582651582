import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Avatar, Select, Space } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { getMembers } from '@services/ecosystems';

type ReceiverSelectorProps = SelectProps & {
  selectedEcosystem: string | null;
  onReceiverSelect?(member: EcosystemMember): void;
};

const ReceiverSelector: FC<ReceiverSelectorProps> = ({
  selectedEcosystem,
  onReceiverSelect,
  ...rest
}) => {
  const [members, setMembers] = useState<EcosystemMember[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedEcosystem) {
      setLoading(true);
      getMembers(selectedEcosystem)
        ?.then(setMembers)
        .catch((e) => {
          console.error(e);
          setMembers([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedEcosystem]);

  const options = useMemo(() => {
    return members.map((member) => ({
      value: member.id,
      'data-forsearch': `${member?.displayName || ''} ${member?.email || ''}`,
      label: (
        <Space key={member.id} size="small" direction="horizontal">
          <Avatar shape="circle" src={member?.photoURL} size="small" />
          <span>{member.displayName || member.email}</span>
        </Space>
      ),
    }));
  }, [members]);

  const handleSelect = useCallback(
    (memberId: any) => {
      const member = members.find((member) => member.id === memberId);
      if (member && onReceiverSelect) {
        onReceiverSelect(member);
      }
    },
    [members, onReceiverSelect],
  );

  return (
    <Select
      {...rest}
      disabled={!selectedEcosystem || loading}
      loading={loading}
      onSelect={handleSelect}
      showSearch
      filterOption={(inputValue, option) => {
        return option
          ? option['data-forsearch']
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          : false;
      }}
      options={options}
    />
  );
};

export default ReceiverSelector;
