import { RegisteredFileApp } from '@apps/AppTypes';
import { connectedApps } from '@apps/connectedApps';
import { useSelector } from 'react-redux';
import {
  getActiveEcosystems,
  getEcosystemById,
} from '@components/EcosystemIndicator/store';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getLicences } from '@core/store/licences';
import { getEcosystemRelations } from '@core/store/ecosystemRelations';
import { rootStore, RootStore } from '@core/store';

export const getActivatedFileApps = (
  ecosystem: Ecosystem,
  licences: Record<string, Licence>,
) => {
  const activatedApps = ecosystem && Object.keys(licences).length > 0;
  if (!activatedApps) {
    return [];
  }
  const state: RootStore = rootStore.getState();
  const stripeApps = state.stripeProducts;

  const apps = Object.values(licences)
    .filter((el) => el.ecosystem === ecosystem.id && el.status === 'active')
    .map((el) => {
      const stripeApp = stripeApps.find((app) => app.id === el.appId);
      if (!stripeApp) {
        return null;
      }
      const application = connectedApps.find(
        (app) => app.todixId === stripeApp.todixId,
      );
      if (!application) {
        return null;
      }
      return {
        ...application,
        appId: stripeApp.id,
        acl: el.acl,
      };
    })
    .filter((app) => app !== null) as RegisteredFileApp[];

  return apps.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export const useAllActivatedFileAppsForEcosystem = (ecosystemId?: string) => {
  const licencesState = useSelector(getLicences);
  const licences = licencesState.data;
  const ecosystem = useSelector(getEcosystemById(ecosystemId || ''));
  const relations = useSelector(getEcosystemRelations);
  if (!ecosystem) {
    return [];
  }
  if (!ecosystem.isActive) {
    return [];
  }
  const relation = relations.find(
    (relation) =>
      relation.ecosystem === ecosystem.id &&
      relation.isActive &&
      !relation.disabled,
  );
  if (!relation) {
    return [];
  }
  return getActivatedFileApps(ecosystem, licences);
};

export const useAllActivatedFileApps = () => {
  const [activeEcosystemsApps, setActiveEcosystemsApps] = useState<
    RegisteredFileApp[]
  >([]);
  const activeEcosystems = useSelector(getActiveEcosystems);
  const licencesState = useSelector(getLicences);
  const licences = licencesState.data;

  useEffect(() => {
    let activeEcosystemsApps: RegisteredFileApp[] = activeEcosystems.reduce(
      (acc: any, ecosystem: Ecosystem) => {
        const activatedFileApps = getActivatedFileApps(
          ecosystem,
          licences,
        ).filter((app) => {
          if (app.hidden) {
            return false;
          }
          return true;
        });
        // check for duplicates
        activatedFileApps.forEach((app) => {
          acc.push(app);
        });
        return acc;
      },
      [],
    );

    const uniqueActivatedFileApps = _.uniqBy(activeEcosystemsApps, 'appId');
    setActiveEcosystemsApps(uniqueActivatedFileApps);
  }, [activeEcosystems, licences]);

  return activeEcosystemsApps;
};
