import { FieldType, LayoutField } from '@apps/documents/service/namespace';
import {
  CommonFieldsLayout,
  SideViewCommonFieldsLayout,
} from '@apps/documents/service/commonFields.layouts';
import { LayoutDestination } from '@apps/documents/service/layouts';

export const ProcessingCrExtractLayout: LayoutField[] = [
  {
    fieldName: 'crNumber',
    fieldType: 'input',
    label: 'documents.processingCrExtract.crNumber.label',
    rules: [
      {
        required: true,
        message: 'documents.processingCrExtract.crNumber.error',
      },
    ],
  },
  {
    fieldName: 'entryDate',
    fieldType: 'datepicker',
    label: 'documents.processingCrExtract.entryDate.label',
    type: 'date',
    placeholder: 'documents.processingCrExtract.entryDate.placeholder',
  },
];

export const ProcessingSummaryCrExtractLayout: LayoutField[] =
  ProcessingCrExtractLayout.map((field) => ({
    ...field,
    fieldType: ['datepicker'].includes(field.fieldType)
      ? (field.fieldType as FieldType)
      : 'input',
    placeholder: '',
    style: {
      border: 0,
    },
  }));

export const CrExtractLayout: LayoutField[] = [
  ...CommonFieldsLayout,
  {
    fieldName: 'crNumber',
    fieldType: 'input',
    label: 'documents.crextract.crnumber.label',
  },
  {
    fieldName: 'entryDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.crextract.entrydate.label',
    type: 'date',
    placeholder: 'documents.crextract.entrydate.placeholder',
  },
];

export const SideViewCrExtractLayout: LayoutField[] = [
  ...SideViewCommonFieldsLayout,
  {
    fieldName: 'crNumber',
    fieldType: 'input',
    label: 'documents.crNumber.crnumber.label',
    readOnly: true,
  },
  {
    fieldName: 'entryDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.crextract.entrydate.label',
    type: 'date',
  },
];

const crExtractLayouts: Record<LayoutDestination, LayoutField[]> = {
  detailedForm: CrExtractLayout,
  sideView: SideViewCrExtractLayout,
  processing: ProcessingCrExtractLayout,
  processingSummary: ProcessingSummaryCrExtractLayout,
};

export default crExtractLayouts;
