import React, { FC, useCallback } from 'react';
import { Radio, RadioChangeEvent, Tooltip, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ImportOutlined,
  ExportOutlined,
  BorderOutlined,
} from '@ant-design/icons';
import { emitCustomEvent } from '@core/services';
import { GridStyleRadioGroup } from '@apps/documents/mainMenu/Inbox/DocumentProcessing/styles.sc';
import StyledItemForm from '@apps/documents/mainMenu/components/StyledItemForm';
import { useFormContext } from '@components/FormWithReminder/useFormContext';

const { Button } = Radio;
const { Item } = Form;

type Props = {
  category?: string;
};

const DocumentCategory: FC<Props> = ({ category }) => {
  const intl = useIntl();
  const { readOnly } = useFormContext();

  const onChange = useCallback((e: RadioChangeEvent) => {
    const category = e.target.value;
    emitCustomEvent('setDocument', {
      category,
    });
  }, []);

  return (
    <StyledItemForm
      name="category"
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'documentprocessing.step1.category.error',
            defaultMessage: 'documentprocessing.step1.category.error',
          }),
        },
      ]}
    >
      <GridStyleRadioGroup
        defaultValue={category}
        size="middle"
        onChange={onChange}
      >
        <Tooltip
          title={
            <FormattedMessage
              id="documentprocessing.step1.category.option1.label"
              defaultMessage="documentprocessing.step1.category.option1.label"
            />
          }
        >
          <Button value="incoming" disabled={readOnly}>
            {category === 'incoming' ? (
              <ImportOutlined style={{ color: '#fff' }} />
            ) : (
              <ImportOutlined />
            )}
          </Button>
        </Tooltip>
        <Tooltip
          title={
            <FormattedMessage
              id="documentprocessing.step1.category.option2.label"
              defaultMessage="documentprocessing.step1.category.option2.label"
            />
          }
        >
          <Button value="outgoing" disabled={readOnly}>
            {category === 'outgoing' ? (
              <ExportOutlined style={{ color: '#fff' }} />
            ) : (
              <ExportOutlined />
            )}
          </Button>
        </Tooltip>
        <Tooltip
          title={
            <FormattedMessage
              id="documentprocessing.step1.category.option3.label"
              defaultMessage="documentprocessing.step1.category.option3.label"
            />
          }
        >
          <Button value="none" disabled={readOnly}>
            {category === 'none' ? (
              <BorderOutlined style={{ color: '#fff' }} />
            ) : (
              <BorderOutlined />
            )}
          </Button>
        </Tooltip>
      </GridStyleRadioGroup>
    </StyledItemForm>
  );
};

export default () => {
  return (
    <Item shouldUpdate={true} noStyle>
      {({ getFieldValue }) => {
        const category = getFieldValue('category');
        const docType = getFieldValue('type');
        return docType ? <DocumentCategory category={category} /> : null;
      }}
    </Item>
  );
};
