import React, { FC } from 'react';
import { Form } from 'antd';
import intl from '../../../../../intl';
import FloatNumberedField from '@components/FloatNumberedField';

const { Item } = Form;

const Tax: FC = () => {
  return (
    <Item noStyle shouldUpdate>
      {() => {
        return (
          <Item
            name="tax"
            label={intl.formatMessage({
              id: 'sales.createForm.tax.label',
              defaultMessage: 'Tax (%)',
            })}
          >
            <FloatNumberedField
              allowNegative={false}
              max={100}
              decimalSeparator={','}
              decimalScale={2}
              thousandSeparator={'.'}
            />
          </Item>
        );
      }}
    </Item>
  );
};

export default Tax;
