import { useEffect, useState } from 'react';
import {
  DeliveryTermsType,
  getActiveSalesDeliveryTerms,
} from '@apps/sales/services/salesDeliveryTerms';

export const useSalesDeliveryTerms = (id: string) => {
  const [deliveryTerms, setDeliveryTerms] = useState<
    null | DeliveryTermsType[]
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    if (!id) {
      return;
    }
    getActiveSalesDeliveryTerms(id)
      ?.then((el) => {
        setDeliveryTerms(el);
      })
      ?.finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return [isLoading, deliveryTerms, setDeliveryTerms] as [
    boolean,
    null | DeliveryTermsType,
    any,
  ];
};
