import { styled } from '@styles/themes';
import { Button } from 'antd';

interface StyledButtonProps {
  buttonType: 'danger' | 'warning' | 'primary';
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${(props) => {
    switch (props.buttonType) {
      case 'danger':
        return '#ff4d4f';
      case 'warning':
        return '#fadb14';
      case 'primary':
        return '#52c41a';
      default:
        return '#ff4d4f';
    }
  }};

  color: ${(props) => {
    switch (props.buttonType) {
      case 'danger':
        return '#fff';
      case 'warning':
        return '#000';
      case 'primary':
        return '#fff';
      default:
        return '#fff';
    }
  }};

  &:hover,
  &:focus {
    background-color: ${(props) => {
      switch (props.buttonType) {
        case 'danger':
          return '#ff4d4f';
        case 'warning':
          return '#fadb14';
        case 'primary':
          return '#52c41a';
        default:
          return '#ff4d4f';
      }
    }};

    border-color: ${(props) => {
      switch (props.buttonType) {
        case 'danger':
          return '#ff4d4f';
        case 'warning':
          return '#fadb14';
        case 'primary':
          return '#52c41a';
        default:
          return '#ff4d4f';
      }
    }};

    color: ${(props) => {
      switch (props.buttonType) {
        case 'danger':
          return '#fff';
        case 'warning':
          return '#000';
        case 'primary':
          return '#fff';
        default:
          return '#fff';
      }
    }};
  }
`;
