import React, { FC, ReactNode } from 'react';
import { Form, FormItemProps } from 'antd';
import { StyledSwitch } from '@pages/settings/components';

const { Item } = Form;

type ActivationToggleProps = FormItemProps & {
  readOnly?: boolean;
  checkedChildren?: ReactNode;
  unCheckedChildren?: ReactNode;
};

const ProductDetailsToggle: FC<ActivationToggleProps> = ({
  readOnly,
  checkedChildren,
  unCheckedChildren,
  ...rest
}) => {
  return (
    <Item valuePropName="checked" {...rest}>
      <StyledSwitch
        disabled={readOnly}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
      />
    </Item>
  );
};

export default ProductDetailsToggle;
