import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Select, Space } from 'antd';
import { Contact, getContactsForEcosystem } from '@apps/contacts/services';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import WidgetResolver from '@components/WidgetResolver';

const { Item } = Form;

type Props = {
  onContactSet(): void;
  disabled?: boolean;
};

const ContactSearch: FC<Props> = ({ onContactSet, disabled = false }) => {
  const intl = useIntl();
  const { getFieldValue, setFieldsValue, getFieldsValue } = useFormContext();
  const ecosystem = getFieldValue('ecosystem');

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ecosystem) {
      setLoading(true);
      getContactsForEcosystem(ecosystem, 'view-contacts')
        ?.then((results) => {
          setContacts(results);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem]);

  const handleChange = useCallback(
    (id) => {
      if (disabled) {
        return;
      }
      const contact = contacts.find((contact) => contact.id === id);
      if (contact) {
        setFieldsValue({
          ...getFieldsValue(),
          relatedContactSnapshot: {
            ...contact,
            addresses: contact.addresses || [],
            emails: contact.emails || [],
            phones: contact.phones || [],
          },
        });
        onContactSet();
      }
    },
    [contacts, disabled, getFieldsValue, onContactSet, setFieldsValue],
  );

  const handleNewContactSubmit = useCallback(
    (contact: Contact) => {
      if (disabled) {
        return;
      }
      setFieldsValue({
        ...getFieldsValue(),
        relatedContactSnapshot: {
          ...contact,
          addresses: contact.addresses || [],
          emails: contact.emails || [],
          phones: contact.phones || [],
        },
      });
      onContactSet();
    },
    [disabled, getFieldsValue, onContactSet, setFieldsValue],
  );

  const options = useMemo(() => {
    return contacts.map((contact, index) => {
      const address = contact?.addresses
        ? contact.addresses.find((add) => add.standard)
        : undefined;
      return {
        value: contact.id,
        'data-forsearch': `${contact?.companyName || ''} ${
          contact?.firstName || ''
        } ${contact?.lastName || ''}`,
        label: (
          <Space size="small" direction="vertical" key={index}>
            {contact.type === 'company' && <div>{contact.companyName}</div>}
            {contact.type === 'person' && (
              <div>
                {contact.firstName} {contact.lastName}
              </div>
            )}
            {address && (
              <div>
                {`${address.street || ''} ${address.number || ''} ${
                  address.zipcode || ''
                } ${address.city || ''} ${address.country || ''}`}
              </div>
            )}
          </Space>
        ),
      };
    });
  }, [contacts]);
  return (
    <div>
      <Item
        className="animate__animated animate__fadeIn animate__faster"
        name="relatedContact"
        required
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'sales.contact.error',
              defaultMessage: 'Missing contact',
            }),
          },
        ]}
      >
        <Select
          disabled={disabled}
          style={{ minWidth: '300px' }}
          showSearch={true}
          loading={loading}
          onChange={handleChange}
          filterOption={(inputValue, option) => {
            return option
              ? option['data-forsearch']
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              : false;
          }}
          placeholder={
            <FormattedMessage
              id="documentprocessing.step5.relatedcontact.label"
              defaultMessage="Related contact"
            />
          }
          options={options}
        />
      </Item>
      <WidgetResolver
        appName="contacts"
        widgetName="AddNewContactWidget"
        injectedProps={{
          OnClickEl: (props: any) => (
            <Button type="primary" {...props} disabled={disabled}>
              <FormattedMessage
                id="documentprocessing.step2.relatedcontact.header"
                defaultMessage="New contact"
              />
            </Button>
          ),
          onSubmit: (contact: Contact) =>
            !disabled && handleNewContactSubmit(contact),
        }}
      />
    </div>
  );
};

export default ContactSearch;
