import { styled } from '@styles/themes';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: 20px;
  height: 50vh;
`;

export const TaskTitleContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  > span[role='img'] {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  :hover {
    > span[role='img'] {
      color: #cf1322;
      opacity: 1;
    }
  }
`;
