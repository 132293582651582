import React, { FC, useState, useCallback } from 'react';
import FlowManagementForm from '@apps/warehouse/mainMenu/components/FlowManagementForm';
import {
  BatchNumberEntry,
  FlowOption,
  ProductEntry,
} from '@apps/warehouse/services/namespace';
import ProductEntries from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries';
import AppConfig, { WAREHOUSE_ACTION } from '@apps/warehouse';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

type FlowManagementProps = {
  onSubmit(values: any): void;
  ecosystemId?: string;
  action: WAREHOUSE_ACTION;
};

const FlowManagement: FC<FlowManagementProps> = ({
  onSubmit,
  ecosystemId = null,
  action,
}) => {
  const canCreate = checkUserPermissions(
    rootStore.getState(),
    AppConfig.todixId,
    action,
    ecosystemId || '',
  );
  const [selectedEcosystem, setSelectedEcosystem] = useState<string | null>(
    canCreate ? ecosystemId : null,
  );
  const [products, setProducts] = useState<ProductEntry[]>([]);
  const [count, setCount] = useState(0);
  const [selectedFlow, setSelectedFlow] = useState<FlowOption | null>(null);
  const [serialNumbersMap, setSerialNumbersMap] = useState<
    Record<string, string[]>
  >({});
  const [batchNumbersMap, setBatchNumbersMap] = useState<
    Record<string, BatchNumberEntry[]>
  >({});
  const [entriesValid, setEntriesValid] = useState<boolean>(true);

  const handleFlowChange = useCallback((flow: FlowOption) => {
    setSelectedFlow(flow);
    setProducts([]);
    setSerialNumbersMap({});
    setBatchNumbersMap({});
    setEntriesValid(true);
  }, []);

  const handleSerialNumbersChange = useCallback(
    (positionId: string, serialNumbers: string[]) => {
      setSerialNumbersMap((prev) => ({
        ...prev,
        [positionId]: serialNumbers,
      }));
    },
    [],
  );

  const handleBatchNumbersChange = useCallback(
    (positionId: string, batchEntries: BatchNumberEntry[]) => {
      setBatchNumbersMap((prev) => ({
        ...prev,
        [positionId]: batchEntries,
      }));
    },
    [],
  );

  const handleEntriesValidationChange = useCallback((isValid: boolean) => {
    setEntriesValid(isValid);
  }, []);

  const handleEcosystemChange = useCallback((ecosystemId: string) => {
    setSelectedEcosystem(ecosystemId);
    setSerialNumbersMap({});
    setBatchNumbersMap({});
    setEntriesValid(true);
  }, []);

  const handleSubmit = useCallback(
    (values: any) => {
      onSubmit({
        ...values,
        serialNumbersMap,
        batchNumbersMap,
      });
    },
    [onSubmit, serialNumbersMap, batchNumbersMap],
  );

  return (
    <>
      <FlowManagementForm
        entries={products}
        setEntries={setProducts}
        onSubmit={handleSubmit}
        onEcosystemSelect={handleEcosystemChange}
        ecosystemId={ecosystemId || ''}
        action={action}
        onFlowSelect={handleFlowChange}
        entriesValid={entriesValid}
      />
      {canCreate && (
        <ProductEntries
          entries={products}
          setEntries={setProducts}
          count={count}
          setCount={setCount}
          selectedEcosystem={selectedEcosystem}
          flow={selectedFlow}
          onSerialNumbersChange={handleSerialNumbersChange}
          serialNumbersMap={serialNumbersMap}
          onBatchNumbersChange={handleBatchNumbersChange}
          batchNumbersMap={batchNumbersMap}
          onValidationChange={handleEntriesValidationChange}
        />
      )}
    </>
  );
};

export default FlowManagement;
