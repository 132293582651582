import React from 'react';
import { Spin } from 'antd';

const SpinContainer = () => (
  <div
    style={{
      padding: '200px 0',
      textAlign: 'center',
    }}
  >
    <Spin spinning />
  </div>
);

export default SpinContainer;
