import { useEffect, useState } from 'react';
import Storage from '@services/Firebase/Storage';

export const useDefaultPhotoUrls = () => {
  const [defaultPhotos, setDefaultPhotos] = useState<string[] | null>(null);

  useEffect(() => {
    const avatarPromises = [];
    // loop because we want to download 5 avatar svgs
    for (let index = 1; index <= 5; index++) {
      const getAvatar = async () =>
        (
          await Storage.getUrl(`public/avatars/avatar${index}.svg`)
        ).getDownloadURL();

      avatarPromises.push(getAvatar());
    }

    (async () => {
      let resp: string[];

      try {
        resp = await Promise.all(avatarPromises);
        setDefaultPhotos(resp);
      } catch {
        setDefaultPhotos([
          'https://firebasestorage.googleapis.com/v0/b/todix-platform-staging.appspot.com/o/random',
        ]);
        console.error('Cant retrieve default login photos');
      }
    })();
  }, []);

  return defaultPhotos;
};
