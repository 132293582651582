import React, { FC, useMemo } from 'react';
import { Badge } from 'antd';
import { Lane } from '@apps/weekplan/services/namespace';

export type ColId =
  | 'tasks_total'
  | 'tasks_monday'
  | 'tasks_tuesday'
  | 'tasks_wednesday'
  | 'tasks_thursday'
  | 'tasks_friday'
  | 'tasks_free';

export type LaneCellRendererProps = {
  data: {
    id: string;
    ecosystem: string;
    lanes: Lane[];
  };
  column: {
    colId: ColId;
  };
};

const laneIndex = [
  'tasks_monday',
  'tasks_tuesday',
  'tasks_wednesday',
  'tasks_thursday',
  'tasks_friday',
  'tasks_free',
];

const LaneCellRenderer: FC<LaneCellRendererProps> = ({
  data,
  column: { colId },
}) => {
  const tasks = useMemo(() => {
    if (colId === 'tasks_total') {
      return data.lanes.reduce((acc, lane) => {
        return acc + lane.cards.length;
      }, 0);
    }
    const indexToCheck = laneIndex.indexOf(colId);

    if (indexToCheck > -1) {
      return data.lanes[indexToCheck].cards.length;
    }
    return 0;
  }, [colId, data.lanes]);
  const backgroundColor = useMemo(() => {
    if (colId === 'tasks_total') {
      return '#595959';
    }
    if (colId === 'tasks_free') {
      return '#52c41a';
    }

    return '#1890ff';
  }, [colId]);
  return <Badge count={tasks} style={{ backgroundColor }} />;
};

export default LaneCellRenderer;
