import React, { FC } from 'react';
import { Popover } from 'antd';
import { Sale } from '@apps/sales/services';
import calculateTotalsForProducts from '@apps/sales/mainMenu/components/SaleCreator/calculateTotalsForProducts';
import { getMoneyValue } from '@components/MoneyInput';
import { currencySigns } from '@services/currency';
import { useIntl } from 'react-intl';
import {
  PriceGrid,
  PriceLabel,
  PriceValue,
} from '@apps/sales/widgets/styles.sc';

type Props = {
  value?: Sale['products'];
};

const SumCellRenderer: FC<Props> = ({ value }) => {
  const intl = useIntl();
  if (!value) {
    return null;
  }
  const { total, totalNetPrice, taxes, totalDiscountedPrice } =
    calculateTotalsForProducts(value);

  const content = () => {
    return (
      <PriceGrid>
        <PriceLabel>
          {intl.formatMessage({
            id: 'sales.createForm.sumNet.label',
            defaultMessage: 'Sum net',
          })}
        </PriceLabel>
        <PriceValue>
          {getMoneyValue({
            value: totalNetPrice ? `${totalNetPrice}` : '',
            prefix: currencySigns['EUR'],
          })?.result || ''}
        </PriceValue>
        {Object.keys(taxes)
          .sort()
          .map((tax, index) => {
            return (
              <React.Fragment key={index}>
                <PriceLabel>{`${intl.formatMessage({
                  id: 'sales.salesTab.pricePreview.tax',
                  defaultMessage: 'Tax',
                })} (${tax} %)`}</PriceLabel>
                <PriceValue>
                  {getMoneyValue({
                    value: taxes[tax] ? `${taxes[tax]}` : '',
                    prefix: currencySigns['EUR'],
                  })?.result || ''}
                </PriceValue>
              </React.Fragment>
            );
          })}

        <PriceLabel>
          {intl.formatMessage({
            id: 'sales.createForm.discountedPrice.label',
            defaultMessage: 'Discounted price',
          })}
        </PriceLabel>
        <PriceValue>
          {getMoneyValue({
            value: totalDiscountedPrice ? `${totalDiscountedPrice}` : '',
            prefix: currencySigns['EUR'],
          })?.result || ''}
        </PriceValue>
      </PriceGrid>
    );
  };
  return (
    <Popover content={content}>
      {getMoneyValue({
        value: total ? `${total}` : '',
        prefix: currencySigns['EUR'],
      })?.result || ''}
    </Popover>
  );
};

export default SumCellRenderer;
