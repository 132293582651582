import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import intl from 'intl';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import {
  GridApi,
  GridReadyEvent,
  IGetRowsParams,
  ModelUpdatedEvent,
  SelectionChangedEvent,
  RowClickedEvent,
  CellClickedEvent,
} from 'ag-grid-community';
import { ExtraOptions } from '../AppView/ActionRenderer';
import AgThemeTodix from '../AppView/AgThemeTodix';
import { EcosystemState } from '@components/EcosystemIndicator/store';
import { AppViewProps } from '@components/AppView';
import { useCustomEventListener } from '@core/services';
import agGridEn from '@assets/i18n/agGrid-en';
import agGridDe from '@assets/i18n/agGrid-de';
import {
  AvatarRenderer,
  ListActionRenderer,
  EcosystemRenderer,
  ProgressBarRenderer,
  GenderRenderer,
  DateRenderer,
  UserRenderer,
  RelationSelectRenderer,
  TimestampRenderer,
  MoneyValueRenderer,
} from '@components/List/FrameworkComponents';
import { connectedApps } from '@apps/connectedApps';
import SpinContainer from '@components/SpinContainer';
import {
  EmailFilter,
  EcosystemFilter,
  ActiveStatusFilter,
  CityFilter,
  CreatingUserFilter,
  DocumentNameFilter,
  DocumentTypeFilter,
  PhoneNumberFilter,
  ProgressFilter,
  RelatedContactFilter,
  TagsFilter,
  UploadingUserFilter,
} from '@components/List/CustomsFilters';
import CategoryFilter from './CustomsFilters/CategoryFilter';
import { FileApp } from '@apps/AppTypes';

const agGridLocale = intl.locale === 'de' ? agGridDe : agGridEn;

export type ListEvents = 'refreshList';

export type GetRows = (
  params: IGetRowsParams & { ecosystems?: string[]; query?: string },
) => void;

const getDatasource = (
  getRows: GetRows,
  ecosystems?: string[],
  quickFilterText = '',
) => ({
  getRows: (getRowsParams: IGetRowsParams) => {
    getRows({
      ...getRowsParams,
      ecosystems,
      query: quickFilterText,
    });
  },
});

export type ListProps = AgGridReactProps & {
  options?: AppViewProps['options'];
  gridRef: any;
  columns: ReactNode;
  getRows: (params: IGetRowsParams) => void;
  quickFilterText?: string;
  activeEcosystems?: EcosystemState;
  selectedRow: string | null;
  setSelectedRow: (id: string | null) => void;
  setRowsCount?: (count: number) => void;
  path: string;
  onCellClickPath: string;
  getExtraOptions?: (data: any, refreshList: () => void) => ExtraOptions;
  fetchExtraOptions?: (
    data: any,
    refreshList: () => void,
  ) => Promise<ExtraOptions>;
  actionListCells: string[];
  appConfig?: FileApp;
  onGridReady?: (event: GridReadyEvent) => void;
} & Pick<AppViewProps, 'onRemoveItem'>;

const discoverRenderers = async () => {
  let discoveredRenderers: Record<string, any> = {};
  connectedApps.forEach((app) => {
    if (app.listCellRenderers) {
      for (const renderer of app.listCellRenderers) {
        const component = import(
          /* webpackMode: "eager" */ `apps/${renderer.path}`
        );
        discoveredRenderers = {
          ...discoveredRenderers,
          [renderer.name]: component,
        };
      }
    }
  });

  const results = await Promise.all(Object.values(discoveredRenderers));
  const keys = Object.keys(discoveredRenderers);

  keys.forEach((key, index) => {
    discoveredRenderers[key] = results[index].default;
  });

  return discoveredRenderers;
};

const List: FC<ListProps> = ({
  gridRef,
  columns,
  getRows,
  quickFilterText = '',
  activeEcosystems,
  selectedRow,
  setSelectedRow,
  setRowsCount,
  path,
  onCellClickPath,
  onRemoveItem,
  getExtraOptions,
  fetchExtraOptions,
  options,
  actionListCells,
  appConfig,
  ...props
}) => {
  const history = useHistory();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [discoveringRenderers, setDiscoveringRenderers] = useState(true);
  const [renderers, setRenderers] = useState<Record<string, any>>({});
  useEffect(() => {
    discoverRenderers().then((fetchedRenderers) => {
      setRenderers(fetchedRenderers);
      setDiscoveringRenderers(false);
    });
  }, []);

  useCustomEventListener<ListEvents>('refreshList', () => {
    if (gridApi) {
      gridApi.deselectAll();
      gridApi.purgeInfiniteCache();
    }
  });

  useEffect(() => {
    if (gridApi) {
      gridApi.setDatasource(
        getDatasource(
          getRows,
          activeEcosystems?.map((e) => e.id),
          quickFilterText,
        ),
      );
    }
  }, [gridApi, quickFilterText, activeEcosystems, getRows]);

  useEffect(() => {
    if (gridApi && selectedRow === null) {
      gridApi.deselectAll();
      gridApi.purgeInfiniteCache();
    }
  }, [gridApi, selectedRow]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    if (props.onGridReady) {
      props.onGridReady(event);
    }
  };

  const onRowClicked = (event: RowClickedEvent) => {
    const id = event.data.id;
    event.node.expanded = true;
    if (selectedRow === id) {
      event.api.deselectAll();
    }
  };
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedRows = event.api.getSelectedRows();
    if (selectedRows.length && selectedRows[0]) {
      setSelectedRow(selectedRows[0].id);
    } else {
      setSelectedRow(null);
    }
  };
  const onCellClicked = (event: CellClickedEvent) => {
    if (
      actionListCells.includes(
        (event.column as any).userProvidedColDef.cellRenderer,
      )
    ) {
      event.node.setSelected(false);
    } else {
      //TODO: remove to go back to side view
      const {
        context: { onCellClickPath },
        data: { id },
      } = event;
      if (id && onCellClickPath) {
        history.push(`${onCellClickPath}/${id}`);
      }
    }
  };
  const onModelUpdated = (event: ModelUpdatedEvent) => {
    if (setRowsCount) {
      setRowsCount(event.api.getInfiniteRowCount() as number);
    }
  };
  if (discoveringRenderers) {
    return <SpinContainer />;
  }
  return (
    <AgThemeTodix>
      <AgGridReact
        localeText={agGridLocale}
        ref={gridRef}
        rowModelType="infinite"
        cacheBlockSize={1000} //TODO: this need to be fixed
        cacheOverflowSize={2}
        maxBlocksInCache={10}
        pagination={false}
        paginationAutoPageSize={false}
        rowHeight={55}
        defaultColDef={{
          resizable: true,
          filter: false,
          sortable: false,
          width: 120,
          minWidth: 40,
        }}
        context={{
          path,
          onCellClickPath,
          onRemoveItem,
          getExtraOptions,
          fetchExtraOptions,
          options,
          appConfig,
        }}
        components={{
          actionRenderer: ListActionRenderer,
          avatarRenderer: AvatarRenderer,
          ecosystemRenderer: EcosystemRenderer,
          progressRenderer: ProgressBarRenderer,
          genderRenderer: GenderRenderer,
          dateRenderer: DateRenderer,
          userRenderer: UserRenderer,
          relationSelectRenderer: RelationSelectRenderer,
          timestampRenderer: TimestampRenderer,
          moneyValueRenderer: MoneyValueRenderer,
          ecosystemFilter: EcosystemFilter,
          emailFilter: EmailFilter,
          activeStatusFilter: ActiveStatusFilter,
          categoryFilter: CategoryFilter,
          cityFilter: CityFilter,
          creatingUserFilter: CreatingUserFilter,
          documentNameFilter: DocumentNameFilter,
          documentTypeFilter: DocumentTypeFilter,
          phoneNumberFilter: PhoneNumberFilter,
          progressFilter: ProgressFilter,
          relatedContactFilter: RelatedContactFilter,
          tagsFilter: TagsFilter,
          uploadingUserFilter: UploadingUserFilter,
          ...renderers,
        }}
        rowSelection={'single'}
        onModelUpdated={onModelUpdated}
        onSelectionChanged={onSelectionChanged}
        onCellClicked={onCellClicked}
        onRowClicked={onRowClicked}
        {...props}
        onGridReady={onGridReady}
        isRowSelectable={() => false} //TODO: remove to get back to side view
      >
        {columns}
      </AgGridReact>
    </AgThemeTodix>
  );
};

export default List;
