import React, { FC } from 'react';
import { Avatar, Form, Input, Space } from 'antd';
import { firebase } from '@services/Firebase';
import { FormattedMessage } from 'react-intl';

const { Item } = Form;

const User: FC = () => {
  const user = firebase.auth.currentUser;
  return (
    <Item
      name="user"
      label={
        <FormattedMessage id="warehouse.create.user" defaultMessage="User" />
      }
    >
      <Space size="small" direction="horizontal">
        <Avatar shape="circle" src={user?.photoURL} size="small" />
        <span>{user?.displayName}</span>
      </Space>
      <Input value={user?.uid} readOnly hidden />
    </Item>
  );
};

export default User;
