import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { FileApp } from '@apps/AppTypes';
import { DIRECT_SALES_CLIENT_COLLECTION_NAME } from '@apps/directSalesClient/services/namespace';

export default {
  todixId: 'directSalesClient',
  link: '/app/directSalesClient',
  name: 'directSalesClient',
  entityType: 'directSalesClient',
  collectionName: DIRECT_SALES_CLIENT_COLLECTION_NAME,
  localizationPath: 'directSalesClient/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  hidden: true,
} as FileApp;
