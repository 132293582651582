import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Purchase } from '@apps/purchases/services';
import AppConfig, { PURCHASES_ACTION } from '@apps/purchases';
import { supportedFileTypes } from '@apps/purchases/settings/rangeOfNumbers/consts';
import { Spin, Form, Button, Space } from 'antd';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'antd/lib/form/Form';
import { useSelector } from 'react-redux';
import {
  checkUserPermissions,
  getActiveEcosystemsForApp,
} from '@components/EcosystemIndicator/store';
import { rootStore, RootStore } from '@core/store';
import {
  getPurchaseOperationsForPurchase,
  PurchaseOperation,
} from '@apps/purchases/services/purchaseOperation';
import { useCustomEventListener } from '@core/services';
import { getEcosystemTaxCategories } from '@apps/finance/services/taxCategories';
import { getPlatformTaxRates } from '@services/dictionary';
import getPurchase from '@apps/purchases/services/getPurchase';
import { getRangeOfNumbersByEcosystem } from '@services/rangeOfNumbers';
import {
  ButtonContainer,
  PageViewDrawer,
  RightColumn,
  ViewLayout,
} from './styles.sc';
import PagePreview from '@apps/purchases/mainMenu/components/PagePreview';
import { ArrowRightOutlined } from '@ant-design/icons';
import SelectedEcosystem from '@components/SelectedEcosystem';
import StepType from '@apps/purchases/mainMenu/components/StepType';
import PurchaseCreatorSettings from '@apps/purchases/mainMenu/components/PurchaseCreatorSettings';

const { Item } = Form;

type PurchaseCreatorProps = {
  purchaseId?: string;
  purchase?: Purchase | null;
  ecosystemId?: string;
  action: PURCHASES_ACTION;
};

const allowingTypes = Object.keys(supportedFileTypes);

const PurchaseCreator: FC<PurchaseCreatorProps> = ({
  purchaseId,
  ecosystemId: passedEcosystemId,
  action,
}) => {
  const asDraft = useRef(false);
  const history = useHistory();
  const intl = useIntl();
  const [form] = useForm();
  const activeEcosystems = useSelector((state) =>
    getActiveEcosystemsForApp(state as RootStore, AppConfig.todixId, action),
  );
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [rangeOfNumbers, setRangeOfNumbers] = useState<any>(null);
  const [relatedSteps, setRelatedSteps] = useState<PurchaseOperation[]>([]);
  const [prevState, setPrevState] = useState<Purchase | null>(null);

  useEffect(() => {
    if (passedEcosystemId) {
      form.setFieldsValue({
        ecosystem: passedEcosystemId,
      });
    }
  }, [form, passedEcosystemId]);

  const canProceed = checkUserPermissions(
    rootStore.getState(),
    AppConfig.todixId,
    'update-purchases' as PURCHASES_ACTION,
    passedEcosystemId || '',
  );

  useCustomEventListener('setPurchaseStep', (step: any) => {
    if (step && step?.type) {
      const relatedStep = relatedSteps.find((s) => s.step === step.type);
      if (relatedStep) {
        const values = {
          type: relatedStep.step,
          status: relatedStep.status,
          stepId: relatedStep.id,
        };
        form.setFieldsValue({
          ...form.getFieldsValue(),
          ...values,
        });
      } else {
        const values = {
          type: step.type,
          status: 'empty',
          stepId: undefined,
        };
        form.setFieldsValue({
          ...form.getFieldsValue(),
          ...values,
        });
      }
    }
  });

  const setTaxCategoriesForEcosystem = useCallback(
    (ecosystemId) => {
      if (ecosystemId) {
        getEcosystemTaxCategories(ecosystemId)?.then(async (taxCategories) => {
          if (taxCategories.length > 0) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              taxCategories,
            });
          } else {
            const platformTaxRates = await getPlatformTaxRates();
            form.setFieldsValue({
              ...form.getFieldsValue(),
              taxCategories: platformTaxRates.map(
                (tax) =>
                  ({
                    id: tax.value,
                    amount: tax.value,
                    label: tax[intl.locale],
                  } as unknown as TaxCategory),
              ),
            });
          }
        });
      }
    },
    [form, intl.locale],
  );

  useEffect(() => {
    if (purchaseId) {
      setLoading(true);
      getPurchase(purchaseId)
        .then((purchase) => {
          if (purchase) {
            setPrevState(purchase);
            setTaxCategoriesForEcosystem(purchase.ecosystem);
          }
          getPurchaseOperationsForPurchase(
            purchaseId,
            purchase.ecosystem,
            action,
          )
            .then((operations) => {
              let latestOperation: Partial<PurchaseOperation> = {
                step: 'quotation',
                status: 'empty',
              };
              if (operations.length > 0) {
                latestOperation = operations.sort(
                  (a, b) => (b.timestamp as number) - (a.timestamp as number),
                )[0];
                setRelatedSteps(operations);
              }
              const values = {
                ...purchase,
                ecosystem: purchase.ecosystem,
                products: purchase.products,
                relatedContact: purchase.contact,
                relatedContactSnapshot: purchase.contactSnapshot,
                type: latestOperation.step,
                status: latestOperation.status,
                stepId: latestOperation.id,
                purchaseId,
              };
              form.setFieldsValue({
                ...form.getFieldsValue(),
                ...values,
              });
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [action, form, purchaseId, setTaxCategoriesForEcosystem]);

  const setNumbers = useCallback((ecosystem) => {
    if (ecosystem) {
      setLoading(true);
      getRangeOfNumbersByEcosystem(ecosystem)
        ?.then((el) => {
          setRangeOfNumbers(el);
        })
        ?.finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleCancel = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleSubmit = useCallback(
    (values: any) => {
      console.warn(values);
      console.warn(history);
      console.warn(activeEcosystems);
      console.warn(rangeOfNumbers);
      console.warn(prevState);
      if (!canProceed) {
        return;
      }
    },
    [canProceed, history, activeEcosystems, rangeOfNumbers, prevState],
  );

  return (
    <Spin spinning={loading}>
      <div>
        <AppEntityForm
          elId={purchaseId}
          appId={AppConfig.todixId}
          onSubmit={handleSubmit}
          autoComplete="off"
          initialValues={
            passedEcosystemId
              ? {
                  ecosystem: passedEcosystemId,
                  products: [],
                  relatedContact: undefined,
                  relatedContactSnapshot: undefined,
                  deliveryTerms: undefined,
                }
              : {}
          }
          name="salesCreator"
          providedForm={form}
          readOnly={!canProceed}
          disabledEditButton={!canProceed}
          disabledSubmitButton={!canProceed}
          style={{ height: `calc(100vh - 240px)` }}
          hideButtons={true}
        >
          {() => {
            return (
              <div>
                <ViewLayout>
                  <Item noStyle shouldUpdate={true}>
                    {({ getFieldsValue }) => (
                      <PagePreview
                        values={getFieldsValue()}
                        readOnly={!canProceed}
                      />
                    )}
                  </Item>
                </ViewLayout>
                <PageViewDrawer
                  closable
                  visible={collapsed}
                  destroyOnClose={false}
                  onClose={() => setCollapsed(false)}
                  placement="right"
                  getContainer={false}
                  mask={false}
                  closeIcon={
                    <Button
                      icon={<ArrowRightOutlined />}
                      type="primary"
                    ></Button>
                  }
                >
                  <RightColumn>
                    <SelectedEcosystem
                      disabled={!!purchaseId || !canProceed}
                      appId={AppConfig.todixId as string}
                      onChange={(ecosystemId: any) => {
                        setTaxCategoriesForEcosystem(ecosystemId);
                        setNumbers(ecosystemId);
                        form.setFieldsValue({
                          ...form.getFieldsValue(),
                          products: [],
                          relatedContact: undefined,
                          relatedContactSnapshot: undefined,
                          deliveryTerms: undefined,
                        });
                      }}
                      action={'view-purchases' as PURCHASES_ACTION}
                    />
                    <StepType
                      allowingTypes={allowingTypes}
                      relatedSteps={relatedSteps}
                    />
                    <ButtonContainer>
                      <Item shouldUpdate noStyle>
                        {({ getFieldValue }) => {
                          const status = getFieldValue('status');
                          const disabled =
                            loading || !['empty', 'pending'].includes(status);
                          const draftShown = status !== 'success';
                          return (
                            <>
                              <Button
                                type="default"
                                htmlType="button"
                                disabled={loading || !canProceed}
                                onClick={handleCancel}
                              >
                                {intl.formatMessage({
                                  id: 'button.cancel',
                                  defaultMessage: 'Cancel',
                                })}
                              </Button>
                              <Space direction="horizontal">
                                {draftShown && (
                                  <Button
                                    type="dashed"
                                    htmlType="submit"
                                    disabled={loading || !canProceed}
                                    onClick={() => {
                                      asDraft.current = true;
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: 'button.save.draft',
                                      defaultMessage: 'Save as draft',
                                    })}
                                  </Button>
                                )}
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  disabled={disabled || !canProceed}
                                >
                                  {intl.formatMessage({
                                    id: 'button.save',
                                    defaultMessage: 'Save',
                                  })}
                                </Button>
                              </Space>
                            </>
                          );
                        }}
                      </Item>
                    </ButtonContainer>
                  </RightColumn>
                </PageViewDrawer>
                {!collapsed && (
                  <PurchaseCreatorSettings
                    onCollapseClick={() => setCollapsed(true)}
                  />
                )}
                <Form.Item name="docNumber" hidden />
                <Form.Item name="relatedContactSnapshot" hidden />
                <Form.Item name="products" hidden />
                <Form.Item name="taxCategories" hidden />
                <Form.Item name="status" hidden />
                <Form.Item name="stepId" hidden />
                <Form.Item name="purchaseId" hidden />
              </div>
            );
          }}
        </AppEntityForm>
      </div>
    </Spin>
  );
};

export default PurchaseCreator;
