import styled from 'styled-components';
import Button, { ButtonProps } from 'antd/lib/button';

// todo: icon position should be handled by props (breaking change for platform)
export const ButtonStyle: typeof Button = styled(Button)<ButtonProps>`
  && {
    display: flex;
    align-items: center;
    border-radius: 2px;
    width: auto;
    flex-direction: ${({ size }) => (size === 'large' ? 'row-reverse' : 'row')};

    span {
      line-height: 1.6;
    }

    .anticon {
      margin-left: ${({ size }) => (size === 'large' ? 8 : 0)}px;
      display: flex;
      align-items: flex-end;
    }
  }
`;
