import { RootStore, rootStore } from '@core/store';

export const isAppActivatedInEcosystem = (
  ecosystem: Ecosystem,
  todixId: string,
) => {
  const state: RootStore = rootStore.getState();
  const appId =
    state.stripeProducts.find((app) => app.todixId === todixId)?.id || '';
  if (ecosystem && state.licences.data && !state.licences.isLoading) {
    const licences = state.licences.data;
    const licence = Object.values(licences).find(
      (el) =>
        el.ecosystem === ecosystem.id &&
        el.status === 'active' &&
        el.appId === appId,
    );
    return !!licence;
  }
  return false;
};

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
};

export const toDataURL = (url: string): Promise<any> =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );
