import React, { FC } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import Upload from '@apps/documents/mainMenu/Upload';
import DocumentsAppConfig from '../index';

type Props = RouteComponentProps & {
  match: {
    params: {
      id: string;
    };
  };
};

const Create: FC<Props> = (_props) => {
  const history = useHistory();
  return (
    <Upload
      onUploadSuccess={() => {
        history.push(`/app/${DocumentsAppConfig.todixId}/inbox`);
      }}
    />
  );
};

export default Create;
