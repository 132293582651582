import React, { FC } from 'react';
import ProductName from '@apps/purchases/mainMenu/components/individualRequest/ProductName';
import ProductNumber from '@apps/purchases/mainMenu/components/individualRequest/ProductNumber';
import ToBeStocked from '@apps/purchases/mainMenu/components/individualRequest/ToBeStocked';
import PreferredSupplier from '@apps/purchases/mainMenu/components/individualRequest/PreferredSupplier';
import ShortDescription from '@apps/purchases/mainMenu/components/individualRequest/ShortDescription';
import Description from '@apps/purchases/mainMenu/components/individualRequest/Description';
import SelectedUnit from '@apps/purchases/mainMenu/components/individualRequest/SelectedUnit';
import NeedDate from '@apps/purchases/mainMenu/components/individualRequest/NeedDate';
import NeedAmount from '@apps/purchases/mainMenu/components/individualRequest/NeedAmount';
import Link from '@apps/purchases/mainMenu/components/individualRequest/Link';
import { PURCHASES_ACTION } from '@apps/purchases';

type LeftColumnProps = {
  ecosystemId?: string;
  readOnly: boolean;
  namePrefix?: string | number;
  action: PURCHASES_ACTION;
  id?: string;
};

const LeftColumn: FC<LeftColumnProps> = ({
  ecosystemId,
  readOnly,
  namePrefix,
  action,
  id,
}) => {
  return (
    <div>
      <ProductName readOnly={readOnly} namePrefix={namePrefix} />
      <ProductNumber
        readOnly={readOnly}
        namePrefix={namePrefix}
        action={action}
        id={id}
      />
      <ToBeStocked readOnly={readOnly} />
      <PreferredSupplier readOnly={readOnly} />
      <ShortDescription readOnly={readOnly} namePrefix={namePrefix} />
      <Description namePrefix={namePrefix} readOnly={readOnly} />
      <SelectedUnit ecosystemId={ecosystemId} namePrefix={namePrefix} />
      <NeedDate readOnly={readOnly} />
      <NeedAmount readOnly={readOnly} namePrefix={namePrefix} />
      <Link readOnly={readOnly} namePrefix={namePrefix} />
    </div>
  );
};

export default LeftColumn;
