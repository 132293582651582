import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type ProductNumberProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const ProductNumber: FC<ProductNumberProps> = ({ namePrefix, readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name={
        namePrefix !== undefined
          ? [namePrefix, `productNumber`]
          : `productNumber`
      }
      label={
        <FormattedMessage
          id="products.form.productnumber.label"
          defaultMessage="Product number"
        />
      }
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="products.form.productnumber.error.missing"
              defaultMessage="Product number is missing"
            />
          ),
        },
        {
          type: 'string',
          max: 40,
          message: (
            <FormattedMessage
              id="products.form.productnumber.error"
              defaultMessage="Product number cannot be longer than 40 characters"
            />
          ),
        },
      ]}
    >
      <Input
        name={`${namePrefix}productNumber`}
        placeholder={intl.formatMessage({
          id: 'products.form.productnumber.placeholder',
          defaultMessage: 'Product number...',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default ProductNumber;
