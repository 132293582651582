import React, { FC } from 'react';
import { SummaryContainer } from '@apps/purchases/mainMenu/components/styles.sc';
import {
  SummaryRow,
  SummaryRowCell,
  SummaryRowCellFinal,
  SummaryRowFinal,
} from '@apps/sales/mainMenu/components/SaleCreator/components/styles.sc';
import { StyledMoneyInput } from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import { PageViewElementProps } from '@apps/purchases/mainMenu/operationSteps/commonTypes';

const PositionsSummary: FC<PageViewElementProps> = ({ intl, bucket }) => {
  const positions =
    bucket?.positions.filter((el) => el.markToRemove !== true) || [];

  const sumNet = positions.reduce(
    (sum, position) =>
      (sum += position.needAmount * Number(position.unitAmount)),
    0,
  );

  const taxes = positions.reduce((acc: Record<any, number>, item) => {
    if (
      item.tax !== undefined &&
      item.unitAmount !== undefined &&
      item.needAmount !== undefined
    ) {
      const prodsInTax = acc[item.tax];
      const net = item.needAmount * Number(item.unitAmount);
      const totalTaxValue = net * (item.tax / 100);
      return {
        ...acc,
        [item.tax]:
          prodsInTax !== undefined ? prodsInTax + totalTaxValue : totalTaxValue,
      };
    }
    return acc;
  }, {});

  const total =
    sumNet + Object.values(taxes).reduce((sum, el) => (sum += el), 0);

  return (
    <SummaryContainer>
      <div />
      <div>
        <SummaryRow>
          <SummaryRowCell>
            {intl.formatMessage({
              id: 'sales.createForm.sumNet.label',
              defaultMessage: 'Sum net',
            })}
          </SummaryRowCell>
          <SummaryRowCell>
            <StyledMoneyInput value={sumNet} disabled={true} />
          </SummaryRowCell>
        </SummaryRow>
        {Object.keys(taxes)
          .sort()
          .map((taxKey, index) => {
            return (
              <SummaryRow key={index}>
                <SummaryRowCell>
                  {`${intl.formatMessage({
                    id: 'sales.salesTab.pricePreview.tax',
                    defaultMessage: 'Tax',
                  })} (${taxKey} %)`}
                </SummaryRowCell>
                <SummaryRowCell>
                  <StyledMoneyInput value={taxes[taxKey]} disabled={true} />
                </SummaryRowCell>
              </SummaryRow>
            );
          })}
        <SummaryRowFinal>
          <SummaryRowCellFinal>
            {intl.formatMessage({
              id: 'sales.salesTab.pricePreview.total',
              defaultMessage: 'Total',
            })}
          </SummaryRowCellFinal>
          <SummaryRowCell>
            <StyledMoneyInput
              value={total}
              disabled={true}
              style={{ fontSize: '14px', fontWeight: 'bold' }}
            />
          </SummaryRowCell>
        </SummaryRowFinal>
      </div>
    </SummaryContainer>
  );
};

export default PositionsSummary;
