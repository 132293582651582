import { styled } from '@styles/themes';
import { Image, Menu, Switch, Typography } from 'antd';
import { MenuItem } from '@todix/ui-components';
import { Text } from '@todix/ui-components';

const { SubMenu } = Menu;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 8px;
  border-radius: 2px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
`;

export const StyledSubMenu = styled(SubMenu)`
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;

    span {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
`;

export const EcosystemImage = styled(Image)`
  margin-right: 10px;
  width: ${(props) => props.width}px;
`;

export const StyledTextTitle = styled(Text)`
  margin-left: 4px;
`;

export const EcosystemFilterSwitch = styled(Switch)`
  margin-left: 38px;
  margin-right: 17px;
`;

export const InboxFilterSwitch = styled(Switch)`
  position: absolute;
  right: 16px;
  margin-left: 38px;
  margin-right: 17px;
`;

export const InboxFilterText = styled(Typography)`
  font-size: 14px;
  margin-left: 10px;
  margin-right: 54px;
`;

export const InboxMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
`;
