import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Switch, Form } from 'antd';
import { styled } from '@styles/themes';
import { FilterProps } from '@components/List/CustomsFilters/useInputFilter';
import { useIntl } from 'react-intl';
import { FilterActiveStatusObject } from '@services/filtering';
import { FilterWrapper } from '@components/List/CustomsFilters/styles';

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

export default forwardRef((props: FilterProps, ref) => {
  const [input, setInput] = useState<boolean | null>(true);
  const intl = useIntl();

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return input !== null;
      },

      getModel() {
        return input !== null
          ? ({
              filter: input,
              filterType: 'active',
              type: 'contains',
            } as FilterActiveStatusObject)
          : null;
      },

      getModelAsString() {
        return '';
      },

      setModel(model: any) {
        setInput(model?.input || '');
      },
    };
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [input]);

  const onValueChange = (value: boolean) => {
    setInput(value);
    props.filterChangedCallback({
      input,
    });
  };

  return (
    <FilterWrapper id="activeStatusFilter">
      <Form>
        <FormItem
          label={intl.formatMessage({
            id: 'settings.list.header.status',
            defaultMessage: 'Status',
          })}
        >
          <Switch
            onChange={onValueChange}
            title={intl.formatMessage({
              id: 'products.columns.isactive.header',
              defaultMessage: 'Active / Inactive',
            })}
            checkedChildren={intl.formatMessage({
              id: 'products.sidecontent.generalinformation.isactive.active',
              defaultMessage: 'Active',
            })}
            unCheckedChildren={intl.formatMessage({
              id: 'products.sidecontent.generalinformation.isactive.inactive',
              defaultMessage: 'Inactive',
            })}
          />
        </FormItem>
      </Form>
    </FilterWrapper>
  );
});
