import { lazy } from 'react';
import { ShopOutlined } from '@ant-design/icons';

const ConsultantLevel = lazy(
  () =>
    import(
      /* webpackChunkName: "directSalesApp" */ '@apps/directSales/settings/ConsultantLevel'
    ),
);

const Commissions = lazy(
  () =>
    import(
      /* webpackChunkName: "directSalesApp" */ '@apps/directSales/settings/Commissions'
    ),
);

const DirectSalesImport = lazy(
  () =>
    import(
      /* webpackChunkName: "directSalesApp" */ '@apps/directSales/settings/Import'
    ),
);

export default {
  appSubmenuName: 'app.directsales',
  icon: ShopOutlined,
  sections: [
    {
      link: 'consultantLevel',
      title: 'consultants.columns.consultantLevel.label',
      component: ConsultantLevel,
    },
    {
      link: 'commissions',
      title: 'consultants.commissions',
      component: Commissions,
    },
    {
      link: 'consultantImport',
      title: 'Import',
      component: DirectSalesImport,
    },
  ],
};
