import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import { nameValueGetter } from '@apps/documents/mainMenu/Archive/valueGetters';
import {
  CheckboxSelectionCallbackParams,
  ColSpanParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';

const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="name"
      checkboxSelection={({ data }: CheckboxSelectionCallbackParams) => !!data}
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      headerName={intl.formatMessage({
        id: 'documents.inbox.columns.name.header',
        defaultMessage: 'Name',
      })}
      valueGetter={({ data }: ValueGetterParams) => nameValueGetter(data, intl)}
      filterValueGetter={({ data }: ValueGetterParams) =>
        nameValueGetter(data, intl)
      }
    />
    <AgGridColumn
      field="ecosystem"
      cellRenderer="ecosystemRenderer"
      maxWidth={135}
      headerName={intl.formatMessage({
        id: 'documents.inbox.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
    />
    <AgGridColumn
      field="type"
      sortable
      headerName={intl.formatMessage({
        id: 'documents.inbox.columns.type.header',
        defaultMessage: 'Type',
      })}
    />
    <AgGridColumn
      field="category"
      sortable
      headerName={intl.formatMessage({
        id: 'documents.inbox.columns.category.header',
        defaultMessage: 'Category',
      })}
    />
    <AgGridColumn
      field="tags"
      sortable
      headerName={intl.formatMessage({
        id: 'documents.inbox.columns.tags.header',
        defaultMessage: 'Tags',
      })}
    />
    <AgGridColumn
      field="creationDate"
      headerName={intl.formatMessage({
        id: 'documents.inbox.columns.creationdate.header',
        defaultMessage: 'Creation date',
      })}
    />
    <AgGridColumn cellRenderer="actionRenderer" maxWidth={70} />
  </>
);

export default columns;
