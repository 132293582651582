import * as React from 'react';
import { DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl';

const { Option } = Select;

const disabledDate = (current: moment.Moment) =>
  current && current > moment().endOf('day');

const PersonInfo: React.FC = () => {
  const { readOnly } = useFormContext();
  const intl = useIntl();
  return (
    <>
      <Form.Item
        name="lastName"
        label={
          <FormattedMessage
            id="detailcontactform.personinfo.lastname.label"
            defaultMessage="Last name"
          />
        }
        required={true}
        rules={[
          {
            required: true,
            type: 'string',
            max: 40,
            message: (
              <FormattedMessage
                id="detailcontactform.personinfo.lastname.error"
                defaultMessage="Last name cannot be longer than 40 characters"
              />
            ),
          },
        ]}
      >
        <Input
          name="lastName"
          placeholder={intl.formatMessage({
            id: 'detailcontactform.personinfo.lastname.placeholder',
            defaultMessage: 'Last name...',
          })}
          autoComplete="nope"
          readOnly={readOnly}
        />
      </Form.Item>
      <Form.Item
        name="firstName"
        label={
          <FormattedMessage
            id="detailcontactform.personinfo.firstname.label"
            defaultMessage="First name"
          />
        }
        rules={[
          {
            type: 'string',
            max: 40,
            message: (
              <FormattedMessage
                id="detailcontactform.personinfo.firstname.error"
                defaultMessage="First name cannot be longer than 40 characters"
              />
            ),
          },
        ]}
      >
        <Input
          name="firstName"
          placeholder={intl.formatMessage({
            id: 'detailcontactform.personinfo.firstname.placeholder',
            defaultMessage: 'First name...',
          })}
          autoComplete="nope"
          readOnly={readOnly}
        />
      </Form.Item>
      <Form.Item
        name="gender"
        label={
          <FormattedMessage
            id="detailcontactform.personinfo.gender.label"
            defaultMessage="Gender"
          />
        }
        required={false}
        rules={[
          {
            required: false,
            message: (
              <FormattedMessage
                id="detailcontactform.personinfo.gender.error"
                defaultMessage="Missing gender"
              />
            ),
          },
        ]}
      >
        <Select
          placeholder={
            <FormattedMessage
              id="detailcontactform.personinfo.gender.placeholder"
              defaultMessage="Gender"
            />
          }
          disabled={readOnly}
        >
          <Option value="female">
            <FormattedMessage
              id="detailcontactform.personinfo.gender.female"
              defaultMessage="Female"
            />
          </Option>
          <Option value="male">
            <FormattedMessage
              id="detailcontactform.personinfo.gender.male"
              defaultMessage="Male"
            />
          </Option>
          <Option value="diverse">
            <FormattedMessage
              id="detailcontactform.personinfo.gender.diverse"
              defaultMessage="Diverse"
            />
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="nickName"
        label={
          <FormattedMessage
            id="detailcontactform.personinfo.nickname.label"
            defaultMessage="Nick name"
          />
        }
        rules={[
          {
            type: 'string',
            max: 40,
            message: (
              <FormattedMessage
                id="detailcontactform.personinfo.nickname.error"
                defaultMessage="Nick name cannot be longer than 40 characters"
              />
            ),
          },
        ]}
      >
        <Input
          name="nickName"
          placeholder={intl.formatMessage({
            id: 'detailcontactform.personinfo.nickname.placeholder',
            defaultMessage: 'Nick name...',
          })}
          autoComplete="nope"
          readOnly={readOnly}
        />
      </Form.Item>
      <Form.Item
        name="employer"
        label={
          <FormattedMessage
            id="detailcontactform.personinfo.employer.label"
            defaultMessage="Employer"
          />
        }
        rules={[
          {
            type: 'string',
            max: 40,
            message: (
              <FormattedMessage
                id="detailcontactform.personinfo.employer.error"
                defaultMessage="Employer cannot be longer than 40 characters"
              />
            ),
          },
        ]}
      >
        <Input
          name="employer"
          placeholder={intl.formatMessage({
            id: 'detailcontactform.personinfo.employer.placeholder',
            defaultMessage: 'Nick name...',
          })}
          autoComplete="nope"
          readOnly={readOnly}
        />
      </Form.Item>
      <Form.Item
        name="department"
        label={
          <FormattedMessage
            id="detailcontactform.personinfo.department.label"
            defaultMessage="Department"
          />
        }
        rules={[
          {
            type: 'string',
            max: 40,
            message: (
              <FormattedMessage
                id="detailcontactform.personinfo.department.error"
                defaultMessage="Department cannot be longer than 40 characters"
              />
            ),
          },
        ]}
      >
        <Input
          name="department"
          placeholder={intl.formatMessage({
            id: 'detailcontactform.personinfo.department.placeholder',
            defaultMessage: 'Department...',
          })}
          autoComplete="nope"
          readOnly={readOnly}
        />
      </Form.Item>
      <Form.Item
        name="titlePosition"
        label={
          <FormattedMessage
            id="detailcontactform.personinfo.titleposition.label"
            defaultMessage="Title Position"
          />
        }
        rules={[
          {
            type: 'string',
            max: 40,
            message: (
              <FormattedMessage
                id="detailcontactform.personinfo.titleposition.error"
                defaultMessage="Title position cannot be longer than 40 characters"
              />
            ),
          },
        ]}
      >
        <Input
          name="titlePosition"
          placeholder={intl.formatMessage({
            id: 'detailcontactform.personinfo.titleposition.placeholder',
            defaultMessage: 'Title Position...',
          })}
          autoComplete="nope"
          readOnly={readOnly}
        />
      </Form.Item>
      <Form.Item
        name="birthDate"
        label={
          <FormattedMessage
            id="detailcontactform.personinfo.birthdate.label"
            defaultMessage="Birth date"
          />
        }
      >
        <DatePicker
          format={moment.defaultFormat}
          disabledDate={disabledDate}
          name="birthDate"
          disabled={readOnly}
          placeholder={intl.formatMessage({
            id: 'detailcontactform.personinfo.birthdate.placeholder',
            defaultMessage: 'Select date',
          })}
        />
      </Form.Item>
    </>
  );
};

export default PersonInfo;
