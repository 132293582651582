import { ApiOptions, put } from '../api';
import { RangeOfNumbersType } from '@services/rangeOfNumbers/namespace';
import { removeUndefinedProperties } from '@services/utils';
import { firebase } from '@services/Firebase';

export * from './namespace';

const COLLECTION_NAME = 'salesRangeOfNumbers';

export const addSalesRangeOfNumbers = (
  rangeOfNumbers: RangeOfNumbersType,
  id: string,
  options?: ApiOptions,
) =>
  put(
    `${COLLECTION_NAME}/${id}`,
    removeUndefinedProperties(rangeOfNumbers),
    options,
  );

export const getRangeOfNumbersByEcosystem = (
  id: string,
): Promise<any | null> | undefined => {
  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(id)
    .get()
    .then((doc) => doc.data() as RangeOfNumbersType);
};
