import React, { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import FetchedUser from '@components/FetchedUser';

const { Item } = Form;

const Creator: FC = () => {
  return (
    <Item noStyle={true} shouldUpdate={true}>
      {(form) => {
        return (
          <Item
            name="creator"
            label={
              <FormattedMessage
                id="products.form.creator.label"
                defaultMessage="Creator"
              />
            }
          >
            <FetchedUser id={form.getFieldValue('creator').uid || ''} />
          </Item>
        );
      }}
    </Item>
  );
};

export default Creator;
