import * as React from 'react';
import { Modal } from 'antd';
import { useIntl } from 'react-intl/lib';

type ReminderConfig = {
  isDirty: boolean;
  onCancel?(e: React.MouseEvent<HTMLElement>): void;
};

type UseReminder = (config: ReminderConfig) => {
  handleCancel(e: React.MouseEvent<HTMLElement>): void;
};

const useReminder: UseReminder = ({ isDirty, onCancel }) => {
  const [showPopupReminder, setShowPopupReminder] = React.useState(isDirty);
  const intl = useIntl();

  React.useEffect(() => {
    if (isDirty) {
      setShowPopupReminder(isDirty);
    }
  }, [isDirty, showPopupReminder]);

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    if (!showPopupReminder) {
      onCancel && onCancel(e);
      return;
    }

    e.persist();
    e.preventDefault();
    e.stopPropagation();

    Modal.confirm({
      content: intl.formatMessage({
        id: 'discard.confirm',
        defaultMessage: 'Discard changes?',
      }),
      onOk: () => {
        setShowPopupReminder(false);
        onCancel && onCancel(e);
      },
      getContainer: () => {
        const mainElement = document
          .getElementsByClassName('fullscreen')
          .item(0) as HTMLElement;
        return mainElement || document.body;
      },
    });
  };

  return {
    handleCancel,
  };
};

export default useReminder;
