import React, { FC, useState } from 'react';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import Create from '@apps/contacts/mainMenu/Create';
import { Contact } from '@apps/contacts/services';

import 'ant-design-draggable-modal/dist/index.css';

type Props = {
  OnClickEl: any;
  onSubmit: (contact: Contact) => void;
};

const AddNewContactWidget: FC<Props> = ({ OnClickEl, onSubmit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <OnClickEl onClick={showModal} />
      <DraggableModalProvider>
        <DraggableModal
          visible={isModalVisible}
          footer={null}
          onCancel={() => setIsModalVisible(false)}
        >
          <Create
            shouldRedirect={false}
            onSubmit={(contact) => {
              setIsModalVisible(false);
              if (onSubmit) {
                onSubmit(contact);
              }
            }}
            onCancel={() => {
              setIsModalVisible(false);
            }}
          />
        </DraggableModal>
      </DraggableModalProvider>
      '
    </>
  );
};

export default AddNewContactWidget;
