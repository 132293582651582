import React from 'react';
import intl from '../../../../../../../../intl';
import { Form } from 'antd';
import MoneyInputInFormItem from '@components/MoneyField/MoneyInputInFormItem';
const { Item } = Form;

const UnitAmount: React.FC = () => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const currency = getFieldValue('currency');
        return (
          <Item
            name="unitAmount"
            label={intl.formatMessage({
              id: 'sales.salesTab.unitAmount.label',
              defaultMessage: 'Price',
            })}
            required
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'sales.salesTab.unitAmount.validationError',
                  defaultMessage: 'Please enter price',
                }),
              },
            ]}
          >
            <MoneyInputInFormItem currency={currency} />
          </Item>
        );
      }}
    </Item>
  );
};

export default UnitAmount;
