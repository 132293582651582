import * as React from 'react';
import { DatePicker, Form, Input } from 'antd';
import moment from 'moment';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl';

const disabledDate = (current: moment.Moment) =>
  current && current > moment().endOf('day');

const CompanyInfo: React.FC = () => {
  const { readOnly } = useFormContext();
  const intl = useIntl();

  return (
    <>
      <Form.Item
        name="companyName"
        label={
          <FormattedMessage
            id="detailcontactform.companyinfo.companyname.label"
            defaultMessage="Company name"
          />
        }
        required={true}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage
                id="detailcontactform.companyinfo.companyname.error.required"
                defaultMessage=" Missing company name"
              />
            ),
          },
          {
            type: 'string',
            max: 40,
            message: (
              <FormattedMessage
                id="detailcontactform.companyinfo.companyname.error.max"
                defaultMessage="Company name cannot be longer than 40 characters"
              />
            ),
          },
        ]}
      >
        <Input
          name="companyName"
          placeholder={intl.formatMessage({
            id: 'detailcontactform.companyinfo.companyname.placeholder',
            defaultMessage: 'Company name',
          })}
          readOnly={readOnly}
        />
      </Form.Item>
      <Form.Item
        name="foundationDate"
        label={
          <FormattedMessage
            id="detailcontactform.companyinfo.foundationdate.label"
            defaultMessage="Foundation date"
          />
        }
      >
        <DatePicker
          format={moment.defaultFormat}
          disabledDate={disabledDate}
          name="foundationDate"
          disabled={readOnly}
          placeholder={intl.formatMessage({
            id: 'detailcontactform.companyinfo.foundationdate.placeholder',
            defaultMessage: 'Select date',
          })}
        />
      </Form.Item>
    </>
  );
};

export default CompanyInfo;
