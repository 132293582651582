const getWords = (name: string) => {
  const words: string[] = [];
  let cur = '';
  name.split('').forEach((letter) => {
    cur += letter;
    words.push(cur);
  });

  return words;
};

export const generateKeywords = (value?: string) => {
  if (!value) {
    return [];
  }

  const lowered = value.toLowerCase();
  const parts = lowered.split(' ');

  if (parts.length > 1) {
    return [
      ...new Set([
        '',
        ...getWords(`${parts[0]} ${parts[1]}`),
        ...getWords(`${parts[1]} ${parts[0]}`),
      ]),
    ];
  }

  return getWords(parts[0]);
};
