import * as React from 'react';
import { FullScreenModeIconWrapper } from './FullScreenMode.sc';
import { Icon } from '@todix/ui-components';
import { FullScreenHandle } from 'react-full-screen';

type Props = {
  fullScreenHandler: FullScreenHandle;
};

export const FullScreenMode: React.FC<Props> = ({ fullScreenHandler }) => {
  if (!fullScreenHandler) {
    return null;
  }
  return (
    <FullScreenModeIconWrapper>
      {fullScreenHandler.active ? (
        <Icon.FullscreenExitOutlined
          style={{ fontSize: 24 }}
          onClick={fullScreenHandler.exit}
        />
      ) : (
        <Icon.FullscreenOutlined
          style={{ fontSize: 24 }}
          onClick={fullScreenHandler.enter}
        />
      )}
    </FullScreenModeIconWrapper>
  );
};
