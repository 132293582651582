import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import { ColSpanParams } from 'ag-grid-community/dist/lib/entities/colDef';

export const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="ecosystem"
      cellRenderer={'ecosystemRenderer'}
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      headerName={intl.formatMessage({
        id: 'consultants.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      filter="ecosystemFilter"
    />
    <AgGridColumn
      field="partyDate"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'parties.columns.partyDate.header',
        defaultMessage: 'Party Date',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="HostRef"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'parties.columns.HostRef.header',
        defaultMessage: 'Host',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantRef"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'parties.personinfo.consultantRef.label',
        defaultMessage: 'Consultant Level',
      })}
    />

    <AgGridColumn
      cellRenderer={'actionRenderer'}
      maxWidth={70}
      filter={false}
    />
  </>
);
