import React, { FC } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { IntlShape } from 'react-intl';
import { getMoneyValue } from '@components/MoneyInput';
import { currencySigns } from '@services/currency';
import { ProdTotalsSummary } from '@apps/purchases/services';

type Props = {
  totals: ProdTotalsSummary;
  intl: IntlShape;
};

const styles = StyleSheet.create({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 11,
    marginTop: '10px',
  },
  rowBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  rowTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    backgroundColor: '#fafafa',
  },
  cell: {
    display: 'flex',
    fontWeight: 500,
    padding: '4px 4px',
    height: '32px',
  },
  cellTotal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700,
    padding: '4px 4px',
    height: '32px',
  },
});

const ProductsSummaryPDF: FC<Props> = ({ totals, intl }) => {
  const { totalNetPrice, taxes, totalDiscountedPrice, total } = totals;
  return (
    <View style={styles.flexContainer}>
      <View style={styles.rowBetween}>
        <Text style={styles.cell}>
          {intl.formatMessage({
            id: 'sales.createForm.sumNet.label',
            defaultMessage: 'Sum net',
          })}
        </Text>
        <Text style={styles.cell}>
          {getMoneyValue({
            value: totalNetPrice ? `${totalNetPrice}` : '',
            prefix: currencySigns['EUR'],
          })?.result || ''}
        </Text>
      </View>
      {Object.keys(taxes)
        .sort()
        .map((taxKey, index) => {
          return (
            <View style={styles.rowBetween} key={index}>
              <Text style={styles.cell}>
                {`${intl.formatMessage({
                  id: 'sales.salesTab.pricePreview.tax',
                  defaultMessage: 'Tax',
                })} (${taxKey} %)`}
              </Text>
              <Text style={styles.cell}>
                {getMoneyValue({
                  value: taxes[taxKey] ? `${taxes[taxKey]}` : '',
                  prefix: currencySigns['EUR'],
                })?.result || ''}
              </Text>
            </View>
          );
        })}
      <View style={styles.rowBetween}>
        <Text style={styles.cell}>
          {intl.formatMessage({
            id: 'sales.createForm.discountedPrice.label',
            defaultMessage: 'Discounted price',
          })}
        </Text>
        <Text style={styles.cell}>
          {getMoneyValue({
            value: totalDiscountedPrice ? `${totalDiscountedPrice}` : '',
            prefix: currencySigns['EUR'],
          })?.result || ''}
        </Text>
      </View>
      <View style={styles.rowTotal}>
        <Text style={styles.cellTotal}>
          {intl.formatMessage({
            id: 'sales.salesTab.pricePreview.total',
            defaultMessage: 'Total',
          })}
        </Text>
        <Text style={styles.cellTotal}>
          {getMoneyValue({
            value: total ? `${total}` : '',
            prefix: currencySigns['EUR'],
          })?.result || ''}
        </Text>
      </View>
    </View>
  );
};

export default ProductsSummaryPDF;
