import React, { useMemo } from 'react';
import { ProductPrice } from '@apps/products/services';
import { FormattedMessage } from 'react-intl';
import { StyledSwitch } from '@pages/settings/components';
import { Tooltip } from 'antd';

type Props = {
  price: ProductPrice;
  handlePriceChange(price: ProductPrice): void;
};

const PriceStatus: React.FC<Props> = ({ price, handlePriceChange }) => {
  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="sales.salesTab.isActive.tooltip"
          defaultMessage="Activate to make this price available to be used for product"
        />
      }
    >
      <StyledSwitch
        key={price.id}
        checked={price.isActive}
        onChange={() =>
          handlePriceChange({
            ...price,
            isActive: !price.isActive,
          } as ProductPrice)
        }
        checkedChildren={activated}
        unCheckedChildren={deactivated}
      />
    </Tooltip>
  );
};

export default PriceStatus;
