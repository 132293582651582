import React, { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Item } = Form;

type Props = {
  readOnly: boolean;
};

const PreferredSupplier: FC<Props> = () => {
  return (
    <Item
      name="preferredSupplierId"
      label={
        <FormattedMessage
          id="preferred.supplier"
          defaultMessage="Preferred Supplier"
        />
      }
    ></Item>
  );
};

export default PreferredSupplier;
