import React, { FC, useState } from 'react';
import { Alert } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import { Filter } from '@apps/documents/mainMenu/Upload';
import {
  InboxCard,
  DropZoneArea,
  UploadText,
  InboxIcon,
  EcosystemImage,
  UploadHint,
  InboxIconWithName,
  ErrorWrapper,
} from '@apps/documents/mainMenu/Upload/UploadInboxCard/UploadInboxCard.sc';
import { FormattedMessage, useIntl } from 'react-intl';

type Props = {
  inboxFilter: Filter;
  fileList: UploadFile[];
  onChange(
    inboxId: string,
    fileList: UploadFile[],
    status?: UploadFileStatus,
  ): void;
};

const MAXIMUM_FILE_SIZE = 1.9; //in MBs
const MAXIMUM_FILES = 100;

const UploadInboxCard: FC<Props> = ({
  inboxFilter,
  fileList = [],
  onChange,
}) => {
  const [error, setError] = useState('');
  const intl = useIntl();
  const props: UploadProps = {
    fileList,
    name: 'file',
    multiple: true,
    maxCount: MAXIMUM_FILES,
    accept: 'image/jpeg,image/gif,image/png,application/pdf',
    beforeUpload(file, fileList) {
      // TODO: this will be improved later
      const fileSizeOk = file.size / 1024 / 1024 < MAXIMUM_FILE_SIZE;
      const maximumFilesExceed = fileList.length + 1 > MAXIMUM_FILES;

      if (!fileSizeOk) {
        setError(
          error.concat(
            intl.formatMessage(
              {
                id: 'documents.upload.uploadinboxcard.error.maxfilesize',
                defaultMessage: 'File {filename} must be smaller than {size}',
              },
              { filename: file.name, size: MAXIMUM_FILE_SIZE },
            ),
          ),
        );
        return false;
      }

      if (maximumFilesExceed) {
        setError(
          error.concat(
            intl.formatMessage(
              {
                id: 'documents.upload.uploadinboxcard.error.maxfilecount',
                defaultMessage:
                  'You cannot upload more than {count} files at once to the given inbox!',
              },
              { count: MAXIMUM_FILES },
            ),
          ),
        );
        return false;
      }

      onChange(inboxFilter.id, fileList);

      return false;

      // return fileSizeOk && !maximumFilesExceed;
    },
    onChange(info) {
      const { status } = info.file;

      if (status === 'removed') {
        onChange(inboxFilter.id, info.fileList, status);
      }
    },
    // customRequest() {},
  };

  return (
    <InboxCard>
      <DropZoneArea {...props}>
        <EcosystemImage
          preview={false}
          src={inboxFilter.avatarUrl}
          width={35}
          height={35}
          style={{ position: 'absolute' }}
        />
        <InboxIconWithName>
          <InboxIcon />
          {inboxFilter.name}
        </InboxIconWithName>
        <UploadText>
          <FormattedMessage
            id="documents.upload.uploadinboxcard.text"
            defaultMessage="Click or drag file to this area to upload"
          />
        </UploadText>
        <UploadHint>
          <FormattedMessage
            id="documents.upload.uploadinboxcard.hint"
            defaultMessage="Support for a single or bulk upload. Strictly prohibit from uploading
          company data or other band files"
          />
        </UploadHint>
      </DropZoneArea>
      {error && (
        <ErrorWrapper>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError('')}
          />
        </ErrorWrapper>
      )}
    </InboxCard>
  );
};

export default UploadInboxCard;
