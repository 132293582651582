import React, { CSSProperties, FC } from 'react';
import { Style } from '@react-pdf/types';
import { IntlShape } from 'react-intl';
import { Form, Input } from 'antd';
import { Text, View } from '@react-pdf/renderer';

const { Item } = Form;

type Props = {
  style?: Style;
  intl: IntlShape;
  forPdf: boolean;
  orderId?: string;
  disabled?: boolean;
};

const OrderId: FC<Props> = ({
  orderId,
  forPdf,
  style,
  intl,
  disabled = false,
}) => {
  if (forPdf && orderId) {
    return (
      <View style={style}>
        <Text>{orderId}</Text>
      </View>
    );
  }

  return (
    <div style={style as CSSProperties}>
      <Item name="orderId">
        <Input
          disabled={disabled}
          placeholder={intl.formatMessage({
            id: 'sales.orderId',
            defaultMessage: 'Order Id',
          })}
        />
      </Item>
    </div>
  );
};

export default OrderId;
