import React, { CSSProperties, FC } from 'react';
import PositionsTable from '@apps/purchases/mainMenu/operationSteps/operationStep/positions/PositionsTable';
import { PageViewElementProps } from '@apps/purchases/mainMenu/operationSteps/commonTypes';
import PositionsSummary from '@apps/purchases/mainMenu/operationSteps/operationStep/positions/PositionsSummary';

const Positions: FC<PageViewElementProps> = (props) => {
  const { style } = props;
  return (
    <div style={{ ...(style as CSSProperties) }}>
      <PositionsTable {...props} />
      <PositionsSummary {...props} />
    </div>
  );
};

export default Positions;
