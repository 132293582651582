import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const ProductGroups = lazy(
  () => import(/* webpackChunkName: "productsApp" */ './groups'),
);

const UnitGroups = lazy(
  () => import(/* webpackChunkName: "productsApp" */ './units'),
);

const UserFields = lazy(
  () => import(/* webpackChunkName: "productsApp" */ './UserFields'),
);

export default {
  appSubmenuName: 'app.products.mainMenu.name',
  icon: Icon.ShoppingOutlined,
  sections: [
    {
      link: 'groups',
      title: 'settings.products.groups.menuTitle',
      component: ProductGroups,
    },
    {
      link: 'units',
      title: 'settings.products.units.menuTitle',
      component: UnitGroups,
    },
    {
      link: 'user_fields',
      title: 'settings.products.userFields.menuTitle',
      component: UserFields,
    },
  ],
};
