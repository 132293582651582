import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const Sales = lazy(() => import(/* webpackChunkName: "salesApp" */ './Sales'));

export default {
  appSubmenuName: 'app.sales.mainMenu.name',
  icon: Icon.AuditOutlined,
  component: Sales,
};
