import React, { FC } from 'react';
import moment from 'moment';
import { Form, DatePicker } from 'antd';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { FormattedMessage, useIntl } from 'react-intl/lib';
import { DatePickerProps } from 'antd/lib/date-picker';

const InnerPicker: FC<DatePickerProps> = ({
  disabledDate,
  placeholder,
  disabled,
  className,
  style,
  value,
  ...rest
}) => {
  let parsedValue = value;
  if (value && !moment.isMoment(value)) {
    parsedValue = moment(value, moment.defaultFormat);
  }
  return (
    <DatePicker
      format={moment.defaultFormat}
      disabledDate={disabledDate}
      placeholder={placeholder}
      disabled={disabled}
      className={className}
      style={style}
      value={parsedValue}
      {...rest}
    />
  );
};

const disabledDate = (current: moment.Moment) =>
  current && current > moment().endOf('day');

const DatePickerField: FC<FieldProps> = ({ field, children, ...rest }) => {
  const { readOnly, setFieldsValue, getFieldsValue } = useFormContext();
  const {
    fieldName,
    label,
    rules,
    className,
    style,
    placeholder,
    readOnly: fieldReadOnly,
    noFutureDate,
  } = field;
  const intl = useIntl();

  return (
    <Form.Item
      name={fieldName}
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      rules={rules?.map((r) => {
        r.message =
          r.message && typeof r.message === 'string' ? (
            <FormattedMessage
              id={`${r.message}`}
              defaultMessage={`${r.message}`}
            />
          ) : (
            r.message
          );
        return r;
      })}
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <InnerPicker
        format={moment.defaultFormat}
        disabledDate={noFutureDate ? disabledDate : undefined}
        placeholder={
          placeholder
            ? intl.formatMessage({
                id: placeholder,
                defaultMessage: placeholder,
              })
            : placeholder
        }
        disabled={fieldReadOnly ? fieldReadOnly : readOnly}
        className={className}
        style={style}
        onChange={(_moment, dateString) => {
          setFieldsValue({
            ...getFieldsValue(),
            [fieldName]: dateString,
          });
        }}
      />
    </Form.Item>
  );
};

export default DatePickerField;
