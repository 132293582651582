import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { FileApp } from '@apps/AppTypes';

export type DOCUMENTS_ACTION =
  | 'view-documents'
  | 'create-documents'
  | 'update-documents'
  | 'delete-documents';

export type DOCUMENTS_PERMISSION = 'all' | 'none';

export default {
  todixId: 'documents',
  link: '/app/documents',
  name: 'documents',
  collectionName: 'documents',
  localizationPath: 'documents/i18n',
  entityType: 'document',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.documents.createNewLink.new.document',
    },
  ],
  listCellRenderers: [
    {
      name: 'documentField',
      path: 'documents/widgets/DocumentFieldRenderer',
    },
  ],
  widgets: [
    {
      name: 'AddArchivedDocument',
      path: 'documents/widgets/AddArchivedDocument',
    },
    {
      name: 'InboxUploadWidget',
      path: 'documents/widgets/InboxUploadWidget',
    },
    {
      name: 'TreeNode',
      path: 'documents/widgets/TreeNode',
    },
    {
      name: 'DashboardWidget',
      path: 'documents/widgets/DashboardWidget',
    },
    {
      name: 'StatisticsWidget',
      path: 'documents/widgets/StatisticsWidget',
    },
    {
      name: 'AppRolesForm',
      path: 'documents/widgets/AppRolesForm',
    },
  ],
  actions: [
    'view-documents',
    'create-documents',
    'update-documents',
    'delete-documents',
  ] as DOCUMENTS_ACTION[],
  permissions: ['all', 'none'] as DOCUMENTS_PERMISSION[],
} as FileApp;
