export const PARTIES_COLLECTION_NAME = 'parties';

export type Party = {
  ecosystem: string;
  id?: string;
  isActive: boolean;
  confirmed: boolean;
  validationDate?: string;
  consultantRef: string;
  hostRef: string;
  partyTopic: string;
  partyDate: string;
  partyStartdate?: string;
  partyEnddate?: string;
  partyClosed: boolean;
};
