import { useState, useEffect, useRef } from 'react';

type UseScriptState = {
  value: string;
  error?: any;
};

const SCRIPT_ID = 'firebase_ui_script';

const useScript = (src: string) => {
  const [state, setState] = useState<UseScriptState>({
    value: 'loading',
    error: undefined,
  }); // value { loading | loaded | error }
  const script = useRef<HTMLScriptElement>(null);

  useEffect(() => {
    // @ts-ignore
    script.current = document.createElement('script');
    script.current.src = src;
    script.current.async = true;
    script.current.id = SCRIPT_ID;

    const onScriptLoad = () => setState({ value: 'loaded', error: undefined });

    const onScriptError = (e: any) => {
      if (script.current) {
        script.current.remove();
      }
      setState({ value: 'error', error: e });
    };

    script.current.addEventListener('load', onScriptLoad);
    script.current.addEventListener('error', onScriptError);

    document.body.appendChild(script.current);
    return () => {
      if (script.current) {
        script.current.removeEventListener('load', onScriptLoad);
        script.current.removeEventListener('error', onScriptError);
        script.current.remove();
      }
    };
  }, [src]);

  return state;
};

export default useScript;
