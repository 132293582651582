import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import {
  HistoryTotalEntry,
  WarehouseHistoryEntry,
  WarehouseStorage,
} from '@apps/warehouse/services/namespace';
import Controls from '@apps/warehouse/mainMenu/components/Controls';
import Plot from '@apps/warehouse/mainMenu/components/Plot';
import HistoryTable from '@apps/warehouse/mainMenu/components/HistoryTable';
import fetchFilteredHistoryForStorage from '@apps/warehouse/services/fetchFilteredHistoryForStorage';
import fetchFilteredTotalStatsHistoryForStorage from '@apps/warehouse/services/fetchFilteredTotalStatsHistoryForStorage';
import AppConfig, { WAREHOUSE_ACTION } from '@apps/warehouse';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

type Props = {
  action: WAREHOUSE_ACTION;
};

const Positions: FC<Props> = ({ action }) => {
  const [form] = useForm();
  const firstActiveEcosystemId = useFirstActiveEcosystemId(
    AppConfig.todixId,
    'view-warehouses' as WAREHOUSE_ACTION,
  );
  const canView = checkUserPermissions(
    rootStore.getState(),
    AppConfig.todixId,
    'view-warehouses' as WAREHOUSE_ACTION,
    firstActiveEcosystemId || '',
  );
  const [historyEntries, setHistoryEntries] = useState<WarehouseHistoryEntry[]>(
    [],
  );
  const [totalStatsHistoryEntries, setTotalStatsHistoryEntries] = useState<
    HistoryTotalEntry[]
  >([]);
  const [selectedEcosystem, setSelectedEcosystem] = useState<string | null>(
    firstActiveEcosystemId || null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleStorageSelect = useCallback(
    (storage: WarehouseStorage) => {
      setIsLoading(true);
      const fetchingHistory = fetchFilteredHistoryForStorage({
        ecosystem: selectedEcosystem as string,
        storageId: storage.id as string,
        filter: {
          field: 'flowType',
          value: 'incoming',
        },
      })?.then((entries) => setHistoryEntries(entries));
      const fetchingTotalStatsHistory =
        fetchFilteredTotalStatsHistoryForStorage({
          ecosystem: selectedEcosystem as string,
          storageId: storage.id as string,
          filter: {
            field: 'flowType',
            value: 'incoming',
          },
        })?.then((entries) => setTotalStatsHistoryEntries(entries));
      Promise.all([fetchingHistory, fetchingTotalStatsHistory]).finally(() => {
        setIsLoading(false);
      });
    },
    [selectedEcosystem],
  );
  const handleEcosystemSelect = useCallback(
    (ecosystem: string) => {
      form.setFieldsValue({
        storage: null,
      });
      setSelectedEcosystem(ecosystem);
      setHistoryEntries([]);
      setTotalStatsHistoryEntries([]);
    },
    [form],
  );
  return (
    <div>
      <Controls
        onEcosystemSelect={handleEcosystemSelect}
        onStorageSelect={handleStorageSelect}
        selectedEcosystem={selectedEcosystem}
        isLoading={isLoading}
        action={action}
      />
      {canView && (
        <>
          <Plot data={totalStatsHistoryEntries} />
          <HistoryTable data={historyEntries} />
        </>
      )}
    </div>
  );
};

export default Positions;
