import { Typography } from 'antd';
import { css } from 'styled-components';
import { Text } from '@todix/ui-components';
import { styled } from '@styles/themes';

const { Title } = Typography;

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const section = css`
  display: flex;
  flex-direction: column;
`;

export const AuthorSection = styled.div`
  ${section};
  height: 100%;
`;

export const PageTitle = styled(Title)`
  && {
    margin: 14px 0;
  }
`;

export const SectionTitle = styled(Text)`
  &&& {
    color: ${({ theme }) => theme.colors.text.gray};
    margin-bottom: 14px;
    line-height: 22px;
  }
`;

export const UserPart = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled(Text)`
  &&& {
    margin-left: 8px;
  }
`;

export const TextDescriptionSection = styled.div`
  ${section};
  padding: 0 0 0 40px;
  max-width: 430px;
`;

export const Section = styled.div`
  padding: 0 40px 0 0;
  ${section};
`;
