import React, { FC } from 'react';
import { message } from 'antd';
import { getRows, GetRowsParams } from '@apps/documents/mainMenu/Archive';
import columns from '@apps/documents/mainMenu/Archive/columns';
import { deleteDocument, LayoutField } from '@apps/documents/service';
import SideContent from '@apps/documents/mainMenu/Archive/SideContent';
import AppView from '@components/AppView';
import { layoutsMap } from '@apps/documents/service/layouts';
import { useIntl } from 'react-intl';
import DocumentAppConfig from '@apps/documents';
import optionsMapper from '@core/helpers/optionsMapper';

type DocumentsProps = {
  contactId: string;
};

const getGetRows =
  (fields: string[], contactId?: string) => (params: GetRowsParams) =>
    getRows({
      fields,
      ...params,
      filterModel: contactId
        ? {
            relatedContact: {
              filter: contactId,
              filterType: 'text',
              type: 'contains',
            },
          }
        : undefined,
    });

const Documents: FC<DocumentsProps> = ({ contactId }) => {
  const intl = useIntl();
  const layouts = { ...layoutsMap };
  const entries = Object.entries(layouts).map(([key, value]) => [
    key,
    value('detailedForm'),
  ]);

  const layoutsDetails = Object.fromEntries(entries);
  // @ts-ignore
  const fields = Object.values(layoutsDetails)
    // @ts-ignore
    .map((fields: any) =>
      fields.map((field: LayoutField) => ({
        fieldName: field.fieldName,
        type: field.type,
      })),
    )
    .flat();
  const fieldsUnique = fields.reduce(
    (items, item) =>
      items.find((x: any) => x.fieldName === item.fieldName)
        ? [...items]
        : [...items, item],
    [],
  );
  return (
    <AppView
      breadcrumbItems={[]}
      columns={columns(fieldsUnique, intl)}
      getRows={getGetRows(
        [
          'id',
          'uploadingUser',
          'creatingUser',
          ...fieldsUnique.map((entry: any) => entry.fieldName),
        ],
        contactId,
      )}
      sideContent={SideContent}
      path={`/app/${DocumentAppConfig.todixId}`}
      onCellClickPath={`/app/${DocumentAppConfig.todixId}/archive`}
      tabs={['list']}
      onRemoveItem={(id) =>
        deleteDocument(id).catch(() => {
          message.error(
            intl.formatMessage({
              id: 'products.deletedocument.error',
              defaultMessage: 'Error while deleting document',
            }),
          );
        })
      }
      options={optionsMapper(['open', 'delete'], intl)}
      hideActionsInSideContent
      hideQuickFilter
    />
  );
};

export default Documents;
