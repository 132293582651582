import React, { useEffect, useState, FC } from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import {
  getEcosystemTaxCategories,
  TaxCategory,
} from '@apps/finance/services/taxCategories';
import { getPlatformTaxRates } from '@services/dictionary';

const { Item } = Form;

type Props = {
  readOnly?: boolean;
  style?: Record<string, any>;
  ecosystemId: string;
};

type TaxRateOption = {
  key: number;
  value: string;
  label?: string;
};

const TaxCategory: FC<Props> = ({ readOnly, ecosystemId, ...rest }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [taxRatesOptions, setTaxRatesOptions] = useState<
    TaxRateOption[] | null
  >(null);
  const intl = useIntl();

  useEffect(() => {
    getEcosystemTaxCategories(ecosystemId)
      ?.then((el) =>
        el.length > 0
          ? setTaxRatesOptions(
              el.map((tax: TaxCategory, index) => ({
                key: index,
                value: tax.id as string,
                label: tax.label[intl.locale]
                  ? `${tax.label[intl.locale]} (${tax.value} %)`
                  : `(${tax.value} %)`,
              })),
            )
          : getPlatformTaxRates().then((elements) =>
              setTaxRatesOptions(
                elements.map((tax, index) => ({
                  key: index,
                  value: `${tax.value}`,
                  label: tax[intl.locale],
                })),
              ),
            ),
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [ecosystemId, intl.locale]);

  return (
    <Item
      name={['purchaseDetails', 'taxCategory']}
      label={
        <FormattedMessage
          id="sales.salesTab.taxCategory.label"
          defaultMessage="Tax category"
        />
      }
    >
      <Select
        {...rest}
        placeholder={
          <FormattedMessage
            id="sales.salesTab.taxCategory.label"
            defaultMessage="Tax category"
          />
        }
        loading={isLoading}
        options={taxRatesOptions || []}
        disabled={readOnly}
      />
    </Item>
  );
};

export default TaxCategory;
