import React, { FC } from 'react';
import { Project } from '@apps/projects/services';

type TreeNodeProps = {
  resource: Record<string, any>;
};

const TreeNode: FC<TreeNodeProps> = ({ resource }) => {
  const project = resource as Project;
  return <span>{project?.name || ''}</span>;
};

export default TreeNode;
