import { firebase } from '@services/Firebase';
import {
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@firebase/firestore-types';

type FetchResourceFromCollectionPayload = {
  collection: string;
  ecosystem: string;
  parentId: string;
};

export type Resource = {
  [key: string]: string | number | string[] | number[];
  id: string;
  collection: string;
};

const fetchResourcesFromCollection = ({
  collection,
  ecosystem,
  parentId,
}: FetchResourceFromCollectionPayload) => {
  return firebase.firestore
    ?.collection(collection)
    .where('ecosystem', '==', ecosystem)
    .get()
    .then((querySnapshot: QuerySnapshot) => {
      return querySnapshot.docs
        .map((doc: QueryDocumentSnapshot) => ({
          ...doc.data(),
          id: doc.id,
          collection,
        }))
        .filter((doc) => doc.id !== parentId);
    }) as Promise<Resource[]>;
};

export default fetchResourcesFromCollection;
