import React, { FC, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Typography, Progress } from 'antd';
import { Text } from '@todix/ui-components';
import { CloseSquareOutlined } from '@ant-design/icons';
import { firebase } from '@services/Firebase';

import { RootStore } from '@core/store';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import {
  InboxGrid,
  UploadCard,
  UploadWrapper,
} from '@apps/documents/mainMenu/Upload/Upload.sc';
import UploadInboxCard from '@apps/documents/mainMenu/Upload/UploadInboxCard';
import Filters from '@apps/documents/mainMenu/components/Filters';
import DropdownWithFullScreenMode from '@components/DropdownWithFullScreenMode';
import { getAllInboxes, postDocument, Document } from '@apps/documents/service';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import storage from '@services/Firebase/Storage';
import { FormattedMessage } from 'react-intl';
import appConfig, { DOCUMENTS_ACTION } from '@apps/documents';

import { Inbox } from '@apps/documents/service/namespace';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import createThumbnailForPDFFile from '@apps/documents/mainMenu/Upload/createThumbnailForPDFFile';

const { Title } = Typography;

const mapState = (state: RootStore) => ({
  ecosystems: getActiveEcosystemsForApp(
    state,
    appConfig.todixId,
    'create-documents' as DOCUMENTS_ACTION,
  ),
});

type UploadProps = ReturnType<typeof mapState> & {
  onUploadSuccess(docs?: Document[]): void;
  onCancel?(): void;
};

export type Filter = {
  id: string;
  name: string;
  amount?: number;
  inFilter: boolean;
  avatarUrl?: string;
  disabled?: boolean;
};

export type FilterWithInboxes = Filter & {
  inboxes: Filter[];
};

type InboxesUploadLists = Record<string, UploadFile[]>;

const Upload: FC<UploadProps> = ({ ecosystems, onUploadSuccess, onCancel }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<FilterWithInboxes[]>([]);
  const [inboxesUploadLists, setInboxesUploadLists] =
    useState<InboxesUploadLists>({});
  const [uploading, setUploading] = useState(false);
  const [filesCount, setFilesCount] = useState(0);
  const [filesProgress, setFilesProgress] = useState(0);
  // should replace filtered inboxes in state
  const [inboxes, setInboxes] = useState<Inbox[]>([]);
  const history = useHistory();

  useEffect(() => {
    getAllInboxes('create-documents')
      .then((inboxes) => {
        const initialFileLists: InboxesUploadLists = Object.fromEntries(
          inboxes
            .filter((inbox) => inbox.isActive)
            .map((inbox) => [inbox.id, []]),
        );

        const initialFilters = ecosystems.map((eco) => ({
          id: eco.id,
          name: eco.name,
          avatarUrl: eco.avatarUrl,
          inboxes: inboxes
            .filter((inbox) => inbox.ecosystem === eco.id)
            .map((inbox) => ({
              id: inbox.id,
              name: inbox.name,
              avatarUrl: eco.avatarUrl,
              disabled: !inbox.isActive,
              inFilter: inbox.isActive,
            })),
          inFilter: true,
        }));

        setInboxes(inboxes);
        setFilters(initialFilters);
        setInboxesUploadLists(initialFileLists);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [ecosystems]);
  useEffect(() => {
    if (Object.keys(inboxesUploadLists).length === 0) {
      return;
    }

    setFilters(
      filters.map((filter) => {
        const inboxes = filter.inboxes.map((inbox) => ({
          ...inbox,
          amount: inboxesUploadLists[inbox.id]
            ? inboxesUploadLists[inbox.id].length
            : 0,
        }));
        return {
          ...filter,
          inboxes,
          amount: inboxes.reduce((acc, inbox) => (acc += inbox.amount), 0),
        };
      }),
    );
  }, [inboxesUploadLists]);

  const getEcosystemByInbox = (id: string) => {
    return inboxes.find((inbox) => inbox.id === id)?.ecosystem;
  };

  const getInboxNameById = (id: string) => {
    return inboxes.find((inbox) => inbox.id === id)?.name;
  };

  const handleMenuClick = useCallback(() => {
    setVisible(false);
  }, []);

  const handleVisibleChange = useCallback((flag: boolean) => {
    setVisible(flag);
  }, []);

  const handleCancel = useCallback(() => {
    // we simply clear the file list for all inboxes
    const entries = Object.entries(inboxesUploadLists).map(([id]) => [id, []]);
    setInboxesUploadLists(Object.fromEntries(entries));
    if (onCancel) {
      onCancel();
    } else {
      history.push('/');
    }
  }, [history, inboxesUploadLists, onCancel]);

  const handleConfirm = useCallback(() => {
    setUploading(true);
    const allFiles = Object.entries(inboxesUploadLists)
      .map(([inboxId, files]) =>
        files.map((file) => ({
          file,
          inbox: inboxId,
          origin: getInboxNameById(inboxId),
          ecosystem: getEcosystemByInbox(inboxId),
          fileDetails: {
            format: file?.type?.split('/')[1],
            size: file?.size,
          },
        })),
      )
      .flat();
    setFilesCount(allFiles.length);
    const user = firebase.auth.currentUser;
    const uploadPromises = allFiles.map((entry) => {
      let document: Partial<Document> = {
        inbox: entry.inbox,
        ecosystem: entry.ecosystem,
        fileDetails: entry.fileDetails as Document['fileDetails'],
        uploadingUser: user?.uid as string,
        origin: entry.origin,
        uploadDate: new Date().toISOString(),
        timestamp: moment().valueOf(),
        isDraft: true,
      };
      return storage
        .upload(`documents/${entry.file.uid}`, entry.file as unknown as Blob)
        ?.then((snapshot) => snapshot.ref.getDownloadURL())
        .then(async (documentUrl) => {
          document = {
            ...document,
            documentUrl,
          };
          if (document.fileDetails?.format === 'pdf') {
            const docWithThumb = await createThumbnailForPDFFile(
              document,
              entry.file.uid,
            );
            return postDocument(docWithThumb, {
              message: false,
            })?.then((id: string) => {
              setFilesProgress((prev) => prev + 1);
              return {
                ...docWithThumb,
                id,
              };
            });
          } else {
            return postDocument(document, {
              message: false,
            })?.then((id: string) => {
              setFilesProgress((prev) => prev + 1);
              return {
                ...document,
                id,
              };
            });
          }
        });
    });
    Promise.all(uploadPromises as Promise<Document>[])
      .then((docs) => {
        onUploadSuccess(docs);
      })
      ?.finally(() => {
        setUploading(false);
        setFilesProgress(0);
        setInboxesUploadLists({});
        setFilesCount(0);
      });
  }, [
    getEcosystemByInbox,
    getInboxNameById,
    inboxesUploadLists,
    onUploadSuccess,
  ]);

  const handleInboxUploadFileListChange = useCallback(
    (inboxId: string, fileList: UploadFile[], status?: UploadFileStatus) => {
      if (inboxesUploadLists[inboxId]) {
        const files = Object.values(inboxesUploadLists[inboxId]);
        const makeHash = (prefix: string, suffix: string) =>
          `${prefix}_${suffix}`;
        if (status === 'removed') {
          const filesHash = fileList.map((file) =>
            makeHash(`${file.size}`, file.name),
          );
          const filteredFiles = files.filter((file) => {
            const hash = makeHash(`${file.size}`, file.name);
            return filesHash.includes(hash);
          });
          setInboxesUploadLists({
            ...inboxesUploadLists,
            [inboxId]: filteredFiles,
          });
        } else {
          const filesHash = files.map((file) =>
            makeHash(`${file.size}`, file.name),
          );
          const incomingFiles = fileList.filter((file) => {
            const hash = makeHash(`${file.size}`, file.name);
            return !filesHash.includes(hash);
          });
          setInboxesUploadLists({
            ...inboxesUploadLists,
            [inboxId]: [...files, ...incomingFiles],
          });
        }
      }
    },
    [inboxesUploadLists],
  );

  const cancel = (
    <Button size="small" disabled={uploading} onClick={handleCancel}>
      <FormattedMessage
        id="documents.upload.cancelbutton"
        defaultMessage="Cancel"
      />
    </Button>
  );

  const confirmUpload = (
    <Button
      type="primary"
      size="small"
      disabled={uploading || inboxes.length < 1}
      onClick={handleConfirm}
    >
      <FormattedMessage
        id="documents.upload.uploadbutton"
        defaultMessage="Confirm upload"
      />
    </Button>
  );

  return (
    <UploadWrapper>
      <UploadCard
        loading={loading}
        title={
          <Text level={3} strong>
            <FormattedMessage
              id="documents.upload.uploadcard.title"
              defaultMessage="Upload files"
            />
          </Text>
        }
        extra={
          <DropdownWithFullScreenMode
            overlay={<Filters filters={filters} onChange={setFilters} />}
            trigger={['click']}
            visible={visible}
            onVisibleChange={handleVisibleChange}
            placement="bottomRight"
          >
            <Button
              type="dashed"
              size="small"
              icon={<CloseSquareOutlined />}
              onClick={handleMenuClick}
            >
              <FormattedMessage
                id="documents.upload.uploadcard.filterbutton"
                defaultMessage="Filter"
              />
            </Button>
          </DropdownWithFullScreenMode>
        }
        actions={[cancel, confirmUpload]}
      >
        {uploading && (
          <>
            <Title level={2}>
              <FormattedMessage
                id="documents.upload.uploadcard.uploading"
                defaultMessage="Uploading..."
              />
            </Title>
            <Progress
              percent={(filesProgress / filesCount) * 100}
              showInfo={false}
            />
          </>
        )}
        {!uploading && (
          <InboxGrid>
            {filters.map((ecosystemFilter) =>
              ecosystemFilter.inboxes.map((inboxFilter) =>
                inboxFilter.inFilter ? (
                  // replace key with inbox id
                  <div key={inboxFilter.id + inboxFilter.name}>
                    <UploadInboxCard
                      inboxFilter={inboxFilter}
                      fileList={inboxesUploadLists[inboxFilter.id]}
                      onChange={handleInboxUploadFileListChange}
                    />
                  </div>
                ) : null,
              ),
            )}
          </InboxGrid>
        )}
      </UploadCard>
    </UploadWrapper>
  );
};

export default compose(connect(mapState))(Upload);
