import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const ContactsSettings = lazy(
  () => import(/* webpackChunkName: "contactsApp" */ './ContactsSettings'),
);

export default {
  appSubmenuName: 'app.contacts.mainMenu.name',
  icon: Icon.TeamOutlined,
  sections: [
    {
      link: 'contacts_settings',
      title: 'app.contacts.settings.manage',
      component: ContactsSettings,
    },
  ],
};
