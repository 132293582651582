import { getEcosystemById } from '@components/EcosystemIndicator/store';
import { UseParamsType } from '@pages/settings';
import { Card, Space, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ConsultantCommissionImport from './CommissionImport';
import ConsultantImport from './ConsultantImport';
import intl from '../../../../../intl';

const DirectSalesImport: React.FC = () => {
  const { uid } = useParams<UseParamsType>();
  const ecosystem = useSelector(getEcosystemById(uid)) as Ecosystem;

  if (ecosystem.type !== 'company') {
    return (
      <Card>
        <Typography.Text
          type="warning"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {intl.formatMessage({
            id: 'consultants.import.nonOrganization',
            defaultMessage: 'consultants.import.nonOrganization',
          })}
        </Typography.Text>
      </Card>
    );
  }

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <ConsultantImport ecosystemId={ecosystem.id} />
      <ConsultantCommissionImport ecosystemId={ecosystem.id} />
    </Space>
  );
};

export default DirectSalesImport;
