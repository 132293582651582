import {
  USER_FIELDS_COLLECTION_NAME,
  UserField,
} from '@apps/products/services/namespace';
import { post } from '@services/api';
import moment from 'moment/moment';

const createProductUserField = (userField: UserField) => {
  return post(USER_FIELDS_COLLECTION_NAME, {
    ...userField,
    timestamp: moment().valueOf(),
  });
};

export default createProductUserField;
