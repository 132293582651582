import * as React from 'react';
import { Icon } from '@todix/ui-components';
import { Wrapper } from '@core/layout/components/TaskHandler/TaskHandler.sc';

type HelpHandlerProps = {
  onClick?(): void;
};

export const HelpHandler: React.FC<HelpHandlerProps> = ({ onClick }) => {
  return (
    <Wrapper>
      <Icon.QuestionCircleOutlined style={{ fontSize: 30 }} onClick={onClick} />
    </Wrapper>
  );
};
