import * as React from 'react';
import { Link } from 'react-router-dom';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { MenuItem } from '@todix/ui-components';
import {
  StyledMenu,
  Wrapper,
} from '@core/layout/components/CreateButtonPlatform/CreateButtonMenu.sc';
import { CreateButtonContextAction } from '@core/layout/components/CreateButtonPlatform/index';
import { useAllActivatedFileApps } from '@core/helpers/apps';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

type Props = {
  handleMenuClick?: MenuClickEventHandler;
  contextActions: CreateButtonContextAction[];
};

const CreateButtonMenu: React.FC<Props> = ({
  handleMenuClick,
  contextActions,
}) => {
  const allActivatedFileApps = useAllActivatedFileApps();
  const uniqueActivatedFileApps = _.uniqBy(allActivatedFileApps, 'appId');

  return (
    <Wrapper>
      <StyledMenu onClick={handleMenuClick} selectable={false}>
        {/* Todo: remove filter when old applications will be depreciated */}
        {contextActions
          .filter((el) => el.app !== 'Products' && el.app !== 'Documents')
          .map((action, index) => (
            <MenuItem key={`${action.app}_${index}`}>
              <Link to={action.link}>{action.app}</Link>
            </MenuItem>
          ))}
        {uniqueActivatedFileApps.map((fileApp) => (
          <>
            {fileApp.createNewLinks &&
              fileApp.createNewLinks.map(({ link, title }) => (
                <MenuItem key={`/app/${fileApp.todixId}/${link}`}>
                  <Link to={`/app/${fileApp.todixId}/${link}`}>
                    <fileApp.mainMenu.icon style={{ marginRight: 8 }} />
                    <FormattedMessage id={title} defaultMessage={title} />
                  </Link>
                </MenuItem>
              ))}
          </>
        ))}
      </StyledMenu>
    </Wrapper>
  );
};

export default CreateButtonMenu;
