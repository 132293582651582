import React, { FC, useState, useEffect } from 'react';
import StepForm from '@apps/documents/mainMenu/DocumentProcessing/StepForm';
import { ChildrenPassedProps } from '@apps/documents/mainMenu/DocumentProcessing/Step';
import AutoCompleteDocument from '../AutoCompleteDocument';
import { Button, Collapse, Divider, Form } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Document, getDocument, LayoutField } from '@apps/documents/service';
import { getName } from '@core/helpers/documents';
import SpinContainer from '@components/SpinContainer';
import LayoutRenderer from '@apps/documents/mainMenu/LayoutRenderer';
import { getLayout } from '@apps/documents/service/layouts';
import { InputField } from '@apps/documents/mainMenu/components/FormFields';
import { useIntl } from 'react-intl';
import { emitCustomEvent } from '@core/services';
import { DocumentProcessingEvents } from '@apps/documents/mainMenu/DocumentProcessing/index';
import { useForm } from 'antd/lib/form/Form';
import { FormContext } from '@components/FormWithReminder/context';

const { Panel } = Collapse;

type PanelContentProps = {
  document: Document;
};

const REQUIRED_DOC_TYPES = ['dunningLetter'];

const PanelContent: FC<PanelContentProps> = ({ document }) => {
  const [layout, setLayout] = useState<LayoutField[]>([]);
  const [form] = useForm();

  useEffect(() => {
    const processingLayout = getLayout(document.type, true, 'processing');
    setLayout(processingLayout);
  }, [document.type]);

  if (layout.length === 0) {
    return <SpinContainer />;
  }

  return (
    <FormContext.Provider value={{ ...form }}>
      <Form
        form={form}
        initialValues={document}
        colon={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
      >
        <LayoutRenderer layoutFields={layout} />
      </Form>
    </FormContext.Provider>
  );
};

type ExtraButtonProps = {
  onClick: () => void;
};

const DeleteButton: FC<ExtraButtonProps> = ({ onClick }) => (
  <Button
    type="text"
    size="small"
    icon={<CloseSquareOutlined />}
    onClick={onClick}
  />
);

const Step4: FC<ChildrenPassedProps> = ({
  document,
  triggerValidator,
  ...rest
}) => {
  const intl = useIntl();
  const [documents, setDocuments] = useState<{
    [key: string]: Document;
  }>({});

  useEffect(() => {
    emitCustomEvent<DocumentProcessingEvents>(
      'setRelatedDocuments',
      Object.values(documents),
    );

    if (rest?.form) {
      rest.form.setFields([
        {
          name: 'relatedDocumentsSnapshots',
          value: Object.keys(documents),
        },
      ]);

      triggerValidator('step4');
    }
  }, [documents]);

  useEffect(() => {
    if (document.relatedDocuments)
      Promise.all(document.relatedDocuments.map((id) => getDocument(id))).then(
        (documents) => {
          setDocuments(
            documents.reduce(
              (prev, curr) => ({
                ...prev,
                // @ts-ignore
                [curr.id]: curr,
              }),
              {},
            ),
          );
        },
      );
  }, []);
  const documentsValues = Object.values(documents);
  const displayCollapse = !!documentsValues.length;
  return (
    <>
      <StepForm {...rest}>
        <InputField
          field={{
            fieldType: 'input',
            fieldName: 'relatedDocuments',
            rules: [
              {
                required:
                  !!document.type && REQUIRED_DOC_TYPES.includes(document.type),
                message: intl.formatMessage({
                  id: 'documentprocessing.step2.relateddocument.error',
                  defaultMessage: 'Missing related document',
                }),
              },
            ],
          }}
          required={
            !!document.type && REQUIRED_DOC_TYPES.includes(document.type)
          }
          hidden
        />
        <InputField
          field={{
            fieldType: 'input',
            fieldName: 'relatedDocumentsSnapshot',
          }}
          hidden
        />
        <AutoCompleteDocument
          ecosystem={rest?.initialValues?.ecosystem}
          hiddenValues={[...Object.keys(documents), document.id as string]}
          onSelect={(id) => {
            getDocument(id).then((doc) => {
              setDocuments((data) => ({
                ...data,
                [id]: doc,
              }));
            });

            if (rest?.form) {
              rest.form.setFields([
                {
                  name: 'relatedDocuments',
                  value: [...Object.keys(documents), id],
                },
              ]);
            }
          }}
        />
      </StepForm>
      {displayCollapse && !rest.hidden && <Divider />}
      {displayCollapse && !rest.hidden && (
        <Collapse>
          {documentsValues.map((doc) => (
            <Panel
              key={doc.id as string}
              header={<span>{getName(doc, intl)}</span>}
              extra={
                <DeleteButton
                  onClick={() => {
                    const filteredDocs = Object.fromEntries(
                      Object.entries(documents).filter(([id]) => id !== doc.id),
                    );

                    setDocuments(filteredDocs);

                    const ids = Object.keys(filteredDocs);

                    if (rest?.form) {
                      rest.form.setFields([
                        {
                          name: 'relatedDocuments',
                          value: ids.length ? ids : undefined,
                        },
                      ]);
                    }
                  }}
                />
              }
            >
              <PanelContent document={doc} />
            </Panel>
          ))}
        </Collapse>
      )}
    </>
  );
};

export default Step4;
