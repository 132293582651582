import { IntlShape } from 'react-intl';
import { emitCustomEvent } from '@core/services';

type ExtraOptions = {
  parentId?: string;
  parentType: string;
  intl: IntlShape;
};

const getExtraOptions = ({ parentId, parentType, intl }: ExtraOptions) => {
  const createDocument = {
    children: intl.formatMessage({
      id: 'products.getextraoptions.create.document',
      defaultMessage: 'create document',
    }),
    danger: false,
    onClick: () => {
      emitCustomEvent('addProductPicture', {
        parentId,
        parentType,
      });
    },
  };
  return [createDocument];
};

export default getExtraOptions;
