import React, { FC, useMemo } from 'react';
import { Switch, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';
import StyledTable from '@pages/settings/components/StyledTable';
import { Inbox } from '@apps/documents/service';
import { styled } from '@styles/themes';
//@ts-ignore
const STable = styled(StyledTable)`
  .ant-table-tbody > tr > td {
    cursor: pointer;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: #fff;
    cursor: pointer;
  }
`;

const getColumns =
  (handleOnChange: (inbox: Inbox, value: boolean) => void) => () =>
    [
      {
        title: (
          <FormattedMessage
            id="settings.documents.inboxesmanagement.list.header.inboxname"
            defaultMessage="Inbox name"
          />
        ),
        sorter: (a: Inbox, b: Inbox) => a.name.localeCompare(b.name),
        dataIndex: 'name',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v: any, record: Inbox) => (
          <Tooltip
            title={
              <FormattedMessage
                id="settings.documents.inboxesmanagement.list.tooltip"
                defaultMessage="Activate to make this Inbox available in the ecosystem"
              />
            }
          >
            <Switch
              checked={record.isActive}
              onClick={(_v, e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onChange={(value, e) => {
                e.preventDefault();
                e.stopPropagation();
                handleOnChange(record, value);
              }}
            />
          </Tooltip>
        ),
      },
      {
        title: (
          <FormattedMessage
            id="products.form.description.label"
            defaultMessage="Description"
          />
        ),
        dataIndex: 'description',
        ellipsis: true,
      },
    ];
type Props = {
  loading: boolean;
  inboxes: Inbox[];
  selectedRow: string;
  onChange: (inbox: Inbox, value: boolean) => void;
  rowClick: (inbox: Inbox) => void;
};
const InboxTable: FC<Props> = ({
  loading,
  inboxes,
  selectedRow,
  onChange,
  rowClick,
}) => {
  const columns: ColumnType<any>[] = useMemo(getColumns(onChange), [onChange]);
  return (
    <STable
      dataSource={inboxes}
      columns={columns}
      bordered
      loading={loading}
      pagination={false}
      rowKey="id"
      rowSelection={{
        selectedRowKeys: [selectedRow],
        type: 'radio',
      }}
      onRow={(record: any) => ({
        onClick: () => {
          rowClick(record);
        },
      })}
    />
  );
};

export default InboxTable;
