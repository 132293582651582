import React, { FC } from 'react';
import AppConfig, { PURCHASES_ACTION } from '@apps/purchases';
import SelectedEcosystem from '@components/SelectedEcosystem';
import Creator from '@apps/purchases/mainMenu/components/individualRequest/Creator';
import CreationDate from '@apps/purchases/mainMenu/components/individualRequest/CreationDate';

type RightColumnProps = {
  readOnly: boolean;
  action: PURCHASES_ACTION;
};

const RightColumn: FC<RightColumnProps> = ({ action, readOnly }) => {
  return (
    <div>
      <SelectedEcosystem
        action={action as string}
        disabled={readOnly}
        appId={AppConfig.todixId}
      />
      <Creator />
      <CreationDate />
    </div>
  );
};

export default RightColumn;
