import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Commission } from '@apps/directSales/services/commisions';
import ProductBonusRenderer from '@apps/directSales/settings/Commissions/components/ProductBonusRenderer';
import RecruitingBonusRenderer from '@apps/directSales/settings/Commissions/components/RecruitingBonusRenderer';
import {
  DivBoldMarginRight,
  FlexDiv,
  FlexDivColumn,
} from '@apps/directSales/styles.sc';
import CommissionThresholdsRenderer from '@apps/directSales/settings/Commissions/components/CommissionThresholdsRenderer';

type Props = {
  record: Commission;
};

const ExpandableRow: FC<Props> = ({ record }) => {
  const intl = useIntl();
  return (
    <FlexDivColumn>
      <FlexDiv>
        <DivBoldMarginRight>
          {intl.formatMessage({
            id: 'commissions.productBonus',
            defaultMessage: 'Product bonus',
          })}
          :
        </DivBoldMarginRight>
        <ProductBonusRenderer value={record.productBonus} />
      </FlexDiv>
      <FlexDiv>
        <DivBoldMarginRight>
          {intl.formatMessage({
            id: 'commissions.recruitingBonus',
            defaultMessage: 'Recruiting bonus',
          })}
          :
        </DivBoldMarginRight>
        <RecruitingBonusRenderer value={record.recruitingBonus} />
      </FlexDiv>
      <CommissionThresholdsRenderer value={record.commissionThresholds} />
    </FlexDivColumn>
  );
};

export default ExpandableRow;
