import { patch } from '@services/api';
import { SALES_COLLECTION_NAME, Sale } from '@apps/sales/services/namespace';
import { removeUndefinedProperties } from '@services/utils';
import moment from 'moment';

const updateSale = (id: string, sale: Partial<Sale>) => {
  return patch(`${SALES_COLLECTION_NAME}/${id}`, {
    ...removeUndefinedProperties(sale),
    timestamp: moment().valueOf(),
  });
};

export default updateSale;
