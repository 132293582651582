import { connect } from 'react-redux';
import { compose } from 'redux';

import { RootStore } from '@core/store';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';
import { EcosystemIndicator, OwnProps } from './EcosystemIndicator';

const mapState = (state: RootStore, ownProps: OwnProps) => ({
  ecosystems: getActiveEcosystems(state),
  ...ownProps,
});

const mapDispatch = {};

export type StateProps = ReturnType<typeof mapState>;

export type DispatchProps = typeof mapDispatch;

export default compose(connect(mapState, mapDispatch))(EcosystemIndicator);
