import React, { FC, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { getBucket } from '@apps/purchases/services/buckets';
import { Document, getDocument } from '@apps/documents/service';

type Props = {
  value?: string;
  data?: {
    bucketId: string;
  };
};

const BucketDocumentRenderer: FC<Props> = ({ value, data }) => {
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<Document | null>(null);

  useEffect(() => {
    if (value) {
      setLoading(true);
      getBucket(value)
        .then((bucket) => {
          if (bucket.relatedDocumentId) {
            setLoading(true);
            getDocument(bucket.relatedDocumentId)
              .then((doc) => {
                setDocument(doc);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [value]);

  if (!value || !data) return null;

  return (
    <Spin spinning={loading} size="small">
      {document?.documentNumber || document?.docNumber}
    </Spin>
  );
};

export default BucketDocumentRenderer;
