import { firebase } from '@services/Firebase';
import {
  BATCH_NUMBERS,
  BatchNumberEntry,
} from '@apps/warehouse/services/namespace';
import moment from 'moment';

const postBatchNumber = async (
  ecosystem: string,
  batchNumber: string,
  productId?: string,
  positionId?: string,
  amount?: number,
) => {
  const entry: BatchNumberEntry = {
    productId,
    amount,
    ecosystem,
    batchNumber,
    positionId,
    timestamp: moment().valueOf(),
  };

  await firebase.firestore
    ?.collection(BATCH_NUMBERS)
    .doc(`${ecosystem}_${batchNumber}`)
    .set(entry);
};

export default postBatchNumber;
