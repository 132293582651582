import * as React from 'react';
import { Progress as AntdProgress } from 'antd';
import { ProgressProps } from 'antd/lib/progress';

const ProgressComponent: React.FC<ProgressProps> = (props) => {
  return (
    <>
      <div>
        <AntdProgress {...props} />
      </div>
    </>
  );
};

export default ProgressComponent;
