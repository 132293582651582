import React, { FC } from 'react';
import ProductGroups from '@apps/products/mainMenu/components/Form/components/ProductGroups';
import SelectedUnit from '@apps/products/mainMenu/components/SelectedUnit';
import ProductName from '@apps/products/mainMenu/components/Form/components/ProductName';
import ProductNumber from '@apps/products/mainMenu/components/Form/components/ProductNumber';
import ActivationToggle from '@apps/products/mainMenu/components/Form/components/ActivationToggle';
import ShortDescription from '@apps/products/mainMenu/components/Form/components/ShortDescription';
import Description from '@apps/products/mainMenu/components/Form/components/Description';
import GroupPurchase from '@apps/products/mainMenu/components/Form/components/GroupPurchase';
import GroupSales from '@apps/products/mainMenu/components/Form/components/GroupSales';
import EANCode from '@apps/products/mainMenu/components/Form/components/EANCode';

type LeftColumnProps = {
  ecosystemId?: string;
  readOnly: boolean;
  form: any;
  namePrefix?: string | number;
};

const LeftColumn: FC<LeftColumnProps> = ({
  ecosystemId,
  readOnly,
  form,
  namePrefix,
}) => {
  return (
    <div>
      <ProductName namePrefix={namePrefix} readOnly={readOnly} />
      <ProductNumber namePrefix={namePrefix} readOnly={readOnly} />
      <ActivationToggle readOnly={readOnly} />
      <ProductGroups form={form} namePrefix={namePrefix} />
      <ShortDescription namePrefix={namePrefix} readOnly={readOnly} />
      <Description namePrefix={namePrefix} readOnly={readOnly} />
      <SelectedUnit ecosystemId={ecosystemId} namePrefix={namePrefix} />
      <GroupPurchase readOnly={readOnly} namePrefix={namePrefix} />
      <GroupSales readOnly={readOnly} namePrefix={namePrefix} />
      <EANCode readOnly={readOnly} namePrefix={namePrefix} />
    </div>
  );
};

export default LeftColumn;
