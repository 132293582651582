import React, { FC } from 'react';
import { Card, Typography } from 'antd';
import { styled } from '@styles/themes';
import { useIntl } from 'react-intl';
import { useAllActivatedFileApps } from '@core/helpers/apps';
import WidgetResolver from '@components/WidgetResolver';

const { Text } = Typography;

const StyledCard = styled(Card)`
  width: 300px;
  margin-left: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

const StatisticsWidget: FC = () => {
  const allActivatedFileApps = useAllActivatedFileApps();
  const intl = useIntl();

  return (
    <StyledCard
      title={
        <Text strong>
          {intl.formatMessage({
            id: 'statistics.widget.title',
            defaultMessage: 'Your space',
          })}
        </Text>
      }
    >
      <Grid>
        {allActivatedFileApps.map((app) => (
          <WidgetResolver
            key={app.todixId}
            appName={app.name}
            widgetName="StatisticsWidget"
            injectedProps={{}}
          />
        ))}
      </Grid>
    </StyledCard>
  );
};

export default StatisticsWidget;
