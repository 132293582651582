import React, { FC } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import intl from '../../../../../intl';
import { styled } from '@styles/themes';
const { Item, List } = Form;

const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.1fr;
  grid-column-gap: 10px;
`;

const UserFieldOptions: FC = () => {
  return (
    <Card>
      <List
        name="options"
        rules={[
          {
            validator: async (_, names) => {
              if (!names || names.length < 1) {
                return Promise.reject(
                  new Error(
                    intl.formatMessage({
                      id: 'settings.products.userField.error',
                      defaultMessage: 'Please add at least 1 option',
                    }),
                  ),
                );
              }
              return Promise.resolve(true);
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => {
                return (
                  <FieldContainer>
                    <Item
                      {...restField}
                      name={[name, 'label']}
                      label={intl.formatMessage({
                        id: 'settings.products.userFields.creationModal.label.label',
                        defaultMessage: 'Label',
                      })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'settings.products.userFields.creationModal.label.error',
                            defaultMessage: 'Label field is required',
                          }),
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                    <Item
                      {...restField}
                      name={[name, 'value']}
                      label={intl.formatMessage({
                        id: 'settings.products.userField.value',
                        defaultMessage: 'Value',
                      })}
                      rules={[
                        {
                          required: true,
                          message: intl.formatMessage({
                            id: 'settings.products.userField.value.error',
                            defaultMessage: 'Missing value',
                          }),
                        },
                      ]}
                    >
                      <Input />
                    </Item>
                    <div>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </div>
                  </FieldContainer>
                );
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {intl.formatMessage({
                    id: 'button.add.field',
                    defaultMessage: 'Add field',
                  })}
                </Button>
              </Form.Item>
              <Form.ErrorList errors={errors} />
            </>
          );
        }}
      </List>
    </Card>
  );
};

export default UserFieldOptions;
