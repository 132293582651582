import { jsPDF } from 'jspdf';
import { TemplateProps } from '@apps/sales/mainMenu/components/pdfTemplates/namespace';

const addDocNumber = (
  doc: jsPDF,
  props: TemplateProps,
  lastYPosition: number,
) => {
  const { intl, values } = props;
  const { docNumber, type } = values;
  if (docNumber && type) {
    const documentNumber = `${intl.formatMessage({
      id: `documents.documentTypes.${type}`,
      defaultMessage: type,
    })}: ${docNumber}`;
    doc.setFont('times', 'normal', 700);
    doc.setFontSize(13);
    doc.text(documentNumber, 20, lastYPosition);
  }
};

export default addDocNumber;
