import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Consultants from '@apps/directSalesClient/mainMenu/Consultants';
import Parties from '@apps/directSalesClient/mainMenu/Parties';

const Routing: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/consultants`}>
        <Consultants />
      </Route>
      <Route path={`${path}/parties`}>
        <Parties />
      </Route>
    </Switch>
  );
};

export default Routing;
