import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import { ColSpanParams } from 'ag-grid-community/dist/lib/entities/colDef';

const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="ecosystem"
      headerName={intl.formatMessage({
        id: 'tasks.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      cellRenderer="ecosystemRenderer"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      filter="ecosystemFilter"
    />
    <AgGridColumn
      field="name"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'app.weekplan.columns.name.header',
        defaultMessage: 'Name',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="lanes"
      colId="tasks_total"
      headerName={intl.formatMessage({
        id: 'app.weekplan.columns.lanes.header',
        defaultMessage: 'Tasks',
      })}
      sortable
      minWidth={140}
      cellRenderer="lanesRenderer"
    />
    <AgGridColumn
      field="lanes"
      colId="tasks_monday"
      headerName={intl.formatMessage({
        id: 'app.weekplan.columns.monday.header',
        defaultMessage: 'Monday',
      })}
      sortable
      minWidth={140}
      cellRenderer="lanesRenderer"
    />
    <AgGridColumn
      field="lanes"
      colId="tasks_tuesday"
      headerName={intl.formatMessage({
        id: 'app.weekplan.columns.tuesday.header',
        defaultMessage: 'Tuesday',
      })}
      sortable
      minWidth={140}
      cellRenderer="lanesRenderer"
    />
    <AgGridColumn
      field="lanes"
      colId="tasks_wednesday"
      headerName={intl.formatMessage({
        id: 'app.weekplan.columns.wednesday.header',
        defaultMessage: 'Wednesday',
      })}
      sortable
      minWidth={140}
      cellRenderer="lanesRenderer"
    />
    <AgGridColumn
      field="lanes"
      colId="tasks_thursday"
      headerName={intl.formatMessage({
        id: 'app.weekplan.columns.thursday.header',
        defaultMessage: 'Thursday',
      })}
      sortable
      minWidth={140}
      cellRenderer="lanesRenderer"
    />
    <AgGridColumn
      field="lanes"
      colId="tasks_friday"
      headerName={intl.formatMessage({
        id: 'app.weekplan.columns.friday.header',
        defaultMessage: 'Friday',
      })}
      sortable
      minWidth={140}
      cellRenderer="lanesRenderer"
    />
    <AgGridColumn
      field="lanes"
      colId="tasks_free"
      headerName={intl.formatMessage({
        id: 'app.weekplan.columns.freeTasks.header',
        defaultMessage: 'Free tasks',
      })}
      sortable
      minWidth={140}
      cellRenderer="lanesRenderer"
    />
    <AgGridColumn cellRenderer="actionRenderer" maxWidth={70} />
  </>
);

export default columns;
