import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from 'antd';
import { Product } from '@apps/products/services';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import ProductImagesInCard from '@apps/products/mainMenu/components/ProductCard/ProductImagesInCard';
import { Italic } from '@apps/products/widgets/styles.sc';
import { StyledProductCard } from '@apps/products/mainMenu/components/styles';
import {
  ActionRenderer,
  ActionRendererProps,
} from '@components/AppView/ActionRenderer';
import { EllipsisOutlined } from '@ant-design/icons';

type Props = {
  item: Product;
  onCardClick(): void;
  actionRendererProps: ActionRendererProps;
};

const ProductCard: FC<Props> = ({ item, onCardClick, actionRendererProps }) => {
  const fullEcosystem = useSelector(
    getEcosystemById(item.ecosystem),
  ) as Ecosystem;

  return (
    <StyledProductCard
      cover={
        <ProductImagesInCard
          productId={item.id as string}
          ecosystemId={item.ecosystem}
        />
      }
      onClick={onCardClick}
      actions={[
        <ActionRenderer
          key="ellipsis"
          {...actionRendererProps}
          icon={<EllipsisOutlined />}
        />,
      ]}
    >
      <Italic>{item.productName}</Italic>
      {fullEcosystem ? (
        <Avatar src={fullEcosystem.avatarUrl} shape="circle" size="default" />
      ) : null}
    </StyledProductCard>
  );
};

export default ProductCard;
