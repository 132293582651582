import { DashboardState } from '../namespace';
import { mockedApplications } from '@services/applications';

export const initial: DashboardState = {
  isLoading: false,
  applications: mockedApplications,
  userPreferences: {},
  favApplications: [],
  errorMessage: '',
  showManageMenu: false,
};
