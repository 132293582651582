import React, { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@todix/ui-components';

const { Item } = Form;

type CanBeBoughtProps = {
  readOnly: boolean;
};

const CanBeBought: FC<CanBeBoughtProps> = ({ readOnly }) => {
  return (
    <Item
      name="canBeBought"
      label={
        <FormattedMessage id="can.be.bought" defaultMessage="Can be bought" />
      }
      valuePropName="checked"
    >
      <Toggle disabled={readOnly} />
    </Item>
  );
};

export default CanBeBought;
