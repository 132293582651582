import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Select } from 'antd';
import { FlowOption } from '@apps/warehouse/services/namespace';

const { Item } = Form;
const { Option } = Select;

type SelectOptionFlowProps = {
  onOptionChange(option: FlowOption): void;
};

const flowOptions: FlowOption[] = [
  'incoming',
  'manual_fill_up',
  'manual_take_out',
  'inventory_correction',
];

const SelectOptionFlow: FC<SelectOptionFlowProps> = ({ onOptionChange }) => {
  const options = useMemo(() => {
    return flowOptions.map((option, index) => {
      return (
        <Option key={index} value={option}>
          <FormattedMessage
            id={`warehouse.create.flow.${option}`}
            defaultMessage={option}
          />
        </Option>
      );
    });
  }, []);
  return (
    <Item
      label={
        <FormattedMessage id="warehouse.create.flow" defaultMessage="Flow" />
      }
    >
      <Select
        placeholder={
          <FormattedMessage
            id="warehouse.create.flow.placeholder"
            defaultMessage="Select flow..."
          />
        }
        aria-required="true"
        onChange={onOptionChange}
      >
        {options}
      </Select>
    </Item>
  );
};

export default SelectOptionFlow;
