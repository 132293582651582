import React, { useState } from 'react';
import { Button, Form, message, Spin } from 'antd';
import removeUndefined from '@core/helpers/removeUndefined';
import CompanyName from '@apps/documents/mainMenu/DocumentProcessing/Step2/CompanyName';
import ContactType from '@apps/documents/mainMenu/DocumentProcessing/Step2/ContactType';
import FoundationDate from '@apps/documents/mainMenu/DocumentProcessing/Step2/FoundationDate';
import Url from '@apps/documents/mainMenu/DocumentProcessing/Step2/Url';
import CountryOfRegistration from '@apps/documents/mainMenu/DocumentProcessing/Step2/CountryOfRegistration';
import LanguageOfContact from '@apps/documents/mainMenu/DocumentProcessing/LanguageOfContact';
import CompanyType from '@apps/documents/mainMenu/DocumentProcessing/Step2/CompanyType';
import FirstName from '@apps/documents/mainMenu/DocumentProcessing/Step2/FirstName';
import LastName from '@apps/documents/mainMenu/DocumentProcessing/Step2/LastName';
import PhonePick from '@apps/documents/mainMenu/DocumentProcessing/Step2/PhonePick';
import EmailPick from '@apps/documents/mainMenu/DocumentProcessing/Step2/EmailPick';
import Address from '@apps/contacts/mainMenu/components/DetailContactForm/Address';
import { Contact, Email, Phone, updateContact } from '@apps/contacts/services';
import { useForm } from 'antd/lib/form/Form';
import { FormContext } from '@components/FormWithReminder/context';
import { FormattedMessage, useIntl } from 'react-intl';
import { ButtonsWrapper } from '@components/FormWithReminder/FormWithReminder.sc';
import moment from 'moment';

const Company = () => (
  <>
    <CompanyName readOnly />
    <ContactType readOnly />
    <FoundationDate readOnly />
    <Url />
    <CountryOfRegistration />
    <LanguageOfContact />
    <CompanyType />
    <Address />
  </>
);
const Person = () => (
  <>
    <FirstName readOnly />
    <LastName readOnly />
    <ContactType readOnly />
    <PhonePick />
    <EmailPick />
    <Address />
  </>
);

type ExistingContactProps = {
  details: Partial<Contact>;
  onFinish?(id: string): void;
  handleCancel?(): void;
  onValuesChange?(contact: Contact): void;
};

const ExistingContact: React.FC<ExistingContactProps> = ({
  details,
  onFinish,
  handleCancel,
  onValuesChange,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = useForm();
  const intl = useIntl();

  return (
    <Spin spinning={isSubmitting}>
      <FormContext.Provider value={{ ...form, readOnly: false }}>
        <Form
          name="relatedContact"
          autoComplete="off"
          form={form}
          initialValues={{
            ...details,
            foundationDate: moment(
              details.foundationDate,
              moment.defaultFormat,
            ),
          }}
          colon={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onFinish={(values) => {
            setIsSubmitting(true);
            values.emails =
              values?.emails?.filter((emailObj: Email) => !!emailObj.email) ||
              [];
            values.phones =
              values?.phones?.filter((phoneObj: Phone) => !!phoneObj.number) ||
              [];
            if (!values.addresses) {
              values.addresses = [];
            }

            if (values.foundationDate) {
              values = {
                ...values,
                foundationDate: values.foundationDate.format(),
              };
            }

            const filteredValues = removeUndefined(values);
            if (details?.id) {
              updateContact(details.id, { ...details, ...filteredValues })
                ?.then((id: any) => {
                  setIsSubmitting(false);
                  if (onFinish) {
                    onFinish(id);
                  }
                })
                .catch(() => {
                  setIsSubmitting(false);
                  message.error(
                    intl.formatMessage({
                      id: 'contacts.edit.error',
                      defaultMessage: 'Error while contact updating',
                    }),
                  );
                });
            } else {
              setIsSubmitting(false);
              message.error(
                intl.formatMessage({
                  id: 'contacts.edit.error',
                  defaultMessage: 'Error while contact updating',
                }),
              );
            }
          }}
          onValuesChange={(_values, all) => {
            if (onValuesChange) {
              onValuesChange(all);
            }
          }}
        >
          <Form.Item name="id" />
          {details.type === 'person' && <Person />}
          {details.type === 'company' && <Company />}
          <ButtonsWrapper>
            <Button onClick={handleCancel}>
              <FormattedMessage
                id="documentprocessing.step2.newcontact.memo.cancel"
                defaultMessage="Cancel"
              />
            </Button>
            <Button type="primary" htmlType="submit" form="relatedContact">
              <FormattedMessage
                id="detailcontactform.contacts.submitbutton.label"
                defaultMessage="Save"
              />
            </Button>
          </ButtonsWrapper>
        </Form>
      </FormContext.Provider>
    </Spin>
  );
};

export default ExistingContact;
