import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type ProductNameProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const ProductName: FC<ProductNameProps> = ({ namePrefix, readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name={
        namePrefix !== undefined ? [namePrefix, `productName`] : `productName`
      }
      label={
        <FormattedMessage
          id="products.form.productname.label"
          defaultMessage="Product name"
        />
      }
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="products.form.productname.error.missing"
              defaultMessage="Product name is missing"
            />
          ),
        },
        {
          type: 'string',
          max: 40,
          message: (
            <FormattedMessage
              id="products.form.productname.error"
              defaultMessage="Product name cannot be longer than 40 characters"
            />
          ),
        },
      ]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'products.form.productname.placeholder',
          defaultMessage: 'Product name...',
        })}
        autoComplete="nope"
        readOnly={readOnly}
        disabled={readOnly}
      />
    </Item>
  );
};

export default ProductName;
