import { Button } from '@todix/ui-components';
import styled from 'styled-components';

const HugeButton = styled(Button)`
  && {
    width: 24vw;
    height: 22vh;
    max-width: 320px;
    max-height: 140px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 20px -20px,
      rgba(0, 0, 0, 0.4) 0px -30px 20px -20px;

    .anticon {
      margin-right: 8px;
    }
  }
`;

export default HugeButton;
