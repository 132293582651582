import { all, takeLatest, put, fork, call, select } from 'redux-saga/effects';
import { fetchUnitGroupsFailed, fetchUnitGroupsSuccess } from './actions';
import { getMultipleEcosystemUnitGroups } from '@services/unitGroups';
import { ActionTypes } from './actionTypes';
import {
  EcosystemActionsTypes,
  getEcosystems,
} from '@components/EcosystemIndicator/store';
import { UnitGroups } from '@services/unitGroups/namespace';

function* fetchUnitGroupsSaga() {
  try {
    // @ts-ignore
    const ecosystemIds = (yield select(getEcosystems)).map(
      (ecosystem: Ecosystem) => ecosystem.id,
    );

    // @ts-ignore
    const unitGroups = yield call(() =>
      getMultipleEcosystemUnitGroups(ecosystemIds),
    );
    ecosystemIds.forEach((ecoId: string) => {
      if (
        !unitGroups.find(
          (unitGroup: UnitGroups) => unitGroup.ecosystemId === ecoId,
        )
      ) {
        unitGroups.push({
          groups: [],
          ecosystemId: ecoId,
        });
      }
    });

    yield put(fetchUnitGroupsSuccess(unitGroups));
  } catch (e) {
    yield put(fetchUnitGroupsFailed(`Error ${e}`));
  }
}

function* watchFetchUnitGroups() {
  yield takeLatest(ActionTypes.FETCH_UNIT_GROUPS, fetchUnitGroupsSaga);
  // download on page start:
  yield takeLatest(EcosystemActionsTypes.SET_ECOSYSTEMS, fetchUnitGroupsSaga);
}

export default function* unitGroupsSaga() {
  yield all([fork(watchFetchUnitGroups)]);
}
