import React, { FC } from 'react';
import columns from './columns';
import getRows from './getRows';
import SideContent from './SideContent';
import { deleteDocument, Document } from '@apps/documents/service';
import { useIntl } from 'react-intl/lib';
import { message } from 'antd';
import DocumentAppConfig, { DOCUMENTS_ACTION } from '@apps/documents';
import { InboxWrapper } from '@apps/documents/mainMenu/Inbox/Inbox.sc';

type InboxProps = {
  docsToProcessing?: Partial<Document>[];
};

const Inbox: FC<InboxProps> = ({ docsToProcessing }) => {
  const intl = useIntl();
  return (
    <InboxWrapper
      breadcrumbItems={[
        [
          `/app/${DocumentAppConfig.todixId}/archive`,
          intl.formatMessage({
            id: 'documents.inbox.nav.documents',
            defaultMessage: 'Documents',
          }),
        ],
        [
          `/app/${DocumentAppConfig.todixId}/inbox`,
          intl.formatMessage({
            id: 'documents.inbox.nav.inbox',
            defaultMessage: 'Inbox',
          }),
        ],
      ]}
      columns={columns(intl)}
      docsToProcessing={docsToProcessing}
      getRows={(params) =>
        getRows(
          docsToProcessing
            ? {
                ...params,
                docsToProcessing,
              }
            : params,
        )
      }
      sideContent={SideContent}
      path={`/app/${DocumentAppConfig.todixId}`}
      onCellClickPath={`/app/${DocumentAppConfig.todixId}/inbox`}
      tabs={['stacked']}
      onRemoveItem={(id) =>
        deleteDocument(id)
          ?.then(() => {})
          .catch(() => {
            message.error(
              intl.formatMessage({
                id: 'products.deletedocument.error',
                defaultMessage: 'Error while deleting document',
              }),
            );
          })
      }
      onResourceChangeEventName="setDocument"
      onResourceSaveEventName="documentSaved"
      hideQuickFilter
      appConfig={DocumentAppConfig}
      action={'view-documents' as DOCUMENTS_ACTION}
    />
  );
};

export default Inbox;
