import { firebase } from '@services/Firebase';
import { post, put } from '@services/api';
import moment from 'moment';
import {
  TAX_CATEGORIES_COLLECTION,
  TaxCategory,
} from '@apps/finance/services/taxCategories/namespace';

export * from './namespace';

export const createTaxCategory = (category: TaxCategory): Promise<string> =>
  post(`${TAX_CATEGORIES_COLLECTION}`, {
    ...category,
    timestamp: moment().valueOf(),
  });

export const updateTaxCategory = (
  id: string,
  category: TaxCategory,
): Promise<void> =>
  put(`${TAX_CATEGORIES_COLLECTION}/${id}`, {
    ...category,
    timestamp: moment().valueOf(),
  });

export const getEcosystemTaxCategories = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection(TAX_CATEGORIES_COLLECTION)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((query) =>
      query.docs.map((doc) => ({ ...doc.data(), id: doc.id } as TaxCategory)),
    );
};

export const getEcosystemActiveTaxCategories = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection(TAX_CATEGORIES_COLLECTION)
    .where('ecosystemId', '==', ecosystemId)
    .where('isActive', '==', true)
    .get()
    .then((query) =>
      query.docs.map((doc) => ({ ...doc.data(), id: doc.id } as TaxCategory)),
    );
};
