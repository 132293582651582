import React from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getCompanyTypes } from '@services/dictionary';
import SelectWithSearch from '@components/SelectWithSearch';

const companyTypes = getCompanyTypes();
const CompanyType = ({ readOnly }: { readOnly?: boolean }) => (
  <Form.Item
    name="companyType"
    label={
      <FormattedMessage
        id="documentprocessing.step2.companytype.label"
        defaultMessage="Company type"
      />
    }
    required={false}
    rules={[
      {
        required: false,
        message: (
          <FormattedMessage
            id="documentprocessing.step2.companytype.error.required"
            defaultMessage="Missing company type"
          />
        ),
      },
    ]}
  >
    <SelectWithSearch
      loading={!companyTypes.length}
      placeholder={
        <FormattedMessage
          id="documentprocessing.step2.companytype.placeholder"
          defaultMessage="Select company type"
        />
      }
      options={companyTypes.sort().map((entry: string) => ({
        label: entry,
        value: entry,
      }))}
      disabled={readOnly}
    />
  </Form.Item>
);

export default CompanyType;
