import React, { FC, useState } from 'react';
import BreadcrumbsNavigation from '@components/BreadcrumbsNavigation';
import { useCustomEventListener } from '@core/services';

export type AppViewNavigationChange = {
  breadcrumbItems?: string[][];
  currentTab?: string;
  rowsCount?: number;
};

const AppViewNavigation: FC = () => {
  const [state, setState] = useState<NonNullable<AppViewNavigationChange>>({
    rowsCount: -1,
    breadcrumbItems: [],
    currentTab: '',
  });
  useCustomEventListener<string, AppViewNavigationChange>(
    'appViewNavigationChange',
    (data) => {
      if (data) {
        setState(data);
      }
    },
  );
  const { breadcrumbItems, rowsCount } = state;
  return (
    <BreadcrumbsNavigation
      items={breadcrumbItems || []}
      rowsCount={rowsCount}
    />
  );
};

export default AppViewNavigation;
