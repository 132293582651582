import {
  WAREHOUSE_STORAGE,
  WarehouseStorage,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';

const updateWarehouseStorage = (
  id: string,
  warehouseStorage: Partial<WarehouseStorage>,
) => {
  const { ecosystem } = warehouseStorage;
  return firebase.db?.ref(`${WAREHOUSE_STORAGE}/${ecosystem}/${id}`).update({
    ...warehouseStorage,
  });
};

export default updateWarehouseStorage;
