import { PageLayout } from '@apps/purchases/services';

const layout: PageLayout = [
  {
    field: 'supplier',
    style: {
      gridArea: 'supplier',
      display: 'flex',
      fontSize: '12px',
      position: 'relative',
      marginTop: '20px',
      marginBottom: '20px',
      minWidth: '280px',
      maxWidth: '280px',
    },
  },
  {
    field: 'documentType',
    readOnly: true,
    style: {
      gridArea: 'documentType',
      fontSize: '12px',
      marginTop: '20px',
      fontWeight: 'bold',
    },
  },
  {
    field: 'connectionArea',
    style: {
      gridArea: 'connectionArea',
      gridColumn: '4',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
    },
  },
  {
    field: 'documentNumber',
    readOnly: true,
    style: {
      gridArea: 'documentNumber',
      fontSize: '12px',
      marginTop: '20px',
      fontWeight: 'bold',
    },
  },
  {
    field: 'referenceNumber',
    style: {
      gridArea: 'referenceNumber',
      gridColumn: '4',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  {
    field: 'creationDate',
    style: {
      gridArea: 'creationDate',
      gridColumn: '4',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  {
    field: 'positions',
    style: {
      gridArea: 'positions',
      gridColumnEnd: 'span 4',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
      marginTop: '20px',
    },
  },
  {
    field: 'paymentTerms',
    style: {
      gridArea: 'paymentTerms',
      fontSize: '12px',
      minWidth: '240px',
    },
  },
  {
    field: 'footer',
    style: {
      gridArea: 'footer',
      gridColumnEnd: 'span 4',
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
  },
];

export default layout;
