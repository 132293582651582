import React, { FC, useState, useRef } from 'react';
import { Form, Select, Space, Tabs, Input, Collapse } from 'antd';
import { Text } from '@todix/ui-components';
import { useCustomEventListener } from '@core/services';
import { Radio } from '@components/FormFields';
import {
  CompoundFieldTabs,
  DivFlex,
  MaxItem,
} from '@apps/contacts/mainMenu/components/DetailContactForm/DetailContactForm.sc';
import { getAddressLabels, getCountries } from '@services/dictionary';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectWithSearch from '@components/SelectWithSearch';
import {
  DeleteOutlined,
  EnvironmentOutlined,
  EnvironmentTwoTone,
  PlusSquareOutlined,
} from '@ant-design/icons';
import {
  ON_FINISH_FAILED_EVENT,
  OnFinishFailedEvent,
} from '@components/AppEntityForm/AppEntityForm';
import Warning from '@apps/contacts/mainMenu/components/DetailContactForm/Warning';
import { FormListOperation } from 'antd/lib/form/FormList';
import ContentIndicator from '@apps/contacts/mainMenu/components/DetailContactForm/ContentIndicator';

const { TabPane } = Tabs;
const { Panel } = Collapse;

type AddressProps = {
  fieldName?: string | string[];
};

const Address: FC<AddressProps> = ({ fieldName }) => {
  const intl = useIntl();
  const { getFieldValue, setFields, readOnly } = useFormContext();
  const [activeKey, setActiveKey] = useState(`addresses0`);
  const [checkedValue, setCheckedValue] = useState(
    getFieldValue('standardAddress'),
  );
  const [hasError, setHasError] = useState(false);
  const addAddressRef = useRef<any>(() => {});
  const activePanel = useRef<string[]>([]);

  const countries = getCountries();
  // const [regions, setRegions] = React.useState<CountryEntry['regions']>([]);

  const setCountry = (country: string) => {
    if (country) {
      /*const foundedCountry = countries.find(
        entry => entry.countryName === country,
      );

      if (foundedCountry) {
        setRegions(foundedCountry.regions);
      }*/
    }
  };

  useCustomEventListener<'onFinishFailed', OnFinishFailedEvent>(
    ON_FINISH_FAILED_EVENT,
    (data) => {
      if (data) {
        const addressError =
          data.errorInfo.errorFields.filter((err) =>
            err.name.includes('addresses'),
          ).length > 0;
        if (addressError) {
          setHasError(true);
        } else {
          setHasError(false);
        }
      }
    },
  );

  const component = (
    { key, ...field }: any,
    fieldIndex: number,
    remove: FormListOperation['remove'],
  ) => {
    return (
      <DivFlex>
        <MaxItem>
          <Form.Item
            label={
              <FormattedMessage
                id="detailcontactform.address.label"
                defaultMessage="Address #{index}"
                values={{ index: fieldIndex + 1 }}
              />
            }
            style={{ maxWidth: '100%' }}
          >
            <Form.Item
              {...field}
              noStyle
              name={[field.name, `label`]}
              fieldKey={[field.fieldKey, `label`]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                disabled={readOnly}
                placeholder={
                  <FormattedMessage
                    id="detailcontactform.address.placeholder"
                    defaultMessage="Address label"
                  />
                }
                options={getAddressLabels(intl)}
                style={{ width: 140, marginRight: 4 }}
              />
            </Form.Item>
          </Form.Item>
          <Form.Item noStyle>
            {!readOnly && (
              <DeleteOutlined
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => {
                  remove(field.name);
                  if (checkedValue === fieldIndex) {
                    setFields([
                      {
                        name: 'standardAddress',
                        value: fieldIndex >= 1 ? fieldIndex - 1 : 0,
                      },
                    ]);
                    setCheckedValue(fieldIndex >= 1 ? fieldIndex - 1 : 0);
                  }
                }}
              />
            )}
          </Form.Item>
        </MaxItem>
        <Form.Item
          {...field}
          label={
            <FormattedMessage
              id="detailcontactform.address.country.label"
              defaultMessage="Country"
            />
          }
          name={[field.name, `country`]}
          fieldKey={[field.fieldKey, `country`]}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <SelectWithSearch
            disabled={readOnly}
            placeholder={
              <FormattedMessage
                id="detailcontactform.address.country.placeholder"
                defaultMessage="Country"
              />
            }
            loading={!countries.length}
            options={countries.map((entry) => ({
              label: entry.countryName,
              value: entry.countryName,
            }))}
            onChange={(v) => {
              setCountry(v as string);
              setFields([
                {
                  name: `addresses.${fieldIndex}.district`,
                  value: '',
                },
              ]);
            }}
          />
        </Form.Item>
        {/* <Form.Item
          {...field}
          label={
            <FormattedMessage
              id="detailcontactform.address.district.label"
              defaultMessage="District/State"
            />
          }
          name={[field.name, `district`]}
          fieldKey={[field.fieldKey, `district`]}
        >
          <Select
            disabled={readOnly}
            placeholder={
              <FormattedMessage
                id="detailcontactform.address.district.placeholder"
                defaultMessage="District/State"
              />
            }
            options={regions.map(region => ({
              label: region.name,
              value: region.name,
            }))}
          />
        </Form.Item>*/}

        <Form.Item
          label={
            <FormattedMessage
              id="detailcontactform.address.zipcity.label"
              defaultMessage="ZIP Code/City"
            />
          }
        >
          <Form.Item
            {...field}
            noStyle
            name={[field.name, `zipcode`]}
            fieldKey={[field.fieldKey, `zipcode`]}
            rules={[
              {
                pattern: /^\d{4,5}$/,
                message: (
                  <FormattedMessage
                    id="detailcontactform.address.zip.error"
                    defaultMessage="Invalid ZIP code"
                  />
                ),
              },
            ]}
          >
            <Input
              readOnly={readOnly}
              autoComplete="nope"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.address.zip.placeholder',
                defaultMessage: 'Zipcode',
              })}
              style={{ width: 120, marginRight: 4 }}
            />
          </Form.Item>
          <Form.Item
            {...field}
            noStyle
            name={[field.name, `city`]}
            fieldKey={[field.fieldKey, `city`]}
            rules={[
              {
                type: 'string',
                max: 40,
                message: (
                  <FormattedMessage
                    id="detailcontactform.address.city.error"
                    defaultMessage="City cannot be longer than 40 characters"
                  />
                ),
              },
            ]}
          >
            <Input
              readOnly={readOnly}
              autoComplete="nope"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.address.city.placeholder',
                defaultMessage: 'City',
              })}
              style={{ width: 'calc(100% - 124px)' }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="detailcontactform.address.streetandnumber.label"
              defaultMessage="Street and number"
            />
          }
        >
          <Form.Item
            {...field}
            noStyle
            name={[field.name, `street`]}
            fieldKey={[field.fieldKey, `street`]}
            rules={[
              {
                type: 'string',
                max: 40,
                message: (
                  <FormattedMessage
                    id="detailcontactform.address.street.error"
                    defaultMessage="Street name cannot be longer than 40 characters"
                  />
                ),
              },
            ]}
          >
            <Input
              readOnly={readOnly}
              autoComplete="nope"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.address.street.placeholder',
                defaultMessage: 'Street name',
              })}
              style={{ width: 'calc(100% - 124px)', marginRight: 4 }}
            />
          </Form.Item>
          <Form.Item
            {...field}
            noStyle
            name={[field.name, `number`]}
            fieldKey={[field.fieldKey, `number`]}
            rules={[
              {
                type: 'string',
                max: 40,
                message: (
                  <FormattedMessage
                    id="detailcontactform.address.number.error"
                    defaultMessage="Number cannot be longer than 40 characters"
                  />
                ),
              },
            ]}
          >
            <Input
              readOnly={readOnly}
              autoComplete="nope"
              placeholder="Number"
              style={{ width: 120 }}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item
          {...field}
          label={
            <FormattedMessage
              id="detailcontactform.address.additional.label"
              defaultMessage="Additional info"
            />
          }
          name={[field.name, `additionalInfo`]}
          fieldKey={[field.fieldKey, `additionalInfo`]}
          rules={[
            {
              type: 'string',
              max: 40,
              message: (
                <FormattedMessage
                  id="detailcontactform.address.additional.error"
                  defaultMessage="Info cannot be longer than 40 characters"
                />
              ),
            },
          ]}
        >
          <Input
            readOnly={readOnly}
            autoComplete="nope"
            placeholder={intl.formatMessage({
              id: 'detailcontactform.address.additional.placeholder',
              defaultMessage: 'Additional info...',
            })}
          />
        </Form.Item>

        <Form.Item>
          <Space size="small" direction="horizontal">
            <Radio
              checked={getFieldValue('standardInvoiceAddress') === fieldIndex}
              disabled={readOnly}
              onChange={() => {
                setFields([
                  {
                    name: 'standardInvoiceAddress',
                    value: fieldIndex,
                  },
                ]);
              }}
            />
            <Text level={2}>
              <FormattedMessage
                id="detailcontactform.address.standardinvoice.text"
                defaultMessage="Standard Invoice Address"
              />
            </Text>
          </Space>
        </Form.Item>

        <Form.Item>
          <Space size="small" direction="horizontal">
            <Radio
              checked={getFieldValue('standardShippingAddress') === fieldIndex}
              disabled={readOnly}
              onChange={() => {
                setFields([
                  {
                    name: 'standardShippingAddress',
                    value: fieldIndex,
                  },
                ]);
              }}
            />
            <Text level={2}>
              <FormattedMessage
                id="detailcontactform.address.standardshipping.text"
                defaultMessage="Standard Shipping Address"
              />
            </Text>
          </Space>
        </Form.Item>
      </DivFlex>
    );
  };

  return (
    <Collapse
      onChange={(keys) => {
        activePanel.current = keys as string[];
      }}
      expandIcon={() =>
        hasError ? (
          <div>
            <Warning />
            <ContentIndicator
              emptyIcon={<EnvironmentOutlined />}
              contentIcon={<EnvironmentTwoTone />}
              emptyIndicator={() =>
                getFieldValue('addresses')
                  ? (getFieldValue('addresses') as []).length < 1
                  : true
              }
            />
          </div>
        ) : (
          <div>
            <ContentIndicator
              emptyIcon={<EnvironmentOutlined />}
              contentIcon={<EnvironmentTwoTone />}
              emptyIndicator={() =>
                getFieldValue('addresses')
                  ? (getFieldValue('addresses') as []).length < 1
                  : true
              }
            />
          </div>
        )
      }
    >
      <Panel
        header={
          <FormattedMessage
            id="detailcontactform.address.title"
            defaultMessage="Address"
          />
        }
        key="1"
        extra={
          !readOnly ? (
            <PlusSquareOutlined
              onClick={(event) => {
                if (activePanel.current.length) {
                  event.stopPropagation();
                }
                addAddressRef.current();
              }}
            />
          ) : undefined
        }
        forceRender
      >
        <Form.Item noStyle shouldUpdate>
          {() => (
            <div>
              <Form.List name={fieldName || 'addresses'}>
                {(fields, { add, remove }) => {
                  addAddressRef.current = () => {
                    // first item will be added
                    const standard = fields.length === 0;
                    setActiveKey(`addresses${fields.length}`);
                    add({
                      standard,
                      additionalInfo: '',
                      city: '',
                      country: '',
                      district: '',
                      label: '',
                      number: '',
                      street: '',
                      zipcode: '',
                    });
                    if (standard) {
                      setFields([
                        {
                          name: 'standardAddress',
                          value: 0,
                        },
                      ]);
                      setCheckedValue(0);
                    }
                  };
                  return (
                    <>
                      <CompoundFieldTabs
                        activeKey={activeKey}
                        onChange={(activeKey) => setActiveKey(activeKey)}
                      >
                        {fields.map((field, index) => {
                          return (
                            <TabPane
                              key={`addresses${index}`}
                              tab={
                                <FormattedMessage
                                  id="detailcontactform.address.list.tab"
                                  defaultMessage="Address #{index}"
                                  values={{ index: index + 1 }}
                                />
                              }
                            >
                              {component(field, index, remove)}
                            </TabPane>
                          );
                        })}
                      </CompoundFieldTabs>
                    </>
                  );
                }}
              </Form.List>

              <Form.Item name="standardInvoiceAddress" hidden={true} />
              <Form.Item name="standardShippingAddress" hidden={true} />
            </div>
          )}
        </Form.Item>
      </Panel>
    </Collapse>
  );
};

export default Address;
