import { COLLECTION, PositionMonitor } from './namespace';
import { removeUndefinedProperties } from '@services/utils';
import { firebase } from '@services/Firebase';
import { Listener } from '../../../../../commonTypes';

export * from './namespace';

export const removePositionMonitor = (positionId: string) =>
  firebase.db?.ref(`${COLLECTION}/${positionId}`).remove();

export const getPositionMonitor = (positionId: string) =>
  firebase.db
    ?.ref(`${COLLECTION}/${positionId}`)
    .once('value')
    .then((result) => {
      if (!result.exists()) {
        return null;
      }
      const values = result.val();

      return values as PositionMonitor;
    });

export const createPositionMonitor = async (
  positionId: string,
  positionMonitor: PositionMonitor,
) => {
  await firebase.db?.ref(`${COLLECTION}/${positionId}`).set({
    ...removeUndefinedProperties(positionMonitor),
  });
};

export const setPositionMonitor = async (
  positionId: string,
  positionMonitor: PositionMonitor,
) => {
  await firebase.db?.ref(`${COLLECTION}/${positionId}`).set({
    ...removeUndefinedProperties(positionMonitor),
  });
};

export const updatePositionMonitor = (
  positionId: string,
  positionMonitor: Partial<PositionMonitor>,
) =>
  firebase.db?.ref(`${COLLECTION}/${positionId}`).update({
    ...removeUndefinedProperties(positionMonitor),
  });

export const positionMonitorListener: Listener = ({
  onAdd,
  onChanged,
  onRemove,
}) => {
  const ref = firebase.db?.ref(COLLECTION);
  ref?.on('child_added', (data) => {
    onAdd && onAdd(data);
  });
  ref?.on('child_changed', (data) => {
    onChanged && onChanged(data);
  });
  ref?.on('child_removed', (data) => {
    onRemove && onRemove(data);
  });

  const unsubscribe = () => {
    ref?.off();
  };

  return {
    unsubscribe,
  };
};
