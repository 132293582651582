import React, { FC } from 'react';
import { Form } from 'antd';
import { BucketStep } from '@apps/purchases/services/buckets';
import intl from '../../../../../intl';
import MoneyInput from '@components/MoneyInput';

const { Item } = Form;

const PositionTotal: FC = () => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const stepType = getFieldValue('stepType') as BucketStep;
        const currency = getFieldValue('currency');

        if (stepType === 'invoiced') {
          return (
            <div>
              <Item
                name="totalPosition"
                label={intl.formatMessage({
                  id: 'total.per.position',
                  defaultMessage: 'Total per position',
                })}
              >
                <MoneyInput
                  currency={currency}
                  readOnly
                  disabled
                  allowNegative={false}
                />
              </Item>
              <Item
                name="totalTaxPosition"
                label={intl.formatMessage({
                  id: 'total.tax.per.position',
                  defaultMessage: 'Total tax per position',
                })}
              >
                <MoneyInput
                  currency={currency}
                  readOnly
                  disabled
                  allowNegative={false}
                />
              </Item>
              <Item
                name="totalGrossPosition"
                label={intl.formatMessage({
                  id: 'total.gross.per.position',
                  defaultMessage: 'Total gross per position',
                })}
              >
                <MoneyInput
                  currency={currency}
                  readOnly
                  disabled
                  allowNegative={false}
                />
              </Item>
            </div>
          );
        }
        return null;
      }}
    </Item>
  );
};

export default PositionTotal;
