import React from 'react';
import moment from 'moment';
import { DatePicker, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const disabledDate = (current: moment.Moment) =>
  current && current > moment().endOf('day');

const FoundationDate = ({ readOnly }: { readOnly?: boolean }) => {
  const intl = useIntl();
  return (
    <Form.Item
      name="foundationDate"
      label={
        <FormattedMessage
          id="documentprocessing.step2.foundationdate.label"
          defaultMessage="Foundation date"
        />
      }
    >
      <DatePicker
        format={moment.defaultFormat}
        disabledDate={disabledDate}
        name="foundationDate"
        disabled={readOnly}
        placeholder={intl.formatMessage({
          id: 'documentprocessing.step2.foundationdate.placeholder',
          defaultMessage: 'Select date',
        })}
      />
    </Form.Item>
  );
};
export default FoundationDate;
