import React, { FC } from 'react';
import { ContactDetails } from '@apps/documents/widgets/ContactDetails';
import { Form as AForm } from 'antd';

type FormContactDetailsType = {
  form: any;
  contactFormName: string;
  contactSnapshotFormName: string;
  label: any;
  rules?: any;
};

export const FormContactDetails: FC<FormContactDetailsType> = ({
  form,
  contactFormName,
  contactSnapshotFormName,
  label,
  rules,
}) => {
  return (
    <AForm.Item
      dependencies={[contactFormName, contactSnapshotFormName, 'ecosystem']}
      label={label}
    >
      {() => {
        return (
          <>
            <ContactDetails
              ecosystemId={form.getFieldValue('ecosystem')}
              defaultContact={form.getFieldValue(contactFormName)}
              defaultContactSnapshot={form.getFieldValue(
                contactSnapshotFormName,
              )}
              contactFormName={contactFormName}
              contactSnapshotFormName={contactSnapshotFormName}
              form={form}
            />
            <AForm.Item name={contactFormName} rules={rules}></AForm.Item>
          </>
        );
      }}
    </AForm.Item>
  );
};
