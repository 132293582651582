import React, { FC } from 'react';
import OriginalAutoCompleteContact from '../mainMenu/AutoCompleteContact';
import { Form as AForm } from 'antd';
import { Contact, getContact } from '@apps/contacts/services';

type AutoCompleteContactProps = {
  disabled: boolean;
  form: any;
  contactFormName: string;
  contactSnapshotFormName: string;
  label: any;
  required: boolean;
};

export const FormAutoCompleteContact: FC<AutoCompleteContactProps> = ({
  form,
  contactFormName,
  contactSnapshotFormName,
  label,
  required,
  ...rest
}) => (
  <AForm.Item dependencies={['ecosystem']} label={label} required={required}>
    {() => {
      const disabled = !form.getFieldValue('ecosystem');

      return (
        <OriginalAutoCompleteContact
          ecosystem={form.getFieldValue('ecosystem')}
          onSelect={(passedContact: Contact, existed) => {
            form.setFieldsValue({
              [contactFormName]: { ...passedContact, existed },
            });
            if (existed) {
              getContact(passedContact?.id as string).then((details) => {
                form.setFieldsValue({ [contactSnapshotFormName]: details });
              });
            } else {
              form.setFieldsValue({ [contactSnapshotFormName]: null });
            }
          }}
          {...rest}
          disabled={disabled}
        />
      );
    }}
  </AForm.Item>
);
