import { post } from '@services/api';
import {
  PRODUCT_PURCHASE_PRICES,
  ProductPurchasePrice,
} from '@apps/products/services/namespace';
import moment from 'moment/moment';

const createProductPurchasePrice = (price: ProductPurchasePrice) => {
  return post(PRODUCT_PURCHASE_PRICES, {
    ...price,
    timestamp: moment().valueOf(),
  });
};

export default createProductPurchasePrice;
