import React from 'react';
import intl from '../../../../../../../../intl';
import MoneyInput from '@components/MoneyInput';
import { Form } from 'antd';
const { Item } = Form;

const UnitAmount: React.FC = () => {
  return (
    <Item
      noStyle
      shouldUpdate={(prevValues, nextValues) =>
        prevValues.currency !== nextValues.currency
      }
    >
      {({ getFieldValue }) => {
        const currency = getFieldValue('currency');
        return (
          <Item
            name="unitAmount"
            label={intl.formatMessage({
              id: 'sales.salesTab.unitAmount.label',
              defaultMessage: 'Price',
            })}
            required
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'sales.salesTab.unitAmount.validationError',
                  defaultMessage: 'Please enter price',
                }),
              },
            ]}
          >
            <MoneyInput currency={currency} />
          </Item>
        );
      }}
    </Item>
  );
};

export default UnitAmount;
