import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/de';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '@todix/ui-components';
import { BrowserRouter as Router } from 'react-router-dom';
import enUS from 'antd/lib/locale/en_US';
import deDE from 'antd/lib/locale/de_DE';

import { RawIntlProvider } from 'react-intl';

import { defaultTheme } from '@styles/themes';
import { AppRoutes } from '@core/config/routes';
import { rootStore } from '@core/store';

import '@assets/external-styles/main.css';
import 'animate.css';
import 'material-symbols';
import intl from './intl';
import { ConfigProvider } from 'antd';
import EmptyResult from '@components/EmptyResult';

import { UserAgentContext, getUserAgentData } from './userAgentContext';
import { connectedApps } from '@apps/connectedApps';

(window as any).connectedApps = connectedApps;

const antDLocalization = intl.locale === 'de' ? deDE : enUS;

ReactDOM.render(
  <UserAgentContext.Provider value={getUserAgentData()}>
    <Provider store={rootStore}>
      <RawIntlProvider value={intl}>
        <ConfigProvider
          locale={antDLocalization}
          getPopupContainer={(_mode) => {
            const mainElement = document
              .getElementsByClassName('fullscreen')
              .item(0) as HTMLElement;
            return mainElement || document.body;
          }}
          getTargetContainer={() => {
            const mainElement = document
              .getElementsByClassName('fullscreen')
              .item(0) as HTMLElement;
            return mainElement || document.body;
          }}
          renderEmpty={() => <EmptyResult />}
        >
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyles {...defaultTheme} />
            <Router>
              <AppRoutes />
            </Router>
          </ThemeProvider>
        </ConfigProvider>
      </RawIntlProvider>
    </Provider>
  </UserAgentContext.Provider>,
  document.getElementById('root'),
);
