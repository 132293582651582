import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import usePurchasesRangeOfNumbers from '@apps/purchases/widgets/usePurchasesRangeOfNumbers';
import SpinContainer from '@components/SpinContainer';
import { supportedFileTypes } from '@apps/purchases/settings/rangeOfNumbers/consts';
import StyledTable from '../../../../pages/settings/components/StyledTable';
import columns from '@apps/purchases/settings/rangeOfNumbers/columns';
import addPurchasesRangeOfNumbers from '@apps/purchases/services/rangeOfNumbers/addPurchasesRangeOfNumbers';

const RangeOfNumbers: FC = () => {
  const intl = useIntl();
  const { uid: ecoId } = useParams<any>();
  const [areDataRetrieved, rangeOfNumbers, setRangeOfNumbers] =
    usePurchasesRangeOfNumbers(ecoId);
  const [isLoading, setIsLoading] = useState<boolean>(areDataRetrieved);

  useEffect(() => {
    setIsLoading(areDataRetrieved);
  }, [areDataRetrieved]);

  if (isLoading) {
    return <SpinContainer />;
  }

  return (
    <Card
      title={
        <FormattedMessage
          id="settings.sales.groups.rangeOfNumbers.menuTitle"
          defaultMessage="settings.sales.groups.rangeOfNumbers.menuTitle"
        />
      }
    >
      <Form
        onFinish={(values) => {
          setIsLoading(true);
          addPurchasesRangeOfNumbers(
            {
              ...values,
              ecosystemId: ecoId,
            },
            ecoId,
          )
            .then(() =>
              setRangeOfNumbers({
                ...values,
                ecosystemId: ecoId,
              }),
            )
            .finally(() => setIsLoading(false));
        }}
        initialValues={{
          fileTypes: (rangeOfNumbers && rangeOfNumbers.fileTypes) || {
            ...Object.keys(supportedFileTypes).reduce((prev: any, fileType) => {
              prev[fileType] = {
                abbreviation: intl.formatMessage({
                  id: `${fileType}.abbreviation`,
                  defaultMessage: `${fileType}.abbreviation`,
                }),
              };
              return prev;
            }, {}),
          },
        }}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <StyledTable
          dataSource={Object.keys(supportedFileTypes).map((el) => ({
            fileType: el,
          }))}
          columns={columns}
          tableLayout="auto"
          pagination={false}
          rowKey={(_product: any, index: any) => index as number}
        />
        <Button
          type="primary"
          disabled={isLoading}
          htmlType="submit"
          style={{ marginTop: 18, marginLeft: 'auto' }}
        >
          <FormattedMessage id="button.save" defaultMessage="Save" />
        </Button>
      </Form>
    </Card>
  );
};

export default RangeOfNumbers;
