import { styled } from '@styles/themes';
import { Button } from 'antd';

export const Toolbar = styled.div`
  display: flex;
  position: relative;
  padding: 1px 18px 17px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
`;

export const ButtonWithMargins = styled(Button)`
  margin-right: 5px;
`;
export const SaveButton = styled(Button)`
  margin-left: 20px;
`;
