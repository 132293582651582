import React, { useEffect, useState, FC } from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import {
  getEcosystemTaxCategories,
  TaxCategory,
} from '@apps/finance/services/taxCategories';

type TaxSelectType = {
  readOnly?: boolean;
  style?: Record<string, any>;
  ecosystemId?: string;
};

export const TaxSelect: FC<TaxSelectType> = ({
  readOnly,
  ecosystemId,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [taxRates, setTaxRates] = useState<any>(null);
  const intl = useIntl();

  useEffect(() => {
    if (ecosystemId) {
      getEcosystemTaxCategories(ecosystemId)
        ?.then((el) =>
          setTaxRates(
            el.map((tax: TaxCategory) => ({
              value: tax.value,
              label: tax.label[intl.locale],
            })),
          ),
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [ecosystemId, intl.locale]);

  return (
    <Select
      {...rest}
      placeholder={
        <FormattedMessage
          id="product.salesTab.selectTag.label"
          defaultMessage="Select tax"
        />
      }
      loading={isLoading}
      options={taxRates}
      disabled={readOnly}
    />
  );
};
