import React, { FC, useMemo } from 'react';
import { Form, Modal, Input } from 'antd';
import intl from '../../../../../intl';
import { useEffect } from 'react';
import { addUnit, updateUnit } from '@services/unitGroups';
import { useDispatch } from 'react-redux';
import { fetchUnitGroups } from '@core/layout/store/unitGroupsSaga/actions';
import { Unit, UnitGroup, UnitGroups } from '@services/unitGroups/namespace';
import { StyledSwitch } from '@pages/settings/components';
import { FormattedMessage } from 'react-intl';

type UnitFormModalProps = {
  ecosystemId: string;
  visible: boolean;
  setVisible: (arg: boolean) => void;
  selectedUnit: Unit | undefined;
  unitGroups: UnitGroups;
  selectedUnitGroup: UnitGroup | undefined;
  setSelectedUnitGroup: (arg: UnitGroup | undefined) => void;
  setSelectedUnit: (arg: Unit | undefined) => void;
};

export const UnitFormModal: FC<UnitFormModalProps> = ({
  ecosystemId,
  visible,
  setVisible,
  unitGroups,
  selectedUnit,
  setSelectedUnit,
  setSelectedUnitGroup,
  selectedUnitGroup,
}) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const initialValues = selectedUnit ? selectedUnit : { isActive: true };

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  return (
    <>
      <Modal
        visible={visible}
        title={intl.formatMessage({
          id: 'settings.unit.modal.createNewUnit.title',
          defaultMessage: 'Create new unit',
        })}
        okText={
          selectedUnit
            ? intl.formatMessage({
                id: 'settings.products.groups.modal.edit',
                defaultMessage: 'Edit',
              })
            : intl.formatMessage({
                id: 'settings.products.groups.modal.create',
                defaultMessage: 'Create',
              })
        }
        cancelText="Cancel"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          if (selectedUnit) {
            setSelectedUnit(undefined);
            setSelectedUnitGroup(undefined);
          }
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setConfirmLoading(true);
              if (selectedUnit) {
                await updateUnit(
                  values,
                  selectedUnit.name,
                  ecosystemId,
                  unitGroups,
                  selectedUnitGroup as unknown as UnitGroup,
                );
                setSelectedUnit(undefined);
                setSelectedUnitGroup(undefined);
              } else {
                await addUnit(
                  values,
                  ecosystemId,
                  unitGroups,
                  selectedUnitGroup as unknown as UnitGroup,
                );
              }
              form.resetFields();
              setConfirmLoading(false);
              setVisible(false);
              setSelectedUnitGroup(undefined);
              dispatch(fetchUnitGroups());
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initialValues}
        >
          <Form.Item
            name="name"
            label={intl.formatMessage({
              id: 'settings.products.groups.creationModal.name.label',
              defaultMessage: 'Name',
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'settings.products.groups.creationModal.name.error',
                  defaultMessage: 'Name field is required',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="isActive"
            label={intl.formatMessage({
              id: 'settings.products.groups.creationModal.isActive.label',
              defaultMessage: 'Status',
            })}
          >
            <StyledSwitch
              onChange={(value) => form.setFieldsValue({ isActive: value })}
              checkedChildren={activated}
              unCheckedChildren={deactivated}
              defaultChecked={initialValues.isActive}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
