import React from 'react';
import SpinContainer from '@components/SpinContainer';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';

const withGuest = (Component: any) => {
  return (props: any) => {
    const { data: user, isLoading } = useSelector(getUser);

    if (isLoading) {
      return <SpinContainer />;
    }
    if (user && user.emailVerified) {
      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  };
};

export default withGuest;
