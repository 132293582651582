import React, { useCallback } from 'react';
import { InputProps } from 'antd/lib/input';
import { Input } from 'antd';
import { styled } from '@styles/themes';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-right: 5px;
  }
`;

const InnerInput: React.FC<InputProps> = ({ value, children, ...props }) => {
  const handleBlur = useCallback((e) => {
    e.target.value = e?.target?.value ? Number(e?.target?.value).toFixed(2) : 0;
    if (props && props.onChange) {
      props.onChange(e);
    }
  }, []);

  return (
    <Wrapper>
      <Input
        {...props}
        value={value}
        onBlur={handleBlur}
        type="number"
        min={0.0}
        step={0.01}
      />
      {children}
    </Wrapper>
  );
};

export default InnerInput;
