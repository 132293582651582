import React, { FC, useCallback, useEffect } from 'react';
import { Modal, ModalProps, Form, Input, Select, Switch } from 'antd';
import intl from '../../../../../intl';
import { FormattedMessage } from 'react-intl';
import UserFieldOptions from '@apps/products/settings/UserFields/UserFieldOptions';
import {
  UserField,
  createProductUserField,
  updateProductUserField,
} from '@apps/products/services';

const { Item } = Form;
const { Option } = Select;

type UserFieldModalProps = ModalProps & {
  ecosystemId?: string;
  selectedUserField?: UserField;
};

const UserFieldModal: FC<UserFieldModalProps> = ({
  visible,
  onOk,
  onCancel,
  selectedUserField,
  ecosystemId,
}) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();
  const handleOk = useCallback(
    (e) => {
      form.validateFields().then(async (values) => {
        setConfirmLoading(true);
        if (selectedUserField) {
          await updateProductUserField({
            ...selectedUserField,
            ...values,
            ecosystemId,
          });
        } else {
          await createProductUserField({
            ...values,
            ecosystemId,
          });
        }
        form.resetFields();
        setConfirmLoading(false);
        if (onOk) {
          onOk(e);
        }
      });
    },
    [ecosystemId, form, onOk, selectedUserField],
  );

  const handleCancel = useCallback(
    (e) => {
      form.resetFields();
      if (onCancel) {
        onCancel(e);
      }
    },
    [onCancel, form],
  );

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  const initialValues = selectedUserField ? selectedUserField : {};

  const options = (
    <>
      <Option key="text" value="text">
        <FormattedMessage
          id="settings.products.userField.text"
          defaultMessage="Text"
        />
      </Option>
      <Option key="number" value="number">
        <FormattedMessage
          id="settings.products.userField.number"
          defaultMessage="Number"
        />
      </Option>
      <Option key="dropdown" value="dropdown">
        <FormattedMessage
          id="settings.products.userField.dropdown"
          defaultMessage="Dropdown"
        />
      </Option>
      <Option key="datepicker" value="datepicker">
        <FormattedMessage
          id="settings.products.userField.datepicker"
          defaultMessage="Datepicker"
        />
      </Option>
    </>
  );

  return (
    <Modal
      visible={visible}
      title={intl.formatMessage({
        id: 'settings.products.userFields.createNewButton',
        defaultMessage: 'settings.products.userFields.createNewButton',
      })}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
    >
      <Form form={form} name="form_in_modal" initialValues={initialValues}>
        <Item
          name="label"
          label={intl.formatMessage({
            id: 'settings.products.userFields.creationModal.label.label',
            defaultMessage: 'Label',
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'settings.products.userFields.creationModal.label.error',
                defaultMessage: 'Label field is required',
              }),
            },
          ]}
        >
          <Input />
        </Item>
        <Item
          name="type"
          label={intl.formatMessage({
            id: 'settings.products.userFields.creationModal.type.label',
            defaultMessage: 'Type',
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'settings.products.userFields.creationModal.type.error',
                defaultMessage: 'Type field is required',
              }),
            },
          ]}
        >
          <Select>{options}</Select>
        </Item>
        <Item noStyle={true} shouldUpdate={true}>
          {(form) => {
            return (
              <Item
                name="required"
                label={intl.formatMessage({
                  id: 'settings.products.userFields.creationModal.required.label',
                  defaultMessage: 'Required',
                })}
              >
                <Switch checked={form.getFieldValue('required')} />
              </Item>
            );
          }}
        </Item>
        <Item
          noStyle={true}
          shouldUpdate={(prevV, nextV) => prevV.type !== nextV.type}
        >
          {(form) => {
            const type = form.getFieldValue('type');

            if (type !== 'dropdown') {
              return null;
            }

            return <UserFieldOptions />;
          }}
        </Item>
      </Form>
    </Modal>
  );
};

export default UserFieldModal;
