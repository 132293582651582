import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Breadcrumb, Typography, Tabs, Row, Col } from 'antd';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { SearchOutlined, CloseSquareOutlined } from '@ant-design/icons';

import { Button } from '../Button/Button';
import { ColumnApi } from 'ag-grid-community';

const { Title } = Typography;
const { TabPane } = Tabs;

const PageTitle = styled(Title)`
  && {
    span {
      font-weight: 400;
      margin-left: 0.4em;
    }
  }
`;
const TabsRightAligned = styled(Tabs)`
  .ant-tabs-nav-list {
    margin-left: auto;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0e7d40;
  }
  .ant-tabs-ink-bar {
    background: #0e7d40;
  }
`;
const QuickFilterWrapper = styled('div')`
  position: relative;
`;
const QuickFilterInput = styled('input')<
  React.InputHTMLAttributes<HTMLInputElement>
>`
  border: none;
  background: none;
  padding: 10px 20px;
  outline: none;
`;
const QuickFilterIcon = styled(SearchOutlined)`
  left: 10px;
  position: relative;
`;
const QuickFilter = (props: React.InputHTMLAttributes<HTMLInputElement>) => (
  <QuickFilterWrapper>
    <QuickFilterIcon />
    {/*// @ts-ignore */}
    <QuickFilterInput {...props} />
  </QuickFilterWrapper>
);

const AgThemeTodix = styled('div')`
  height: 80vh;
  margin-top: -10px;
  .ag-root {
    background: #fff;
  }
  .ag-header {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  }
  .ag-header,
  .ag-header-row {
    min-height: 40px !important;
  }

  .ag-header-cell {
    border: solid 1px #e8e8e8;
    height: 40px;
    padding: 10px 24px;
    color: ${({ theme }) => theme.colors.text.gray};
  }
  .ag-row .ag-cell:first-of-type {
    font-weight: 600;
  }
  .ag-cell {
    border: solid 1px #e8e8e8;
    padding: 16px 24px;
    color: #262626;
  }
  .ag-input-wrapper {
    width: 16px;
    margin-right: 10px;
  }

  .ag-menu {
    background: #fff;
    padding: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    min-width: 100px;
    .ag-filter-condition {
      display: none;
    }
    .ag-filter-body-wrapper {
      .ag-filter-body[ref='eCondition2Body'] {
        display: none;
      }
    }
  }
  .ag-select {
    display: none;
  }
  .ag-menu {
    padding: 10px 16px;
    .ag-input-wrapper {
      margin: 0;
      width: initial;
    }
    .ag-input-field-input {
      border: solid 1px #d9d9d9;
      border-radius: 2px;
      padding: 2px 8px;
    }
  }
`;

export type ListProps = {};

export const List: FC = () => {
  const [state, setState] = useState([]);
  const [quickFilterText, setQuickFilterText] = useState('');
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);

  useEffect(() => {
    fetch('https://randomuser.me/api/?results=50')
      .then((r) => r.json())
      .then((response) => setState(response.results));
  }, []);

  const onGridReady = ({ columnApi }: { columnApi: ColumnApi }) => {
    setGridColumnApi(columnApi);
  };

  const clearSort = () => {
    if (gridColumnApi) {
      gridColumnApi.applyColumnState({ defaultState: { sort: null } });
    }
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item href="">Application Center</Breadcrumb.Item>
        <Breadcrumb.Item href="">Application List</Breadcrumb.Item>
        <Breadcrumb.Item>An Application</Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle level={2}>
        List view:
        <span>{state.length} elements</span>
      </PageTitle>
      <TabsRightAligned defaultActiveKey="1" onChange={() => {}}>
        <TabPane tab="List view" key="1">
          <AgThemeTodix>
            <Row align="middle" justify="space-between">
              <Col>
                <QuickFilter
                  placeholder="Find on this list"
                  value={quickFilterText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setQuickFilterText(e.target.value)
                  }
                />
              </Col>
              <Col>
                <Button
                  type="dashed"
                  size="small"
                  icon={<CloseSquareOutlined />}
                  onClick={clearSort}
                >
                  Clear sort
                </Button>
              </Col>
            </Row>
            <AgGridReact
              rowData={state}
              rowHeight={55}
              quickFilterText={quickFilterText}
              defaultColDef={{
                filter: true,
              }}
              onGridReady={onGridReady}
            >
              <AgGridColumn
                field="ecosystem"
                valueGetter={() => 'Espeo'}
                checkboxSelection={true}
                headerCheckboxSelection
                sortable
              />
              <AgGridColumn field="Image" valueGetter={() => ''} />
              <AgGridColumn field="name.first" sortable />
              <AgGridColumn field="name.last" sortable />
              <AgGridColumn
                field="company"
                valueGetter={() => 'Espeo'}
                sortable
              />
              <AgGridColumn field="employer" valueGetter={() => '...'} />
              <AgGridColumn
                field="title position"
                valueGetter={() => 'Espeo'}
              />
              <AgGridColumn field="phone" valueGetter={() => 'worker'} />
              <AgGridColumn field="email" />
            </AgGridReact>
          </AgThemeTodix>
        </TabPane>
        <TabPane tab="Stacked view" key="2">
          In progress
        </TabPane>
        <TabPane tab="Cards view" key="3">
          In progress
        </TabPane>
        <TabPane tab="Network view" key="4">
          In progress
        </TabPane>
      </TabsRightAligned>
    </div>
  );
};
