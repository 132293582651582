import React from 'react';
import { Tag, Space } from 'antd';
import { ProductPrice } from '@apps/products/services';
import getValueWithCurrency from '@services/currency/getValueWithCurrency';
import {
  FlexSpaceBetween,
  ItalicSmall,
} from '@apps/sales/widgets/SalesTab/styles.sc';
import { FormattedMessage } from 'react-intl';

type Props = {
  price: ProductPrice;
};

const PriceName: React.FC<Props> = ({ price }) => {
  return (
    <FlexSpaceBetween>
      <div>
        <div>
          {getValueWithCurrency(price.unitAmount, price.currency).format()}{' '}
          {price.currency}
        </div>
        {price.description && <ItalicSmall>{price.description}</ItalicSmall>}
      </div>
      <Space direction="vertical" size="small">
        {price.isDefaultPrice && (
          <Tag color="green">
            <FormattedMessage
              id="sales.salesTab.isDefaultPrice.label"
              defaultMessage="Default price"
            />
          </Tag>
        )}
        {price.isFallbackPrice && (
          <Tag color="blue">
            <FormattedMessage
              id="sales.salesTab.isFallbackPrice.label"
              defaultMessage="Fallback price"
            />
          </Tag>
        )}
      </Space>
    </FlexSpaceBetween>
  );
};

export default PriceName;
