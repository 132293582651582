import React, { FC } from 'react';
import EmptyResult from '@components/EmptyResult';

const PurchasePlot: FC = () => {
  return (
    <div style={{ minHeight: '300px', height: '300px' }}>
      <EmptyResult />
    </div>
  );
};

export default PurchasePlot;
