import { styled } from '@styles/themes';
import { Button } from 'antd';

export const ButtonColorSelector = styled(Button)`
  width: 28px;
  height: 28px;
  border-radius: 8px;
  border: 3px solid #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  padding: 0;
`;
