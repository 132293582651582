import React from 'react';
import SpinContainer from '@components/SpinContainer';
import { getEcosystems } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import { Avatar, Tooltip } from 'antd';

export type EcosystemRendererProps = {
  data: {
    ecosystem: string;
  };
};

export const EcosystemRenderer: React.FC<EcosystemRendererProps> = ({
  data,
}) => {
  if (!data) {
    return <SpinContainer />;
  }
  const ecosystems = getEcosystems(rootStore.getState());
  const ecosystem = ecosystems.find((e) => e.id === data.ecosystem);
  if (!ecosystem) {
    return <span />;
  }
  return (
    <Tooltip placement="bottom" title={ecosystem.name}>
      <Avatar src={ecosystem.avatarUrl} size="small" />
    </Tooltip>
  );
};

export default EcosystemRenderer;
