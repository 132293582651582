import * as React from 'react';
import { Select as AntdSelect } from 'antd';
import { SelectProps as AntdSelectProps } from 'antd/lib/select';
import { Label } from '@components/FormFields';
import { FlexWrapper } from '@components/FormFields/common.sc';

type CommonProps = {
  name?: string;
  label?: string;
};

export type SelectProps<T = any> = CommonProps &
  AntdSelectProps<T> & { children?: React.ReactNode };

export const Select = (props: SelectProps) => {
  return (
    <FlexWrapper>
      {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
      <AntdSelect<any>
        {...props}
        getPopupContainer={() => {
          const mainElement = document
            .getElementsByClassName('fullscreen')
            .item(0) as HTMLElement;
          return mainElement || document.body;
        }}
      >
        {props.children}
      </AntdSelect>
    </FlexWrapper>
  );
};

export default Select;

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
