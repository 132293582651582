import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import {
  ENTITY_RELATIONS_COLLECTION,
  EntityRelation,
} from '@services/entityRelations/namespace';

type GetAnyChildrenForParentPayload = {
  parentId: string;
  ecosystem: string;
};

const getAnyChildrenForParent = ({
  parentId,
  ecosystem,
}: GetAnyChildrenForParentPayload) => {
  return firebase.firestore
    ?.collection(ENTITY_RELATIONS_COLLECTION)
    .where('parentId', '==', parentId)
    .where('ecosystem', '==', ecosystem)
    .get()
    .then(getArrayWithIds) as Promise<EntityRelation[]>;
};

export default getAnyChildrenForParent;
