import { StripeProduct } from '@services/stripe/namespace';
import {
  StripeProductsActions,
  StripeProductsActionTypes,
} from '@core/store/stripeProducts/actions';

export type StripeProductsState = StripeProduct[];

const initial: StripeProduct[] = [];
export const stripeProductsReducer = (
  state = initial,
  action: StripeProductsActions,
) => {
  switch (action.type) {
    case StripeProductsActionTypes.SET_STRIPE_PRODUCTS: {
      return action.payload.map((app) => {
        const todixId = app.metadata?.todixId;
        if (todixId) {
          return {
            ...app,
            todixId,
          };
        }
        return app;
      });
    }
    default: {
      return state;
    }
  }
};
