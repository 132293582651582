import React, { FC, Suspense, useState } from 'react';
import SpinContainer from '@components/SpinContainer';
import { useIntl } from 'react-intl';
import { Button, message, Result } from 'antd';
import { firebase } from '@services/Firebase';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';

const VerifyEmail: FC = () => {
  const intl = useIntl();
  const [sendingEmail, setSendingEmail] = useState(false);
  const history = useHistory();
  const { data: user, isLoading } = useSelector(getUser);

  if (!isLoading && user?.emailVerified) {
    history.push('/');
  }

  return (
    <Suspense fallback={<SpinContainer />}>
      {!isLoading && (
        <Result
          status={'info'}
          title={intl.formatMessage({
            id: 'error.verifyemail',
            defaultMessage: 'You need to verify your email address.',
          })}
          subTitle={intl.formatMessage({
            id: 'check.your.email',
            defaultMessage:
              'We send you confirmation email with link to confirm your email address. Please, check your email box.',
          })}
          extra={[
            <Button
              type="primary"
              key="dashboard"
              onClick={() => (window.location.href = window.location.origin)}
              disabled={sendingEmail}
            >
              {intl.formatMessage({
                id: 'order.dashboard.link',
                defaultMessage: 'Go to dashboard',
              })}
            </Button>,
            <Button
              key="email"
              disabled={sendingEmail}
              onClick={() => {
                setSendingEmail(true);
                firebase.auth.currentUser
                  ?.sendEmailVerification()
                  .then(() => {
                    message.success(
                      intl.formatMessage({
                        id: 'verification.email.send',
                        defaultMessage:
                          'Verification email has been send. Check your email box.',
                      }),
                    );
                  })
                  .finally(() => {
                    setSendingEmail(false);
                  });
              }}
            >
              {intl.formatMessage({
                id: 'resend.verification.email',
                defaultMessage: 'Resend verification email',
              })}
            </Button>,
          ]}
        />
      )}
      {sendingEmail && <SpinContainer />}
    </Suspense>
  );
};

export default VerifyEmail;
