import React, { FC, useState, useEffect, useCallback } from 'react';
import { Tree } from 'antd';
// eslint-disable-next-line prettier/prettier
import type { DataNode } from 'antd/lib/tree';
import { getTask, Task } from '@apps/tasks/services';
import { ApiOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import getParentsForChild from '@services/entityRelations/getParentsForChild';
import { EventDataNode } from 'antd/lib/tree';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';

type TasksProps = {
  tasks: Partial<Task>[];
  onSubtaskClick(subtask: Partial<Task>): void;
  readOnly: boolean;
  id?: string;
  ecosystem?: string;
  parentType: string;
};

const Tasks: FC<TasksProps> = ({
  tasks,
  onSubtaskClick,
  readOnly,
  id,
  ecosystem,
  parentType,
}) => {
  const [parents, setParents] = useState<Task[]>([]);
  const [tree, setTree] = useState<DataNode[]>([]);

  useEffect(() => {
    if (id && ecosystem) {
      getParentsForChild({
        childId: id,
        parentType,
        ecosystem,
      }).then((fetchedParents) => {
        const parentsPromises = fetchedParents.map((parent) =>
          getTask(parent.parentId),
        );
        Promise.all(parentsPromises).then((results) => {
          setParents(results);
        });
      });
    }
  }, [ecosystem, id, parentType]);

  const updateTreeData = useCallback(
    (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
      list.map((node) => {
        if (node.key === key) {
          return {
            ...node,
            children,
          };
        }
        if (node.children) {
          return {
            ...node,
            children: updateTreeData(node.children, key, children),
          };
        }
        return node;
      }),
    [],
  );

  const onLoadData = useCallback(
    (treeNode: EventDataNode) => {
      if (!ecosystem) {
        return Promise.resolve();
      }
      const keyParts = (treeNode.key as string).split('-');
      const key = keyParts[keyParts.length - 1];
      return getChildrenForParent({
        parentId: key,
        ecosystem,
        childType: 'task',
      }).then((entities) => {
        const tasksPromises = entities
          .map((entity) => entity.childId)
          .map((id) => getTask(id));
        Promise.all(tasksPromises).then((results) => {
          setTree((origin) =>
            updateTreeData(
              origin,
              treeNode.key,
              results.map((task) => ({
                title: task.title,
                key: `${treeNode.key}-${task.id as string}`,
                selectable: false,
                checkable: false,
              })),
            ),
          );
        });
      });
    },
    [ecosystem, updateTreeData],
  );

  useEffect(() => {
    if (tasks.length) {
      const treeData: DataNode[] = [
        {
          title: (
            <FormattedMessage
              id="tasks.subtasks.header"
              defaultMessage="Subtasks"
            />
          ),
          key: id || 'currentTask',
          selectable: false,
          checkable: false,
          children: tasks.map((subTask) => ({
            icon: (
              <ApiOutlined
                onClick={() => !readOnly && onSubtaskClick(subTask)}
              />
            ),
            title: (
              <span onClick={() => !readOnly && onSubtaskClick(subTask)}>
                {subTask.title}
              </span>
            ),
            key: subTask.id as string,
            selectable: false,
          })),
        },
      ];

      setTree(treeData);
    }
  }, [id, onSubtaskClick, readOnly, tasks]);

  const parentsTreeData: DataNode[] = [
    {
      title: (
        <FormattedMessage
          id="tasks.subtasks.parents"
          defaultMessage="Parents"
        />
      ),
      key: 'parents',
      selectable: false,
      checkable: false,
      children: parents.map((parent) => ({
        icon: <ApiOutlined />,
        title: <span>{parent.title}</span>,
        key: parent.id as string,
        selectable: false,
      })),
    },
  ];

  return (
    <div>
      {parents.length > 0 && (
        <Tree
          multiple={false}
          style={{
            marginBottom: '20px',
          }}
          showIcon
          defaultExpandAll
          treeData={parentsTreeData}
        />
      )}
      <Tree
        multiple={false}
        style={{
          marginBottom: '20px',
        }}
        showIcon
        defaultExpandAll
        treeData={tree}
        loadData={onLoadData}
      />
    </div>
  );
};

export default Tasks;
