import React, { FC } from 'react';
import { Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { StripePrice } from '@services/stripe/namespace';
import withSuffix from '@core/helpers/withSuffix';
import { currencySigns } from '@services/currency';
import { PriceColumn } from '@pages/settings/store/Store.sc';

type PriceDetailsProps = {
  price: StripePrice;
  qty: number;
  showTrial?: boolean;
};

const PriceDetails: FC<PriceDetailsProps> = ({
  price,
  qty,
  showTrial = false,
}) => {
  const amount = (price?.unit_amount && (price.unit_amount / 100) * qty) || 0;
  const tax = amount * 0.19;
  const priceValue = price.tax_behavior === 'exclusive' ? amount + tax : amount;
  const priceWithSuffix = withSuffix({
    value: priceValue,
    suffix: price?.currency
      ? currencySigns[price?.currency.toUpperCase()]
      : currencySigns['EUR'],
  });
  const taxValue = withSuffix({
    value: tax,
    suffix: price?.currency
      ? currencySigns[price?.currency.toUpperCase()]
      : currencySigns['EUR'],
  });

  const commonStyle = showTrial
    ? {
        display: 'flex',
        justifyContent: 'end',
        textDecorationLine: 'line-through',
      }
    : {
        display: 'flex',
        justifyContent: 'end',
      };
  return (
    <Space direction="vertical" style={{ marginBottom: '8px' }}>
      <PriceColumn style={commonStyle}>
        {priceWithSuffix}/
        <FormattedMessage id="per.month" defaultMessage="per Month" />
      </PriceColumn>
      <div style={commonStyle}>
        <FormattedMessage
          id={`tax.calc.${
            !['exclusive', 'inclusive'].includes(price?.tax_behavior || '')
              ? 'inclusive'
              : price.tax_behavior
          }`}
          defaultMessage={`tax.calc.${price.tax_behavior}`}
          values={{
            vatRate: `19% VAT`,
            taxDetails: taxValue,
          }}
        />
      </div>
    </Space>
  );
};

export default PriceDetails;
