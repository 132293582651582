import { firebase } from '@services/Firebase';
import { RangeOfNumbersType } from '@services/rangeOfNumbers';
import { COLLECTION_NAME } from '@apps/purchases/services/rangeOfNumbers/namespace';

const getRangeOfNumbersByEcosystem = (
  id: string,
): Promise<any | null> | undefined => {
  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(id)
    .get()
    .then((doc) => doc.data() as RangeOfNumbersType);
};

export default getRangeOfNumbersByEcosystem;
