export { default as deleteProduct } from './deleteProduct';
export * from './namespace';
export { default as postProduct } from './postProduct';
export { default as patchProduct } from './patchProduct';
export { default as updateProduct } from './updateProduct';
export { default as getProduct } from './getProduct';
export { default as getProducts } from './getProducts';
export { default as getProductsForEcosystem } from './getProductsForEcosystem';
//user-fields
export { default as getEcosystemProductUserFields } from './getEcosystemProductUserFields';
export { default as createProductUserField } from './createProductUserField';
export { default as updateProductUserField } from './updateProductUserField';
export { default as deleteProductUserField } from './deleteProductUserField';
//product-prices
export { default as getProductPrices } from './getProductPrices';
export { default as createProductPrice } from './createProductPrice';
export { default as updateProductPrice } from './updateProductPrice';
export { default as archiveProductPrice } from './archiveProductPrice';
