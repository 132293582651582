import React, { FC } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Popover, Space } from 'antd';
import { ButtonColorSelector } from '@components/ColorPicker/ColorPicker.sc';

type PopoverPickerProps = {
  color: string;
  presetColors: string[];
  onChange(color: string): void;
};

export const PopoverPicker: FC<PopoverPickerProps> = ({
  color,
  onChange,
  presetColors,
}) => {
  const content = (
    <Space direction="vertical">
      <HexColorPicker color={color} onChange={onChange} />
      <Space direction="horizontal" size="small">
        {presetColors.map((presetColor) => (
          <ButtonColorSelector
            key={presetColor}
            style={{ background: presetColor }}
            onClick={() => onChange(presetColor)}
          >
            {' '}
          </ButtonColorSelector>
        ))}
      </Space>
    </Space>
  );

  return (
    <Popover content={content}>
      <ButtonColorSelector style={{ backgroundColor: color }}>
        {' '}
      </ButtonColorSelector>
    </Popover>
  );
};
