import React, { FC } from 'react';
import { Card } from '@todix/ui-components';

const View: FC = () => {
  return (
    <Card>
      <div />
    </Card>
  );
};

export default View;
