import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { IGetRowsParams } from 'ag-grid-community';
import { useIntl } from 'react-intl';
import { message } from 'antd';

import {
  deleteProduct,
  getProducts,
  patchProduct,
} from '@apps/products/services';
import AppView from '@core/components/AppView';
import { columns } from './components/columns';
import { SideContent } from './components/SideContent';
import Modal from '@components/Modal';
import { FilterTextObject } from '@services/filtering';
import optionsMapper from '@core/helpers/optionsMapper';
import appConfig, { PRODUCTS_ACTION } from '@apps/products';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

const View: FC<{}> = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();
  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getProducts(
      {
        query: params.query,
        offset: params.startRow,
        limit: params.endRow - params.startRow,
        fields: [
          'id',
          'ecosystem',
          'isActive',
          'productCreationDate',
          'productGroup',
          'productName',
          'productNumber',
          'creator',
        ],
        filters: Object.fromEntries(filtersEntries),
        sort: params.sortModel.map(
          (model: { colId: string; sort: string }) =>
            `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
        ),
      },
      'view-products',
    )?.then((response) => {
      // ts ignored because I want if element exists there for old, incompatible data filtering
      // @ts-ignore
      const filteredResults = response.results.filter((el) => el.creator);
      params.successCallback(filteredResults, filteredResults.length);
    });
  };

  return (
    <AppView
      hideQuickFilter
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'app.products',
            defaultMessage: 'Products',
          }),
        ],
      ]}
      options={optionsMapper(['open', 'edit', 'delete'], intl)}
      columns={useMemo(() => columns(intl), [intl])}
      getRows={getRows}
      sideContent={SideContent}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      onRemoveItem={(id) =>
        deleteProduct(id)
          ?.then(() => {
            // message.success(
            //   intl.formatMessage({
            //     id: 'products.deleteproduct.success',
            //     defaultMessage: 'Product deleted',
            //   }),
            // );
          })
          .catch(() => {
            message.error(
              intl.formatMessage({
                id: 'products.deleteproduct.error',
                defaultMessage: 'Error while contact creating',
              }),
            );
          })
      }
      getExtraOptions={(item) => {
        const ecosystemId = item?.ecosystem || '';
        const canEdit = checkUserPermissions(
          rootStore.getState(),
          appConfig.todixId,
          'update-products' as PRODUCTS_ACTION,
          ecosystemId,
        );
        const activationLabel = item.isActive ? 'deactivate' : 'reactivate';
        return canEdit
          ? [
              {
                children: activationLabel,
                onClick: () => {
                  Modal.confirm({
                    content: intl.formatMessage(
                      {
                        id: 'products.confirmation',
                        defaultMessage:
                          'Are you sure you want to {action} this item?',
                      },
                      { action: activationLabel },
                    ),
                    onOk: () => {
                      patchProduct(item.id, {
                        isActive: !item?.isActive,
                      })
                        .then(() => {
                          // message.success(
                          //   intl.formatMessage({
                          //     id: 'products.patchproduct.success',
                          //     defaultMessage: 'Product updated',
                          //   }),
                          // );
                        })
                        .catch(() => {
                          message.error(
                            intl.formatMessage({
                              id: 'products.patchproduct.error',
                              defaultMessage: 'Error while updating product',
                            }),
                          );
                        });
                    },
                  });
                },
              },
            ]
          : [];
      }}
      appConfig={appConfig}
    />
  );
};

export default View;
