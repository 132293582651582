import { styled } from '@styles/themes';

export const PriceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 5px;
`;

export const PriceLabel = styled.div`
  font-weight: 700;
`;

export const PriceValue = styled.div`
  text-align: right;
`;
