import React, { FC, useEffect, useMemo, useCallback } from 'react';
import { Form, Input, Modal } from 'antd';
import { WarehouseStorage } from '@apps/warehouse/services/namespace';
import intl from '../../../../intl';
import createWarehouseStorage from '@apps/warehouse/services/createWarehouseStorage';
import updateWarehouseStorage from '@apps/warehouse/services/updateWareouseStorage';

type StorageModalProps = {
  ecosystemId: string;
  visible: boolean;
  storage: WarehouseStorage | null;
  onCancel(): void;
  onOk(): void;
};

const StorageModal: FC<StorageModalProps> = ({
  ecosystemId,
  visible,
  storage = {},
  onOk,
  onCancel,
}) => {
  const [form] = Form.useForm<WarehouseStorage>();

  const okText = useMemo(() => {
    if (storage && storage.id) {
      return intl.formatMessage({
        id: 'button.edit',
        defaultMessage: 'Edit',
      });
    }
    return intl.formatMessage({
      id: 'button.create',
      defaultMessage: 'Create',
    });
  }, [storage]);

  const cancelText = useMemo(() => {
    return intl.formatMessage({
      id: 'button.cancel',
      defaultMessage: 'Cancel',
    });
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  const handleOk = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        if (storage && storage.id) {
          updateWarehouseStorage(storage.id, { ...storage, ...values })?.then(
            () => {
              form.resetFields();
              onOk();
            },
          );
        } else {
          createWarehouseStorage({
            ...values,
            ecosystem: ecosystemId,
          })?.then(() => {
            form.resetFields();
            onOk();
          });
        }
      })
      .catch((info) => {
        console.warn('Validation failed: ', info);
      });
  }, [ecosystemId, form, onOk, storage]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={storage || {}}
      >
        <Form.Item
          name="name"
          label={intl.formatMessage({
            id: 'warehouse.form.name.label',
            defaultMessage: 'Name',
          })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'warehouse.form.name.error',
                defaultMessage: 'Name may not be empty',
              }),
            },
          ]}
        >
          <Input
            id="name"
            placeholder={intl.formatMessage({
              id: 'warehouse.form.name.placeholder',
              defaultMessage: 'Name...',
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default StorageModal;
