import React from 'react';
import {
  BigBold,
  PriceSegmentCard,
} from '@apps/sales/widgets/SalesTab/styles.sc';
import intl from '../../../../../../intl';
import PriceDateConstraints from '@apps/sales/widgets/SalesTab/Pricing/PriceDateConstraints';
import PriceOrderTypeConstraints from '@apps/sales/widgets/SalesTab/Pricing/PriceOrderTypeConstraints';
import PriceCountryConstraints from '@apps/sales/widgets/SalesTab/Pricing/PriceCountryConstraints';

const PriceConstraints: React.FC = () => {
  return (
    <PriceSegmentCard
      title={
        <BigBold>
          {intl.formatMessage({
            id: 'sales.salesTab.priceConstraints.title',
            defaultMessage: 'Price constraints',
          })}
        </BigBold>
      }
    >
      <PriceDateConstraints />
      <PriceOrderTypeConstraints />
      <PriceCountryConstraints />
    </PriceSegmentCard>
  );
};

export default PriceConstraints;
