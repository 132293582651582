import get from 'lodash.get';
import set from 'lodash.set';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import { get as apiGet } from '@services/api';
import { getFilteredData } from '@services/filtering';
import {
  PRODUCTS_COLLECTION_NAME,
  GetProductsParams,
  ApiResponse,
} from '@apps/products/services/namespace';
import appConfig, { PRODUCTS_ACTION } from '@apps/products';

type GetProducts = (
  params: GetProductsParams,
  action: PRODUCTS_ACTION,
) => Promise<ApiResponse> | undefined;

const getProducts: GetProducts = (p, action) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 1000,
    fields: ['id', 'ecosystem'],
    filters: {},
    sort: [],
    ...p,
  };
  // @ts-ignore
  return apiGet(PRODUCTS_COLLECTION_NAME)?.then((data: Product[]) => {
    // filter, sort, limit (need info about all records)
    const filterEntries = Object.entries(params.filters);
    const shouldFilter = filterEntries.length;
    const shouldSort = !!params.sort.length;
    const filteredByEcosystems = data.filter((row) =>
      activeEcosystemsIds.includes(row.ecosystem as string),
    );
    const filteredByQuery = filteredByEcosystems.filter((row) =>
      params.fields.some((path) => {
        const fieldValue = get(row, path);
        if (typeof fieldValue !== 'string') {
          return false;
        }
        const queryLower = params.query.toLowerCase();
        return fieldValue.toLowerCase().includes(queryLower);
      }),
    );
    const filtered = shouldFilter
      ? getFilteredData(filteredByQuery, filterEntries)
      : filteredByQuery;
    const sorted = shouldSort
      ? [...filtered].sort((rowA, rowB) => {
          const sortKey = params.sort[0];
          const desc = sortKey.split('').includes('-');
          const path = sortKey
            .split('')
            .filter((c) => c !== '-')
            .join('');
          const valueA = get(rowA, path);
          const valueB = get(rowB, path);
          if (valueA === valueB) {
            return 0;
          }
          if (valueA < valueB) {
            return desc ? 1 : -1;
          } else {
            return desc ? -1 : 1;
          }
        })
      : filtered;
    const choosenFields = sorted.map((row) => {
      const newRow = {};
      params.fields.forEach((path: string): void => {
        const value = get(row, path);
        set(newRow, path, value);
      });
      return newRow;
    });
    const results = choosenFields.length;
    const page = choosenFields.slice(
      params.offset,
      params.offset + params.limit,
    );
    return {
      results: page,
      info: {
        results,
      },
    };
  });
};

export default getProducts;
