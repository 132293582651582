import { styled } from '@styles/themes';
import { Form } from 'antd';

const { Item } = Form;

const StyledItemForm = styled(Item)`
  display: grid;
  grid-template-columns: 1fr;

  & {
    .ant-form-item-control {
      min-width: 100%;
    }
  }
`;

export default StyledItemForm;
