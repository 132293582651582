import moment from 'moment';
import { put } from '@services/api';
import {
  WeekPlanEntry,
  WEEK_PLAN_COLLECTION_NAME,
} from '@apps/weekplan/services/namespace';

const updateWeekPlan = (id: string, weekPlan: WeekPlanEntry) =>
  put(`${WEEK_PLAN_COLLECTION_NAME}/${id}`, {
    ...weekPlan,
    timestamp: moment().valueOf(),
  });

export default updateWeekPlan;
