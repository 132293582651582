import React, { FC, useState, useCallback, useEffect } from 'react';
import {
  BatchNumberEntry,
  FlowOption,
  ProductEntry,
} from '@apps/warehouse/services/namespace';
import BatchNumbersModal from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/BatchNumbersModal';
import getBatchNumbers from '@apps/warehouse/services/getBatchNumbers';
import { StyledButton } from './ButtonBatchNumber.sc';

interface ButtonBatchNumberProps {
  record: ProductEntry;
  ecosystem: string | null;
  onBatchNumbersChange?: (
    positionId: string,
    batchNumberEntries: BatchNumberEntry[],
  ) => void;
  batchNumbers?: BatchNumberEntry[];
  onValidationChange?: (positionId: string, isValid: boolean) => void;
  flowType?: FlowOption | null;
}

const ButtonBatchNumber: FC<ButtonBatchNumberProps> = ({
  record,
  ecosystem,
  onBatchNumbersChange,
  batchNumbers: existingBatchNumbers,
  onValidationChange,
  flowType,
}) => {
  const [batchNumbersModalVisible, setBatchNumbersModalVisible] =
    useState(false);
  const [buttonType, setButtonType] = useState<
    'danger' | 'warning' | 'primary'
  >('danger');
  const [loading, setLoading] = useState<boolean>(false);

  const calculateCount = useCallback((entries: BatchNumberEntry[]) => {
    return entries.reduce((sum, entry) => sum + (entry.amount || 0), 0);
  }, []);

  useEffect(() => {
    if (record.hasBatchNumber && ecosystem && record.productId && record.id) {
      setLoading(true);

      if (existingBatchNumbers && existingBatchNumbers.length > 0) {
        const count = calculateCount(existingBatchNumbers);

        if (flowType === 'manual_take_out') {
          if (count === 0) {
            setButtonType('danger');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          } else if (count < record.deliveredAmount) {
            setButtonType('warning');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          } else if (count === record.deliveredAmount) {
            setButtonType('primary');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, true);
            }
          } else {
            setButtonType('warning');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          }
        } else {
          if (count === 0) {
            setButtonType('danger');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          } else if (count < record.deliveredAmount || 0) {
            setButtonType('warning');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          } else {
            setButtonType('primary');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, true);
            }
          }
        }

        setLoading(false);
        return;
      }

      getBatchNumbers({
        ecosystem,
        productId: record.productId,
        positionId: flowType === 'manual_take_out' ? undefined : record.id,
      })
        .then((entries) => {
          const count = entries ? calculateCount(entries) : 0;

          if (flowType === 'manual_take_out') {
            if (count === 0) {
              setButtonType('danger');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            } else if (count < record.deliveredAmount) {
              setButtonType('warning');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            } else if (count === record.deliveredAmount) {
              setButtonType('primary');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, true);
              }
            } else {
              setButtonType('danger');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            }
          } else {
            if (count === 0) {
              setButtonType('danger');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            } else if (count < (record.deliveredAmount || 0)) {
              setButtonType('warning');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            } else {
              setButtonType('primary');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, true);
              }
            }
          }
        })
        .catch(() => {
          setButtonType('danger');
          if (onValidationChange && record.id) {
            onValidationChange(record.id, false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (!record.hasBatchNumber && onValidationChange && record.id) {
      onValidationChange(record.id, true);
    }
  }, [
    record,
    ecosystem,
    batchNumbersModalVisible,
    existingBatchNumbers,
    calculateCount,
    onValidationChange,
    flowType,
  ]);

  const handleBatchNumbersClick = () => {
    setBatchNumbersModalVisible(true);
  };

  const handleBatchNumbersSave = (batchNumbers: BatchNumberEntry[]) => {
    if (onBatchNumbersChange && record.id) {
      onBatchNumbersChange(record.id, batchNumbers);
    }
    setBatchNumbersModalVisible(false);
  };

  return (
    <>
      <StyledButton
        shape="circle"
        size="small"
        disabled={!record.hasBatchNumber}
        buttonType={buttonType}
        loading={loading}
        onClick={handleBatchNumbersClick}
      >
        B
      </StyledButton>
      {batchNumbersModalVisible && (
        <BatchNumbersModal
          visible={batchNumbersModalVisible}
          onCancel={() => setBatchNumbersModalVisible(false)}
          onSave={handleBatchNumbersSave}
          productId={record.productId}
          positionId={record.id}
          ecosystem={ecosystem || ''}
          quantity={record.deliveredAmount}
          initialBatchNumbers={existingBatchNumbers}
          flowType={flowType}
        />
      )}
    </>
  );
};

export default ButtonBatchNumber;
