import React, { FC } from 'react';
import { Form, Input } from 'antd';
import intl from '../../../../../../intl';

const { Item } = Form;

const SimpleProductName: FC = () => {
  return (
    <Item
      name="productName"
      label={intl.formatMessage({
        id: 'products.form.productname.label',
        defaultMessage: 'Product name',
      })}
      required
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'products.form.productname.error.missing',
            defaultMessage: 'Product name is missing',
          }),
        },
      ]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'products.form.productname.placeholder',
          defaultMessage: 'Product name...',
        })}
        autoComplete="nope"
      />
    </Item>
  );
};

export default SimpleProductName;
