import { IntlShape } from 'react-intl';
import { AgGridColumn } from 'ag-grid-react';
import dateFilter from '@core/helpers/dateFilter';
import React from 'react';
import { Document } from '@apps/documents/service/namespace';
import { nameValueGetter } from '@apps/documents/mainMenu/Archive/valueGetters';
import {
  CheckboxSelectionCallbackParams,
  ColSpanParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';

const unfilterableColumns: Array<keyof Document> = [
  'payments',
  'costStructure',
  'fileDetails',
  'hasCostStructure',
  'hasPayments',
  'relatedDocuments',
  'relatedDocumentsSnapshot',
];

const fieldTypeToRenderer: Record<string, string> = {
  date: 'dateRenderer',
  moneyInput: 'moneyValueRenderer',
};

const columns = (
  fields: { fieldName: string; type: string }[],
  intl: IntlShape,
) => (
  <>
    <AgGridColumn
      field="name"
      checkboxSelection={({ data }: CheckboxSelectionCallbackParams) => !!data}
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      headerName={intl.formatMessage({
        id: 'documents.columns.name.header',
        defaultMessage: 'Name',
      })}
      filter="documentNameFilter"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      valueGetter={({ data }: ValueGetterParams) => nameValueGetter(data, intl)}
      filterValueGetter={({ data }: ValueGetterParams) => {
        nameValueGetter(data, intl).toLowerCase();
      }}
    />
    {fields.map((field) => {
      const header = intl.formatMessage({
        id: `documents.columns.${field.fieldName}.header`,
      });
      let props: any = {
        key: field.fieldName,
        field: field.fieldName,
        headerName: header,
        headerTooltip: header,
        cellRenderer: fieldTypeToRenderer[field.type] || 'documentField',
        filterParams: {
          filterOptions: ['contains'],
          defaultOption: 'contains',
        },
      };

      if (field.type === 'date') {
        props = {
          ...props,
          filter: 'agDateColumnFilter',
          filterParams: dateFilter,
        };
      }
      if (field.type === 'moneyInput') {
        props = {
          ...props,
          filterParams: undefined,
          minWidth: 160,
          filter: 'agNumberColumnFilter',
          valueGetter: ({ data }: ValueGetterParams) => {
            return data[props.field];
          },
          filterValueGetter: ({ data }: ValueGetterParams) => {
            return data[props.field] || 0;
          },
          sortable: true,
        };
      }

      if (field.fieldName === 'creatingUser') {
        props = {
          ...props,
          filter: 'creatingUserFilter',
        };
      }

      if (field.fieldName === 'uploadingUser') {
        props = {
          ...props,
          filter: 'uploadingUserFilter',
        };
      }

      if (field.fieldName === 'relatedContactSnapshot') {
        props = {
          ...props,
          filter: 'relatedContactFilter',
        };
      }

      if (field.fieldName === 'ecosystem') {
        props = {
          ...props,
          filter: 'ecosystemFilter',
        };
      }

      if (field.fieldName === 'category') {
        props = {
          ...props,
          filter: 'categoryFilter',
        };
      }

      if (field.fieldName === 'tags') {
        props = {
          ...props,
          filter: 'tagsFilter',
        };
      }

      if (field.fieldName === 'type') {
        props = {
          ...props,
          filter: 'documentTypeFilter',
        };
      }

      if (unfilterableColumns.includes(field.fieldName as keyof Document)) {
        props = {
          ...props,
          filter: false,
        };
      }
      if (['costStructure', 'payments'].includes(field.fieldName)) {
        props = {
          ...props,
          hide: true,
        };
      }

      return (
        <AgGridColumn
          {...props}
          // filter="agDateColumnFilter"
          // filterParams={filterParams}
        />
      );
    })}
    <AgGridColumn cellRenderer="actionRenderer" maxWidth={70} filter={false} />
  </>
);

export default columns;
