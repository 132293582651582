import React, { useState, FC } from 'react';
import Create from '@apps/products/mainMenu/routes/create';
import { ProductModal } from '@apps/products/widgets/styles.sc';
import { Product } from '@apps/products/services';

type CreateProductModalType = {
  OnClickEl: any;
  onSubmit: (product: Product) => void;
  onCancel?: () => void;
  showExtraContent?: boolean;
};

export const ProductCreationModal: FC<CreateProductModalType> = ({
  OnClickEl,
  onSubmit,
  onCancel,
  showExtraContent = true,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <OnClickEl onClick={showModal} />
      <ProductModal
        width={'80%'}
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <Create
          shouldRedirect={false}
          showExtraContent={showExtraContent}
          onCancel={() => {
            setIsModalVisible(false);
            if (onCancel) {
              onCancel();
            }
          }}
          onSubmit={(product) => {
            setIsModalVisible(false);
            if (onSubmit) {
              onSubmit(product);
            }
          }}
        />
      </ProductModal>
    </>
  );
};
