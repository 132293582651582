import { UnitGroupsActions } from '@core/layout/store/unitGroupsSaga/actions';
import { ActionTypes } from '@core/layout/store/unitGroupsSaga/actionTypes';
import { Unit, UnitGroup, UnitGroups } from '@services/unitGroups/namespace';

export type UnitGroupsState = UnitGroups[];

const initial: UnitGroupsState = [];

const DEFAULT_UNIT_GROUPS = [
  {
    key: 'general',
    children: ['item', 'service', 'package', 'box'],
  },
  {
    key: 'area',
    children: [
      'squaremillimetre',
      'squarecentimetre',
      'squaremetre',
      'squarekilometre',
    ],
  },
  {
    key: 'distance',
    children: ['millimetre', 'centimeter', 'metre', 'kilometre'],
  },
  {
    key: 'volume',
    children: ['millilitre', 'litre', 'cubicmetre'],
  },
  {
    key: 'weight',
    children: ['milligram', 'gram', 'kilogram', 'tonne'],
  },
  {
    key: 'time',
    children: ['minute', 'hour', 'month', 'year'],
  },
  {
    key: 'work',
    children: ['manday', 'manhour', 'manmonth'],
  },
  {
    key: 'energy',
    children: ['kilowatthour'],
  },
];

export const unitGroupsReducer = (
  state = initial,
  action: UnitGroupsActions,
) => {
  switch (action.type) {
    case ActionTypes.FETCH_UNIT_GROUPS_SUCCESS: {
      action.payload?.forEach((unitGroups) => {
        // check if exists before pushing
        const filteredDefaultUnitGroups = DEFAULT_UNIT_GROUPS.reduce(
          (acc: any, defaultUnitGroup) => {
            unitGroups.groups = unitGroups.groups.map(
              (group) =>
                ({
                  ...group,
                  locales: {},
                  units: group.units.map(
                    (unit) =>
                      ({
                        ...unit,
                        locales: {},
                      } as Unit),
                  ),
                } as UnitGroup),
            );
            const foundDefaultGroup = unitGroups.groups.find(
              (group) => group.name === defaultUnitGroup.key,
            );
            if (foundDefaultGroup) {
              foundDefaultGroup.isDefault = true;
              foundDefaultGroup.units.forEach((unit) => {
                if (defaultUnitGroup.children.includes(unit.name)) {
                  unit.isDefault = true;
                }
              });
            } else {
              acc.push(defaultUnitGroup);
            }
            return acc;
          },
          [],
        );

        unitGroups.groups.unshift(
          ...filteredDefaultUnitGroups.map(
            (defaultUnitGroup: any) =>
              ({
                name: defaultUnitGroup.key,
                isDefault: true,
                units: defaultUnitGroup.children.map(
                  (unitName: string) =>
                    ({
                      isDefault: true,
                      isActive: true,
                      name: unitName,
                    } as Unit),
                ),
              } as UnitGroup),
          ),
        );
      });

      return [...action.payload];
    }
    default: {
      return state;
    }
  }
};
