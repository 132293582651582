import React, { FC, useState } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { Image } from 'antd';
import { useCustomEventListener } from '@core/services';

type Props = {
  element: ReactImageGalleryItem;
  width?: string;
  height?: string;
};

const ImageWrapper: FC<Props> = ({ element }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  useCustomEventListener<string, boolean>(
    'mediaViewerFullScreen',
    (fullScreen) => {
      setIsFullScreen(!!fullScreen);
    },
  );
  return (
    <Image
      src={element.original}
      preview={false}
      style={
        isFullScreen
          ? { height: '100vh' }
          : {
              maxHeight: 'calc(100vh - 180px)',
            }
      }
    />
  );
};

export default ImageWrapper;
