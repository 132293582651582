import { firebase } from '@services/Firebase';

type Payload = {
  period: string;
  ecosystemId: string;
};
const generatePeriodReport = (payload: Payload) =>
  firebase.functions?.httpsCallable('handlePeriodCommissions')(payload);

export default generatePeriodReport;
