import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import {
  getPaymentTerms,
  PaymentTerm,
  updatePaymentTerm,
} from '@apps/purchases/services/paymentTerms';
import { ColumnType } from 'antd/lib/table';
import {
  StyledEditIcon,
  StyledSwitch,
  StyledTable,
} from '@pages/settings/components';
import PaymentTermsModal from '@apps/purchases/settings/paymentTerms/PaymentTermsModal';
import { PlusSquareOutlined } from '@ant-design/icons';

const PaymentTerms: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<PaymentTerm[]>([]);
  const [selectedPaymentTerm, setSelectedPaymentTerm] =
    useState<PaymentTerm | null>(null);

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getPaymentTerms(uid)
        ?.then(setPaymentTerms)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const handleTermsChange = useCallback(
    async (term: PaymentTerm) => {
      const { id } = term;
      if (id) {
        setLoading(true);
        await updatePaymentTerm(id, term);
        setPaymentTerms(
          paymentTerms.map((item) => {
            if (item.id === id) {
              return term;
            }
            return item;
          }),
        );
        setLoading(false);
      }
    },
    [paymentTerms],
  );

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="sales.createNew.name.label"
            defaultMessage="Name"
          />
        ),
        sorter: true,
        dataIndex: 'name',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="sales.createNew.category.label"
            defaultMessage="Category"
          />
        ),
        sorter: true,
        dataIndex: 'category',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="sales.createNew.description.label"
            defaultMessage="Description"
          />
        ),
        sorter: true,
        dataIndex: 'description',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: PaymentTerm) => (
          <Tooltip
            title={
              <FormattedMessage
                id="app.sales.settings.isActive.tooltip"
                defaultMessage="app.sales.settings.isActive.tooltip"
              />
            }
          >
            <StyledSwitch
              key={record.id}
              checked={record.isActive}
              onChange={() =>
                handleTermsChange({
                  ...record,
                  isActive: !record.isActive,
                } as PaymentTerm)
              }
              checkedChildren={activated}
              unCheckedChildren={deactivated}
            />
          </Tooltip>
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: PaymentTerm) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedPaymentTerm(record);
                setVisible(true);
              }}
            />
          </>
        ),
      },
    ],
    [activated, deactivated, handleTermsChange],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const onOk = useCallback(() => {
    setVisible(false);
    if (uid) {
      setLoading(true);
      getPaymentTerms(uid)
        ?.then(setPaymentTerms)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  return (
    <Card
      title={
        <FormattedMessage
          id="sales.settings.paymentTerms.header"
          defaultMessage="Payment terms"
        />
      }
      extra={
        <Button
          type={'primary'}
          icon={<PlusSquareOutlined />}
          onClick={() => setVisible(true)}
        />
      }
    >
      <StyledTable
        dataSource={paymentTerms}
        columns={columns}
        bordered
        tableLayout="auto"
        loading={loading}
        pagination={false}
        rowKey="id"
      />
      <PaymentTermsModal
        terms={selectedPaymentTerm}
        visible={visible}
        ecosystemId={uid}
        onCancel={onCancel}
        onOk={onOk}
      />
    </Card>
  );
};

export default PaymentTerms;
