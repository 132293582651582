import React from 'react';
import RouteForOwner from '@pages/settings/RouteForOwner';
import _ from 'lodash';
import { FileApp } from '@apps/AppTypes';
import AppsSettingsTabs from '@apps/AppsSettingsTabs';

export const AppsSettingsRouting: React.FC<{
  activatedFileApps: FileApp[];
  ecoId: string;
}> = React.memo(
  ({ activatedFileApps, ecoId }) => {
    return (
      <>
        {activatedFileApps.map((fileApp, index) => {
          return (
            <RouteForOwner
              key={index}
              path={`/settings/:uid/app/${fileApp.todixId}/:sectionLink`}
              component={(props: any) => (
                <AppsSettingsTabs
                  {...props}
                  sections={fileApp.settings.sections}
                  ecoId={ecoId}
                  appId={fileApp.todixId}
                />
              )}
              exact
            />
          );
        })}
      </>
    );
  },
  (prev, next) =>
    _.isEqual(prev.activatedFileApps, next.activatedFileApps) &&
    prev.ecoId === next.ecoId,
);
