import ClampLines from 'react-clamp-lines';
import { styled } from '@styles/themes';
import Meta from 'antd/lib/card/Meta';
import { Card } from 'antd';

export const GridThreeColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
`;

export const AppStoreDetails = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  grid-column-gap: 20px;
`;

export const PriceColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddToCardButton = styled.div`
  bottom: 8px;
  position: absolute;
  top: 120px !important;
  padding: 0;
`;

export const FreeTrial = styled.div`
  bottom: 16px;
  position: absolute;
  top: 45px !important;
  background: #f5222d;
`;

export const AddToCardButtonCorner = styled.div``;

export const StyledClampLines = styled(ClampLines)`
  margin-top: 20px;
`;

export const StyledMeta = styled(Meta)`
  display: flex;
  justify-content: center;
  margin: 0;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
`;

export const AppCard = styled(Card)`
  height: 154px;

  .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;
