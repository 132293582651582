import { CartEntry, CARTS } from '@services/cart/namespace';
import { firebase } from '@services/Firebase';

const getCart = async (userId: string): Promise<CartEntry[] | undefined> => {
  return firebase.db
    ?.ref(`${CARTS}/${userId}/cart/entries`)
    .once('value')
    .then((result) => {
      if (!result.exists()) {
        return [];
      }
      const values = result.val();

      return Object.values(values);
    });
};

export default getCart;
