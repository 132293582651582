import * as React from 'react';
import { StyledSectionTitle } from '@apps/contacts/mainMenu/components/DetailContactForm/DetailContactForm.sc';
import { Avatar, Form, Mentions } from 'antd';
import { MentionsWithTags } from '@components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl';

export const initialTeam = {
  team: [],
};

const mockedUsers = [
  {
    name: 'John Snow',
    url: 'https://randomuser.me/api/portraits/men/1.jpg',
  },
  {
    name: 'Adam Smith',
    url: 'https://randomuser.me/api/portraits/men/2.jpg',
  },
  {
    name: 'Jane Right',
    url: 'https://randomuser.me/api/portraits/women/1.jpg',
  },
  {
    name: 'Susanne Osmond',
    url: 'https://randomuser.me/api/portraits/women/2.jpg',
  },
];

const Team: React.FC = () => {
  const { setFields, readOnly } = useFormContext();
  const intl = useIntl();
  return (
    <>
      <StyledSectionTitle level={5}>
        <FormattedMessage
          id="detailcontactform.team.title"
          defaultMessage="Team"
        />
      </StyledSectionTitle>
      <Form.Item name="team">
        <MentionsWithTags
          readOnly={readOnly}
          setValue={(team) => {
            setFields([
              {
                name: 'team',
                value: team,
              },
            ]);
          }}
          label={
            <FormattedMessage
              id="detailcontactform.team.team.label"
              defaultMessage="Add team member"
            />
          }
          placeholder={intl.formatMessage({
            id: 'detailcontactform.team.team.placeholder',
            defaultMessage: 'Find team member by @mention...',
          })}
          inputMode="search"
          customTagRenderer={(userName) => (
            <span>
              <Avatar
                src={
                  mockedUsers.filter((user) => user.name === userName)[0]?.url
                }
                style={{ marginRight: 8 }}
              />
              {userName}
            </span>
          )}
        >
          {mockedUsers.map((user) => (
            <Mentions.Option key={user.name} value={user.name}>
              {user.name}
            </Mentions.Option>
          ))}
        </MentionsWithTags>
      </Form.Item>
    </>
  );
};

export default Team;
