import { initial } from './initial';
import {
  DashboardActions,
  DashboardActionsTypes,
  FetchApplicationsFailedAction,
} from './actions';
import { DashboardState } from '../namespace';

export const dashboardReducer = (
  state = initial,
  action: DashboardActions,
): DashboardState => {
  switch (action.type) {
    case DashboardActionsTypes.FETCH_APPLICATIONS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DashboardActionsTypes.FETCH_APPLICATIONS_SUCCESS: {
      const { applications, favApplications } = action.payload;
      return {
        ...state,
        isLoading: false,
        applications,
        favApplications,
      };
    }
    case DashboardActionsTypes.FETCH_APPLICATIONS_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: (action as FetchApplicationsFailedAction).error,
      };
    }
    case DashboardActionsTypes.SET_FAV_APPLICATIONS: {
      return {
        ...state,
        favApplications: action.payload,
      };
    }
    default:
      return state;
  }
};
