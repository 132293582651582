export const INVITATION_COLLECTION = 'invitations';

type Status = 'PENDING' | 'APPROVED' | 'ERROR' | 'DECLINED';

export type Invitation = {
  id?: string;
  senderId: string;
  senderEmail: string;
  recipientId: string;
  recipientEmail: string;
  ecosystem: string;
  status: Status;
};
