import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/directSales';
import { firebase } from '@services/Firebase';
import {
  COMMISSION_PERIOD_REPORTS,
  CommissionPeriodReportEntry,
} from '@apps/directSales/services/commisions/namespace';

const getCommissionPeriodReports = (ecosystemId: string, period: string) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    '',
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve(null);
  }

  return firebase.firestore
    ?.collection(COMMISSION_PERIOD_REPORTS)
    .doc(`${ecosystemId}_${period}`)
    .get()
    .then((result) => {
      if (result.exists) {
        return result.data() as CommissionPeriodReportEntry;
      }
      return null;
    });
};

export default getCommissionPeriodReports;
