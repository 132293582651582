import React, { FC } from 'react';
import { Form } from 'antd';
import { ItemNoMargin } from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import { NumericFormatProps } from 'react-number-format';
import { NumberInput } from '@components/MoneyInput';

const { Item } = Form;

type Props = NumericFormatProps & {
  name: string;
  handleSave(): void;
};

const isNumber = (value: any) => {
  return typeof value === 'number';
};

const calculateValues = (
  discountValue: number,
  formValues: Record<string, any>,
) => {
  const { price, taxValue, amount } = formValues;
  const discount = discountValue !== undefined ? discountValue : 0;
  let values: any = {
    discount,
  };
  if (price !== '' && amount) {
    const priceValue = isNumber(price)
      ? price
      : Number((price as string).replaceAll('.', '').replaceAll(',', '.'));
    const discountedPrice = Number(
      Number(priceValue - (discount / 100) * priceValue).toFixed(2),
    );
    const totalNetPrice = Number(Number(discountedPrice * amount).toFixed(2));
    const grossPrice = Number(
      Number(
        discountedPrice + discountedPrice * (parseFloat(taxValue) / 100),
      ).toFixed(2),
    );
    const totalGrossPrice = Number(Number(grossPrice * amount).toFixed(2));

    values = {
      ...values,
      grossPrice,
      discountedPrice,
      totalNetPrice,
      totalGrossPrice,
    };
  }
  return values;
};

const Discount: FC<Props> = ({ name, handleSave, ...rest }) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name="discount">
            <NumberInput
              onChange={(event) => {
                const valueString = event.target.value;
                if (valueString !== '') {
                  const floatValue = Number(
                    valueString.replaceAll('.', '').replaceAll(',', '.'),
                  );
                  const newValues = calculateValues(floatValue, formValues);

                  setFieldsValue({
                    ...formValues,
                    ...newValues,
                  });

                  if (handleSave) {
                    handleSave();
                  }
                }
              }}
              onValueChange={({ floatValue = 0 }) => {
                const newValues = calculateValues(floatValue, formValues);

                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });

                if (handleSave) {
                  handleSave();
                }
              }}
              {...rest}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default Discount;
