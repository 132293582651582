import { SKREntry, SKRJsonEntry } from '@apps/finance/services/skr/namespace';
import Storage from '@services/Firebase/Storage';
import ApiService from '@services/config';
import { AxiosResponse } from 'axios';
import { removeUndefinedProperties } from '@services/utils';

const getSKREntries = async (skr: string): Promise<SKREntry[]> => {
  const list = await Storage.list('public');
  const skrFile = list.items?.find((el) =>
    el?.downloadUrl?.includes(`${skr}.json`),
  );
  if (skrFile && skrFile.downloadUrl) {
    const response = await ApiService.get<AxiosResponse<SKRJsonEntry[]>>(
      skrFile.downloadUrl,
    );
    return removeUndefinedProperties(
      response.data.map(
        (el) =>
          ({
            id: el.id,
            categories: el.categories,
            code: el.code,
            description: el.description,
            leaf: el.leaf,
            hierarchicalCategoriesLvl0: el['hierarchicalCategories.lvl0'],
            hierarchicalCategoriesLvl1: el['hierarchicalCategories.lvl1'],
            parent: el.parent,
            name: el.name,
            title: el.name,
            value: el.name,
            placeholder: el.placeholder,
            sortCode: el['sort-code'],
            taxRelated: el['tax-related'],
            type: el.type,
          } as SKREntry),
      ),
    ) as SKREntry[];
  } else {
    throw new Error("SKR file doesn't exist");
  }
};

export default getSKREntries;
