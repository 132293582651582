import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { InputNumber, Form } from 'antd';
import { currencySigns } from '@services/currency';
import { transformFloat } from '@apps/directSales/settings/Commissions/components/commonHelpers';

const { Item } = Form;

type Props = {
  intl: IntlShape;
};

const CommissionSalesInput: FC<Props> = ({ intl }) => {
  return (
    <Item
      label={intl.formatMessage({
        id: 'commissions.sales',
        defaultMessage: 'Commission sales',
      })}
      name="sales"
      normalize={transformFloat}
      rules={[
        {
          type: 'number',
          min: 0,
          transform: transformFloat,
        },
      ]}
    >
      <InputNumber
        type="number"
        min={0}
        step={0.01}
        precision={2}
        onChange={transformFloat}
        placeholder={intl.formatMessage({
          id: 'commissions.sale',
          defaultMessage: 'Commission sale',
        })}
        addonAfter={currencySigns['EUR']}
      />
    </Item>
  );
};

export default CommissionSalesInput;
