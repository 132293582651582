import React from 'react';
import styled from 'styled-components';
import { CostEntity } from '@apps/documents/service';
import { LocalisedValue } from '@services/dictionary';
import { Col, Form, Row, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { TaxCategory } from '@apps/finance/services/taxCategories';
import { Text } from '@todix/ui-components';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import InnerInput from '@apps/documents/mainMenu/components/InnerInput';

const StyledCol = styled(Col)`
  label {
    width: 100%;
  }
`;

type SummaryProps = {
  costTypes: LocalisedValue[];
  taxRates: TaxCategory[];
};

const Summary: React.FC<SummaryProps> = ({ taxRates }) => {
  const intl = useIntl();
  const { getFieldsValue } = useFormContext();
  const { costStructure: cs } = getFieldsValue();
  const costStructure = cs as CostEntity[];
  if (!costStructure || !costStructure?.length) {
    return null;
  }
  const uniqueTaxRates = [
    ...new Set(costStructure?.map((cost) => cost.taxRateId)),
  ];
  const taxAmounts = uniqueTaxRates.map((rate) => {
    const taxRate = taxRates.find((r) => r.id === (rate as unknown as string));
    const label = taxRate ? taxRate.label[intl.locale] : '';
    const cost = costStructure?.reduce((ac, it) => {
      if (taxRate && taxRate.id === it.taxRateId) {
        return it.netAmount
          ? ac + Number(it.netAmount) * (taxRate.value / 100)
          : ac;
      }
      return ac;
    }, 0);

    return taxRate ? { taxRate, cost, label } : null;
  });
  const sumNetAmount = costStructure?.reduce((acc, it) => {
    if (!it.netAmount) {
      return acc;
    }
    return acc + Number(it.netAmount);
  }, 0);

  const grossTotalAmount =
    sumNetAmount +
    taxAmounts.reduce((acc, it) => {
      if (it?.taxRate) {
        return acc + it.cost;
      }
      return acc;
    }, 0);
  const sumNetAmountLabel = (
    <FormattedMessage
      id={'documents.processinginvoice.coststructure.sumnetamount.label'}
      defaultMessage="Sum net amount"
    />
  );
  const sumGrossAmountLabel = (
    <FormattedMessage
      id={'documents.processinginvoice.coststructure.sumgrossamount.label'}
      defaultMessage="Sum gross amount"
    />
  );

  return (
    <>
      <Row gutter={[1, 1]} justify="end">
        {taxAmounts.map((entry) => {
          return entry?.taxRate ? (
            <StyledCol key={entry?.taxRate?.id} span={8}>
              <Form.Item
                label={
                  <Tooltip title={entry?.label}>
                    <Text level={2} ellipsis strong>
                      {entry?.label}
                    </Text>
                  </Tooltip>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ position: 'relative' }}
              >
                <InnerInput value={Number(entry?.cost).toFixed(2)} readOnly />
              </Form.Item>
            </StyledCol>
          ) : null;
        })}
        <StyledCol span={8}>
          <Form.Item
            label={
              <Tooltip title={sumNetAmountLabel}>
                <Text level={2} strong ellipsis>
                  {sumNetAmountLabel}
                </Text>
              </Tooltip>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ position: 'relative' }}
          >
            <InnerInput value={sumNetAmount?.toFixed(2) || 0} readOnly />
          </Form.Item>
        </StyledCol>
      </Row>
      <Row gutter={[1, 1]} justify="end">
        <StyledCol span={8}>
          <Form.Item
            label={
              <Tooltip title={sumGrossAmountLabel}>
                <Text level={2} strong ellipsis>
                  {sumGrossAmountLabel}
                </Text>
              </Tooltip>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <InnerInput value={grossTotalAmount?.toFixed(2) || 0} readOnly />
          </Form.Item>
        </StyledCol>
      </Row>
    </>
  );
};

export default Summary;
