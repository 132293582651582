import moment from 'moment';
import { post } from '@services/api';
import {
  PRODUCTS_COLLECTION_NAME,
  Product,
} from '@apps/products/services/namespace';
import postProductNumber from '@apps/products/services/postProductNumber';

const postProduct = (product: Product) =>
  post(PRODUCTS_COLLECTION_NAME, {
    ...product,
    timestamp: moment().valueOf(),
  }).then(async (id) => {
    await postProductNumber(product.ecosystem, product.productNumber);
    return id;
  });

export default postProduct;
