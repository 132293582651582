import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Preview from './Preview';
import Edit from './Edit';
import Inbox from './Inbox';
import Archive from './Archive';
import Settings from './Settings';
import Create from './Create';

const Documents: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/settings`}>
        <Settings />
      </Route>
      <Route path={`${path}/create`} component={Create} />
      <Route path={`${path}/inbox`}>
        <Inbox />
      </Route>
      <Route path={`${path}/archive`}>
        <Archive />
      </Route>
      <Route exact path={`${path}/:id`} component={Preview} />
      <Route exact path={`${path}/:id/edit`} component={Edit} />
    </Switch>
  );
};

export default Documents;
