import React, { FC, useState, useEffect } from 'react';
import { Icon } from '@todix/ui-components';
import { Input, Card as AntCard, Space, Avatar, Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { DeleteButton, MovableCardWrapper } from './Board.sc';
import SelectWithSearch from '@components/SelectWithSearch';
import { Option } from '@apps/weekplan/mainMenu/WeekPlan/common';
import ResourceLabel from '@apps/weekplan/mainMenu/WeekPlan/ResourceLabel';
import { fetchUser } from '@services/users';

type Resource = {
  id: string;
  displayName: string;
  photoURL: string;
  email: string;
};

type CardProps = {
  id: string;
  onClick: any;
  className: string;
  title: string;
  resourceId: string;
  cardStyle: any;
  description: any;
  cardColor: any;
  tagStyle: any;
  tags: any;
  showDeleteButton: boolean;
  onDelete: any;
  editable: boolean;
  onChange(updatedCard: any): void;
  cardDraggable: boolean;
  activeEcosystems: Ecosystem[];
  members: EcosystemMember[];
  canProceed: boolean;
};

const Card: FC<CardProps> = ({
  id,
  onClick,
  className,
  title,
  resourceId,
  cardStyle,
  description,
  onDelete,
  editable,
  onChange,
  cardDraggable,
  members,
  canProceed,
}) => {
  const [usersOptions, setUsersOptions] = useState<Option[]>(
    members.map((member) => ({
      key: member.id,
      value: member.id,
      label: <ResourceLabel resource={member} />,
    })),
  );
  const [parsedResource, setParsedResource] = useState<Resource | null>(null);

  useEffect(() => {
    setUsersOptions(
      members.map((member) => ({
        key: member.id,
        value: member.id,
        label: <ResourceLabel resource={member} />,
      })),
    );
  }, [members]);

  useEffect(() => {
    if (resourceId) {
      fetchUser(resourceId).then((user) => {
        setParsedResource({
          id: user.uid,
          displayName: user.displayName,
          email: user.email,
          photoURL: user.photoURL,
        });
      });
    }
  }, [resourceId]);

  const intl = useIntl();
  const clickDelete = (e: any) => {
    if (!canProceed) {
      e.stopPropagation();
      return;
    }
    onDelete();
    e.stopPropagation();
  };

  const updateCard = (card: any) => {
    if (!canProceed) {
      return;
    }
    onChange({ ...card, id });
  };

  const onFieldChange = (field: string, value: any) => {
    if (!canProceed) {
      return;
    }
    updateCard({
      [field]: value,
    });
  };

  const cardTitle = (
    <Space direction="horizontal">
      <div>
        {editable && canProceed && (
          <Input
            type="text"
            value={title}
            placeholder={intl.formatMessage({
              id: 'app.weekplan.card.title',
              defaultMessage: 'Title',
            })}
            onChange={(e) => onFieldChange('title', e.target.value)}
          />
        )}
        {!editable && title}
      </div>
      <DeleteButton
        size="small"
        shape="circle"
        onClick={(event) => canProceed && clickDelete(event)}
        icon={<Icon.CloseOutlined />}
        disabled={!canProceed}
        danger
      />
    </Space>
  );

  return (
    <MovableCardWrapper
      data-id={id}
      onClick={onClick}
      style={cardStyle}
      className={className}
    >
      <AntCard title={cardTitle} draggable={cardDraggable}>
        <Space direction="vertical" style={{ display: 'flex' }}>
          <div>
            {editable && parsedResource && (
              <SelectWithSearch
                disabled={!canProceed}
                value={{
                  value: resourceId,
                  key: resourceId,
                  label: <ResourceLabel resource={parsedResource} />,
                }}
                options={usersOptions}
                width="100%"
                placeholder={intl.formatMessage({
                  id: 'app.weekplan.card.resource',
                  defaultMessage: 'Resource',
                })}
                onSelect={(value) => onFieldChange('resourceId', value)}
              />
            )}
            {!editable && parsedResource && (
              <Tooltip
                title={`${parsedResource.displayName} (${parsedResource.email})`}
              >
                <Space direction="horizontal" size="small">
                  {parsedResource.photoURL && (
                    <Avatar src={parsedResource.photoURL} size="small" />
                  )}
                  {parsedResource.displayName && (
                    <span>{parsedResource.displayName}</span>
                  )}
                </Space>
              </Tooltip>
            )}
          </div>
          <div>
            {editable && canProceed && (
              <Input.TextArea
                disabled={!canProceed}
                placeholder={intl.formatMessage({
                  id: 'app.weekplan.card.description',
                  defaultMessage: 'Description',
                })}
                value={description}
                onChange={(e) => onFieldChange('description', e.target.value)}
              />
            )}
            {!editable && <i>{description}</i>}
          </div>
        </Space>
      </AntCard>
    </MovableCardWrapper>
  );
};

export default Card;
