import React from 'react';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl/lib';

const BlockedTag = () => (
  <Tag
    color="error"
    style={{
      position: 'absolute',
      top: '-14px',
      left: '-14px',
      zIndex: '1',
      fontSize: '10px',
    }}
  >
    <FormattedMessage id="tag.blocked" defaultMessage="Blocked" />
  </Tag>
);

export default BlockedTag;
