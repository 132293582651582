import { RootStore } from '@core/store';
import { ProductGroupState } from '@core/store/productGroups/reducers';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';

export const getActiveProductGroups = (state: RootStore): ProductGroupState => {
  const activeEcosystems = getActiveEcosystems(state);
  const activeEcosystemsIds = activeEcosystems.map((eco) => eco.id);

  return state.productGroups.filter(
    (productGroup) =>
      activeEcosystemsIds.includes(productGroup.ecosystemId || '') &&
      productGroup.isActive,
  );
};
