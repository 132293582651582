import React, { FC } from 'react';
import styled from 'styled-components';
import { Card, Col } from 'antd';

export const StyledCard = styled(Card)`
  height: 100%;
  overflow: auto;
  position: relative;
  .ant-card-body {
    padding-top: 8px;
  }
`;

const SideView: FC = ({ children }) => {
  return (
    <Col
      span={10}
      style={{ maxHeight: '100%' }}
      className="animate__animated animate__slideInRight animate__faster"
    >
      <StyledCard>{children}</StyledCard>
    </Col>
  );
};

export default SideView;
