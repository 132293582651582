import styled, { css, DefaultTheme } from 'styled-components';
import Typography from 'antd/lib/typography';
import { LinkProps } from 'antd/lib/typography/Link';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { TextProps as AntTextProps } from 'antd/lib/typography/Text';

export type TextProps = AntTextProps & {
  level?: keyof DefaultTheme['textSize'];
};

const textSizeHelper = css<TextProps>`
  font-size: ${(props) =>
    props.level ? props.theme.textSize[props.level] : props.theme.textSize[2]};
`;

const textColorHelper = css<TextProps>`
  color: ${(props) => {
    if (props.disabled) {
      return props.theme.colors.text.lightGray;
    }

    if (props.type === 'secondary') {
      return props.theme.colors.text.gray;
    }

    return props.theme.colors.text.normal;
  }};
`;

export const Text = styled(Typography.Text)<TextProps>`
  && {
    ${textColorHelper};
    ${textSizeHelper}
  }
`;

export const Link = styled(Typography.Link)<LinkProps>``;

export const Paragraph = styled(Typography.Paragraph)<ParagraphProps>``;
