import React, { FC } from 'react';
import { FormProps, Form } from 'antd';
import PricingModel from '@apps/sales/widgets/SalesTab/Pricing/PriceForm/PricingModel';
import Price from '@apps/sales/widgets/SalesTab/Pricing/PriceForm/Price';
import ProductDetailsToggle from '@apps/sales/widgets/SalesTab/Details/ProductDetailsToggle';
import intl from '../../../../../../../intl';
import {
  FlexSpaceBetween,
  GridTwoColumns,
} from '@apps/sales/widgets/SalesTab/styles.sc';
import PricePreview from '@apps/sales/widgets/SalesTab/Pricing/PricePreview';
import PriceConstraints from '@apps/sales/widgets/SalesTab/Pricing/PriceConstraints';
const { Item } = Form;

const PriceForm: FC<FormProps> = (formProps) => {
  return (
    <Form {...formProps}>
      <GridTwoColumns>
        <div>
          <FlexSpaceBetween>
            <ProductDetailsToggle
              name="isActive"
              label={intl.formatMessage({
                id: 'settings.list.header.status',
                defaultMessage: 'Status',
              })}
              checkedChildren={intl.formatMessage({
                id: 'settings.status.activated',
                defaultMessage: 'Activated',
              })}
              unCheckedChildren={intl.formatMessage({
                id: 'settings.status.deactivated',
                defaultMessage: 'Deactivated',
              })}
            />
            <ProductDetailsToggle
              name="isDefaultPrice"
              label={intl.formatMessage({
                id: 'sales.salesTab.isDefaultPrice.label',
                defaultMessage: 'Default price',
              })}
            />
            <ProductDetailsToggle
              name="isFallbackPrice"
              label={intl.formatMessage({
                id: 'sales.salesTab.isFallbackPrice.label',
                defaultMessage: 'Fallback price',
              })}
            />
          </FlexSpaceBetween>
          <PricingModel />
          <Price />
        </div>
        <div>
          <PricePreview />
          <PriceConstraints />
        </div>
        <Item hidden name="isArchived" />
        <Item hidden name="currency" />
      </GridTwoColumns>
    </Form>
  );
};

export default PriceForm;
