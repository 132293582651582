import React from 'react';
import { Form, Select } from 'antd';
import intl from '../../../../../../intl';

const { Item } = Form;

const PriceCountryConstraints: React.FC = () => {
  return (
    <Item name={['constraints', 'validCountry']}>
      <Select
        placeholder={intl.formatMessage({
          id: 'sales.salesTab.priceConstraints.validCountry.placeholder',
          defaultMessage: 'Valid country',
        })}
      />
    </Item>
  );
};

export default PriceCountryConstraints;
