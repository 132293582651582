import { WEEK_PLAN_COLLECTION_NAME } from '@apps/weekplan/services/namespace';
import { deleteRequest } from '@services/api';
import { emitCustomEvent } from '@core/services';
import { ListEvents } from '@components/List';

const deleteWeekPlan = (id: string) =>
  deleteRequest(`${WEEK_PLAN_COLLECTION_NAME}/${id}`)?.then((response) => {
    emitCustomEvent<ListEvents>('refreshList');
    return response;
  });

export default deleteWeekPlan;
