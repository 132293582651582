import { Contact, Email, postContact } from '@apps/contacts/services';
import React, { FC, useState } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form, Input, message, Spin } from 'antd';
import moment from 'moment';
import { FormContext } from '@components/FormWithReminder/context';
import ContactType from '@apps/documents/mainMenu/DocumentProcessing/Step2/ContactType';
import { SelectedEcosystem } from '@apps/contacts/mainMenu/components/DetailContactForm/SelectedEcosystem/SelectedEcosystem';
import PersonInfo from '@apps/contacts/mainMenu/components/DetailContactForm/PersonInfo';
import CompanyInfo from '@apps/contacts/mainMenu/components/DetailContactForm/CompanyInfo';
import AssetUpload from '@apps/contacts/mainMenu/components/DetailContactForm/AssetUpload';
import ArrayOfFields from '@apps/contacts/mainMenu/components/DetailContactForm/ArrayOfFields';
import Address from '@apps/contacts/mainMenu/components/DetailContactForm/Address';
import BankAccount from '@apps/contacts/mainMenu/components/DetailContactForm/BankAccount';
import CompanyDetails from '@apps/contacts/mainMenu/components/DetailContactForm/CompanyDetails';
import { ButtonsWrapper } from '@components/FormWithReminder/FormWithReminder.sc';

type NewContactsProps = {
  details: Partial<Contact>;
  ecosystems: Ecosystem[];
  onFinish(id: string): void;
  handleCancel(): void;
  onValuesChange?(contact: Contact): void;
};

const NewContact: FC<NewContactsProps> = ({
  details,
  ecosystems,
  onFinish,
  handleCancel,
  onValuesChange,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = useForm();
  const intl = useIntl();
  return (
    <Spin spinning={isSubmitting}>
      <Form
        form={form}
        initialValues={details}
        colon={false}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
        onFinish={(values) => {
          setIsSubmitting(true);
          if (values.birthDate) {
            values.birthDate = moment(values.birthDate).format(
              moment.defaultFormat,
            );
          }
          if (values.foundationDate) {
            values.foundationDate = moment(values.foundationDate).format(
              moment.defaultFormat,
            );
          }
          values.emails =
            values?.emails?.filter((emailObj: Email) => !!emailObj.email) || [];
          postContact(values)
            ?.then((id) => {
              // message.success(
              //   intl.formatMessage({
              //     id: 'documentprocessing.step2.newcontact.finish.success',
              //     defaultMessage: 'Contact created',
              //   }),
              // );
              setIsSubmitting(false);
              onFinish(id);
            })
            .catch(() => {
              setIsSubmitting(false);
              message.error(
                intl.formatMessage({
                  id: 'documentprocessing.step2.newcontact.finish.error',
                  defaultMessage: 'Error while contact creating',
                }),
              );
            });
        }}
        onValuesChange={(_values, all) => {
          if (onValuesChange) {
            onValuesChange(all);
          }
        }}
      >
        {(values) => (
          <FormContext.Provider value={{ ...form, readOnly: false }}>
            <ContactType />
            <SelectedEcosystem ecosystems={ecosystems} />
            {values.type === 'person' && <PersonInfo />}
            {values.type === 'company' && <CompanyInfo />}

            <AssetUpload
              fieldName="avatar"
              label={
                values.type === 'person'
                  ? intl.formatMessage({
                      id: 'detailcontactform.avatarupload.label.avatar',
                      defaultMessage: 'Image/avatar',
                    })
                  : intl.formatMessage({
                      id: 'detailcontactform.avatarupload.label.logo',
                      defaultMessage: 'Logo',
                    })
              }
            />

            <ArrayOfFields
              label={intl.formatMessage({
                id: 'documentprocessing.step2.newcontact.phone.label',
                defaultMessage: 'Phone#',
              })}
              options={[
                {
                  label: intl.formatMessage({
                    id: 'documentprocessing.step2.newcontact.phone.option',
                    defaultMessage: 'Primary Phone',
                  }),
                  value: 'primary',
                },
              ]}
              arrayName="phones"
              firstInputNameField="label"
              secondInputNameField="number"
              firstInputPlaceholder={intl.formatMessage({
                id: 'documentprocessing.step2.newcontact.phone.firstinputplaceholder',
                defaultMessage: 'Phone label',
              })}
              secondInputPlaceholder={intl.formatMessage({
                id: 'documentprocessing.step2.newcontact.phone.secondinputplaceholder',
                defaultMessage: 'Phone number',
              })}
              secondInputRules={[
                {
                  required: true,
                },
              ]}
              addNextButtonLabel={intl.formatMessage({
                id: 'documentprocessing.step2.newcontact.phone.addnextbutton',
                defaultMessage: 'Add phone number',
              })}
              standardFieldName="standardPhone"
              defaultValue={{
                label: intl.formatMessage({
                  id: 'documentprocessing.step2.newcontact.phone.option',
                  defaultMessage: 'Primary Phone',
                }),
                value: 'primary',
              }}
            />
            <ArrayOfFields
              label={intl.formatMessage({
                id: 'documentprocessing.step2.newcontact.email.label',
                defaultMessage: 'E-mail#',
              })}
              options={[
                {
                  label: intl.formatMessage({
                    id: 'documentprocessing.step2.newcontact.email.option',
                    defaultMessage: 'Primary Email',
                  }),
                  value: intl.formatMessage({
                    id: 'documentprocessing.step2.newcontact.email.option',
                    defaultMessage: 'Primary Email',
                  }),
                },
              ]}
              arrayName="emails"
              secondInputRules={[
                {
                  type: 'email',
                  required: true,
                  message: intl.formatMessage({
                    id: 'documentprocessing.step2.newcontact.email.secondinput.error',
                    defaultMessage: 'Must be valid email address',
                  }),
                },
              ]}
              firstInputNameField="label"
              secondInputNameField="email"
              firstInputPlaceholder={intl.formatMessage({
                id: 'documentprocessing.step2.newcontact.email.firstinputplaceholder',
                defaultMessage: 'E-mail label',
              })}
              secondInputPlaceholder={intl.formatMessage({
                id: 'documentprocessing.step2.newcontact.email.secondinputplaceholder',
                defaultMessage: 'E-mail address',
              })}
              addNextButtonLabel={intl.formatMessage({
                id: 'documentprocessing.step2.newcontact.email.addnextbutton',
                defaultMessage: 'Add e-mail address',
              })}
              standardFieldName="standardEmail"
              defaultValue={{
                label: intl.formatMessage({
                  id: 'documentprocessing.step2.newcontact.email.option',
                  defaultMessage: 'Primary Email',
                }),
                value: intl.formatMessage({
                  id: 'documentprocessing.step2.newcontact.email.option',
                  defaultMessage: 'Primary Email',
                }),
                standard: true,
              }}
            />

            {values.type === 'company' && (
              <Form.Item
                name="invoiceEmail"
                label={
                  <FormattedMessage
                    id="documentprocessing.step2.newcontact.invoiceemail.label"
                    defaultMessage="Invoice email"
                  />
                }
                rules={[
                  {
                    type: 'email',
                    message: (
                      <FormattedMessage
                        id="documentprocessing.step2.newcontact.invoiceemail.error"
                        defaultMessage="Must be valid email address"
                      />
                    ),
                  },
                ]}
              >
                <Input
                  name="invoiceEmail"
                  placeholder={intl.formatMessage({
                    id: 'documentprocessing.step2.newcontact.invoiceemail.placeholder',
                    defaultMessage: 'Invoice email',
                  })}
                />
              </Form.Item>
            )}

            <Address />

            <BankAccount />

            <Form.Item
              name="memo"
              label={
                <FormattedMessage
                  id="documentprocessing.step2.newcontact.memo.label"
                  defaultMessage="Memo"
                />
              }
              rules={[
                {
                  type: 'string',
                  max: 500,
                  message: (
                    <FormattedMessage
                      id="documentprocessing.step2.newcontact.memo.error"
                      defaultMessage="Memo cannot be longer than 500 characters"
                    />
                  ),
                },
              ]}
            >
              <Input.TextArea
                name="memo"
                placeholder={intl.formatMessage({
                  id: 'documentprocessing.step2.newcontact.memo.placeholder',
                  defaultMessage: 'Maximum 500 characters...',
                })}
              />
            </Form.Item>
            {values.type === 'company' && <CompanyDetails />}
            <ButtonsWrapper>
              <Button onClick={handleCancel}>
                <FormattedMessage
                  id="documentprocessing.step2.newcontact.memo.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button type="primary" htmlType="submit">
                <FormattedMessage
                  id="documentprocessing.step2.newcontact.memo.submit"
                  defaultMessage="Add new contact"
                />
              </Button>
            </ButtonsWrapper>
          </FormContext.Provider>
        )}
      </Form>
    </Spin>
  );
};

export default NewContact;
