import React, { FC } from 'react';
import { StyledSpan } from '@apps/documents/mainMenu/DocumentProcessing/DocumentProcessing.sc';
import { FormattedMessage } from 'react-intl';

type Props = {
  total: number;
  filled: number;
};

const FilledMandatoryFields: FC<Props> = ({ filled, total }) => (
  <StyledSpan>
    <FormattedMessage
      id="documentprocessing.filledmandatoryfields"
      defaultMessage="Filled mandatory fields: {filled}/{total}"
      values={{ filled, total }}
    />
  </StyledSpan>
);

export default FilledMandatoryFields;
