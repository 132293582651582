import React, { FC, useMemo, useState, useCallback } from 'react';
import { styled } from '@styles/themes';
import Modal, { ModalProps } from '@components/Modal';
import { Invitation } from '@services/invitations/namespace';
import StyledTable from '@pages/settings/components/StyledTable';
import { ColumnType } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl/lib';
import { Button, Space, message } from 'antd';
import updateInvitation from '@services/invitations/updateInvitation';
import { addMember } from '@services/ecosystems';
import ecosystemsListeners from '@services/ecosystems/ecosystemsListeners';
import { firebase } from '@services/Firebase';
import FetchedUser from '@components/FetchedUser';
import getInvitation from '@services/invitations/getInvitation';
import { IntlShape } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import updateInvitationStatus from '@services/invitations/updateInvitationStatus';
import { emitCustomEvent } from '@core/services';

const StyledModal = styled(Modal)`
  && {
    .ant-modal-body {
      padding: 40px 24px 24px 24px;
    }
  }
`;

type Props = ModalProps & {
  invitations: Invitation[];
  intl: IntlShape;
};

const InvitationsModal: FC<Props> = ({ invitations, intl, ...rest }) => {
  const user = useSelector(getUser);
  const [pendingInvitations, setPendingInvitations] =
    useState<Invitation[]>(invitations);

  const approveInvitation = useCallback(
    async (invitation: Invitation) => {
      if (firebase.db && invitation.id) {
        const fetchedInvitation = await getInvitation(invitation.id);
        if (!fetchedInvitation) {
          message.warning(
            intl.formatMessage({
              id: 'invitation.doesnt.exists.error',
              defaultMessage: `This invitation doesn't exists any more`,
            }),
          );
          setPendingInvitations(
            pendingInvitations.filter((inv) => inv.id !== invitation.id),
          );
          emitCustomEvent('remove_invitation', invitation);
          return;
        } else {
          if (fetchedInvitation.status !== 'PENDING') {
            message.warning(
              intl.formatMessage({
                id: 'invitation.cant.confirm.error',
                defaultMessage: `You can't confirm this invitation any more`,
              }),
            );
            setPendingInvitations(
              pendingInvitations.filter((inv) => inv.id !== invitation.id),
            );
            emitCustomEvent('remove_invitation', invitation);
            return;
          }
        }
        if (user && user.data?.uid) {
          const { uid } = user.data;
          const invitationWithUser: Invitation = {
            ...invitation,
            recipientId: uid,
            status: 'APPROVED',
          };
          await updateInvitation(invitationWithUser);
          await addMember(invitationWithUser);
          ecosystemsListeners([invitationWithUser.ecosystem], firebase.db);
          await firebase.initEcosystems();
          setPendingInvitations(
            pendingInvitations.filter(
              (entry) => entry.id !== invitationWithUser.id,
            ),
          );
        }
      }
    },
    [intl, pendingInvitations, user],
  );

  const declineInvitation = useCallback(
    async (invitation: Invitation) => {
      await updateInvitationStatus(invitation, 'DECLINED');
      setPendingInvitations(
        pendingInvitations.filter((entry) => entry.id !== invitation.id),
      );
    },
    [pendingInvitations],
  );

  const columns: ColumnType<any>[] = useMemo(() => {
    return [
      {
        title: (
          <FormattedMessage
            id="settings.memberslist.header.user"
            defaultMessage="User"
          />
        ),
        dataIndex: 'recipientId',
        render: (_v, record: Invitation) => (
          <FetchedUser key={_v} id={record.senderId} />
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.memberslist.header.email"
            defaultMessage="E-mail address"
          />
        ),
        sorter: (a, b) => a.email.localeCompare(b.email),
        dataIndex: 'recipientEmail',
        ellipsis: true,
      },
      {
        key: 'action',
        render: (_v, record: Invitation) => {
          return (
            <Space direction="horizontal">
              <Button size="small" onClick={() => approveInvitation(record)}>
                <FormattedMessage
                  id="button.approve"
                  defaultMessage="Approve"
                />
              </Button>
              <Button size="small" onClick={() => declineInvitation(record)}>
                <FormattedMessage
                  id="button.decline"
                  defaultMessage="Decline"
                />
              </Button>
            </Space>
          );
        },
      },
    ];
  }, [approveInvitation, declineInvitation]);

  return (
    <StyledModal {...rest}>
      <StyledTable
        dataSource={pendingInvitations}
        columns={columns}
        bordered
        pagination={{
          defaultPageSize: 5,
        }}
        rowKey="id"
      />
    </StyledModal>
  );
};

export default InvitationsModal;
