import styled from 'styled-components';
import Menu, { MenuProps, MenuItemGroupProps } from 'antd/lib/menu';
import { SubMenuProps } from 'antd/lib/menu/SubMenu';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';

export const MenuStyle = styled(Menu)<MenuProps>`
  && {
    .ant-menu-item-selected {
      background-color: ${(props) => props.theme.colors.background.lightGreen};
      color: ${(props) => props.theme.colors.text.lightGreen};
    }
    .ant-menu-item-selected::after {
      border-right: 3px solid ${(props) => props.theme.colors.text.lightGreen};
    }
  }
`;

export const SubMenuStyle = styled(Menu.SubMenu)<SubMenuProps>``;

export const MenuItemGroupMenuStyle = styled(
  Menu.ItemGroup,
)<MenuItemGroupProps>``;

export const MenuItemStyle = styled(Menu.Item)<MenuItemProps>``;
