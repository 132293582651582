import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Spin, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import getStatsOnEcosystems from '@services/statistics/getStatsForEcosystems';
import WeekplanAppConfig, { WEEKPLAN_ACTION } from '@apps/weekplan';
import { RootStore } from '@core/store';

const iconStyle = { width: '32px', height: '32px', fontSize: '32px' };

const StatisticsWidget: FC = () => {
  const [spinning, setSpinning] = useState(true);
  const [stats, setStats] = useState<any>(0);
  const activeEcosystems = useSelector((state) =>
    getActiveEcosystemsForApp(
      state as RootStore,
      WeekplanAppConfig.todixId,
      'view-weekplans' as WEEKPLAN_ACTION,
    ),
  );

  useEffect(() => {
    if (activeEcosystems.length) {
      getStatsOnEcosystems({
        ecosystems: activeEcosystems,
        collection: WeekplanAppConfig.collectionName,
      })
        .then((r) => {
          setStats(r);
          setSpinning(false);
        })
        .catch((e) => {
          console.error(e);
          setSpinning(false);
        });
    }
  }, [setStats, activeEcosystems]);

  return (
    <Spin spinning={spinning} style={{ height: '100%' }}>
      <Tooltip
        title={
          <FormattedMessage
            id="app.weekplan.mainMenu.name"
            defaultMessage="WeekPlan"
          />
        }
      >
        <Link to={`app/${WeekplanAppConfig.todixId}`}>
          <Badge count={stats}>
            <WeekplanAppConfig.mainMenu.icon style={iconStyle} />
          </Badge>
        </Link>
      </Tooltip>
    </Spin>
  );
};

export default StatisticsWidget;
