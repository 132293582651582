import { Editor } from 'slate';
import isMarkActive from '@components/RichTextEditor/helpers/isMarkActive';

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export default toggleMark;
