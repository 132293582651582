import axios from 'axios';
import {
  GetProductsResponse,
  StripePrice,
  StripeProduct,
  SubscriptionWithEcosystem,
} from '@services/stripe/namespace';
import { firebase } from '@services/Firebase';

export const APP_LIMIT_PER_SUBSCRIPTION = 20;

type CreateCheckoutSessionPayload = {
  userId: string;
  successUrl: string;
  cancelUrl: string;
  setTrial?: boolean;
};

type CreateCheckoutSessionResponse = {
  data: {
    sessionUrl: string;
    latestInvoiceUrl?: string;
  };
};

type GetSubscriptionsPayload = {
  userId: string;
  ecosystemId: string;
};

type GetSubscriptionsResponse = {
  data: { subscriptions: SubscriptionWithEcosystem[] };
};

type UpdateSubscriptionPayload = {
  userId: string;
  subscriptionId: string;
  subscriptionData: Record<string, any>;
};

type UpdateSubscriptionResponse = {
  data: Record<string, any>;
};

const SK_KEY = process.env.STRIPE_KEY;

const stripe = () => {
  const config = {
    baseURL: 'https://api.stripe.com/v1',
  };
  const instance = axios.create(config);
  instance.defaults.headers.common['Authorization'] = `Bearer ${SK_KEY}`;

  const getProducts = async () => {
    const response = await instance.get<GetProductsResponse>('/products', {
      ...config,
      params: {
        limit: 1000,
      },
    });
    return response.data;
  };

  const getProduct = (id: string) => {
    return instance
      .get<StripeProduct>(`/products/${id}`, config)
      .then((response) => response.data);
  };

  const getPrice = (id: string) => {
    return instance
      .get<StripePrice>(`/prices/${id}`, config)
      .then((response) => response.data);
  };

  const createCheckoutSession = (payload: CreateCheckoutSessionPayload) => {
    return firebase.functions
      ?.httpsCallable('stripeServer/create-checkout-session')(payload)
      .then((data: CreateCheckoutSessionResponse) => data.data.sessionUrl);
  };

  const getActiveSubscriptions = (payload: GetSubscriptionsPayload) => {
    return (firebase.functions as firebase.functions.Functions)
      .httpsCallable('stripeServer/get-active-subscriptions')(payload)
      .then((data: GetSubscriptionsResponse) => data.data.subscriptions);
  };

  const getSubscriptions = (payload: GetSubscriptionsPayload) => {
    return (firebase.functions as firebase.functions.Functions)
      .httpsCallable('stripeServer/get-subscriptions')(payload)
      .then((data: GetSubscriptionsResponse) => data.data.subscriptions);
  };

  const updateSubscription = (payload: UpdateSubscriptionPayload) => {
    return firebase.functions
      ?.httpsCallable('stripeServer/update-subscription')(payload)
      .then((data: UpdateSubscriptionResponse) => data.data);
  };

  return {
    getProducts,
    getProduct,
    getPrice,
    createCheckoutSession,
    getActiveSubscriptions,
    getSubscriptions,
    updateSubscription,
  };
};

export default stripe();
