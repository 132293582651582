import React, { FC } from 'react';
import { StripeProduct } from '@services/stripe/namespace';
import { Space, Image } from 'antd';
import { FormattedMessage } from 'react-intl';

type ApplicationProps = {
  product: StripeProduct;
};

const Application: FC<ApplicationProps> = ({ product }) => {
  return (
    <Space direction="horizontal">
      <Image
        src={product.images[0]}
        preview={false}
        fallback="No image"
        style={{
          maxWidth: '36px',
        }}
      />
      <FormattedMessage
        id={product.metadata.title as string}
        defaultMessage={(product.metadata.title as string) || product.name}
      />
    </Space>
  );
};

export default Application;
