import { IGetRowsParams } from 'ag-grid-community';
import { getContacts } from '@apps/contacts/services';
import { FilterTextObject } from '@services/filtering';
import { CONTACTS_ACTION } from '@apps/contacts';

export const getRows = (
  params: IGetRowsParams & { ecosystems?: string[]; query?: string },
) => {
  const filtersEntries = Object.entries(params.filterModel).map(
    ([key, filterObj]) => [
      key,
      // @ts-ignore
      filterObj,
    ],
  );
  if (!params.filterModel.ecosystem && params.ecosystems) {
    filtersEntries.push([
      'ecosystem',
      {
        filter: params.ecosystems.join('|'),
        filterType: 'text',
        type: 'contains',
      } as FilterTextObject,
    ]);
  }
  getContacts(
    {
      query: params.query,
      offset: params.startRow,
      limit: params.endRow - params.startRow,
      fields: [
        'id',
        'ecosystem',
        'avatar',
        'firstName',
        'lastName',
        'companyName',
        'employer',
        'titlePosition',
        'phones',
        'emails',
        'addresses',
        'standardPhone',
        'standardEmail',
        'standardInvoiceAddress',
        'standardShippingAddress',
        'gender',
        'nickName',
        'department',
        'birthDate',
        'memo',
      ],
      filters: Object.fromEntries(filtersEntries),
      sort: params.sortModel.map(
        (model: { colId: string; sort: string }) =>
          `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
      ),
    },
    'view-contacts' as CONTACTS_ACTION,
  ).then((response) => {
    params.successCallback(response.results, response.info.results);
  });
};
