import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'antd';
import WidgetResolver from '@components/WidgetResolver';

const { Item } = Form;

type AssigneeProps = {
  readOnly: boolean;
};

const Assignee: FC<AssigneeProps> = ({ readOnly }) => {
  return (
    <Item shouldUpdate noStyle>
      {({ getFieldValue, getFieldsValue, setFieldsValue }) => {
        return (
          <Item
            name="assignee"
            label={
              <FormattedMessage
                id="tasks.assignee.label"
                defaultMessage="Assignee"
              />
            }
          >
            <WidgetResolver
              appName="projects"
              widgetName="MemberSelector"
              injectedProps={{
                placeholder: (
                  <FormattedMessage
                    id="tasks.assignee.addNew.button"
                    defaultMessage="Assign new user"
                  />
                ),
                ecosystem: getFieldValue('ecosystem'),
                onChange: (id: string) =>
                  setFieldsValue({
                    ...getFieldsValue(),
                    assignee: id,
                  }),
                value: getFieldValue('assignee'),
                readOnly,
              }}
            />
          </Item>
        );
      }}
    </Item>
  );
};

export default Assignee;
