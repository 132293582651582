import {
  USER_FIELDS_COLLECTION_NAME,
  UserField,
} from '@apps/products/services/namespace';
import { patch } from '@services/api';

const updateProductUserField = (userField: UserField) => {
  const data = { ...userField };
  delete data.id;
  return patch(`${USER_FIELDS_COLLECTION_NAME}/${userField.id}`, data);
};

export default updateProductUserField;
