import React, { FC, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { getProjectById, Project } from '@apps/projects/services';

type Props = {
  value?: string;
  data?: {
    project: string;
  };
};

const ProjectRenderer: FC<Props> = ({ value, data }) => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<Project | null>(null);

  useEffect(() => {
    if (value) {
      setLoading(true);
      getProjectById(value)
        .then((projectData) => {
          setProject(projectData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [value]);

  if (!value || !data) return null;

  return (
    <Spin spinning={loading} size="small">
      {project?.name}
    </Spin>
  );
};

export default ProjectRenderer;
