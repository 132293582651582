import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import {
  ENTITY_RELATIONS_COLLECTION,
  EntityRelation,
} from '@services/entityRelations/namespace';

type GetRelationPayload = {
  parentId: string;
  childId: string;
  ecosystem?: string;
};

const getRelation = ({ parentId, childId, ecosystem }: GetRelationPayload) => {
  let query = firebase.firestore
    ?.collection(ENTITY_RELATIONS_COLLECTION)
    .where('parentId', '==', parentId)
    .where('childId', '==', childId);

  if (ecosystem) {
    query?.where('ecosystem', '==', ecosystem);
  }
  return query?.get().then(getArrayWithIds) as Promise<EntityRelation[]>;
};

export default getRelation;
