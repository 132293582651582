import { styled } from '@styles/themes';
import { Switch } from 'antd';

const StyledSwitch = styled(Switch)`
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default StyledSwitch;
