import React, { FC, MouseEventHandler } from 'react';
import { Button } from 'antd';
import FormWithReminder, {
  FormWithReminderProps,
} from '@components/FormWithReminder';
import { ButtonsWrapper } from '@components/FormWithReminder/FormWithReminder.sc';

export type ButtonConfig = {
  label: string;
  onClick?: MouseEventHandler<HTMLElement>;
  disabled?: boolean;
};

type Props = FormWithReminderProps & {
  readonlyCancelButton: ButtonConfig;
  readonlySubmitButton?: ButtonConfig;
  cancelButton: ButtonConfig;
  submitButton?: ButtonConfig;
  hideButtons?: boolean;
};

const FormWithButtons: FC<Props> = ({
  submitButton,
  cancelButton,
  readonlyCancelButton,
  readonlySubmitButton,
  children,
  hideButtons,
  ...rest
}) => {
  const { readOnly, name } = rest;
  return (
    <FormWithReminder {...rest} labelAlign="left" colon={false}>
      {children}
      {readOnly && !hideButtons && (
        <ButtonsWrapper className="form-with-reminder-buttons">
          <Button
            form={name}
            onClick={readonlyCancelButton.onClick}
            style={{ marginRight: '20px' }}
          >
            {readonlyCancelButton.label}
          </Button>
          {readonlySubmitButton && (
            <Button
              type="primary"
              onClick={readonlySubmitButton.onClick}
              disabled={readonlySubmitButton.disabled}
            >
              {readonlySubmitButton.label}
            </Button>
          )}
        </ButtonsWrapper>
      )}
      {!readOnly && !hideButtons && (
        <ButtonsWrapper className="form-with-reminder-buttons">
          <Button
            form={name}
            onClick={cancelButton.onClick}
            style={{ marginRight: '20px' }}
          >
            {cancelButton.label}
          </Button>
          {submitButton && (
            <Button
              type="primary"
              htmlType="submit"
              form={name}
              disabled={submitButton.disabled || false}
              onClick={submitButton.onClick}
            >
              {submitButton.label}
            </Button>
          )}
        </ButtonsWrapper>
      )}
    </FormWithReminder>
  );
};

export default FormWithButtons;
