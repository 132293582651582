import React, { FC, useState, useEffect } from 'react';
import { Spin, Space, Badge, Popover } from 'antd';
import { EntityWithType, Project } from '@apps/projects/services';
import SpinContainer from '@components/SpinContainer';
import getProjectById from '@apps/projects/services/getProjectById';
import ProjectNameRenderer from '@apps/projects/widgets/ProjectNameRenderer';

type ProjectCellRendererProps = {
  value: EntityWithType[];
};

const ProjectCellRenderer: FC<ProjectCellRendererProps> = (props) => {
  const { value } = props;
  const [isFetching, setIsFetching] = useState(true);
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    if (value) {
      setIsFetching(true);
      const projectsToFetch = value
        .filter((entry) => entry.type === 'project')
        .map((entry) => {
          return getProjectById(entry.id);
        });
      Promise.all(projectsToFetch)
        .then((fetchedProjects) => setProjects(fetchedProjects))
        .finally(() => setIsFetching(false));
    }
  }, [value]);

  if (value) {
    const content = (
      <Space direction="vertical" size="small">
        {projects.map((project, index) => (
          <ProjectNameRenderer
            key={index}
            data={{
              name: project.name,
              color: project.color,
            }}
          />
        ))}
      </Space>
    );

    return (
      <Spin spinning={isFetching} indicator={<SpinContainer />}>
        <Popover content={content}>
          <Badge count={projects.length} showZero />
        </Popover>
      </Spin>
    );
  }
  return null;
};

export default ProjectCellRenderer;
