import { getWithId } from '@services/api';
import {
  PRODUCTS_COLLECTION_NAME,
  Product,
} from '@apps/products/services/namespace';

const getProduct = (id: string) =>
  getWithId(`${PRODUCTS_COLLECTION_NAME}/${id}`) as Promise<Product>;

export default getProduct;
