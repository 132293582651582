import React, { FC } from 'react';
import { Contact } from '@apps/contacts/services';
import { Space, Avatar } from 'antd';

type Props = {
  value: Contact;
};

const CustomerCellRenderer: FC<Props> = ({ value }) => {
  if (!value) {
    return <span />;
  }
  const { type, companyName, firstName, lastName, avatar } = value;
  const customerName =
    type === 'company'
      ? `${companyName || ''}`
      : `${firstName || ''} ${lastName || ''}`;
  return (
    <Space>
      {avatar && <Avatar src={avatar} size="small" />}
      <div>{customerName}</div>
    </Space>
  );
};

export default CustomerCellRenderer;
