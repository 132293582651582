import {
  Document,
  DocumentCategory,
  getContactName,
} from '@apps/documents/service';
import { IntlShape } from 'react-intl';
// import moment from 'moment';

const getDirection = (category: DocumentCategory, intl: IntlShape) => {
  const directions = {
    incoming: intl.formatMessage({
      id: 'documents.documentTypes.category.incoming',
      defaultMessage: `incoming`,
    }),
    outgoing: intl.formatMessage({
      id: 'documents.documentTypes.category.outgoing',
      defaultMessage: `outgoing`,
    }),
    none: intl.formatMessage({
      id: 'documents.documentTypes.category.none',
      defaultMessage: `non direction`,
    }),
  };
  return directions[category];
};

export const getName = (doc: Document, intl: IntlShape) => {
  const type = doc.type
    ? intl.formatMessage({
        id: `documents.documentTypes.${doc.type}`,
        defaultMessage: doc.type,
      })
    : intl
        .formatMessage({
          id: 'documents.unknowndocument',
          defaultMessage: `Unknown document`,
        })
        .toUpperCase();
  let contactName = intl.formatMessage({
    id: 'documents.unknowncontact',
    defaultMessage: `Unknown Contact`,
  });
  if (doc.relatedContactSnapshot) {
    contactName = getContactName(doc.relatedContactSnapshot) as string;
  }
  return intl.formatMessage(
    {
      id: 'documents.inbox.processingheader.documentname',
      defaultMessage: '{type} {issuingDate} {category} {contact}',
    },
    {
      type,
      issuingDate: (doc.issuingDate as string) || '',
      contact: contactName,
      category: getDirection(doc.category, intl),
    },
  );
};
