import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { FileApp } from '@apps/AppTypes';
import { CONSULTANTS_COLLECTION_NAME } from '@apps/directSales/mainMenu/Consultants/services/namespace';

export default {
  todixId: 'directSales',
  link: '/app/directSales',
  name: 'directSales',
  entityType: 'directSales',
  collectionName: CONSULTANTS_COLLECTION_NAME,
  localizationPath: 'directSales/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create-consultant',
      title: 'consultants.consultant',
    },
    {
      link: 'create-party',
      title: 'consultants.party',
    },
    {
      link: 'create-news',
      title: 'app.directsales.news',
    },
  ],
  listCellRenderers: [
    {
      name: 'activationCellRenderer',
      path: 'directSales/widgets/ActivationCellRenderer',
    },
    {
      name: 'validationCellRenderer',
      path: 'directSales/widgets/ValidationCellRenderer',
    },
    {
      name: 'mobileLicenseCellRenderer',
      path: 'directSales/widgets/MobileLicenseCellRenderer',
    },
    {
      name: 'consultantParentCellRenderer',
      path: 'directSales/widgets/ConsultantParentCellRenderer',
    },
    {
      name: 'reportEmailStatusCellRenderer',
      path: 'directSales/widgets/ReportEmailStatusCellRenderer',
    },
  ],
} as FileApp;
