import * as React from 'react';
import moment from 'moment';
import { DatePicker as AntdDatePicker } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import { Label } from '@components/FormFields';
import { FlexWrapper } from '@components/FormFields/common.sc';

type Props = DatePickerProps & {
  name: string;
  label?: string;
};

const DatePicker: React.FC<Props> = (props) => {
  const { onChange, ...rest } = props;

  const handleChange: DatePickerProps['onChange'] = (date, dateString) => {
    onChange && onChange(date, dateString);
  };

  return (
    <>
      <FlexWrapper>
        {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
        <AntdDatePicker
          format={moment.defaultFormat}
          {...rest}
          onChange={handleChange}
          getPopupContainer={() => {
            const mainElement = document
              .getElementsByClassName('fullscreen')
              .item(0) as HTMLElement;
            return mainElement || document.body;
          }}
        />
      </FlexWrapper>
    </>
  );
};

export default React.memo<Props>(DatePicker);
