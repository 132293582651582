import React, { FC, useCallback } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Form, Button, Modal } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { PDFPreviewTrigger } from '@apps/sales/mainMenu/components/SaleCreator/styles.sc';
import getLayout from '@apps/sales/settings/layouts';
import { toDataURL } from '@apps/utils';
import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';
import { useIntl } from 'react-intl';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import MainTemplate from '@apps/sales/mainMenu/components/pdfTemplates/MainTemplate';

const PDFPreview: FC = () => {
  const intl = useIntl();
  const activeEcosystems = useSelector(getActiveEcosystems);
  const showPdfPreview = useCallback(
    (values: SalesFormValues, ecosystem: string) => {
      getLayout(values.type).then(async (layout) => {
        const eco = activeEcosystems.find((eco) => eco.id === ecosystem);
        const avatar: string =
          eco && eco.avatarUrl ? await toDataURL(eco.avatarUrl) : '';
        if (layout) {
          Modal.success({
            width: 960,
            content: (
              <PDFViewer showToolbar={true} width={900} height={600}>
                {MainTemplate({
                  values,
                  layout,
                  ecosystem: eco,
                  intl,
                  avatar,
                })}
              </PDFViewer>
            ),
            icon: null,
          });
        }
      });
    },
    [activeEcosystems, intl],
  );

  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          const { ecosystem, type } = getFieldsValue();

          if (!ecosystem || !type) {
            return null;
          }

          return (
            <PDFPreviewTrigger>
              <Button
                icon={<FilePdfOutlined />}
                onClick={() => showPdfPreview(getFieldsValue(), ecosystem)}
              />
            </PDFPreviewTrigger>
          );
        }}
      </Form.Item>
    </>
  );
};

export default PDFPreview;
