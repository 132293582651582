import { FieldType, LayoutField } from '@apps/documents/service/namespace';
import {
  CommonFieldsLayout,
  SideViewCommonFieldsLayout,
} from '@apps/documents/service/commonFields.layouts';
import { LayoutDestination } from '@apps/documents/service/layouts';

export const ProcessingDataPrivacyStatementLayout: LayoutField[] = [
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    label: 'documents.processingcatalogue.receivingdate.label',
    type: 'date',
    placeholder: 'documents.processingcatalogue.receivingdate.placeholder',
    noFutureDate: true,
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
    noFutureDate: true,
  },
  {
    fieldName: 'commencingDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.commencingdate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.commencingdate.placeholder',
  },
  {
    fieldName: 'endingDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.endingdate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.endingdate.placeholder',
  },
  {
    fieldName: 'memo',
    fieldType: 'input',
    label: 'documents.catalogue.memo.label',
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    placeholder: 'documents.commonfields.sideview.notes',
    wrapperStyle: {
      gridColumn: '1 / span 2',
    },
  },
];

export const ProcessingSummaryDataPrivacyLayoutLayout: LayoutField[] =
  ProcessingDataPrivacyStatementLayout.map((field) => ({
    ...field,
    fieldType: ['datepicker'].includes(field.fieldType)
      ? (field.fieldType as FieldType)
      : 'input',
    placeholder: '',
    style: {
      border: 0,
    },
  }));

export const DataPrivacyStatementLayout: LayoutField[] = [
  ...CommonFieldsLayout,
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.catalogue.receivingdate.label',
    type: 'date',
    placeholder: 'documents.catalogue.receivingdate.placeholder',
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'commencingDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.commencingdate.label',
    type: 'date',
    readOnly: true,
    placeholder: 'documents.processinginvoice.commencingdate.placeholder',
  },
  {
    fieldName: 'endingDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.endingdate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.endingdate.placeholder',
    readOnly: true,
  },
  {
    fieldName: 'memo',
    fieldType: 'input',
    label: 'documents.catalogue.memo.label',
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];

export const SideViewDataPrivacyLayout: LayoutField[] = [
  ...SideViewCommonFieldsLayout,
  {
    fieldName: 'receivingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.catalogue.sideview.receivingdate.label',
    type: 'date',
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'commencingDate',
    fieldType: 'input',
    label: 'documents.processinginvoice.commencingdate.label',
    type: 'date',
    readOnly: true,
    placeholder: 'documents.processinginvoice.commencingdate.placeholder',
  },
  {
    fieldName: 'endingDate',
    fieldType: 'input',
    label: 'documents.processinginvoice.endingdate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.endingdate.placeholder',
    readOnly: true,
  },
  {
    fieldName: 'memo',
    fieldType: 'input',
    label: 'documents.catalogue.memo.label',
    readOnly: true,
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];

const dataPrivacyStatementLayouts: Record<LayoutDestination, LayoutField[]> = {
  detailedForm: DataPrivacyStatementLayout,
  sideView: SideViewDataPrivacyLayout,
  processing: ProcessingDataPrivacyStatementLayout,
  processingSummary: ProcessingSummaryDataPrivacyLayoutLayout,
};

export default dataPrivacyStatementLayouts;
