import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import getProductNumber from '@apps/products/services/getProductNumber';
import { PURCHASES_ACTION } from '@apps/purchases';

const { Item } = Form;

type ProductNumberProps = {
  readOnly: boolean;
  namePrefix?: string | number;
  action: PURCHASES_ACTION;
  id?: string;
};

const ProductNumber: FC<ProductNumberProps> = ({
  namePrefix,
  readOnly,
  action,
  id,
}) => {
  const intl = useIntl();
  const isReadOnly = readOnly || action !== 'create-purchases-requests';
  return (
    <Item noStyle shouldUpdate={true}>
      {({ getFieldValue }) => {
        const ecosystem = getFieldValue('ecosystem');
        return (
          <Item
            name={
              namePrefix !== undefined
                ? [namePrefix, `productNumber`]
                : `productNumber`
            }
            label={
              <FormattedMessage
                id="products.form.productnumber.label"
                defaultMessage="Product number"
              />
            }
            rules={[
              {
                validator: (_rule, value: string) => {
                  return new Promise((resolve, reject) => {
                    if (value && !id && !isReadOnly) {
                      if (value.includes('/')) {
                        return reject(
                          intl.formatMessage({
                            id: 'product.number.syntax.error',
                            defaultMessage: 'Cannot use / in product number',
                          }),
                        );
                      }
                      getProductNumber(`${ecosystem}_${value}`)
                        .then((result) => {
                          if (!result) {
                            return resolve('');
                          } else {
                            return reject(
                              intl.formatMessage({
                                id: 'product.number.error',
                                defaultMessage: 'Product number already in use',
                              }),
                            );
                          }
                        })
                        .catch(() => {
                          reject('error');
                        });
                    } else {
                      return resolve('');
                    }
                  });
                },
              },
            ]}
          >
            <Input
              disabled={isReadOnly}
              placeholder={intl.formatMessage({
                id: 'products.form.productnumber.placeholder',
                defaultMessage: 'Product number...',
              })}
              autoComplete="nope"
              readOnly={isReadOnly}
            />
          </Item>
        );
      }}
    </Item>
  );
};

export default ProductNumber;
