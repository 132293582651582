import {
  GetApiResponse,
  Purchase,
  PURCHASES_COLLECTION,
} from '@apps/purchases/services/namespace';
import appConfig from '@apps/purchases';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import { get as apiGet } from '@services/api';
import getFilteredResults from '@core/helpers/getFilteredResults';

const getPurchases: GetApiResponse = (passedParams, action) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  ).map((eco) => eco.id);

  const params = {
    query: '',
    offset: 0,
    limit: 1000,
    fields: [
      'id',
      'ecosystem',
      'contact',
      'contactSnapshot',
      'products',
      'timestamp',
    ],
    filters: {},
    sort: [],
    ...passedParams,
  };

  // @ts-ignore
  return apiGet(PURCHASES_COLLECTION)?.then((data: Purchase[]) => {
    return getFilteredResults({
      data,
      params,
      activeEcosystemsIds,
    });
  });
};

export default getPurchases;
