import styled from 'styled-components';
import { Text } from '@todix/ui-components';

const StyledSectionTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.text.gray};
  font-weight: 600;
  margin-bottom: 18px;
  display: block;
`;

export default StyledSectionTitle;
