import {
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@firebase/firestore-types';

const getArrayWithIds = (querySnapshot: QuerySnapshot) =>
  querySnapshot.docs.map((doc: QueryDocumentSnapshot) => ({
    ...doc.data(),
    id: doc.id,
  }));

export default getArrayWithIds;
