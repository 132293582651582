import { LayoutField } from '@apps/documents/service/namespace';
import { FormItemProps } from 'antd/lib/form';

export { default as SelectField } from './SelectField';
export { default as InputField } from './InputField';
export { default as TextAreaField } from './TextAreaField';
export { default as SeparatedValues } from './SeparatedValues';
export { default as Contact } from './Contact';
export { default as User } from './User';
export { default as RadioField } from './RadioField';
export { default as RadioGroupField } from './RadioGroupField';
export { default as DatePickerField } from './DatePickerField';
export { default as CostStructure } from './CostStructure';
export { default as RelatedDocuments } from './RelatedDocuments';

export type FieldProps = FormItemProps & {
  field: LayoutField;
};
