import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { DashboardOutlined } from '@ant-design/icons';
import { Card } from '@todix/ui-components';
import Tabs from '@components/Tabs';
import MainView from '@apps/warehouse/mainMenu/components/MainView';
import DispoProposal from '@apps/warehouse/mainMenu/components/DispoProposal';
import Expectations from '@apps/warehouse/mainMenu/components/Expectations';
import Income from '@apps/warehouse/mainMenu/components/Income';
import Positions from '@apps/warehouse/mainMenu/components/Positions';

const { TabPane } = Tabs;

const View: FC = () => {
  return (
    <Card>
      <Tabs defaultActiveKey="1">
        <TabPane
          key="1"
          tab={
            <>
              <DashboardOutlined />{' '}
              <FormattedMessage
                id="stacked.basetab"
                defaultMessage="Base Tab"
              />
            </>
          }
        >
          <MainView action="view-warehouses" />
        </TabPane>
        <TabPane
          key="2"
          disabled
          tab={
            <>
              <FormattedMessage
                id="warehouse.tab.dispo.proposal"
                defaultMessage="Dispo proposal"
              />
            </>
          }
        >
          <DispoProposal />
        </TabPane>
        <TabPane
          key="3"
          disabled
          tab={
            <>
              <FormattedMessage
                id="warehouse.tab.expectations"
                defaultMessage="Expectations"
              />
            </>
          }
        >
          <Expectations />
        </TabPane>
        <TabPane
          key="4"
          tab={
            <>
              <FormattedMessage
                id="warehouse.tab.income"
                defaultMessage="Income"
              />
            </>
          }
        >
          <Income action="view-warehouses" />
        </TabPane>
        <TabPane
          key="5"
          tab={
            <>
              <FormattedMessage
                id="warehouse.tab.positions"
                defaultMessage="Positions"
              />
            </>
          }
        >
          <Positions action="view-warehouses" />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default View;
