import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Form, Select } from 'antd';
import intl from '../../../../../../intl';
import {
  getActiveSalesOrderTypes,
  OrderType,
} from '@apps/sales/services/orderTypes';
import SalesTabContext from '@apps/sales/widgets/SalesTab/context';

const { Item } = Form;

const PriceOrderTypeConstraints: React.FC = () => {
  const { ecosystem } = useContext(SalesTabContext);
  const [isLoading, setIsLoading] = useState(false);
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);

  useEffect(() => {
    if (ecosystem) {
      setIsLoading(true);
      getActiveSalesOrderTypes(ecosystem.id)
        ?.then(setOrderTypes)
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [ecosystem]);

  const options = useMemo(
    () =>
      orderTypes.map((orderType, index) => {
        return {
          key: index,
          value: orderType.id,
          label: orderType.name,
        };
      }),
    [orderTypes],
  );

  return (
    <Item name={['constraints', 'validOrderType']}>
      <Select
        loading={isLoading}
        placeholder={intl.formatMessage({
          id: 'sales.salesTab.priceConstraints.validOrderType.placeholder',
          defaultMessage: 'Valid order type',
        })}
        options={options}
      />
    </Item>
  );
};

export default PriceOrderTypeConstraints;
