import React from 'react';
import { Spin, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import DetailedContact from '@apps/contacts/mainMenu/components/DetailedContact';
import {
  CompanyContact,
  Contact,
  PersonContact,
  postContact,
} from '@apps/contacts/services';
import ContactsAppConfig, { CONTACTS_ACTION } from '@apps/contacts';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

type Props = {
  shouldRedirect?: boolean;
  onSubmit?: (contact: Contact) => void;
  onCancel?: () => void;
};

const Create: React.FC<Props> = ({
  shouldRedirect = true,
  onSubmit,
  onCancel,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const intl = useIntl();
  const history = useHistory();
  const firstActiveEcosystemId = getActiveEcosystemsForApp(
    rootStore.getState(),
    ContactsAppConfig.todixId,
    'create-contacts' as CONTACTS_ACTION,
  )?.map((el) => el.id)[0];

  const handleSubmit = React.useCallback(
    (values) => {
      setIsSubmitting(true);
      postContact(values)
        ?.then((id: string) => {
          // message.success(
          //   intl.formatMessage({
          //     id: 'contacts.create.success',
          //     defaultMessage: 'Contact created',
          //   }),
          // );
          setIsSubmitting(false);
          if (shouldRedirect) {
            history.push(`/app/${ContactsAppConfig.todixId}`);
          }
          if (onSubmit) {
            onSubmit({
              ...values,
              id,
            });
          }
        })
        .catch(() => {
          setIsSubmitting(false);
          message.error(
            intl.formatMessage({
              id: 'contacts.create.error',
              defaultMessage: 'Error while contact creating',
            }),
          );
        });
    },
    [shouldRedirect, onSubmit, history, intl],
  );

  return (
    <Spin spinning={isSubmitting}>
      <DetailedContact
        readOnly={false}
        shouldRedirect={shouldRedirect}
        onSubmit={(values: PersonContact | CompanyContact) => {
          handleSubmit(values);
        }}
        onCancel={onCancel}
        initialValues={{
          ecosystem: firstActiveEcosystemId,
        }}
        action={'create-contacts' as CONTACTS_ACTION}
      />
    </Spin>
  );
};

export default Create;
