export type ConsultantLevelType = {
  id?: string;
  level: string;
  ecosystemId: string;
  shortage?: string;
  longName?: string;
  category?: string;
  description?: string;
  isActive: boolean;
  timestamp?: number;
};

export const DIRECTSALES_CONSULTANT_LEVEL_COLLECTION_NAME = 'consultantLevels';
