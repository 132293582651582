import { Button, Result } from 'antd';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

const Success: FC = () => {
  const intl = useIntl();

  return (
    <Result
      status={'success'}
      title={intl.formatMessage({
        id: 'order.success',
        defaultMessage: 'Thank you for your purchase',
      })}
      subTitle={intl.formatMessage({
        id: 'order.subtitle',
        defaultMessage: 'Order configuration takes 1-2 minutes, please wait.',
      })}
      extra={[
        <Button
          type="primary"
          key="console"
          onClick={() => (window.location.href = window.location.origin)}
        >
          {intl.formatMessage({
            id: 'order.dashboard.link',
            defaultMessage: 'Go to dashboard',
          })}
        </Button>,
      ]}
    />
  );
};

export default Success;
