import { AxiosResponse } from 'axios';
import { SelectProps, LabeledValue } from 'antd/lib/select';
import ApiService from '../config';
import { IntlShape } from 'react-intl';
import { getCollection } from '@services/api';
import { DocumentType } from '@apps/documents/service';
import countries from '@assets/countries.json';
import langs from '@assets/languages.json';

export type LocalisedValue = {
  value: any;
  [locale: string]: string;
};

export type Region = {
  name: string;
  shortCode: string;
};

type CountrySetValue = {
  name: string;
  native: string;
  phone: number[];
  continent: string;
  capital: string;
  currency: string[];
  languages: string[];
};

export type Countries = {
  [code: string]: CountrySetValue;
};

export type CountryEntry = {
  countryName: string;
  countryShortCode: string;
  regions?: Region[];
};

export type LanguageEntry = {
  name: string;
  nativeName: string;
};

export type PaymentMethod = {
  value: string;
  isActive: boolean;
};

export type Languages = Record<string, LanguageEntry>;

export type Currencies = Record<string, string>;

type GetCurrencies = () => Promise<Currencies>;

export const getCurrencies: GetCurrencies = async () => {
  const response = await ApiService.get<AxiosResponse<Currencies>>(
    'https://openexchangerates.org/api/currencies.json',
  );

  return response.data;
};

type GetCountries = () => CountryEntry[];

export const getCountries: GetCountries = () => {
  const countrySet = countries as Countries;
  return Object.keys(countrySet).map((code) => {
    const country = countrySet[code];

    return {
      countryName: country.native,
      countryShortCode: code,
      regions: [],
    } as CountryEntry;
  });
};

type GetLanguages = () => string[];

export const getLanguages: GetLanguages = () => {
  const languages = langs as Languages;
  return Object.keys(languages).map((code) => languages[code].nativeName);
};

type GetLanguagesWithCodes = () => { name: string; code: string }[];

export const getLanguagesWithCodes: GetLanguagesWithCodes = () => {
  const languages = langs as Languages;
  return Object.keys(languages).map((code) => ({
    name: languages[code].nativeName,
    code,
  }));
};

export const getCompanyTypes = () => {
  return [
    'Behörde',
    'GmbH',
    'GmbH & Co. KG',
    'AG',
    'e.K.',
    'KG',
    'OHG',
    'GBR',
    'UG',
  ];
};

export const getRegisterCourt = () => {
  return ['Amtsgericht Köln', 'Amtsgericht Düsseldorf'];
};

export const getCostTypes = () => getCollection('costTypes');

export const getPaymentMethods = () => getCollection('paymentMethods');

export const getTimeIntervals = () =>
  ['Day', 'Month', 'Year'].map((el) => ({
    value: el,
    label: el,
  }));

export const getSignatures = () => {
  return [
    {
      value: 'checked',
      label: 'checked',
    },
    {
      value: 'notChecked',
      label: 'not checked',
    },
  ];
};

export const getTaxRates = () => {
  return [
    {
      value: 19,
      label: '19% Standardlieferung',
    },
    {
      value: 7,
      label: '7% ermäßigter Steuersatz',
    },
    {
      value: 0,
      label: '0% Innerhalb EU',
    },
    {
      value: 0,
      label: '0% Ausserhalb EU',
    },
  ];
};

export const getPhonesLabels = (intl: IntlShape): LabeledValue[] => [
  {
    label: intl.formatMessage({
      id: 'detailcontactform.generalinformation.phone.option.business',
      defaultMessage: 'Business',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.generalinformation.phone.option.business',
      defaultMessage: 'Business',
    }),
  },
  {
    label: intl.formatMessage({
      id: 'detailcontactform.generalinformation.phone.option.private',
      defaultMessage: 'Private',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.generalinformation.phone.option.private',
      defaultMessage: 'Private',
    }),
  },
  {
    label: intl.formatMessage({
      id: 'detailcontactform.generalinformation.phone.option.mobilebusiness',
      defaultMessage: 'Mobile Business',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.generalinformation.phone.option.mobilebusiness',
      defaultMessage: 'Mobile Business',
    }),
  },
  {
    label: intl.formatMessage({
      id: 'detailcontactform.generalinformation.phone.option.mobileprivate',
      defaultMessage: 'Mobile Private',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.generalinformation.phone.option.mobileprivate',
      defaultMessage: 'Mobile Private',
    }),
  },
];

export const getEmailsLabels = (intl: IntlShape): LabeledValue[] => [
  {
    label: intl.formatMessage({
      id: 'detailcontactform.generalinformation.email.option.business',
      defaultMessage: 'Business',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.generalinformation.email.option.business',
      defaultMessage: 'Business',
    }),
  },
  {
    label: intl.formatMessage({
      id: 'detailcontactform.generalinformation.email.option.private',
      defaultMessage: 'Private',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.generalinformation.email.option.private',
      defaultMessage: 'Private',
    }),
  },
];

export const getAddressLabels = (
  intl: IntlShape,
): SelectProps<string>['options'] => [
  {
    label: intl.formatMessage({
      id: 'detailcontactform.address.option.business',
      defaultMessage: 'Business',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.address.option.business',
      defaultMessage: 'Business',
    }),
  },
  {
    label: intl.formatMessage({
      id: 'detailcontactform.address.option.private',
      defaultMessage: 'Private',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.address.option.private',
      defaultMessage: 'Private',
    }),
  },
];

export const getBankAccountsLabels = (
  intl: IntlShape,
): SelectProps<string>['options'] => [
  {
    label: intl.formatMessage({
      id: 'detailcontactform.bankaccount.label.option.business',
      defaultMessage: 'Business',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.bankaccount.label.option.business',
      defaultMessage: 'Business',
    }),
  },
  {
    label: intl.formatMessage({
      id: 'detailcontactform.bankaccount.label.option.private',
      defaultMessage: 'Private',
    }),
    value: intl.formatMessage({
      id: 'detailcontactform.bankaccount.label.option.private',
      defaultMessage: 'Private',
    }),
  },
];

export const getDocumentTypes = (): { label: string; value: string }[] => {
  const types: DocumentType[] = [
    'businessRegistration',
    'catalogue',
    'certificate',
    'commonDocumentation',
    'courtFiles',
    'creditNote',
    'crExtract',
    'deliveryNote',
    'dunningLetter',
    'employeeAbsenceNote',
    'employmentContract',
    'financialContract',
    'invoice',
    'leaseContract',
    'letter',
    'nda',
    'notaryDeed',
    'order',
    'orderConfirmation',
    'picture',
    'quotation',
    'rentalAgreement',
    'report',
    'requestForQuotation',
    'shareholderResolution',
    'technicalDrawing',
    'termsAndConditions',
    'newType',
  ];

  return types.map((value) => ({
    value,
    label: `documents.documentTypes.${value}`,
  }));
};

export type PlatformTaxRate = {
  value: string;
  [locale: string]: string;
};

export const getPlatformTaxRates = () => {
  return getCollection('taxRates') as Promise<PlatformTaxRate[]>;
};
