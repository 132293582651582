import React, { FC } from 'react';
import { TreeSelect, TreeSelectProps } from 'antd';
import { SKRTreeEntry } from '@apps/finance/services/skr/namespace';

type SKRTreeSelectProps = TreeSelectProps & {
  treeData: SKRTreeEntry[];
};
const SKRTreeSelect: FC<SKRTreeSelectProps> = (props) => {
  return <TreeSelect {...props} />;
};

export default SKRTreeSelect;
