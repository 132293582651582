import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { Consultant } from '@apps/directSales/mainMenu/Consultants/services';
import SpinContainer from '@components/SpinContainer';
import { Button, message, Modal, Space, Spin, Switch } from 'antd';
import MobileClientConfig from '@apps/directSalesClient';
import getLicencesForMembers from '@services/licences/getLicencesForMembers';
import BlockedTag from '@pages/settings/team/BlockedTag';
import ActiveTag from '@pages/settings/team/ActiveTag';
import InActiveTag from '@pages/settings/team/InActiveTag';
import { FormattedMessage } from 'react-intl/lib';
import { useHistory } from 'react-router-dom';
import stripe from '@services/stripe';
import getLicencePoolConfig from '@pages/settings/team/getLicencePoolConfig';
import { useIntl } from 'react-intl';
import updateLicence from '@services/licences/updateLicence';
import setSeatsForApp from '@services/licences/setSeatsForApp';
import { SeatsPoolContext } from '@pages/settings/team/MembersList';
import { useCustomEventListener } from '@core/services';
import createLicenceForMember from '@services/licences/createLicenceForMember';
import { useSelector } from 'react-redux';
import { getStripeProducts } from '@core/store/stripeProducts/selectors';
import calculateSeatsForEcosystem from '@services/licences/calculateSeatsForEcosystem';

type Props = {
  consultant?: Partial<Consultant>;
};

const MobileLicenseManager: FC<Props> = ({ consultant }) => {
  const history = useHistory();
  const intl = useIntl();
  const [modal, contextHolder] = Modal.useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [seats, setSeats] = useState<Seat[]>([]);
  const [licences, setLicences] = useState<Licence[]>([]);
  const [selectedSub, setSelectedSub] = useState<Subscription | null>(null);
  const [selectedMember, setSelectedMember] =
    useState<MemberWithLicences | null>(null);
  const stripeApps = useSelector(getStripeProducts);
  const stripeProduct =
    stripeApps.find((app) => app.todixId === MobileClientConfig.todixId) ||
    null;

  useEffect(() => {
    if (consultant && consultant.ecosystem && consultant.id && stripeProduct) {
      setIsLoading(true);
      const seatsPromise = calculateSeatsForEcosystem({
        ecosystemId: consultant.ecosystem,
        userId: consultant.id,
      })?.then((results) => {
        setSeats(results.filter((seat) => seat.appId === stripeProduct.id));
      });
      const licencesPromise = getLicencesForMembers({
        ecosystemId: consultant.ecosystem,
        membersIds: [consultant.id as string],
      }).then((results) => {
        if (results && results[0]) {
          const filteredLicences = results[0]?.filter(
            (res) => res.appId === stripeProduct.id,
          );
          setSelectedMember({
            id: consultant.id as string,
            role: 'consultant',
            isBlocked: !consultant.isActive,
            email: consultant.consultantEmail as string,
            isActive: consultant.isActive || false,
            photoURL: consultant.photoURL as string,
            displayName: `${consultant.consultantName} ${consultant.consultantSurname}`,
            licences: filteredLicences.filter(
              (lic) => lic.ownerId === consultant.id,
            ),
          });
          setLicences(filteredLicences);
        }
      });
      Promise.all([seatsPromise, licencesPromise]).finally(() => {
        setIsLoading(false);
      });
    }
  }, [stripeProduct, consultant]);

  const redirectToStore = useCallback(
    (appId: string) => {
      if (consultant && consultant.ecosystem) {
        history.push(
          `/settings/${consultant.ecosystem}/applications/store/details/${appId}`,
        );
      }
    },
    [consultant, history],
  );

  const handleLicenceChange = useCallback(
    (licence: Licence, status: boolean) => {
      const seat = seats.find(
        (seat) =>
          seat.appId === licence.appId && seat.subId === licence.subscription,
      );
      const freeSeats = seat?.seats || 0;

      if (status && !freeSeats) {
        message.error(
          intl.formatMessage({
            id: 'no.more.licence',
            defaultMessage:
              'You have no more free licence. Please, deactivate one or buy more',
          }),
        );
        return;
      }
      setIsLoading(true);
      const newLicence: Licence = {
        ...licence,
        status: status ? 'active' : 'inactive',
      };
      updateLicence({
        licence: newLicence,
        id: licence.id as string,
      })
        ?.then(() => {
          const newSeats = status ? freeSeats - 1 : freeSeats + 1;
          setSeatsForApp({
            appId: newLicence.appId,
            ecosystemId: newLicence.ecosystem,
            seats: newSeats,
            subId: newLicence.subscription,
          });
          if (!seat) {
            setSeats([
              ...seats,
              {
                appId: newLicence.appId,
                subId: newLicence.subscription,
                ecosystem: newLicence.ecosystem,
                seats: newSeats,
              },
            ]);
          } else {
            setSeats(
              seats.map((seat) => {
                if (
                  seat.appId === newLicence.appId &&
                  seat.subId === newLicence.subscription
                ) {
                  return {
                    ...seat,
                    seats: newSeats,
                  };
                }
                return seat;
              }),
            );
          }
          setLicences(
            licences.map((lic) => (lic.id === licence.id ? newLicence : lic)),
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [intl, licences, seats],
  );

  const handleAddLicenceSelect = useCallback(
    async ({ appId, seatsForApp }) => {
      setIsLoading(true);
      const product = await stripe.getProduct(appId);
      setIsLoading(false);
      if (product) {
        const config = getLicencePoolConfig({
          intl,
          product,
          seatsForApp,
        });
        modal.confirm(config);
      }
    },
    [intl, modal],
  );

  useCustomEventListener('saveLicence', () => {
    if (
      selectedSub &&
      stripeProduct &&
      selectedMember?.id &&
      consultant?.ecosystem
    ) {
      const seat = seats.find(
        (seat) =>
          seat.appId === stripeProduct.id && seat.subId === selectedSub.id,
      );

      if (seat) {
        setIsLoading(true);
        createLicenceForMember({
          memberId: selectedMember.id,
          product: stripeProduct,
          ecosystemId: consultant?.ecosystem,
          subscription: selectedSub.id,
        })
          ?.then((newLicence) => {
            if (newLicence) {
              setSeatsForApp({
                appId: newLicence.appId,
                ecosystemId: newLicence.ecosystem,
                seats: seat.seats - 1,
                subId: newLicence.subscription,
              })?.then(() => {
                setSeats(
                  seats.map((s) => {
                    if (
                      s.subId === selectedSub.id &&
                      s.appId === stripeProduct.id
                    ) {
                      return {
                        ...s,
                        seats: seat.seats - 1,
                      };
                    } else {
                      return s;
                    }
                  }),
                );
                setSelectedSub(null);
                setSelectedMember(null);
                setLicences([...licences, newLicence]);
              });
            }
          })
          ?.finally(() => {
            setIsLoading(false);
          });
      }
    }
  });

  const licenseComponent = useMemo(() => {
    const appId = stripeProduct?.id || '';
    let freeSeats = 0;
    const seatsForApp = seats.filter((seat) => seat.appId === appId);
    if (seatsForApp.length) {
      freeSeats = seatsForApp.reduce((acc, item) => acc + item.seats, 0);
    }
    const userLicence = licences.find((lic) => lic.appId === appId);
    if (userLicence) {
      if (userLicence.blocked) {
        return <BlockedTag />;
      }
      if (userLicence.status === 'active') {
        return (
          <Space size="small" direction="horizontal">
            <ActiveTag />
            <Switch
              disabled={isLoading}
              checked={true}
              onClick={() => handleLicenceChange(userLicence, false)}
            />
          </Space>
        );
      }
      if (userLicence.status === 'inactive') {
        return (
          <Space size="small" direction="horizontal">
            <InActiveTag />
            <Switch
              disabled={isLoading}
              checked={false}
              onClick={() => {
                handleLicenceChange(userLicence, true);
              }}
            />
          </Space>
        );
      }
    }

    if (freeSeats) {
      return (
        <Button
          onClick={async () => {
            await handleAddLicenceSelect({
              appId,
              seatsForApp,
            });
          }}
        >
          <FormattedMessage id="add.licence" defaultMessage="Add licence" />
        </Button>
      );
    } else {
      return (
        <Button onClick={() => redirectToStore(appId)}>
          <FormattedMessage id="buy.licence" defaultMessage="Buy licence" />
        </Button>
      );
    }
  }, [
    handleAddLicenceSelect,
    handleLicenceChange,
    isLoading,
    licences,
    redirectToStore,
    seats,
    stripeProduct?.id,
  ]);

  return (
    <SeatsPoolContext.Provider
      value={{
        seats,
        member: selectedMember,
        appId: stripeProduct?.id || '',
        selectedSub,
        setSelectedSub,
      }}
    >
      <Spin spinning={isLoading} indicator={<SpinContainer />}>
        {licenseComponent}
        {contextHolder}
      </Spin>
    </SeatsPoolContext.Provider>
  );
};

export default MobileLicenseManager;
