import React, { FC, useCallback, useMemo, useRef } from 'react';
import isHotkey from 'is-hotkey';
import { useIntl } from 'react-intl';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';

import { SaveButton, Toolbar } from '@components/RichTextEditor/styles.sc';
import MarkButton from '@components/RichTextEditor/components/MarkButton';
import Leaf from '@components/RichTextEditor/components/Leaf';
import Element from '@components/RichTextEditor/components/Element';
import toggleMark from '@components/RichTextEditor/helpers/toggleMark';
import BlockButton from '@components/RichTextEditor/components/BlockButton';
import { HOTKEYS } from '@components/RichTextEditor/consts';
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  CodeOutlined,
  ItalicOutlined,
  UnderlineOutlined,
} from '@ant-design/icons';

type Props = {
  onChange?(changes: any): void;
  onSaveClick?(changes: string): void;
  content?: string;
};

const defaultValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const RichTextEditor: FC<Props> = ({ onChange, content, onSaveClick }) => {
  const intl = useIntl();
  const editorContent = useRef<any>(null);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const initialValue = useMemo(
    () => (content ? JSON.parse(content) || defaultValue : defaultValue),
    [content],
  );

  const handleOnChange = useCallback(
    (changes: any) => {
      editorContent.current = changes;
      if (onChange) {
        onChange(changes);
      }
    },
    [onChange],
  );

  const handleOnSaveClick = useCallback(() => {
    const changes = editorContent.current;
    if (onSaveClick && changes) {
      onSaveClick(JSON.stringify(changes));
    }
  }, [onSaveClick]);

  return (
    <Slate editor={editor} value={initialValue} onChange={handleOnChange}>
      <Toolbar>
        <MarkButton format="bold" icon={<BoldOutlined />} />
        <MarkButton format="italic" icon={<ItalicOutlined />} />
        <MarkButton format="underline" icon={<UnderlineOutlined />} />
        <MarkButton format="code" icon={<CodeOutlined />} />
        <BlockButton format="left" icon={<AlignLeftOutlined />} />
        <BlockButton format="center" icon={<AlignCenterOutlined />} />
        <BlockButton format="right" icon={<AlignRightOutlined />} />
        <SaveButton type="dashed" onClick={handleOnSaveClick}>
          {intl.formatMessage({
            id: 'button.save',
            defaultMessage: 'Save',
          })}
        </SaveButton>
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={`${intl.formatMessage({
          id: 'consultant.about',
          defaultMessage: 'About',
        })}...`}
        spellCheck
        autoFocus
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};
export default RichTextEditor;
