import React, { FC } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { useSelector } from 'react-redux';
import { getUnitGroupsForEcosystemSelector } from '@core/layout/store/unitGroupsSaga/selectors';
import { FormattedMessage } from 'react-intl';
import intl from '../../../../../../intl';

const { Option, OptGroup } = Select;

type UnitSelectorProps = SelectProps & {
  selectedEcosystem: string | null;
  onUnitSelect?(unit: string): void;
};

const UnitSelector: FC<UnitSelectorProps> = ({
  selectedEcosystem,
  onUnitSelect,
  ...rest
}) => {
  const unitGroups = useSelector(
    getUnitGroupsForEcosystemSelector(selectedEcosystem || ''),
  );

  return (
    <Select
      disabled={!selectedEcosystem}
      onSelect={(value) => {
        if (onUnitSelect) {
          onUnitSelect(value as string);
        }
      }}
      placeholder={
        <FormattedMessage
          id="products.selectedunit.placeholder"
          defaultMessage="Select unit..."
        />
      }
      {...rest}
    >
      {unitGroups.groups.map((unitGroup, index) => (
        <OptGroup
          key={index}
          label={intl.formatMessage({
            id: `products.unitGroup.${unitGroup.name}`,
            defaultMessage: unitGroup.name,
          })}
        >
          {unitGroup.units
            .filter((unit) => unit.isActive)
            .map(({ name }) => (
              <Option
                value={name}
                key={name}
                label={intl.formatMessage({
                  id: `products.unit.${name}`,
                  defaultMessage: name,
                })}
              >
                {intl.formatMessage({
                  id: `products.unit.${name}`,
                  defaultMessage: name,
                })}
              </Option>
            ))}
        </OptGroup>
      ))}
    </Select>
  );
};

export default UnitSelector;
