import React, { FC, useEffect, useState } from 'react';
import { Card, Empty, Typography, Tabs, Table } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumberOutlined, BarcodeOutlined } from '@ant-design/icons';
import getSerialNumbers from '@apps/warehouse/services/getSerialNumbers';
import getBatchNumbers from '@apps/warehouse/services/getBatchNumbers';
import {
  BatchNumberEntry,
  SerialNumberEntry,
} from '@apps/warehouse/services/namespace';
import moment from 'moment';

const { Title } = Typography;
const { TabPane } = Tabs;

interface BatchSerialNumberContentProps {
  productId?: string;
  readOnly?: boolean;
  hasBatchNumber?: boolean;
  hasSerialNumber?: boolean;
  ecosystem?: string;
}

const BatchSerialNumberContent: FC<BatchSerialNumberContentProps> = ({
  productId,
  hasBatchNumber,
  hasSerialNumber,
  ecosystem,
}) => {
  const intl = useIntl();
  const [serialNumberData, setSerialNumberData] = useState<SerialNumberEntry[]>(
    [],
  );
  const [batchNumberData, setBatchNumberData] = useState<BatchNumberEntry[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [batchLoading, setBatchLoading] = useState<boolean>(false);

  useEffect(() => {
    if (productId && hasSerialNumber && ecosystem) {
      setLoading(true);
      getSerialNumbers({
        ecosystem,
        productId,
      })
        .then((entries) => {
          const sortedEntries = [...entries].sort((a, b) => {
            if (!a.timestamp && !b.timestamp) return 0;
            if (!a.timestamp) return 1;
            if (!b.timestamp) return -1;
            return b.timestamp - a.timestamp;
          });

          setSerialNumberData(sortedEntries);
        })
        .catch((error) => {
          console.error('Error fetching serial numbers:', error);
          setSerialNumberData([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [productId, hasSerialNumber, ecosystem]);

  useEffect(() => {
    if (productId && hasBatchNumber && ecosystem) {
      setBatchLoading(true);
      getBatchNumbers({
        ecosystem,
        productId,
      })
        .then((entries) => {
          const sortedEntries = [...entries].sort((a, b) => {
            if (!a.timestamp && !b.timestamp) return 0;
            if (!a.timestamp) return 1;
            if (!b.timestamp) return -1;
            return b.timestamp - a.timestamp;
          });

          setBatchNumberData(sortedEntries);
        })
        .catch((error) => {
          console.error('Error fetching batch numbers:', error);
          setBatchNumberData([]);
        })
        .finally(() => {
          setBatchLoading(false);
        });
    }
  }, [productId, hasBatchNumber, ecosystem]);

  if (!productId) {
    return (
      <Empty
        description={
          <FormattedMessage
            id="products.batchserialnumber.save.first"
            defaultMessage="Please save the product first to manage batch and serial numbers"
          />
        }
      />
    );
  }

  const serialNumberColumns = [
    {
      title: intl.formatMessage({
        id: 'products.form.serialNumber.label',
        defaultMessage: 'Serial Number',
      }),
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: intl.formatMessage({
        id: 'products.serialnumber.date',
        defaultMessage: 'Date Added',
      }),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: number) =>
        timestamp ? moment(timestamp).format('DD.MM.YYYY HH:mm') : '-',
    },
  ];

  const batchNumberColumns = [
    {
      title: intl.formatMessage({
        id: 'products.form.batchNumber.label',
        defaultMessage: 'Batch Number',
      }),
      dataIndex: 'batchNumber',
      key: 'batchNumber',
    },
    {
      title: intl.formatMessage({
        id: 'products.batchnumber.amount',
        defaultMessage: 'Amount (per batch)',
      }),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: intl.formatMessage({
        id: 'products.serialnumber.date',
        defaultMessage: 'Date Added',
      }),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: number) =>
        timestamp ? moment(timestamp).format('DD.MM.YYYY HH:mm') : '-',
    },
  ];

  return (
    <Card>
      <Title level={4}>
        <FormattedMessage
          id="products.batchserialnumber.title"
          defaultMessage="Batch and Serial Number Management"
        />
      </Title>

      <Tabs defaultActiveKey={hasSerialNumber ? 'serialNumber' : 'batchNumber'}>
        {hasSerialNumber && (
          <TabPane
            tab={
              <>
                <BarcodeOutlined />{' '}
                <FormattedMessage
                  id="products.serialnumber.title"
                  defaultMessage="Serial Numbers"
                />
              </>
            }
            key="serialNumber"
          >
            <p>
              <FormattedMessage
                id="products.serialnumber.description"
                defaultMessage="This product requires serial number tracking for each delivery and movement."
              />
            </p>
            <Table
              columns={serialNumberColumns}
              dataSource={serialNumberData}
              rowKey={(record) => `${record.ecosystem}_${record.serialNumber}`}
              pagination={{ pageSize: 10 }}
              loading={loading}
              locale={{
                emptyText: intl.formatMessage({
                  id: 'products.serialnumber.empty',
                  defaultMessage: 'No serial numbers available',
                }),
              }}
            />
          </TabPane>
        )}

        {hasBatchNumber && (
          <TabPane
            tab={
              <>
                <NumberOutlined />{' '}
                <FormattedMessage
                  id="products.batchnumber.title"
                  defaultMessage="Batch Numbers"
                />
              </>
            }
            key="batchNumber"
          >
            <p>
              <FormattedMessage
                id="products.batchnumber.description"
                defaultMessage="This product requires batch number tracking for each delivery and movement."
              />
            </p>
            <Table
              columns={batchNumberColumns}
              dataSource={batchNumberData}
              rowKey={(record) => `${record.ecosystem}_${record.batchNumber}`}
              pagination={{ pageSize: 10 }}
              loading={batchLoading}
              locale={{
                emptyText: intl.formatMessage({
                  id: 'products.batchnumber.empty',
                  defaultMessage: 'No batch numbers available',
                }),
              }}
            />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};

export default BatchSerialNumberContent;
