import { FilterTextObject } from '@services/filtering/index';
import get from 'lodash.get';

const textMatcher = (filterObject: FilterTextObject, row: any, key: string) => {
  const { type } = filterObject;
  const handleFilterType = (value: string) => {
    if (type === 'contains') {
      return (get(row, key) + '')?.toLowerCase()?.includes(value.toLowerCase());
    }
    if (type === 'notContains') {
      return !(get(row, key) + '')
        ?.toLowerCase()
        ?.includes(value.toLowerCase());
    }
    return false;
  };
  return filterObject?.filter
    ?.split('|')
    .some((valuePart: string) => handleFilterType(valuePart));
};

export default textMatcher;
