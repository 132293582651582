import {
  WAREHOUSE_HISTORY,
  WarehouseHistoryEntry,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';

type Payload = {
  ecosystem: string;
  storageId: string;
};

const fetchHistoryForStorage = ({ ecosystem, storageId }: Payload) => {
  return firebase.db
    ?.ref(`${WAREHOUSE_HISTORY}/${ecosystem}/${storageId}`)
    .once('value')
    .then((results) => {
      if (!results.exists()) {
        return Promise.resolve([] as WarehouseHistoryEntry[]);
      }

      let entries: WarehouseHistoryEntry[] = [];
      results.forEach((snap) => {
        const snapVal = snap.val();
        entries = [...entries, snapVal as WarehouseHistoryEntry];
      });

      return entries;
    });
};

export default fetchHistoryForStorage;
