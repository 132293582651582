import React, { FC, useContext, useEffect } from 'react';
import AppConfig from '@apps/directSales';
import {
  Consultant,
  updateConsultant,
} from '@apps/directSales/mainMenu/Consultants/services';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import FormContainer from '@components/FormContainer';
import { useForm } from 'antd/lib/form/Form';
import LeftColumn from '@apps/directSales/mainMenu/Consultants/components/LeftColumn';
import RightColumn from '@apps/directSales/mainMenu/Consultants/components/RightColumn';
import { removeUndefinedProperties } from '@services/utils';
import { useHistory } from 'react-router-dom';
import ConsultantContext from '@apps/directSales/mainMenu/Consultants/components/formContext';
import moment from 'moment';
import { useDefaultPhotoUrls } from '@pages/sign/useDefaultPhotoUrls';
import createConsultant from '@apps/directSales/mainMenu/Consultants/services/createConsultant';
import sendPasswordResetEmail from '@apps/directSales/mainMenu/Consultants/services/sendPasswordResetEmail';

type Props = {
  id?: string;
  onEcosystemChange(ecosystem: string): void;
  readOnly: boolean;
  consultant: Consultant | null;
};
const ConsultantForm: FC<Props> = ({ id, consultant, readOnly }) => {
  const defaultPhotoUrls = useDefaultPhotoUrls();
  const [form] = useForm();
  const history = useHistory();
  const { setLoading } = useContext(ConsultantContext);

  useEffect(() => {
    if (consultant !== null) {
      form.setFieldsValue({
        ...consultant,
        consultantBirthday: consultant.consultantBirthday
          ? moment(consultant.consultantBirthday)
          : undefined,
        applicationDate: consultant.applicationDate
          ? moment(consultant.applicationDate)
          : undefined,
      });
    }
  }, [consultant, form, setLoading]);
  return (
    <AppEntityForm
      readOnly={readOnly}
      appId={AppConfig.todixId}
      editLink={`/app/${AppConfig.todixId}/consultants/${id}/edit`}
      backLink={`/app/${AppConfig.todixId}/consultants`}
      cancelLink={`/app/${AppConfig.todixId}/consultants`}
      elId={id}
      autoComplete="off"
      name="consultant"
      initialValues={consultant === null ? {} : consultant}
      providedForm={form}
      onSubmit={(formValues) => {
        if (defaultPhotoUrls) {
          setLoading(true);
          const photoURL =
            defaultPhotoUrls[
              Math.floor(Math.random() * defaultPhotoUrls.length)
            ];
          const filteredValues = {
            ...formValues,
            ...removeUndefinedProperties(formValues),
          };
          if (id) {
            updateConsultant(id, filteredValues).then(() => {
              history.push(`/app/${AppConfig.todixId}/consultants`);
            });
          } else {
            createConsultant({ ...filteredValues, photoURL })?.then(() => {
              sendPasswordResetEmail(
                (filteredValues as Partial<Consultant>)
                  .consultantEmail as string,
              ).then(() => {
                history.push(`/app/${AppConfig.todixId}/consultants`);
              });
            });
          }
        }
      }}
    >
      {() => {
        return (
          <FormContainer>
            <LeftColumn />
            <RightColumn />
          </FormContainer>
        );
      }}
    </AppEntityForm>
  );
};

export default ConsultantForm;
