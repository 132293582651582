import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const { Item } = Form;

const NeedDate: FC = () => {
  return (
    <Item
      name="needDate"
      label={<FormattedMessage id="need.date" defaultMessage="Need date" />}
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="need.date.missing"
              defaultMessage="Need date is missing"
            />
          ),
        },
      ]}
    >
      <DatePicker format={moment.defaultFormat} />
    </Item>
  );
};

export default NeedDate;
