import React, { FC } from 'react';
import { Collapse, Form } from 'antd';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import ExistingContact from '@apps/documents/mainMenu/DocumentProcessing/Step2/ExistingContact';
// import { FormattedMessage } from 'react-intl/lib';

const { Panel } = Collapse;

type Props = Partial<{
  id: string;
  value: any;
}>;

const Content: FC<Props> = ({ id = 'relatedContact', value }) => {
  if (!value) {
    return null;
  }
  const header =
    value.type === 'person'
      ? `${value.firstName || ''} ${value.lastName || ''}`
      : value.companyName;
  return (
    <Collapse>
      <Panel key={id} header={header}>
        <ExistingContact details={value} />
      </Panel>
    </Collapse>
  );
};

const RelatedContact: FC<FieldProps> = ({ field }) => {
  const { fieldName } = field;
  return (
    <Form.Item name={fieldName} wrapperCol={{ span: 20 }}>
      <Content />
    </Form.Item>
  );
};

export default RelatedContact;
