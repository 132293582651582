import {
  EcosystemActions,
  EcosystemActionsTypes,
  SetEcosystemAvailability,
  SetEcosystems,
  UpdateEcosystem,
  RemoveEcosystem,
} from '@components/EcosystemIndicator/store/actions';

export type EcosystemState = Ecosystem[];

const initial: EcosystemState = [];

export const ecosystemReducer = (state = initial, action: EcosystemActions) => {
  switch (action.type) {
    case EcosystemActionsTypes.SET_ECOSYSTEMS: {
      return [...(action as SetEcosystems).payload];
    }
    case EcosystemActionsTypes.REMOVE_ECOSYSTEM: {
      const ecosystemId = (action as RemoveEcosystem).payload;
      return state.filter((eco) => eco.id !== ecosystemId);
    }
    case EcosystemActionsTypes.UPDATE_ECOSYSTEM: {
      const ecosystem = action.payload as Ecosystem;
      const ids = state.map((eco) => eco.id);
      if (ids.find((id) => id === (ecosystem.id as string))) {
        const ecosystems = state.map((eco) => {
          if (eco.id === (action as UpdateEcosystem).payload.id) {
            return action.payload;
          }
          return eco;
        });
        return [...ecosystems];
      } else {
        return [...state, ecosystem];
      }
    }
    case EcosystemActionsTypes.SET_ECOSYSTEM_AVAILABILITY: {
      const { ecosystemId, isActive } = (action as SetEcosystemAvailability)
        .payload;
      return state.map((eco) => ({
        ...eco,
        isActive: eco.id === ecosystemId ? isActive : eco.isActive,
      }));
    }
    default: {
      return state;
    }
  }
};
