import React, { FC, useState, useEffect, useMemo, useContext } from 'react';
import getSubscription from '@services/subscriptions/getSubscription';
import { ColumnType } from 'antd/lib/table';
import StyledTable from '@pages/settings/components/StyledTable';
import SubscriptionStatus from '@pages/settings/team/SubscriptionStatus';
import { FormattedMessage } from 'react-intl/lib';
import moment from 'moment';
import { Checkbox } from 'antd';
import { SeatsPoolContext } from '@pages/settings/team/MembersList';

type Props = {
  seatsForApp: Seat[];
};

const SubscriptionsTable: FC<Props> = ({ seatsForApp }) => {
  const { selectedSub, setSelectedSub, appId } = useContext(SeatsPoolContext);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<
    (Subscription | undefined | null)[]
  >([]);

  useEffect(() => {
    const promises = [
      ...new Set([...seatsForApp.map((seat) => seat.subId)]),
    ].map((subId) => getSubscription(subId));
    setLoading(true);
    Promise.all(promises)
      .then((res) => {
        if (res && res.length) {
          setSubscriptions(res);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [seatsForApp]);

  const columns: ColumnType<any>[] = useMemo(() => {
    return [
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'status',
        render: (status) => <SubscriptionStatus status={status} />,
      },
      {
        title: (
          <FormattedMessage
            id="current.period.start"
            defaultMessage="Current period start"
          />
        ),
        dataIndex: 'current_period_start',
        render: (value) => moment.unix(value).format(),
      },
      {
        title: (
          <FormattedMessage
            id="current.period.end"
            defaultMessage="Current period end"
          />
        ),
        dataIndex: 'current_period_end',
        render: (value) => moment.unix(value).format(moment.defaultFormat),
      },
      {
        title: <FormattedMessage id="seats" defaultMessage="Seats" />,
        dataIndex: 'items',
        render: (value, sub) => {
          const freeSeats =
            seatsForApp.find((seat) => seat.subId === sub.id)?.seats || 0;
          const totalSeats = Object.values(value)
            .filter((entry: any) => entry.appId === appId)
            .reduce((acc, item) => acc + (item as any).quantity, 0);
          return `${freeSeats}/${totalSeats}`;
        },
      },
      {
        key: 'actions',
        render: (_value, sub) => {
          const freeSeats =
            seatsForApp.find((seat) => seat.subId === sub.id)?.seats || 0;
          if (freeSeats) {
            return (
              <Checkbox
                onChange={() => setSelectedSub && setSelectedSub(sub)}
                checked={selectedSub?.id === sub.id}
              />
            );
          } else {
            return null;
          }
        },
      },
    ];
  }, [appId, seatsForApp, selectedSub?.id, setSelectedSub]);

  return (
    <StyledTable
      dataSource={subscriptions as Subscription[]}
      columns={columns}
      bordered
      loading={loading}
      pagination={false}
      rowKey="current_period_start"
      scroll={{ x: 600 }}
    />
  );
};

export default SubscriptionsTable;
