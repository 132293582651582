import { styled } from '@styles/themes';

export const NameWithActions = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  grid-column-gap: 10px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
`;
