import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import {
  StyledEditIcon,
  StyledSwitch,
  StyledTable,
} from '@pages/settings/components';
import { FormattedMessage } from 'react-intl';
import { Button, Card, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import { UseParamsType } from '@pages/settings';
import {
  DeliveryTermsType,
  getDeliveryTerms,
  updateDeliveryTerms,
} from '@apps/purchases/services/deliveryTerms';
import DeliveryTermsModal from '@apps/purchases/settings/deliveryTerms/DeliveryTermsModal';
import { PlusSquareOutlined } from '@ant-design/icons';

const DeliveryTerms: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deliveryTerms, setDeliveryTerms] = useState<DeliveryTermsType[]>([]);
  const [selectedDeliveryTerms, setSelectedDeliveryTerms] =
    useState<DeliveryTermsType | null>(null);

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getDeliveryTerms(uid)
        ?.then(setDeliveryTerms)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  const handleTermsChange = useCallback(
    async (terms: DeliveryTermsType) => {
      const { id } = terms;
      if (id) {
        setLoading(true);
        await updateDeliveryTerms(id, terms);
        setDeliveryTerms(
          deliveryTerms.map((item) => {
            if (item.id === id) {
              return terms;
            }
            return item;
          }),
        );
        setLoading(false);
      }
    },
    [deliveryTerms],
  );

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="sales.createNew.shortage.label"
            defaultMessage="Shortage"
          />
        ),
        sorter: true,
        dataIndex: 'shortage',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="sales.createNew.longName.label"
            defaultMessage="Long name"
          />
        ),
        sorter: true,
        dataIndex: 'longName',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="sales.createNew.category.label"
            defaultMessage="Category"
          />
        ),
        sorter: true,
        dataIndex: 'category',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="sales.createNew.description.label"
            defaultMessage="Description"
          />
        ),
        sorter: true,
        dataIndex: 'description',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: DeliveryTermsType) => (
          <Tooltip
            title={
              <FormattedMessage
                id="app.sales.settings.isActive.tooltip"
                defaultMessage="app.sales.settings.isActive.tooltip"
              />
            }
          >
            <StyledSwitch
              key={record.id}
              checked={record.isActive}
              onChange={() =>
                handleTermsChange({
                  ...record,
                  isActive: !record.isActive,
                } as DeliveryTermsType)
              }
              checkedChildren={activated}
              unCheckedChildren={deactivated}
            />
          </Tooltip>
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: DeliveryTermsType) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedDeliveryTerms(record);
                setVisible(true);
              }}
            />
          </>
        ),
      },
    ],
    [activated, deactivated, handleTermsChange],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const onOk = useCallback(() => {
    setVisible(false);
    if (uid) {
      setLoading(true);
      getDeliveryTerms(uid)
        ?.then(setDeliveryTerms)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  return (
    <Card
      title={
        <FormattedMessage
          id="sales.settings.deliveryterms.header"
          defaultMessage="Delivery terms"
        />
      }
      extra={
        <Button
          type={'primary'}
          icon={<PlusSquareOutlined />}
          onClick={() => setVisible(true)}
        />
      }
    >
      <StyledTable
        dataSource={deliveryTerms}
        columns={columns}
        bordered
        tableLayout="auto"
        loading={loading}
        pagination={false}
        rowKey="id"
      />
      <DeliveryTermsModal
        terms={selectedDeliveryTerms}
        visible={visible}
        ecosystemId={uid}
        onCancel={onCancel}
        onOk={onOk}
      />
    </Card>
  );
};

export default DeliveryTerms;
