import React, { FC, useEffect, useState, useCallback } from 'react';
import { Spin, Button } from 'antd';
import { useIntl } from 'react-intl';
import {
  addSaleOperation,
  getSaleOperationSettings,
  updateSaleOperationSettings,
} from '@apps/sales/services/saleOperation/saleOperationSettings';
import { SaleOperationSettings } from '@apps/sales/services/saleOperation/namespace';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import AppConfig from '@apps/sales';
import { useForm } from 'antd/lib/form/Form';
import {
  ButtonsContainer,
  Flex,
  StyledSectionTitle,
} from '@apps/sales/settings/OperationSteps/style.sc';
import FooterContent from '@apps/sales/settings/OperationSteps/FooterContent';

type StepSettingsProps = {
  step: string;
  ecosystemId: string;
  ecosystem: Ecosystem;
};

const StepSettings: FC<StepSettingsProps> = ({ step, ecosystemId }) => {
  const intl = useIntl();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({
    footer: {
      content: ['', '', '', ''],
    },
  } as Omit<SaleOperationSettings, 'ecosystem' | 'step'>);
  useEffect(() => {
    setIsLoading(true);
    getSaleOperationSettings(step, ecosystemId)
      .then((results) => {
        if (results.length) {
          setSettings(results[0]);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ...results[0],
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [ecosystemId, form, step]);
  const handleSubmit = useCallback(
    async (values: SaleOperationSettings) => {
      setIsLoading(true);
      values.footer.content = Object.values(values.footer.content);
      if (settings.id) {
        await updateSaleOperationSettings(settings.id, values);
      } else {
        await addSaleOperation({
          ...values,
          step,
          ecosystem: ecosystemId,
        });
      }
      setIsLoading(false);
    },
    [settings, ecosystemId, step],
  );
  return (
    <Spin spinning={isLoading}>
      <div>
        <AppEntityForm
          appId={AppConfig.todixId}
          initialValues={settings}
          onSubmit={handleSubmit}
          style={{ height: `calc(100vh - 240px)` }}
          providedForm={form}
          readOnly={false}
          hideButtons={true}
        >
          {() => {
            return (
              <Flex>
                <StyledSectionTitle level={5}>
                  {intl.formatMessage({
                    id: 'sales.operations.steps.settings.footer',
                    defaultMessage: 'Footer',
                  })}
                </StyledSectionTitle>
                <FooterContent />
                <ButtonsContainer>
                  <Button type="primary" htmlType="submit">
                    {intl.formatMessage({
                      id: 'button.save',
                      defaultMessage: 'Save',
                    })}
                  </Button>
                </ButtonsContainer>
              </Flex>
            );
          }}
        </AppEntityForm>
      </div>
    </Spin>
  );
};

export default StepSettings;
