import { styled } from '@styles/themes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 12px;

  & > * {
    border-radius: 8px;
  }
`;
