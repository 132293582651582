import React from 'react';
import moment from 'moment';

export type Props = {
  colDef: any;
  data: any;
  value: any;
  [key: string]: any;
};

const TimestampRenderer = ({ value }: Props) => {
  if (!value) {
    return '';
  }

  if (value.seconds) {
    return (
      <span>{moment.unix(value.seconds).format(moment.defaultFormat)}</span>
    );
  } else {
    try {
      return <span>{moment(value).format(moment.defaultFormat)}</span>;
    } catch (e) {
      return <span>{value}</span>;
    }
  }
};

export default TimestampRenderer;
