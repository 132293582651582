import React, { FC, useState, useEffect, useContext } from 'react';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
  getActiveSalesOrderTypes,
  OrderType,
} from '@apps/sales/services/orderTypes';
import SalesTabContext from '@apps/sales/widgets/SalesTab/context';

const { Item } = Form;

type Props = {
  ecosystemId: string;
};

const OrderType: FC<Props> = ({ ecosystemId }) => {
  const intl = useIntl();
  const { readOnly } = useContext(SalesTabContext);
  const [loading, setLoading] = useState(false);
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);

  useEffect(() => {
    if (ecosystemId) {
      setOrderTypes([]);
      setLoading(true);
      getActiveSalesOrderTypes(ecosystemId)
        ?.then(setOrderTypes)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystemId]);

  return (
    <Item name="orderType">
      <Select
        showSearch
        disabled={readOnly}
        optionFilterProp="label"
        placeholder={intl.formatMessage({
          id: `sales.salesTab.priceConstraints.validOrderType.placeholder`,
          defaultMessage: 'Valid order type',
        })}
        filterOption={(input, option) => {
          if (option && typeof option.label === 'string') {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return true;
        }}
        loading={loading}
        options={orderTypes.map((type) => {
          return {
            label: type.name,
            value: type.id,
          };
        })}
      />
    </Item>
  );
};

export default () => {
  return (
    <Item shouldUpdate={true} noStyle>
      {({ getFieldValue }) => {
        const ecosystemId = getFieldValue('ecosystem');
        const type = getFieldValue('type');

        return type === 'order' ? (
          <OrderType ecosystemId={ecosystemId} />
        ) : null;
      }}
    </Item>
  );
};
