import moment from 'moment';
import {
  Project,
  PROJECTS_COLLECTION_NAME,
} from '@apps/projects/services/namespace';
import { post } from '@services/api';
import { emitCustomEvent } from '@core/services';

const createProject = (project: Project) =>
  post(`${PROJECTS_COLLECTION_NAME}`, {
    ...project,
    timestamp: moment().valueOf(),
  }).then((id) => {
    emitCustomEvent('projectSubmitted', {
      id,
    });
  });

export default createProject;
