import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo,
} from 'react';
import { Slider, Select, Form } from 'antd';
import { styled } from '@styles/themes';
import { FilterProps } from '@components/List/CustomsFilters/useInputFilter';
import { FormattedMessage, useIntl } from 'react-intl';
import { FilterType } from '@services/filtering';
import { FilterWrapper } from '@components/List/CustomsFilters/styles';

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const supportedFilterType: FilterType[] = [
  'contains',
  'lessThan',
  'greaterThan',
  'inRange',
];

export default forwardRef((props: FilterProps, ref) => {
  const [input, setInput] = useState<string | null>(null);
  const [type, setType] = useState<FilterType>('contains');
  const intl = useIntl();

  const filterTypeOptions = useMemo(() => {
    return supportedFilterType.map((filterType) => ({
      label: intl.formatMessage({
        id: `filter.condition.${filterType}`,
        defaultMessage: filterType,
      }),
      value: filterType as string,
    }));
  }, [intl]);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return input !== null;
      },

      getModel() {
        return input !== null
          ? {
              filter: input,
              filterType: 'progress',
              type: type,
            }
          : null;
      },

      getModelAsString() {
        return '';
      },

      setModel(model: any) {
        setInput(model?.input || '');
        setType(model?.type || 'contains');
      },
    };
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [input, props]);

  const onValueChange = (value: any) => {
    setInput(String(value));
    props.filterChangedCallback({
      input,
      type,
    });
  };

  return (
    <FilterWrapper id="progressFilter">
      <Form>
        <FormItem
          label={intl.formatMessage({
            id: 'tasks.columns.progress.header',
            defaultMessage: 'Progress',
          })}
        >
          <Slider range={type === 'inRange'} onChange={onValueChange} />
        </FormItem>
        <FormItem>
          <Select
            dropdownClassName="ag-custom-component-popup"
            placeholder={
              <FormattedMessage
                id="progress.filter.select.placeholder"
                defaultMessage="Select filter mode"
              />
            }
            options={filterTypeOptions}
            onChange={(option: any) => setType(option as FilterType)}
          />
        </FormItem>
      </Form>
    </FilterWrapper>
  );
});
