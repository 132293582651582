import React, { FC, CSSProperties, useEffect, useState } from 'react';
import { Space, Spin } from 'antd';
import purchaseStepIconMapper from '../mainMenu/purchaseStepIconMapper';
import purchaseStatusColors, {
  PurchaseOperationStatus,
} from '../mainMenu/purchaseStatusColors';
import {
  getPositionMonitor,
  PositionMonitor,
} from '@apps/purchases/services/positionMonitor';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import PurchaseStep from './PurchaseStep';

interface Props {
  value?: string;
  data?: Partial<IndividualPurchaseRequest>;
}

const PurchaseStepsStatusRenderer: FC<Props> = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [monitor, setMonitor] = useState<PositionMonitor | null>(null);

  useEffect(() => {
    if (data?.id) {
      setLoading(true);
      getPositionMonitor(data.id)
        ?.then((fetchedMonitor) => {
          setMonitor(fetchedMonitor);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [data?.id]);

  if (!data || !monitor) return null;

  const steps: string[] = [
    'ordered',
    'invoiced',
    'tracked',
    'paid',
    'delivered',
  ];

  return (
    <Spin spinning={loading}>
      <Space size="small">
        {steps.map((step, index) => {
          const StepIcon: FC<{ style: CSSProperties }> =
            purchaseStepIconMapper[step];
          let stepStatus: PurchaseOperationStatus = 'empty';
          let stepType = step;
          let color;
          if (monitor) {
            if (monitor[step]) {
              const { status } = monitor[step];
              stepStatus = status as any;
            } else {
              if (step === 'delivered') {
                if (monitor['partlyDelivered']) {
                  stepStatus = 'draft';
                  stepType = 'partlyDelivered';
                }
                if (monitor['overDelivered']) {
                  stepStatus = 'overDelivered';
                  stepType = 'overDelivered';
                }
              }
              if (step === 'paid') {
                if (monitor['partlyPaid']) {
                  stepStatus = 'draft';
                  stepType = 'partlyPaid';
                }
                if (monitor['paid']) {
                  stepStatus = 'done';
                  stepType = 'paid';
                }
              }
            }
          }

          color =
            purchaseStatusColors[stepStatus] || purchaseStatusColors['empty'];

          return (
            <PurchaseStep
              key={index}
              stepStatus={stepStatus}
              stepType={stepType}
              color={color}
              StepIcon={StepIcon}
              index={index}
              monitor={monitor}
            />
          );
        })}
      </Space>
    </Spin>
  );
};

export default PurchaseStepsStatusRenderer;
