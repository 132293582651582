import React from 'react';
import { ModalFuncProps } from 'antd/lib/modal/Modal';
import { FormattedMessage, IntlShape } from 'react-intl';
import { SeatsPoolContext } from './MembersList';
import SubscriptionsTable from '@pages/settings/team/SubscriptionsTable';
import { StripeProduct } from '@services/stripe/namespace';
import { emitCustomEvent } from '@core/services';

type Props = {
  intl: IntlShape;
  product: StripeProduct | null;
  seatsForApp: Seat[];
};

const getLicencePoolConfig = ({ intl, product, seatsForApp }: Props) => {
  if (!product) {
    return {};
  }

  const Content = () => (
    <SeatsPoolContext.Consumer>
      {() =>
        seatsForApp ? <SubscriptionsTable seatsForApp={seatsForApp} /> : null
      }
    </SeatsPoolContext.Consumer>
  );
  return {
    icon: null,
    title: (
      <span>
        <FormattedMessage
          id={`app.${product.name}`}
          defaultMessage={`app.${product.name}`}
        />
        {': '}
        {intl.formatMessage({
          id: 'select.licence.from.pool',
          defaultMessage: 'Select licence from pool',
        })}
      </span>
    ),
    content: <Content />,
    width: '50vw',
    onOk: () => emitCustomEvent('saveLicence'),
    onCancel: () => emitCustomEvent('cancelSaveLicence'),
  } as ModalFuncProps;
};

export default getLicencePoolConfig;
