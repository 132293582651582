import React, { FC } from 'react';
import { WeekPlanEntry } from '@apps/weekplan/services/namespace';

type TreeNodeProps = {
  resource: Record<string, any>;
};

const TreeNode: FC<TreeNodeProps> = ({ resource }) => {
  const weekplan = resource as WeekPlanEntry;
  return <span>{weekplan?.name || ''}</span>;
};

export default TreeNode;
