import React, { FC, useState, useEffect, useMemo } from 'react';
import SpinContainer from '@components/SpinContainer';
import { Spin, Tag } from 'antd';
import { Consultant } from '@apps/directSales/mainMenu/Consultants/services';
import getCommissionMailsForConsultant from '@apps/directSales/services/commisions/getCommissionMailsForConsultant';
import { MailEntry, Status } from '@services/email/namespace';
import moment from 'moment';

const statusColor: Record<Status, string> = {
  ERROR: 'error',
  SUCCESS: 'success',
  PENDING: 'warning',
  PROCESSING: 'processing',
};

type Props = {
  data?: Partial<Consultant>;
  value?: string;
};
const ReportEmailStatusCellRenderer: FC<Props> = ({ value, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mailEntry, setMailEntry] = useState<MailEntry | null>(null);
  const status = useMemo(() => {
    if (mailEntry) {
      if (mailEntry.delivery) {
        const state = mailEntry.delivery.state;
        const date = mailEntry.delivery.endTime.toDate();
        return (
          <Tag color={statusColor[state]}>
            {state}: {moment(date).format(moment.defaultFormat)}
          </Tag>
        );
      }
      return <Tag color="default">Unknown</Tag>;
    }
    return <Tag color="default">Unknown</Tag>;
  }, [mailEntry]);

  useEffect(() => {
    if (value && data && data.ecosystem) {
      setIsLoading(true);
      getCommissionMailsForConsultant(data.ecosystem, value)
        ?.then((results) => {
          if (results && Array.isArray(results)) {
            setMailEntry(results[0]);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [value, data]);

  return (
    <Spin spinning={isLoading} indicator={<SpinContainer />}>
      <div>{status}</div>
    </Spin>
  );
};

export default ReportEmailStatusCellRenderer;
