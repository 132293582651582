import { IntlShape } from 'react-intl';
import { emitCustomEvent } from '@core/services';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import ContactsConfig, { CONTACTS_ACTION } from '@apps/contacts';

export type AppProductContactEvent = {
  productId: string;
  ecosystemId: string;
};

type ExtraOptions = {
  parentId?: string;
  parentType: string;
  ecosystemId?: string;
  intl: IntlShape;
};

const getExtraOptions = ({
  parentId,
  parentType,
  intl,
  ecosystemId,
}: ExtraOptions) => {
  const addPhotos = {
    children: intl.formatMessage({
      id: 'add.photos',
      defaultMessage: 'Add photos',
    }),
    danger: false,
    onClick: () => {
      emitCustomEvent('addProductPicture', {
        parentId,
        parentType,
        ecosystemId,
      });
    },
  };
  let options = [addPhotos];

  const canSeeContacts = ecosystemId
    ? checkUserPermissions(
        rootStore.getState(),
        ContactsConfig.todixId,
        'view-contacts' as CONTACTS_ACTION,
        ecosystemId,
      )
    : false;

  if (canSeeContacts && ecosystemId && parentId) {
    const addSupplier = {
      children: intl.formatMessage({
        id: 'add.supplier',
        defaultMessage: 'Add supplier',
      }),
      danger: false,
      onClick: () => {
        emitCustomEvent<'addSupplier', AppProductContactEvent>('addSupplier', {
          productId: parentId,
          ecosystemId,
        });
      },
    };
    const addCustomer = {
      children: intl.formatMessage({
        id: 'add.customer',
        defaultMessage: 'Add customer',
      }),
      danger: false,
      onClick: () => {
        emitCustomEvent<'addCustomer', AppProductContactEvent>('addCustomer', {
          productId: parentId,
          ecosystemId,
        });
      },
    };
    options = [...options, addSupplier, addCustomer];
  }
  return options;
};

export default getExtraOptions;
