import { firebase } from '@core/services/Firebase';
import { message } from 'antd';
import removeUndefined from '@core/helpers/removeUndefined';
import { formatMessage } from 'intl';

export const get = <T extends object = object>(
  endpoint: string,
): Promise<T> | Promise<T[]> | undefined => {
  if (endpoint.includes('/')) {
    const [collection, id] = endpoint.split('/');
    return firebase.firestore
      ?.collection(collection)
      .doc(id)
      .get()
      .then((doc) => doc.data() as T) as Promise<T>;
  }
  return getCollection(endpoint) as Promise<T[]>;
};

export const getCollection = (endpoint: string) =>
  (firebase.firestore as firebase.firestore.Firestore)
    .collection(endpoint)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs as object[];
    });

export const getWithId = (endpoint: string) => {
  if (endpoint.includes('/')) {
    const [, id] = endpoint.split('/');
    return get(endpoint)?.then((data) => {
      return data
        ? {
            id,
            ...data,
          }
        : null;
    });
  } else {
    return get(endpoint);
  }
};

export const post = (
  endpoint: string,
  data: object,
  optionsProp: ApiOptions = {},
) => {
  const options = {
    message: true,
    ...optionsProp,
  };
  return (firebase.firestore as firebase.firestore.Firestore)
    .collection(endpoint)
    .add(removeUndefined(data))
    .then((docRef) => {
      if (options.message) {
        message.success(formatMessage('api.post.success'));
      }
      return docRef.id;
    })
    .catch((e) => {
      message.error(formatMessage('api.post.fail'));
      console.error(e);
      return '';
    });
};

export type ApiOptions = Partial<{
  message: boolean;
}>;
export const put = (
  endpoint: string,
  data: object,
  optionsProp: ApiOptions = {},
) => {
  const [collection, id] = endpoint.split('/');
  const options = {
    message: false,
    ...optionsProp,
  };
  return (firebase.firestore as firebase.firestore.Firestore)
    .collection(collection)
    .doc(id)
    .set(data)
    .then((docRef) => {
      if (options.message) {
        message.success(formatMessage('api.post.success'));
      }
      return docRef;
    })
    .catch((e) => {
      message.error(formatMessage('api.post.fail'));
      console.error(e);
    });
};

// fixes part of a document, in HTML standard it should be put
export const patch = (
  endpoint: string,
  data: object,
  optionsProp?: ApiOptions,
) => {
  const options = {
    message: false,
    ...optionsProp,
  };
  const [collection, id] = endpoint.split('/');
  return (firebase.firestore as firebase.firestore.Firestore)
    .collection(collection)
    .doc(id)
    .update(data)
    .then((docRef) => {
      if (options.message) {
        message.success(formatMessage('api.patch.success'));
      }
      return docRef;
    })
    .catch((e) => {
      message.error(formatMessage('api.patch.fail'));
      console.error(e);
    });
};

export const deleteRequest = (endpoint: string, optionsProp?: ApiOptions) => {
  const options = {
    message: false,
    ...optionsProp,
  };
  const [collection, id] = endpoint.split('/');
  return (firebase.firestore as firebase.firestore.Firestore)
    .collection(collection)
    .doc(id)
    .delete()
    .then((docRef) => {
      if (options.message) {
        message.success(formatMessage('api.delete.success'));
      }
      return docRef;
    })
    .catch((e) => {
      message.error(formatMessage('api.delete.fail'));
      console.error(e);
    });
};

export const setupPlatform = () => {
  return firebase.functions
    ?.httpsCallable('setupPlatform')()
    .then((result) => {
      return result.data.text;
    });
};
