import styled from 'styled-components';
import { Tag, Avatar } from 'antd';

export const StyledTag = styled(Tag)`
  width: 100%;
  margin-bottom: 8px;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 8px;
`;
