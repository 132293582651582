import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select, Form } from 'antd';

const { Item } = Form;
const { Option } = Select;

export const statuses = ['open', 'inProgress', 'done'];

type StatusProps = {
  readOnly: boolean;
};

const Status: FC<StatusProps> = ({ readOnly }) => {
  return (
    <Item
      name="status"
      label={
        <FormattedMessage
          id="tasks.form.status.label"
          defaultMessage="Status"
        />
      }
    >
      <Select
        id="status"
        allowClear
        disabled={readOnly}
        placeholder={
          <FormattedMessage
            id="tasks.form.status.label"
            defaultMessage="Status"
          />
        }
      >
        {statuses.map((status) => (
          <Option key={status} id={status} value={status}>
            <FormattedMessage
              id={`tasks.form.status.label.${status}`}
              defaultMessage={`tasks.form.status.label.${status}`}
            />
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default Status;
