import { combineReducers, Reducer } from 'redux';
import { ecosystemReducer } from '@components/EcosystemIndicator/store';
import { dashboardReducer } from '@core/layout/store/reducers';
import { userReducer } from '@core/store/user/reducer';
import { productGroupsReducer } from '@core/store/productGroups/reducers';
import { unitGroupsReducer } from '@core/layout/store/unitGroupsSaga/reducers';
import { licencesReducer } from '@core/store/licences/reducer';
import { cartReducer } from '@core/store/cart/reducer';
import { ecosystemRelationsReducer } from '@core/store/ecosystemRelations/reducer';
import { stripeProductsReducer } from '@core/store/stripeProducts/reducers';
import { userPreferencesReducer } from '@core/store/userPreferences/reducer';
import { permissionsReducer } from '@core/store/permissions';

const rootReducer: Reducer = combineReducers({
  user: userReducer,
  userPreferences: userPreferencesReducer,
  dashboard: dashboardReducer,
  ecosystems: ecosystemReducer,
  productGroups: productGroupsReducer,
  unitGroups: unitGroupsReducer,
  licences: licencesReducer,
  permissions: permissionsReducer,
  cart: cartReducer,
  ecosystemRelations: ecosystemRelationsReducer,
  stripeProducts: stripeProductsReducer,
});

export default rootReducer;
