import * as React from 'react';
import { AutoComplete, Divider, Input, Form } from 'antd';
import removeUndefined from '@core/helpers/removeUndefined';
import { StyledSectionTitle } from '@apps/contacts/mainMenu/components/DetailContactForm/DetailContactForm.sc';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { ApiResponse, Contact, getContacts } from '@apps/contacts/services';
import { FilterTextObject } from '@services/filtering';
import { AutoCompleteLabel } from '@apps/documents/mainMenu/AutoCompleteContact';
import { SearchOutlined } from '@ant-design/icons';
import {
  StyledTag,
  StyledAvatar,
} from '@apps/contacts/mainMenu/components/DetailContactForm/RelatedContacts/RelatedContacts.sc';
import { CONTACTS_ACTION } from '@apps/contacts';

export const initialRelatedContacts = {
  contacts: [],
};

export type RelatedContactsPropType = {
  selectedContactId?: string;
};

const RelatedContacts: React.FC<RelatedContactsPropType> = ({
  selectedContactId,
}) => {
  const { readOnly, getFieldValue, setFields } = useFormContext();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const intl = useIntl();
  const disabled = readOnly;
  const alreadySelectedContacts = getFieldValue('contacts') as Contact[];
  const ecosystem = getFieldValue('ecosystem');
  const [innerEco, setInnerEco] = useState<string>(ecosystem);

  useEffect(() => {
    if (ecosystem !== innerEco) {
      setInnerEco(ecosystem);
      setFields([
        {
          name: 'contacts',
          value: [],
          touched: true,
        },
      ]);
    }
  }, [ecosystem]);

  const onSelect = (contactId: string) => {
    const selectedContact = contacts.filter((c) => c.id === contactId);

    if (selectedContact.length) {
      setValue('');
      setFields([
        {
          name: 'contacts',
          value: [
            ...alreadySelectedContacts,
            removeUndefined(selectedContact[0]),
          ],
          touched: true,
        },
      ]);
    }
  };

  const handleDelete = (contactId: string) => {
    setFields([
      {
        name: 'contacts',
        value: alreadySelectedContacts.filter((c) => c.id !== contactId),
        touched: true,
      },
    ]);
  };

  useEffect(() => {
    getContacts(
      {
        fields: [
          'id',
          'type',
          'companyName',
          'ecosystem',
          'firstName',
          'lastName',
          'avatar',
          'addresses',
          'bankAccounts',
          'phoneNumbers',
          'emails',
        ],
        filters: {
          ecosystem: {
            filter: ecosystem,
            type: 'contains',
            filterType: 'text',
          } as FilterTextObject,
        },
      },
      'view-contacts' as CONTACTS_ACTION,
    ).then((res: ApiResponse) => {
      setContacts(res.results);
    });
  }, [ecosystem]);

  const searchLowerCase = search.toLowerCase();
  const options = contacts
    .filter(
      (c) =>
        c.companyName?.toLowerCase().includes(searchLowerCase) ||
        c.firstName?.toLowerCase().includes(searchLowerCase) ||
        c.lastName?.toLowerCase().includes(searchLowerCase),
    )
    // remove selected contact if from search
    .filter((c) => c.id !== selectedContactId)
    // limit results to 20
    .slice(0, 20)
    .map((c) => ({
      label: (
        <AutoCompleteLabel
          contact={c}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
      ),
      value: c.id,
    }));

  return (
    <>
      <StyledSectionTitle level={5}>
        <FormattedMessage
          id="detailcontactform.relatedcontacts.title"
          defaultMessage="Related contacts"
        />
      </StyledSectionTitle>
      <Form.Item name="contacts" hidden />
      <AutoComplete
        // clear input if it is disabled
        value={disabled ? '' : value}
        disabled={disabled}
        dropdownClassName="contacts-autocomplete-dropdown"
        // @ts-ignore
        options={options}
        onSearch={setSearch}
        onSelect={(contactId) => {
          onSelect(contactId as string);
          setValue('');
        }}
        onChange={setValue}
        style={{ width: '100%' }}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder={intl.formatMessage({
            id: 'documents.autocompletecontact.placeholder',
            defaultMessage: 'Search in Contacts',
          })}
        />
      </AutoComplete>
      {alreadySelectedContacts.length ? <Divider type="horizontal" /> : null}
      {alreadySelectedContacts.map((contact) => {
        return (
          <StyledTag
            key={contact.id}
            closable={!readOnly}
            onClose={() => handleDelete(contact.id as string)}
          >
            <>
              <StyledAvatar
                src={contact.avatar}
                style={{ marginRight: '8px' }}
              />
              <span>
                {contact.type === 'person'
                  ? `${contact.firstName || ''} ${contact.lastName}`
                  : contact.companyName || ''}
              </span>
            </>
          </StyledTag>
        );
      })}
    </>
  );
};

export default RelatedContacts;
