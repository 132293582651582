import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, PageHeader, Button } from 'antd';
import {
  getInboxes,
  Inbox,
  postInbox,
  updateInbox,
} from '@apps/documents/service';
import InboxTable from '@apps/documents/settings/inbox/InboxTable';
import Details from '@apps/documents/settings/inbox/Details';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';

const InboxesManagement = () => {
  const intl = useIntl();
  const { uid } = useParams<UseParamsType>();
  const [loading, setLoading] = useState(true);
  const [inboxes, setInboxes] = useState<Inbox[]>([]);
  const [selectedRow, setSelectedRow] = useState<string>('');

  const fetchInboxes = useCallback((id?: string) => {
    setLoading(true);
    if (!selectedRow && !id) {
      setSelectedRow('');
    }
    getInboxes(uid).then((inboxes) => {
      setInboxes(inboxes);
      if (!selectedRow && inboxes.length) {
        if (!selectedRow && !id) {
          setSelectedRow(inboxes[0].id);
        } else if (id) {
          setSelectedRow(id);
        }
      }
      setLoading(false);
    });
  }, []);
  useEffect(() => fetchInboxes(), []);
  const details = useMemo(
    () => inboxes.find((inbox) => inbox.id === selectedRow),
    [selectedRow],
  );
  return (
    <>
      <PageHeader
        title={intl.formatMessage({
          id: 'settings.documents.inboxesmanagement.title',
          defaultMessage: 'settings.documents.inboxesmanagement.title',
        })}
        extra={
          <Button
            type="primary"
            onClick={() => {
              postInbox({
                name: 'New Inbox',
                ecosystem: uid,
                isActive: true,
              })?.then((id) => {
                fetchInboxes(id);
              });
            }}
          >
            {intl.formatMessage({
              id: 'settings.documents.inboxesmanagement.create',
              defaultMessage: 'settings.documents.inboxesmanagement.create',
            })}
          </Button>
        }
      />
      <Row gutter={22}>
        <Col span={14}>
          <InboxTable
            loading={loading}
            inboxes={inboxes}
            selectedRow={selectedRow}
            onChange={() => {}}
            rowClick={(inbox) => {
              setSelectedRow(inbox.id);
            }}
          />
        </Col>
        <Col span={10}>
          {!!details && (
            <Details
              loading={loading}
              details={details}
              onFinish={(inbox) => {
                updateInbox(selectedRow, {
                  ...details,
                  ...inbox,
                })?.then(() => {
                  fetchInboxes(selectedRow);
                });
              }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default InboxesManagement;
