export const transformFloat = (e: any): number => {
  if (e) {
    return Number(parseFloat(e).toFixed(2));
  }
  return 0.0;
};

export const transformInt = (e: any): number => {
  if (e) {
    return Number(parseInt(e));
  }
  return 0;
};
