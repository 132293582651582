import React, { FC, useState, useEffect, useCallback } from 'react';
import { useStore } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spin, Form as AntdForm, Space } from 'antd';
import moment from 'moment';
import { useIntl } from 'react-intl';
import {
  Document,
  LayoutField,
  DocumentType,
  SelectLayoutField,
} from '@apps/documents/service/namespace';
import { getLayout } from '@apps/documents/service/layouts';
import LayoutRenderer from '@apps/documents/mainMenu/LayoutRenderer';
import { injectEcosystemsOptions } from '@apps/documents/mainMenu/Details/Form/formInjectors';
import FormWithButtons from '@components/FormWithReminder/FormWithButtons';
import SpinContainer from '@components/SpinContainer';
import momentToFormatString from '@core/helpers/momentToFormatString';
import MediaViewer from '@components/MediaViewer';

const formLayout = {
  wrapperCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 12,
    },
    xxl: {
      span: 12,
    },
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 8,
    },
    xxl: {
      span: 4,
    },
    offset: 0,
  },
};

export type FormProps = {
  id?: string;
  initialValues: Partial<Document>;
  onSubmit: (v: any) => void;
  readOnly?: boolean;
  hideReadonlySubmitButton?: boolean;
};

const Form: FC<FormProps> = ({
  initialValues,
  onSubmit,
  readOnly = true,
  id,
  hideReadonlySubmitButton = true,
}) => {
  const [layout, setLayout] = useState<LayoutField[]>([]);
  const [docType, setDocType] = useState<DocumentType>(
    initialValues?.type as unknown as DocumentType,
  );
  const [parsedValues, setParsedValues] = useState<Partial<Document> | null>(
    null,
  );
  const history = useHistory();
  //TODO: ecosystems later should be accessible without need to fetch the store
  const store = useStore();
  const intl = useIntl();

  useEffect(() => {
    if (docType) {
      try {
        const documentLayout = getLayout(docType, readOnly, 'detailedForm');
        let values = initialValues;
        const documentLayoutWithOptions = documentLayout.map((field) => {
          if (field.fieldType === 'ecosystemSelect') {
            const { ecosystems } = store.getState();
            return injectEcosystemsOptions(
              ecosystems,
              field as SelectLayoutField,
            );
          }

          if (field.type === 'date' && values[field.fieldName]) {
            values = {
              ...values,
              [field.fieldName]: moment(values[field.fieldName] as string),
            };
          }

          return field;
        });

        setLayout(documentLayoutWithOptions);
        setParsedValues(values);
      } catch (_e) {}
    }
  }, [docType, initialValues, readOnly, store]);

  useEffect(() => {
    if (initialValues && initialValues.type) {
      setDocType(initialValues.type);
    }
  }, [initialValues]);

  const shouldUpdate = useCallback((prev, curr) => {
    if (prev.type !== curr.type) {
      setDocType(curr.type);
      return true;
    }

    return false;
  }, []);

  if (!parsedValues) {
    return <SpinContainer />;
  }
  return (
    <FormWithButtons
      autoComplete="off"
      initialValues={parsedValues}
      name="document"
      onFinish={(values) => onSubmit(momentToFormatString(values))}
      readOnly={readOnly}
      readonlyCancelButton={{
        label: intl.formatMessage({
          id: 'documents.details.form.readonlycancelbutton.label',
          defaultMessage: 'Back to list',
        }),
        onClick: () => {
          history.push('/documents');
        },
      }}
      readonlySubmitButton={
        !hideReadonlySubmitButton
          ? {
              label: intl.formatMessage({
                id: 'documents.details.form.readonlysubmitbutton.label',
                defaultMessage: 'Edit',
              }),
              onClick: () => {
                if (id) {
                  history.push(`/documents/${id}/edit`);
                }
              },
            }
          : undefined
      }
      cancelButton={{
        label: intl.formatMessage({
          id: 'documents.details.form.cancelbutton.label',
          defaultMessage: 'Cancel',
        }),
        onClick: () => {
          history.push('/documents');
        },
      }}
      submitButton={{
        label: intl.formatMessage({
          id: 'documents.details.form.submitbutton.label',
          defaultMessage: 'Save',
        }),
      }}
      style={{ flex: 1 }}
      {...formLayout}
    >
      <div style={{ display: 'flex' }}>
        {layout && (
          <AntdForm.Item shouldUpdate={shouldUpdate} noStyle>
            {() => {
              return (
                <Space
                  direction="vertical"
                  style={{ flex: 1, display: 'flex' }}
                  size="small"
                >
                  <LayoutRenderer layoutFields={layout} />
                </Space>
              );
            }}
          </AntdForm.Item>
        )}
        {initialValues && (
          <MediaViewer
            items={[
              {
                url: initialValues.documentUrl || '',
                thumbnailUrl: initialValues.thumbnailUrl,
                format: initialValues.fileDetails?.format || '',
              },
            ]}
            showPlayButton={false}
            showThumbnails={false}
          />
        )}
      </div>
      <Spin spinning={!docType || !layout.length} />
    </FormWithButtons>
  );
};

export default Form;
