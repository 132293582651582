import { Tag, Card } from 'antd';
import { styled } from '@styles/themes';

export const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FreeTrialTag = styled(Tag)`
  margin: 10px 10px;
`;

export const FlexDiv = styled.div`
  display: flex;
  line-height: 36px;
  gap: 10px;
`;

export const StoreCard = styled(Card)`
  & {
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: var(--swiper-navigation-top-offset, 90%);
    }
    .swiper-pagination {
      bottom: 0 !important;
    }
    .ant-card-body {
      padding: 12px !important;
    }
  }
`;

export const YouCanSwipe = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-style: italic;
  margin: 5px 0;
  padding: 15px 0;
`;

export const AppImagesGridContainer = styled.div`
  @media (max-width: 1400px) {
    display: flex;
  }
  display: grid;
  grid-template-columns: 1fr minmax(240px, 1fr) 1fr;
  gap: 10px;

  & {
    .ant-carousel {
      max-width: 300px;
    }
  }
`;

export const MetaDescription = styled.div`
  height: 160px;
  overflow: auto;
`;
