import { lazy } from 'react';
import { BankOutlined } from '@ant-design/icons';

const TaxCategoriesSettings = lazy(
  () => import(/* webpackChunkName: "financeApp" */ './taxCategories'),
);

export default {
  appSubmenuName: 'app.finance',
  icon: BankOutlined,
  sections: [
    {
      link: 'taxcategories_settings',
      title: 'settings.finance.taxCategories',
      component: TaxCategoriesSettings,
    },
  ],
};
