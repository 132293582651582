import { firebase } from '@services/Firebase';
import {
  SERIAL_NUMBERS,
  SerialNumberEntry,
} from '@apps/warehouse/services/namespace';
import moment from 'moment/moment';

const postSerialNumber = async (
  ecosystem: string,
  serialNumber: string,
  productId?: string,
  positionId?: string,
) => {
  const entry: SerialNumberEntry = {
    ecosystem,
    productId,
    positionId,
    serialNumber,
    timestamp: moment().valueOf(),
  };

  await firebase.firestore
    ?.collection(SERIAL_NUMBERS)
    .doc(`${ecosystem}_${serialNumber}`)
    .set(entry);
};

export default postSerialNumber;
