import { FilterRelatedContactObject } from '@services/filtering/index';

const relatedContactMatcher = (
  filterObject: FilterRelatedContactObject,
  row: any,
) => {
  if (filterObject.type === 'contains') {
    if (row.relatedContactSnapshot) {
      const { type, companyName, firstName, lastName } =
        row.relatedContactSnapshot;

      if (type === 'company' && companyName) {
        const name = companyName || '';
        return name?.toLowerCase().includes(filterObject.filter.toLowerCase());
      }

      if (type === 'person') {
        const name = `${firstName || ''} ${lastName || ''}`;
        return name?.toLowerCase().includes(filterObject.filter.toLowerCase());
      }
      return false;
    } else {
      return false;
    }
  }

  return false;
};

export default relatedContactMatcher;
