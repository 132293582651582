import { firebase } from '@services/Firebase';

export const COLLECTION_NAME = 'permissions';

type AddUserPermissionPayload = {
  userId: string;
  ecosystemId: string;
  companyRoleId: string;
  permissions: Record<string, Record<string, string>>;
};

export const setUserPermissions = ({
  userId,
  ecosystemId,
  companyRoleId,
  permissions,
}: AddUserPermissionPayload) => {
  return firebase?.db?.ref(`${COLLECTION_NAME}/${userId}/${ecosystemId}`).set({
    ...permissions,
    companyRole: companyRoleId,
  });
};

type UpdateUserPermissionPayload = {
  id: string;
  userId: string;
  ecosystemId: string;
  companyRole: EcosystemRole;
};

export const updateUserPermission = ({
  userId,
  ecosystemId,
  id,
  companyRole,
}: UpdateUserPermissionPayload) => {
  return firebase?.db
    ?.ref(`${COLLECTION_NAME}/${userId}/${ecosystemId}/${id}`)
    .update(companyRole);
};

export const getUserPermissions = (
  userId: string,
  ecosystemId: string,
): Promise<UserPermissions> => {
  if (!firebase?.db) {
    return Promise.resolve({} as UserPermissions);
  }
  return firebase.db
    .ref(`${COLLECTION_NAME}/${userId}/${ecosystemId}`)
    .once('value')
    .then((results) => {
      const records = results.val() as Record<string, UserPermissions>;
      if (!records) {
        return Promise.resolve({} as UserPermissions);
      }
      return Object.entries(records).reduce(
        (acc: any, entry) => {
          const key = entry[0];
          const value = entry[1];
          if (key === 'companyRole') {
            return {
              ...acc,
              companyRole: value,
            };
          } else {
            return {
              ...acc,
              permissions: {
                ...acc.permissions,
                [key]: { ...value },
              },
            };
          }
        },
        { permissions: {} },
      ) as UserPermissions;
    });
};
