import React, { FC, useCallback } from 'react';
import { Radio, Space, Input, Form } from 'antd';
import intl from '../../../../../intl';
import { PaymentTerm, Term } from '@apps/purchases/services/paymentTerms';
import { FormInstance } from 'antd/lib/form';

const types: Term['type'][] = ['afterDays', 'immediately', 'alreadyPaid'];

type Props = {
  currentTerm?: PaymentTerm['term'];
  form: FormInstance<PaymentTerm>;
};

const TermRadio: FC<Props> = ({ currentTerm, form }) => {
  const handleTermChange = useCallback(
    (e) => {
      const values = form.getFieldsValue();
      form.setFieldsValue({
        ...values,
        term: {
          ...values.term,
          type: e.target.value,
        },
      });
    },
    [form],
  );
  return (
    <Radio.Group onChange={handleTermChange}>
      <Space direction="vertical">
        <Radio value={types[0]}>
          <Space direction="horizontal">
            <span>
              {intl.formatMessage({
                id: 'app.sales.payment.term.afterDays.description.part1',
                defaultMessage: 'After',
              })}
            </span>
            <Form.Item noStyle name={['term', 'value']}>
              <Input
                type="number"
                disabled={currentTerm ? currentTerm.type !== 'afterDays' : true}
              />
            </Form.Item>
            <span>
              {intl.formatMessage({
                id: 'app.sales.payment.term.afterDays.description.part2',
                defaultMessage: 'days from invoice date',
              })}
            </span>
          </Space>
        </Radio>
        <Radio value={types[1]}>
          {intl.formatMessage({
            id: 'app.sales.payment.term.immediately.description',
            defaultMessage: 'Immediately',
          })}
        </Radio>
        <Radio value={types[2]}>
          {intl.formatMessage({
            id: 'app.sales.payment.term.alreadyPaid.description',
            defaultMessage: 'Already paid',
          })}
        </Radio>
      </Space>
    </Radio.Group>
  );
};

export default TermRadio;
