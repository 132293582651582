import React, { FC, useEffect, useState } from 'react';
import { Form, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Document } from '@apps/documents/service/namespace';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { Bucket, getBucketsPerDetails } from '@apps/purchases/services/buckets';
import { getDocumentsRelatedWithBucket } from '@apps/purchases/services/buckets';
import OrderSelectionModal from './OrderSelectionModal';

const { Item } = Form;

interface OrderProps {
  onOrderSelect: (
    bucket: Bucket,
    mode: 'add' | 'replace',
    order: Document,
  ) => void;
}

const Order: FC<OrderProps> = ({ onOrderSelect }) => {
  const { getFieldValue, getFieldsValue, setFieldsValue } = useFormContext();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const supplierId = getFieldValue('supplier');
  const ecosystem = getFieldValue('ecosystem');
  const deliveryNote = getFieldValue('deliveryNote');

  useEffect(() => {
    const fetchDocuments = async () => {
      if (supplierId && ecosystem) {
        try {
          const buckets = await getBucketsPerDetails({
            ecosystem,
            relatedContact: supplierId,
            stepType: 'ordered',
            status: 'done',
          });

          const allDocuments = await Promise.all(
            buckets.map((bucket) => getDocumentsRelatedWithBucket(bucket)),
          );

          setDocuments(allDocuments.flat());
        } catch (error) {
          console.error('Error fetching orders:', error);
          setDocuments([]);
        }
      } else {
        setDocuments([]);
      }
    };

    fetchDocuments();
  }, [supplierId, ecosystem]);

  const handleAddToTable = async (bucket: Bucket, order: Document) => {
    const orderId = bucket.relatedDocumentId;
    setFieldsValue({
      ...getFieldsValue(),
      supplier: supplierId,
      supplierSnapshot: bucket.relatedContactSnapshot,
      orderId,
      order,
    });
    onOrderSelect(bucket, 'add', order);
    setIsModalVisible(false);
  };

  const handleReplaceTable = async (bucket: Bucket, order: Document) => {
    const orderId = bucket.relatedDocumentId;
    setFieldsValue({
      ...getFieldsValue(),
      supplier: supplierId,
      supplierSnapshot: bucket.relatedContactSnapshot,
      orderId,
      order,
    });
    onOrderSelect(bucket, 'replace', order);
    setIsModalVisible(false);
  };

  return (
    <>
      <Item
        name="orderId"
        label={
          <FormattedMessage
            id="warehouse.create.order"
            defaultMessage="Order"
          />
        }
        className="animate__animated animate__slideInRight animate__faster"
      >
        <Button
          onClick={() => setIsModalVisible(true)}
          disabled={!supplierId || !ecosystem || !deliveryNote}
        >
          <FormattedMessage
            id="warehouse.create.order.select"
            defaultMessage="Select Order"
          />
        </Button>
      </Item>

      <OrderSelectionModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onAddToTable={handleAddToTable}
        onReplaceTable={handleReplaceTable}
        documents={documents}
        ecosystem={ecosystem}
        supplierId={supplierId}
      />
    </>
  );
};

export default Order;
