import * as React from 'react';
import { Badge, Icon } from '@todix/ui-components';
import { Wrapper } from '@core/layout/components/TaskHandler/TaskHandler.sc';

type NotificationsHandlerProps = {
  newNotificationsCount?: number;
  onClick?(): void;
};

export const NotificationsHandler: React.FC<NotificationsHandlerProps> = ({
  newNotificationsCount = 0,
  onClick,
}) => {
  const NotificationIcon = newNotificationsCount ? (
    <Badge count={newNotificationsCount}>
      <Icon.NotificationOutlined style={{ fontSize: 30 }} onClick={onClick} />
    </Badge>
  ) : (
    <Icon.NotificationOutlined style={{ fontSize: 30 }} onClick={onClick} />
  );

  return <Wrapper>{NotificationIcon}</Wrapper>;
};
