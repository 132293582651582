import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import dateFilter from '@core/helpers/dateFilter';
import moment from 'moment';
import { Product } from '@apps/products/services';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';

export const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="docName"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'sales.columns.operationNumber.header',
        defaultMessage: 'Operation number',
      })}
      sortable
      minWidth={180}
    />
    <AgGridColumn
      field="type"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'sales.columns.operationStep.header',
        defaultMessage: 'Operation step',
      })}
      valueGetter={({ data }: ValueGetterParams) => {
        if (!data) {
          return null;
        }
        return intl.formatMessage({
          id: `settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${data.type}`,
          defaultMessage: `settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${data.type}`,
        });
      }}
      sortable
      minWidth={180}
    />
    <AgGridColumn
      field="creationDate"
      headerName={intl.formatMessage({
        id: 'sales.columns.creationDate.header',
        defaultMessage: 'Creation Date',
      })}
      sortable
      valueGetter={({ data }: ValueGetterParams) => {
        if (!data) {
          return null;
        }
        return moment(data.creationDate).format(moment.defaultFormat);
      }}
      minWidth={210}
      //valueFormatter={({ value }) => moment(value).format(moment.defaultFormat)}
      filterParams={dateFilter}
      filter={'agDateColumnFilter'}
    />
    <AgGridColumn
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      field="contactSnapshot.companyName"
      headerName={intl.formatMessage({
        id: 'sales.columns.customer.header',
        defaultMessage: 'Customer',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="products"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'sales.columns.aggValue.header',
        defaultMessage: 'Agg. Value',
      })}
      sortable
      valueGetter={({ data }: ValueGetterParams) => {
        if (!data) {
          return null;
        }
        return data.products.reduce((acc: number, product: Product) => {
          acc += Number(product.price) * Number(product.amount);
          return acc;
        }, 0);
      }}
      minWidth={160}
    />
    <AgGridColumn
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      field="creatorSnapshot.displayName"
      headerName={intl.formatMessage({
        id: 'sales.columns.creator.header',
        defaultMessage: 'Creator',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      field="status"
      headerName={intl.formatMessage({
        id: 'sales.columns.status.header',
        defaultMessage: 'Status',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn cellRenderer="actionRenderer" maxWidth={70} />
  </>
);
