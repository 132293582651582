import React, { FC, useMemo, useCallback, useContext } from 'react';
import {
  createProductPurchasePrice,
  ProductPurchasePrice,
  updateProductPurchasePrice,
} from '@apps/products/services';
import { Form, Modal } from 'antd';
import PurchasesTabContext from '@apps/purchases/mainMenu/purchasesTabForProduct/context';
import intl from '../../../../../../intl';
import removeUndefined from '@core/helpers/removeUndefined';
import currency from 'currency.js';
import PriceForm from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/priceForm';

type PriceModalProps = {
  visible: boolean;
  price: ProductPurchasePrice | null;
  onCancel(): void;
  onOk(): void;
};

const PriceModal: FC<PriceModalProps> = ({
  visible,
  price,
  onCancel,
  onOk,
}) => {
  const { ecosystem, productId } = useContext(PurchasesTabContext);
  const [form] = Form.useForm<ProductPurchasePrice>();

  const okText = useMemo(() => {
    if (price && price.id) {
      return intl.formatMessage({
        id: 'button.save',
        defaultMessage: 'Save',
      });
    }
    return intl.formatMessage({
      id: 'button.create',
      defaultMessage: 'Create',
    });
  }, [price]);

  const cancelText = useMemo(() => {
    return intl.formatMessage({
      id: 'button.cancel',
      defaultMessage: 'Cancel',
    });
  }, []);

  const modalTitle = useMemo(() => {
    return !price
      ? intl.formatMessage({
          id: 'sales.salesTab.addPrice.title',
          defaultMessage: 'Add a new price',
        })
      : intl.formatMessage({
          id: 'sales.salesTab.editPrice.title',
          defaultMessage: 'Edit price',
        });
  }, [price]);

  const handleOk = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        let formValues = removeUndefined(values) as ProductPurchasePrice;
        formValues = {
          ...formValues,
          unitAmount: currency(formValues.unitAmount).value,
        };
        if (price && price.id) {
          //update price
          updateProductPurchasePrice(price.id, { ...price, ...formValues })
            ?.then(() => {
              form.resetFields();
              onOk();
            })
            ?.then(() => {
              form.resetFields();
              onOk();
            });
        } else {
          //create price
          if (ecosystem && ecosystem.id && productId) {
            createProductPurchasePrice({
              ...formValues,
              ecosystemId: ecosystem?.id,
              productId,
            })?.then(() => {
              form.resetFields();
              onOk();
            });
          }
        }
      })
      .catch((info) => {
        console.warn('Validation failed: ', info);
      });
  }, [ecosystem, form, onOk, price, productId]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const initialValues = useMemo(() => {
    return price
      ? {
          ...price,
          currency: price.currency || ecosystem?.currency || 'EUR',
        }
      : {
          model: 'standard',
          currency: ecosystem?.currency || 'EUR',
          isTaxInclusive: true,
          isActive: false,
          isArchived: false,
          productId,
        };
  }, [ecosystem?.currency, price, productId]);

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={handleCancel}
      title={modalTitle}
    >
      <PriceForm
        form={form}
        layout="vertical"
        name="purchasePriceForm"
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default PriceModal;
