import React, { FC, useCallback, useState } from 'react';
import { Document, getDocument, updateDocument } from '@apps/documents/service';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import AppConfig, { DOCUMENTS_ACTION } from '@apps/documents';
import { styled } from '@styles/themes';
import { useForm } from 'antd/lib/form/Form';
import DocumentType from '@apps/documents/mainMenu/Inbox/DocumentProcessing/DocumentType';
import HiddenFields from '@apps/documents/mainMenu/Inbox/DocumentProcessing/HiddenFields';
import DocumentCategory from '@apps/documents/mainMenu/Inbox/DocumentProcessing/DocumentCategory';
import {
  ButtonContainer,
  DocumentSpecificGridSection,
  DocumentWrapper,
  GridSection,
  LeftColumn,
  RightColumn,
  SaveButtons,
} from '@apps/documents/mainMenu/Inbox/DocumentProcessing/styles.sc';
import RelatedContact from '@apps/documents/mainMenu/Inbox/DocumentProcessing/RelatedContact';
import DocumentSpecificFields from '@apps/documents/mainMenu/Inbox/DocumentProcessing/DocumentSpecificFields';
import { firebase } from '@services/Firebase';
import { emitCustomEvent } from '@core/services';
import { message, Spin, Button } from 'antd';
import { DocumentProcessingEvents } from '@apps/documents/mainMenu/DocumentProcessing';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { getContact } from '@apps/contacts/services';
import momentToFormatString from '@core/helpers/momentToFormatString';
import { useHistory } from 'react-router-dom';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import DocumentAppConfig from '@apps/documents';

type Props = {
  document: Partial<Document>;
  id: string;
};

const FormContainer = styled.div``;

const DocumentProcessing: FC<Props> = ({ id, document }) => {
  const [form] = useForm();
  const history = useHistory();
  const intl = useIntl();
  const [submitting, setSubmitting] = useState(false);
  const initialValues = document;

  const action = 'update-documents' as DOCUMENTS_ACTION;
  const canUpdate = document
    ? checkUserPermissions(
        rootStore.getState(),
        DocumentAppConfig.todixId,
        action,
        document.ecosystem as string,
      )
    : false;

  const handleCancel = useCallback(() => {
    history.push(`/app/${AppConfig.todixId}/archive`);
  }, [history]);

  const handleSubmit = useCallback(
    async (values: Document, asDraft: boolean) => {
      const user = firebase.auth.currentUser;
      const beDocument = await getDocument(id);

      if (!canUpdate) {
        return;
      }

      if (!beDocument.isDraft) {
        emitCustomEvent<DocumentProcessingEvents, Partial<Document>>(
          'documentSaved',
          beDocument,
        );

        message.error(
          intl.formatMessage({
            id: 'documentprocessing.handlesave.error.alreadysaved',
            defaultMessage: 'File is already in archive',
          }),
          5,
        );
        setSubmitting(false);
        return;
      }

      const doc: Document = {
        ...document,
        ...values,
        isDraft: asDraft,
        hasCostStructure: values?.costStructure
          ? values.costStructure.length > 0
          : false,
        creationDate: moment(),
        creatingUser: user?.uid as string,
      };

      if (doc.relatedContact) {
        const relatedContactSnapshot = await getContact(doc.relatedContact);
        doc.relatedContactSnapshot = relatedContactSnapshot;
      }

      if (doc.payments) {
        doc.payments = doc.payments.map((payment) =>
          momentToFormatString(payment),
        );
      }

      updateDocument(id, doc)
        ?.then(() => {
          setSubmitting(false);
          emitCustomEvent<DocumentProcessingEvents, Partial<Document>>(
            'documentSaved',
            doc,
          );
          if (!asDraft) {
            history.push(`/app/${AppConfig.todixId}/archive`);
          } else {
            emitCustomEvent<string, string>('jumpToNextDoc', id);
          }
        })
        .catch(() => {
          message.error(
            asDraft
              ? intl.formatMessage({
                  id: 'documentprocessing.handlesave.error.draft',
                  defaultMessage: 'Error while saving document',
                })
              : intl.formatMessage({
                  id: 'documentprocessing.handlesave.error.archived',
                  defaultMessage: 'Error while archiving document',
                }),
          );
          setSubmitting(false);
        });
    },
    [document, history, id, intl, canUpdate],
  );

  const handleSave = useCallback(
    (asDraft: boolean) => {
      if (canUpdate) {
        form.validateFields().then(async (values) => {
          await handleSubmit(values, asDraft);
        });
      }
    },
    [form, handleSubmit, canUpdate],
  );

  return (
    <Spin spinning={submitting}>
      <div>
        <AppEntityForm
          elId={id}
          appId={AppConfig.todixId}
          autoComplete="off"
          initialValues={initialValues}
          onValuesChange={(v: any, _all: Document) => {
            emitCustomEvent('setDocument', v);
          }}
          name="documentProcessing"
          providedForm={form}
          readOnly={!canUpdate}
          style={{ height: `calc(100vh - 280px)` }}
          hideButtons={true}
        >
          {() => {
            return (
              <DocumentWrapper>
                <FormContainer>
                  <GridSection>
                    <LeftColumn>
                      <HiddenFields />
                      <DocumentType />
                      <DocumentCategory />
                    </LeftColumn>
                    <RightColumn>
                      <RelatedContact />
                    </RightColumn>
                  </GridSection>
                  <DocumentSpecificGridSection>
                    <DocumentSpecificFields />
                  </DocumentSpecificGridSection>
                </FormContainer>
              </DocumentWrapper>
            );
          }}
        </AppEntityForm>
      </div>
      <ButtonContainer>
        <div>
          <Button
            type="default"
            htmlType="button"
            disabled={submitting}
            onClick={handleCancel}
          >
            {intl.formatMessage({
              id: 'button.cancel',
              defaultMessage: 'Cancel',
            })}
          </Button>
        </div>
        <SaveButtons>
          <Button
            type="primary"
            htmlType="button"
            disabled={submitting || !canUpdate}
            onClick={() => handleSave(false)}
          >
            {intl.formatMessage({
              id: 'button.save',
              defaultMessage: 'Save',
            })}
          </Button>
          <Button
            type="primary"
            htmlType="button"
            onClick={() => handleSave(true)}
            disabled={submitting || !canUpdate}
          >
            {intl.formatMessage({
              id: 'button.save.and.new',
              defaultMessage: 'Save + new',
            })}
          </Button>
        </SaveButtons>
      </ButtonContainer>
    </Spin>
  );
};

export default DocumentProcessing;
