import * as React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { StyledInnerLabel } from '@components/FormFields/common.sc';

type Props = CheckboxProps & {
  name: string;
  label: React.ReactNode;
};

const Checkbox: React.FC<Props> = (props) => {
  return (
    <>
      <div>
        <AntdCheckbox {...props}>
          <StyledInnerLabel>{props.label}</StyledInnerLabel>
        </AntdCheckbox>
      </div>
    </>
  );
};

export default Checkbox;
