import { SaleOperationStatus } from '@apps/sales/services/saleOperation';

const saleStatusColors: Record<SaleOperationStatus, string> = {
  success: '#0e7d40',
  error: '#f5222d',
  empty: '#595959',
  pending: '#1890ff',
  cancelled: '#faad14',
};

export default saleStatusColors;
