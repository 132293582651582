import { firebase } from '@services/Firebase';
import { SEATS } from '@services/licences/namespace';

type Payload = {
  ecosystemId: string;
  appId: string;
  subId: string;
  seats: number;
};

const setSeatsForApp = ({
  ecosystemId,
  appId,
  subId,
  seats,
}: Payload): Promise<any> | undefined => {
  return firebase?.db
    ?.ref(`${SEATS}/${ecosystemId}/${appId}/${subId}`)
    .set(seats);
};

export default setSeatsForApp;
