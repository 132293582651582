import { ProductGroup } from '@services/productGroups/namespace';

export enum ProductGroupsActionsTypes {
  SET_PRODUCT_GROUPS = 'SET_PRODUCT_GROUPS',
  SET_ECOSYSTEM_PRODUCT_GROUP = 'SET_ECOSYSTEM_PRODUCT_GROUP',
}

export const setProductGroups = (payload: ProductGroup[]) => ({
  type: ProductGroupsActionsTypes.SET_PRODUCT_GROUPS,
  payload,
});

export const setEcosystemProductGroups = (payload: ProductGroup[]) => ({
  type: ProductGroupsActionsTypes.SET_ECOSYSTEM_PRODUCT_GROUP,
  payload,
});

export type SetProductGroups = ReturnType<typeof setProductGroups>;

export type SetEcosystemProductGroups = ReturnType<
  typeof setEcosystemProductGroups
>;

export type ProductGroupActions = SetProductGroups | SetEcosystemProductGroups;
