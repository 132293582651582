export const coreTheme = {
  colors: {
    main: {
      primary: '#0E7D40',
      secondary: '#0A3161',
    },
    text: {
      normal: '#262626',
      gray: '#595959',
      lightGray: '#8c8c8c',
      light: '#bfbfbf',
      lightGreen: '#0e7d40',
    },
    background: {
      blue: '#1890ff',
      light: '#FFF',
      lightGreen: '#f6ffed',
    },
    functional: {
      error: '#F16A82',
      success: '#4DC66E',
      disabled: '#A5A5A5',
    },
    scrollbar: {
      track: '#fafafa',
      thumb: '#bfbfbf',
    },
    badge: {
      red: '#f5222d',
    },
  },
  textSize: {
    1: '12px',
    2: '14px',
    3: '16px',
    4: '20px',
    5: '24px',
    6: '30px',
    7: '38px',
    8: '46px',
    9: '56px',
  },
};
