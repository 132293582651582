import React, { FC } from 'react';
import { useSlate } from 'slate-react';
import isBlockActive from '@components/RichTextEditor/helpers/isBLockActive';
import { TEXT_ALIGN_TYPES } from '@components/RichTextEditor/consts';
import toggleBlock from '@components/RichTextEditor/helpers/toogleBlock';
import { ButtonWithMargins } from '@components/RichTextEditor/styles.sc';

type Props = {
  format: string;
  icon: any;
};
const BlockButton: FC<Props> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ButtonWithMargins
      type={
        isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
        )
          ? 'primary'
          : 'default'
      }
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={icon}
    />
  );
};

export default BlockButton;
