import { styled } from '@styles/themes';
import { Text } from '@todix/ui-components';
import { Tabs, Form } from 'antd';

const { Item } = Form;

export const StyledSectionTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.text.gray};
  font-weight: 600;
`;

export const CompoundFieldTabs = styled(Tabs)`
  & {
    .ant-tabs-nav::before {
      border: 0;
    }
  }
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(280px, 0.45fr) minmax(280px, 0.55fr);
  grid-column-gap: 10px;

  & {
    .ant-tabs-content-holder {
      height: auto !important;
      overflow: auto;
    }

    .ant-btn {
      width: auto;
    }
  }
`;

export const Column = styled.div`
  & {
    .ant-collapse {
      margin-bottom: 12px;
    }
  }
`;

export const MaxItem = styled(Item)`
  width: 100%;
  min-width: 100%;

  > .ant-form-item-control {
    width: 100%;
    max-width: 100%;
  }
`;

export const DivFlex = styled.div`
  display: flex;
  flex-direction: column;
`;
