import { connect } from 'react-redux';
import { compose } from 'redux';

import { RootStore } from '@core/store';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import {
  OwnProps,
  SelectedEcosystem,
} from '@apps/contacts/mainMenu/components/DetailContactForm/SelectedEcosystem/SelectedEcosystem';
import appConfig from '@apps/contacts';

const mapState = (state: RootStore, ownProps: OwnProps) => ({
  ecosystems: getActiveEcosystemsForApp(
    state,
    appConfig.todixId,
    ownProps.action as string,
  ),
  ...ownProps,
});

export type StateProps = ReturnType<typeof mapState>;

export default compose(connect(mapState, () => ({})))(SelectedEcosystem);
