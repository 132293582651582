import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { SelectLayoutField } from '@apps/documents/service/namespace';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { FormattedMessage } from 'react-intl/lib';
import { useIntl } from 'react-intl';

const SelectField: FC<FieldProps> = ({ field, ...rest }) => {
  const intl = useIntl();
  const { readOnly } = useFormContext();
  const {
    fieldName,
    label,
    rules,
    className,
    style,
    placeholder,
    options,
    readOnly: fieldReadOnly,
    mode,
  } = field as SelectLayoutField;
  return (
    <Form.Item
      name={fieldName}
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      rules={rules?.map((r) => {
        r.message =
          r.message && typeof r.message === 'string' ? (
            <FormattedMessage
              id={`${r.message}`}
              defaultMessage={`${r.message}`}
            />
          ) : (
            r.message
          );
        return r;
      })}
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <Select
        mode={mode}
        disabled={fieldReadOnly ? fieldReadOnly : readOnly}
        placeholder={
          placeholder ? (
            <FormattedMessage id={placeholder} defaultMessage={placeholder} />
          ) : (
            placeholder
          )
        }
        options={
          options
            ? options.map((o) => {
                if (typeof o.label === 'string' && o.label) {
                  o.label = intl.formatMessage({
                    id: o.label,
                    defaultMessage: o.label,
                  });
                }
                return o;
              })
            : []
        }
        className={className}
        style={style}
        allowClear
        showArrow={!fieldReadOnly || !readOnly}
        bordered={!fieldReadOnly || !readOnly}
      />
    </Form.Item>
  );
};

export default SelectField;
