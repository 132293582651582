import React, { FC } from 'react';
import { Avatar, Typography, Tooltip } from 'antd';
import { ResourceLabelSpace } from '@apps/weekplan/mainMenu/WeekPlan/Board.sc';

const { Text } = Typography;

type ResourceLabelProps = {
  resource: Pick<CommonUserData, 'photoURL' | 'displayName' | 'email'>;
};

const ResourceLabel: FC<ResourceLabelProps> = ({ resource }) => (
  <Tooltip title={`${resource.displayName} (${resource.email})`}>
    <ResourceLabelSpace direction="horizontal">
      <Avatar src={resource.photoURL} size="small" />
      <Text ellipsis>{resource.displayName}</Text>
    </ResourceLabelSpace>
  </Tooltip>
);

export default ResourceLabel;
