import React, { FC, useMemo, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, Form, Select, Space } from 'antd';
import { Contact, getContactsForEcosystem } from '@apps/contacts/services';
import { useFormContext } from '@components/FormWithReminder/useFormContext';

const { Item } = Form;
const { Option } = Select;

const Supplier: FC = () => {
  const { getFieldValue } = useFormContext();
  const ecosystem = getFieldValue('ecosystem');
  const [suppliers, setSuppliers] = useState<Contact[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ecosystem) {
      setLoading(true);
      getContactsForEcosystem(ecosystem, 'view-contacts')
        ?.then((results) => {
          setSuppliers(results);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem]);

  const options = useMemo(() => {
    return suppliers.map((supplier, index) => {
      return (
        <Option key={index} value={supplier.id}>
          <Space size="small" direction="horizontal">
            <Avatar shape="circle" src={supplier.avatar} size="small" />
            {supplier.type === 'company' && <span>{supplier.companyName}</span>}
            {supplier.type === 'person' && (
              <span>
                {supplier.firstName} {supplier.lastName}
              </span>
            )}
          </Space>
        </Option>
      );
    });
  }, [suppliers]);
  return (
    <Item
      className="animate__animated animate__slideInLeft animate__faster"
      name="supplier"
      label={
        <FormattedMessage
          id="warehouse.create.supplier"
          defaultMessage="Supplier"
        />
      }
    >
      <Select
        loading={loading}
        placeholder={
          <FormattedMessage
            id="warehouse.create.supplier.placeholder"
            defaultMessage="Supplier..."
          />
        }
      >
        {options}
      </Select>
    </Item>
  );
};

export default Supplier;
