import React, { FC } from 'react';
import { Form } from 'antd';

import { Select } from '@components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';

export const initialProductGroup = {
  productGroup: [] as string[],
};

const { Option } = Select;
const { Item } = Form;

const mockedGroups = [
  {
    name: 'Furniture',
    id: 0,
  },
  {
    name: 'Cars',
    id: 1,
  },
  {
    name: 'Equipment',
    id: 2,
  },
];

const SelectedProductGroup: FC = () => {
  const { readOnly } = useFormContext();
  return (
    <Item
      name="productGroup"
      label={
        <FormattedMessage
          id="products.selectedproductgroup.label"
          defaultMessage="Product group"
        />
      }
      required
    >
      <Select
        disabled={readOnly}
        allowClear
        optionLabelProp="label"
        name="productGroup"
        placeholder={
          <FormattedMessage
            id="products.selectedproductgroup.placeholder"
            defaultMessage="Select product group..."
          />
        }
      >
        {mockedGroups.map(({ name, id }) => (
          <Option value={name} key={id} label={name}>
            {name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default SelectedProductGroup;
