import React, { FC } from 'react';
import { Form } from 'antd';
import { ItemNoMargin, StyledMoneyInput } from './styles.sc';

const { Item } = Form;

type Props = {
  name: string;
};

const PriceOutput: FC<Props> = ({ name }) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const value = getFieldValue(name);
        return (
          <ItemNoMargin name={name}>
            <StyledMoneyInput value={value} disabled={true} />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default PriceOutput;
