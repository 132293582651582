import { styled } from '@styles/themes';

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 10px;

  && {
    .ant-form-item-label {
      min-width: 160px;
    }
  }
`;

export default FormContainer;
