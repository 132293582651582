import { ActionTypes } from '@core/layout/store/unitGroupsSaga/actionTypes';
import { UnitGroups } from '@services/unitGroups/namespace';

export const fetchUnitGroups = () =>
  ({
    type: ActionTypes.FETCH_UNIT_GROUPS,
  } as const);

export type FetchUnitGroupsAction = ReturnType<typeof fetchUnitGroups>;

type FetchUnitGroupsSuccessPayload = UnitGroups[];

export const fetchUnitGroupsSuccess = (
  payload: FetchUnitGroupsSuccessPayload,
) =>
  ({
    type: ActionTypes.FETCH_UNIT_GROUPS_SUCCESS,
    payload,
  } as const);

export type FetchUnitGroupsSuccessAction = ReturnType<
  typeof fetchUnitGroupsSuccess
>;

export const fetchUnitGroupsFailed = (error: string) =>
  ({
    type: ActionTypes.FETCH_UNIT_GROUPS_FAILED,
    error,
  } as const);

export type FetchUnitGroupsFailedAction = ReturnType<
  typeof fetchUnitGroupsFailed
>;

export type UnitGroupsActions =
  | FetchUnitGroupsAction
  | FetchUnitGroupsSuccessAction
  | FetchUnitGroupsFailedAction;
