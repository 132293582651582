import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import { ColSpanParams } from 'ag-grid-community/dist/lib/entities/colDef';

const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="isFav"
      filter={false}
      headerName={intl.formatMessage({
        id: 'app.projects.columns.favourites.header',
        defaultMessage: 'Favourites',
      })}
      minWidth={40}
      cellRenderer="favProjectCellRenderer"
    />
    <AgGridColumn
      field="name"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'app.projects.columns.name.header',
        defaultMessage: 'Name',
      })}
      sortable
      minWidth={160}
      cellRenderer="projectNameRenderer"
    />
    <AgGridColumn
      field="ecosystem"
      headerName={intl.formatMessage({
        id: 'app.projects.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      cellRenderer="ecosystemRenderer"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      filter="ecosystemFilter"
    />
    <AgGridColumn
      field="creator"
      headerName={intl.formatMessage({
        id: 'app.projects.columns.creator.header',
        defaultMessage: 'Creator',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
    />
    <AgGridColumn
      field="startDate"
      headerName={intl.formatMessage({
        id: 'app.projects.columns.startDate.header',
        defaultMessage: 'Start date',
      })}
      sortable
      minWidth={140}
      cellRenderer="dateRenderer"
    />
    <AgGridColumn
      field="endDate"
      headerName={intl.formatMessage({
        id: 'app.projects.columns.endDate.header',
        defaultMessage: 'End date',
      })}
      minWidth={140}
      cellRenderer="dateRenderer"
    />
    <AgGridColumn
      field="id"
      headerName={intl.formatMessage({
        id: 'app.projects.columns.children.header',
        defaultMessage: 'Tasks',
      })}
      minWidth={140}
      cellRenderer="tasksInProjectCellRenderer"
      filter={false}
    />
    <AgGridColumn
      field="managerId"
      headerName={intl.formatMessage({
        id: 'app.projects.columns.manager.header',
        defaultMessage: 'Manager',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
    />
    <AgGridColumn
      field="membersIds"
      headerName={intl.formatMessage({
        id: 'app.projects.columns.members.header',
        defaultMessage: 'Members',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
    />
    <AgGridColumn cellRenderer="actionRenderer" maxWidth={70} />
  </>
);

export default columns;
