import { styled } from '@styles/themes';
import { Card } from '../../../../../ui-components';

export const GridContainer = styled.div`
  display: grid;
  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
  }
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 10px;
`;

export const StyledProductCard = styled(Card)`
  min-height: 220px;

  & {
    .image-gallery-image {
      max-height: 120px !important;
    }
  }
`;
