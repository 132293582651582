import React, { FC } from 'react';
import { Form, InputProps } from 'antd';
import {
  ItemNoMargin,
  ShortInput,
} from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';

const { Item } = Form;

type Props = InputProps & {
  name: string;
  handleSave(): void;
};

const calculateValues = (
  amountValue: string,
  formValues: Record<string, any>,
) => {
  const { discountedPrice, grossPrice } = formValues;
  const amount =
    amountValue !== undefined && amountValue !== '' ? Number(amountValue) : 0;
  const totalNetPrice = Number(Number(discountedPrice * amount).toFixed(2));
  const totalGrossPrice = Number(Number(grossPrice * amount).toFixed(2));

  return {
    amount,
    totalNetPrice,
    totalGrossPrice,
  };
};

const Amount: FC<Props> = ({ name, handleSave, ...inputProps }) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={name}>
            <ShortInput
              {...inputProps}
              type="number"
              maxLength={5}
              min={0}
              onChange={(event) => {
                const value = event.target.value;
                const newValues = calculateValues(value, formValues);

                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });

                if (handleSave) {
                  handleSave();
                }
              }}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default Amount;
