import { jsPDF } from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { TemplateProps } from '@apps/sales/mainMenu/components/pdfTemplates/namespace';
import calculateTotalsForProducts from '@apps/sales/mainMenu/components/SaleCreator/calculateTotalsForProducts';
import { DocumentType } from '@apps/documents/service';
import withSuffix from '@core/helpers/withSuffix';

type TitleEntry = {
  header: string;
  dataKey: string;
};

const createData = (
  products: TemplateProps['values']['products'],
  type: DocumentType,
) => {
  return products.map((prod, index) => {
    const pos = index + 1;
    return type === 'deliveryNote'
      ? {
          pos: `${pos}`,
          name: `${prod?.productName || ''} ${prod?.description || ' '}`,
          amount: `${prod.amount}`,
          unit: `${prod?.salesUnit || prod?.saleDetails?.salesUnit || ' '}`,
        }
      : {
          pos: `${pos}`,
          name: `${prod?.productName || ''} ${prod?.description || ' '}`,
          amount: `${prod.amount}`,
          unit: `${prod?.salesUnit || prod?.saleDetails?.salesUnit || ' '}`,
          price: withSuffix({
            value: `${prod.price}`.replaceAll(',', '').replaceAll('.', ','),
          }),
          discount: `${prod?.discount || 0}`,
          discountedPrice: `${withSuffix({
            value: prod?.discountedPrice
              ? `${prod.discountedPrice}`
                  .replaceAll(',', '')
                  .replaceAll('.', ',')
              : `${prod.price || 0}`.replaceAll(',', '').replaceAll('.', ','),
          })}`,
          taxValue: `${
            prod.taxValue !== undefined ? `${prod.taxValue}%` : '19%'
          }`,
          grossPrice: `${withSuffix({
            value: prod?.grossPrice
              ? `${prod.grossPrice}`.replaceAll(',', '').replaceAll('.', ',')
              : `${prod.grossPrice || 0}`
                  .replaceAll(',', '')
                  .replaceAll('.', ','),
          })}`,
        };
  });
};

const addProductsTable = (
  doc: jsPDF,
  props: TemplateProps,
  lastYPosition: number,
  type: DocumentType,
) => {
  const {
    values: { products, deliveryTerms, paymentTerms },
    intl,
  } = props;
  const titles: TitleEntry[] =
    type === 'deliveryNote'
      ? [
          {
            header: intl.formatMessage({
              id: 'sales.createForm.position.label',
              defaultMessage: 'Pos.',
            }),
            dataKey: 'pos',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createNew.item',
              defaultMessage: 'Product/Service',
            }),
            dataKey: 'name',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createForm.amount.label',
              defaultMessage: 'Amount',
            }),
            dataKey: 'amount',
          },
          {
            header: intl.formatMessage({
              id: 'products.selectedunit.label',
              defaultMessage: 'Unit',
            }),
            dataKey: 'unit',
          },
        ]
      : [
          {
            header: intl.formatMessage({
              id: 'sales.createForm.position.label',
              defaultMessage: 'Pos.',
            }),
            dataKey: 'pos',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createNew.item',
              defaultMessage: 'Product/Service',
            }),
            dataKey: 'name',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createForm.amount.label',
              defaultMessage: 'Amount',
            }),
            dataKey: 'amount',
          },
          {
            header: intl.formatMessage({
              id: 'products.selectedunit.label',
              defaultMessage: 'Unit',
            }),
            dataKey: 'unit',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createForm.netPrice.label',
              defaultMessage: 'Net price',
            }),
            dataKey: 'price',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createForm.discount.label',
              defaultMessage: 'Discount (%)',
            }),
            dataKey: 'discount',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createForm.discountedPrice.label',
              defaultMessage: 'Discounted price',
            }),
            dataKey: 'discountedPrice',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createForm.tax.label',
              defaultMessage: 'Tax (%)',
            }),
            dataKey: 'taxValue',
          },
          {
            header: intl.formatMessage({
              id: 'sales.createForm.grossPrice.label',
              defaultMessage: 'Gross price',
            }),
            dataKey: 'grossPrice',
          },
        ];
  const data = createData(products, type);
  const totals = calculateTotalsForProducts(products);
  const { totalNetPrice, totalDiscountedPrice, taxes, total } = totals;
  const taxesRow = Object.keys(taxes)
    .sort()
    .map((taxKey) => {
      return [
        {
          rowSpan: 1,
          colSpan: 8,
          content: `${intl.formatMessage({
            id: 'sales.salesTab.pricePreview.tax',
            defaultMessage: 'Tax',
          })} (${taxKey} %)`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          content: withSuffix({
            value: taxes[taxKey]
              ? `${taxes[taxKey]}`.replaceAll(',', '').replaceAll('.', ',')
              : '',
          }),
        },
      ] as RowInput;
    });

  autoTable(doc, {
    headStyles: {
      fillColor: '#8c8c8c',
      textColor: '#000',
    },
    footStyles: {
      fillColor: '#FFF',
      textColor: '#000',
    },
    styles: {
      fontSize: 8,
    },
    margin: { top: lastYPosition, left: 20 },
    columns: titles,
    body: data as RowInput[],
    foot:
      type === 'deliveryNote'
        ? [
            [
              {
                rowSpan: 2,
                colSpan: 2,
                content: `${intl.formatMessage({
                  id: 'sales.settings.deliveryterms.header',
                  defaultMessage: 'Delivery terms',
                })}:`,
              },
              {
                rowSpan: 2,
                colSpan: 2,
                content: deliveryTerms?.shortage || ``,
              },
              {
                rowSpan: 2,
                colSpan: 2,
                content: `${intl.formatMessage({
                  id: 'sales.settings.paymentTerms.header',
                  defaultMessage: 'Payment terms',
                })}:`,
              },
              {
                rowSpan: 2,
                colSpan: 2,
                content: paymentTerms?.name || ``,
              },
            ],
          ]
        : [
            [
              {
                rowSpan: 1,
                colSpan: 8,
                content: intl.formatMessage({
                  id: 'sales.createForm.sumNet.label',
                  defaultMessage: 'Sum net',
                }),
              },
              {
                rowSpan: 1,
                colSpan: 1,
                content: withSuffix({
                  value: totalNetPrice
                    ? `${totalNetPrice}`
                        .replaceAll(',', '')
                        .replaceAll('.', ',')
                    : '',
                }),
              },
            ],
            ...taxesRow,
            [
              {
                rowSpan: 1,
                colSpan: 8,
                content: intl.formatMessage({
                  id: 'sales.createForm.discountedPrice.label',
                  defaultMessage: 'Discounted price',
                }),
              },
              {
                rowSpan: 1,
                colSpan: 1,
                content: withSuffix({
                  value: totalDiscountedPrice
                    ? `${totalDiscountedPrice}`
                        .replaceAll(',', '')
                        .replaceAll('.', ',')
                    : '',
                }),
              },
            ],
            [
              {
                rowSpan: 1,
                colSpan: 8,
                content: intl.formatMessage({
                  id: 'sales.salesTab.pricePreview.total',
                  defaultMessage: 'Total',
                }),
                styles: {
                  fillColor: '#8c8c8c',
                },
              },
              {
                rowSpan: 1,
                colSpan: 1,
                content: withSuffix({
                  value: total
                    ? `${total}`.replaceAll(',', '').replaceAll('.', ',')
                    : '',
                }),
                styles: {
                  fillColor: '#8c8c8c',
                },
              },
            ],
            [
              {
                rowSpan: 2,
                colSpan: 2,
                content: `${intl.formatMessage({
                  id: 'sales.settings.deliveryterms.header',
                  defaultMessage: 'Delivery terms',
                })}:`,
              },
              {
                rowSpan: 2,
                colSpan: 2,
                content: deliveryTerms?.shortage || ``,
              },
              {
                rowSpan: 2,
                colSpan: 2,
                content: `${intl.formatMessage({
                  id: 'sales.settings.paymentTerms.header',
                  defaultMessage: 'Payment terms',
                })}:`,
              },
              {
                rowSpan: 2,
                colSpan: 2,
                content: paymentTerms?.name || ``,
              },
            ],
          ],
  });
};

export default addProductsTable;
