import React, { FC } from 'react';
import { Product } from '@apps/products/services';

type TreeNodeProps = {
  resource: Record<string, any>;
};

const TreeNode: FC<TreeNodeProps> = ({ resource }) => {
  const product = resource as Product;
  return <span>{product?.productName || ''}</span>;
};

export default TreeNode;
