import * as React from 'react';
import { Dropdown } from 'antd';
import { DropDownProps } from 'antd/lib/dropdown';

const DropdownWithFullScreenMode: React.FC<DropDownProps> = (props) => (
  <Dropdown
    getPopupContainer={() => {
      const mainElement = document
        .getElementsByClassName('fullscreen')
        .item(0) as HTMLElement;
      return mainElement || document.body;
    }}
    {...props}
  />
);

export default DropdownWithFullScreenMode;
