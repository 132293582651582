import { FieldType, LayoutField } from '@apps/documents/service/namespace';
import {
  CommonFieldsLayout,
  SideViewCommonFieldsLayout,
} from '@apps/documents/service/commonFields.layouts';
import { LayoutDestination } from '@apps/documents/service/layouts';

export const ProcessingReportLayout: LayoutField[] = [
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.processinginvoice.docnumber.label',
    rules: [
      {
        required: true,
        message: 'documents.processinginvoice.docnumber.error',
      },
    ],
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
    rules: [
      { required: true, message: 'documents.commonfields.issuingDate.error' },
    ],
    noFutureDate: true,
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.receivingdate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.receivingdate.placeholder',
    rules: [
      {
        required: true,
        message: 'documents.processinginvoice.receivingDate.error',
      },
    ],
    noFutureDate: true,
  },
  /* {
    fieldName: 'productNumber',
    fieldType: 'input',
    label: 'documents.processingquotation.productnumber.label',
    placeholder: 'documents.processingquotation.productnumber.placeholder',
  }, */
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.processinginvoice.referencenumber.label',
  },
  /* {
    fieldName: 'productName',
    fieldType: 'input',
    label: 'documents.processingquotation.productname.label',
    placeholder: 'documents.processingquotation.productname.placeholder',
  } */
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    placeholder: 'documents.commonfields.sideview.notes',
    wrapperStyle: {
      gridColumn: '1 / span 2',
    },
  },
];

export const ProcessingSummaryReportLayout: LayoutField[] =
  ProcessingReportLayout.map((field) => ({
    ...field,
    fieldType: ['datepicker'].includes(field.fieldType)
      ? (field.fieldType as FieldType)
      : 'input',
    placeholder: '',
    style: {
      border: 0,
    },
  }));

export const ReportLayout: LayoutField[] = [
  ...CommonFieldsLayout,
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.invoice.docnumber.label',
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.invoice.receivingdate.label',
    type: 'date',
    placeholder: 'documents.invoice.receivingdate.placeholder',
  },
  /* {
    fieldName: 'productNumber',
    fieldType: 'input',
    label: 'documents.quotation.productnumber.label',
    placeholder: 'documents.quotation.productnumber.placeholder',
  }, */
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.invoice.referencenumber.label',
  },
  /* {
    fieldName: 'productName',
    fieldType: 'input',
    label: 'documents.quotation.productname.label',
    placeholder: 'documents.quotation.productname.placeholder',
  }, */
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];

export const SideViewReportLayout: LayoutField[] = [
  ...SideViewCommonFieldsLayout,
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.invoice.sideview.docnumber.label',
    readOnly: true,
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.invoice.sideview.receivingdate.label',
    type: 'date',
  },
  /* {
    fieldName: 'productNumber',
    fieldType: 'input',
    label: 'documents.quotation.sideview.productnumber.label',
    readOnly: true,
  }, */
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.invoice.sideview.referencenumber.label',
    readOnly: true,
  },
  /* {
    fieldName: 'productName',
    fieldType: 'input',
    label: 'documents.quotation.sideview.productname.label',
    readOnly: true,
  }, */
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];

const reportLayouts: Record<LayoutDestination, LayoutField[]> = {
  detailedForm: ReportLayout,
  sideView: SideViewReportLayout,
  processing: ProcessingReportLayout,
  processingSummary: ProcessingSummaryReportLayout,
};

export default reportLayouts;
