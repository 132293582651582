import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import { Tabs, Modal, message, Form, DatePicker, Input } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import ProductsAppConfig from '@apps/products/index';

import {
  Product,
  deleteProduct,
  getProduct,
  patchProduct,
} from '@apps/products/services';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import SpinContainer from '@components/SpinContainer';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'antd/es/select';
import { getEcosystemsOptions } from '@apps/documents/mainMenu/Details/Form/formInjectors';
import optionsMapper from '@core/helpers/optionsMapper';

const { TabPane } = Tabs;

const formLayout = {
  wrapperCol: {
    span: 12,
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 10,
    },
    xxl: {
      span: 6,
    },
    offset: 0,
  },
};

export const SideContent: FC<{ id: string }> = ({ id }) => {
  const [product, setProduct] = useState<Product | null>(null);
  const [form] = Form.useForm();
  const intl = useIntl();
  useEffect(() => {
    getProduct(id)?.then((product) => {
      setProduct(product);
      // to reset for initial values
      form.resetFields();
    });
  }, [id]);

  const activationLabel = product?.isActive ? 'deactivate' : 'reactivate';

  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={
        <ActionRenderer
          path={`/app/${ProductsAppConfig.todixId}/${id}`}
          options={optionsMapper(['open', 'edit', 'delete'], intl)}
          handleDelete={() => {
            deleteProduct(id)
              ?.then(() => {
                // message.success(
                //   intl.formatMessage({
                //     id: 'products.deleteproduct.success',
                //     defaultMessage: 'Product deleted',
                //   }),
                // );
              })
              .catch(() => {
                message.error(
                  intl.formatMessage({
                    id: 'products.deleteproduct.error',
                    defaultMessage: 'Error while contact creating',
                  }),
                );
              });
          }}
          extraOptions={[
            {
              children: activationLabel,
              onClick: () => {
                Modal.confirm({
                  content: intl.formatMessage(
                    {
                      id: 'products.sidecontent.confirmation',
                      defaultMessage:
                        'Are you sure you want to {action} this item?',
                    },
                    { action: activationLabel },
                  ),
                  onOk: () => {
                    patchProduct(id, {
                      isActive: !product?.isActive,
                    })
                      .then(() => {
                        // message.success(
                        //   intl.formatMessage({
                        //     id: 'products.patchproduct.success',
                        //     defaultMessage: 'Product updated',
                        //   }),
                        // );
                      })
                      .catch(() => {
                        message.error(
                          intl.formatMessage({
                            id: 'products.patchproduct.error',
                            defaultMessage: 'Error while updating product',
                          }),
                        );
                      });
                  },
                });
              },
            },
          ]}
          intl={intl}
        />
      }
    >
      <TabPane
        key="1"
        tab={
          <>
            <DashboardOutlined />{' '}
            <FormattedMessage
              id="products.sidecontent.basetab"
              defaultMessage="Base Tab"
            />
          </>
        }
      >
        {!product && <SpinContainer />}
        {!!product &&
          (() => {
            const { productCreationDate, isActive } = product;
            return (
              <Form
                form={form}
                initialValues={{
                  ...product,
                  productCreationDate: moment(
                    productCreationDate,
                    moment.defaultFormat,
                  ),
                }}
                colon={false}
                labelAlign="left"
                {...formLayout}
              >
                <Form.Item
                  name="ecosystem"
                  label={
                    <FormattedMessage
                      id="products.sidecontent.generalinformation.ecosystem.label"
                      defaultMessage="Ecosystem"
                    />
                  }
                >
                  <Select
                    options={getEcosystemsOptions()}
                    showArrow={false}
                    bordered={false}
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  name="productCreationDate"
                  label={
                    <FormattedMessage
                      id="products.sidecontent.generalinformation.productcreationdate.label"
                      defaultMessage="Creation date"
                    />
                  }
                >
                  <DatePicker format={moment.defaultFormat} disabled />
                </Form.Item>
                <Form.Item
                  name="isActive"
                  label={
                    <FormattedMessage
                      id="products.sidecontent.generalinformation.isactive.label"
                      defaultMessage="Active / Inactive"
                    />
                  }
                >
                  {isActive ? (
                    <FormattedMessage
                      id="products.sidecontent.generalinformation.isactive.active"
                      defaultMessage="Active"
                    />
                  ) : (
                    <FormattedMessage
                      id="products.sidecontent.generalinformation.isactive.inactive"
                      defaultMessage="Inactive"
                    />
                  )}
                </Form.Item>
                <Form.Item
                  name="productNumber"
                  label={
                    <FormattedMessage
                      id="products.sidecontent.generalinformation.productnumber.label"
                      defaultMessage="Product Number"
                    />
                  }
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="productName"
                  label={
                    <FormattedMessage
                      id="products.sidecontent.generalinformation.productname.label"
                      defaultMessage="Product Name"
                    />
                  }
                >
                  <Input readOnly />
                </Form.Item>
              </Form>
            );
          })()}
      </TabPane>
      {/* <TabPane
        key="2"
        tab={
          <>
            <HistoryOutlined />{' '}
            <FormattedMessage
              id="products.sidecontent.timeline"
              defaultMessage="Timeline"
            />
          </>
        }
      /> */}
    </Tabs>
  );
};
