import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const Settings = lazy(
  () => import(/* webpackChunkName: "warehouseApp" */ './Settings'),
);

export default {
  appSubmenuName: 'app.warehouse',
  icon: Icon.RocketOutlined,
  sections: [
    {
      link: 'warehouse_settings',
      title: 'settings.warehouse.manage',
      component: Settings,
    },
  ],
};
