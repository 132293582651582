import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Item } = Form;

const Order: FC = () => {
  return (
    <Item
      name="orderId"
      label={
        <FormattedMessage id="warehouse.create.order" defaultMessage="Order" />
      }
      className="animate__animated animate__slideInRight animate__faster"
    >
      <Select
        placeholder={
          <FormattedMessage
            id="warehouse.create.order.placeholder"
            defaultMessage="Order..."
          />
        }
      ></Select>
    </Item>
  );
};

export default Order;
