import { LayoutField } from '@apps/documents/service/namespace';
import { getDocumentTypes } from '@services/dictionary';

export const CommonFieldsLayout: LayoutField[] = [
  {
    fieldName: 'ecosystem',
    fieldType: 'ecosystemSelect',
    label: 'documents.commonfields.ecosystem.label',
    //
    rules: [
      { required: true, message: 'documents.commonfields.ecosystem.error' },
    ],
  },
  {
    fieldName: 'category',
    fieldType: 'radioGroup',
    label: 'documents.commonfields.category.label',
    rules: [
      { required: true, message: 'documents.commonfields.category.error' },
    ],
    options: [
      {
        value: 'incoming',
        label: 'documents.commonfields.category.incoming',
      },
      {
        value: 'outgoing',
        label: 'documents.commonfields.category.outgoing',
      },
      {
        value: 'none',
        label: 'documents.commonfields.category.none',
      },
    ],
  },
  {
    fieldName: 'type',
    fieldType: 'selectSearch',
    label: 'documents.commonfields.type.label',
    rules: [{ required: true, message: 'documents.commonfields.type.error' }],
    options: getDocumentTypes(),
  },
  {
    fieldName: 'creatingUser',
    fieldType: 'user',
    readOnly: true,
    label: 'documents.commonfields.creatinguserid.label',
  },
  {
    fieldName: 'uploadingUser',
    fieldType: 'user',
    readOnly: true,
    label: 'documents.commonfields.uploadingUser.label',
  },
  {
    fieldName: 'relatedContactSnapshot',
    fieldType: 'relatedContactSnapshot',
    readOnly: true,
    label: 'documents.commonfields.relatedcontact.label',
  },
  {
    fieldName: 'creationDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.commonfields.creationdate.label',
    type: 'date',
    placeholder: 'documents.commonfields.creationdate.placeholder',
  },
  {
    fieldName: 'uploadDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.commonfields.uploaddate.label',
    type: 'date',
    placeholder: 'documents.commonfields.uploaddate.placeholder',
  },
  {
    fieldName: 'origin',
    fieldType: 'input',
    label: 'documents.commonfields.origin.label',
    readOnly: true,
    style: {
      border: 0,
    },
  },
  {
    fieldName: 'fileDetails',
    fieldType: 'separatedValues',
    label: 'documents.commonfields.filedetails.label',
    readOnly: true,
    style: {
      border: 0,
    },
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    placeholder: 'documents.commonfields.notes.label',
    wrapperStyle: {
      gridColumn: '1 / span 2',
    },
  },
];

export const SideViewCommonFieldsLayout: LayoutField[] = [
  {
    fieldName: 'ecosystem',
    fieldType: 'ecosystemSelect',
    label: 'documents.commonfields.sideview.ecosystem.label',
    readOnly: true,
  },
  {
    fieldName: 'category',
    fieldType: 'input',
    label: 'documents.commonfields.sideview.category.label',
    readOnly: true,
  },
  {
    fieldName: 'type',
    fieldType: 'input',
    label: 'documents.commonfields.sideview.type.label',
    readOnly: true,
  },
  {
    fieldName: 'creatingUser',
    fieldType: 'user',
    label: 'documents.commonfields.sideview.creatinguserid.label',
    readOnly: true,
  },
  {
    fieldName: 'uploadingUser',
    fieldType: 'uploadingUser',
    readOnly: true,
    label: 'documents.commonfields.uploadingUser.label',
  },
  {
    fieldName: 'creationDate',
    fieldType: 'input',
    label: 'documents.commonfields.sideview.creationdate.label',
    type: 'date',
    readOnly: true,
  },
  {
    fieldName: 'uploadDate',
    fieldType: 'input',
    label: 'documents.commonfields.sideview.uploaddate.label',
    type: 'date',
    readOnly: true,
  },
  {
    fieldName: 'origin',
    fieldType: 'input',
    label: 'documents.commonfields.sideview.origin.label',
    readOnly: true,
  },
  {
    fieldName: 'fileDetails',
    fieldType: 'separatedValues',
    label: 'documents.commonfields.sideview.filedetails.label',
    readOnly: true,
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];
