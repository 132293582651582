import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const ProjectsSettings = lazy(
  () => import(/* webpackChunkName: "projectsApp" */ './ProjectsSettings'),
);

export default {
  appSubmenuName: 'app.projects.mainMenu.name',
  icon: Icon.ScheduleOutlined,
  sections: [
    {
      link: 'projects_settings',
      title: 'settings.projects.manage',
      component: ProjectsSettings,
    },
  ],
};
