import React, { FC } from 'react';
import { Form, Input } from 'antd';
const { Item } = Form;
import { FormItemProps } from 'antd';

type Props = FormItemProps & {
  placeholder?: string;
  type?: any;
  readOnly?: boolean;
};

const ProductDetailsInput: FC<Props> = ({
  placeholder,
  type = 'text',
  readOnly = false,
  ...rest
}) => {
  return (
    <Item {...rest}>
      <Input placeholder={placeholder} type={type} disabled={readOnly} />
    </Item>
  );
};

export default ProductDetailsInput;
