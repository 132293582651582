import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import fetchWarehouseStoragesForEcosystem from '@apps/warehouse/services/fetchWarehouseStoragesForEcosystem';
import { WarehouseStorage } from '@apps/warehouse/services/namespace';

const { Option } = Select;

type StorageSelectorProps = SelectProps & {
  selectedEcosystem: string | null;
  onStorageSelect?(storage: WarehouseStorage): void;
};

const StorageSelector: FC<StorageSelectorProps> = ({
  selectedEcosystem,
  onStorageSelect,
  ...rest
}) => {
  const [storages, setStorages] = useState<WarehouseStorage[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (selectedEcosystem) {
      setLoading(true);
      fetchWarehouseStoragesForEcosystem(selectedEcosystem)
        ?.then(setStorages)
        ?.finally(() => {
          setLoading(false);
        });
    }
  }, [selectedEcosystem]);
  const options = useMemo(() => {
    return storages.map((storage, index) => {
      return (
        <Option key={index} value={storage.id}>
          {storage.name}
        </Option>
      );
    });
  }, [storages]);
  const handleSelect = useCallback(
    (storageId: string) => {
      const storage = storages.find((storage) => storage.id === storageId);
      if (storage && onStorageSelect) {
        onStorageSelect(storage);
      }
    },
    [storages, onStorageSelect],
  );

  return (
    <Select
      {...rest}
      disabled={!selectedEcosystem || loading}
      loading={loading}
      onSelect={(value) => handleSelect(value as string)}
    >
      {options}
    </Select>
  );
};

export default StorageSelector;
