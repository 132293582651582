import React, { useState, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getTask, Task } from '@apps/tasks/services';
import { DetailedTask } from '../../components/DetailedTask';

export const Preview: FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const [task, setTask] = useState<Task | null>(null);

  useEffect(() => {
    getTask(id).then(setTask);
  }, [id]);

  if (!task) return null;

  return (
    <DetailedTask id={id} initialValues={task} readOnly action="view-tasks" />
  );
};
