import React, { CSSProperties, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { useIntl } from 'react-intl';
import SpinContainer from '@components/SpinContainer';
import FormWithButtons from '@components/FormWithReminder/FormWithButtons';
import { layout } from '@components/AppEntityForm/constants';
import { Space } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';

export const ON_FINISH_FAILED_EVENT = 'onFinishFailed';
export type OnFinishFailedEvent = {
  errorInfo: ValidateErrorEntity;
};

export type FormProps = {
  elId?: string;
  appId: string;
  initialValues: any;
  onSubmit?: (v: any) => void;
  onCancel?: () => void;
  readOnly?: boolean;
  columns?: boolean;
  onFieldsChange?: any;
  onValuesChange?: any;
  onFinishFailed?(errorInfo: ValidateErrorEntity): void;
  providedForm?: any;
  children: (arg: any) => any;
  className?: string;
  name?: string;
  autoComplete?: string;
  disabledSubmitButton?: boolean;
  disabledEditButton?: boolean;
  style?: CSSProperties | undefined;
  hideButtons?: boolean;
  editLink?: string;
  backLink?: string;
  cancelLink?: string;
  shouldRedirect?: boolean;
};

// Used for every app create/edit/preview
export const AppEntityForm: FC<FormProps> = ({
  elId,
  appId,
  name,
  initialValues: initialValuesProp,
  onSubmit,
  onCancel,
  readOnly = true,
  columns = true,
  providedForm,
  onFieldsChange,
  onValuesChange,
  onFinishFailed,
  children,
  autoComplete = 'off',
  disabledSubmitButton = false,
  disabledEditButton = false,
  style,
  className,
  hideButtons = false,
  editLink,
  backLink,
  cancelLink,
  shouldRedirect = true,
}) => {
  const history = useHistory();
  const [form] = useForm();
  const intl = useIntl();
  if (initialValuesProp === null) {
    return <SpinContainer />;
  }

  const initialValues = {
    ...initialValuesProp,
  };

  return (
    <FormWithButtons
      autoComplete={autoComplete}
      initialValues={initialValues}
      name={name || appId}
      form={providedForm || form}
      onValuesChange={onValuesChange}
      onFinish={(values) => {
        if (onSubmit) {
          onSubmit(values);
        }
      }}
      onFinishFailed={onFinishFailed}
      readOnly={readOnly}
      readonlyCancelButton={{
        label: intl.formatMessage({
          id: 'button.back',
          defaultMessage: 'Back',
        }),
        onClick: () => {
          if (shouldRedirect) {
            history.push(backLink || `/app/${appId}`);
          }
        },
      }}
      readonlySubmitButton={{
        label: intl.formatMessage({
          id: 'button.edit',
          defaultMessage: 'Edit',
        }),
        onClick: () => {
          if (elId && !disabledEditButton) {
            history.push(editLink || `/app/${appId}/${elId}/edit`);
          }
        },
        disabled: disabledEditButton,
      }}
      onFieldsChange={() => {
        if (onFieldsChange) {
          onFieldsChange(form.getFieldsValue());
        }
      }}
      cancelButton={{
        label: intl.formatMessage({
          id: 'button.cancel',
          defaultMessage: 'Cancel',
        }),
        onClick: () => {
          if (shouldRedirect) {
            history.push(cancelLink || `/app/${appId}`);
          }
          if (onCancel) {
            onCancel();
          }
        },
      }}
      submitButton={{
        label: intl.formatMessage({
          id: 'button.save',
          defaultMessage: 'Save',
        }),
        disabled: disabledSubmitButton,
      }}
      {...layout}
      style={style}
      className={className}
      hideButtons={hideButtons}
    >
      <div
        style={
          columns
            ? {
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
                overflow: 'auto',
              }
            : {}
        }
      >
        <Space
          direction="vertical"
          style={{ paddingRight: 10, flex: 1 }}
          size="small"
        >
          {children(form)}
        </Space>
      </div>
    </FormWithButtons>
  );
};
