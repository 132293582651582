import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { InputNumber, Form } from 'antd';
import {
  transformFloat,
  transformInt,
} from '@apps/directSales/settings/Commissions/components/commonHelpers';
import { currencySigns } from '@services/currency';

const { Item } = Form;

type Props = {
  intl: IntlShape;
};

const ProductBonus: FC<Props> = ({ intl }) => {
  return (
    <Item
      label={intl.formatMessage({
        id: 'commissions.productBonus',
        defaultMessage: 'Product bonus',
      })}
    >
      <Item
        name={['productBonus', 'coupon']}
        style={{
          display: 'inline-block',
          width: 'calc(40% - 8px)',
          margin: '0 10px 0 0',
        }}
        normalize={transformFloat}
        rules={[
          {
            type: 'number',
            min: 0,
            transform: transformFloat,
          },
        ]}
      >
        <InputNumber
          min={0}
          step={0.01}
          type="number"
          placeholder={intl.formatMessage({
            id: 'commissions.productBonus.coupon',
            defaultMessage: 'Coupon',
          })}
          addonAfter={currencySigns['EUR']}
        />
      </Item>
      <Item
        name={['productBonus', 'perEachCommission']}
        style={{ display: 'inline-block', width: 'calc(60% - 8px)' }}
        normalize={transformFloat}
        rules={[
          {
            type: 'number',
            min: 0,
            transform: transformFloat,
          },
        ]}
      >
        <InputNumber
          min={0}
          step={0.01}
          type="number"
          placeholder={intl.formatMessage({
            id: 'commissions.productBonus.perEachCommission',
            defaultMessage: 'Per each commission',
          })}
          addonAfter={currencySigns['EUR']}
        />
      </Item>
      <Item
        name={['productBonus', 'maxCoupon']}
        style={{ display: 'inline-block', width: 'calc(40% - 8px)' }}
        normalize={transformInt}
        rules={[
          {
            type: 'number',
            min: 0,
            transform: transformInt,
          },
        ]}
      >
        <InputNumber
          type="number"
          step={1}
          min={0}
          onChange={transformInt}
          placeholder={intl.formatMessage({
            id: 'commissions.productBonus.maxCoupon',
            defaultMessage: 'Max coupons',
          })}
        />
      </Item>
    </Item>
  );
};

export default ProductBonus;
