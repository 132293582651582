import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { Input, Form } from 'antd';

const { Item } = Form;

type Props = {
  intl: IntlShape;
};

const RuleNameInput: FC<Props> = ({ intl }) => {
  return (
    <Item
      label={intl.formatMessage({
        id: 'commissions.rule.name',
        defaultMessage: 'Rule name',
      })}
      name="name"
      required
      rules={[{ required: true }]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'commissions.rule.name',
          defaultMessage: 'Rule name',
        })}
      />
    </Item>
  );
};

export default RuleNameInput;
