import React from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

const ContactType = ({ readOnly }: { readOnly?: boolean }) => (
  <Form.Item
    name="type"
    label={
      <FormattedMessage
        id="documentprocessing.step2.contacttype.label"
        defaultMessage="Type of Contact"
      />
    }
    rules={
      readOnly
        ? undefined
        : [
            {
              required: true,
              message: (
                <FormattedMessage
                  id="documentprocessing.step2.contacttype.error.required"
                  defaultMessage="Missing contact type"
                />
              ),
            },
          ]
    }
  >
    <Select disabled={readOnly}>
      <Option value="company">
        <FormattedMessage
          id="documentprocessing.step2.contacttype.option.company"
          defaultMessage="Company"
        />
      </Option>
      <Option value="person">
        <FormattedMessage
          id="documentprocessing.step2.contacttype.option.person"
          defaultMessage="Person"
        />
      </Option>
    </Select>
  </Form.Item>
);

export default ContactType;
