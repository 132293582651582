import React, { useEffect, useState, useCallback } from 'react';
import { message, Tabs } from 'antd';
import { useStore } from 'react-redux';
import { DashboardOutlined } from '@ant-design/icons';
import { Text } from '@todix/ui-components';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import { SideContentProps } from '@components/AppView';
import SpinContainer from '@components/SpinContainer';
import {
  deleteDocument,
  Document,
  getDocument,
  LayoutField,
  SelectLayoutField,
  DocumentType,
} from '@apps/documents/service';
import { getLayout } from '@apps/documents/service/layouts';
import FormWithReminder from '@components/FormWithReminder';
import LayoutRenderer from '@apps/documents/mainMenu/LayoutRenderer';
import { injectEcosystemsOptions } from '@apps/documents/mainMenu/Details/Form/formInjectors';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl/lib';
import { getName } from '@core/helpers/documents';
import moment from 'moment';
import optionsMapper from '@core/helpers/optionsMapper';
import DocumentAppConfig from '@apps/documents';
import { emitCustomEvent } from '@core/services';
import MediaViewer from '@components/MediaViewer';

const formLayout = {
  wrapperCol: {
    span: 12,
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 10,
    },
    xxl: {
      span: 6,
    },
    offset: 0,
  },
};

const { TabPane } = Tabs;

type HandleLayout = (layout: LayoutField[]) => void;

const SideContent: React.FC<SideContentProps> = ({
  id,
  hideActionsInSideContent = false,
}) => {
  const [details, setDetails] = useState<Document | null>(null);
  const [layout, setLayout] = useState<LayoutField[]>([]);
  const store = useStore();
  const intl = useIntl();

  const handleDocumentSideContent = useCallback(
    (id: string, handleLayout?: HandleLayout) => {
      getDocument(id)?.then((response) => {
        const layout = getLayout(response.type, false, 'sideView');
        let processedResponse = {
          ...response,
          creationDate: moment(response.creationDate).format(
            moment.defaultFormat,
          ),
        };
        const documentLayout = layout
          .filter((field) => field.fieldName !== 'category')
          .map((field) => {
            if (field.fieldType === 'ecosystemSelect') {
              const { ecosystems } = store.getState();
              return injectEcosystemsOptions(
                ecosystems,
                field as SelectLayoutField,
              );
            }

            if (field.fieldName === 'type') {
              processedResponse = {
                ...processedResponse,
                [field.fieldName]: intl.formatMessage({
                  id: `documents.documentTypes.${response.type}`,
                  defaultMessage: response.type,
                }) as DocumentType,
              };
            }

            return field;
          });
        setDetails(processedResponse);
        setLayout(documentLayout);

        if (handleLayout) {
          handleLayout(documentLayout);
        }
      });
    },
    [intl, store],
  );

  const handleDelete = useCallback(() => {
    deleteDocument(id)
      ?.then(() => {
        emitCustomEvent('documentRemoved', {
          id,
        });
      })
      .catch(() => {
        message.error(
          intl.formatMessage({
            id: 'products.deletedocument.error',
            defaultMessage: 'Error while deleting document',
          }),
        );
      });
  }, [id, intl]);

  useEffect(() => {
    setDetails(null);
    handleDocumentSideContent(id);
  }, [handleDocumentSideContent, id]);

  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={
        hideActionsInSideContent ? null : (
          <ActionRenderer
            path={`/app/${DocumentAppConfig.todixId}/${id}`}
            options={optionsMapper(['open', 'delete'], intl)}
            handleDelete={handleDelete}
            intl={intl}
          />
        )
      }
    >
      <TabPane
        key="1"
        tab={
          <>
            <DashboardOutlined />{' '}
            <FormattedMessage
              id="documents.sidecontent.basetab"
              defaultMessage="Base Tab"
            />
          </>
        }
      >
        {details === null ? (
          <SpinContainer />
        ) : (
          <>
            <Text level={4} style={{ display: 'block', marginBottom: 36 }}>
              {getName(details, intl)}
            </Text>
            <MediaViewer
              items={[
                {
                  url: details.documentUrl,
                  thumbnailUrl: details.thumbnailUrl,
                  format: details.fileDetails.format || '',
                },
              ]}
              showPlayButton={false}
              showThumbnails={false}
            />
            {layout && details && (
              <FormWithReminder initialValues={details} {...formLayout}>
                <LayoutRenderer layoutFields={layout} />
              </FormWithReminder>
            )}
          </>
        )}
      </TabPane>
    </Tabs>
  );
};

export default SideContent;
