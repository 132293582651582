import React, { FC } from 'react';
import { Form } from 'antd';
import {
  FooterContentInput,
  FooterGrid,
} from '@apps/purchases/settings/operationSteps/style.sc';
import { FormattedMessage } from 'react-intl';
import intl from '../../../../../intl';
const { Item } = Form;

const FooterContent: FC = () => {
  const inputs = Array.from({ length: 4 }, (_v, i) => {
    return (
      <Item
        name={['footer', 'content', `${i}`]}
        key={i}
        rules={[
          {
            type: 'string',
            max: 115,
            message: (
              <FormattedMessage
                id="sales.operations.steps.limit"
                defaultMessage="This part can't be longer than 115 characters or more than 5 lines"
              />
            ),
            validator: (_rule, value) => {
              if (value.split('\n').length > 5) {
                return Promise.reject(
                  intl.formatMessage({
                    id: 'sales.operations.steps.limit',
                    defaultMessage:
                      "This part can't be longer than 115 characters or more than 5 lines",
                  }),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <FooterContentInput size="large" rows={4} showCount maxLength={115} />
      </Item>
    );
  });
  return <FooterGrid>{inputs}</FooterGrid>;
};

export default FooterContent;
