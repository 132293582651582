import React, { FC, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import DropdownWithFullScreenMode from '@components/DropdownWithFullScreenMode';
import Filters from '@apps/documents/mainMenu/components/Filters';
import { Filter, FilterWithInboxes } from '@apps/documents/mainMenu/Upload';
import { FormattedMessage } from 'react-intl';

// TODO: improve types
type ResourceFilterProps = {
  filters: any;
  resources: any;
  activeEcosystems: Ecosystem[];
  onFiltersChange(data: any): void;
  selectedRow: string | null;
  onResourcesChange(resources: any): void;
  setSelectedRow: (resources: any) => void;
  element: Element;
};

export const getFilteredResources = (
  filters: FilterWithInboxes[],
  resources: any,
  activeEcosystems: Ecosystem[],
) => {
  const filteredEcosystems = filters
    .filter((el: any) => el.inFilter)
    .map((el: any) => el.id);

  const filteredInboxes = filters
    .reduce((acc: Filter[], ecosystem: any) => {
      acc.push(...ecosystem.inboxes);
      return acc;
    }, [])
    .filter((el: any) => el.inFilter)
    .map((el: any) => el.id);

  return resources
    .filter((r: any) =>
      activeEcosystems.map((e: any) => e.id).includes(r.ecosystem),
    )
    .filter((r: any) => filteredInboxes.includes(r.inbox))
    .filter((r: any) => filteredEcosystems.includes(r.ecosystem));
};

const ResourceFilter: FC<ResourceFilterProps> = ({
  filters,
  resources,
  activeEcosystems,
  onFiltersChange,
  selectedRow,
  onResourcesChange,
  setSelectedRow,
  element,
}) => {
  const [showFilters, setShowFilters] = useState(false);

  const handleVisibleChange = useCallback((flag: boolean) => {
    setShowFilters(flag);
  }, []);

  const handleChange = useCallback(
    (data: FilterWithInboxes[]) => {
      onFiltersChange(data);

      const resourcesAfterFiltersApplied = getFilteredResources(
        data,
        resources,
        activeEcosystems,
      );

      const resourcesAfterFiltersAppliedIds = resourcesAfterFiltersApplied.map(
        (el: FilterWithInboxes) => el.id,
      );

      // to hide sideview with unknown data
      if (resourcesAfterFiltersApplied.length === 0) {
        setSelectedRow(null);
      } else if (
        selectedRow === null ||
        !resourcesAfterFiltersAppliedIds.includes(selectedRow)
      ) {
        setSelectedRow(resourcesAfterFiltersApplied[0].id);
      }

      onResourcesChange(resourcesAfterFiltersApplied);
    },
    [
      activeEcosystems,
      onFiltersChange,
      onResourcesChange,
      resources,
      selectedRow,
      setSelectedRow,
    ],
  );

  // TODO: this is an antipattern and should be replace in the near future
  return createPortal(
    <DropdownWithFullScreenMode
      overlay={<Filters filters={filters} onChange={handleChange} />}
      trigger={['click']}
      visible={showFilters}
      onVisibleChange={handleVisibleChange}
      placement="bottomRight"
    >
      <Button
        type="dashed"
        size="small"
        icon={<FilterOutlined style={{ marginRight: '8px' }} />}
        onClick={() => {
          setShowFilters(!showFilters);
        }}
      >
        <FormattedMessage
          id="appview.resourcefilter.filter"
          defaultMessage="Filter"
        />
      </Button>
    </DropdownWithFullScreenMode>,
    element,
  );
};

export default ResourceFilter;
