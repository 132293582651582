import React, { FC, lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const SalesList = lazy(
  () => import(/* webpackChunkName: "salesApp" */ './salesList'),
);

const Create = lazy(
  () => import(/* webpackChunkName: "salesApp" */ './routes/create'),
);

const Edit = lazy(
  () => import(/* webpackChunkName: "salesApp" */ './routes/edit'),
);

const Sales: FC<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`}>
        <Create />
      </Route>
      <Route exact path={`${path}/:id`}>
        <Edit />
      </Route>
      <Route path={`${path}`}>
        <SalesList />
      </Route>
    </Switch>
  );
};

export default Sales;
