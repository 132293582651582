import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Card, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import {
  StyledDeleteIcon,
  StyledEditIcon,
  StyledTable,
} from '@pages/settings/components';
import { styled } from '@styles/themes';
import UserFieldModal from '@apps/products/settings/UserFields/UserFieldModal';
import { ColumnType } from 'antd/lib/table';
import {
  deleteProductUserField,
  getEcosystemProductUserFields,
  UserField,
} from '@apps/products/services';

const StyledButton = styled(Button)`
  && {
    flex-direction: row-reverse;

    .anticon {
      margin-left: 4px;
    }
  }
`;

const UserFields: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userFields, setUserFields] = useState<UserField[]>([]);
  const selectedUserField = useRef<UserField | undefined>(undefined);

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getEcosystemProductUserFields(uid)
        ?.then(setUserFields)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const handleOk = useCallback(() => {
    setVisible(false);
    selectedUserField.current = undefined;
    setLoading(true);
    getEcosystemProductUserFields(uid)
      ?.then(setUserFields)
      .finally(() => {
        setLoading(false);
      });
  }, [uid]);

  const handleCancel = useCallback(() => {
    setVisible(false);
    selectedUserField.current = undefined;
  }, []);

  const handleUserFieldDelete = useCallback(
    async (id: string) => {
      setLoading(true);
      await deleteProductUserField(id);
      getEcosystemProductUserFields(uid)
        ?.then(setUserFields)
        .finally(() => {
          setLoading(false);
        });
    },
    [uid],
  );

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="settings.products.userFields.creationModal.label.label"
            defaultMessage="Label"
          />
        ),
        sorter: (a, b) => a.label.localeCompare(b.label),
        dataIndex: 'label',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.products.userFields.creationModal.type.label"
            defaultMessage="Type"
          />
        ),
        sorter: (a, b) => a.type.localeCompare(b.type),
        dataIndex: 'type',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: UserField) => (
          <>
            <StyledEditIcon
              onClick={() => {
                selectedUserField.current = record;
                setVisible(true);
              }}
            />
            <StyledDeleteIcon
              onClick={() => handleUserFieldDelete(record.id || '')}
            />
          </>
        ),
      },
    ],
    [handleUserFieldDelete],
  );

  return (
    <Card
      title={
        <FormattedMessage
          id="settings.products.userFields.menuTitle"
          defaultMessage="User fields"
        />
      }
      extra={
        <StyledButton
          type="primary"
          size="small"
          onClick={() => setVisible(true)}
        >
          <FormattedMessage
            id="settings.products.userFields.createNewButton"
            defaultMessage="settings.products.userFields.createNewButton"
          />
        </StyledButton>
      }
    >
      <StyledTable
        dataSource={userFields}
        columns={columns}
        bordered
        tableLayout="auto"
        loading={loading}
        pagination={false}
        rowKey="id"
      />
      <UserFieldModal
        ecosystemId={uid}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        selectedUserField={selectedUserField.current}
      />
    </Card>
  );
};

export default UserFields;
