import JSZIP from 'jszip';
import getCommissionPeriodReports from '@apps/directSales/services/commisions/getCommissionPeriodReports';

type Payload = {
  period: string;
  ecosystemId: string;
};

const downloadReports = async ({
  ecosystemId,
  period,
}: Payload): Promise<string | null | Blob> => {
  try {
    const periodReports = await getCommissionPeriodReports(ecosystemId, period);
    if (periodReports && periodReports?.links?.length) {
      const jsZip = new JSZIP();
      const links = periodReports.links.map((entry) => {
        return fetch(entry.link)
          .then((response) => {
            if (response.status === 200 || response.status === 0) {
              return Promise.resolve(response.blob());
            } else {
              return Promise.reject(new Error(response.statusText));
            }
          })
          .then((blob) => {
            return jsZip.file(
              `${entry.consultantId}_${period}_${ecosystemId}.pdf`,
              blob,
            );
          });
      });

      await Promise.all(links);
      return jsZip.generateAsync({ type: 'blob' });
    }
    return Promise.resolve('');
  } catch (e) {
    console.warn(e);
    return Promise.resolve(null);
  }
};

export default downloadReports;
