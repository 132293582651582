import {
  Invitation,
  INVITATION_COLLECTION,
} from '@services/invitations/namespace';
import { firebase } from '@services/Firebase';

const getInvitation = (id: string): Promise<Invitation | null> | undefined => {
  return firebase.db
    ?.ref(`${INVITATION_COLLECTION}/${id}`)
    .once('value')
    .then((result) => {
      if (result.exists()) {
        return {
          ...result.val(),
          id: result.key,
        } as Invitation;
      }
      return null;
    });
};

export default getInvitation;
