import styled from 'styled-components';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InputStyled = styled(Input)<InputProps>`
  color: ${(props) => props.theme.colors.text.normal};
  && {
    ::placeholder,
    ::-webkit-input-placeholder {
      color: ${(props) => props.theme.colors.text.gray};
    }
    :-ms-input-placeholder {
      color: ${(props) => props.theme.colors.text.gray};
    }
  }
`;
