import {
  ProdTotalsSummary,
  SalesFormValues,
} from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';

const calculateTotalsForProducts = (
  products: SalesFormValues['products'],
): ProdTotalsSummary => {
  const total = products?.reduce((acc, item) => {
    if (item.totalGrossPrice !== undefined) {
      return acc + item.totalGrossPrice;
    }
    return acc;
  }, 0);
  const totalNetPrice = products?.reduce((acc, item) => {
    if (item.price !== undefined && item.amount !== undefined) {
      return acc + Number(Number(item.price * item.amount));
    }
    return acc;
  }, 0);
  const totalDiscountedPrice = products?.reduce((acc, item) => {
    if (item.discountedPrice !== undefined && item.amount !== undefined) {
      return acc + Number(Number(item.discountedPrice * item.amount));
    }
    return acc;
  }, 0);
  const taxes = products?.reduce((acc: Record<any, number>, item) => {
    if (
      item.taxValue !== undefined &&
      item.totalGrossPrice !== undefined &&
      item.totalNetPrice !== undefined
    ) {
      const prodsInTax = acc[item.taxValue];
      const totalTaxValue = Number(
        Number(item.totalGrossPrice - item.totalNetPrice),
      );
      return {
        ...acc,
        [item.taxValue]:
          prodsInTax !== undefined ? prodsInTax + totalTaxValue : totalTaxValue,
      };
    }
    return acc;
  }, {});
  return {
    total,
    totalNetPrice,
    totalDiscountedPrice,
    taxes,
  };
};

export default calculateTotalsForProducts;
