import { useState, useEffect } from 'react';
import { RangeOfNumbersType } from '@services/rangeOfNumbers';
import getRangeOfNumbersByEcosystem from '@apps/purchases/services/rangeOfNumbers/getPurchasesRangeOfNumbersByEcosystem';

const usePurchasesRangeOfNumbers = (id: string) => {
  const [rangeOfNumbers, setRangeOfNumbers] =
    useState<null | RangeOfNumbersType>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    getRangeOfNumbersByEcosystem(id)
      ?.then((el) => {
        setRangeOfNumbers(el);
      })
      ?.finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  return [isLoading, rangeOfNumbers, setRangeOfNumbers] as [
    boolean,
    null | RangeOfNumbersType,
    any,
  ];
};

export default usePurchasesRangeOfNumbers;
