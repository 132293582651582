import React, { FC, useState, useCallback } from 'react';
import { UserField } from '@apps/products/services';
import { Form, Space, Select, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import intl from '../../../../../../../intl';
const { Item } = Form;

type FieldSelectorProps = {
  userFields: UserField[];
  add(payload?: any): void;
};

const FieldSelector: FC<FieldSelectorProps> = ({ add, userFields }) => {
  const [selectedFieldId, setSelectedFieldId] = useState<string>('');
  const handleAdd = useCallback(
    (id: string) => {
      const foundedField = userFields.find((field) => field.id === id);
      if (foundedField) {
        add(foundedField);
        setSelectedFieldId('');
      }
    },
    [add, userFields],
  );
  return (
    <Item>
      <Space>
        <Select onChange={setSelectedFieldId} style={{ minWidth: '160px' }}>
          {userFields?.map((field) => {
            return (
              <Select.Option key={field.id} value={field.id}>
                {field.label}
              </Select.Option>
            );
          })}
        </Select>
        <Button
          type="dashed"
          onClick={() => handleAdd(selectedFieldId)}
          disabled={!selectedFieldId}
          block
          icon={<PlusOutlined />}
        >
          {intl.formatMessage({
            id: 'button.add.field',
            defaultMessage: 'Add field',
          })}
        </Button>
      </Space>
    </Item>
  );
};

export default FieldSelector;
