import { deleteRequest } from '../api';
import { firebase } from '@core/services/Firebase';
import { fetchUser } from '@services/users';
import { Invitation } from '@services/invitations/namespace';
import removeLicencesForUserInEcosystem from '@services/licences/removeLicencesForUserInEcosystem';
import { RootStore, rootStore } from '@core/store';
import { message } from 'antd';
import { formatMessage } from '../../../intl';

const COLLECTION_NAME = 'ecosystems';

const ECOSYSTEM_RELATIONS_COLLECTION = 'ecosystemsRelations';

export const getEcosystems = async (uids: string[]) => {
  const t = uids.map((uid) => {
    return firebase.db
      ?.ref(`${COLLECTION_NAME}/${uid}`)
      .once('value')
      .then((d) => (d.val() ? { ...d.val(), id: d.key } : null));
  });

  const rdbEcosystems = await Promise.all(t).then((eco) =>
    eco.filter((ec) => ec !== null),
  );

  return [...rdbEcosystems];
};

export const getEcosystem = (uid: string) => {
  return firebase?.db
    ?.ref(`${COLLECTION_NAME}/${uid}`)
    .once('value')
    .then((res) => {
      return res.val() as Ecosystem;
    });
};

export const addMember = async (invitation: Invitation) => {
  await firebase.db?.ref(ECOSYSTEM_RELATIONS_COLLECTION).push({
    memberId: invitation.recipientId,
    ecosystem: invitation.ecosystem,
    role: 'member',
    isActive: true,
  } as EcosystemRelation);
};

export const addRelation = async (relation: EcosystemRelation) => {
  await firebase.db?.ref(ECOSYSTEM_RELATIONS_COLLECTION).push(relation);
};

export const changeRelation = async (
  relation: EcosystemRelation,
  id: string,
) => {
  await firebase.db
    ?.ref(`${ECOSYSTEM_RELATIONS_COLLECTION}/${id}`)
    .set(relation);
};

export const getEcosystemRelationsEcosystem = (
  ecosystem: string,
): Promise<EcosystemRelation[]> | undefined => {
  return firebase.db
    ?.ref(ECOSYSTEM_RELATIONS_COLLECTION)
    .orderByChild('ecosystem')
    .equalTo(ecosystem)
    .once('value')
    .then((results) => {
      const records = results.val() as Record<string, EcosystemRelation>;
      if (!records) {
        return [];
      }
      return Object.entries(records).map((entry) => ({
        ...entry[1],
        id: entry[0],
      }));
    });
};

export const getEcosystemRelationsByMemberId = (
  memberId: string,
): Promise<EcosystemRelation[]> | undefined => {
  return firebase.db
    ?.ref(ECOSYSTEM_RELATIONS_COLLECTION)
    .orderByChild('memberId')
    .equalTo(memberId)
    .once('value')
    .then((results) => {
      const records = results.val() as Record<string, EcosystemRelation>;
      if (!records) {
        return [];
      }
      return Object.entries(records).map((entry) => ({
        ...entry[1],
        id: entry[0],
      }));
    });
};

export const getMembers = (ecosystem: string) => {
  return firebase.db
    ?.ref(ECOSYSTEM_RELATIONS_COLLECTION)
    .orderByChild('ecosystem')
    .equalTo(ecosystem)
    .once('value')
    .then((results) => {
      const records = results.val() as Record<string, EcosystemRelation>;
      if (!records) {
        return [];
      }
      const entries = Object.entries(records).map((entry) => ({
        ...entry[1],
        id: entry[0],
      }));
      const membersPromises = entries.map((entry) => {
        return fetchUser(entry.memberId).then((user) => {
          return {
            ...user,
            role: entry.role,
            isActive: entry.isActive,
            relationId: entry.id,
            id: entry.memberId,
          };
        });
      });

      return Promise.all(membersPromises) as unknown as Promise<
        EcosystemMember[]
      >;
    });
};

export const removeEcosystemMember = async (
  uid: string,
  memberUid: string,
): Promise<string | null | undefined> => {
  return firebase.db
    ?.ref(ECOSYSTEM_RELATIONS_COLLECTION)
    .orderByChild('ecosystem')
    .equalTo(uid)
    .once('value')
    .then((result) => {
      const records = result.val() as Record<string, EcosystemRelation>;
      if (records && memberUid) {
        const relationToRemove = Object.entries(records)
          .map((entry) => {
            return {
              ...entry[1],
              id: entry[0],
            };
          })
          .find((entry) => entry.memberId === memberUid);
        if (relationToRemove) {
          return firebase.db
            ?.ref(`${ECOSYSTEM_RELATIONS_COLLECTION}/${relationToRemove.id}`)
            .remove()
            .then(async () => {
              await removeLicencesForUserInEcosystem({
                ecosystem: relationToRemove.ecosystem,
                ownerId: relationToRemove.memberId,
              });
              return relationToRemove.id;
            });
        }
        return null;
      }
      return null;
    });
};

export const postEcosystem = async (ecosystem: Ecosystem) => {
  const state: RootStore = rootStore.getState();

  if (!state.user.data) {
    return null;
  }

  const user = state.user.data;
  const userEcosystems = state.ecosystems.filter(
    (eco) => eco.ownerUid === user.uid,
  );

  if (userEcosystems.length >= user.ecosystemLimit) {
    message.error(formatMessage('ecosystem.limit'));
    return null;
  }

  return firebase?.db
    ?.ref(`${COLLECTION_NAME}`)
    .push(ecosystem)
    .then((res) => {
      return res.key;
    });
};

export const patchEcosystem = (id: string, ecosystem: Partial<Ecosystem>) => {
  return firebase?.db?.ref(`${COLLECTION_NAME}/${id}`).update(ecosystem);
};

export const deleteEcosystem = (id: string) =>
  deleteRequest(`${COLLECTION_NAME}/${id}`);
