import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import dateFilter from '@core/helpers/dateFilter';
import { ColSpanParams } from 'ag-grid-community/dist/lib/entities/colDef';

export const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="ecosystem"
      headerName={intl.formatMessage({
        id: 'tasks.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      cellRenderer="ecosystemRenderer"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      filter="ecosystemFilter"
    />
    <AgGridColumn
      field="title"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'tasks.columns.title.header',
        defaultMessage: 'Title',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="assignee"
      headerName={intl.formatMessage({
        id: 'tasks.assignee.label',
        defaultMessage: 'Assignee',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
    />
    <AgGridColumn
      field="parents"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'tasks.columns.projects.header',
        defaultMessage: 'Projects',
      })}
      sortable
      filter={false}
      minWidth={160}
      cellRenderer="projectsCellRenderer"
    />
    <AgGridColumn
      field="creationDate"
      headerName={intl.formatMessage({
        id: 'tasks.columns.creationDate.header',
        defaultMessage: 'Creation Date',
      })}
      sortable
      minWidth={210}
      filterParams={dateFilter}
      filter={'agDateColumnFilter'}
    />
    <AgGridColumn
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      field="creator.displayName"
      headerName={intl.formatMessage({
        id: 'products.columns.creator.header',
        defaultMessage: 'Creator',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="progress"
      filter="progressFilter"
      headerName={intl.formatMessage({
        id: 'tasks.columns.progress.header',
        defaultMessage: 'Progress',
      })}
      sortable
      cellRenderer="progressRenderer"
      minWidth={160}
    />
    <AgGridColumn
      field="dueDate"
      headerName={intl.formatMessage({
        id: 'tasks.columns.dueDate.header',
        defaultMessage: 'Due Date',
      })}
      sortable
      minWidth={210}
      filterParams={dateFilter}
      filter={'agDateColumnFilter'}
    />
    <AgGridColumn
      field="estimation"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'tasks.form.estimation.label',
        defaultMessage: 'Estimation (h)',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="status"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'tasks.form.status.label',
        defaultMessage: 'Status',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="followers"
      headerName={intl.formatMessage({
        id: 'tasks.followers.label',
        defaultMessage: 'Followers',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
    />
    <AgGridColumn cellRenderer="actionRenderer" maxWidth={70} />
  </>
);
