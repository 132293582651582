import {
  HistoryTotalEntry,
  WAREHOUSE_PRODUCTS_STATUS_HISTORY,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';

type Payload = {
  ecosystem: string;
  storageId: string;
  productId: string;
};

const fetchProductStatsHistory = ({
  ecosystem,
  storageId,
  productId,
}: Payload) => {
  return firebase.db
    ?.ref(
      `${WAREHOUSE_PRODUCTS_STATUS_HISTORY}/${ecosystem}/${storageId}/${productId}`,
    )
    .once('value')
    .then((results) => {
      if (!results.exists()) {
        return Promise.resolve([] as HistoryTotalEntry[]);
      }

      let entries: HistoryTotalEntry[] = [];
      results.forEach((snap) => {
        const snapVal = snap.val();
        entries = [...entries, snapVal as HistoryTotalEntry];
      });

      return entries;
    });
};

export default fetchProductStatsHistory;
