import React, { FC, useContext } from 'react';
import { GridTwoColumns } from '@apps/sales/widgets/SalesTab/styles.sc';
import TaxCategory from '@apps/sales/widgets/SalesTab/Details/TaxCategory';
import ProductUnitSelector from '@apps/sales/widgets/SalesTab/Details/ProductUnitSelector';
import ProductDetailsInput from '@apps/sales/widgets/SalesTab/Details/ProductDetailsInput';
import { FormattedMessage } from 'react-intl';
import ProductDetailsToggle from '@apps/sales/widgets/SalesTab/Details/ProductDetailsToggle';
import SalesTabContext from '@apps/sales/widgets/SalesTab/context';

const Details: FC = () => {
  const { readOnly, ecosystem } = useContext(SalesTabContext);
  return (
    <GridTwoColumns>
      <div>
        <ProductUnitSelector
          readOnly={readOnly}
          ecosystemId={ecosystem?.id}
          name={['saleDetails', 'salesUnit']}
          label={
            <FormattedMessage
              id="sales.salesTab.salesUnit.label"
              defaultMessage="Sales unit"
            />
          }
        />
        <ProductDetailsInput
          name={['saleDetails', 'amountPerUnit']}
          type="number"
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.amountPerUnit.label"
              defaultMessage="Amount per unit"
            />
          }
        />
        <ProductDetailsInput
          name={['saleDetails', 'weight']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.weight.label"
              defaultMessage="Weight"
            />
          }
        />
        <ProductDetailsInput
          name={['saleDetails', 'volume']}
          readOnly={readOnly}
          type="number"
          label={
            <FormattedMessage
              id="sales.salesTab.volume.label"
              defaultMessage="Volume"
            />
          }
        />
        <ProductDetailsInput
          name={['saleDetails', 'minAmount']}
          readOnly={readOnly}
          type="number"
          label={
            <FormattedMessage
              id="sales.salesTab.minAmount.label"
              defaultMessage="Min amount"
            />
          }
        />
        <ProductDetailsToggle
          name={['saleDetails', 'isActive']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="settings.list.header.status"
              defaultMessage="Status"
            />
          }
        />
        <ProductDetailsToggle
          name={['saleDetails', 'baseUnitRequired']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.baseUnitRequired.label"
              defaultMessage="Base unit required"
            />
          }
        />
        <ProductDetailsToggle
          name={['saleDetails', 'noDeliveryCost']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.noDeliveryCost.label"
              defaultMessage="No delivery cost"
            />
          }
        />
        <ProductDetailsToggle
          name={['saleDetails', 'withExtraBox']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.withExtraBox.label"
              defaultMessage="With extra box"
            />
          }
        />
        <ProductDetailsToggle
          name={['saleDetails', 'isTariffNumberChecked']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.isTariffNumberChecked.label"
              defaultMessage="Tariff number check"
            />
          }
        />
      </div>
      <div>
        <ProductUnitSelector
          readOnly={readOnly}
          ecosystemId={ecosystem?.id}
          name={['saleDetails', 'unitBasePrice']}
          label={
            <FormattedMessage
              id="sales.salesTab.unitBasePrice.label"
              defaultMessage="Unit base price"
            />
          }
        />
        <ProductDetailsInput
          name={['saleDetails', 'amountBaseUnit']}
          type="number"
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.amountBaseUnit.label"
              defaultMessage="Amount base unit"
            />
          }
        />
        <TaxCategory
          readOnly={readOnly}
          ecosystemId={ecosystem?.id as string}
        />
        <ProductDetailsInput
          name={['saleDetails', 'content']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.content.label"
              defaultMessage="Content"
            />
          }
        />
        <ProductDetailsInput
          name={['saleDetails', 'warrantyMonths']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.warrantyMonths.label"
              defaultMessage="Warranty (months)"
            />
          }
        />
        <ProductDetailsInput
          name={['saleDetails', 'deliveryTimeDays']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.deliveryTimeDays.label"
              defaultMessage="Delivery time (days)"
            />
          }
        />
        <ProductDetailsInput
          name={['saleDetails', 'boxId']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.boxId.label"
              defaultMessage="Box ID"
            />
          }
          dependencies={['saleDetails', 'withExtraBox']}
        />
        <ProductDetailsInput
          name={['saleDetails', 'tariffNumber']}
          readOnly={readOnly}
          label={
            <FormattedMessage
              id="sales.salesTab.tariffNumber.label"
              defaultMessage="Tariff number"
            />
          }
          dependencies={['saleDetails', 'isTariffNumberChecked']}
        />
      </div>
    </GridTwoColumns>
  );
};

export default Details;
