import { get, put, ApiOptions, patch } from '../api';
import { firebase } from '@services/Firebase';

const COLLECTION_NAME = 'users';

export type UserWithId = CommonUserData & { id: string };

export type UserWithIdToInvite = UserWithId & {
  toInvite: boolean;
};

export const patchUser = (
  uid: string,
  user: Partial<User>,
  options?: ApiOptions,
) => patch(`${COLLECTION_NAME}/${uid}`, user, options);

export const setUser = (uid: string, user: Partial<User>) =>
  put(`${COLLECTION_NAME}/${uid}`, user);

export const fetchUser = (uid: string) =>
  get(`${COLLECTION_NAME}/${uid}`) as Promise<User>;

export const updateUser = (
  id: string,
  user: Partial<User>,
  options?: ApiOptions,
) => put(`${COLLECTION_NAME}/${id}`, user, options);

export const searchUserByDisplayName = (search: string) => {
  // TODO: later we should add .limit() and .startAfter() for supporting pagination
  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .where('keywords', 'array-contains', search.toLowerCase())
    .get()
    .then((querySnapshot) => {
      const docs: UserWithId[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...(doc.data() as User),
          id: doc.id,
        });
      });
      return docs;
    });
};
