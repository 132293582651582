import React, { FC } from 'react';
import { Form, Modal, Input } from 'antd';
import intl from '../../../../../intl';
import { useEffect } from 'react';
import {
  addUnitGroup,
  createUnitGroups,
  updateUnitGroupName,
} from '@services/unitGroups';
import { useDispatch } from 'react-redux';
import { fetchUnitGroups } from '@core/layout/store/unitGroupsSaga/actions';
import { UnitGroup, UnitGroups } from '@services/unitGroups/namespace';

type UnitGroupFormModalProps = {
  ecosystemId: string;
  visible: boolean;
  unitGroups: UnitGroups;
  setVisible: (arg: boolean) => void;
  selectedUnitGroup: UnitGroup | undefined;
  setSelectedUnitGroup: (arg: UnitGroup | undefined) => void;
};

export const UnitGroupFormModal: FC<UnitGroupFormModalProps> = ({
  ecosystemId,
  visible,
  setVisible,
  unitGroups,
  selectedUnitGroup,
  setSelectedUnitGroup,
}) => {
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const initialValues = selectedUnitGroup
    ? selectedUnitGroup
    : { isActive: true };

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    <>
      <Modal
        visible={visible}
        title={intl.formatMessage({
          id: 'settings.products.groups.createNewButton',
          defaultMessage: 'Create new group',
        })}
        okText={
          selectedUnitGroup
            ? intl.formatMessage({
                id: 'settings.products.groups.modal.edit',
                defaultMessage: 'Edit',
              })
            : intl.formatMessage({
                id: 'settings.products.groups.modal.create',
                defaultMessage: 'Create',
              })
        }
        cancelText="Cancel"
        confirmLoading={confirmLoading}
        onCancel={() => {
          setVisible(false);
          if (selectedUnitGroup) {
            setSelectedUnitGroup(undefined);
          }
        }}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              setConfirmLoading(true);
              if (selectedUnitGroup) {
                await updateUnitGroupName(
                  values.name,
                  unitGroups,
                  selectedUnitGroup.name,
                  ecosystemId,
                );
                setSelectedUnitGroup(undefined);
              } else {
                if (!unitGroups) {
                  await createUnitGroups(ecosystemId);
                }
                await addUnitGroup({ ...values, units: [] }, ecosystemId);
              }
              form.resetFields();
              setConfirmLoading(false);
              setVisible(false);
              dispatch(fetchUnitGroups());
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initialValues}
        >
          <Form.Item
            name="name"
            label={intl.formatMessage({
              id: 'settings.products.groups.creationModal.name.label',
              defaultMessage: 'Name',
            })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'settings.products.groups.creationModal.name.error',
                  defaultMessage: 'Name field is required',
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
