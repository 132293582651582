import React, { FC } from 'react';
import { Form } from 'antd';
import { ItemNoMargin } from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import FloatNumberedField from '@components/FloatNumberedField';
import { NumericFormatProps } from 'react-number-format/types/types';

const { Item } = Form;

type Props = NumericFormatProps & {
  name: string;
  handleSave(): void;
};

const calculateValues = (
  name: string,
  amountValue: string,
  formValues: Record<string, any>,
) => {
  const { discountedPrice, grossPrice } = formValues;
  const amount =
    amountValue !== undefined && amountValue !== '' ? Number(amountValue) : 0;
  const totalNetPrice = Number(Number(discountedPrice * amount).toFixed(2));
  const totalGrossPrice = Number(Number(grossPrice * amount).toFixed(2));

  return {
    [name]: amount,
    totalNetPrice,
    totalGrossPrice,
  };
};

const Amount: FC<Props> = ({ name = 'amount', handleSave, ...inputProps }) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={name}>
            <FloatNumberedField
              {...inputProps}
              maxLength={5}
              min={0}
              onChange={(value) => {
                const newValues = calculateValues(name, value, formValues);
                const newFormValues = {
                  ...formValues,
                  ...newValues,
                };

                setFieldsValue(newFormValues);

                if (handleSave) {
                  handleSave();
                }
              }}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default Amount;
