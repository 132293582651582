import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Card, Switch, Tooltip } from 'antd';
import { styled } from '@styles/themes';
import { ColumnType } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';
import StyledTable from '@pages/settings/components/StyledTable';
import {
  DocumentType,
  getDocumentsTypesSettings,
  updateDocumentsTypesSettings,
} from '@apps/documents/service';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import defaultDocTypes from '@apps/documents/settings/defaultDocTypes';

type DocumentTypeSettings = {
  docType: DocumentType;
  isActive: boolean;
};

const StyledSwitch = styled(Switch)`
  width: 120px;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DocumentTypes: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const docId = useRef('');
  const [loading, setLoading] = useState(true);
  const [docTypes, setDocTypes] = useState<DocumentTypeSettings[]>([]);
  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  useEffect(() => {
    getDocumentsTypesSettings(uid)
      ?.then((results) => {
        setLoading(false);

        if (results) {
          docId.current = results.id;
          const docTypesNames = results.docTypes.map((entry) => entry.docType);
          const documentTypes = [
            ...new Set([
              ...results.docTypes,
              ...defaultDocTypes.filter(
                (entry) => !docTypesNames.includes(entry.docType),
              ),
            ]),
          ];
          setDocTypes(documentTypes);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [uid]);

  const handleOnChange = useCallback(
    (docType: DocumentType, value: boolean) => {
      setLoading(true);
      const newDocTypes = docTypes.map((setting) =>
        setting.docType === docType
          ? {
              ...setting,
              isActive: value,
            }
          : setting,
      );

      updateDocumentsTypesSettings(docId.current, {
        ecosystem: uid,
        docTypes: newDocTypes,
      })
        ?.then(() => {
          setLoading(false);
          setDocTypes(newDocTypes);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    },
    [docTypes, uid],
  );

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="settings.documents.documentTypes.list.header.documentType"
            defaultMessage="Document Type"
          />
        ),
        sorter: (a, b) => a.docType.localeCompare(b.docType),
        dataIndex: 'docType',
        ellipsis: true,
        render: (_v, record: DocumentTypeSettings) => (
          <FormattedMessage
            id={`documents.documentTypes.${record.docType}`}
            defaultMessage={`documents.documentTypes.${record.docType}`}
          />
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: DocumentTypeSettings) => (
          <Tooltip
            title={
              <FormattedMessage
                id="settings.documents.documentTypes.list.tooltip"
                defaultMessage="Activate to make this document type available to be set for new documents in your Inboxes"
              />
            }
          >
            <StyledSwitch
              key={record.docType}
              checked={record.isActive}
              onChange={(value) => handleOnChange(record.docType, value)}
              checkedChildren={activated}
              unCheckedChildren={deactivated}
            />
          </Tooltip>
        ),
      },
    ],
    [activated, deactivated, handleOnChange],
  );

  return (
    <Card
      title={
        <FormattedMessage
          id="settings.documents.documentTypes.title"
          defaultMessage="settings.documents.documentTypes.title"
        />
      }
    >
      <StyledTable
        dataSource={docTypes}
        columns={columns}
        bordered
        tableLayout="auto"
        loading={loading}
        pagination={{ pageSize: 10 }}
        rowKey="docType"
      />
    </Card>
  );
};

export default DocumentTypes;
