import React, { FC } from 'react';
import { Consultant } from '@apps/directSales/mainMenu/Consultants/services';
import MobileLicenseManager from '@apps/directSales/mainMenu/Consultants/components/MobileLicenceManager';

type Props = {
  data?: Partial<Consultant>;
};

const MobileLicenseCellRenderer: FC<Props> = ({ data }) => {
  return <MobileLicenseManager consultant={data} />;
};

export default MobileLicenseCellRenderer;
