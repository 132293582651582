import React, { FC } from 'react';
import { DatePicker, Input, InputNumber, Select } from 'antd';
import { UserField } from '@apps/products/services';
import moment from 'moment';

type FieldResolverProps = {
  onChange(value: string | number): void;
  userField: UserField;
  value: any;
  readOnly: boolean;
};

const FieldResolver: FC<FieldResolverProps> = ({
  userField,
  value,
  onChange,
  readOnly,
}) => {
  if (userField.type === 'text') {
    return (
      <Input
        type="text"
        value={value}
        onBlur={(e) => onChange(e.currentTarget.value)}
        disabled={readOnly}
      />
    );
  }
  if (userField.type === 'number') {
    return (
      <InputNumber
        type="number"
        value={value}
        onBlur={(e) => {
          onChange(e.currentTarget.value);
        }}
        disabled={readOnly}
      />
    );
  }
  if (userField.type === 'datepicker') {
    return (
      <DatePicker
        format={moment.defaultFormat}
        value={value ? moment(value, moment.defaultFormat) : moment()}
        onSelect={(value) => onChange(value.format(moment.defaultFormat))}
        disabled={readOnly}
      />
    );
  }
  if (userField.type === 'dropdown') {
    const options = userField.options?.map((option, index) => (
      <Select.Option key={index} value={option.value}>
        {option.label}
      </Select.Option>
    ));
    return (
      <Select
        defaultValue={value}
        onChange={(value) => onChange(value)}
        disabled={readOnly}
      >
        {options}
      </Select>
    );
  }
  return <div />;
};

export default FieldResolver;
