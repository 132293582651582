import React from 'react';
import { Form, Select } from 'antd';
import intl from '../../../../../../../intl';
import { PriceModel } from '@apps/products/services';

const { Item } = Form;

const PricingModel: React.FC = () => {
  const options = React.useMemo(() => {
    return (['standard', 'package'] as PriceModel[]).map((mode, index) => ({
      key: index,
      value: mode,
      label: intl.formatMessage({
        id: `sales.salesTab.priceMode.${mode}`,
        defaultMessage: `sales.salesTab.priceMode.${mode}`,
      }),
    }));
  }, []);
  return (
    <Item
      name="model"
      label={intl.formatMessage({
        id: 'sales.salesTab.pricingMode.label',
        defaultMessage: 'Pricing mode',
      })}
    >
      <Select options={options} />
    </Item>
  );
};

export default PricingModel;
