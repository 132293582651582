// based on: https://raw.githubusercontent.com/HarshRohila/react-custom-events/master/src/index.tsx
import { useEffect } from 'react';

declare global {
  interface Window {
    _customEventTargetElement: undefined | HTMLDivElement;
  }
}

const getElement = () => {
  if (!window._customEventTargetElement) {
    window._customEventTargetElement = document.createElement('div');
  }

  return window._customEventTargetElement;
};

const useCustomEventListener = <ET extends string, VT = undefined>(
  eventName: ET,
  eventHandler: (data?: VT) => void,
) => {
  useEffect(() => {
    const element = getElement();
    const handleEvent = (event: CustomEvent | Event) => {
      const data = (event as CustomEvent).detail;
      eventHandler(data);
    };

    if (eventName) {
      element.addEventListener(eventName, handleEvent, false);
    }

    return eventName
      ? () => {
          element.removeEventListener(eventName, handleEvent, false);
        }
      : () => {};
  });
};

const emitCustomEvent = <ET extends string, VT = {}>(
  eventName: ET,
  data?: VT,
) => {
  const element = getElement();
  const event = new CustomEvent(eventName, { detail: data });
  element.dispatchEvent(event);
};

export { useCustomEventListener, emitCustomEvent };
