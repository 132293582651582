import currency from 'currency.js';
import currencySigns from './currencySigns';

const getValueWithCurrency = (value: number | string, currencyCode: string) =>
  currency(value, {
    precision: 2,
    symbol: currencySigns[currencyCode],
    decimal: ',',
  });

export default getValueWithCurrency;
