import { StripeProduct } from '@services/stripe/namespace';
import { firebase } from '@services/Firebase';
import { LICENCES } from '@services/licences/namespace';

type Payload = {
  memberId: string;
  product: StripeProduct;
  ecosystemId: string;
  subscription: string;
};

const createLicenceForMember = ({
  memberId,
  subscription,
  product,
  ecosystemId,
}: Payload): Promise<Licence> | undefined => {
  const newLicence: Licence = {
    appId: product.id,
    appName: product.name,
    subscription,
    status: 'active',
    acl: 'ALL',
    ecosystem: ecosystemId,
    ownerId: memberId,
    blocked: false,
  };
  return firebase?.db
    ?.ref(`${LICENCES}/${ecosystemId}`)
    .push(newLicence)
    .then((result) => ({
      ...newLicence,
      id: result.key as string,
    }));
};

export default createLicenceForMember;
