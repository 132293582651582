export const supportedFileTypes = {
  requestForQuotation: 'RQT',
  order: 'OR',
  supplierOrderConfirmation: 'SORC',
  upfrontInvoice: 'UINV',
  incomingDeliveryNote: 'IDN',
  goodsReciept: 'GR',
  invoice: 'INV',
  creditNote: 'CN',
  deliveryReminder: 'DR',
};

export const MIN_LENGTH_SELECT: Record<string, number> = {
  '2-digits': 2,
  '3-digits': 3,
  '4-digits': 4,
  '5-digits': 5,
  '6-digits': 6,
};
