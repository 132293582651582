import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@styles/themes';
import Modal, { ModalProps } from '@components/Modal';
import { Button, Spin } from 'antd';
import { FormattedMessage } from 'react-intl/lib';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import removeCartService from '@services/cart/removeCart';
import CartEntriesTable from '@components/Cart/CartEntriesTable';
import { CartEntry } from '@services/cart/namespace';
import { StripePrice, StripeProduct } from '@services/stripe/namespace';
import stripe from '@services/stripe';
import { removeCart } from '@core/store/cart';
import { getStripeSettings } from '@services/platform';

export const StyledModal = styled(Modal)`
  && {
    .ant-modal-body {
      margin: 24px;
      padding: 0;
    }
  }
`;

type CartModalProps = Omit<ModalProps, 'footer'> & {
  cart: CartEntry[];
};

const CartModal: FC<CartModalProps> = ({ cart, children, ...rest }) => {
  const { data: user, isLoading } = useSelector(getUser);
  const [stripePrices, setStripePrices] = useState<StripePrice[]>([]);
  const [stripeProducts, setStripeProducts] = useState<StripeProduct[]>([]);
  const [isPricesFetching, setIsPricesFetching] = useState(false);
  const [isProductsFetching, setIsProductsFetching] = useState(false);
  const [isBuying, setIsBuying] = useState(false);
  const dispatch = useDispatch();
  const [showTrial, setShowTrial] = useState(false);
  const stripeSettings = useRef<StripeSettings | null>(null);

  useEffect(() => {
    if (cart) {
      const trialInit = async () => {
        const ecosystemId = [...new Set(cart.map((el) => el.ecosystem))][0];
        if (user && ecosystemId) {
          const subs = await stripe.getActiveSubscriptions({
            userId: user?.uid,
            ecosystemId,
          });
          const stripeSet = await getStripeSettings();
          stripeSettings.current = stripeSet
            ? stripeSet
            : {
                trialPeriodDays: 30,
              };

          if (
            subs.length < 1 ||
            subs.filter((sub) => sub.status === 'trialing').length ||
            subs.filter(
              (sub) => sub.trial_end === null && sub.trial_start === null,
            ).length === subs.length
          ) {
            //calculate trial end
            const sub = subs.find((sub) => sub.status === 'trialing');
            if (sub && sub.trial_end && stripeSettings.current) {
              stripeSettings.current.trialEnd = sub.trial_end;
            }
            setShowTrial(true);
          } else {
            setShowTrial(false);
          }
        }
      };
      trialInit();

      const pricesPromises = cart.map((entry) =>
        stripe.getPrice(entry.priceId),
      );
      const productPromises = cart.map((entry) =>
        stripe.getProduct(entry.productId),
      );
      setIsPricesFetching(true);
      Promise.all(pricesPromises)
        .then((prices) => {
          setStripePrices(prices);
        })
        .finally(() => {
          setIsPricesFetching(false);
        });
      setIsProductsFetching(true);
      Promise.all(productPromises)
        .then((products) => {
          setStripeProducts(products);
        })
        .finally(() => {
          setIsProductsFetching(false);
        });
    }
  }, [cart, user]);

  const handleCancel = useCallback(
    (e) => {
      if (user && !isLoading) {
        removeCartService(user.uid).then(() => {
          dispatch(removeCart());
          if (rest.onCancel) {
            rest.onCancel(e);
          }
        });
      }
    },
    [user, isLoading, dispatch, rest],
  );

  const handleBuy = useCallback(() => {
    if (user) {
      setIsBuying(true);
      stripe
        .createCheckoutSession({
          userId: user?.uid,
          cancelUrl: `${window.location.origin}`,
          successUrl: `${window.location.origin}/stripe_success`,
          setTrial: showTrial,
        })
        ?.then((sessionUrl) => {
          if (sessionUrl) {
            window.location.href = sessionUrl;
          }
        })
        .finally(() => {
          setIsBuying(false);
        });
    }
  }, [showTrial, user]);

  const isFetching = isProductsFetching || isPricesFetching;

  return (
    <StyledModal
      {...rest}
      maskClosable={false}
      closable={!isBuying}
      title={<FormattedMessage id="your.cart" defaultMessage="Your cart" />}
      footer={[
        <Button key="removeCart" onClick={handleCancel} disabled={isFetching}>
          <FormattedMessage
            id="button.remove.cart"
            defaultMessage="Remove cart"
          />
        </Button>,
        <Button
          key="buy"
          type="primary"
          onClick={handleBuy}
          disabled={isFetching}
        >
          <FormattedMessage id="button.buy" defaultMessage="Buy" />
        </Button>,
      ]}
    >
      <Spin spinning={isBuying}>
        <CartEntriesTable
          showTrial={showTrial}
          stripeSettings={stripeSettings.current}
          cart={cart}
          stripePrices={stripePrices}
          stripeProducts={stripeProducts}
          isFetching={isFetching}
        />
      </Spin>
    </StyledModal>
  );
};

export default CartModal;
