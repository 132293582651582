import React, { useState } from 'react';
import { message, Card } from 'antd';
import { RcFile } from 'antd/lib/upload';
import UploadModal from './components/UploadModal';
import { parseToCommissions } from './utils/parseToCommission';
import { createSaleCommission } from '@apps/directSales/services/commisions/createSaleCommission';
import intl from '../../../../../intl';

interface ICommissionImportProps {
  ecosystemId: Ecosystem['id'];
}

const CommissionImport: React.FC<ICommissionImportProps> = ({
  ecosystemId,
}) => {
  const [fileLoading, setFileLoading] = useState(false);

  const handleFileUpload = async (file: RcFile) => {
    try {
      setFileLoading(true);

      const fileContent = await file.text();

      const commissions = parseToCommissions(fileContent, ecosystemId);
      await createSaleCommission(commissions, ecosystemId);
    } catch (error) {
      message.error(
        intl.formatMessage({
          id: 'consultants.import.consultant.error.json',
          defaultMessage: 'consultants.import.consultant.error.json',
        }) + ` ${error.message}`,
      );
    }

    setFileLoading(false);
  };

  return (
    <Card title="Import Commissions" style={{ width: '100%' }}>
      <UploadModal
        description={intl.formatMessage({
          id: 'consultants.import.consultant.json.upload.description',
          defaultMessage:
            'consultants.import.consultant.json.upload.description',
        })}
        disabled={fileLoading}
        fileAccept=".json"
        handleFileUpload={handleFileUpload}
        textOnLoading={intl.formatMessage({
          id: 'consultants.import.consultant.json.loading.isUploading',
          defaultMessage:
            'consultants.import.consultant.json.loading.isUploading',
        })}
      />
    </Card>
  );
};

export default CommissionImport;
