import { PageLayout } from '@apps/sales/services';

const commonSections: PageLayout = [
  {
    field: 'logoWithName',
    readOnly: true,
    style: {
      fontSize: '11px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
  },
  {
    field: 'ecosystemAddress',
    readOnly: true,
    style: {
      fontSize: '12px',
      position: 'relative',
      marginTop: '10px',
      textAlign: 'right',
    },
  },
  {
    field: 'docNumber',
    readOnly: true,
    style: {
      fontSize: '12px',
      position: 'relative',
      fontWeight: 'bold',
    },
  },
  {
    field: 'contactDetails',
    style: {
      display: 'flex',
      fontSize: '12px',
      position: 'relative',
      marginTop: '20px',
      marginBottom: '20px',
      minWidth: '280px',
      maxWidth: '280px',
    },
  },
  {
    field: 'orderId',
    style: {
      fontSize: '12px',
      position: 'relative',
      fontWeight: 'bold',
      maxWidth: '200px',
      alignSelf: 'flex-end',
      marginTop: '-26px',
    },
  },
  {
    field: 'products',
    style: {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '12px',
      marginTop: '20px',
    },
  },
  {
    field: 'deliveryTerms',
    style: {
      fontSize: '12px',
      minWidth: '240px',
    },
  },
  {
    field: 'paymentTerms',
    style: {
      fontSize: '12px',
      minWidth: '240px',
    },
  },
  {
    field: 'footer',
    style: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '12px',
    },
  },
];

export default commonSections;
