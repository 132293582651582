import { FilterEmailObject } from '@services/filtering/index';
import get from 'lodash.get';
import { Email } from '@apps/contacts/services';

const emailMatcher = (
  filterObject: FilterEmailObject,
  row: any,
  key: string,
) => {
  if (filterObject.type === 'contains') {
    const emails = get(row, key) as Email[];
    return emails
      ? emails.filter((entry) =>
          entry.email.toLowerCase().includes(filterObject?.filter || ''),
        ).length > 0
      : false;
  }

  return false;
};

export default emailMatcher;
