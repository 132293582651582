import {
  Invitation,
  INVITATION_COLLECTION,
} from '@services/invitations/namespace';
import { firebase } from '@services/Firebase';

const updateInvitation = async (invitation: Invitation) => {
  await firebase.db?.ref(`${INVITATION_COLLECTION}/${invitation.id}`).update({
    ...invitation,
  });
};

export default updateInvitation;
