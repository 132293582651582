import React, { FC, useState } from 'react';
import { FormProps } from 'antd/lib/form';
import { useForm } from 'antd/lib/form/Form';
import { FormContext } from '@components/FormWithReminder/context';
import UnsavedChangesPrompt from '@components/UnsavedChangesPrompt';
import removeUndefined from '@core/helpers/removeUndefined';
import { StyledForm } from '@components/FormWithReminder/FormWithReminder.sc';

export type FormWithReminderProps = FormProps & {
  readOnly?: boolean;
};

const FormWithReminder: FC<FormWithReminderProps> = ({
  children,
  onFieldsChange: passedOnFieldsChange,
  onFinish: passedOnFinish,
  readOnly = false,
  ...restProps
}) => {
  const [formRef] = useForm();

  const form = restProps.form ? restProps.form : formRef;

  const [isDirty, setIsDirty] = useState(form.isFieldsTouched());

  return (
    <>
      <UnsavedChangesPrompt when={isDirty} />
      <FormContext.Provider value={{ ...form, readOnly }}>
        <StyledForm
          {...restProps}
          form={form}
          onFinish={(values: any) => {
            setIsDirty(false);
            passedOnFinish && passedOnFinish(removeUndefined(values));
          }}
          onFieldsChange={(changedFields, allFields) => {
            if (!isDirty) {
              setIsDirty(true);
            }

            passedOnFieldsChange &&
              passedOnFieldsChange(changedFields, allFields);
          }}
        >
          {children}
        </StyledForm>
      </FormContext.Provider>
    </>
  );
};

export default FormWithReminder;
