import {
  Commission,
  COMMISSIONS_SETTINGS_COLLECTION_NAME,
} from '@apps/directSales/services/commisions';
import { post } from '@services/api';
import { removeUndefinedProperties } from '@services/utils';
import moment from 'moment';
export const createCommission = (commission: Commission) =>
  post(`${COMMISSIONS_SETTINGS_COLLECTION_NAME}`, {
    ...removeUndefinedProperties(commission),
    timestamp: moment().valueOf(),
  });
