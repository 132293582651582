import React, { FC, useState, useEffect, lazy, Suspense } from 'react';
import { connectedApps } from '@apps/connectedApps';
import SpinContainer from '@components/SpinContainer';

type WidgetResolverProps = {
  appName: string;
  widgetName: string;
  injectedProps?: any;
};

const WidgetResolver: FC<WidgetResolverProps> = ({
  appName,
  widgetName,
  injectedProps,
}) => {
  const [Component, SetComponent] = useState<React.ElementType | null>(null);

  useEffect(() => {
    const app = connectedApps.find((app) => app.name === appName);
    if (app && app.widgets) {
      const widgetConfig = app.widgets
        .flat()
        .find((widget) => widget?.name === widgetName);

      if (widgetConfig) {
        SetComponent(
          lazy(() => {
            return import(
              /* webpackChunkName: "widgets" */ `apps/${widgetConfig.path}`
            ).catch(() => {
              return Promise.resolve(null);
            });
          }),
        );
      }
    }
  }, [appName, widgetName]);

  return (
    <Suspense fallback={<SpinContainer />}>
      {Component && <Component {...injectedProps} />}
    </Suspense>
  );
};

export default WidgetResolver;
