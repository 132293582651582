import React, { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@todix/ui-components';

const { Item } = Form;

type ActivationToggleProps = {
  readOnly: boolean;
};

const ActivationToggle: FC<ActivationToggleProps> = ({ readOnly }) => {
  return (
    <Item
      name="toBeStocked"
      label={
        <FormattedMessage id="to.be.stocked" defaultMessage="to be stocked" />
      }
      valuePropName="checked"
    >
      <Toggle disabled={readOnly} />
    </Item>
  );
};

export default ActivationToggle;
