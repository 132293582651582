import {
  WAREHOUSE_HISTORY,
  WarehouseHistoryEntry,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';

type Payload = {
  ecosystem: string;
  storageId: string;
  filter: {
    field:
      | 'flowType'
      | 'timestamp'
      | 'storageId'
      | 'numberDeliveryNote'
      | 'productId';
    value: number | string;
  };
};

const fetchFilteredHistoryForStorage = ({
  ecosystem,
  storageId,
  filter,
}: Payload) => {
  return firebase.db
    ?.ref(`${WAREHOUSE_HISTORY}/${ecosystem}/${storageId}`)
    .orderByChild(filter.field)
    .equalTo(filter.value)
    .once('value')
    .then((results) => {
      if (!results.exists()) {
        return Promise.resolve([] as WarehouseHistoryEntry[]);
      }

      let entries: WarehouseHistoryEntry[] = [];
      results.forEach((snap) => {
        const snapVal = snap.val();
        entries = [...entries, snapVal as WarehouseHistoryEntry];
      });

      return entries;
    });
};

export default fetchFilteredHistoryForStorage;
