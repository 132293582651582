import { getWithId } from '@services/api';
import {
  SERIAL_NUMBERS,
  SerialNumberEntry,
} from '@apps/warehouse/services/namespace';

const getSerialNumber = (id: string) =>
  getWithId(`${SERIAL_NUMBERS}/${id}`) as Promise<SerialNumberEntry>;

export default getSerialNumber;
