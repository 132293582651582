import React, { CSSProperties, FC } from 'react';
import { FooterGrid } from '@apps/purchases/mainMenu/components/styles.sc';
import { PurchaseOperationSettings } from '@apps/purchases/services/purchaseOperation';
import { FormattedMessage } from 'react-intl';
import intl from '../../../../../intl';
import { TextAreaInput } from '@apps/purchases/settings/operationSteps/style.sc';
import { Form } from 'antd';
const { Item } = Form;

type FooterProps = {
  style?: CSSProperties;
  user?: User | null;
  ecosystem?: Ecosystem;
  settings: PurchaseOperationSettings | null;
  readOnly?: boolean;
};

const Footer: FC<FooterProps> = ({ style, readOnly }) => {
  const inputs = Array.from({ length: 4 }, (_v, i) => {
    return (
      <Item
        name={['footer', 'content', `${i}`]}
        key={i}
        rules={[
          {
            type: 'string',
            max: 115,
            message: (
              <FormattedMessage
                id="sales.operations.steps.limit"
                defaultMessage="This part can't be longer than 115 characters or more than 5 lines"
              />
            ),
            validator: (_rule, value) => {
              if (value?.split('\n').length > 5) {
                return Promise.reject(
                  intl.formatMessage({
                    id: 'sales.operations.steps.limit',
                    defaultMessage:
                      "This part can't be longer than 115 characters or more than 5 lines",
                  }),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <TextAreaInput
          size="large"
          rows={4}
          showCount
          maxLength={115}
          readOnly={readOnly}
        />
      </Item>
    );
  });
  return <FooterGrid style={style}>{inputs}</FooterGrid>;
};

export default Footer;
