import { styled } from '@styles/themes';
import { Text } from '@todix/ui-components';
import { Input } from 'antd';
const { TextArea } = Input;

export const StyledSectionTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.text.gray};
  font-weight: 600;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
`;

export const FooterContentInput = styled(TextArea)`
  min-width: 240px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const TabIconContainer = styled.div`
  background-color: #8c8c8c;
  padding: 5px;

  & > * {
    margin: 0 !important;
  }
`;
