import { jsPDF } from 'jspdf';
import { TemplateProps } from '@apps/sales/mainMenu/components/pdfTemplates/namespace';

const addOrderId = (
  doc: jsPDF,
  props: TemplateProps,
  lastYPosition: number,
) => {
  const { intl, values } = props;
  const { orderId } = values;
  if (orderId) {
    const documentNumber = `${intl.formatMessage({
      id: `sales.orderId`,
      defaultMessage: 'sales.orderId',
    })}: ${orderId}`;
    doc.setFont('times', 'normal', 700);
    doc.setFontSize(13);
    doc.text(documentNumber, 140, lastYPosition);
  }
};

export default addOrderId;
