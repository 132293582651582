import React, { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import InputField from '@components/InputField';
import DateField from '@components/DateField';
import ConsultantContext from '@apps/directSales/mainMenu/Consultants/components/formContext';

const LeftColumn: FC = () => {
  const { readOnly } = useContext(ConsultantContext);
  return (
    <div>
      <InputField
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantName',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantName.header"
              defaultMessage="Consultant Name"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantSurname',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantSurname.header"
              defaultMessage="Consultant Surname"
            />
          ),
        }}
      />
      <InputField
        type="email"
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantEmail',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantEmail.header"
              defaultMessage="Consultant Email"
            />
          ),
        }}
      />
      <DateField
        disabled={readOnly}
        formItemProps={{
          name: 'consultantBirthday',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantBirthday.header"
              defaultMessage="Consultant Birthday"
            />
          ),
        }}
      />
      <DateField
        disabled={readOnly}
        formItemProps={{
          name: 'applicationDate',
          label: (
            <FormattedMessage
              id="consultants.columns.applicationDate.header"
              defaultMessage="Consultant application Date"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        type={'tel'}
        formItemProps={{
          required: true,
          name: 'consultantPhone',
          label: (
            <FormattedMessage
              id="consultants.consultant.phone"
              defaultMessage="Consultant Phone"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantStreet',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantStreet.header"
              defaultMessage="Consultant Street"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantStreetNo',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantStreetNo.header"
              defaultMessage="Consultant Street No"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantZip',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantZip.header"
              defaultMessage="Consultant ZIP"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantCity',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantCity.header"
              defaultMessage="Consultant City"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantNo',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantNo.header"
              defaultMessage="Consultant No"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          required: true,
          name: 'consultantTaxNo',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantTaxNo.header"
              defaultMessage="Consultant Tax No"
            />
          ),
        }}
      />
    </div>
  );
};

export default LeftColumn;
