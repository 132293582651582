import React, { FC, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { getConsultant } from '@apps/directSales/mainMenu/Consultants/services';

type Props = {
  value?: string;
};

const ConsultantParentCellRenderer: FC<Props> = ({ value }) => {
  const [loading, setLoading] = useState(false);
  const [parentName, setParentName] = useState('');

  useEffect(() => {
    if (value) {
      setLoading(true);
      getConsultant(value)
        .then((consultant) => {
          if (consultant) {
            setParentName(
              `${consultant.consultantName || ''} ${
                consultant.consultantSurname || ''
              }`,
            );
          }
        })
        ?.finally(() => {
          setLoading(false);
        });
    }
  }, [value]);

  return <Spin spinning={loading}>{parentName}</Spin>;
};

export default ConsultantParentCellRenderer;
