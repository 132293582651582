import React, { FC } from 'react';
import { useAllActivatedFileApps } from '@core/helpers/apps';
import WidgetResolver from '@components/WidgetResolver';
import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';

const DashboardWidgets: FC = () => {
  const allActivatedFileApps = useAllActivatedFileApps();
  const activeEcosystems = useSelector(getActiveEcosystems);

  return (
    <>
      {activeEcosystems.length > 0 &&
        allActivatedFileApps.map((app) => (
          <WidgetResolver
            key={app.todixId}
            appName={app.name}
            widgetName="DashboardWidget"
            injectedProps={{}}
          />
        ))}
    </>
  );
};

export default DashboardWidgets;
