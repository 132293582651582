import React, { FC, useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Card } from '@todix/ui-components';
import Tabs from '@components/Tabs';
import AppConfig, { PROJECTS_ACTION } from '@apps/projects';
import { DashboardOutlined, NodeIndexOutlined } from '@ant-design/icons';
import ProjectForm from '@apps/projects/mainMenu/components/ProjectForm';
import getProjectById from '@apps/projects/services/getProjectById';
import { Project } from '@apps/projects/services';
import Relations from '@components/Relations';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';

const { TabPane } = Tabs;

type Props = {
  action: PROJECTS_ACTION;
};

const ProjectDetails: FC<Props> = ({ action }) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const firstActiveEcosystemId = useFirstActiveEcosystemId(
    AppConfig.todixId,
    action,
  );
  const [ecosystem, setEcosystem] = useState(firstActiveEcosystemId);
  const [project, setProject] = useState<Project | null>(null);

  const onEcosystemChange = useCallback((ecosystem: string) => {
    setEcosystem(ecosystem);
  }, []);

  useEffect(() => {
    if (id) {
      getProjectById(id).then((fetchedProject) => {
        setProject(fetchedProject);
      });
    }
  }, [id, setProject]);

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${AppConfig.todixId}`,
        intl.formatMessage({
          id: 'app.projects.mainMenu.name',
          defaultMessage: 'Projects',
        }),
      ],
      project
        ? [`/app/${AppConfig.todixId}/${project.name}`, project.name]
        : [
            `/app/${AppConfig.todixId}/create`,
            intl.formatMessage({
              id: 'products.detailedproduct.nav.create',
              defaultMessage: 'Create',
            }),
          ],
    ];
    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [intl, project]);

  return (
    <div>
      <Card>
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="products.detailedproduct.basetab"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <ProjectForm
              id={id}
              onEcosystemChange={onEcosystemChange}
              ecosystem={ecosystem}
              action={action}
            />
          </TabPane>
          <TabPane
            key="2"
            disabled={!id || !ecosystem}
            tab={
              <>
                <NodeIndexOutlined />{' '}
                <FormattedMessage
                  id="relations.tab"
                  defaultMessage="Relations"
                />
              </>
            }
          >
            <Relations
              ecosystem={ecosystem}
              entityId={id}
              entityType="project"
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default ProjectDetails;
