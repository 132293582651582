import React, { FC } from 'react';
import { Form, Select, Input } from 'antd';
import { FormattedMessage } from 'react-intl';
import { GridContainer } from '@apps/products/mainMenu/components/styles';

const { Item } = Form;

type GroupSalesProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const GroupSales: FC<GroupSalesProps> = ({ namePrefix, readOnly }) => {
  return (
    <Item noStyle shouldUpdate={true}>
      {() => {
        return (
          <Item
            label={
              <FormattedMessage
                id="app.products.group.sales"
                defaultMessage="Group Sales"
              />
            }
          >
            <GridContainer>
              <Item
                name={
                  namePrefix !== undefined
                    ? [namePrefix, `groupSales`]
                    : `groupSales`
                }
                noStyle={true}
              >
                <Select disabled={readOnly} notFoundContent={() => <div />} />
              </Item>
              <Item noStyle={true}>
                <Input readOnly={true} />
              </Item>
            </GridContainer>
          </Item>
        );
      }}
    </Item>
  );
};

export default GroupSales;
