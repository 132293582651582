import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type LinkProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const Link: FC<LinkProps> = ({ namePrefix, readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name={namePrefix !== undefined ? [namePrefix, `link`] : `link`}
      label={<FormattedMessage id="link" defaultMessage="Link" />}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'link',
          defaultMessage: 'Link',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default Link;
