import React, { FC } from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import EcosystemAvatar from '@components/EcosystemAvatar';
import { Document } from '@apps/documents/service';
import UserAvatar from '@components/UserAvatar';
import { Container, TwoColumnsDiv } from './DocumentInfo.sc';

type Props = {
  document: Document;
};

export const DocumentInfo: FC<Props> = ({ document }) => {
  const { ecosystem, uploadingUser, uploadDate, origin } = document;
  const uploadDateMoment = moment(uploadDate);
  const timeFromUpload = uploadDateMoment.from(new Date(), true);

  return (
    <Container>
      <TwoColumnsDiv>
        <div>
          <FormattedMessage
            id="documents.inbox.documentinfo.inbox.col"
            defaultMessage="Inbox"
          />
        </div>
        <div>
          {origin || (
            <FormattedMessage id="documents.unknown" defaultMessage="unknown" />
          )}
        </div>
      </TwoColumnsDiv>
      <TwoColumnsDiv>
        <div>
          <FormattedMessage
            id="documents.inbox.documentinfo.ecosystem.col"
            defaultMessage="Ecosystem"
          />
        </div>
        <div>
          <EcosystemAvatar id={ecosystem} />
        </div>
      </TwoColumnsDiv>
      <TwoColumnsDiv>
        <div>
          <FormattedMessage
            id="documents.inbox.documentinfo.uploadinguser.col"
            defaultMessage="Uploading user"
          />
        </div>
        <div>
          <UserAvatar user={uploadingUser as CommonUserData} />
        </div>
      </TwoColumnsDiv>
      <TwoColumnsDiv>
        <div>
          <FormattedMessage
            id="documents.inbox.documentinfo.uploaded.col"
            defaultMessage="Uploaded"
          />
        </div>
        <div>{uploadDateMoment.format('DD:MM:YYYY, HH:mm')}</div>
      </TwoColumnsDiv>
      <TwoColumnsDiv>
        <div>
          <FormattedMessage
            id="documents.inbox.documentinfo.ininbox.col"
            defaultMessage="documents.inbox.documentinfo.ininbox.col"
          />
        </div>
        <div>{timeFromUpload}</div>
      </TwoColumnsDiv>
    </Container>
  );
};
