import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 40,
    paddingBottom: 80,
    fontSize: '9px',
  },
  description: {
    position: 'relative',
    width: '500px',
    height: '32px',
    paddingLeft: '8px',
    fontSize: '8px',
    justifyContent: 'center',
  },
  deliveryDateWrapper: {
    position: 'relative',
    width: '500px',
    height: '16px',
    fontSize: '8px',
    paddingLeft: '8px',
  },
  section: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    fontSize: '10px',
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 40,
    width: '100%',
  },
  companyAddress: {
    fontSize: '8px',
  },
  secondRow: {
    marginTop: '24px',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
  },
  docDataLabel: {
    fontSize: '8px',
    marginRight: '8px',
  },
  docDataValue: {
    fontSize: '8px',
  },
  docDataRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '9px',
    marginBottom: '4px',
  },
  contactDetails: {
    fontSize: '8px',
  },
  salesDescription: {
    fontSize: '9px',
    marginTop: '9px',
  },
  productInfoWrapper: {
    fontSize: '11px',
    marginTop: '24px',
  },
  productTable: {
    flexDirection: 'row',
    width: '100%',
    marginTop: '10px',
    fontSize: '10px',
  },
  productCol: {
    width: '100%',
  },
  productRow: {
    height: '24px',
    paddingLeft: '8px',
    justifyContent: 'center',
    fontSize: '8px',
  },
  productHeaderRow: {
    height: '36px',
    paddingLeft: '8px',
    fontSize: '9px',
    backgroundColor: '#f0f0f0',
    justifyContent: 'center',
  },
  totalWrapper: {
    marginTop: '24px',
    backgroundColor: '#f0f0f0',
    padding: '8px',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: '10px',
  },
  deliveryDetailsWrapper: {
    marginTop: '8px',
    fontSize: '9px',
    flexDirection: 'row',
  },
  deliveryDetailsCol: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '24px',
  },
  deliveryDetail: {
    fontSize: '8px',
  },
  companyDetails: {
    flexDirection: 'row',
    fontSize: '8px',
    marginTop: '18px',
    justifyContent: 'space-between',
  },
  productsSummaryRow: {
    flexDirection: 'row',
    paddingLeft: '8px',
    fontSize: '9px',
    marginTop: 8,
    width: '100%',
    justifyContent: 'space-between',
  },
  priceText: {
    color: '#2c9569',
  },
});
