import React, { useState } from 'react';
import { Button, Modal, Select, message, Input, Card, Row, Col } from 'antd';
import Papa from 'papaparse';
import styled from 'styled-components';
import { Consultant } from '@apps/directSales/mainMenu/Consultants/services';
import { RcFile } from 'antd/lib/upload';
import UploadModal from './components/UploadModal';
import { isConsultant } from './utils/isConsultant';
import createConsultants from '@apps/directSales/mainMenu/Consultants/services/createConsultants';
import intl from '../../../../../intl';

const ReadableConsultantKeys = {
  consultantNo:
    intl.formatMessage({
      id: 'consultants.columns.consultantNo.header',
      defaultMessage: 'consultants.columns.consultantNo.header',
    }) + '*',
  consultantName:
    intl.formatMessage({
      id: 'consultants.columns.consultantName.header',
      defaultMessage: 'consultants.columns.consultantName.header',
    }) + '*',
  consultantSurname:
    intl.formatMessage({
      id: 'consultants.columns.consultantSurname.header',
      defaultMessage: 'consultants.columns.consultantSurname.header',
    }) + '*',
  consultantEmail:
    intl.formatMessage({
      id: 'consultants.columns.consultantEmail.header',
      defaultMessage: 'consultants.columns.consultantEmail.header',
    }) + '*',
  consultantBirthday: intl.formatMessage({
    id: 'consultants.columns.consultantBirthday.header',
    defaultMessage: 'consultants.columns.consultantBirthday.header',
  }),
  consultantPhone: intl.formatMessage({
    id: 'consultants.consultant.phone',
    defaultMessage: 'consultants.consultant.phone',
  }),
  consultantStreet: intl.formatMessage({
    id: 'consultants.columns.consultantStreet.header',
    defaultMessage: 'consultants.columns.consultantStreet.header',
  }),
  consultantStreetNo: intl.formatMessage({
    id: 'consultants.columns.consultantStreetNo.heade',
    defaultMessage: 'consultants.columns.consultantStreetNo.heade',
  }),
  consultantZip: intl.formatMessage({
    id: 'consultants.columns.consultantZip.header',
    defaultMessage: 'consultants.columns.consultantZip.header',
  }),
  consultantCity: intl.formatMessage({
    id: 'consultants.columns.consultantCity.header',
    defaultMessage: 'consultants.columns.consultantCity.header',
  }),
  consultantParent: intl.formatMessage({
    id: 'consultants.columns.consultantParent.header',
    defaultMessage: 'consultants.columns.consultantParent.header',
  }),
  consultantLevel: intl.formatMessage({
    id: 'consultants.columns.consultantLevel.label',
    defaultMessage: 'consultants.columns.consultantLevel.label',
  }),
  consultantRank: intl.formatMessage({
    id: 'consultants.columns.consultantRank.header',
    defaultMessage: 'consultants.columns.consultantRank.header',
  }),
  consultantTaxNo: intl.formatMessage({
    id: 'consultants.columns.consultantTaxNo.header',
    defaultMessage: 'consultants.columns.consultantTaxNo.header',
  }),
  iban: intl.formatMessage({
    id: 'consultants.columns.iban.header',
    defaultMessage: 'consultants.columns.iban.header',
  }),
  bic: intl.formatMessage({
    id: 'consultants.columns.bic.header',
    defaultMessage: 'consultants.columns.bic.header',
  }),
  description: intl.formatMessage({
    id: 'consultants.columns.description.header',
    defaultMessage: 'consultants.columns.description.header',
  }),
  applicationDate: intl.formatMessage({
    id: 'consultants.columns.applicationDate.header',
    defaultMessage: 'consultants.columns.applicationDate.header',
  }),
  careerplanConfirmed: intl.formatMessage({
    id: 'consultants.columns.careerplanConfirmed.header',
    defaultMessage: 'consultants.columns.careerplanConfirmed.header',
  }),
  privacyProtectionConfirmed: intl.formatMessage({
    id: 'consultants.columns.PrivacyProtectionConfirmed.header',
    defaultMessage: 'consultants.columns.PrivacyProtectionConfirmed.header',
  }),
  agbConfirmed: intl.formatMessage({
    id: 'consultants.columns.agbConfirmed.header',
    defaultMessage: 'consultants.columns.agbConfirmed.header',
  }),
  joker: intl.formatMessage({
    id: 'consultants.columns.joker.header',
    defaultMessage: 'consultants.columns.joker.header',
  }),
  originConsultantEmail: intl.formatMessage({
    id: 'consultants.columns.consultantOriginEmail.header',
    defaultMessage: 'consultants.columns.consultantOriginEmail.header',
  }),
} as const;

const requiredFields = [
  'consultantNo',
  'consultantName',
  'consultantSurname',
  'consultantEmail',
] as const;

const DEFAULT_DELIMITER = ',';

const ILoadingState = {
  isUploading: intl.formatMessage({
    id: 'consultants.import.consultant.loading.isUploading',
    defaultMessage: 'consultants.import.consultant.loading.isUploading',
  }),
  isImporting: intl.formatMessage({
    id: 'consultants.import.consultant.loading.isImporting',
    defaultMessage: 'consultants.import.consultant.loading.isImporting',
  }),
} as const;

interface IConsultantImportProps {
  ecosystemId: Ecosystem['id'];
}

const ConsultantImport: React.FC<IConsultantImportProps> = ({
  ecosystemId,
}) => {
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [delimiter, setDelimiter] = useState<string>(DEFAULT_DELIMITER);
  const [fileLoading, setFileLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(ILoadingState.isUploading);
  const [mappingModalVisible, setMappingModalVisible] = useState(false);
  const [importingConsultants, setImportingConsultants] = useState(false);
  const [columnMappings, setColumnMappings] = useState<Record<string, number>>(
    {},
  );

  const handleFileUpload = async (file: RcFile) => {
    const isValidDelimiter = (data: string[][]) => {
      if (data[0].length <= 1) {
        return false;
      }
      return true;
    };

    try {
      setFileLoading(true);

      const content = await file.text();
      const { data } = Papa.parse<string[]>(content, {
        delimiter: delimiter,
        header: false,
        skipEmptyLines: 'greedy',
      });

      if (!isValidDelimiter(data)) {
        throw Error('Invalid delimiter');
      }

      setCsvData(data);
      setMappingModalVisible(true);
    } catch (error) {
      switch (true) {
        case /delimiter/.test(error.message):
          message.error(
            intl.formatMessage({
              id: 'consultants.import.consultant.error.delimiter',
              defaultMessage: 'consultants.import.consultant.error.delimiter',
            }),
          );
          break;

        default:
          message.error(
            intl.formatMessage({
              id: 'consultants.import.consultant.error.csv',
              defaultMessage: 'consultants.import.consultant.error.csv',
            }) + ` ${error.message}`,
          );
          break;
      }
    }
    setFileLoading(false);
  };

  const handleMappingChange = (key: string, value: number | null) => {
    if (value === null) {
      const newMappings = { ...columnMappings };
      delete newMappings[key];
      setColumnMappings(newMappings);
    } else {
      setColumnMappings({ ...columnMappings, [key]: value });
    }
  };

  const handleImport = async () => {
    try {
      setLoadingState(ILoadingState.isImporting);
      setImportingConsultants(true);

      const consultants = extractConsultants();
      await createConsultants(consultants, ecosystemId);

      message.success(
        intl.formatMessage({
          id: 'consultants.import.consultant.success',
          defaultMessage: 'consultants.import.consultant.success',
        }),
      );
    } catch (error) {
      message.error(
        intl.formatMessage({
          id: 'consultants.import.consultant.error',
          defaultMessage: 'consultants.import.consultant.error',
        }) + ` ${error.message}`,
      );
    }

    setLoadingState(ILoadingState.isImporting);
    setImportingConsultants(false);
    setMappingModalVisible(false);
  };

  const extractConsultants = (): Consultant[] => {
    return csvData.slice(1).map((row, index) => {
      const consultant: Partial<Consultant> = {};
      for (const key in columnMappings) {
        consultant[key as keyof Consultant] = row[columnMappings[key]];
      }

      consultant['ecosystem'] = ecosystemId;

      if (!isConsultant(consultant)) {
        throw new Error(
          intl.formatMessage({
            id: 'consultants.import.consultant.error.csv.row',
            defaultMessage: 'consultants.import.consultant.error.csv.row',
          }) + ` ${index + 1}`,
        );
      }

      return consultant as Consultant;
    });
  };

  const isOptionDisabled = (index: number) => {
    return Object.values(columnMappings).includes(index);
  };

  const filterOption = (input: string, option: any) => {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  const allRequiredFieldsMapped = () => {
    return requiredFields.every((key) => key in columnMappings);
  };

  return (
    <Card
      title={intl.formatMessage({
        id: 'consultants.import.consultant',
        defaultMessage: 'consultants.import.consultant',
      })}
      style={{ width: '100%' }}
    >
      <Row>
        <Col span={10}>
          <div>
            <span>
              {intl.formatMessage({
                id: 'consultants.import.consultant.csv.delimiter',
                defaultMessage: 'consultants.import.consultant.csv.delimiter',
              }) + ':'}
            </span>
            <div style={{ marginBottom: 8 }}>
              <p>
                {intl.formatMessage(
                  {
                    id: 'consultants.import.consultant.csv.delimiter.description',
                    defaultMessage:
                      'consultants.import.consultant.csv.delimiter.description',
                  },
                  {
                    delimiter: DEFAULT_DELIMITER,
                  },
                )}
              </p>
            </div>
            <Input
              style={{ width: '30%' }}
              value={delimiter}
              onChange={(e) => setDelimiter(e.target.value)}
            />
          </div>
        </Col>
        <Col span={2}></Col>
        <Col span={12}>
          <UploadModal
            description={intl.formatMessage({
              id: 'consultants.import.consultant.csv.upload.description',
              defaultMessage:
                'consultants.import.consultant.csv.upload.description',
            })}
            disabled={fileLoading}
            fileAccept=".csv"
            handleFileUpload={handleFileUpload}
            textOnLoading={loadingState}
          />
        </Col>
      </Row>

      <div>
        <Modal
          title={intl.formatMessage({
            id: 'consultants.import.consultant.csv.mapping.title',
            defaultMessage: 'consultants.import.consultant.csv.mapping.title',
          })}
          visible={mappingModalVisible}
          onCancel={() => setMappingModalVisible(false)}
          footer={[
            <Button key="cancel" onClick={() => setMappingModalVisible(false)}>
              {intl.formatMessage({
                id: 'consultants.import.consultant.csv.mapping.button.cancel',
                defaultMessage:
                  'consultants.import.consultant.csv.mapping.button.cancel',
              })}
            </Button>,
            <Button
              key="import"
              type="primary"
              loading={importingConsultants}
              onClick={handleImport}
              disabled={!allRequiredFieldsMapped()}
            >
              {intl.formatMessage({
                id: 'consultants.import.consultant.csv.mapping.button.import',
                defaultMessage:
                  'consultants.import.consultant.csv.mapping.button.import',
              })}
            </Button>,
          ]}
        >
          {Object.keys(ReadableConsultantKeys).map((key) => (
            <MappingRow key={key}>
              <span>
                {
                  ReadableConsultantKeys[
                    key as keyof typeof ReadableConsultantKeys
                  ]
                }
              </span>
              <Select
                showSearch
                filterOption={filterOption}
                style={{ width: 200 }}
                onChange={(value) => handleMappingChange(key, value)}
                allowClear
              >
                {csvData[0]?.map((colName, index) => (
                  <Select.Option
                    key={index}
                    value={index}
                    disabled={isOptionDisabled(index)}
                  >
                    {colName}
                  </Select.Option>
                ))}
              </Select>
            </MappingRow>
          ))}
          <small>
            {intl.formatMessage({
              id: 'consultants.import.consultant.csv.mapping.requiredFields',
              defaultMessage:
                'consultants.import.consultant.csv.mapping.requiredFields',
            })}
          </small>
        </Modal>
      </div>
    </Card>
  );
};

const MappingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export default ConsultantImport;
