import React, { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import FloatNumberedField from '@components/FloatNumberedField';

const { Item } = Form;

type NeedAmountProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const NeedAmount: FC<NeedAmountProps> = ({ namePrefix, readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name={
        namePrefix !== undefined ? [namePrefix, `needAmount`] : `needAmount`
      }
      label={<FormattedMessage id="need.amount" defaultMessage="Need amount" />}
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="need.amount.missing"
              defaultMessage="Need amount is missing"
            />
          ),
        },
      ]}
    >
      <FloatNumberedField
        placeholder={intl.formatMessage({
          id: 'need.amount',
          defaultMessage: 'Need amount',
        })}
        allowNegative={false}
        max={100}
        decimalSeparator={','}
        decimalScale={2}
        thousandSeparator={'.'}
        readOnly={readOnly}
      />
    </Item>
  );
};

export default NeedAmount;
