import React, { FC, useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Spin } from 'antd';
import {
  getDocumentsTypesSettings,
  LayoutField,
} from '@apps/documents/service';
import LayoutRenderer from '@apps/documents/mainMenu/LayoutRenderer';
import FilledMandatoryFields from '@apps/documents/mainMenu/DocumentProcessing/FilledMandatoryFields';
import StepForm from '@apps/documents/mainMenu/DocumentProcessing/StepForm';
import { ChildrenPassedProps } from '@apps/documents/mainMenu/DocumentProcessing/Step';

const Step1: FC<ChildrenPassedProps> = ({
  document,
  triggerValidator,
  ...rest
}) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [docTypes, setDocTypes] = useState<string[]>([]);
  const filled = [document.type, document.category].filter(
    (item) => item !== undefined,
  ).length;

  useEffect(() => {
    if (document.ecosystem) {
      getDocumentsTypesSettings(document.ecosystem)
        ?.then((results) => {
          if (results) {
            setDocTypes(
              results.docTypes
                .filter((entry) => entry.isActive)
                .map((entry) => entry.docType),
            );
            setLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [document.ecosystem]);

  const layoutFields: LayoutField[] = useMemo(
    () => [
      {
        fieldName: 'type',
        fieldType: 'selectSearch',
        placeholder: intl.formatMessage({
          id: `documentprocessing.step1.type.placeholder`,
          defaultMessage: 'Please select document type...',
        }),
        label: 'documentprocessing.step1.type.label',
        rules: [
          { required: true, message: 'documentprocessing.step1.type.error' },
        ],
        options: docTypes.map((docType) => ({
          value: docType,
          label: intl.formatMessage({
            id: `documents.documentTypes.${docType}`,
            defaultMessage: docType,
          }),
        })),
      },
      {
        fieldName: 'category',
        fieldType: 'radioGroup',
        label: 'documentprocessing.step1.category.label',
        rules: [
          {
            required: true,
            message: 'documentprocessing.step1.category.error',
          },
        ],
        options: [
          {
            value: 'incoming',
            label: 'documentprocessing.step1.category.option1.label',
          },
          {
            value: 'outgoing',
            label: 'documentprocessing.step1.category.option2.label',
          },
          {
            value: 'none',
            label: 'documentprocessing.step1.category.option3.label',
          },
        ],
      },
    ],
    [docTypes, intl],
  );

  return (
    <StepForm {...rest}>
      <Spin spinning={loading}>
        <LayoutRenderer layoutFields={layoutFields} />
        <FilledMandatoryFields filled={filled} total={2} />
      </Spin>
    </StepForm>
  );
};

export default Step1;
