import {
  WAREHOUSE_STORAGE,
  WarehouseStorageEntry,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';

const updateWarehouseStorageEntry = (
  ecosystem: string,
  entry: WarehouseStorageEntry,
) => {
  const { id, storageId, ...rest } = entry;
  return firebase.db
    ?.ref(`${WAREHOUSE_STORAGE}/${ecosystem}/${storageId}/entries/${id}`)
    .set({
      ...rest,
    })
    .then(() => entry);
};

export default updateWarehouseStorageEntry;
