import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { InputNumber, Form } from 'antd';
import { transformInt } from '@apps/directSales/settings/Commissions/components/commonHelpers';

const { Item } = Form;

type Props = {
  intl: IntlShape;
};

const TeamSizeInput: FC<Props> = ({ intl }) => {
  return (
    <Item
      label={intl.formatMessage({
        id: 'commissions.team.size',
        defaultMessage: 'Team size',
      })}
      name="teamSize"
      normalize={transformInt}
      rules={[
        {
          type: 'number',
          min: 0,
          transform: transformInt,
        },
      ]}
    >
      <InputNumber
        type="number"
        min={0}
        step={1}
        placeholder={intl.formatMessage({
          id: 'consultants.team.size',
          defaultMessage: 'Team size',
        })}
      />
    </Item>
  );
};

export default TeamSizeInput;
