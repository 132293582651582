import {
  HistoryTotalEntry,
  TOTAL_STATS_HISTORY_COLLECTION,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';

type Payload = {
  ecosystem: string;
  storageId: string;
  filter: {
    field: 'flowType' | 'timestamp';
    value: number | string;
  };
};

const fetchFilteredTotalStatsHistoryForStorage = ({
  ecosystem,
  storageId,
  filter,
}: Payload) => {
  return firebase.db
    ?.ref(`${TOTAL_STATS_HISTORY_COLLECTION}/${ecosystem}/${storageId}`)
    .orderByChild(filter.field)
    .equalTo(filter.value)
    .once('value')
    .then((results) => {
      if (!results.exists()) {
        return Promise.resolve([] as HistoryTotalEntry[]);
      }

      let entries: HistoryTotalEntry[] = [];
      results.forEach((snap) => {
        const snapVal = snap.val();
        entries = [...entries, snapVal as HistoryTotalEntry];
      });

      return entries;
    });
};

export default fetchFilteredTotalStatsHistoryForStorage;
