import { Cart, CartEntry, CARTS } from '@services/cart/namespace';
import { firebase } from '@services/Firebase';
import moment from 'moment';
import isCartEntryEqual from '@services/cart/isCartEntryEqual';

const updateEntryInCart = async (
  cartEntry: CartEntry,
  userId: string,
  override?: boolean,
) => {
  const cartRef = firebase.db?.ref(`${CARTS}/${userId}/cart`);

  if (!cartRef) {
    throw Error('Firebase real time base is not ready');
  }

  const cart = await cartRef.once('value');

  if (cart) {
    if (!cart.exists()) {
      await cartRef.set({
        timestamp: moment().valueOf(),
        entries: [cartEntry],
      } as Cart);
    } else {
      const cartObject = cart.val() as Cart;
      let cartEntries = cartObject.entries;
      const foundedEntryIndex = cartEntries.findIndex((entry) =>
        isCartEntryEqual(entry, cartEntry),
      );
      if (foundedEntryIndex !== -1) {
        cartEntries[foundedEntryIndex] = {
          ...cartEntries[foundedEntryIndex],
          quantity: override
            ? cartEntry.quantity
            : cartEntries[foundedEntryIndex].quantity + cartEntry.quantity,
        };
      } else {
        cartEntries = [...cartEntries, cartEntry];
      }

      await cartRef.update({
        entries: cartEntries,
        timestamp: moment().valueOf(),
      });
    }
  }
};

export default updateEntryInCart;
