import React, { FC, useMemo, useState } from 'react';
import AppView from '@core/components/AppView';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import appConfig from '@apps/products';
import optionsMapper from '@core/helpers/optionsMapper';
import { IGetRowsParams } from 'ag-grid-community';
import { FilterTextObject } from '@services/filtering';
import reorderStockPartsColumns from '@apps/products/widgets/reorderStockPartsColumns';
import getActiveProducts from '@apps/products/services/getActiveProducts';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import PurchaseConfig, { PURCHASES_ACTION } from '@apps/purchases';
import RequestFormModal from '@apps/purchases/widgets/RequestFormModal';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import { RequestModal } from '@apps/products/widgets/styles.sc';
import { Spin } from 'antd';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { createIndividualPurchaseRequest } from '@apps/purchases/services/individualPurchaseRequests';
import { createActivity } from '@core/services';
import ProductCard from '@apps/products/mainMenu/components/ProductCard/ProductCard';

const ReorderStockPartsList: FC = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();
  const [spinning, setSpinning] = useState(false);
  const { data: user } = useSelector(getUser);
  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getActiveProducts(
      {
        query: params.query,
        offset: params.startRow,
        limit: params.endRow - params.startRow,
        fields: [
          'id',
          'ecosystem',
          'unit',
          'isActive',
          'canBeBought',
          'productCreationDate',
          'productGroup',
          'productName',
          'productNumber',
          'description',
          'creator',
        ],
        filters: Object.fromEntries(filtersEntries),
        sort: params.sortModel.map(
          (model: { colId: string; sort: string }) =>
            `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
        ),
      },
      'view-products',
    )?.then((response) => {
      // ts ignored because I want if element exists there for old, incompatible data filtering
      // @ts-ignore
      const filteredResults = response.results.filter((el) => el.creator);
      params.successCallback(filteredResults, filteredResults.length);
    });
  };

  return (
    <Spin spinning={spinning}>
      <AppView
        hideQuickFilter
        breadcrumbItems={[
          [
            path,
            intl.formatMessage({
              id: 'app.products',
              defaultMessage: 'Products',
            }),
          ],
        ]}
        options={optionsMapper([], intl)}
        columns={useMemo(() => reorderStockPartsColumns(intl), [intl])}
        getRows={getRows}
        sideContent={() => <div />}
        cardComponent={ProductCard}
        path={path}
        onCellClickPath={path}
        tabs={['list', 'cards']}
        getExtraOptions={(item) => {
          const ecosystemId = item?.ecosystem || '';
          const canCreate = checkUserPermissions(
            rootStore.getState(),
            PurchaseConfig.todixId,
            'create-purchases-requests' as PURCHASES_ACTION,
            ecosystemId,
          );

          let onClose = () => {};

          const submit = async (values: IndividualPurchaseRequest) => {
            try {
              setSpinning(true);
              await createIndividualPurchaseRequest(values);
              await createActivity({
                ecosystemId: values.ecosystem,
                userId: values.creator.uid,
                title: 'purchase.request.created.by',
                content: values.creator.displayName,
              });
              if (onClose) {
                onClose();
                setSpinning(false);
              }
            } catch (e) {
              console.error(e);
              setSpinning(false);
            }
          };

          return canCreate
            ? [
                {
                  children: intl.formatMessage({
                    id: 'create.individual.request',
                    defaultMessage: 'Create individual request',
                  }),
                  onClick: () => {
                    RequestModal.confirm({
                      className: 'request-modal',
                      content: (
                        <RequestFormModal
                          initialValues={...item}
                          onSubmit={submit}
                          user={user}
                        />
                      ),
                      icon: null,
                      okText: intl.formatMessage({
                        id: 'create.individual.request',
                        defaultMessage: 'Create individual request',
                      }),
                      onOk: (close) => {
                        onClose = close;
                      },
                      okButtonProps: {
                        htmlType: 'submit',
                        form: 'individualRequest',
                      },
                    });
                  },
                },
              ]
            : [];
        }}
        appConfig={appConfig}
      />
    </Spin>
  );
};

export default ReorderStockPartsList;
