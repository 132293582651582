//based on: https://ag-grid.com/javascript-grid/component-filter/#custom-filter-example

import React from 'react';
import { Select, Avatar } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getEcosystems } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import { FilterTextObject } from '@services/filtering';
import { FilterWrapper } from '@components/List/CustomsFilters/styles';

const { Option } = Select;

type State = {
  selectedEcosystemId: string;
};

type Props = {
  filterChangedCallback: any;
};

// TODO: change it to the forwardRef FC
class EcosystemFilter extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedEcosystemId: '',
    };
  }

  onValueChange = (value: any) => {
    this.setState(
      {
        selectedEcosystemId: value,
      },
      this.props.filterChangedCallback,
    );
  };

  isFilterActive = () => {
    return !!this.state.selectedEcosystemId;
  };

  getModelAsString = () => {
    return '';
  };

  getModel = () => {
    return this.state.selectedEcosystemId
      ? ({
          filter: this.state.selectedEcosystemId,
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject)
      : null;
  };

  setModel = (model: any) => {
    this.setState({
      selectedEcosystemId: model?.selectedEcosystemId || null,
    });
  };

  render() {
    const ecosystems = getEcosystems(rootStore.getState());

    return (
      <FilterWrapper>
        <Select
          id="ecosystems"
          onChange={this.onValueChange}
          allowClear
          placeholder={
            <FormattedMessage
              id="documents.commonfields.ecosystem.label"
              defaultMessage="Ecosystem"
            />
          }
          style={{ width: 200 }}
        >
          {ecosystems.map((eco) => (
            <Option key={eco.id} id={eco.id} value={eco.id}>
              <Avatar src={eco.avatarUrl} size="small" /> {eco.name}
            </Option>
          ))}
        </Select>
      </FilterWrapper>
    );
  }
}

export default EcosystemFilter;
