import React, { ReactNode, FC, useState, useEffect } from 'react';
import { Select, Space, Avatar, SelectProps } from 'antd';
import { fetchUser } from '@services/users';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/projects';
import { getMembers } from '@services/ecosystems';
import { CONTACTS_ACTION } from '@apps/contacts';

const { Option } = Select;

type MemberSelectorProps = {
  ecosystem: string;
  placeholder?: ReactNode;
  onChange?: any;
  readOnly?: boolean;
  value?: string;
  mode?: SelectProps['mode'];
};

const MemberSelector: FC<MemberSelectorProps> = ({
  placeholder,
  onChange,
  ecosystem,
  readOnly = false,
  value,
  mode,
}) => {
  const [currentEcosystem, setCurrentEcosystem] = useState(ecosystem);
  const activeEcosystems = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-contacts' as CONTACTS_ACTION,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState<EcosystemMember[] | null>(null);

  useEffect(() => {
    if (currentEcosystem !== ecosystem) {
      setCurrentEcosystem(ecosystem);
      setMembers(null);
    }
  }, [ecosystem, currentEcosystem]);

  useEffect(() => {
    if (!currentEcosystem) {
      return;
    }
    if (currentEcosystem && members === null) {
      setIsLoading(true);
      const selectedEcosystem = activeEcosystems.find(
        (eco) => eco.id === currentEcosystem,
      );

      if (selectedEcosystem) {
        if (selectedEcosystem.type === 'private') {
          fetchUser(selectedEcosystem.ownerUid)
            .then((user) => {
              if (user) {
                setMembers([
                  {
                    ...user,
                    id: selectedEcosystem.ownerUid,
                    isActive: true,
                    role: 'owner',
                  },
                ]);
              }
            })
            .finally(() => setIsLoading(false));
        }

        if (selectedEcosystem.type === 'company') {
          getMembers(selectedEcosystem.id)
            ?.then(setMembers)
            ?.finally(() => {
              setIsLoading(false);
            });
        }
      }
    }
  }, [activeEcosystems, members, currentEcosystem]);

  const handleOnChange = (id: string) => {
    if (onChange) {
      onChange(id);
    }
  };

  return (
    <Select
      mode={mode}
      value={value}
      placeholder={placeholder}
      onChange={handleOnChange}
      disabled={readOnly || !ecosystem}
      loading={isLoading}
    >
      {members &&
        members.map((member, index) => (
          <Option key={index} value={member.id}>
            <Space direction="horizontal" size="small">
              {member.photoURL && <Avatar src={member.photoURL} size="small" />}
              <span>{member.displayName}</span>
            </Space>
          </Option>
        ))}
    </Select>
  );
};

export default MemberSelector;
