import React, { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { StyledDashboardCard } from '@pages/dashboard';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Document, getDocumentsInbox } from '@apps/documents/service';
import { Icon } from '@todix/ui-components';
import DocumentsAppConfig from '@apps/documents';
import HugeButton from '@components/HugeButton';

const DashboardWidget: FC = () => {
  const [spinning, setSpinning] = useState(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const intl = useIntl();

  useEffect(() => {
    const fields = [
      'id',
      'receivingDate',
      'ecosystem',
      'type',
      'category',
      'tags',
      'uploadDate',
      'creationDate',
      'creatingUser',
      'uploadingUser',
      'origin',
      'relatedContact',
      'relatedContactSnapshot',
      'notes',
    ];
    setSpinning(true);
    getDocumentsInbox(
      'view-documents',
      {
        fields,
      },
      intl,
    )
      .then((response) => {
        setDocuments(response.results as Document[]);
        setSpinning(false);
      })
      .catch((e) => {
        console.error(e);
        setSpinning(false);
      });
  }, [setDocuments, intl]);

  return (
    <StyledDashboardCard bordered={false}>
      <Spin spinning={spinning} style={{ height: '100%' }} />
      {documents.length ? (
        <Link to={`app/${DocumentsAppConfig.todixId}/create`}>
          <HugeButton type="dashed" icon={<Icon.UploadOutlined />}>
            <span style={{ whiteSpace: 'pre-wrap' }}>
              <FormattedMessage
                id="dashboard.uploaddocument.content"
                defaultMessage="You have {documentsAmount} new Documents in your"
                values={{
                  documentsAmount: documents.length,
                }}
              />
              <Link
                to={`app/${DocumentsAppConfig.todixId}/inbox`}
                style={{ marginLeft: '4px' }}
              >
                {intl.formatMessage({
                  id: 'dashboard.inbox.link',
                  defaultMessage: ' Inbox',
                })}
              </Link>
            </span>
          </HugeButton>
        </Link>
      ) : (
        !spinning && (
          <Link to={`app/${DocumentsAppConfig.todixId}/create`}>
            <HugeButton type="dashed" icon={<Icon.UploadOutlined />}>
              <FormattedMessage
                id="dashboard.empty.uploaddocument"
                defaultMessage="Upload your first Document now"
              />
            </HugeButton>
          </Link>
        )
      )}
    </StyledDashboardCard>
  );
};

export default DashboardWidget;
