import { Contact } from '@apps/contacts/services';
import { DeliveryTermsType } from '@apps/purchases/services/deliveryTerms';
import { PaymentTerm } from '@apps/purchases/services/paymentTerms';

export const BUCKETS_COLLECTION = 'buckets';

export type BucketStep =
  | 'banf'
  | 'rfq'
  | 'ordered'
  | 'orderConfirmed'
  | 'delivered'
  | 'partlyDelivered'
  | 'overDelivered'
  | 'invoiced'
  | 'partlyInvoiced'
  | 'paid'
  | 'partlyPaid'
  | 'cancelled';

export type BucketType = 'supplier' | 'customer';

export type BucketStatus =
  | 'draft'
  | 'cancelled'
  | 'tracked'
  | 'sent'
  | 'done'
  | 'delivered'
  | 'partlyDelivered';

export type BucketPosition = {
  id?: string;
  ecosystem: string;
  timestamp?: number;
  productName?: string;
  productNumber?: string;
  productId?: string;
  shortDescription: string;
  description: string;
  unit?: string;
  creator: User;
  needDate: string;
  expectedDeliveryDate?: string;
  needAmount: number;
  deliveredAmount: number;
  unitAmount: number;
  tax?: number;
  currency?: string;
  creationDate: string;
  project?: string;
  costCenter?: string;
  relatedDocumentId?: string;
  markToRemove?: boolean;
  hasSerialNumber?: boolean;
  hasBatchNumber?: boolean;
};

export type Bucket = {
  id?: string;
  draftSaved?: boolean;
  ecosystem: string;
  type: BucketType;
  relatedContact: string;
  relatedContactSnapshot: Contact;
  relatedDocumentId?: string;
  timestamp?: number;
  key?: number;
  positions: BucketPosition[];
  stepType: BucketStep;
  status: BucketStatus;
  deliveryTerms?: DeliveryTermsType;
  paymentTerms?: PaymentTerm;
  creationDate?: string;
  docNumber?: string;
  prevBucketId?: string;
  referenceNumber?: string;
  freeTextStart?: string;
  freeTextEnd?: string;
  deliveryAddress?: string;
  footer?: {
    content: string[];
  };
};
