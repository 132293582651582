import React from 'react';
import Tabs from '@components/Tabs';
import { isAppActivatedInEcosystem } from '@apps/utils';
import appConfig, { WAREHOUSE_ACTION } from '@apps/warehouse';
import { FormattedMessage } from 'react-intl';
import WarehouseForm from '@apps/warehouse/widgets/WarehouseForm';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import AppConfig from '@apps/warehouse';

const { TabPane } = Tabs;

type GetWarehouseTabTypePayload = {
  intl: any;
  fullEcosystem: Ecosystem;
  integratedAppId: string;
  productId?: string;
  readOnly?: boolean;
};

type GetArchivedDocumentsTabType = (payload: GetWarehouseTabTypePayload) => any;

const getWarehouseTab: GetArchivedDocumentsTabType = ({
  fullEcosystem,
  productId,
  integratedAppId,
  readOnly,
}) => {
  const shouldRender = isAppActivatedInEcosystem(
    fullEcosystem,
    appConfig.todixId,
  );
  if (!shouldRender) {
    return null;
  }

  const canView = checkUserPermissions(
    rootStore.getState(),
    AppConfig.todixId,
    'view-warehouses' as WAREHOUSE_ACTION,
    fullEcosystem?.id || '',
  );

  return (
    <TabPane
      disabled={!canView}
      key={appConfig.todixId}
      tab={
        <>
          <appConfig.mainMenu.icon />
          <FormattedMessage id="app.warehouse" defaultMessage="Warehouse" />
        </>
      }
    >
      <WarehouseForm
        ecosystemId={fullEcosystem.id}
        productId={productId}
        integratedAppId={integratedAppId}
        readOnly={readOnly || !canView}
      />
    </TabPane>
  );
};

export default getWarehouseTab;
