import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { View } from './routes/view';
import { Create } from './routes/create';
import { Edit } from './routes/edit';
import { Preview } from './routes/preview';

const Tasks: FC<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`}>
        <Create />
      </Route>
      <Route path={`${path}/:id/edit`}>
        <Edit />
      </Route>
      <Route path={`${path}/:id`}>
        <Preview />
      </Route>
      <Route path={`${path}`}>
        <View />
      </Route>
    </Switch>
  );
};

export default Tasks;
