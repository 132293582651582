import React, { FC } from 'react';
import { Form, FormItemProps } from 'antd';
import UnitSelector from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/UnitSelector';

const { Item } = Form;

type Props = FormItemProps & {
  readOnly?: boolean;
  ecosystemId?: string;
};

const ProductUnitSelector: FC<Props> = ({ readOnly, ecosystemId, ...rest }) => {
  return (
    <Item {...rest}>
      <UnitSelector
        disabled={readOnly}
        selectedEcosystem={ecosystemId ? ecosystemId : null}
        style={{
          minWidth: '120px',
        }}
      />
    </Item>
  );
};

export default ProductUnitSelector;
