import get from 'lodash.get';
import set from 'lodash.set';
import { getFilteredData } from '@services/filtering';

type GetFilteredResultsPayload = {
  params: any;
  data: any[];
  activeEcosystemsIds: string[];
};

const getFilteredResults = ({
  params,
  data,
  activeEcosystemsIds,
}: GetFilteredResultsPayload) => {
  const filterEntries = Object.entries(params.filters);
  const shouldFilter = filterEntries.length;
  const shouldSort = !!params.sort.length;
  const filteredByEcosystems = data.filter((row) =>
    activeEcosystemsIds.includes(row.ecosystem as string),
  );
  const filteredByQuery = filteredByEcosystems.filter((row) =>
    params.fields.some((path: any) => {
      const fieldValue = get(row, path);
      if (typeof fieldValue !== 'string') {
        return false;
      }
      const queryLower = params.query.toLowerCase();
      return fieldValue.toLowerCase().includes(queryLower);
    }),
  );
  const filtered = shouldFilter
    ? getFilteredData(filteredByQuery, filterEntries)
    : filteredByQuery;
  const sorted = shouldSort
    ? [...filtered].sort((rowA, rowB) => {
        const sortKey = params.sort[0];
        const desc = sortKey.split('').includes('-');
        const path = sortKey
          .split('')
          .filter((c: string) => c !== '-')
          .join('');
        const valueA = get(rowA, path);
        const valueB = get(rowB, path);
        if (valueA === valueB) {
          return 0;
        }
        if (valueA < valueB) {
          return desc ? 1 : -1;
        } else {
          return desc ? -1 : 1;
        }
      })
    : filtered;
  const choosenFields = sorted.map((row) => {
    const newRow = {};
    params.fields.forEach((path: string): void => {
      const value = get(row, path);
      set(newRow, path, value);
    });
    return newRow;
  });
  const results = choosenFields.length;
  const page = choosenFields.slice(params.offset, params.offset + params.limit);
  return {
    results: page,
    info: {
      results,
    },
  };
};

export default getFilteredResults;
