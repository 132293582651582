import { createContext } from 'react';
import { Product } from '@apps/products/services';
import { TaxCategory } from '@apps/finance/services/taxCategories';
type Context = {
  ecosystem: Ecosystem | null;
  productId?: string;
  product?: Partial<Product>;
  taxRates: TaxCategory[];
  readOnly?: boolean;
};

const SalesTabContext = createContext<Context>({
  ecosystem: null,
  taxRates: [],
});

export default SalesTabContext;
