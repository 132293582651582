import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DashboardOutlined } from '@ant-design/icons';

import { Card } from '@todix/ui-components';

import Tabs from '@components/Tabs';

import AppConfig from '@apps/sales';

import { FormattedMessage, useIntl } from 'react-intl';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import { TabsWithOverflowHidden } from '@apps/sales/mainMenu/styles.sc';
import SaleCreator from '@apps/sales/mainMenu/components/SaleCreator';
import { Sale } from '@apps/sales/services';
import getSale from '@apps/sales/services/getSale';
import { Spin } from 'antd';
import { CompanyContact, Contact } from '@apps/contacts/services';
import getArchivedDocumentsTab from '@apps/documents/mainMenu/widgets/ArchivedDocumentsTab';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import { Document } from '@apps/documents/service';
import getDocumentsRelatedWithSale from '@apps/sales/services/getDocumentsRelatedWithSale';
import DocumentAppConfig from '@apps/documents';
const { TabPane } = Tabs;

const Edit: FC<{}> = () => {
  const { id } = useParams<{ id: string; appId: string }>();
  const [relatedDocuments, setRelatedDocuments] = useState<Document[]>([]);
  const [activeKey, setActiveKey] = useState('1');
  const [isProcessing, setIsProcessing] = useState(false);
  const [ecosystemId, setEcosystemId] = useState('');
  const fullEcosystem = useSelector(getEcosystemById(ecosystemId)) as Ecosystem;
  const [sale, setSale] = useState<Sale | null>();
  const contact = sale?.contactSnapshot as Contact;
  let contactName = '';
  if (contact?.type === 'person') {
    contactName = `${contact?.firstName || ''} ${contact?.lastName || ''}`;
  }
  if (contact?.type === 'company') {
    contactName = `${(contact as CompanyContact).companyName || ''}`;
  }
  const intl = useIntl();

  useEffect(() => {
    if (id) {
      setIsProcessing(true);
      getSale(id)
        ?.then(setSale)
        ?.finally(() => setIsProcessing(false));
    }
  }, [id]);

  useEffect(() => {
    if (sale && sale?.ecosystem) {
      setEcosystemId(sale?.ecosystem);
      getDocumentsRelatedWithSale(sale).then(setRelatedDocuments);
    }
  }, [sale, sale?.ecosystem]);

  useEffect(() => {
    const breadcrumbItems =
      activeKey === '1'
        ? [
            [
              `/app/${AppConfig.todixId}`,
              intl.formatMessage({
                id: 'sales.detailedsale.nav.sales',
                defaultMessage: 'Sales',
              }),
            ],
            id
              ? [`/app/${AppConfig.todixId}/${id}`, contactName]
              : [
                  `/app/${AppConfig.todixId}/create`,
                  intl.formatMessage({
                    id: 'sales.detailedsale.nav.create',
                    defaultMessage: 'Create',
                  }),
                ],
          ]
        : [
            [
              `/app/${AppConfig.todixId}`,
              intl.formatMessage({
                id: 'sales.detailedsale.nav.sales',
                defaultMessage: 'Sales',
              }),
            ],
            [
              `/app/${DocumentAppConfig.todixId}/archive`,
              intl.formatMessage({
                id: 'app.documents.related',
                defaultMessage: 'Related documents',
              }),
            ],
          ];

    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [contactName, id, intl, activeKey]);

  return (
    <Spin spinning={isProcessing}>
      <Card>
        <TabsWithOverflowHidden
          defaultActiveKey="1"
          onChange={(key) => {
            setActiveKey(key);
          }}
        >
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="tasks.detailedtask.basetab"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <SaleCreator
              saleId={id}
              sale={sale}
              action="update-sales"
              relatedDocuments={relatedDocuments}
            />
          </TabPane>
          {id &&
            sale &&
            getArchivedDocumentsTab({
              intl,
              fullEcosystem,
              relatedDocuments,
            })}
        </TabsWithOverflowHidden>
      </Card>
    </Spin>
  );
};

export default Edit;
