import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { RuleObject } from 'antd/lib/form';
import { FormProps } from 'antd/lib/form/Form';
import LayoutRenderer from '@apps/documents/mainMenu/LayoutRenderer';
import { getLayout } from '@apps/documents/service/layouts';
import {
  LayoutField,
  Document,
  PaymentEntity,
  CostEntity,
} from '@apps/documents/service';
import FilledMandatoryFields from '@apps/documents/mainMenu/DocumentProcessing/FilledMandatoryFields';
import { ChildrenPassedProps } from '@apps/documents/mainMenu/DocumentProcessing/Step';
import StepForm from '@apps/documents/mainMenu/DocumentProcessing/StepForm';
import { Spin } from 'antd';

const Step3: FC<ChildrenPassedProps> = ({
  document,
  triggerValidator,
  ...rest
}) => {
  const { type } = document;
  const [selectedType, setSelectedType] = useState(type);
  const [parsedDocument, setParsedDocument] =
    useState<Partial<Document> | null>(null);
  const [layout, setLayout] = useState<LayoutField[]>([]);
  const [required, setRequired] = useState<string[]>([]);
  const [filled, setFilled] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (type !== selectedType) {
      setSelectedType(type);
      setLoading(true);
    }
  }, [selectedType, type]);

  useEffect(() => {
    if (selectedType && document && loading) {
      try {
        let doc = document;
        const processingLayout = getLayout(selectedType, false, 'processing');
        const totalRequired = processingLayout
          .filter((field) => {
            const { rules } = field;

            if (rules) {
              return !!(rules as RuleObject[]).filter((r) => r.required).length;
            }
            return false;
          })
          .map((field) => field.fieldName);

        processingLayout.forEach((field) => {
          if (field.type === 'date' && document[field.fieldName]) {
            doc = {
              ...doc,
              [field.fieldName]: moment(
                document[field.fieldName] as string,
                moment.defaultFormat,
              ),
            };
          }
          if (
            field.fieldType === 'costStructure' &&
            document[field.fieldName]
          ) {
            doc = {
              ...doc,
              [field.fieldName]: (document.costStructure as CostEntity[])?.map(
                (cost) => ({
                  ...cost,
                  netAmount: Number(cost.netAmount).toFixed(2),
                }),
              ),
            };
          }
          if (field.fieldType === 'payments' && document[field.fieldName]) {
            doc = {
              ...doc,
              [field.fieldName]: (
                document[field.fieldName] as PaymentEntity[]
              )?.map((payment: PaymentEntity) => ({
                ...payment,
                payAmount: Number(payment.payAmount).toFixed(2),
                payDate: moment(
                  payment.payDate as string,
                  moment.defaultFormat,
                ),
              })),
            };
          }
        });

        // automatically added values:
        doc.receivingDate = moment(new Date(doc.uploadDate as string));

        setRequired(totalRequired);
        setLayout(processingLayout);
        setParsedDocument(doc || document);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
  }, [document, selectedType, loading]);

  useEffect(() => {
    if (document) {
      const fields = Object.keys(document).filter((name) => {
        const fieldName = name as keyof Document;
        return !!(required.includes(fieldName) && document[fieldName]);
      });

      setFilled(fields.length);
    }
  }, [document, required]);

  const handleValuesChange: FormProps['onValuesChange'] = (_value, values) => {
    const fields = Object.keys(values).filter((name) => {
      const fieldName = name as keyof Document;
      if (required.includes(fieldName)) {
        const typedValue = values[fieldName];

        // need to handle 0 number as filled field, but empty string as not filled one
        return !(
          typedValue === null ||
          typedValue === undefined ||
          typedValue === ''
        );
      }

      return false;
    });

    if (fields.length !== filled) {
      setFilled(fields.length);
    }

    if (rest.onValuesChange) {
      rest.onValuesChange(_value, values);
    }
  };
  return parsedDocument ? (
    <StepForm
      {...rest}
      onValuesChange={handleValuesChange}
      initialValues={parsedDocument || {}}
    >
      <div
        style={{
          display: rest.hidden || !parsedDocument ? 'none' : 'block',
        }}
      >
        <Spin spinning={loading}>
          <LayoutRenderer layoutFields={layout} />
        </Spin>

        {required.length > 0 && (
          <FilledMandatoryFields filled={filled} total={required.length} />
        )}
      </div>
    </StepForm>
  ) : null;
};

export default Step3;
