import React from 'react';

const purchaseStepIconMapper: Record<string, React.FC> = {
  ordered: () => (
    <span className="material-symbols-outlined">order_approve</span>
  ),
  proforma: () => <span className="material-symbols-outlined">draft</span>,
  tracked: () => (
    <span className="material-symbols-outlined">delivery_truck_speed</span>
  ),
  delivered: () => <span className="material-symbols-outlined">package_2</span>,
  invoiced: () => (
    <span className="material-symbols-outlined">request_page</span>
  ),
  paid: () => <span className="material-symbols-outlined">payments</span>,
  cancelled: () => <span className="material-symbols-outlined">cancel</span>,
};

export default purchaseStepIconMapper;
