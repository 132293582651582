import React, { useCallback, useState, FC, useEffect } from 'react';
import { Spin, message } from 'antd';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import getProduct from '@apps/products/services/getProduct';
import updateProduct from '@apps/products/services/updateProduct';
import { Product } from '@apps/products/services/namespace';
import { DetailedProduct } from '../../components/DetailedProduct';
import { useIntl } from 'react-intl';
import ProductsAppConfig, { PRODUCTS_ACTION } from '@apps/products';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

const Edit: FC<{}> = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const intl = useIntl();
  useEffect(() => {
    getProduct(id).then(setProduct);
  }, [id]);

  const handleSubmit = useCallback(
    (values: Product) => {
      setIsSubmitting(true);
      updateProduct(id, values)
        ?.then(() => {
          // message.success(
          //   intl.formatMessage({
          //     id: 'products.edit.success',
          //     defaultMessage: 'Product updated',
          //   }),
          // );
          setIsSubmitting(false);
          history.push(`/app/${ProductsAppConfig.todixId}`);
        })
        .catch(() => {
          setIsSubmitting(false);
          message.error(
            intl.formatMessage({
              id: 'products.edit.error',
              defaultMessage: 'Error while updating Product',
            }),
          );
        });
    },
    [history, id, intl],
  );

  if (!product) return null;

  const action = 'update-products' as PRODUCTS_ACTION;
  const canEdit = product
    ? checkUserPermissions(
        rootStore.getState(),
        ProductsAppConfig.todixId,
        action,
        product?.ecosystem as string,
      )
    : false;

  if (!canEdit) {
    return <Redirect to={`/app/${ProductsAppConfig.todixId}/${id}`} />;
  }

  return (
    <Spin spinning={isSubmitting}>
      <DetailedProduct
        id={id}
        readOnly={!canEdit}
        initialValues={product}
        onSubmit={handleSubmit}
        action={action}
      />
    </Spin>
  );
};

export default Edit;
