import { Tabs } from 'antd';
import styled from 'styled-components';

const TabsRightAligned = styled(Tabs)`
  padding: 0 24px;
  overflow: auto;
  & > .ant-tabs-nav {
    .ant-tabs-nav-list {
      margin-left: auto;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.main.primary};
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.main.primary};
  }
  .ant-tabs-nav::before {
    border-bottom-color: #e8e8e8;
  }
`;

export default TabsRightAligned;
