import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

const useFirstActiveEcosystemId = (todixId: string, action: string) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    todixId,
    action,
  ).map((el) => el.id);
  return activeEcosystemsIds[0];
};

export default useFirstActiveEcosystemId;
