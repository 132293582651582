import {
  Project,
  PROJECTS_COLLECTION_NAME,
} from '@apps/projects/services/namespace';
import { getWithId } from '@services/api';

const getProjectById = (id: string) => {
  return getWithId(`${PROJECTS_COLLECTION_NAME}/${id}`) as Promise<Project>;
};

export default getProjectById;
