import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, AutoComplete } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  getContactsQueries,
  putContactsQueries,
} from '@core/services/platform';
import { AutoCompleteProps } from 'antd/lib/auto-complete';
import { FormattedMessage } from 'react-intl';

const StyledAutoComplete = styled(AutoComplete)`
  .ant-select-clear {
    right: 173px;
  }
  .anticon {
    margin-right: 7px;
  }
`;
const filterOption = (inputValue: string, option: any) =>
  option?.value.toUpperCase().includes(inputValue.toUpperCase());

const QuickFilter: React.FC<AutoCompleteProps> = (props) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<string[]>([]);
  useEffect(() => {
    getContactsQueries()
      ?.then((queries) => Object.values(queries || {}))
      .then(setOptions);
  }, []);
  const optionsObjs = options
    .map((value) => ({
      label: value,
      value,
    }))
    .reverse();
  const valueExist = options.some(
    (option) => option.toLowerCase() === value.toLowerCase(),
  );
  const newOption = {
    label: value,
    value,
  };
  const onSelect = (value: any, option: any) => {
    if (value && !valueExist) {
      putContactsQueries({
        ...[newOption, ...optionsObjs].map((o) => o.value),
      });
    }
    return props && props.onSelect && props.onSelect(value, option);
  };

  return (
    // @ts-ignore
    <StyledAutoComplete
      value={value}
      options={valueExist || !value ? optionsObjs : [newOption, ...optionsObjs]}
      style={{
        width: 700,
      }}
      allowClear
      filterOption={filterOption as any}
      onChange={
        ((value: any) => {
          setValue(value || '');
          // after user click clear icon value is set to undefined
          if (typeof value === 'undefined' && props.onSelect) {
            props.onSelect('', { label: '', value: '' });
          }
        }) as any
      }
      {...props}
      onSelect={onSelect}
    >
      <Input.Search
        size="large"
        enterButton={
          <>
            <SearchOutlined />
            <FormattedMessage
              id="appview.quickfilter.find"
              defaultMessage="Find on the list"
            />
          </>
        }
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            // @ts-ignore
            const value = e.target.value;
            onSelect(value, { label: value, value });
          }
        }}
      />
    </StyledAutoComplete>
  );
};

export default QuickFilter;
