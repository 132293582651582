import React, { FC, useState, useCallback } from 'react';
import { Modal } from 'antd';
import Upload from '@apps/documents/mainMenu/Upload';
import { useCustomEventListener } from '@core/services';
import Inbox from '@apps/documents/mainMenu/Inbox';
import { Document } from '@apps/documents/service';
import processingProductPictures from '@apps/documents/service/processingProductPictures';

type Props = {};

type FastProcessedDocTypes = 'productPicture';

const InboxUploadWidget: FC<Props> = () => {
  const [show, setShow] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [docsToProcessing, setDocsToProcessing] = useState<Partial<Document>[]>(
    [],
  );
  const [noProcessing, setNoProcessing] = useState(false);
  const [docType, setDocType] = useState<FastProcessedDocTypes | null>(null);
  const [ecosystemId, setEcosystemId] = useState<string>('');

  useCustomEventListener('addProductPicture', (data: any) => {
    if (data && data?.ecosystemId) {
      setEcosystemId(data.ecosystemId);
      setShow(true);
      setDocType('productPicture');
      setNoProcessing(true);
    }
  });

  const handleUploadSuccess = useCallback(
    (docs) => {
      if (!noProcessing) {
        setDocsToProcessing(docs);
        setShowProcessing(true);
        setShow(false);
        return;
      }

      if (docType === 'productPicture') {
        processingProductPictures(docs).then(() => {
          setShowProcessing(false);
          setDocType(null);
          setNoProcessing(false);
          setShow(false);
        });
      }
    },
    [noProcessing, docType],
  );

  return (
    <Modal
      width={'80%'}
      visible={show}
      footer={null}
      onCancel={() => setShow(false)}
    >
      {!showProcessing && ecosystemId && (
        <Upload
          onUploadSuccess={handleUploadSuccess}
          onCancel={() => setShow(false)}
          ecosystemId={ecosystemId}
        />
      )}
      {showProcessing && docsToProcessing.length > 0 && (
        <Inbox docsToProcessing={docsToProcessing} />
      )}
    </Modal>
  );
};

export default InboxUploadWidget;
