const getArrayChunks = (array: Ecosystem[], chunk_size: number) => {
  let aggregated = [];
  let i, j;
  for (i = 0, j = array.length; i < j; i += chunk_size) {
    aggregated.push(array.slice(i, i + chunk_size).map((eco) => eco.id));
  }

  return aggregated;
};

export default getArrayChunks;
