import React, { FC, useState } from 'react';
import {
  StackedViewContent,
  StackedViewLayout,
  StackedViewSider,
} from '@components/AppView/AppView.sc';
import StackedWithListener from '@components/AppView/StackedWithListener';
import { StyledCard } from '@components/AppView/SideView';
import { Document } from '@apps/documents/service';
import { SideContentProps, StackedSortType } from '@components/AppView/index';
import { FileApp } from '@apps/AppTypes';

type Props = {
  docsToProcessing?: Partial<Document>[];
  hideActionsInSideContent?: boolean;
  SideContent: React.ComponentType<SideContentProps>;
  selectedRow: string | null;
  setSelectedRow(id: string | null): void;
  stackedSort: StackedSortType;
  setStackedSort: (arg: StackedSortType) => void;
  onResourceChangeEventName?: string;
  onResourceSaveEventName?: string;
  headerColRef: any;
  appConfig?: FileApp;
  action?: string;
};

const StackedView: FC<Props> = ({
  docsToProcessing,
  hideActionsInSideContent,
  SideContent,
  selectedRow,
  setSelectedRow,
  stackedSort,
  setStackedSort,
  onResourceChangeEventName,
  onResourceSaveEventName,
  headerColRef,
  appConfig,
  action,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <StackedViewLayout>
      <StackedViewSider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={'100%'}
      >
        <StackedWithListener
          showNavigation={!collapsed}
          stackedSort={stackedSort}
          setStackedSort={setStackedSort}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          onResourceChangeEventName={onResourceChangeEventName}
          onResourceSaveEventName={onResourceSaveEventName}
          headerColRef={headerColRef}
          docsToProcessing={docsToProcessing}
          appConfig={appConfig}
          action={action}
        />
      </StackedViewSider>
      <StackedViewContent>
        {selectedRow && (
          <StyledCard>
            <SideContent
              id={selectedRow}
              hideActionsInSideContent={hideActionsInSideContent}
            />
          </StyledCard>
        )}
      </StackedViewContent>
    </StackedViewLayout>
  );
};

export default StackedView;
