import React from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const Url = ({ readOnly }: { readOnly?: boolean }) => {
  const intl = useIntl();
  return (
    <Form.Item
      name="url"
      label={
        <FormattedMessage
          id="documentprocessing.step2.url.label"
          defaultMessage="URL"
        />
      }
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'documentprocessing.step2.url.placeholder',
          defaultMessage: 'Type url here...',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Form.Item>
  );
};

export default Url;
