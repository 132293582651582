import React, { FC } from 'react';
import WidgetResolver from '@components/WidgetResolver';
import Config from '@apps/products';

const ReorderStockParts: FC = () => {
  return (
    <div>
      <WidgetResolver
        appName={Config.todixId}
        widgetName="ReorderStockPartsList"
      />
    </div>
  );
};

export default ReorderStockParts;
