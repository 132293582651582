class LocalStorageService {
  private readonly PREFIX: string = 'todix';

  public get(key: string) {
    const value = localStorage.getItem(`${this.PREFIX}-${key}`);
    if (value) {
      return JSON.parse(value);
    }
    return null;
  }

  public set(key: string, value: any) {
    const stringify = JSON.stringify(value);
    localStorage.setItem(`${this.PREFIX}-${key}`, stringify);
  }

  public remove(key: string) {
    localStorage.removeItem(`${this.PREFIX}-${key}`);
  }
}

export default new LocalStorageService();
