import React, { FC } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FlexContainer } from '@apps/purchases/mainMenu/components/styles.sc';
import { useHistory } from 'react-router-dom';
import AppConfig from '@apps/purchases';

const PurchaseRequest: FC = () => {
  const history = useHistory();
  return (
    <FlexContainer>
      <Button
        size="large"
        type="primary"
        onClick={() =>
          history.push(`/app/${AppConfig.todixId}/create_individual_request`)
        }
      >
        <FormattedMessage
          id="create.individual.request"
          defaultMessage="Create individual request"
        />
      </Button>
      <Button size="large" type="primary">
        <FormattedMessage
          id="reorder.stock.parts"
          defaultMessage="Reorder stock parts"
        />
      </Button>
      <Button size="large" type="primary">
        <FormattedMessage
          id="fetch.disposition"
          defaultMessage="Fetch Disposition"
        />
      </Button>
    </FlexContainer>
  );
};

export default PurchaseRequest;
