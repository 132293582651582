import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig, { SALES_ACTION } from '@apps/sales';
import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import { SaleOperationSettings } from '@apps/sales/services/saleOperation/namespace';
import { patch, post } from '@services/api';
import moment from 'moment/moment';

const COLLECTION_NAME = 'saleOperationSettings';

export const getSaleOperationSettings = (step: string, ecosystemId: string) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-sales' as SALES_ACTION,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds.includes(ecosystemId)) {
    return Promise.resolve([]);
  }

  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .where('ecosystem', '==', ecosystemId)
    .where('step', '==', step)
    .get()
    .then(getArrayWithIds) as Promise<SaleOperationSettings[]>;
};

export const updateSaleOperationSettings = (
  id: string,
  saleOperationSettings: SaleOperationSettings,
) =>
  patch(`${COLLECTION_NAME}/${id}`, {
    ...saleOperationSettings,
    timestamp: moment().valueOf(),
  });

export const addSaleOperation = (
  saleOperationSettings: SaleOperationSettings,
) =>
  post(`${COLLECTION_NAME}`, {
    ...saleOperationSettings,
    timestamp: moment().valueOf(),
  });
