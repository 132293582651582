import { useEffect, useState } from 'react';
import Storage from '@services/Firebase/Storage';

const getRegulationDocument = async (documentName: string) =>
  (
    await Storage.getUrl(`public/regulations/${documentName}.pdf`)
  ).getDownloadURL();

export const useTOSAndPP = () => {
  const [tos, setTos] = useState<string | null>(null);
  const [pp, setPp] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      let resp: string[];
      try {
        resp = await Promise.all([
          getRegulationDocument('privacyPolicy'),
          getRegulationDocument('termOfService'),
        ]);
        setPp(resp[0]);
        setTos(resp[1]);
      } catch {
        console.error(
          'Cant retrieve Term Of Service or Privacy Policy documents.',
        );
      }
    })();
  }, []);

  return [tos, pp];
};
