import { styled } from '@styles/themes';
import { Menu } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 12px;
`;

export const StyledMenu = styled(Menu)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 8px;
  &:after,
  &:before {
    display: none;
  }
`;
