import { ACTIVITY_COLLECTION } from './namespace';
import { post } from '@services/api';
import moment from 'moment';
import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';

export const createActivity = (activity: ActivityEntry) => {
  return post(
    `${ACTIVITY_COLLECTION}`,
    {
      ...activity,
      timestamp: moment().valueOf(),
    },
    {
      message: false,
    },
  );
};

type GetActivityForUserInAppPayload = {
  userId: string;
  ecosystemId: string;
  appId: string;
};

export const getActivityForUserInApp = ({
  ecosystemId,
  appId,
  userId,
}: GetActivityForUserInAppPayload) => {
  return firebase.firestore
    ?.collection(ACTIVITY_COLLECTION)
    .where('ecosystem', '==', ecosystemId)
    .where('appId', '==', appId)
    .where('userId', '==', userId)
    .get()
    .then(getArrayWithIds) as Promise<ActivityEntry[]>;
};

type GetActivityForAppPayload = {
  ecosystemId: string;
  appId: string;
};

export const getActivityForApp = ({
  ecosystemId,
  appId,
}: GetActivityForAppPayload) => {
  return firebase.firestore
    ?.collection(ACTIVITY_COLLECTION)
    .where('ecosystem', '==', ecosystemId)
    .where('appId', '==', appId)
    .get()
    .then(getArrayWithIds) as Promise<ActivityEntry[]>;
};
