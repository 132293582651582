import React, { FC } from 'react';
import { Form, FormItemProps, DatePicker, DatePickerProps } from 'antd';

const { Item } = Form;

type Props = DatePickerProps & {
  formItemProps: FormItemProps;
};

const DateField: FC<Props> = ({ formItemProps, ...pickerProps }) => {
  return (
    <Item {...formItemProps} onReset={formItemProps.onReset as any}>
      <DatePicker {...pickerProps} />
    </Item>
  );
};
export default DateField;
