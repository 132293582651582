import React from 'react';
import intl from '../../../../../../../../intl';
import UnitAmount from './UnitAmount';
import ProductDetailsToggle from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/priceForm/ProductDetailsToggle';
import ProductDetailsInput from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/priceForm/ProductDetailsInput';

const StandardPrice: React.FC = () => {
  return (
    <>
      <UnitAmount />
      <ProductDetailsToggle
        name="isTaxInclusive"
        label={intl.formatMessage({
          id: 'sales.salesTab.isTaxInclusive.label',
          defaultMessage: 'Is task inclusive?',
        })}
      />
      <ProductDetailsInput
        name="description"
        label={intl.formatMessage({
          id: 'sales.salesTab.description.label',
          defaultMessage: 'Description',
        })}
      />
    </>
  );
};

export default StandardPrice;
