import { FieldType, LayoutField } from '@apps/documents/service/namespace';
import {
  CommonFieldsLayout,
  SideViewCommonFieldsLayout,
} from '@apps/documents/service/commonFields.layouts';
import { LayoutDestination } from '@apps/documents/service/layouts';
import { getTaxRates } from '@services/dictionary';

export const ProcessingNewTypeLayout: LayoutField[] = [
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.processinginvoice.docnumber.label',
    rules: [
      {
        required: true,
        message: 'documents.processinginvoice.docnumber.error',
      },
    ],
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.receivingdate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.receivingdate.placeholder',
    noFutureDate: true,
  },
  {
    fieldName: 'documentDueDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.documentduedate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.documentduedate.placeholder',
  },
  {
    fieldName: 'totalNetAmount',
    fieldType: 'moneyInput',
    label: 'documents.processinginvoice.totalnetamount.label',
  },
  /* {
    fieldName: 'currency',
    fieldType: 'input',
    label: 'documents.processinginvoice.currency.label',
  }, */
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.processinginvoice.referencenumber.label',
  },
  {
    fieldName: 'taxAmount',
    fieldType: 'moneyInput',
    label: 'documents.processinginvoice.taxamount.label',
  },
  {
    fieldName: 'taxRate',
    fieldType: 'select',
    label: 'documents.processinginvoice.taxrate.label',
    rules: [
      { required: true, message: 'documents.processinginvoice.taxrate.error' },
    ],
    options: getTaxRates(),
  },
  {
    fieldName: 'totalGrossAmount',
    fieldType: 'moneyInput',
    label: 'documents.processinginvoice.totalgrossamount.label',
  },
];

export const ProcessingSummaryNewTypeLayout: LayoutField[] =
  ProcessingNewTypeLayout.map((field) => ({
    ...field,
    fieldType: ['datepicker'].includes(field.fieldType)
      ? (field.fieldType as FieldType)
      : 'input',
    placeholder: '',
    style: {
      border: 0,
    },
  }));

export const NewTypeLayout: LayoutField[] = [
  ...CommonFieldsLayout,
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.invoice.docnumber.label',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.invoice.receivingdate.label',
    type: 'date',
    placeholder: 'documents.invoice.receivingdate.placeholder',
  },
  {
    fieldName: 'documentDueDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.invoice.documentduedate.label',
    type: 'date',
    placeholder: 'documents.invoice.documentduedate.placeholder',
  },
  {
    fieldName: 'totalNetAmount',
    fieldType: 'moneyInput',
    label: 'documents.invoice.totalnetamount.label',
  },
  /* {
    fieldName: 'currency',
    fieldType: 'input',
    label: 'documents.invoice.currency.label',
  }, */
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.invoice.referencenumber.label',
  },
  {
    fieldName: 'taxAmount',
    fieldType: 'moneyInput',
    label: 'documents.invoice.taxamount.label',
  },
  {
    fieldName: 'taxRate',
    fieldType: 'select',
    label: 'documents.invoice.taxrate.label',
    options: getTaxRates(),
  },
  {
    fieldName: 'totalGrossAmount',
    fieldType: 'moneyInput',
    label: 'documents.invoice.totalgrossamount.label',
  },
];

export const SideViewNewTypeLayout: LayoutField[] = [
  ...SideViewCommonFieldsLayout,
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.invoice.sideview.docnumber.label',
    readOnly: true,
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.invoice.sideview.receivingdate.label',
    type: 'date',
  },
  {
    fieldName: 'documentDueDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.invoice.sideview.documentduedate.label',
    type: 'date',
  },
  {
    fieldName: 'totalNetAmount',
    fieldType: 'moneyInput',
    label: 'documents.invoice.sideview.totalnetamount.label',
    readOnly: true,
  },
  /* {
    fieldName: 'currency',
    fieldType: 'input',
    label: 'documents.invoice.sideview.currency.label',
    readOnly: true,
  }, */
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.invoice.sideview.referencenumber.label',
    readOnly: true,
  },
  {
    fieldName: 'taxAmount',
    fieldType: 'moneyInput',
    label: 'documents.invoice.sideview.taxamount.label',
    readOnly: true,
  },
  {
    fieldName: 'taxRate',
    fieldType: 'input',
    label: 'documents.invoice.sideview.taxrate.label',
    readOnly: true,
  },
  {
    fieldName: 'totalGrossAmount',
    fieldType: 'moneyInput',
    label: 'documents.invoice.sideview.totalgrossamount.label',
    readOnly: true,
  },
];

const newTypeLayouts: Record<LayoutDestination, LayoutField[]> = {
  detailedForm: NewTypeLayout,
  sideView: SideViewNewTypeLayout,
  processing: ProcessingNewTypeLayout,
  processingSummary: ProcessingSummaryNewTypeLayout,
};

export default newTypeLayouts;
