export type TaxCategory = {
  id?: string;
  isActive: boolean;
  ecosystemId: string;
  name: string;
  value: number;
  label: Record<string, string>;
  timestamp?: number;
};

export const TAX_CATEGORIES_COLLECTION = 'taxCategories';
