import React from 'react';
import { useIntl } from 'react-intl';
import { EcosystemRenderer } from '@components/List/FrameworkComponents';
import { getContactName } from '@apps/documents/service';
import UserRenderer from '../../../components/List/FrameworkComponents/UserRenderer';

export type DocumentFieldProps = {
  colDef: any;
  data: any;
  value: any;
  [key: string]: any;
};

const DocumentFieldRenderer: React.FC<DocumentFieldProps> = ({
  colDef,
  data,
  value,
}) => {
  const intl = useIntl();
  const { field } = colDef;
  if (!data || !value) {
    return '';
  }
  if (field === 'category') {
    return (
      <>
        {intl.formatMessage({
          id: `documents.documentTypes.category.${value}`,
          defaultMessage: value,
        })}
      </>
    );
  }
  if (field === 'type') {
    return (
      <>
        {intl.formatMessage({
          id: `documents.documentTypes.${value}`,
          defaultMessage: value,
        })}
      </>
    );
  }
  if (field === 'ecosystem') {
    return <EcosystemRenderer data={data} />;
  }
  if (field === 'creatingUser' || field === 'uploadingUser') {
    return data.creatingUser.displayName ? (
      data.creatingUser.displayName
    ) : (
      <UserRenderer value={data.creatingUser} />
    );
  }
  if (field === 'relatedContactSnapshot' && data.relatedContactSnapshot) {
    return <>{getContactName(data.relatedContactSnapshot)}</>;
  }
  if (field === 'fileDetails' && data.fileDetails) {
    const { format, size } = data.fileDetails;
    return (
      <>
        {format}, {(size / 1024 / 1024).toFixed(2)}MB
      </>
    );
  }
  return <>{String(value || '')}</>;
};

export default DocumentFieldRenderer;
