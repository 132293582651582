import get from 'lodash.get';
import set from 'lodash.set';
import {
  post,
  get as apiGet,
  getWithId,
  deleteRequest,
  put,
  patch,
} from '@services/api';
import { emitCustomEvent } from '@core/services';
import { Task, TASKS_COLLECTION_NAME } from './namespace';
import { ListEvents } from '@components/List';
import { getFilteredData } from '@services/filtering';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import moment from 'moment';
import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import getAnyRelations from '@services/entityRelations/getAnyRelations';
import removeRelation from '@services/entityRelations/removeRelation';
import appConfig, { TASKS_ACTION } from '@apps/tasks';

export * from './namespace';

export type GetFilterParams = {
  query?: string;
  offset?: number;
  limit?: number;
  fields?: string[];
  filters?: object;
  sort?: string[];
};

type ApiResponse = {
  results: object[];
  info: {
    results: number;
  };
};

export type GetFilteredData = (
  params: GetFilterParams,
  action: string,
) => Promise<ApiResponse>;

export type GetTasks = (
  params: GetFilterParams,
  action: TASKS_ACTION,
) => Promise<ApiResponse>;

// @ts-ignore
export const getTasks: GetTasks = (p, action) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 50,
    fields: ['id', 'ecosystem'],
    filters: {},
    sort: [],
    ...p,
  };
  // @ts-ignore
  return apiGet(TASKS_COLLECTION_NAME)?.then((data: Task[]) => {
    // filter, sort, limit (need info about all records)
    const filterEntries = Object.entries(params.filters);
    const shouldFilter = filterEntries.length;
    const shouldSort = !!params.sort.length;
    const filteredByEcosystems = data.filter((row) =>
      activeEcosystemsIds.includes(row.ecosystem as string),
    );
    const filteredByQuery = filteredByEcosystems.filter((row) =>
      params.fields.some((path) => {
        const fieldValue = get(row, path);
        if (typeof fieldValue !== 'string') {
          return false;
        }
        const queryLower = params.query.toLowerCase();
        return fieldValue.toLowerCase().includes(queryLower);
      }),
    );
    const filtered = shouldFilter
      ? getFilteredData(filteredByQuery, filterEntries)
      : filteredByQuery;
    const sorted = shouldSort
      ? [...filtered].sort((rowA, rowB) => {
          const sortKey = params.sort[0];
          const desc = sortKey.split('').includes('-');
          const path = sortKey
            .split('')
            .filter((c) => c !== '-')
            .join('');
          const valueA = get(rowA, path);
          const valueB = get(rowB, path);
          if (valueA === valueB) {
            return 0;
          }
          if (valueA < valueB) {
            return desc ? 1 : -1;
          } else {
            return desc ? -1 : 1;
          }
        })
      : filtered;
    const choosenFields = sorted.map((row) => {
      const newRow = {};
      params.fields.forEach((path: string): void => {
        const value = get(row, path);
        set(newRow, path, value);
      });
      return newRow;
    });
    const results = choosenFields.length;
    const page = choosenFields.slice(
      params.offset,
      params.offset + params.limit,
    );
    return {
      results: page,
      info: {
        results,
      },
    };
  });
};

export const getTask = (id: string) =>
  getWithId(`${TASKS_COLLECTION_NAME}/${id}`) as Promise<Task>;

export const updateTask = (id: string, task: Task) =>
  put(`${TASKS_COLLECTION_NAME}/${id}`, {
    ...task,
    timestamp: moment().valueOf(),
  });

export const patchTask = async (id: string, task: Partial<Task>) => {
  const response = await patch(`${TASKS_COLLECTION_NAME}/${id}`, {
    ...task,
    timestamp: moment().valueOf(),
  });
  // TODO: Have a common name for event that will refresh the list
  emitCustomEvent('refreshList');
  return response;
};

export const postTask = (task: Task) =>
  post(TASKS_COLLECTION_NAME, { ...task, timestamp: moment().valueOf() });

export const deleteTask = (id: string) =>
  deleteRequest(`${TASKS_COLLECTION_NAME}/${id}`)?.then((response) => {
    emitCustomEvent<ListEvents>('refreshList');
    //search task as parent
    getAnyRelations({
      key: 'parentId',
      value: id,
    }).then((relations) => {
      relations.forEach((relation) => {
        removeRelation(relation.id as string);
      });
    });
    //search task as child
    getAnyRelations({
      key: 'childId',
      value: id,
    }).then((relations) => {
      relations.forEach((relation) => {
        removeRelation(relation.id as string);
      });
    });
    return response;
  });

export const getTasksByEcosystemId = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection(TASKS_COLLECTION_NAME)
    .where('ecosystem', '==', ecosystemId)
    .get()
    .then(getArrayWithIds) as Promise<Task[]>;
};
