import { normalize } from 'styled-normalize';
import { createGlobalStyle, DefaultTheme } from 'styled-components';
import SegoeUI from './assets/fonts/SegoeUI.woff';
import SegoeUIBold from './assets/fonts/SegoeUIBold.woff';

export const GlobalStyles = createGlobalStyle<DefaultTheme>`
  ${normalize}
    
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: normal;
    src: url(${SegoeUI}) format('truetype');
  }
  
   @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: bold;
    src: url(${SegoeUIBold}) format('truetype');
  }
  
  body, html {
    font-family: 'Segoe UI';
    color: ${(props) => props.theme.colors.text.normal};
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
  
  * {
    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.colors.scrollbar.thumb} ${(
  props,
) => props.theme.colors.scrollbar.track};
  }
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.scrollbar.track};
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.scrollbar.thumb};
    border-radius: 100px;
    border: 3px solid ${(props) => props.theme.colors.scrollbar.track};
  }
`;
