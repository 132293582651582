import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useContext,
  ComponentType,
  useEffect,
} from 'react';
import { Divider, Form, message, Select } from 'antd';
import debounce from 'lodash.debounce';
// import { CheckCircleOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/es/select';
import { FormInstance } from 'antd/lib/form';
import { ThemeContext } from 'styled-components';
import { Document, getDocument, updateDocument } from '@apps/documents/service';
import {
  InfoIcon,
  OptionContentWrapper,
  OptionLabel,
  SelectStep,
  Wrapper,
} from '@apps/documents/mainMenu/DocumentProcessing/DocumentProcessing.sc';
import Step1 from '@apps/documents/mainMenu/DocumentProcessing/Step1';
import Step2 from '@apps/documents/mainMenu/DocumentProcessing/Step2';
import Step3 from '@apps/documents/mainMenu/DocumentProcessing/Step3';
import Step4 from '@apps/documents/mainMenu/DocumentProcessing/Step4';
import Step5 from '@apps/documents/mainMenu/DocumentProcessing/Step5';
import Step, {
  ChildrenPassedProps,
  StepProps,
} from '@apps/documents/mainMenu/DocumentProcessing/Step';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import UnsavedChangesPrompt from '@components/UnsavedChangesPrompt';
import { useIntl } from 'react-intl';
import { firebase } from '@services/Firebase';
import momentToFormatString from '@core/helpers/momentToFormatString';
import { getContact } from '@apps/contacts/services';
import moment from 'moment';

type Props = {
  document: Partial<Document>;
  setDetails: (arg: any) => void;
  id: string;
  onStepChange?: (value: SelectValue) => void;
};

export type DocumentProcessingEvents =
  | 'setRelatedContact'
  | 'setRelatedDocuments'
  | 'setDocument'
  | 'documentSaved';

const { Option } = Select;

const handleValue = (
  setFormDocument: any,
  formDocument: any,
  value: any,
  triggerValidator: any,
  option: any,
) => {
  if (setFormDocument) {
    setFormDocument({
      ...formDocument,
      ...value,
    });
  }

  if (triggerValidator) {
    triggerValidator(option.value);
  }
};

const debouncedHandleValue = debounce(handleValue, 500);

const initialFormValidState: Record<string, boolean> = {
  step1: false,
  step2: false,
  step3: false,
  step4: false,
  step5: false,
};

const DocumentProcessing: FC<Props> = ({
  document,
  setDetails,
  id,
  onStepChange = () => {},
}) => {
  const intl = useIntl();
  const { colors } = useContext(ThemeContext);
  const [currentStep, setCurrentStep] = useState<SelectValue>('step1');
  const [formDocument, setFormDocument] = useState(document);
  const [submitting, setSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [stepsForms, setStepsForms] = useState<
    Record<string, FormInstance | undefined>
  >({
    step1: undefined,
    step2: undefined,
    step3: undefined,
    step4: undefined,
    step5: undefined,
  });
  const [areFormsValid, setAreFormsValid] = useState<Record<string, boolean>>(
    initialFormValidState,
  );
  const shouldUpdate = useCallback((prev, curr) => {
    return prev.type !== curr.type;
  }, []);

  useEffect(() => {
    setDetails(formDocument);
  }, [formDocument]);

  const options = useMemo(
    () => [
      {
        value: 'step1',
        label: intl.formatMessage({
          id: 'documentprocessing.step1',
          defaultMessage: 'Step 1/5: Select document type and category',
        }),
      },
      {
        value: 'step2',
        label: intl.formatMessage({
          id: 'documentprocessing.step2',
          defaultMessage: 'Step 2/5: Select related contact',
        }),
      },
      {
        value: 'step3',
        label: intl.formatMessage({
          id: 'documentprocessing.step3',
          defaultMessage: 'Step 3/5: Provide document details',
        }),
      },
      {
        value: 'step4',
        label: intl.formatMessage({
          id: 'documentprocessing.step4',
          defaultMessage: 'Step 4/5: Select related documents',
        }),
      },
      {
        value: 'step5',
        label: intl.formatMessage({
          id: 'documentprocessing.step5',
          defaultMessage: 'Step 5/5: Processing summary',
        }),
      },
    ],
    [intl],
  );

  const stepLayout: Record<string, ComponentType<ChildrenPassedProps>> = {
    step1: Step1,
    step2: Step2,
    step3: Step3,
    step4: Step4,
    step5: Step5,
  };

  useEffect(() => {
    // lunch once all are ready
    const formsNames = Object.keys(stepsForms);
    const allRegisteredForms = formsNames.filter(
      (name) => stepsForms[name] !== null,
    );

    if (allRegisteredForms.length > 4) {
      const forms = allRegisteredForms.map((name) => {
        const form = stepsForms[name];

        if (form) {
          return form
            .validateFields()
            .then(() => Promise.resolve({ name, isValid: true }))
            .catch((e) => {
              return Promise.resolve({
                name,
                isValid: e.errorFields.length < 1,
              });
            });
        }

        return null;
      });

      if (forms.filter((form) => form === null).length < 1) {
        Promise.all(forms)
          .then((r) => {
            const formsValid = r.reduce((acc, item) => {
              return {
                ...acc,
                [item?.name as string]: item?.isValid,
              };
            }, {});

            setAreFormsValid(formsValid);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }, [stepsForms]);

  const triggerValidator = (name: string) => {
    const form = stepsForms[name];

    if (form) {
      form
        .validateFields()
        .then(() => {
          setAreFormsValid({
            ...areFormsValid,
            [name]: true,
          });
        })
        .catch((e) => {
          //TODO: must check why validate is failed with empty errorFields array - there are no errors?
          setAreFormsValid({
            ...areFormsValid,
            [name]: e.errorFields.length < 1,
          });
        });
    }
  };

  useEffect(() => {
    if (!areFormsValid['step5']) {
      const inValidForms = Object.keys(areFormsValid)
        .filter((key) => key !== 'step5')
        .filter((key) => !areFormsValid[key]);

      if (!inValidForms.length) {
        setAreFormsValid({
          ...areFormsValid,
          step5: true,
        });
      }
    }
  }, [areFormsValid]);

  useCustomEventListener('setRelatedContact', (relatedContactSnapshot) => {
    setFormDocument({
      ...formDocument,
      relatedContactSnapshot,
    });
  });
  useCustomEventListener('setRelatedDocuments', (relatedDocumentsSnapshot) => {
    setFormDocument({
      ...formDocument,
      relatedDocumentsSnapshot,
    });
  });

  const handleSave = useCallback(
    async (asDraft: boolean) => {
      setSubmitting(true);
      const user = firebase.auth.currentUser;

      const beDocument = await getDocument(id);

      if (!beDocument.isDraft) {
        emitCustomEvent<DocumentProcessingEvents, Partial<Document>>(
          'documentSaved',
          beDocument,
        );

        message.error(
          intl.formatMessage({
            id: 'documentprocessing.handlesave.error.alreadysaved',
            defaultMessage: 'File is already in archive',
          }),
          5,
        );
        setSubmitting(false);
        return;
      }

      const doc: Document = {
        // get fields not included in form, like file details, documentUrl...
        ...formDocument,
        ...stepsForms.step4?.getFieldsValue(),
        ...stepsForms.step3?.getFieldsValue(),
        ...stepsForms.step2?.getFieldsValue(),
        ...stepsForms.step1?.getFieldsValue(),
        isDraft: asDraft,
        hasCostStructure:
          stepsForms.step3?.getFieldsValue().costStructure?.length > 0,
        creationDate: moment(),
        creatingUser: {
          displayName: user?.displayName,
          photoURL: user?.photoURL,
          email: user?.email,
        },
      };

      if (doc.relatedContact) {
        const relatedContactSnapshot = await getContact(doc.relatedContact);
        doc.relatedContactSnapshot = relatedContactSnapshot;
      }

      if (doc.payments) {
        doc.payments = doc.payments.map((payment) =>
          momentToFormatString(payment),
        );
      }
      updateDocument(id, doc)
        ?.then(() => {
          setIsDirty(false);
          setSubmitting(false);

          if (!asDraft) {
            emitCustomEvent<DocumentProcessingEvents, Partial<Document>>(
              'documentSaved',
              doc,
            );
          }
        })
        .catch(() => {
          message.error(
            asDraft
              ? intl.formatMessage({
                  id: 'documentprocessing.handlesave.error.draft',
                  defaultMessage: 'Error while saving document',
                })
              : intl.formatMessage({
                  id: 'documentprocessing.handlesave.error.archived',
                  defaultMessage: 'Error while archiving document',
                }),
          );
          setSubmitting(false);
        });

      /*   if (doc.relatedContact) {
        const relatedContactSnapshot = await getContact(doc.relatedContact);
        doc.relatedContactSnapshot = relatedContactSnapshot;
      }*/
      /*if (doc.relatedDocuments) {
        const relatedCodumentsSnapshot = await getDocumentsByIds(
          doc.relatedDocuments,
        );
        doc.relatedDocumentsSnapshot = relatedCodumentsSnapshot;
      }*/
    },
    [
      formDocument,
      id,
      intl,
      stepsForms.step1,
      stepsForms.step2,
      stepsForms.step3,
      stepsForms.step4,
    ],
  );

  const renderedForms = options.map((option, index) => {
    const props: StepProps = {
      formProps: {
        name: option.value,
        hidden: option.value !== currentStep,
        initialValues: {
          ...formDocument,
          type: document.type,
          category: document.category,
        },
        onValuesChange: (value) => {
          debouncedHandleValue(
            setFormDocument,
            formDocument,
            value,
            triggerValidator,
            option,
          );
        },
        shouldUpdate,
        registerValidateFunction(name: string, validateFunc: FormInstance) {
          if (!stepsForms[name]) {
            setStepsForms({
              ...stepsForms,
              [name]: validateFunc,
            });
          }
        },
        form: stepsForms[option.value],
      },
      step: index + 1,
      steps: options.length,
      submitButton:
        index < options.length - 1
          ? {
              label: intl.formatMessage({
                id: 'documentprocessing.submitbutton.continue',
                defaultMessage: 'Continue',
              }),
              disabled: !areFormsValid[option.value] || submitting,
            }
          : {
              onClick: () => handleSave(false),
              label: intl.formatMessage({
                id: 'documentprocessing.submitbutton.save',
                defaultMessage: 'Save document',
              }),
              disabled: !areFormsValid[option.value] || submitting,
            },
      cancelButton: {
        onClick: () => {
          stepsForms['step1']?.resetFields();
          stepsForms['step2']?.resetFields();
          stepsForms['step3']?.resetFields();
          stepsForms['step4']?.resetFields();
          stepsForms['step5']?.resetFields();
          setCurrentStep('step1');
          setAreFormsValid(initialFormValidState);
          setFormDocument(document);
          setIsDirty(false);
          triggerValidator('step1');
        },
        label: intl.formatMessage({
          id: 'documentprocessing.cancelbutton',
          defaultMessage: 'Cancel',
        }),
        disabled: submitting,
      },
      submitDraftButton: {
        onClick: () => handleSave(true),
        label: intl.formatMessage({
          id: 'documentprocessing.submitdraftbutton',
          defaultMessage: 'Save a draft',
        }),
        disabled: submitting,
      },
      document: formDocument,
      triggerValidator,
    };
    return (
      <Step key={option.value} {...props}>
        {(passedProps) => {
          const StepComponent = stepLayout[
            option.value as string
          ] as React.ComponentType;
          return <StepComponent {...passedProps} />;
        }}
      </Step>
    );
  });

  return (
    <Form.Provider
      onFormChange={(_name, { changedFields, forms }) => {
        const areAnyChanges =
          changedFields.filter((field) => field.touched && !field.validating)
            .length > 0;

        if (!isDirty && areAnyChanges) {
          setIsDirty(true);
        }

        if (areAnyChanges) {
          emitCustomEvent<DocumentProcessingEvents, Partial<Document>>(
            'setDocument',
            formDocument,
          );
        }

        if (forms['step5'] && changedFields.length) {
          forms['step5'].setFields(changedFields);
        }
      }}
      onFormFinish={(currentStep) => {
        // protection for forms other than step forms
        if (options.some((step) => step.value === currentStep)) {
          const nextStep =
            options.findIndex((step) => step.value === currentStep) + 1;

          if (nextStep < options.length) {
            setCurrentStep(options[nextStep].value);
          }
        }
      }}
    >
      <Divider type="horizontal" />
      <Wrapper>
        <InfoIcon />
        <SelectStep
          onChange={(value: any) => {
            setCurrentStep(value);
            if (onStepChange) {
              onStepChange(value);
            }
          }}
          value={currentStep}
        >
          {options.map((option, index) => {
            const { value, label } = option;
            // const isStepValid = areFormsValid[option.value];
            const isDisabled =
              index > 0 &&
              currentStep !== value &&
              !areFormsValid[options[index - 1].value];

            return (
              <Option key={value} value={value} disabled={isDisabled}>
                <OptionContentWrapper>
                  <OptionLabel
                    style={{
                      color: isDisabled
                        ? colors.text.lightGray
                        : colors.text.normal,
                    }}
                  >
                    {label}
                  </OptionLabel>
                </OptionContentWrapper>
              </Option>
            );
          })}
        </SelectStep>
      </Wrapper>
      <Divider type="horizontal" />
      {renderedForms}
      <UnsavedChangesPrompt when={isDirty} />
    </Form.Provider>
  );
};

export default DocumentProcessing;
