import styled from 'styled-components';

export const TwoColumnsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
`;
