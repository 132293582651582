import React, { FC } from 'react';
import { Form } from 'antd';
import intl from '../../../../../../intl';
import FloatNumberedField from '@components/FloatNumberedField';

const { Item } = Form;

const TaxValue: FC = () => {
  return (
    <Item
      name="taxValue"
      label={intl.formatMessage({
        id: 'sales.createForm.tax.label',
        defaultMessage: 'Tax (%)',
      })}
    >
      <FloatNumberedField
        allowNegative={false}
        max={100}
        decimalSeparator={','}
        decimalScale={2}
        thousandSeparator={'.'}
      />
    </Item>
  );
};

export default TaxValue;
