import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Form, Modal } from 'antd';
import intl from '../../../../../intl';
import {
  Commission,
  createCommission,
  updateCommission,
} from '@apps/directSales/services/commisions';
import CommissionForm from '@apps/directSales/settings/Commissions/CommissionForm';

type Props = {
  ecosystemId: string;
  visible: boolean;
  commission: Commission | null;
  onCancel(): void;
  onOk(): void;
};
const CommissionModal: FC<Props> = ({
  visible,
  onOk,
  onCancel,
  commission,
  ecosystemId,
}) => {
  const [form] = Form.useForm<Commission>();

  const okText = useMemo(() => {
    if (commission && commission.id) {
      return intl.formatMessage({
        id: 'button.edit',
        defaultMessage: 'Edit',
      });
    }
    return intl.formatMessage({
      id: 'button.create',
      defaultMessage: 'Create',
    });
  }, [commission]);

  const cancelText = useMemo(() => {
    return intl.formatMessage({
      id: 'button.cancel',
      defaultMessage: 'Cancel',
    });
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  const handleOk = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        if (commission && commission.id) {
          updateCommission(commission.id, {
            ...commission,
            ...values,
          })?.then(() => {
            form.resetFields();
            onOk();
          });
        } else {
          createCommission({
            ...values,
            ecosystemId,
          })?.then(() => {
            form.resetFields();
            onOk();
          });
        }
      })
      .catch((info) => {
        console.warn('Validation failed: ', info);
      });
  }, [ecosystemId, form, onOk, commission]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={handleCancel}
      width="66vw"
    >
      <CommissionForm
        form={form}
        commission={commission}
        ecosystemId={ecosystemId}
      />
    </Modal>
  );
};

export default CommissionModal;
