import { Contact, postContact } from '@apps/contacts/services';
import { CommissionEntry } from '@apps/directSales/settings/Import/utils/parseToCommission';
import { post } from '@services/api';
import { firebase } from '@services/Firebase';
import { Consultant } from '@apps/directSales/mainMenu/Consultants/services';
import moment from 'moment';

const monthFormat = 'YYYY_M';

export const createSaleCommission = async (
  commissions: Partial<CommissionEntry>[],
  ecosystemId: string,
) => {
  try {
    for (const commission of commissions) {
      if (
        commission.contactSnapshot.firstName &&
        commission.contactSnapshot.lastName
      ) {
        const newContact = {
          avatar:
            'https://firebasestorage.googleapis.com/v0/b/todix-platform-ui.appspot.com/o/simple-avatar.svg?alt=media&token=3842cd57-6fdb-4109-9301-6d92786e9b41',
          contacts: {
            ecosystem: ecosystemId,
            standardInvoiceAddress: 0,
            standardShippingAddress: 0,
            firstName: commission.contactSnapshot.firstName,
            lastName: commission.contactSnapshot.lastName,
            type: 'person',
          },
        };

        const contacts = await getContact(
          ecosystemId,
          newContact.contacts.firstName,
          newContact.contacts.lastName,
        );

        if (contacts && contacts?.length > 1) {
          throw new Error('Duplicate Contacts found');
        }

        if (contacts && contacts[0]?.id) {
          commission.contact = contacts[0]?.id;
        } else {
          const contactId = await postContact(newContact);
          commission.contact = contactId;
        }
      }
      if (commission.sellerId) {
        const consultantSnap = (
          await firebase.firestore
            ?.collection('consultants')
            .where('consultantNo', '==', commission.sellerId)
            .get()
        )?.docs[0];

        if (!consultantSnap) {
          continue;
        }
        const consultant = consultantSnap.data() as Consultant;
        let consultantRecruitingTime = '';
        if (
          consultant &&
          consultant.applicationDate &&
          !String(consultant.applicationDate).toLowerCase().includes('nan')
        ) {
          consultantRecruitingTime = moment(
            consultant.applicationDate,
            'DD.MM.YYYY',
          ).format(monthFormat);
        } else {
          console.error(
            `Invalid application date: ${consultant.applicationDate}`,
          );
        }
        await post('commissionEntries', {
          ...commission,
          consultantRecruitingTime,
          sellerId: consultantSnap.id,
          sellerPeriodToken: `${consultantSnap.id}_${commission.period}_${commission.ecosystem}`,
        } as CommissionEntry);
      }
    }
  } catch (e) {
    throw new Error(
      `An unexpected error occurred during commission entry creation: ${e.message}`,
    );
  }
};

const getContact = async (
  ecosystemId: string,
  firstName: string,
  lastName: string,
) => {
  return firebase.firestore
    ?.collection('contacts')
    .where('contacts.ecosystem', '==', ecosystemId)
    .where('contacts.firstName', '==', firstName)
    .where('contacts.lastName', '==', lastName)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs as Contact[];
    });
};
