import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DashboardOutlined, NodeIndexOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Card } from '@todix/ui-components';
import { Product, deleteProduct } from '@apps/products/services';
import { Document } from '@apps/documents/service/namespace';

import Tabs from '@components/Tabs';
import { ActionRenderer } from '@components/AppView/ActionRenderer';

import { DocumentWithRelationFlag, Form, FormProps } from '../Form';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Form as AtdForm } from 'antd';
import optionsMapper from '@core/helpers/optionsMapper';
import ProductsAppConfig, { PRODUCTS_ACTION } from '@apps/products';
import { getSalesTab } from '@apps/sales/widgets/SalesTab';
import { useSelector } from 'react-redux';
import {
  checkUserPermissions,
  getEcosystemById,
} from '@components/EcosystemIndicator/store';
import { useForm } from 'antd/lib/form/Form';
import getArchivedDocumentsTab from '@apps/documents/mainMenu/widgets/ArchivedDocumentsTab';
import getExtraOptions from '@apps/products/mainMenu/components/DetailedProduct/getExtraOptions';
import WidgetResolver from '@components/WidgetResolver';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import Relations from '@components/Relations';
import getWarehouseTab from '@apps/warehouse/widgets/Warehouse';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import { rootStore } from '@core/store';
import { getPurchasesTab } from '@apps/purchases/mainMenu/purchasesTabForProduct';
import AddProductContactConstraint from '@apps/products/mainMenu/components/DetailedProduct/AddProductContactConstraint';
import getBatchSerialNumberTab from './getBatchSerialNumberTab';

const { TabPane } = Tabs;
const FormProvider = AtdForm.Provider;

type DetailedProductProps = Partial<
  FormProps & {
    id: string;
    shouldRedirect?: boolean;
    showExtraContent?: boolean;
  }
> & {
  action: PRODUCTS_ACTION;
};

export const DetailedProduct: FC<DetailedProductProps> = ({
  id,
  initialValues = {},
  onSubmit,
  onCancel,
  readOnly,
  shouldRedirect,
  showExtraContent = true,
  action,
}) => {
  const [relatedDocuments, setRelatedDocuments] = useState<
    DocumentWithRelationFlag[]
  >([]);
  const history = useHistory();
  const intl = useIntl();

  const canUpdate = initialValues
    ? checkUserPermissions(
        rootStore.getState(),
        ProductsAppConfig.todixId,
        'update-products' as PRODUCTS_ACTION,
        initialValues?.ecosystem as string,
      )
    : false;

  const canDelete = initialValues
    ? checkUserPermissions(
        rootStore.getState(),
        ProductsAppConfig.todixId,
        'delete-products' as PRODUCTS_ACTION,
        initialValues?.ecosystem as string,
      )
    : false;

  const [form] = useForm();
  const [forms, setForms] = useState<any>(null);
  const [ecosystemId, setEcosystemId] = useState(
    initialValues.ecosystem as string,
  );
  const fullEcosystem = useSelector(getEcosystemById(ecosystemId)) as Ecosystem;
  const parseValues = (product: Product, docs?: Document[]) => {
    const parsedValues = {
      ...initialValues,
      ...product,
      isActive: !!product.isActive,
      canBeBought:
        initialValues?.canBeBought !== undefined
          ? initialValues.canBeBought
          : !!product.canBeBought,
      productCreationDate: moment(product.productCreationDate).format(),
    } as Product;

    if (onSubmit) {
      onSubmit(parsedValues, docs);
    }
  };

  useCustomEventListener('relatedDocumentsChanged', (docs) => {
    if (docs) {
      setRelatedDocuments(docs as unknown as DocumentWithRelationFlag[]);
    }
  });

  useCustomEventListener('documentRemoved', (data: any) => {
    if (data && data.id) {
      setRelatedDocuments(relatedDocuments.filter((doc) => doc.id !== data.id));
    }
  });

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${ProductsAppConfig.todixId}`,
        intl.formatMessage({
          id: 'products.detailedproduct.nav.products',
          defaultMessage: 'Products',
        }),
      ],
      initialValues.productName
        ? [
            `/app/${ProductsAppConfig.todixId}/${initialValues.productName}`,
            initialValues.productName,
          ]
        : [
            `/app/${ProductsAppConfig.todixId}/create`,
            intl.formatMessage({
              id: 'products.detailedproduct.nav.create',
              defaultMessage: 'Create',
            }),
          ],
    ];
    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [id, initialValues, intl]);

  return (
    <FormProvider
      onFormChange={(_formName, { forms }) => {
        setEcosystemId(forms.productBasics.getFieldsValue().ecosystem);
        setForms(forms);
      }}
    >
      <Card>
        <Tabs
          defaultActiveKey="1"
          onChange={() => {}}
          tabBarExtraContent={
            showExtraContent ? (
              <ActionRenderer
                path={
                  id
                    ? `/app/${ProductsAppConfig.todixId}/${id}`
                    : `/app/${ProductsAppConfig.todixId}`
                }
                options={id && canDelete ? optionsMapper(['delete'], intl) : []}
                extraOptions={getExtraOptions({
                  parentId: id,
                  parentType: 'product',
                  intl,
                  ecosystemId,
                })}
                handleDelete={
                  id
                    ? () => {
                        if (canDelete) {
                          deleteProduct(id as string)
                            ?.then(() => {
                              history.push(`/app/${ProductsAppConfig.todixId}`);
                            })
                            .catch(() => {
                              message.error(
                                intl.formatMessage({
                                  id: 'products.deleteproduct.error',
                                  defaultMessage:
                                    'Error while contact creating',
                                }),
                              );
                            });
                        }
                      }
                    : () => {}
                }
                intl={intl}
              />
            ) : undefined
          }
        >
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="products.detailedproduct.basetab"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <Form
              id={id}
              name="productBasics"
              providedForm={form}
              initialValues={initialValues}
              onSubmit={(values, docs) => parseValues(values, docs)}
              onCancel={onCancel}
              readOnly={readOnly || !canUpdate}
              shouldRedirect={shouldRedirect}
              action={action}
              canUpdate={canUpdate}
            />
          </TabPane>
          {getSalesTab({
            fullEcosystem,
            integratedAppId: ProductsAppConfig.todixId,
            forms,
            id,
            initialValues,
            onCreateSubmit: (values) => parseValues(values),
            onSubmitSuccess: () => {
              history.push(`/app/${ProductsAppConfig.todixId}`);
            },
            readOnly,
          })}
          {getPurchasesTab({
            fullEcosystem,
            integratedAppId: ProductsAppConfig.todixId,
            forms,
            id,
            initialValues,
            onCreateSubmit: (values) => parseValues(values),
            onSubmitSuccess: () => {
              history.push(`/app/${ProductsAppConfig.todixId}`);
            },
            readOnly,
          })}
          {getArchivedDocumentsTab({
            intl,
            fullEcosystem,
            relatedDocuments,
          })}
          {getWarehouseTab({
            intl,
            fullEcosystem,
            integratedAppId: ProductsAppConfig.todixId,
            productId: id,
            readOnly,
          })}
          {getBatchSerialNumberTab({
            initialValues,
            readOnly: readOnly || !canUpdate,
            id,
          })}
          <TabPane
            key="relations"
            disabled={!initialValues?.id || !initialValues?.ecosystem}
            tab={
              <>
                <NodeIndexOutlined />{' '}
                <FormattedMessage
                  id="relations.tab"
                  defaultMessage="Relations"
                />
              </>
            }
          >
            {initialValues?.ecosystem && initialValues?.id && (
              <Relations
                ecosystem={initialValues.ecosystem}
                entityId={initialValues?.id}
                entityType="product"
              />
            )}
          </TabPane>
        </Tabs>
        <WidgetResolver
          appName={'documents'}
          widgetName={'InboxUploadWidget'}
          injectedProps={{}}
        />
        <AddProductContactConstraint />
      </Card>
    </FormProvider>
  );
};
