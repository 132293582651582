import React, { FC, Key, useCallback, useState, useEffect } from 'react';
import { Table, Popconfirm, Button } from 'antd';
import EditableRow from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/EditableRow';
import EditableCell from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/EditableCell';
import {
  BatchNumberEntry,
  FlowOption,
  ProductEntry,
} from '@apps/warehouse/services/namespace';
import { ColumnType } from 'antd/lib/table';
import { ProductEntriesContainer } from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/styles.sc';
import { useIntl } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';
import ProductSelector from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/ProductSelector';
import { Product } from '@apps/products/services';
import ButtonSerialNumber from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/ButtonSerialNumber';
import ButtonBatchNumber from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/ButtonBatchNumber';

type ProductEntriesProps = {
  entries: ProductEntry[];
  setEntries(entry: ProductEntry[]): void;
  count: number;
  setCount(count: number): void;
  selectedEcosystem: string | null;
  flow?: FlowOption | null;
  bucketId?: string;
  onSerialNumbersChange?: (positionId: string, serialNumbers: string[]) => void;
  serialNumbersMap?: Record<string, string[]>;
  onBatchNumbersChange?: (
    positionId: string,
    batchNumbers: BatchNumberEntry[],
  ) => void;
  batchNumbersMap?: Record<string, BatchNumberEntry[]>;
  onValidationChange?: (isValid: boolean) => void;
};

const ProductEntries: FC<ProductEntriesProps> = ({
  entries,
  setEntries,
  count,
  setCount,
  selectedEcosystem,
  flow,
  onSerialNumbersChange,
  serialNumbersMap = {},
  onBatchNumbersChange,
  batchNumbersMap = {},
  onValidationChange,
}) => {
  const intl = useIntl();
  const [validationState, setValidationState] = useState<
    Record<string, Record<string, boolean>>
  >({
    serialNumbers: {},
    batchNumbers: {},
  });

  useEffect(() => {
    if (onValidationChange) {
      const allValid = entries.every((entry) => {
        if (!entry.id) return true;

        const serialNumberValid = entry.hasSerialNumber
          ? validationState.serialNumbers[entry.id] !== false
          : true;

        const batchNumberValid = entry.hasBatchNumber
          ? validationState.batchNumbers[entry.id] !== false
          : true;

        return serialNumberValid && batchNumberValid;
      });

      onValidationChange(allValid);
    }
  }, [entries, validationState, onValidationChange]);

  const handleSerialNumberValidationChange = useCallback(
    (positionId: string, isValid: boolean) => {
      setValidationState((prev) => ({
        ...prev,
        serialNumbers: {
          ...prev.serialNumbers,
          [positionId]: isValid,
        },
      }));
    },
    [],
  );

  const handleBatchNumberValidationChange = useCallback(
    (positionId: string, isValid: boolean) => {
      setValidationState((prev) => ({
        ...prev,
        batchNumbers: {
          ...prev.batchNumbers,
          [positionId]: isValid,
        },
      }));
    },
    [],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleDelete = useCallback(
    (key: Key) => {
      const newData = entries.filter((item) => item.key !== key);
      setEntries(newData);
    },
    [entries, setEntries],
  );

  const handleSave = useCallback(
    (row: ProductEntry) => {
      const newData = [...entries];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setEntries(newData);
    },
    [entries, setEntries],
  );

  const handleProductSelect = useCallback(
    (product: Product) => {
      const newEntry: ProductEntry = {
        id: product.id,
        key: count,
        productId: product.id,
        productName: product.productName,
        productNumber: product.productNumber,
        deliveredAmount: 1,
        unit: product.unit,
        hasSerialNumber: product.hasSerialNumber,
        hasBatchNumber: product.hasBatchNumber,
      };
      setEntries([...entries, newEntry]);
      setCount(count + 1);
    },
    [entries, setEntries, count, setCount],
  );

  const defaultColumnsWithOrder: (ColumnType<any> & {
    editable?: boolean;
    dataIndex?: string;
    type?: 'dropdown' | 'number' | 'string';
    selectedEcosystem?: ProductEntriesProps['selectedEcosystem'];
  })[] = [
    {
      title: intl.formatMessage({
        id: 'order',
        defaultMessage: 'Order',
      }),
      dataIndex: 'order',
      type: 'string',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.productnumber',
        defaultMessage: 'Product number',
      }),
      dataIndex: 'productNumber',
      type: 'string',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.productname',
        defaultMessage: 'Product name',
      }),
      dataIndex: 'productName',
      type: 'string',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.amount',
        defaultMessage: 'Amount',
      }),
      dataIndex: 'deliveredAmount',
      editable: true,
      type: 'number',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.unit',
        defaultMessage: 'Unit',
      }),
      dataIndex: 'unit',
      editable: true,
      type: 'dropdown',
      selectedEcosystem,
    },
    {
      title: intl.formatMessage({
        id: 'products.detailedproduct.batchserialnumber',
        defaultMessage: 'Batch and Serial Number',
      }),
      render: (_, record: ProductEntry) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <ButtonBatchNumber
            record={record}
            ecosystem={selectedEcosystem}
            onBatchNumbersChange={onBatchNumbersChange}
            batchNumbers={record.id ? batchNumbersMap[record.id] : undefined}
            onValidationChange={handleBatchNumberValidationChange}
            flowType={flow}
          />
          <ButtonSerialNumber
            record={record}
            ecosystem={selectedEcosystem}
            onSerialNumbersChange={onSerialNumbersChange}
            serialNumbers={record.id ? serialNumbersMap[record.id] : undefined}
            onValidationChange={handleSerialNumberValidationChange}
            flowType={flow}
          />
        </div>
      ),
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.storage',
        defaultMessage: 'Storage',
      }),
      dataIndex: 'storageId',
      editable: true,
      type: 'dropdown',
      selectedEcosystem,
    },
    {
      title: intl.formatMessage({
        id: 'receiver',
        defaultMessage: 'Receiver',
      }),
      dataIndex: 'receiver',
      editable: true,
      type: 'dropdown',
      selectedEcosystem,
    },
    {
      title: intl.formatMessage({
        id: 'app.projects.createNewLink.new.project',
        defaultMessage: 'Project',
      }),
      dataIndex: 'project',
      editable: true,
      type: 'dropdown',
      selectedEcosystem,
    },
    {
      render: (_, record: ProductEntry) =>
        entries.length >= 1 ? (
          <Popconfirm
            title={intl.formatMessage({
              id: 'warehouse.create.product.remove.confirm',
              defaultMessage: 'Sure to delete?',
            })}
            onConfirm={() => handleDelete(record.key)}
          >
            <Button icon={<CloseOutlined />} size="small" shape="circle" />
          </Popconfirm>
        ) : null,
    },
  ];

  const defaultColumns = (() => {
    if (flow === 'incoming') {
      return defaultColumnsWithOrder.filter(
        (el) => el.dataIndex !== 'receiver' && el.dataIndex !== 'project',
      );
    }
    if (flow === 'manual_take_out') {
      return defaultColumnsWithOrder.filter((el) => el.dataIndex !== 'order');
    }
    return defaultColumnsWithOrder;
  })();

  const columns = defaultColumns.map((col) => {
    if (col.dataIndex === 'order') {
      return {
        ...col,
        onCell: (record: ProductEntry) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          type: col.type,
          title: col.title,
          selectedEcosystem: col.selectedEcosystem,
        }),
      };
    }
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ProductEntry) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        type: col.type,
        title: col.title,
        selectedEcosystem: col.selectedEcosystem,
        handleSave,
      }),
    };
  });

  return (
    <ProductEntriesContainer>
      {flow === 'manual_take_out' && (
        <ProductSelector
          selectedEcosystem={selectedEcosystem}
          onProductSelect={handleProductSelect}
        />
      )}
      <Table
        components={components}
        rowClassName={() =>
          'editable-row animate__animated animate__slideInUp animate__faster'
        }
        bordered
        dataSource={entries}
        columns={columns as any}
        pagination={{
          hideOnSinglePage: true,
          pageSize: 5,
        }}
      />
    </ProductEntriesContainer>
  );
};

export default ProductEntries;
