import * as React from 'react';
import { Badge, Icon } from '@todix/ui-components';
import { Wrapper } from '@core/layout/components/TaskHandler/TaskHandler.sc';

type TaskHandlerProps = {
  showDots?: boolean;
  onClick?(): void;
};

export const TaskHandler: React.FC<TaskHandlerProps> = ({
  showDots = false,
  onClick,
}) => {
  const TaskIcon = showDots ? (
    <Badge dot>
      <Icon.HistoryOutlined style={{ fontSize: 30 }} onClick={onClick} />
    </Badge>
  ) : (
    <Icon.HistoryOutlined style={{ fontSize: 30 }} onClick={onClick} />
  );

  return <Wrapper>{TaskIcon}</Wrapper>;
};
