import { deleteRequest } from '@services/api';
import { emitCustomEvent } from '@core/services';
import { ListEvents } from '@components/List';
import { PRODUCTS_COLLECTION_NAME } from '@apps/products/services/namespace';

const deleteProduct = (id: string) =>
  deleteRequest(`${PRODUCTS_COLLECTION_NAME}/${id}`)?.then((response) => {
    emitCustomEvent<ListEvents>('refreshList');
    return response;
  });

export default deleteProduct;
