import { firestore } from 'firebase/app';

export const MAIL_COLLECTION = 'mail';

export type Status = 'PENDING' | 'PROCESSING' | 'SUCCESS' | 'ERROR';

type Delivery = {
  attempts: number;
  error: null | object;
  state: Status;
  endTime: firestore.Timestamp;
  startTime: firestore.Timestamp;
  expireAt: firestore.Timestamp;
};

export type MailEntry = {
  message: {
    html: string;
    subject: string;
    text: string;
  };
  to?: string;
  from?: string;
  delivery?: Delivery;
  consultantId?: string;
  ecosystemId?: string;
  period?: string;
  timestamp?: number;
};
