import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import {
  StyledEditIcon,
  StyledSwitch,
  StyledTable,
} from '@pages/settings/components';
import { FormattedMessage } from 'react-intl';
import { Button, Tooltip } from 'antd';
import StyledSectionTitle from '@pages/settings/components/StyledSectionTitle.ts';
import { FlexBetween } from '@pages/settings/components/styles';
import {
  getEcosystemTaxCategories,
  TaxCategory,
  updateTaxCategory,
} from '@apps/finance/services/taxCategories';

import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import { ColumnType } from 'antd/lib/table';
import TaxCategoryModal from '@apps/finance/settings/taxCategories/TaxCategoryModal';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';

type Props = {
  readOnly?: boolean;
};

const TaxCategories: FC<Props> = ({ readOnly = false }) => {
  const { uid } = useParams<UseParamsType>();
  const ecosystem = useSelector(getEcosystemById(uid)) as Ecosystem;
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxCategories, setTaxCategories] = useState<TaxCategory[]>([]);
  const [taxCategory, setTaxCategory] = useState<TaxCategory | null>(null);

  useEffect(() => {
    if (uid && ecosystem.skr) {
      setLoading(true);
      getEcosystemTaxCategories(uid)
        ?.then(setTaxCategories)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem, uid]);

  const onOk = useCallback(() => {
    setVisible(false);
    if (uid) {
      setLoading(true);
      getEcosystemTaxCategories(uid)
        ?.then(setTaxCategories)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleStatusChange = useCallback(
    async (term: TaxCategory) => {
      const { id } = term;
      if (id) {
        setLoading(true);
        await updateTaxCategory(id, term);
        setTaxCategories(
          taxCategories.map((item) => {
            if (item.id === id) {
              return term;
            }
            return item;
          }),
        );
        setLoading(false);
      }
    },
    [taxCategories],
  );

  const title = useMemo(
    () => () =>
      (
        <FlexBetween>
          <StyledSectionTitle>
            <FormattedMessage
              id="settings.finance.taxCategories"
              defaultMessage="Tax categories"
            />
          </StyledSectionTitle>
          {!readOnly && (
            <Button disabled={readOnly} onClick={() => setVisible(true)}>
              <FormattedMessage
                id="settings.finance.taxCategories.addNew"
                defaultMessage="Add new category"
              />
            </Button>
          )}
        </FlexBetween>
      ),
    [readOnly],
  );

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="settings.finance.taxCategories.addNew.name"
            defaultMessage="Name"
          />
        ),
        sorter: true,
        dataIndex: 'name',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.finance.taxCategories.addNew.value"
            defaultMessage="Value (%)"
          />
        ),
        sorter: true,
        dataIndex: 'value',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: TaxCategory) => (
          <Tooltip
            title={
              <FormattedMessage
                id="settings.finance.taxCategories.status.tooltip"
                defaultMessage="Activate to make this tax category available"
              />
            }
          >
            <StyledSwitch
              key={record.id}
              checked={record.isActive}
              onChange={() =>
                handleStatusChange({
                  ...record,
                  isActive: !record.isActive,
                } as TaxCategory)
              }
              checkedChildren={activated}
              unCheckedChildren={deactivated}
            />
          </Tooltip>
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: TaxCategory) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setTaxCategory(record);
                setVisible(true);
              }}
            />
          </>
        ),
      },
    ],
    [activated, deactivated, handleStatusChange],
  );

  return (
    <>
      {ecosystem?.skr && (
        <div>{`SKR: ${ecosystem?.skr ? `${ecosystem?.skr}` : ''}`}</div>
      )}
      <StyledTable
        title={title}
        dataSource={taxCategories}
        columns={columns}
        loading={loading}
        bordered
        pagination={false}
        rowKey="id"
      />
      <TaxCategoryModal
        ecosystemId={uid}
        visible={visible}
        taxCategory={taxCategory}
        onCancel={onCancel}
        onOk={onOk}
      />
    </>
  );
};

export default TaxCategories;
