import styled from 'styled-components';
import AntBadge, { BadgeProps } from 'antd/lib/badge';

export const Badge: typeof AntBadge = styled(AntBadge)<BadgeProps>`
  && {
    .ant-badge-count,
    .ant-badge-dot {
      background-color: ${(props) => props.theme.colors.badge.red};
    }
  }
`;
