import React, { useEffect, useState, useCallback } from 'react';
import { message, Tabs } from 'antd';
import { useStore } from 'react-redux';
import { DashboardOutlined } from '@ant-design/icons';
import { Text } from '@todix/ui-components';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import { SideContentProps } from '@components/AppView';
import SpinContainer from '@components/SpinContainer';
import {
  deleteDocument,
  Document,
  getDocument,
  LayoutField,
  SelectLayoutField,
  DocumentCategory,
  DocumentType,
  PaymentEntity,
  updateDocument,
} from '@apps/documents/service';
import { getLayout } from '@apps/documents/service/layouts';
import FormWithReminder from '@components/FormWithReminder';
import LayoutRenderer from '@apps/documents/mainMenu/LayoutRenderer';
import { injectEcosystemsOptions } from '@apps/documents/mainMenu/Details/Form/formInjectors';
import getExtraOptions, {
  AddPaymentEvent,
  AddPaymentEventPayload,
} from './getExtraOptions';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl/lib';
import { getName } from '@core/helpers/documents';
import { useCustomEventListener } from '@core/services';
import moment from 'moment';
import FormWithButtons from '@components/FormWithReminder/FormWithButtons';
import momentToFormatString from '@core/helpers/momentToFormatString';
import optionsMapper from '@core/helpers/optionsMapper';
import DocumentAppConfig from '../../index';
import MediaViewer from '@components/MediaViewer';
import { getBucketsPerDetails } from '@apps/purchases/services/buckets';
import { updatePositionMonitor } from '@apps/purchases/services/positionMonitor';
import { PositionMonitor } from '@apps/purchases/services/positionMonitor/namespace';

const formLayout = {
  wrapperCol: {
    span: 12,
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 10,
    },
    xxl: {
      span: 6,
    },
    offset: 0,
  },
};

const { TabPane } = Tabs;

type HandleLayout = (layout: LayoutField[]) => void;

const SideContent: React.FC<SideContentProps> = ({
  id,
  hideActionsInSideContent = false,
}) => {
  const [details, setDetails] = useState<Document | null>(null);
  const [layout, setLayout] = useState<LayoutField[]>([]);
  const [showButtons, setShowButtons] = useState(false);
  const [docType, setDocType] = useState('');
  const [docCategory, setDocCategory] = useState('');
  const store = useStore();
  const intl = useIntl();

  const handleDocumentSideContent = useCallback(
    (id: string, handleLayout?: HandleLayout) => {
      getDocument(id)?.then((response) => {
        const layout = getLayout(response.type, false, 'sideView');
        let processedResponse = {
          ...response,
        };
        const documentLayout = layout.map((field) => {
          if (field.fieldType === 'ecosystemSelect') {
            const { ecosystems } = store.getState();
            return injectEcosystemsOptions(
              ecosystems,
              field as SelectLayoutField,
            );
          }

          if (field.fieldName === 'category') {
            processedResponse = {
              ...processedResponse,
              [field.fieldName]: intl.formatMessage({
                id: `documents.commonfields.category.${response.category}`,
                defaultMessage: response.category,
              }) as DocumentCategory,
            };
          }

          if (field.fieldName === 'type') {
            processedResponse = {
              ...processedResponse,
              [field.fieldName]: intl.formatMessage({
                id: `documents.documentTypes.${response.type}`,
                defaultMessage: response.type,
              }) as DocumentType,
            };
          }

          if (field.fieldName === 'payments') {
            processedResponse = {
              ...processedResponse,
              [field.fieldName]: (
                response[field.fieldName] as PaymentEntity[]
              )?.map((payment: PaymentEntity) => ({
                ...payment,
                payDate: moment(
                  payment.payDate as string,
                  moment.defaultFormat,
                ),
              })),
            };
          }

          return field;
        });
        setShowButtons(false);
        setDetails(processedResponse);
        setLayout(documentLayout);
        setDocType(response.type);
        setDocCategory(response.category);

        if (handleLayout) {
          handleLayout(documentLayout);
        }
      });
    },
    [intl, store],
  );

  const handleDelete = useCallback(() => {
    deleteDocument(id)
      ?.then(() => {
        // message.success(
        //   intl.formatMessage({
        //     id: 'documents.deletedocument.success',
        //     defaultMessage: 'Document deleted',
        //   }),
        // );
      })
      .catch(() => {
        message.error(
          intl.formatMessage({
            id: 'products.deletedocument.error',
            defaultMessage: 'Error while deleting document',
          }),
        );
      });
  }, [id, intl]);

  const handleDocumentUpdate = useCallback(
    (values) => {
      let parsedValues = momentToFormatString({
        ...details,
        ...values,
      });

      if (parsedValues.payments) {
        parsedValues = {
          ...parsedValues,
          payments: (parsedValues.payments as PaymentEntity[]).map((payment) =>
            momentToFormatString(payment),
          ),
        };
      }

      const newDoc: Document = {
        ...parsedValues,
        isDraft: false,
        documentUrl: details?.documentUrl,
        fileDetails: details?.fileDetails || null,
        creatingUser: details?.creatingUser || '',
        uploadingUserId: details?.uploadingUserId || '',
        type: docType,
        category: docCategory,
      };

      updateDocument(id, newDoc)
        ?.then(async () => {
          if (id) {
            if (newDoc.payments) {
              const buckets = await getBucketsPerDetails({
                relatedDocumentId: id,
                relatedContact: details?.relatedContact || '',
                stepType: 'invoiced',
                ecosystem: newDoc?.ecosystem,
              });
              if (buckets && newDoc.payments) {
                const totalPayments = newDoc.payments.reduce(
                  (sum, payment) => sum + (payment.payAmount || 0),
                  0,
                );
                const totalGrossAmount = newDoc.totalGrossAmount || 0;

                for (const bucket of buckets) {
                  for (const position of bucket.positions) {
                    const paymentStatus =
                      totalPayments >= totalGrossAmount ? 'paid' : 'partlyPaid';

                    const latestTimestamp = Math.max(
                      ...newDoc.payments.map((payment) =>
                        payment.payDate ? moment(payment.payDate).valueOf() : 0,
                      ),
                    );

                    const monitorUpdate: Partial<PositionMonitor> = {
                      [paymentStatus]: {
                        status: paymentStatus === 'paid' ? 'done' : 'draft',
                        relatedDocumentId: id,
                        timestamp: latestTimestamp || moment().valueOf(),
                      },
                    };

                    await updatePositionMonitor(
                      position.id as string,
                      monitorUpdate,
                    );
                  }
                }
              }
              setDetails(null);
              handleDocumentSideContent(id);
            }
          }
        })
        .catch(() => {
          message.error(
            intl.formatMessage({
              id: 'documents.edit.error',
              defaultMessage: 'Error while document creating',
            }),
          );
        });
    },
    [details, docCategory, docType, handleDocumentSideContent, id, intl],
  );

  useCustomEventListener<AddPaymentEvent, AddPaymentEventPayload>(
    'addPayment',
    (e) => {
      if (e?.item.id) {
        handleDocumentSideContent(e.item.id, (documentLayout) => {
          const newLayout = documentLayout.map((item) => {
            if (item.fieldName === 'payments') {
              return {
                ...item,
                readOnly: false,
              };
            }
            return item;
          });
          setLayout(newLayout);
          setShowButtons(true);
        });
      }
    },
  );

  useEffect(() => {
    setDetails(null);
    handleDocumentSideContent(id);
  }, [handleDocumentSideContent, id]);

  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={
        hideActionsInSideContent ? null : (
          <ActionRenderer
            path={`/app/${DocumentAppConfig.todixId}/${id}`}
            options={optionsMapper(['open'], intl)}
            handleDelete={handleDelete}
            extraOptions={details ? getExtraOptions(details, intl) : undefined}
            intl={intl}
          />
        )
      }
    >
      <TabPane
        key="1"
        tab={
          <>
            <DashboardOutlined />{' '}
            <FormattedMessage
              id="documents.sidecontent.basetab"
              defaultMessage="Base Tab"
            />
          </>
        }
      >
        {details === null ? (
          <SpinContainer />
        ) : (
          <>
            <Text level={4} style={{ display: 'block', marginBottom: 36 }}>
              {getName(details, intl)}
            </Text>
            {details && (
              <MediaViewer
                items={[
                  {
                    url: details.documentUrl,
                    thumbnailUrl: details.thumbnailUrl,
                    format: details.fileDetails.format || '',
                  },
                ]}
                showPlayButton={false}
                showThumbnails={false}
              />
            )}
            {layout && details && showButtons && (
              <FormWithButtons
                readOnly={false}
                initialValues={details}
                name="document"
                onFinish={handleDocumentUpdate}
                submitButton={{
                  label: intl.formatMessage({
                    id: 'button.save',
                    defaultMessage: 'Save',
                  }),
                }}
                cancelButton={{
                  label: intl.formatMessage({
                    id: 'button.cancel',
                    defaultMessage: 'Cancel',
                  }),
                  onClick: () => {
                    if (details.id) {
                      handleDocumentSideContent(details.id);
                    }
                  },
                }}
                readonlyCancelButton={{
                  label: intl.formatMessage({
                    id: 'button.cancel',
                    defaultMessage: 'Cancel',
                  }),
                  onClick: () => {
                    if (details.id) {
                      handleDocumentSideContent(details.id);
                    }
                  },
                }}
                {...formLayout}
              >
                <LayoutRenderer layoutFields={layout} />
              </FormWithButtons>
            )}
            {layout && details && !showButtons && (
              <FormWithReminder initialValues={details} {...formLayout}>
                <LayoutRenderer layoutFields={layout} />
              </FormWithReminder>
            )}
          </>
        )}
      </TabPane>
      {/* <TabPane
        key="2"
        tab={
          <>
            <HistoryOutlined />{' '}
            <FormattedMessage
              id="documents.sidecontent.timeline"
              defaultMessage="Timeline"
            />
          </>
        }
      >
        <SpinContainer />
      </TabPane> */}
    </Tabs>
  );
};

export default SideContent;
