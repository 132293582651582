import styled from 'styled-components';
import Card, { CardProps } from 'antd/lib/card';

export const CardStyle: typeof Card = styled(Card)<CardProps>`
  && {
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.background.light};
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  }
`;
