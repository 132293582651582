import React, { FC, useState, useEffect } from 'react';
import { InputNumber } from 'antd';
import { CartEntry } from '@services/cart/namespace';
import updateEntryInCart from '@services/cart/updateEntryInCart';
import { updateCart } from '@core/store/cart';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { APP_LIMIT_PER_SUBSCRIPTION } from '@services/stripe';

type QuantityProps = {
  entry: CartEntry;
  qty: number;
  disabled: boolean;
  ecosystem?: Ecosystem;
};

const Quantity: FC<QuantityProps> = ({ qty, disabled, entry, ecosystem }) => {
  const [value, setValue] = useState<number>(qty);
  const dispatch = useDispatch();

  const handleQtyChange = useDebouncedCallback(async (newQty: number) => {
    const newValue =
      newQty > APP_LIMIT_PER_SUBSCRIPTION ? APP_LIMIT_PER_SUBSCRIPTION : newQty;

    const cartEntry: CartEntry = {
      ...entry,
      quantity: newValue,
    };

    await updateEntryInCart(cartEntry, entry.userId, true);
    dispatch(
      updateCart({
        cartEntry,
        override: true,
      }),
    );

    setValue(newValue);
  }, 300);

  useEffect(() => {
    if (value !== qty) {
      handleQtyChange(qty);
    }
  }, [handleQtyChange, qty, value]);

  return (
    <InputNumber
      value={value}
      min={1}
      max={ecosystem?.type === 'private' ? 1 : APP_LIMIT_PER_SUBSCRIPTION}
      disabled={disabled}
      onChange={handleQtyChange}
      size="small"
    />
  );
};

export default Quantity;
