import React, { FC, useState, useEffect } from 'react';
import { styled } from '@styles/themes';
import { FormattedMessage } from 'react-intl/lib';
import { CartEntry } from '@services/cart/namespace';
import { StripePrice } from '@services/stripe/namespace';
import withSuffix from '@core/helpers/withSuffix';
import { currencySigns } from '@services/currency';
import { FreeTrialTag } from '@pages/settings/store/AppDetails.sc';
import moment from 'moment/moment';

const TotalPriceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

type AmountAndCurrency = {
  amount: number;
  tax: number;
  currency: string;
};

type CartTotalPriceProps = {
  cart: CartEntry[];
  prices: StripePrice[];
  showTrial: boolean;
  stripeSettings: StripeSettings | null;
};

const CartTotalPrice: FC<CartTotalPriceProps> = ({
  cart,
  prices,
  showTrial,
  stripeSettings,
}) => {
  const [totalPrice, setTotalPrice] = useState<AmountAndCurrency | null>(null);

  useEffect(() => {
    if (cart.length && prices.length) {
      const newTotalPrice = cart.reduce((acc, entry) => {
        const price = prices.find((price) => price.id === entry.priceId);
        if (price && price.unit_amount) {
          let prodAmount = (price.unit_amount / 100) * entry.quantity;
          const tax = prodAmount * 0.19;
          if (price.tax_behavior === 'exclusive') {
            prodAmount = prodAmount + tax;
          }
          if (Object.keys(acc).length < 1) {
            return {
              amount: Number(prodAmount.toFixed(2)),
              currency: price.currency.toUpperCase(),
              tax: Number(tax.toFixed(2)),
            };
          } else {
            return {
              ...acc,
              amount: acc.amount + Number(prodAmount.toFixed(2)),
              currency: acc.currency,
              tax: acc.tax + Number(tax.toFixed(2)),
            };
          }
        }
        return acc;
      }, {} as AmountAndCurrency);

      setTotalPrice(newTotalPrice);
    }
  }, [cart, prices]);

  const priceWithSuffix = withSuffix({
    value: totalPrice ? totalPrice.amount : 0,
    suffix: prices[0]?.currency
      ? currencySigns[prices[0]?.currency.toUpperCase()]
      : currencySigns['EUR'],
  });

  const style = showTrial
    ? {
        textDecorationLine: 'line-through',
      }
    : {};

  const taxValue = withSuffix({
    value: totalPrice ? totalPrice.tax : 0,
    suffix: prices[0]?.currency
      ? currencySigns[prices[0]?.currency.toUpperCase()]
      : currencySigns['EUR'],
  });

  return (
    <TotalPriceWrapper>
      {totalPrice && (
        <div>
          <div style={style}>
            <FormattedMessage
              id="total.amount"
              defaultMessage="Total amount:"
            />{' '}
            {priceWithSuffix}/
            <FormattedMessage id="per.month" defaultMessage="per Month" />
          </div>
          <div style={style}>
            <FormattedMessage
              id="tax.calc"
              defaultMessage="tax.calc"
              values={{
                vatRate: `19% VAT`,
                taxDetails: taxValue,
              }}
            />
          </div>
        </div>
      )}
      {showTrial && stripeSettings?.trialEnd && (
        <FreeTrialTag color="red">
          <FormattedMessage
            id="free.trial.till"
            defaultMessage="Free till"
            values={{
              date: moment
                .unix(stripeSettings?.trialEnd)
                .format(moment.defaultFormat),
            }}
          />
        </FreeTrialTag>
      )}
      {showTrial && !stripeSettings?.trialEnd && (
        <FreeTrialTag color="red">
          <FormattedMessage
            id="free.trial"
            defaultMessage="Free trial"
            values={{
              days: stripeSettings?.trialPeriodDays || 30,
            }}
          />
        </FreeTrialTag>
      )}
    </TotalPriceWrapper>
  );
};

export default CartTotalPrice;
