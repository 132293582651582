import moment from 'moment';
import { patch } from '@services/api';
import {
  PRODUCT_PRICES,
  ProductPrice,
} from '@apps/products/services/namespace';

const updateProductPrice = (id: string, price: ProductPrice) =>
  patch(`${PRODUCT_PRICES}/${id}`, {
    ...price,
    timestamp: moment().valueOf(),
  });

export default updateProductPrice;
