import { styled } from '@styles/themes';
import { Tabs } from 'antd';

export const TabsWithOverflowHidden = styled(Tabs)`
  overflow: hidden;
`;

export const StatusIconContainer = styled.div`
  padding: 2px;
  justify-content: center;
  align-items: center;
`;
