import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tabs from '@components/Tabs';
import { BarcodeOutlined } from '@ant-design/icons';
import { Product } from '@apps/products/services';
import BatchSerialNumberContent from './BatchSerialNumberContent';

const { TabPane } = Tabs;

type TabProps = {
  initialValues?: Partial<Product>;
  readOnly?: boolean;
  id?: string;
};

export const getBatchSerialNumberTab = ({
  initialValues,
  readOnly,
  id,
}: TabProps) => {
  if (
    !initialValues ||
    (!initialValues.hasSerialNumber && !initialValues.hasBatchNumber)
  ) {
    return null;
  }

  return (
    <TabPane
      key="batchSerialNumber"
      tab={
        <>
          <BarcodeOutlined />{' '}
          <FormattedMessage
            id="products.detailedproduct.batchserialnumber"
            defaultMessage="Batch and Serial Number"
          />
        </>
      }
    >
      <BatchSerialNumberContent
        productId={id}
        readOnly={readOnly}
        hasBatchNumber={initialValues.hasBatchNumber}
        hasSerialNumber={initialValues.hasSerialNumber}
        ecosystem={initialValues.ecosystem}
      />
    </TabPane>
  );
};

export default getBatchSerialNumberTab;
