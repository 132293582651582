import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import {
  ColSpanParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { getStringDateFromValue } from '@components/List/FrameworkComponents/DateRenderer';
import dateFilter from '@core/helpers/dateFilter';

const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="productName"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'products.columns.productname.header',
        defaultMessage: 'Product Name',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="project"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'project',
        defaultMessage: 'project',
      })}
      minWidth={160}
      cellRenderer="projectRenderer"
      sortable={false}
      filter={false}
    />
    <AgGridColumn
      field="costCenter"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'cost.center',
        defaultMessage: 'cost.center',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="bucketId"
      headerName={intl.formatMessage({
        id: 'document.number',
        defaultMessage: 'Document Number',
      })}
      minWidth={160}
      filter={false}
      sortable={false}
      cellRenderer="bucketDocumentRenderer"
    />
    <AgGridColumn
      field="ecosystem"
      minWidth={80}
      headerName={intl.formatMessage({
        id: 'tasks.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      cellRenderer="ecosystemRenderer"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      filter="ecosystemFilter"
      lockPosition="left"
    />
    <AgGridColumn
      field="creator"
      headerName={intl.formatMessage({
        id: 'products.form.creator.label',
        defaultMessage: 'Creator',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
    />
    <AgGridColumn
      field="needDate"
      headerName={intl.formatMessage({
        id: 'need.date',
        defaultMessage: 'Need Date',
      })}
      filter={'agDateColumnFilter'}
      filterParams={dateFilter}
      sortable
      valueGetter={({ data }: ValueGetterParams) =>
        getStringDateFromValue(data?.needDate)
      }
      minWidth={160}
    />
    <AgGridColumn
      field="needAmount"
      headerName={intl.formatMessage({
        id: 'need.amount',
        defaultMessage: 'Need amount',
      })}
      filter={'agNumberColumnFilter'}
      sortable={true}
    />
    <AgGridColumn
      field="description"
      headerName={intl.formatMessage({
        id: 'products.form.description.label',
        defaultMessage: 'Description',
      })}
    />
    <AgGridColumn
      field="takeBy"
      headerName={intl.formatMessage({
        id: 'take.by',
        defaultMessage: 'Take by',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
      sortable={false}
    />
    <AgGridColumn
      field="status"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'tasks.form.status.label',
        defaultMessage: 'Status',
      })}
      sortable
      minWidth={160}
      cellRenderer="bucketStatusRenderer"
      valueGetter={({ data }: ValueGetterParams) =>
        data?.status
          ? intl.formatMessage({
              id: `${data?.status}`,
              defaultMessage: `${data?.status}`,
            })
          : ''
      }
    />
    <AgGridColumn
      headerName={intl.formatMessage({
        id: 'sales.monitor',
        defaultMessage: 'Monitor',
      })}
      cellRenderer="purchaseStepsStatusRenderer"
      minWidth={240}
      filter={false}
      sortable={false}
    />
    <AgGridColumn
      field="stepType"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      sortable
      valueGetter={({ data }: ValueGetterParams) => {
        return data?.stepType
          ? intl.formatMessage({
              id: `${data?.stepType}`,
              defaultMessage: `${data?.stepType}`,
            })
          : '';
      }}
      headerName={intl.formatMessage({
        id: 'step',
        defaultMessage: 'Step',
      })}
      minWidth={160}
    />
    <AgGridColumn
      field="timestamp"
      headerName={intl.formatMessage({
        id: 'latest.change',
        defaultMessage: 'Latest Change',
      })}
      minWidth={160}
      filter={'agDateColumnFilter'}
      filterParams={dateFilter}
      sortable
      valueGetter={({ data }: ValueGetterParams) =>
        getStringDateFromValue(data?.timestamp)
      }
    />
    <AgGridColumn
      cellRenderer="actionRenderer"
      maxWidth={70}
      filter={false}
      sortable={false}
    />
  </>
);

export default columns;
