import React, { FC } from 'react';
import { Space, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { CartEntry } from '@services/cart/namespace';

type EntryActionProps = {
  entry: CartEntry;
  onRemoveEntry(): void;
};

const EntryAction: FC<EntryActionProps> = ({ onRemoveEntry }) => {
  return (
    <Space direction="horizontal">
      <Button
        icon={<DeleteOutlined />}
        type="primary"
        danger
        size="small"
        onClick={onRemoveEntry}
      />
    </Space>
  );
};

export default EntryAction;
