import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type ShortDescriptionProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const ShortDescription: FC<ShortDescriptionProps> = ({
  namePrefix,
  readOnly,
}) => {
  const intl = useIntl();
  return (
    <Item
      name={
        namePrefix !== undefined
          ? [namePrefix, `shortDescription`]
          : `shortDescription`
      }
      label={
        <FormattedMessage
          id="products.form.shortdescription.label"
          defaultMessage="Short description"
        />
      }
      rules={[
        {
          type: 'string',
          max: 40,
          message: (
            <FormattedMessage
              id="products.form.shortdescription.error"
              defaultMessage="Short description cannot be longer than 40 characters"
            />
          ),
        },
      ]}
    >
      <Input
        name={`${namePrefix}shortDescription`}
        placeholder={intl.formatMessage({
          id: 'products.form.shortdescription.placeholder',
          defaultMessage: 'Short description...',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default ShortDescription;
