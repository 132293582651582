import React, { FC, useEffect } from 'react';
import { Form } from 'antd';
import { useForm, FormProps } from 'antd/lib/form/Form';
import { FormInstance, FormItemProps } from 'antd/lib/form';
import { FormContext } from '@components/FormWithReminder/context';

export type StepFormProps = FormProps & {
  readOnly?: boolean;
  shouldUpdate: FormItemProps['shouldUpdate'];
  registerValidateFunction?(name: string, validateFunc: FormInstance): void;
};

const formLayout = {
  wrapperCol: {
    flex: 1,
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xxl: {
      span: 8,
    },
  },
};

const StepForm: FC<StepFormProps> = ({
  children,
  name,
  readOnly = false,
  shouldUpdate,
  registerValidateFunction,
  form: passedForm,
  ...rest
}) => {
  const [form] = useForm(passedForm);

  useEffect(() => {
    if (registerValidateFunction && form && name) {
      registerValidateFunction(name, form);
    }
  }, [form, name, registerValidateFunction]);

  return (
    <FormContext.Provider value={{ ...form, readOnly }}>
      <Form name={name} form={form} {...rest} {...formLayout}>
        <Form.Item shouldUpdate={shouldUpdate} noStyle>
          {() => children}
        </Form.Item>
      </Form>
    </FormContext.Provider>
  );
};

export default StepForm;
