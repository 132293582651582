import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Form } from 'antd';

const { Item } = Form;

type LocationProps = {
  readOnly: boolean;
};

const Location: FC<LocationProps> = ({ readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name="location"
      label={
        <FormattedMessage
          id="warehouse.form.location.label"
          defaultMessage="Location"
        />
      }
      rules={[
        {
          type: 'string',
          max: 80,
          required: false,
          message: (
            <FormattedMessage
              id="warehouse.form.location.error"
              defaultMessage="Location cannot be longer than 80 characters"
            />
          ),
        },
      ]}
    >
      <Input
        name="location"
        placeholder={intl.formatMessage({
          id: 'warehouse.form.location.placeholder',
          defaultMessage: 'Location...',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default Location;
