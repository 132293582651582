import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import { ColSpanParams } from 'ag-grid-community/dist/lib/entities/colDef';

const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="timestamp"
      headerName={intl.formatMessage({
        id: 'sales.columns.creationDate.header',
        defaultMessage: 'Creation Date',
      })}
      cellRenderer="dateRenderer"
      minWidth={160}
    />
    <AgGridColumn
      field="ecosystem"
      minWidth={160}
      headerName={intl.formatMessage({
        id: 'ecosystem',
        defaultMessage: 'Ecosystem',
      })}
      cellRenderer="ecosystemRenderer"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      filter="ecosystemFilter"
    />
    <AgGridColumn
      field="products"
      headerName={intl.formatMessage({
        id: 'sales.sum',
        defaultMessage: 'Sum',
      })}
      minWidth={160}
      cellRenderer="sumCellRenderer"
    />
    <AgGridColumn
      field="contactSnapshot"
      headerName={intl.formatMessage({
        id: 'sales.columns.customer.header',
        defaultMessage: 'Customer',
      })}
      minWidth={160}
      cellRenderer="customerCellRenderer"
    />
    <AgGridColumn
      field="id"
      headerName={intl.formatMessage({
        id: 'sales.monitor',
        defaultMessage: 'Monitor',
      })}
      minWidth={160}
      cellRenderer="stepsStatusCellRenderer"
    />
    <AgGridColumn
      field="paymentStatus"
      headerName={intl.formatMessage({
        id: 'sales.payment.status',
        defaultMessage: 'Payment',
      })}
      minWidth={160}
      cellRenderer="salePaymentStatusCellRenderer"
    />
    <AgGridColumn
      field="shipmentStatus"
      headerName={intl.formatMessage({
        id: 'sales.shipment.status',
        defaultMessage: 'Shipment',
      })}
      minWidth={160}
      cellRenderer="saleShipmentStatusCellRenderer"
    />
    <AgGridColumn cellRenderer="actionRenderer" maxWidth={70} filter={false} />
  </>
);

export default columns;
