import React, { FC } from 'react';
import { Commission } from '@apps/directSales/services/commisions';
import { List } from 'antd';
import { useIntl } from 'react-intl';
import {
  DivBoldMarginRight,
  SpanMarginRight,
} from '@apps/directSales/styles.sc';
import { currencySigns } from '@services/currency';

const { Item } = List;

type Props = {
  value: Commission['commissionThresholds'];
};

const CommissionThresholdsRenderer: FC<Props> = ({ value }) => {
  const intl = useIntl();
  return (
    <List
      dataSource={value || []}
      renderItem={(item, index) => (
        <Item key={index}>
          <div>
            <SpanMarginRight>
              {intl.formatMessage(
                {
                  id: 'commissions.commission.from',
                  defaultMessage: 'commissions.commission.from',
                },
                {
                  commission: `${item.minThreshold}${currencySigns['EUR']}`,
                },
              )}
              :
            </SpanMarginRight>
            <span>{`${item.commissionBonus}%`}</span>
          </div>
        </Item>
      )}
      header={
        <DivBoldMarginRight>
          {intl.formatMessage({
            id: 'commissions.commission.thresholds',
            defaultMessage: 'Commission thresholds',
          })}
        </DivBoldMarginRight>
      }
      size="small"
      bordered
    />
  );
};

export default CommissionThresholdsRenderer;
