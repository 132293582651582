import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AppView from '@components/AppView';

import { deleteConsultant } from '@apps/directSales/mainMenu/Consultants/services';
import { columns } from './columns';
import { getRows } from './getRows';
import { SideContent } from './SideContent';
import optionsMapper from '@core/helpers/optionsMapper';

const Consultants: React.FC = () => {
  const intl = useIntl();
  const { path } = useRouteMatch();
  return (
    <AppView
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'consultants.nav.consultants',
            defaultMessage: 'Consultants',
          }),
        ],
      ]}
      options={optionsMapper(['open', 'edit', 'delete'], intl)}
      columns={columns(intl)}
      getRows={getRows}
      sideContent={SideContent}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      onRemoveItem={deleteConsultant}
      actionListCells={[
        'activationCellRenderer',
        'validationCellRenderer',
        'mobileLicenseCellRenderer',
      ]}
      hideQuickFilter
    />
  );
};

export default Consultants;
