import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type EstimationProps = {
  readOnly: boolean;
};

const Estimation: FC<EstimationProps> = ({ readOnly }) => {
  const intl = useIntl();

  return (
    <Item
      name="estimation"
      label={
        <FormattedMessage
          id="tasks.form.estimation.label"
          defaultMessage="Estimation (h)"
        />
      }
    >
      <Input
        name="estimation"
        type={'number'}
        min={'0'}
        step={'0.25'}
        pattern={'[0-9]*'}
        placeholder={intl.formatMessage({
          id: 'tasks.form.estimation.label',
          defaultMessage: 'Estimation (h)',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default Estimation;
