import React, { FC, useState, useCallback, useEffect } from 'react';
import { Spin, message } from 'antd';
import AppConfig, { WAREHOUSE_ACTION } from '@apps/warehouse';
import { useHistory } from 'react-router-dom';
import { Card } from '@todix/ui-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Tabs from '@components/Tabs';
import { DashboardOutlined } from '@ant-design/icons';
import FlowManagement from '@apps/warehouse/mainMenu/components/FlowManagement';
import moment from 'moment';
import {
  BatchNumberEntry,
  ExtendedWarehouseFlow,
  WarehouseFlow,
} from '@apps/warehouse/services/namespace';
import handleWarehouseFlow from '@apps/warehouse/services/handleWarehouseFlow';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import {
  Bucket,
  BucketStep,
  createBucket,
  updateBucket,
} from '@apps/purchases/services/buckets';
import { getContact } from '@apps/contacts/services';
import { updateIndividualPurchaseRequest } from '@apps/purchases/services/individualPurchaseRequests';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import createRelation from '@services/entityRelations/createRelation';
import { updateDocumentWarehouseConnection } from '@apps/documents/service';
import postSerialNumber from '@apps/warehouse/services/postSerialNumber';
import postBatchNumber from '@apps/warehouse/services/postBatchNumber';

const { TabPane } = Tabs;

const Create: FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();
  const intl = useIntl();
  const { data: user } = useSelector(getUser);
  const firstActiveEcosystemId = useFirstActiveEcosystemId(
    AppConfig.todixId,
    'create-warehouses' as WAREHOUSE_ACTION,
  );

  const canCreate = checkUserPermissions(
    rootStore.getState(),
    AppConfig.todixId,
    'view-warehouses' as WAREHOUSE_ACTION,
    firstActiveEcosystemId || '',
  );

  const createBucketFromWarehouseFlow = async (
    values: ExtendedWarehouseFlow & {
      serialNumbersMap?: Record<string, string[]>;
      batchNumbersMap?: Record<string, BatchNumberEntry[]>;
    },
  ) => {
    if (
      values.flow !== 'incoming' ||
      !values.supplier ||
      !user ||
      !values.orderId
    )
      return;

    try {
      const contact = await getContact(values.supplier);
      if (!contact) throw new Error('Contact not found');

      if (!values.deliveryNote) throw new Error('Delivery note not found');

      const deliveryBucket: Bucket = {
        ecosystem: values.ecosystem,
        type: 'supplier',
        status: 'done',
        stepType: 'delivered',
        relatedContact: values.supplier,
        relatedContactSnapshot: contact,
        relatedDocumentId: values.deliveryNote,
        positions: values.entries.map((entry) => ({
          id: entry.id,
          ecosystem: values.ecosystem,
          productId: entry.productId,
          productName: entry.productName,
          productNumber: entry.productNumber,
          shortDescription: entry.shortDescription || '',
          description: entry.description || '',
          unit: entry.unit,
          needAmount: Number(entry.amount),
          deliveredAmount: Number(entry.deliveredAmount),
          unitAmount: Number(entry.unitAmount),
          creator: user,
          needDate: moment().format('YYYY-MM-DD'),
          creationDate: moment().format('YYYY-MM-DD'),
        })),
        timestamp: moment().valueOf(),
      };

      const bucketId = await createBucket(deliveryBucket);

      //for delivery note
      await createRelation({
        ecosystem: values.ecosystem as string,
        parentId: bucketId,
        parentType: 'bucket',
        parentRefCollection: 'buckets',
        childId: values.deliveryNote || '',
        childType: 'document',
        childRefCollection: 'documents',
      });

      //for orders
      const positionsWithOrder = values.entries.filter((el) => el.order);
      const uniqueOrderIds = new Set(
        positionsWithOrder.map((el) => el.order?.id).filter(Boolean),
      );

      const relationsForOrders = Array.from(uniqueOrderIds).map(
        async (orderId) => {
          const positionWithOrder = positionsWithOrder.find(
            (el) => el.order?.id === orderId,
          );

          await createRelation({
            ecosystem: values.ecosystem as string,
            parentId: bucketId,
            parentType: 'bucket',
            parentRefCollection: 'buckets',
            childId: orderId || '',
            childType: 'document',
            childRefCollection: 'documents',
          });

          if (positionWithOrder?.orderBucket?.id) {
            await createRelation({
              ecosystem: values.ecosystem as string,
              parentId: positionWithOrder.orderBucket.id,
              parentType: 'bucket',
              parentRefCollection: 'buckets',
              childId: bucketId,
              childType: 'bucket',
              childRefCollection: 'buckets',
            });
          }
        },
      );

      await Promise.all(relationsForOrders);

      const totalPositions = deliveryBucket.positions.length;
      let delivered = 0;

      const updatePromises = deliveryBucket.positions.map(
        async (orderPosition) => {
          const deliveredAmount = Number(orderPosition.deliveredAmount);
          const orderedAmount = Number(orderPosition.needAmount);

          let stepType: BucketStep = 'ordered';
          if (deliveredAmount === orderedAmount) {
            stepType = 'delivered';
            delivered += 1;
          } else if (deliveredAmount > 0 && deliveredAmount < orderedAmount) {
            stepType = 'partlyDelivered';
          } else if (deliveredAmount > orderedAmount) {
            stepType = 'overDelivered';
          }

          await updateIndividualPurchaseRequest(
            orderPosition.id as string,
            {
              deliveredAmount,
              stepType,
              status: 'done',
            } as any,
          );
        },
      );

      await Promise.all(updatePromises);
      await updateDocumentWarehouseConnection(
        values.deliveryNote as string,
        true,
      );

      if (delivered === totalPositions && deliveryBucket.id) {
        await updateBucket(deliveryBucket.id, {
          ...deliveryBucket,
          status: 'delivered',
        });
      }

      if (values.serialNumbersMap) {
        const serialNumberPromises: Promise<void>[] = [];

        Object.entries(values.serialNumbersMap).forEach(
          ([positionId, serialNumbers]) => {
            const position = values.entries.find(
              (entry) => entry.id === positionId,
            );

            if (position && position.productId) {
              serialNumbers.forEach((serialNumber) => {
                if (serialNumber) {
                  serialNumberPromises.push(
                    postSerialNumber(
                      values.ecosystem,
                      serialNumber,
                      position.productId,
                      positionId,
                    ),
                  );
                }
              });
            }
          },
        );

        await Promise.all(serialNumberPromises);
      }

      if (values.batchNumbersMap) {
        const batchNumberPromises: Promise<void>[] = [];

        Object.entries(values.batchNumbersMap).forEach(
          ([positionId, batchEntries]) => {
            const position = values.entries.find(
              (entry) => entry.id === positionId,
            );

            if (position && position.productId) {
              batchEntries.forEach((batchEntry) => {
                if (batchEntry.batchNumber) {
                  batchNumberPromises.push(
                    postBatchNumber(
                      values.ecosystem,
                      batchEntry.batchNumber,
                      position.productId,
                      positionId,
                      batchEntry.amount,
                    ),
                  );
                }
              });
            }
          },
        );

        await Promise.all(batchNumberPromises);
      }
    } catch (error) {
      console.error('Error creating bucket:', error);
      message.error(
        intl.formatMessage({
          id: 'warehouse.bucket.error.message',
          defaultMessage: 'Error while creating bucket',
        }),
      );
    }
  };

  const handleSubmit = useCallback(
    async (values: ExtendedWarehouseFlow) => {
      if (!canCreate) {
        return;
      }

      const hasInvalidSerialOrBatchNumbers = values.entries.some((entry) => {
        if (entry.id) {
          if (entry.hasSerialNumber === true) {
            const serialNumbers = values.serialNumbersMap?.[entry.id] || [];
            if (serialNumbers.length < entry.deliveredAmount) {
              return true;
            }
          }

          if (entry.hasBatchNumber === true) {
            const batchEntries = values.batchNumbersMap?.[entry.id] || [];
            const batchAmount = batchEntries.reduce(
              (sum, batchEntry) => sum + (batchEntry.amount || 0),
              0,
            );
            if (batchAmount < entry.deliveredAmount) {
              return true;
            }
          }
        }
        return false;
      });

      if (hasInvalidSerialOrBatchNumbers) {
        message.error(
          intl.formatMessage({
            id: 'warehouse.invalid.serialbatch.numbers',
            defaultMessage:
              'Please complete all serial numbers and batch numbers before submitting',
          }),
        );
        return;
      }

      const entryToSave: WarehouseFlow = {
        ...values,
        timestamp: (values.timestamp as unknown as typeof moment).utc().unix(),
      };

      let emptyStorage: boolean = false;
      entryToSave.entries.forEach((entry) => {
        if (!entry.storageId) {
          emptyStorage = true;
        }
      });

      if (emptyStorage) {
        message.error(
          intl.formatMessage({
            id: 'warehouse.empty.storage',
            defaultMessage: 'You need to select storage for all positions',
          }),
        );
        setIsSubmitting(false);
        return;
      }

      try {
        //TODO: change statistic handling
        if (entryToSave.flow === 'manual_take_out') {
          setIsSubmitting(true);
          handleWarehouseFlow(entryToSave);
          await message.success(
            intl.formatMessage({
              id: 'warehouse.flow.success.message',
              defaultMessage:
                'Warehouse operation has been added for processing',
            }),
          );
        }
        if (entryToSave.flow === 'incoming') {
          setIsSubmitting(true);
          await createBucketFromWarehouseFlow(entryToSave);
          handleWarehouseFlow(entryToSave);
          await message.success(
            intl.formatMessage({
              id: 'warehouse.flow.success.message',
              defaultMessage:
                'Warehouse operation has been added for processing',
            }),
          );
        }
        setIsSubmitting(false);
        history.push(`/app/${AppConfig.todixId}`);
      } catch (e) {
        console.error(e);
        setIsSubmitting(false);
        message.error(
          intl.formatMessage({
            id: 'warehouse.flow.error.message',
            defaultMessage: 'Error while adding warehouse operation',
          }),
        );
      }
    },
    [canCreate, createBucketFromWarehouseFlow, history, intl],
  );

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${AppConfig.todixId}`,
        intl.formatMessage({
          id: 'warehouse.nav.warehouses',
          defaultMessage: 'Warehouses',
        }),
      ],
      [
        `/app/${AppConfig.todixId}/create`,
        intl.formatMessage({
          id: 'warehouse.nav.create',
          defaultMessage: 'Create',
        }),
      ],
    ];

    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [intl]);

  return (
    <Spin spinning={isSubmitting}>
      <Card>
        <Tabs defaultActiveKey="1" onChange={() => {}}>
          <TabPane
            key="1"
            disabled={!canCreate}
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="warehouse.basetab"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <FlowManagement
              onSubmit={handleSubmit}
              ecosystemId={firstActiveEcosystemId}
              action="create-warehouses"
            />
          </TabPane>
        </Tabs>
      </Card>
    </Spin>
  );
};

export default Create;
