import React, { FC, useState, useCallback, useEffect } from 'react';
import { PopoverPicker } from './PopoverPicker';

type ColorPickerProps = {
  initialColor?: string;
  presetColors?: string[];
  onChange?(color: string): void;
};

const ColorPicker: FC<ColorPickerProps> = ({
  initialColor = '#aabbcc',
  presetColors = [],
  onChange,
}) => {
  const [color, setColor] = useState(initialColor);

  useEffect(() => {
    if (initialColor) {
      setColor(initialColor);
    }
  }, [initialColor]);

  const handleColorChange = useCallback(
    (color: string) => {
      setColor(color);
      if (onChange) {
        onChange(color);
      }
    },
    [onChange],
  );

  return (
    <PopoverPicker
      color={color}
      onChange={handleColorChange}
      presetColors={presetColors}
    />
  );
};

export default ColorPicker;
