export type DeliveryTermsType = {
  id?: string;
  ecosystemId: string;
  shortage?: string;
  longName?: string;
  category?: string;
  description?: string;
  isActive: boolean;
  timestamp?: number;
};

export const SALES_DELIVERY_TERMS_COLLECTION_NAME = 'salesDeliveryTerms';
