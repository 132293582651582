import { FilterProgressObject } from '@services/filtering/index';
import get from 'lodash.get';

const progressMatcher = (
  filterObject: FilterProgressObject,
  row: any,
  key: string,
) => {
  if (filterObject.type === 'contains') {
    return Number(get(row, key)) === Number(filterObject.filter);
  }

  if (filterObject.type === 'lessThan') {
    return Number(get(row, key)) < Number(filterObject.filter);
  }

  if (filterObject.type === 'lessThanOrEqual') {
    return Number(get(row, key)) <= Number(filterObject.filter);
  }

  if (filterObject.type === 'greaterThan') {
    return Number(get(row, key)) > Number(filterObject.filter);
  }

  if (filterObject.type === 'greaterThanOrEqual') {
    return Number(get(row, key)) >= Number(filterObject.filter);
  }

  if (filterObject.type === 'inRange') {
    const range = filterObject.filter.split(',').map((v) => Number(v));
    const progress = Number(get(row, key));
    return progress >= range[0] && progress <= range[1];
  }

  return false;
};

export default progressMatcher;
