import React, { CSSProperties, FC, useState, useEffect } from 'react';
import { ExclamationCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { Document, getDocument } from '@apps/documents/service';
import { PageViewElementProps } from '@apps/purchases/mainMenu/operationSteps/commonTypes';
import {
  ConnectionAreaContainer,
  GridFormItem,
  InvoiceStatus,
} from '@apps/purchases/mainMenu/components/styles.sc';
import { Badge } from 'antd';
import MoneyInput from '@components/MoneyInput';

const ConnectionArea: FC<PageViewElementProps> = ({ style, bucket, intl }) => {
  const [invoice, setInvoice] = useState<Document | null>(null);
  const [documentTotalAmount, setDocumentTotalAmount] = useState(0);

  useEffect(() => {
    if (invoice === null && bucket?.relatedDocumentId) {
      getDocument(bucket.relatedDocumentId).then((doc) => {
        if (doc) {
          setInvoice(doc);
          setDocumentTotalAmount(doc.totalGrossAmount || 0);
        }
      });
    }
  }, [bucket, bucket.relatedDocumentId, invoice]);
  const positionSum =
    bucket?.positions.reduce((acc, el) => {
      let posSum = el.unitAmount * el.needAmount;
      if (el.tax) {
        posSum += posSum * (el.tax / 100);
      }
      return (acc += posSum);
    }, 0) || 0;
  let BadgeIcon: React.ForwardRefExoticComponent<any> = CheckCircleFilled;
  let color = '#0E7D40';
  if (positionSum !== documentTotalAmount) {
    BadgeIcon = ExclamationCircleFilled;
    color = '#f5222d';
  }
  let paymentStatus = intl.formatMessage({
    id: 'no',
    defaultMessage: 'No',
  });
  let partially = false;
  const upfrontPaymentStatus = bucket?.paymentTerms
    ? intl.formatMessage({
        id: 'yes',
        defaultMessage: 'Yes',
      })
    : intl.formatMessage({
        id: 'no',
        defaultMessage: 'No',
      });
  if (invoice?.payments) {
    const paymentsTotal =
      invoice?.payments.reduce((acc, el) => {
        return (acc = acc + el.payAmount);
      }, 0) || 0;
    if (paymentsTotal === documentTotalAmount) {
      paymentStatus = intl.formatMessage({
        id: 'yes',
        defaultMessage: 'Yes',
      });
    } else {
      paymentStatus = intl.formatMessage({
        id: 'partially',
        defaultMessage: 'Partially',
      });
      partially = true;
    }
  }
  return (
    <div style={{ ...(style as CSSProperties) }}>
      <ConnectionAreaContainer>
        <GridFormItem
          label={intl.formatMessage({
            id: 'aligned.with.invoice',
            defaultMessage: 'Aligned with invoice',
          })}
        >
          <InvoiceStatus>
            <Badge dot count={<BadgeIcon style={{ color }} />}>
              <div />
            </Badge>
          </InvoiceStatus>
        </GridFormItem>
        <GridFormItem
          label={intl.formatMessage({
            id: 'total.amount',
            defaultMessage: 'Total amount',
          })}
        >
          <MoneyInput currency={'EUR'} value={documentTotalAmount} readOnly />
        </GridFormItem>
        <GridFormItem
          label={intl.formatMessage({
            id: 'upfront.payment',
            defaultMessage: 'Upfront payment',
          })}
        >
          <div>{upfrontPaymentStatus}</div>
        </GridFormItem>
        <GridFormItem
          label={intl.formatMessage({
            id: 'paid',
            defaultMessage: 'Paid',
          })}
        >
          <div>{paymentStatus}</div>
        </GridFormItem>
        {partially &&
          invoice?.payments?.map((payment, index) => {
            return (
              <GridFormItem
                key={index}
                label={`${intl.formatMessage({
                  id: 'date',
                  defaultMessage: 'Date',
                })}: ${payment.payDate}`}
              >
                <MoneyInput
                  currency={'EUR'}
                  value={payment.payAmount}
                  readOnly
                />
              </GridFormItem>
            );
          })}
      </ConnectionAreaContainer>
    </div>
  );
};

export default ConnectionArea;
