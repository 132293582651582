import React, { FC } from 'react';
import { MenuItemGroupProps as AntdMenuItemGroupProps } from 'antd/lib/menu';
import { MenuItemGroupMenuStyle } from './Menu.sc';

export type MenuItemGroupProps = AntdMenuItemGroupProps;

export const MenuItemGroup: FC<MenuItemGroupProps> = ({
  children,
  ...rest
}) => {
  return <MenuItemGroupMenuStyle {...rest}>{children}</MenuItemGroupMenuStyle>;
};
