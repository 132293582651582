import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AppView from '@components/AppView';

import { deleteNewFeed } from '@apps/directSales/mainMenu/News/services';
import { columns } from './columns';
import { getRows } from './getRows';
import { SideContent } from './SideContent';

const News: React.FC = () => {
  const intl = useIntl();
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`}>
        <AppView
          breadcrumbItems={[
            [
              path,
              intl.formatMessage({
                id: 'News.nav.News',
                defaultMessage: 'News',
              }),
            ],
          ]}
          columns={columns(intl)}
          getRows={getRows}
          sideContent={SideContent}
          path={path}
          onCellClickPath={path}
          tabs={['list']}
          onRemoveItem={deleteNewFeed}
          hideQuickFilter
        />
      </Route>
    </Switch>
  );
};

export default News;
