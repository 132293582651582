import React, { FC, useCallback } from 'react';
import { Menu } from 'antd';
import { Text } from '@todix/ui-components';
import {
  Wrapper,
  EcosystemImage,
  EcosystemFilterSwitch,
  InboxFilterSwitch,
  InboxFilterText,
  InboxMenuItem,
  StyledSubMenu,
  StyledTextTitle,
} from '@apps/documents/mainMenu/components/Filters/Filters.sc';
import { Filter, FilterWithInboxes } from '@apps/documents/mainMenu/Upload';
import { FormattedMessage } from 'react-intl';

type Props = {
  filters: FilterWithInboxes[];
  onChange(filters: FilterWithInboxes[]): void;
};

const Filters: FC<Props> = ({ filters, onChange }) => {
  const onEcosystemFilterChange = useCallback(
    (ecosystemFilter: FilterWithInboxes) => {
      const newFilters = filters.map((filter) => {
        if (filter.id === ecosystemFilter.id) {
          return {
            ...filter,
            inFilter: !filter.inFilter,
            inboxes: filter.inboxes.map((inbox) =>
              inbox.disabled
                ? inbox
                : {
                    ...inbox,
                    inFilter: !filter.inFilter,
                  },
            ),
          };
        }

        return filter;
      });

      onChange(newFilters);
    },
    [filters, onChange],
  );

  const onInboxFilterChange = useCallback(
    (inboxFilter: Filter, ecosystemFilter: FilterWithInboxes) => {
      const newFilters = filters.map((filter) => {
        if (filter.id === ecosystemFilter.id) {
          return {
            ...filter,
            inboxes: filter.inboxes.map((inbox) => {
              if (inbox.disabled || inbox.id !== inboxFilter.id) {
                return inbox;
              }

              return {
                ...inbox,
                inFilter: !inbox.inFilter,
              };
            }),
          };
        }

        return filter;
      });

      onChange(newFilters);
    },
    [filters, onChange],
  );

  return (
    <Wrapper>
      <Text level={1}>
        <FormattedMessage
          id="documents.filters.text"
          defaultMessage="Filter by"
        />
      </Text>
      <Menu mode="inline" selectable={false}>
        {filters.map((ecosystemFilter) => (
          <StyledSubMenu
            key={ecosystemFilter.id}
            icon={
              <EcosystemImage
                preview={false}
                src={ecosystemFilter.avatarUrl}
                width={24}
                height={24}
              />
            }
            title={
              <>
                <StyledTextTitle level={2}>
                  {ecosystemFilter.name} (
                  {ecosystemFilter.amount ? ecosystemFilter.amount : 0}){' '}
                </StyledTextTitle>
                <EcosystemFilterSwitch
                  size="small"
                  checked={ecosystemFilter.inFilter}
                  disabled={ecosystemFilter.disabled}
                  onClick={(_v, e) => {
                    // handle ecosystem filter
                    e.preventDefault();
                    e.stopPropagation();
                    onEcosystemFilterChange(ecosystemFilter);
                  }}
                />
              </>
            }
          >
            {ecosystemFilter.inboxes.map((inboxFilter) => (
              <InboxMenuItem key={inboxFilter.id}>
                <InboxFilterText>
                  {inboxFilter.name} (
                  {inboxFilter.amount ? inboxFilter.amount : 0}){' '}
                </InboxFilterText>
                <InboxFilterSwitch
                  size="small"
                  checked={inboxFilter.inFilter}
                  disabled={inboxFilter.disabled}
                  onClick={() => {
                    // handle inbox filter
                    if (ecosystemFilter.inFilter) {
                      onInboxFilterChange(inboxFilter, ecosystemFilter);
                    }
                  }}
                />
              </InboxMenuItem>
            ))}
          </StyledSubMenu>
        ))}
      </Menu>
    </Wrapper>
  );
};

export default Filters;
