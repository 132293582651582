import React from 'react';
import { CostEntity, PaymentEntity } from '@apps/documents/service';
import { Col, Form, Row, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Text } from '@todix/ui-components';
import { TaxCategory } from '@apps/finance/services/taxCategories';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import InnerInput from '@apps/documents/mainMenu/components/InnerInput';
import styled from 'styled-components';

const StyledCol = styled(Col)`
  label {
    width: 100%;
  }
`;

type SummaryProps = {
  taxRates: TaxCategory[];
};

const Summary: React.FC<SummaryProps> = ({ taxRates }) => {
  const { getFieldsValue } = useFormContext();
  const { costStructure, payments } = getFieldsValue();
  if (!costStructure || !costStructure?.length) {
    return null;
  }
  if (!payments || !payments.length) {
    return null;
  }
  const taxValues: Record<string, any> = taxRates.reduce((acc, it) => {
    if (!it.id) {
      return acc;
    }
    return {
      ...acc,
      [it.id]: it.value,
    };
  }, {});
  const sumGrossAmount = (costStructure as CostEntity[])?.reduce((acc, it) => {
    if (!it.netAmount) {
      return acc;
    }
    return Number(
      Number(
        acc +
          (Number(it.netAmount) +
            Number(it.netAmount) * Number(taxValues[it.taxRateId] / 100)),
      ).toFixed(2),
    );
  }, 0);
  const paidTotal = (payments as PaymentEntity[])?.reduce((acc, it) => {
    if (!it.payAmount) {
      return acc;
    }
    return acc + Number(Number(it.payAmount).toFixed(2));
  }, 0);

  const unpaidLabel = (
    <FormattedMessage
      id={'documents.processinginvoice.payments.unpaid.label'}
      defaultMessage="Unpaid"
    />
  );
  const grossAmountLabel = (
    <FormattedMessage
      id={'documents.processinginvoice.payments.grossamount.label'}
      defaultMessage="Gross amount"
    />
  );
  const paidTotalLabel = (
    <FormattedMessage
      id={'documents.processinginvoice.payments.paidtotal.label'}
      defaultMessage="Paid total"
    />
  );

  return (
    <Form.Item noStyle shouldUpdate>
      {() => {
        return (
          <Row gutter={[1, 1]}>
            <StyledCol span={8}>
              <Form.Item
                label={
                  <Tooltip title={unpaidLabel}>
                    <Text level={2} strong ellipsis>
                      {unpaidLabel}
                    </Text>
                  </Tooltip>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InnerInput
                  value={
                    sumGrossAmount ? (sumGrossAmount - paidTotal).toFixed(2) : 0
                  }
                  readOnly
                />
              </Form.Item>
            </StyledCol>
            <StyledCol span={8}>
              <Form.Item
                label={
                  <Tooltip title={grossAmountLabel}>
                    <Text level={2} strong ellipsis>
                      {grossAmountLabel}
                    </Text>
                  </Tooltip>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InnerInput value={sumGrossAmount?.toFixed(2) || 0} readOnly />
              </Form.Item>
            </StyledCol>
            <StyledCol span={8}>
              <Form.Item
                label={
                  <Tooltip title={paidTotalLabel}>
                    <Text level={2} strong ellipsis>
                      {paidTotalLabel}
                    </Text>
                  </Tooltip>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InnerInput value={paidTotal?.toFixed(2) || 0} readOnly />
              </Form.Item>
            </StyledCol>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default Summary;
