import * as React from 'react';

type DownloadProps = {
  url: string;
  filename?: string;
};

const Download: React.FC<DownloadProps> = ({ url, filename, children }) => {
  const download =
    filename || url.split('/').pop()?.split('?')[0].split('#')[0];

  return (
    <a href={url} download={download} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default Download;
