import React from 'react';
import Modal from '@components/Modal';
import { resetDocument, Document } from '@apps/documents/service';
import { emitCustomEvent } from '@core/services';
import { ListEvents } from '@components/List';
import { FormattedMessage, IntlShape } from 'react-intl';
import { AppViewEvent, SelectItemPayload } from '@components/AppView';
import { ExtraOptions } from '@components/AppView/ActionRenderer';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig, { DOCUMENTS_ACTION } from '@apps/documents';

export type AddPaymentEvent = 'addPayment';

export type AddPaymentEventPayload = {
  item: Partial<Document>;
};

const getExtraOptions = (
  item: Partial<Document>,
  intl: IntlShape,
): ExtraOptions => {
  const ecosystemId = item?.ecosystem || '';
  const canEdit = checkUserPermissions(
    rootStore.getState(),
    appConfig.todixId,
    'update-documents' as DOCUMENTS_ACTION,
    ecosystemId,
  );
  const addPayment: MenuItemProps = {
    children: intl.formatMessage({
      id: 'documents.getextraoptions.addpayment',
      defaultMessage: 'add payment',
    }),
    danger: false,
    onClick: () => {
      emitCustomEvent<AppViewEvent, SelectItemPayload>('selectItem', {
        id: item.id as string,
        extraAction() {
          setTimeout(() => {
            emitCustomEvent<AddPaymentEvent, AddPaymentEventPayload>(
              'addPayment',
              {
                item,
              },
            );
          }, 100);
        },
      });
    },
  };

  const reset: MenuItemProps = {
    children: intl.formatMessage({
      id: 'documents.getextraoptions.reset',
      defaultMessage: 'reset',
    }),
    danger: true,
    onClick: () => {
      Modal.confirm({
        content: (
          <FormattedMessage
            id="documents.getextraoptions.reset.confirm"
            defaultMessage="Are you sure you want to reset this item?"
          />
        ),
        onOk: () => {
          if (item.id) {
            resetDocument(item.id)?.then(() => {
              emitCustomEvent<ListEvents>('refreshList');
            });
          }
        },
      });
    },
  };
  if (!canEdit) {
    return [];
  }

  return item.type?.toLowerCase() === 'invoice' ? [addPayment, reset] : [reset];
};

export default getExtraOptions;
