import { SKREntry, SKRTreeEntry } from '@apps/finance/services/skr/namespace';

const arrayToTree = (arr: SKRTreeEntry[], parent = ''): any[] =>
  arr
    .filter((item) => item.parent === parent)
    .map((child) => ({ ...child, children: arrayToTree(arr, child.id) }));

const getSKRTree = (skrArray: SKREntry[]): SKRTreeEntry[] =>
  arrayToTree(skrArray as SKRTreeEntry[], skrArray[0].id);

export default getSKRTree;
