import { styled } from '@styles/themes';
import { InboxOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';

const { Dragger } = Upload;

export const InboxCard = styled.div`
  display: flex;
  min-height: 129px;
  height: 100%;
  padding: 24px 22px;
  border: solid 1px #f5f5f5;

  > * {
    display: grid;
    grid-template-columns: 50% 50%;
    overflow: hidden;
    padding: 5px;
  }

  .ant-upload-list {
    flex: 1;
    padding: 0 5px;
    max-height: 50vh !important;
    overflow: auto;
  }
`;

export const DropZoneArea = styled(Dragger)`
  && {
    position: relative;
    flex: 0 0 260px;
    margin-right: 10px;
    min-width: 180px;
    & > .ant-upload-btn {
      padding 16px;
    }
  }
`;

export const InboxIcon = styled(InboxOutlined)`
  color: ${({ theme }) => theme.colors.main.primary};
  font-size: 40px;
`;

export const EcosystemImage = styled(Image)`
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const UploadText = styled.p`
  && {
    margin: 16px auto 6px;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text.normal};
    width: 140px;
  }
`;

export const UploadHint = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.gray};
`;

export const InboxIconWithName = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.main.primary};
`;

export const ErrorWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;

  .ant-alert {
    width: 100%;
  }
`;
