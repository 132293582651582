import React from 'react';
import { Select, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FilterTextObject } from '@services/filtering';
import {
  DocumentsTypesSettingsResponse,
  DocumentType,
  getDocumentsTypesSettings,
} from '@apps/documents/service';
import { rootStore } from '@core/store';
import {
  EcosystemState,
  getActiveEcosystemsForApp,
} from '@components/EcosystemIndicator/store';
import { FilterWrapper } from '@components/List/CustomsFilters/styles';
import appConfig, { DOCUMENTS_ACTION } from '@apps/documents';

const { Option } = Select;

type State = {
  type: string;
  loading: boolean;
  types: DocumentType[];
};

type Props = {
  activeEcosystems: EcosystemState;
  filterChangedCallback: any;
};

// TODO: change it to the forwardRef FC
// can't be connected to the redux directly, because methods: isFilterActive, getModelAsString, getModel, setModel need to be accessed by ag-grid directly
class DocumentTypeFilter extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      type: '',
      loading: true,
      types: [],
    };
  }

  componentDidMount() {
    const activeEcosystems = getActiveEcosystemsForApp(
      rootStore.getState(),
      appConfig.todixId,
      'view-documents' as DOCUMENTS_ACTION,
    );
    const ecoIds = activeEcosystems.map((eco) => eco.id);
    const docTypesInEcosystemPromises = ecoIds.map((id) =>
      getDocumentsTypesSettings(id),
    );
    Promise.all(docTypesInEcosystemPromises)
      .then((response) => {
        const types: DocumentType[] = [
          ...new Set(
            (response as DocumentsTypesSettingsResponse[])
              .map((entry) => entry.docTypes)
              .flat()
              .map((entry) => entry.docType),
          ),
        ].sort();
        this.setState({
          ...this.state,
          types,
          loading: false,
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  }

  onValueChange = (value: any) => {
    this.setState(
      {
        ...this.state,
        type: value,
      },
      this.props.filterChangedCallback,
    );
  };

  isFilterActive = () => {
    return !!this.state.type;
  };

  getModelAsString = () => {
    return '';
  };

  getModel = () => {
    return this.state.type
      ? ({
          filter: this.state.type,
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject)
      : null;
  };

  setModel = (model: any) => {
    this.setState({
      ...this.state,
      type: model?.type || null,
    });
  };

  render() {
    return (
      <FilterWrapper>
        <Spin spinning={this.state.loading}>
          <Select
            id="documentTypes"
            onChange={this.onValueChange}
            allowClear
            disabled={this.state.loading}
            placeholder={
              <FormattedMessage
                id="documents.commonfields.type.label"
                defaultMessage="Document type"
              />
            }
            style={{ width: 200 }}
          >
            {this.state.types.map((type) => (
              <Option key={type} id={type} value={type}>
                <FormattedMessage
                  id={`documents.documentTypes.${type}`}
                  defaultMessage={`documents.documentTypes.${type}`}
                />
              </Option>
            ))}
          </Select>
        </Spin>
      </FilterWrapper>
    );
  }
}

export default DocumentTypeFilter;
