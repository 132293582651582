import React, { FC } from 'react';
import { Form, InputNumber } from 'antd';

const { Item } = Form;

type StockLevelInputProps = {
  name: string;
  label: string;
  placeholder: string;
  level: 'max' | 'order' | 'target' | 'critical';
  readOnly: boolean;
};

const StockLevelInput: FC<StockLevelInputProps> = ({
  name,
  label,
  placeholder,
  readOnly,
  level,
}) => {
  return (
    <Item name={name} label={label} data-level={level}>
      <InputNumber
        width="100%"
        type="number"
        name={name}
        placeholder={placeholder}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default StockLevelInput;
