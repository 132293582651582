import * as React from 'react';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { Button, Tooltip } from 'antd';
import { Icon } from '@todix/ui-components';
import { ButtonWrapper } from '@core/layout/components/CreateButtonPlatform/CreateButtonPlatform.sc';
import DropdownWithFullScreenMode from '@components/DropdownWithFullScreenMode';
import CreateButtonMenu from '@core/layout/components/CreateButtonPlatform/CreateButtonMenu';
import {
  CreateButtonContextAction,
  DispatchProps,
  StateProps,
} from '@core/layout/components/CreateButtonPlatform';
import { useIntl } from 'react-intl/lib';
import { FormattedMessage } from 'react-intl';

type Props = StateProps & DispatchProps;

export const CreateButtonPlatform: React.FC<Props> = ({ applications }) => {
  const [visible, setVisible] = React.useState(false);
  const intl = useIntl();
  const contextActions: CreateButtonContextAction[] = applications
    .filter((app) => app.createButtonAction)
    .map((action) => ({
      app: intl.formatMessage({
        id: `app.${action.contextName}`,
        defaultMessage: action.name,
      }),
      link: action.createButtonAction as string,
    }));

  const handleMenuClick: MenuClickEventHandler = () => {
    setVisible(false);
  };

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  return (
    <ButtonWrapper>
      <DropdownWithFullScreenMode
        overlay={
          <CreateButtonMenu
            handleMenuClick={handleMenuClick}
            contextActions={contextActions}
          />
        }
        trigger={['click']}
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <Tooltip
          title={
            <FormattedMessage
              id="createbuttonplatform.create"
              defaultMessage="Create new"
            />
          }
        >
          <Button type="primary" icon={<Icon.AppstoreAddOutlined />} />
        </Tooltip>
      </DropdownWithFullScreenMode>
    </ButtonWrapper>
  );
};
