import React, { FC } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Commission } from '@apps/directSales/services/commisions';
import intl from '../../../../../intl';
import FormContainer from '@components/FormContainer';
import TeamSizeInput from '@apps/directSales/settings/Commissions/components/TeamSizeInput';
import RuleNameInput from '@apps/directSales/settings/Commissions/components/RuleNameInput';
import TeamSalesInput from '@apps/directSales/settings/Commissions/components/TeamSalesInput';
import MemberBonusInput from '@apps/directSales/settings/Commissions/components/MemberBonusInput';
import CommissionThresholds from '@apps/directSales/settings/Commissions/components/CommissionThresholds';
import CommissionSalesInput from '@apps/directSales/settings/Commissions/components/CommissionSalesInput';
import YearlyJokerInput from '@apps/directSales/settings/Commissions/components/YearlyJokerInput';
import { ScrollableColumn } from '@apps/directSales/styles.sc';
import RelatedLevelSelect from '@apps/directSales/settings/Commissions/components/RelatedLevelSelect';
import ProductBonus from '@apps/directSales/settings/Commissions/components/ProductBonus';
import RecruitingBonus from '@apps/directSales/settings/Commissions/components/RecruitingBonus';

type Props = {
  form: FormInstance;
  commission: Commission | null;
  ecosystemId?: string;
};
const CommissionForm: FC<Props> = ({ form, commission = {}, ecosystemId }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      name="commission_form"
      initialValues={commission || { ecosystemId }}
    >
      <FormContainer>
        <ScrollableColumn>
          <RuleNameInput intl={intl} />
          <RelatedLevelSelect intl={intl} ecosystemId={ecosystemId} />
          <CommissionSalesInput intl={intl} />
          <TeamSizeInput intl={intl} />
          <TeamSalesInput intl={intl} />
          <MemberBonusInput intl={intl} />
          <YearlyJokerInput intl={intl} />
        </ScrollableColumn>
        <ScrollableColumn>
          <ProductBonus intl={intl} />
          <RecruitingBonus intl={intl} />
          <CommissionThresholds intl={intl} />
        </ScrollableColumn>
        <Form.Item name="ecosystemId" hidden />
      </FormContainer>
    </Form>
  );
};

export default CommissionForm;
