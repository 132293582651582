import { styled } from '@styles/themes';
import { Link } from 'react-router-dom';
import { Menu, SubMenu, MenuItem } from '@todix/ui-components';
import { Layout, Menu as AntdMenu } from 'antd';
import { css } from 'styled-components';

export const StyledMenu = styled(Menu)`
  width: 256px;
  margin-left: 5px;
  margin-right: 5px;
  border: 0;
  user-select: none;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const StyledMenuLink = styled(Link)`
  display: flex;
  justify-content: space-between;
`;

const collapsedMenuStyles = css`
  && {
    padding-left: 14px !important;
  }
  &&&& .ant-menu-title-content {
    flex: none;
  }
`;

export const StyledCollapsedMenu = styled<any>(MenuItem)`
  ${(props) => {
    return props.collapsed && collapsedMenuStyles;
  }}
`;

export const OldAppMenuWrapper = styled.span`
  flex: none;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const collapsedSubMenuStyles = css`
  && {
    padding-left: 0 !important;
  }
  &&&& .ant-menu-submenu-title {
    flex: none;
    padding-left: 14px !important;
  }

  && .ant-menu-item {
    padding-left: 14px !important;
  }
`;

export const StyledCollapsedSubMenu = styled<any>(SubMenu)`
  ${(props) => {
    return props.collapsed && collapsedSubMenuStyles;
  }}
`;

export const Sider = styled(Layout.Sider)`
  background-color: ${(props) => props.theme.colors.background.light};
  top: 56px;
  left: 0;
  bottom: 0;

  && {
    .ant-layout-sider-children {
      display: grid;
      grid-template-rows: 60vh 40vh;
    }
  }
`;

export const StyledSubmenu = styled(SubMenu)`
  && {
    .ant-menu-submenu-title {
      display: flex;
      flex-direction: row;
      font-size: 14px;
    }
    .ant-menu-submenu-arrow {
      position: relative;
      align-self: center;
      top: unset;
      right: unset;
      margin-left: 8px;
    }
    .ant-menu-item a {
      font-size: 16px;
    }
  }
`;

export const MenuDivider = styled(AntdMenu.Divider)`
  && {
    margin: 12px 0;
  }
`;
