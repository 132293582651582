import { css } from 'styled-components';
import { styled } from '@styles/themes';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

export const ActionIconStyled = css`
  font-size: 18px;
  margin-left: 8px;
  margin-right: 8px;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.main.primary};
  }
`;

export const StyledPlusCircleOutlined = styled(PlusCircleOutlined)`
  ${ActionIconStyled}
`;

export const StyledEditIcon = styled(EditOutlined)`
  ${ActionIconStyled}
`;

export const StyledDeleteIcon = styled(DeleteOutlined)`
  ${ActionIconStyled}
`;
