export const ENTITY_RELATIONS_COLLECTION = 'entityRelations';

export type EntityRelation = {
  id?: string;
  timestamp?: any;
  parentId: string;
  parentRefCollection: string;
  parentType: string;
  childId: string;
  childRefCollection: string;
  childType: string;
  ecosystem: string;
};
