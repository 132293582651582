import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { IGetRowsParams } from 'ag-grid-community';
import { useIntl } from 'react-intl';
import { message } from 'antd';

import { deleteProduct, patchProduct } from '@apps/products/services';
import AppView from '@core/components/AppView';
import { columns } from './components/columns';
import { SideContent } from './components/SideContent';
import Modal from '@components/Modal';
import { FilterTextObject } from '@services/filtering';
import optionsMapper from '@core/helpers/optionsMapper';
import { getSalesOperations } from '@apps/sales/services/saleOperation';
import { SALES_ACTION } from '@apps/sales';

const View: FC<{}> = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();
  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getSalesOperations(
      {
        query: params.query,
        offset: params.startRow,
        limit: params.endRow - params.startRow,
        fields: [
          'ecosystem',
          'deliveryDate',
          'id',
          'contactSnapshot',
          'products',
          'creatorSnapshot',
          'creationDate',
          'docName',
          'type',
        ],
        filters: Object.fromEntries(filtersEntries),
        sort: params.sortModel.map(
          (model: { colId: string; sort: string }) =>
            `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
        ),
      },
      'view-sales' as SALES_ACTION,
    ).then((response) => {
      params.successCallback(response.results, response.results.length);
    });
  };

  return (
    <AppView
      hideQuickFilter
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'app.sales',
            defaultMessage: 'Sales',
          }),
        ],
      ]}
      options={optionsMapper(['open', 'edit', 'delete'], intl)}
      columns={useMemo(() => columns(intl), [intl])}
      getRows={getRows}
      sideContent={SideContent}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      onRemoveItem={(id) =>
        deleteProduct(id)
          ?.then(() => {})
          .catch(() => {
            message.error(
              intl.formatMessage({
                id: 'products.deleteproduct.error',
                defaultMessage: 'Error while removing product',
              }),
            );
          })
      }
      getExtraOptions={(item) => {
        const activationLabel = item.isActive ? 'deactivate' : 'reactivate';
        return [
          {
            children: activationLabel,
            onClick: () => {
              Modal.confirm({
                content: intl.formatMessage(
                  {
                    id: 'products.confirmation',
                    defaultMessage:
                      'Are you sure you want to {action} this item?',
                  },
                  { action: activationLabel },
                ),
                onOk: () => {
                  patchProduct(item.id, {
                    isActive: !item?.isActive,
                  })
                    .then(() => {})
                    .catch(() => {
                      message.error(
                        intl.formatMessage({
                          id: 'products.patchproduct.error',
                          defaultMessage: 'Error while updating product',
                        }),
                      );
                    });
                },
              });
            },
          },
        ];
      }}
    />
  );
};

export default View;
