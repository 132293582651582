import { patch } from '@services/api';
import {
  PURCHASES_COLLECTION,
  Purchase,
} from '@apps/purchases/services/namespace';
import { removeUndefinedProperties } from '@services/utils';
import moment from 'moment';

const updatePurchase = (id: string, purchase: Partial<Purchase>) => {
  return patch(`${PURCHASES_COLLECTION}/${id}`, {
    ...removeUndefinedProperties(purchase),
    timestamp: moment().valueOf(),
  });
};

export default updatePurchase;
