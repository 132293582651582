import * as React from 'react';
import { Form, Space, Select, Input } from 'antd';
import { Radio } from '@components/FormFields';
import { Button, Text } from '@todix/ui-components';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Rule } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';

type Props = {
  label: string;
  options: SelectProps<string>['options'];
  arrayName: string;
  firstInputNameField: string;
  firstInputPlaceholder?: string;
  firstInputRules?: Rule[];
  secondInputNameField: string;
  secondInputPlaceholder?: string;
  secondInputRules?: Rule[];
  addNextButtonLabel: string;
  standardFieldName: string;
  defaultValue?: any;
};

const DynamicFieldTwoInputs: React.FC<Props> = ({
  label,
  options,
  arrayName,
  firstInputNameField,
  firstInputRules,
  firstInputPlaceholder,
  secondInputNameField,
  secondInputRules,
  secondInputPlaceholder,
  addNextButtonLabel,
  standardFieldName,
  defaultValue = {},
}) => {
  const { getFieldValue, setFields, readOnly } = useFormContext();
  const [checkedValue, setCheckedValue] = React.useState(
    getFieldValue(standardFieldName),
  );

  return (
    <Form.List name={arrayName}>
      {(fields, { add, remove }) => (
        <>
          {(fields as any).map(({ key, ...field }: any, index: any) => (
            <Form.Item key={key} label={`${label}${index + 1}`}>
              <Form.Item
                {...field}
                noStyle
                name={[field.name, firstInputNameField]}
                fieldKey={[field.fieldKey, firstInputNameField]}
                rules={firstInputRules}
              >
                <Select
                  disabled={readOnly}
                  placeholder={firstInputPlaceholder}
                  options={options}
                  style={{
                    display: 'inline-block',
                    width: 140,
                    marginRight: 4,
                  }}
                />
              </Form.Item>
              <Form.Item
                {...field}
                noStyle
                name={[field.name, secondInputNameField]}
                fieldKey={[field.fieldKey, secondInputNameField]}
                rules={secondInputRules}
              >
                <Input
                  readOnly={readOnly}
                  autoComplete="nope"
                  placeholder={secondInputPlaceholder}
                  style={{
                    display: 'inline-block',
                    width: 140,
                    marginRight: 4,
                  }}
                />
              </Form.Item>
              <Form.Item
                name={[field.name, 'standard']}
                fieldKey={[field.fieldKey, 'standard']}
                noStyle
              >
                <Space size="small" direction="horizontal">
                  <Radio
                    disabled={readOnly}
                    name={standardFieldName}
                    checked={checkedValue === index}
                    onChange={(_e) => {
                      setFields([
                        {
                          name: standardFieldName,
                          value: index,
                        },
                      ]);
                      setCheckedValue(index);
                    }}
                  />
                  <Text level={2}>
                    <FormattedMessage
                      id="detailcontactform.dynamicfieldtwoinputs.text"
                      defaultMessage="Standard"
                    />
                  </Text>
                </Space>
              </Form.Item>
              <Form.Item noStyle>
                {!readOnly && (
                  <MinusCircleOutlined
                    onClick={() => {
                      remove(field.name);
                      if (checkedValue === index) {
                        setFields([
                          {
                            name: standardFieldName,
                            value: index >= 1 ? index - 1 : 0,
                          },
                        ]);
                        setCheckedValue(index >= 1 ? index - 1 : 0);
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Form.Item>
          ))}
          {!readOnly && (
            <Form.Item>
              <Button
                size="small"
                type="primary"
                onClick={() => add(defaultValue)}
                block
              >
                {addNextButtonLabel}
              </Button>
            </Form.Item>
          )}
        </>
      )}
    </Form.List>
  );
};

export default DynamicFieldTwoInputs;
