import React, { FC, useRef, useState } from 'react';
import { Form, Space, Tabs, Select, Input, Collapse } from 'antd';
import * as iban from 'iban';
import { Text } from '@todix/ui-components';
import { FormListOperation } from 'antd/lib/form/FormList';
import { Radio } from '@components/FormFields';
import {
  BankOutlined,
  BankTwoTone,
  DeleteOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import {
  CompoundFieldTabs,
  DivFlex,
  MaxItem,
} from '@apps/contacts/mainMenu/components/DetailContactForm/DetailContactForm.sc';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { getBankAccountsLabels } from '@services/dictionary';
import { useCustomEventListener } from '@core/services';
import {
  ON_FINISH_FAILED_EVENT,
  OnFinishFailedEvent,
} from '@components/AppEntityForm/AppEntityForm';
import Warning from '@apps/contacts/mainMenu/components/DetailContactForm/Warning';
import ContentIndicator from '@apps/contacts/mainMenu/components/DetailContactForm/ContentIndicator';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const BankAccount: FC = () => {
  const intl = useIntl();
  const { getFieldValue, setFields, readOnly } = useFormContext();
  const [checkedValue, setCheckedValue] = useState(
    getFieldValue('standardBankAccount'),
  );
  const [hasError, setHasError] = useState(false);
  const addBankAccountRef = useRef<any>(() => {});
  const activePanel = useRef<string[]>([]);

  useCustomEventListener<'onFinishFailed', OnFinishFailedEvent>(
    ON_FINISH_FAILED_EVENT,
    (data) => {
      if (data) {
        const error =
          data.errorInfo.errorFields.filter((err) =>
            err.name.includes('bankAccounts'),
          ).length > 0;
        if (error) {
          setHasError(true);
        } else {
          setHasError(false);
        }
      }
    },
  );

  const component = (
    { key, ...field }: any,
    fieldIndex: number,
    remove: FormListOperation['remove'],
  ) => {
    return (
      <DivFlex>
        <MaxItem
          required
          label={
            <FormattedMessage
              id="detailcontactform.bankaccount.label"
              defaultMessage="Bank account {index}"
              values={{ index: fieldIndex + 1 }}
            />
          }
        >
          <Form.Item
            {...field}
            noStyle
            name={[field.name, `label`]}
            fieldKey={[field.fieldKey, `label`]}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'detailcontactform.bankaccount.label.error',
                  defaultMessage: 'Missing label',
                }),
              },
            ]}
          >
            <Select
              disabled={readOnly}
              placeholder={
                <FormattedMessage
                  id="detailcontactform.bankaccount.label.placeholder"
                  defaultMessage="Bank account label"
                />
              }
              options={getBankAccountsLabels(intl)}
              style={{ width: 140, marginRight: 4 }}
            />
          </Form.Item>
          <Form.Item name="standardBankAccount" noStyle>
            <Space size="small" direction="horizontal">
              <Radio
                disabled={readOnly}
                name="standardBankAccount"
                checked={checkedValue === fieldIndex}
                onChange={() => {
                  setFields([
                    {
                      name: 'standardBankAccount',
                      value: fieldIndex,
                    },
                  ]);
                  setCheckedValue(fieldIndex);
                }}
              />
              <Text level={2}>
                <FormattedMessage
                  id="detailcontactform.bankaccount.standard.text"
                  defaultMessage="Standard"
                />
              </Text>
            </Space>
          </Form.Item>
          <Form.Item noStyle>
            {!readOnly && (
              <DeleteOutlined
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={() => {
                  remove(field.name);
                  if (checkedValue === fieldIndex) {
                    setFields([
                      {
                        name: 'standardBankAccount',
                        value: fieldIndex >= 1 ? fieldIndex - 1 : 0,
                      },
                    ]);
                    setCheckedValue(fieldIndex >= 1 ? fieldIndex - 1 : 0);
                  }
                }}
              />
            )}
          </Form.Item>
        </MaxItem>
        <Form.Item
          {...field}
          label={
            <FormattedMessage
              id="detailcontactform.bankaccount.accounttype.label"
              defaultMessage="Account type"
            />
          }
          required
          name={[field.name, `type`]}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'detailcontactform.bankaccount.accounttype.error',
                defaultMessage: 'Missing account type',
              }),
            },
          ]}
          fieldKey={[field.fieldKey, `type`]}
        >
          <Select
            disabled={readOnly}
            placeholder={
              <FormattedMessage
                id="detailcontactform.bankaccount.accounttype.placeholder"
                defaultMessage="Select bank account type"
              />
            }
            options={[
              {
                label: intl.formatMessage({
                  id: 'detailcontactform.bankaccount.accounttype.option',
                  defaultMessage: 'Bank connection',
                }),
                value: 'bank',
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          required
          label={
            <FormattedMessage
              id="detailcontactform.bankaccount.iban.label"
              defaultMessage="IBAN"
            />
          }
        >
          <Form.Item
            {...field}
            noStyle
            name={[field.name, `iban`]}
            fieldKey={[field.fieldKey, `iban`]}
            rules={[
              {
                required: true,
                type: 'string',
                validator: (_rule, value) => {
                  if (!iban.isValid(value)) {
                    return Promise.reject(
                      intl.formatMessage({
                        id: 'detailcontactform.bankaccount.iban.error',
                        defaultMessage: 'Invalid IBAN',
                      }),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              readOnly={readOnly}
              autoComplete="nope"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.bankaccount.iban.placeholder',
                defaultMessage: 'Type IBAN...',
              })}
              style={{ marginBottom: 12 }}
            />
          </Form.Item>
          <Form.Item
            {...field}
            noStyle
            name={[field.name, `bankName`]}
            fieldKey={[field.fieldKey, `bankName`]}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'detailcontactform.bankaccount.iban.bankname.error',
                  defaultMessage: 'Missing bank name',
                }),
              },
            ]}
          >
            <Input
              readOnly={readOnly}
              autoComplete="nope"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.bankaccount.iban.bankname.placeholder',
                defaultMessage: 'Bank name',
              })}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          {...field}
          label={
            <FormattedMessage
              id="detailcontactform.bankaccount.swift.label"
              defaultMessage="Bic Swift"
            />
          }
          required
          name={[field.name, `swift`]}
          fieldKey={[field.fieldKey, `swift`]}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'detailcontactform.bankaccount.swift.error',
                defaultMessage: 'Missing Swift',
              }),
            },
          ]}
        >
          <Input
            readOnly={readOnly}
            autoComplete="nope"
            placeholder={intl.formatMessage({
              id: 'detailcontactform.bankaccount.swift.placeholder',
              defaultMessage: 'Bic Swift...',
            })}
          />
        </Form.Item>
      </DivFlex>
    );
  };

  return (
    <Collapse
      onChange={(keys) => {
        activePanel.current = keys as string[];
      }}
      expandIcon={() =>
        hasError ? (
          <div>
            <Warning />
            <ContentIndicator
              emptyIcon={<BankOutlined />}
              contentIcon={<BankTwoTone />}
              emptyIndicator={() =>
                getFieldValue('bankAccounts')
                  ? (getFieldValue('bankAccounts') as []).length < 1
                  : true
              }
            />
          </div>
        ) : (
          <div>
            <ContentIndicator
              emptyIcon={<BankOutlined />}
              contentIcon={<BankTwoTone />}
              emptyIndicator={() =>
                getFieldValue('bankAccounts')
                  ? (getFieldValue('bankAccounts') as []).length < 1
                  : true
              }
            />
          </div>
        )
      }
    >
      <Panel
        header={
          <FormattedMessage
            id="detailcontactform.contacts.title"
            defaultMessage="Bank account"
          />
        }
        key="1"
        extra={
          !readOnly ? (
            <PlusSquareOutlined
              onClick={(event) => {
                if (activePanel.current.length) {
                  event.stopPropagation();
                }
                addBankAccountRef.current();
              }}
            />
          ) : undefined
        }
        forceRender
      >
        <Form.List name="bankAccounts">
          {(fields, { add, remove }) => {
            addBankAccountRef.current = () => {
              // first item will be added
              const standard = fields.length === 0;
              add({
                standard,
              });
              if (standard) {
                setFields([
                  {
                    name: 'standardBankAccount',
                    value: 0,
                  },
                ]);
                setCheckedValue(0);
              }
            };
            return (
              <div>
                <CompoundFieldTabs defaultActiveKey="0">
                  {fields.map((field, index) => {
                    return (
                      <TabPane
                        key={`bankAccounts${index}`}
                        tab={
                          <FormattedMessage
                            id="detailcontactform.bankaccount.tab"
                            defaultMessage="Bank account #{index}"
                            values={{ index: index + 1 }}
                          />
                        }
                      >
                        {component(field, index, remove)}
                      </TabPane>
                    );
                  })}
                </CompoundFieldTabs>
              </div>
            );
          }}
        </Form.List>
      </Panel>
    </Collapse>
  );
};

export default BankAccount;
