import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AppView from '@components/AppView';

import { deleteParty } from '@apps/directSales/mainMenu/Parties/services';
import { columns } from './columns';
import { getRows } from './getRows';
import { SideContent } from './SideContent';
import optionsMapper from '@core/helpers/optionsMapper';

const Parties: React.FC = () => {
  const intl = useIntl();
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`}>
        <AppView
          breadcrumbItems={[
            [
              path,
              intl.formatMessage({
                id: 'parties.nav.parties',
                defaultMessage: 'Parties',
              }),
            ],
          ]}
          options={optionsMapper(['open', 'edit', 'delete'], intl)}
          columns={columns(intl)}
          getRows={getRows}
          sideContent={SideContent}
          path={path}
          onCellClickPath={path}
          tabs={['list']}
          onRemoveItem={deleteParty}
          hideQuickFilter
        />
      </Route>
    </Switch>
  );
};

export default Parties;
