import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type DueDateProps = {
  readOnly: boolean;
};

const DueDate: FC<DueDateProps> = ({ readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name="dueDate"
      label={
        <FormattedMessage
          id="tasks.form.duedate.label"
          defaultMessage="Due Date"
        />
      }
    >
      <DatePicker
        disabled={readOnly}
        placeholder={intl.formatMessage({
          id: 'tasks.form.duedate.placeholder',
          defaultMessage: 'Select date',
        })}
      />
    </Item>
  );
};

export default DueDate;
