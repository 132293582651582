import * as React from 'react';
import { Modal as AntdModal } from 'antd';
import { ModalProps as AntdModalProps } from 'antd/lib/modal';
import useReminder from '@core/hooks/useReminder';
import { ModalFuncProps } from 'antd/lib/modal/Modal';

export type ModalProps = AntdModalProps & {
  isDirty?: boolean;
};

type ModalType = React.FC<ModalProps> & {
  confirm(props: ModalFuncProps): void;
};

// this modal is based on Antd Modal and handles fullscreen support
const ModalRef: React.FC<ModalProps> = (props) => {
  const { isDirty = false, onCancel } = props;
  const { handleCancel } = useReminder({
    onCancel,
    isDirty,
  });

  return (
    <AntdModal
      {...props}
      getContainer={() => {
        const mainElement = document
          .getElementsByClassName('fullscreen')
          .item(0) as HTMLElement;
        return mainElement || document.body;
      }}
      onCancel={handleCancel}
      mask={false}
      destroyOnClose
    />
  );
};

const Modal = ModalRef as unknown as ModalType;

Modal.confirm = (props: ModalFuncProps) => {
  AntdModal.confirm({
    ...props,
    getContainer: () => {
      const mainElement = document
        .getElementsByClassName('fullscreen')
        .item(0) as HTMLElement;
      return mainElement || document.body;
    },
  });
  return null;
};

export default Modal;
