export enum UserActionTypes {
  SET_USER = 'SET_USER',
  SET_USER_APPS_ORDER = 'SET_USER_APPS_ORDER',
}

export const setUser = (payload: User | null) =>
  ({
    type: UserActionTypes.SET_USER,
    payload,
  } as const);

export type SetUserAction = ReturnType<typeof setUser>;

export const setUserAppsOrder = (payload: string[] | null) =>
  ({
    type: UserActionTypes.SET_USER_APPS_ORDER,
    payload,
  } as const);

export type SetUserAppsOrderAction = ReturnType<typeof setUserAppsOrder>;

export type UserAction = SetUserAction | SetUserAppsOrderAction;
