import React, { FC } from 'react';
import { Form, InputProps } from 'antd';
import {
  ItemNoMargin,
  ShortInput,
} from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import { NumericFormat } from 'react-number-format';

const { Item } = Form;

type Props = InputProps & {
  name: string;
  handleSave(): void;
};

const calculateValues = (
  discountValue: string,
  formValues: Record<string, any>,
) => {
  const { price, taxValue, amount } = formValues;
  const discount = discountValue ? parseFloat(discountValue) : 0;
  let values: any = {
    discount,
  };
  if (price !== '' && amount) {
    const discountedPrice = Number(
      Number(price - (discount / 100) * price).toFixed(2),
    );
    const totalNetPrice = Number(Number(discountedPrice * amount).toFixed(2));
    const grossPrice = Number(
      Number(
        discountedPrice + discountedPrice * (parseFloat(taxValue) / 100),
      ).toFixed(2),
    );
    const totalGrossPrice = Number(Number(grossPrice * amount).toFixed(2));

    values = {
      ...values,
      grossPrice,
      discountedPrice,
      totalNetPrice,
      totalGrossPrice,
    };
  }
  return values;
};

const Discount: FC<Props> = ({
  name,
  onBlur,
  onPressEnter,
  handleSave,
  disabled,
}) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={name}>
            <NumericFormat
              disabled={disabled}
              allowNegative={false}
              max={100}
              decimalSeparator={','}
              decimalScale={2}
              thousandSeparator={'.'}
              onBlur={onBlur}
              onPressEnter={onPressEnter}
              customInput={ShortInput}
              onChange={(event) => {
                const value = event.target.value;
                const formatedValue = value
                  .replaceAll('.', '')
                  .replaceAll(',', '.');
                const newValues = calculateValues(formatedValue, formValues);
                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });

                if (handleSave) {
                  handleSave();
                }
              }}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default Discount;
