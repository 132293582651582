import {
  Commission,
  COMMISSIONS_SETTINGS_COLLECTION_NAME,
} from '@apps/directSales/services/commisions';
import { patch } from '@services/api';
import { removeUndefinedProperties } from '@services/utils';
import moment from 'moment';

export const updateCommission = (id: string, commission: Commission) =>
  patch(`${COMMISSIONS_SETTINGS_COLLECTION_NAME}/${id}`, {
    ...removeUndefinedProperties(commission),
    timestamp: moment().valueOf(),
  });
