import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card } from '@todix/ui-components';
import { useParams } from 'react-router-dom';
import AppConfig from '@apps/directSales';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import {
  Consultant,
  getConsultant,
} from '@apps/directSales/mainMenu/Consultants/services';
import Tabs from '@components/Tabs';
import {
  DashboardOutlined,
  InfoCircleOutlined,
  NodeIndexOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import Relations from '@components/Relations';
import ConsultantForm from '@apps/directSales/mainMenu/Consultants/components/ConsultantForm';
import { Spin } from 'antd';
import ConsultantContext from './components/formContext';
import ConsultantTeam from '@apps/directSales/mainMenu/Consultants/consultantTeam';
import ConsultantAbout from '@apps/directSales/mainMenu/Consultants/about';

const { TabPane } = Tabs;

type Props = {
  readOnly: boolean;
};
const ConsultantDetails: FC<Props> = ({ readOnly }) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const [consultant, setConsultant] = useState<Consultant | null>(null);
  const [ecosystem, setEcosystem] = useState('');
  const [loading, setLoading] = useState(false);

  const onEcosystemChange = useCallback((ecosystem: string) => {
    setEcosystem(ecosystem);
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getConsultant(id)
        .then((fetchedConsultant) => {
          setConsultant(fetchedConsultant);
          setEcosystem(fetchedConsultant.ecosystem);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${AppConfig.todixId}/consultants`,
        intl.formatMessage({
          id: 'app.directsales.consultants',
          defaultMessage: 'Consultants',
        }),
      ],
      consultant
        ? [
            `/app/${AppConfig.todixId}/${consultant.consultantName}`,
            `${consultant.consultantName || ''} ${
              consultant.consultantSurname || ''
            }`,
          ]
        : [
            `/app/${AppConfig.todixId}/create`,
            intl.formatMessage({
              id: 'createbuttonplatform.create',
              defaultMessage: 'Create',
            }),
          ],
    ];
    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [intl, consultant]);

  return (
    <div>
      <ConsultantContext.Provider
        value={{
          ecosystem: null,
          readOnly,
          setLoading,
          loading,
          consultantId: id,
        }}
      >
        <Spin spinning={loading}>
          <Card>
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane
                key="1"
                tab={
                  <>
                    <DashboardOutlined />{' '}
                    <FormattedMessage
                      id="stacked.basetab"
                      defaultMessage="Base Tab"
                    />
                  </>
                }
              >
                <ConsultantForm
                  id={id}
                  onEcosystemChange={onEcosystemChange}
                  readOnly={readOnly}
                  consultant={consultant}
                />
              </TabPane>
              <TabPane
                key="2"
                disabled={!id || !ecosystem}
                tab={
                  <>
                    <NodeIndexOutlined />{' '}
                    <FormattedMessage
                      id="relations.tab"
                      defaultMessage="Relations"
                    />
                  </>
                }
              >
                <Relations
                  ecosystem={ecosystem}
                  entityId={id}
                  entityType="consultant"
                />
              </TabPane>
              <TabPane
                key="3"
                disabled={!id || !ecosystem}
                tab={
                  <>
                    <TeamOutlined />{' '}
                    <FormattedMessage
                      id="consultant.team"
                      defaultMessage="Team"
                    />
                  </>
                }
              >
                <ConsultantTeam
                  consultantId={id || ''}
                  ecosystemId={ecosystem}
                />
              </TabPane>
              <TabPane
                key="4"
                disabled={!id || !ecosystem}
                tab={
                  <>
                    <InfoCircleOutlined />{' '}
                    <FormattedMessage
                      id="consultant.about"
                      defaultMessage="About"
                    />
                  </>
                }
              >
                <ConsultantAbout
                  consultant={consultant}
                  about={consultant?.about || ''}
                  consultantId={id || ''}
                  ecosystemId={ecosystem}
                />
              </TabPane>
            </Tabs>
          </Card>
        </Spin>
      </ConsultantContext.Provider>
    </div>
  );
};

export default ConsultantDetails;
