import React, { FC } from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PurchaseCreatorSettingsWrapper } from '@apps/purchases/mainMenu/components/styles.sc';

type Props = {
  onCollapseClick(): void;
};

const PurchaseCreatorSettings: FC<Props> = ({ onCollapseClick }) => {
  return (
    <PurchaseCreatorSettingsWrapper>
      <Button
        onClick={onCollapseClick}
        icon={<ArrowLeftOutlined />}
        type="primary"
      ></Button>
    </PurchaseCreatorSettingsWrapper>
  );
};

export default PurchaseCreatorSettings;
