import { database } from 'firebase';
import { rootStore } from '@core/store';

const handleEcosystemChange = (ecosystem: Ecosystem) => {
  rootStore.dispatch({
    type: 'UPDATE_ECOSYSTEM',
    payload: ecosystem,
  });
};

const handleEcosystemDelete = (ecosystemId: string) => {
  rootStore.dispatch({
    type: 'REMOVE_ECOSYSTEM',
    payload: ecosystemId,
  });
};

const ecosystemsListeners = (
  ecosystemsIds: string[],
  db: database.Database,
) => {
  ecosystemsIds.forEach((ecosystemId) => {
    const ecosystemRef = db.ref(`ecosystems/${ecosystemId}`);
    ecosystemRef.off();
    ecosystemRef.on('value', (data) => {
      const value = data.val();
      if (!value) {
        handleEcosystemDelete(ecosystemId);
      } else {
        handleEcosystemChange({
          ...value,
          id: data.key,
        });
      }
    });
  });
};

export default ecosystemsListeners;
