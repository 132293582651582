import React, { FC } from 'react';
import { ColumnType } from 'antd/lib/table';
import { PageViewElementProps } from '@apps/purchases/mainMenu/operationSteps/commonTypes';
import { useIntl } from 'react-intl';
import { BucketPosition } from '@apps/purchases/services/buckets';
import {
  CustomTable,
  PositionsContainer,
} from '@apps/purchases/mainMenu/operationSteps/operationStep/positions/styles.sc';
import EditableRow from '../positions/EditableRow';
import EditableCell from '../positions/EditableCell';

type DeliveredPositionTableColumnType = ColumnType<any> & {
  editable?: boolean;
  dataIndex?: string;
  type?: 'dropdown' | 'number' | 'string' | 'unit' | 'price';
  ecosystem?: string;
  data?: BucketPosition;
};

const DeliveredPositionsTable: FC<PageViewElementProps> = ({
  bucket,
  ecosystem,
}) => {
  const intl = useIntl();

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const customColumns: DeliveredPositionTableColumnType[] = [
    {
      title: intl.formatMessage({
        id: 'sales.createForm.position.label',
        defaultMessage: 'Pos.',
      }),
      render: (_v, _r, index) => {
        const pos = index + 1;
        return <span>{pos}</span>;
      },
    },
    {
      title: intl.formatMessage({
        id: 'products.columns.productname.header',
        defaultMessage: 'Product name',
      }),
      dataIndex: 'productName',
      type: 'string',
      editable: false,
    },
    {
      title: intl.formatMessage({
        id: 'products.form.productnumber.label',
        defaultMessage: 'Product number',
      }),
      dataIndex: 'productNumber',
      type: 'string',
      editable: false,
    },
    {
      title: intl.formatMessage({
        id: 'sales.createForm.ordered.amount.label',
        defaultMessage: 'Ordered amount',
      }),
      dataIndex: 'needAmount',
      editable: false,
      type: 'number',
    },
    {
      title: intl.formatMessage({
        id: 'sales.createForm.delivered.amount.label',
        defaultMessage: 'Delivered amount',
      }),
      dataIndex: 'deliveredAmount',
      editable: false,
      type: 'number',
    },
    {
      title: intl.formatMessage({
        id: 'warehouse.create.table.header.unit',
        defaultMessage: 'Unit',
      }),
      editable: false,
      type: 'unit',
      dataIndex: 'salesUnit',
      ecosystem,
    },
  ];

  const columns = customColumns.map((col) => {
    return {
      ...col,
      onCell: (record: BucketPosition) => ({
        record,
        data: col.data,
        editable: col.editable,
        dataIndex: col.dataIndex,
        type: col.type,
        title: col.title,
        selectedEcosystem: col.ecosystem,
        readOnly: true, // Wszystkie pola są tylko do odczytu
      }),
    };
  });

  return (
    <PositionsContainer>
      <CustomTable
        pagination={false}
        components={components}
        dataSource={bucket.positions || []}
        columns={columns as any}
        rowClassName={() =>
          'editable-row animate__animated animate__slideInUp animate__faster'
        }
        bordered
      />
    </PositionsContainer>
  );
};

export default DeliveredPositionsTable;
