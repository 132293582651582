import { post } from '@services/api';
import {
  PRODUCT_CONTACT_CONSTRAINTS,
  ProductContactConstraint,
} from '@apps/products/services/namespace';
import moment from 'moment/moment';

const createProductContactConstraint = (
  constraint: ProductContactConstraint,
) => {
  return post(PRODUCT_CONTACT_CONSTRAINTS, {
    ...constraint,
    timestamp: moment().valueOf(),
  });
};

export default createProductContactConstraint;
