import React, { useEffect, useState } from 'react';
import { Form, Row, Col, DatePicker } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Text } from '@todix/ui-components';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';
import {
  getEcosystemTaxCategories,
  TaxCategory,
} from '@apps/finance/services/taxCategories';
import { getPaymentMethods, PaymentMethod } from '@services/dictionary';
import SelectWithSearch from '@components/SelectWithSearch';
import moment from 'moment';
import Summary from '@apps/documents/mainMenu/components/FormFields/Payments/Summary';
import { DatePickerProps } from 'antd/lib/date-picker';
import MoneyInputInFormItem from '@components/MoneyField/MoneyInputInFormItem';

const InnerPicker: React.FC<DatePickerProps> = ({ value, ...props }) => {
  let parsedValue = value;
  if (!moment.isMoment(value)) {
    parsedValue = moment(value, moment.defaultFormat);
  }
  return (
    <>
      <DatePicker
        {...props}
        value={parsedValue}
        style={{ width: '100%' }}
        allowClear={false}
      />
    </>
  );
};

const disabledDate = (current: moment.Moment) =>
  current && current > moment().endOf('day');

const Payments: React.FC<FieldProps> = ({
  field,
  children,
  initialValue,
  ...rest
}) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [taxRates, setTaxRates] = useState<TaxCategory[]>([]);
  const { readOnly, getFieldValue } = useFormContext();
  const { fieldName, label, readOnly: fieldReadOnly, wrapperStyle } = field;
  const ecosystemId = getFieldValue('ecosystem');

  useEffect(() => {
    if (ecosystemId) {
      Promise.all([getPaymentMethods(), getEcosystemTaxCategories(ecosystemId)])
        .then((result) => {
          setPaymentMethods(
            (result[0] as PaymentMethod[]).sort((a, b) => {
              const aName = a.value.toLowerCase();
              const bName = b.value.toLowerCase();
              if (aName < bName) {
                return -1;
              }
              if (aName > bName) {
                return 1;
              }
              return 0;
            }),
          );
          setTaxRates(result[1] as TaxCategory[]);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ecosystemId]);

  return (
    <>
      <Form.List name={fieldName}>
        {(fields, { add, remove }) => (
          <div style={wrapperStyle}>
            {fields.length ? (
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      label ? (
                        <Text level={3} strong>
                          <FormattedMessage id={label} defaultMessage={label} />
                        </Text>
                      ) : (
                        label
                      )
                    }
                    colon={false}
                    labelAlign="left"
                    {...rest}
                  />
                </Col>
              </Row>
            ) : null}
            {(fields as any).map(({ key, ...field }: any) => (
              <Form.Item key={key} noStyle>
                <Row
                  style={{ position: 'relative' }}
                  justify="center"
                  gutter={[1, 1]}
                >
                  <Col span={8}>
                    <Form.Item
                      {...field}
                      wrapperCol={{ span: 24 }}
                      name={[field.name, 'paymentMethod']}
                    >
                      <SelectWithSearch
                        disabled={fieldReadOnly ? fieldReadOnly : readOnly}
                        showArrow={!fieldReadOnly || !readOnly}
                        bordered={!fieldReadOnly || !readOnly}
                        options={
                          paymentMethods.length
                            ? paymentMethods.map((method) => ({
                                value: method.value,
                                label: method.value,
                              }))
                            : []
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...field}
                      wrapperCol={{ span: 24 }}
                      name={[field.name, 'payDate']}
                    >
                      <InnerPicker
                        format={moment.defaultFormat}
                        disabledDate={disabledDate}
                        disabled={fieldReadOnly ? fieldReadOnly : readOnly}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      {...field}
                      wrapperCol={{ span: 24 }}
                      name={[field.name, 'payAmount']}
                      required
                    >
                      <MoneyInputInFormItem
                        placeholder="Betrag in &euro;"
                        tabIndex={0}
                        readOnly={readOnly || fieldReadOnly}
                      />
                    </Form.Item>
                  </Col>
                  {!readOnly && !fieldReadOnly && (
                    <MinusCircleOutlined
                      style={{
                        position: 'absolute',
                        right: '0px',
                        top: '-20px',
                      }}
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  )}
                </Row>
              </Form.Item>
            ))}
            {!readOnly && !fieldReadOnly && (
              <Form.Item>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    add({
                      paymentMethod: paymentMethods[0].value,
                      payDate: moment(),
                    });
                  }}
                  block
                >
                  <FormattedMessage
                    id={'documents.processinginvoice.payments.button'}
                    defaultMessage="Add payments"
                  />
                </Button>
              </Form.Item>
            )}
            <Form.Item noStyle shouldUpdate>
              {() => <Summary taxRates={taxRates} />}
            </Form.Item>
          </div>
        )}
      </Form.List>
    </>
  );
};

export default Payments;
