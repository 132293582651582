import React from 'react';
import ProgressComponent from '@components/ProgressComponent';

export type ProgressBarRendererProps = {
  data: {
    progress: number;
  };
};

const ProgressBarRenderer: React.FC<ProgressBarRendererProps> = ({ data }) => {
  return (
    <ProgressComponent
      percent={data.progress}
      width={24}
      trailColor={'#BBBBBB'}
    />
  );
};

export default ProgressBarRenderer;
