import React, {
  FC,
  useCallback,
  MouseEventHandler,
  MouseEvent,
  useEffect,
  useRef,
} from 'react';
import ImageGallery, {
  ReactImageGalleryItem,
  ReactImageGalleryProps,
} from 'react-image-gallery';
import PDFThumbnail from '@components/MediaViewer/PDFThumbnail';
import ResponsivePDFViewer from '@components/MediaViewer/ResponsivePDFViewer';
import { emitCustomEvent } from '@core/services';
import styled from 'styled-components';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

type ButtonSliderType = React.FC<
  ButtonProps & {
    position: string;
  }
>;

export const ButtonSlider = styled<ButtonSliderType>(Button)`
  position: absolute;
  top: 50%;
  ${({ position }) => (position === 'left' ? 'left: 15px' : 'right: 15px')};
  z-index: 1;
`;

type MediaItem = {
  format: 'pdf' | 'jpg' | 'png' | string;
  url: string;
  thumbnailUrl?: string;
};

type MediaViewerProps = Omit<ReactImageGalleryProps, 'items'> & {
  items: MediaItem[];
  width?: string;
  height?: string;
  carouselRef?: any;
  onLeftClick?(cb: any): any;
  onRightClick?(cb: any): any;
  onThumbnailClickCb?(cb: any): any;
  imageRenderItem?: ReactImageGalleryProps['renderItem'];
};

const MediaViewer: FC<MediaViewerProps> = ({
  items,
  width,
  height,
  carouselRef,
  onLeftClick,
  onRightClick,
  onThumbnailClickCb,
  imageRenderItem,
  ...props
}) => {
  const onThumbnailClick = useRef<any>(null);
  const mediaItems: ReadonlyArray<ReactImageGalleryItem> = items.map(
    (item) => ({
      original: item.url,
      thumbnail: item.thumbnailUrl,
      renderThumbInner:
        item.format === 'pdf'
          ? (el) => <PDFThumbnail element={el} />
          : undefined,
      renderItem:
        item.format === 'pdf'
          ? (el) => (
              <ResponsivePDFViewer element={el} width={width} height={height} />
            )
          : imageRenderItem,
    }),
  );

  const handleClick = useCallback(
    (
      type: 'left' | 'right',
      handler: MouseEventHandler<HTMLElement>,
      event: any,
    ) => {
      if (onLeftClick && type === 'left') {
        event.preventDefault();
        onLeftClick(() => handler(event));
        return;
      }
      if (onRightClick && type === 'right') {
        event.preventDefault();
        onRightClick(() => handler(event));
        return;
      }
      handler(event);
    },
    [onLeftClick, onRightClick],
  );

  useEffect(() => {
    if (carouselRef && carouselRef.current && onThumbnailClickCb) {
      onThumbnailClick.current = carouselRef.current.onThumbnailClick;
      carouselRef.current.onThumbnailClick = function (
        event: any,
        index: number,
      ) {
        const { onThumbnailClick } = this.props;
        // blur element to remove outline cause by focus
        event.target.parentNode.parentNode.blur();
        onThumbnailClick(event, index);
      };
    }
    return () => {
      if (onThumbnailClick.current) {
        carouselRef.current.onThumbnailClick = onThumbnailClick.current;
      }
    };
  }, [carouselRef, onThumbnailClickCb]);

  const handleThumbnailClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, index: number) => {
      if (onThumbnailClickCb) {
        event.preventDefault();
        onThumbnailClickCb(() =>
          carouselRef.current.slideToIndex(index, event),
        );
        return;
      }
    },
    [carouselRef, onThumbnailClickCb],
  );

  return (
    <ImageGallery
      {...props}
      ref={carouselRef}
      items={mediaItems}
      showFullscreenButton={mediaItems.length > 0}
      onScreenChange={(fullScreen) => {
        emitCustomEvent('mediaViewerFullScreen', fullScreen);
        if (props.onScreenChange) {
          props.onScreenChange(fullScreen);
        }
      }}
      renderLeftNav={(onClick, disabled) => (
        <ButtonSlider
          type="primary"
          icon={<ArrowLeftOutlined />}
          position="left"
          onClick={(e) => handleClick('left', onClick, e)}
          disabled={disabled}
        />
      )}
      renderRightNav={(onClick, disabled) => (
        <ButtonSlider
          type="primary"
          icon={<ArrowRightOutlined />}
          position="right"
          onClick={(e) => handleClick('right', onClick, e)}
          disabled={disabled}
        />
      )}
      onThumbnailClick={handleThumbnailClick}
      stopPropagation={false}
    />
  );
};

export default React.memo(MediaViewer);
