import { firebase } from '@services/Firebase';
import {
  USER_PREFERENCES,
  UserPreferences,
} from '@services/userPreferences/namespace';

const getUserPreferences = async (userId: string): Promise<UserPreferences> => {
  if (firebase.db) {
    return firebase.db
      .ref(`${USER_PREFERENCES}/${userId}`)
      .once('value')
      .then((d) => (d.val() ? d.val() : {}));
  } else {
    return {};
  }
};

export default getUserPreferences;
