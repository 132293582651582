import autoTable, { PageHook, RowInput } from 'jspdf-autotable';
import { ToolsProps } from '@apps/purchases/mainMenu/components/pdfTemplates/namespace';
import { BucketPosition } from '@apps/purchases/services/buckets';
import withSuffix from '@core/helpers/withSuffix';
import moment from 'moment';

type TitleEntry = {
  header: string;
  dataKey: string;
};

type DataEntry = {
  pos: string;
  productName: string;
  productNumber: string;
  needAmount: string;
  unit: string;
  unitAmount: string;
  description: string;
  expectedDeliveryDate: string;
};

type PositionsTotalSummary = {
  total: number;
  net: number;
  taxes: Record<any, number>;
};

const calculateTotalForPositions = (
  positions: BucketPosition[],
): PositionsTotalSummary => {
  const net = positions.reduce(
    (sum, position) =>
      (sum += position.needAmount * Number(position.unitAmount)),
    0,
  );

  const taxes = positions.reduce((acc: Record<any, number>, item) => {
    if (
      item.tax !== undefined &&
      item.unitAmount !== undefined &&
      item.needAmount !== undefined
    ) {
      const prodsInTax = acc[item.tax];
      const net = item.needAmount * Number(item.unitAmount);
      const totalTaxValue = net * (item.tax / 100);
      return {
        ...acc,
        [item.tax]:
          prodsInTax !== undefined ? prodsInTax + totalTaxValue : totalTaxValue,
      };
    }
    return acc;
  }, {});

  const total = net + Object.values(taxes).reduce((sum, el) => (sum += el), 0);

  return {
    net,
    taxes,
    total,
  };
};

const createData = (positions: BucketPosition[]): DataEntry[] => {
  return positions.map((entry, index) => {
    const pos = index + 1;
    return {
      pos: `${pos}`,
      productName: `${entry.productName || ''}`,
      productNumber: entry.productNumber || '',
      needAmount: `${entry.needAmount}`,
      unitAmount: withSuffix({
        value: entry.unitAmount,
      }),
      tax: `${entry?.tax || '0,00'}`,
      unit: `${entry?.unit || ' '}`,
      description: entry.description || '',
      expectedDeliveryDate:
        moment(entry.expectedDeliveryDate).format(moment.defaultFormat) || '',
    };
  });
};

const addPositions = (payload: ToolsProps, didDrawPage: PageHook) => {
  const { props, lastYPosition, doc } = payload;
  const {
    values: { positions },
    intl,
  } = props;
  const titles: TitleEntry[] = [
    {
      header: intl.formatMessage({
        id: 'sales.createForm.position.label',
        defaultMessage: 'Pos.',
      }),
      dataKey: 'pos',
    },
    {
      header: intl.formatMessage({
        id: 'sales.createNew.item',
        defaultMessage: 'Product name',
      }),
      dataKey: 'productName',
    },
    {
      header: intl.formatMessage({
        id: 'products.form.productnumber.label',
        defaultMessage: 'Product number',
      }),
      dataKey: 'productNumber',
    },
    {
      header: intl.formatMessage({
        id: 'sales.createForm.amount.label',
        defaultMessage: 'Amount',
      }),
      dataKey: 'needAmount',
    },
    {
      header: intl.formatMessage({
        id: 'products.selectedunit.label',
        defaultMessage: 'Unit',
      }),
      dataKey: 'unit',
    },
    {
      header: intl.formatMessage({
        id: 'sales.createForm.tax.label',
        defaultMessage: 'Tax (%)',
      }),
      dataKey: 'tax',
    },
    {
      header: intl.formatMessage({
        id: 'price',
        defaultMessage: 'Price',
      }),
      dataKey: 'unitAmount',
    },
    {
      header: intl.formatMessage({
        id: 'sales.createNew.description.label',
        defaultMessage: 'Description',
      }),
      dataKey: 'description',
    },
    {
      header: intl.formatMessage({
        id: 'expected.delivery.date',
        defaultMessage: 'Expected delivery date',
      }),
      dataKey: 'expectedDeliveryDate',
    },
  ];
  const data = createData(positions);
  const { total, net, taxes } = calculateTotalForPositions(positions);
  const taxesRow = Object.keys(taxes)
    .sort()
    .map((taxKey) => {
      return [
        {
          rowSpan: 1,
          colSpan: 8,
          content: `${intl.formatMessage({
            id: 'sales.salesTab.pricePreview.tax',
            defaultMessage: 'Tax',
          })} (${taxKey} %)`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          content: withSuffix({
            value: taxes[taxKey] !== undefined ? taxes[taxKey] : '',
          }),
        },
      ] as RowInput;
    });

  autoTable(doc, {
    headStyles: {
      fillColor: '#8c8c8c',
      textColor: '#000',
    },
    footStyles: {
      fillColor: '#FFF',
      textColor: '#000',
    },
    styles: {
      font: 'time',
      fontSize: 8,
    },
    margin: { top: lastYPosition, left: 20 },
    columns: titles,
    body: data as RowInput[],
    didDrawPage,
    foot: [
      [
        {
          rowSpan: 1,
          colSpan: 8,
          content: intl.formatMessage({
            id: 'sales.createForm.sumNet.label',
            defaultMessage: 'Sum net',
          }),
        },
        {
          rowSpan: 1,
          colSpan: 1,
          content: withSuffix({
            value: net !== undefined ? net : '',
          }),
        },
      ],
      ...taxesRow,
      [
        {
          rowSpan: 1,
          colSpan: 8,
          content: intl.formatMessage({
            id: 'sales.salesTab.pricePreview.total',
            defaultMessage: 'Total',
          }),
          styles: {
            fillColor: '#8c8c8c',
          },
        },
        {
          rowSpan: 1,
          colSpan: 1,
          content: withSuffix({
            value: total !== undefined ? total : '',
          }),
          styles: {
            fillColor: '#8c8c8c',
          },
        },
      ],
    ],
  });
};

export default addPositions;
