import React, { FC } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { useSelector } from 'react-redux';
import { getUnitGroupsForEcosystemSelector } from '@core/layout/store/unitGroupsSaga/selectors';
import { FormattedMessage } from 'react-intl';

const { Option, OptGroup } = Select;

type UnitSelectorProps = SelectProps & {
  selectedEcosystem: string | null;
  onUnitSelect(unit: string): void;
  dataIndex: string;
};

const UnitSelector: FC<UnitSelectorProps> = ({
  selectedEcosystem,
  onUnitSelect,
}) => {
  const unitGroups = useSelector(
    getUnitGroupsForEcosystemSelector(selectedEcosystem || ''),
  );

  return (
    <Select
      disabled={!selectedEcosystem}
      onSelect={(value) => onUnitSelect(value as string)}
      placeholder={
        <FormattedMessage
          id="products.selectedunit.placeholder"
          defaultMessage="Select unit..."
        />
      }
    >
      {unitGroups.groups.map((unitGroup, index) => (
        <OptGroup key={index} label={unitGroup.name}>
          {unitGroup.units
            .filter((unit) => unit.isActive)
            .map(({ name }) => (
              <Option value={name} key={name} label={name}>
                {name}
              </Option>
            ))}
        </OptGroup>
      ))}
    </Select>
  );
};

export default UnitSelector;
