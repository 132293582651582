import { lazy } from 'react';
import { ShopOutlined } from '@ant-design/icons';

const DirectSalesClientSettings = lazy(
  () => import(/* webpackChunkName: "directSalesClientApp" */ './Settings'),
);

export default {
  appSubmenuName: 'app.directsalesclient',
  icon: ShopOutlined,
  sections: [
    {
      link: 'directsalesclient_settings',
      title: 'app.directsalesclient.settings',
      component: DirectSalesClientSettings,
    },
  ],
};
