import React, { FC, useMemo, useRef } from 'react';
import { Table } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColumnType } from 'antd/lib/table';
import { WarehouseHistoryEntry } from '@apps/warehouse/services/namespace';
import moment from 'moment';
import UserEntry, {
  UserEntryType,
} from '@apps/warehouse/mainMenu/components/UserEntry';
import { StyledMoneyInput } from '@components/List/FrameworkComponents/MoneyValueRenderer';
import FloatNumberedField from '@components/FloatNumberedField';

type HistoryTableProps = {
  data: WarehouseHistoryEntry[];
};

const HistoryTable: FC<HistoryTableProps> = ({ data }) => {
  const intl = useIntl();
  const users = useRef<Record<string, UserEntryType>>({});
  const columns = useMemo(() => {
    return [
      {
        title: intl.formatMessage({
          id: 'warehouse.history.table.entry.date.header',
          defaultMessage: 'Entry date',
        }),
        dataIndex: 'timestamp',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.timestamp - b.timestamp,
        render: (timestamp) =>
          moment.unix(timestamp).format(moment.defaultFormat),
      },
      {
        title: intl.formatMessage({
          id: 'warehouse.history.table.operation.header',
          defaultMessage: 'Operation',
        }),
        dataIndex: 'flowType',
        render: (flowType) => (
          <FormattedMessage
            id={`warehouse.create.flow.${flowType}`}
            defaultMessage={flowType}
          />
        ),
      },
      {
        title: intl.formatMessage({
          id: 'warehouse.create.table.header.productname',
          defaultMessage: 'Product name',
        }),
        dataIndex: 'productName',
      },
      {
        title: intl.formatMessage({
          id: 'warehouse.create.table.header.amount',
          defaultMessage: 'Amount',
        }),
        dataIndex: 'amount',
        render: (value) => {
          return (
            <FloatNumberedField
              value={value}
              allowNegative={false}
              decimalSeparator={','}
              decimalScale={2}
              thousandSeparator={'.'}
              readOnly
            />
          );
        },
      },
      {
        title: intl.formatMessage({
          id: 'warehouse.history.table.value.header',
          defaultMessage: 'Value',
        }),
        dataIndex: 'value',
        render: (value) => {
          return <StyledMoneyInput value={value} currency={'EUR'} readOnly />;
        },
      },
      {
        title: intl.formatMessage({
          id: 'warehouse.create.supplier',
          defaultMessage: 'Supplier',
        }),
        dataIndex: 'supplier',
        render: (value) => {
          return (
            <UserEntry
              id={value}
              userEntry={users.current[value]}
              type="contact"
              onFetch={(userEntry) => {
                users.current[value] = userEntry;
              }}
            />
          );
        },
      },
      {
        title: intl.formatMessage({
          id: 'warehouse.create.user',
          defaultMessage: 'User',
        }),
        dataIndex: 'user',
        render: (value) => {
          return (
            <UserEntry
              id={value}
              userEntry={users.current[value]}
              type="user"
              onFetch={(userEntry) => {
                users.current[value] = userEntry;
              }}
            />
          );
        },
      },
    ] as ColumnType<any>[];
  }, [intl]);
  return (
    <Table
      style={{ marginTop: '20px' }}
      title={() => (
        <FormattedMessage
          id="warehouse.history.table.title"
          defaultMessage="History table"
        />
      )}
      dataSource={data}
      columns={columns}
      bordered
      pagination={{
        hideOnSinglePage: true,
        pageSize: 10,
      }}
      size="small"
    />
  );
};

export default HistoryTable;
