import React, { FC } from 'react';
import { List, Skeleton, Avatar, Space, Button, Tag } from 'antd';
import {
  deleteProductContactConstraint,
  ProductContactConstraint,
} from '@apps/products/services';
import { FormattedMessage } from 'react-intl';
import intl from '../../../../../intl';
import { SupplierDesc } from '@apps/purchases/mainMenu/purchasesTabForProduct/styles.sc';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { emitCustomEvent } from '@core/services';
import { RefreshConstraintEvent } from '@apps/products/mainMenu/components/DetailedProduct/AddProductContactConstraint';
import getValueWithCurrency from '@services/currency/getValueWithCurrency';

type SupplierProps = {
  constraints: ProductContactConstraint[];
};

const Suppliers: FC<SupplierProps> = ({ constraints }) => {
  return (
    <List
      itemLayout="horizontal"
      header={<FormattedMessage id="suppliers" defaultMessage="Suppliers" />}
      dataSource={constraints}
      renderItem={(item: ProductContactConstraint) => {
        const removeItem = () => {
          if (item.id) {
            deleteProductContactConstraint(item.id);
            emitCustomEvent<'refreshConstraint', RefreshConstraintEvent>(
              'refreshConstraint',
              {
                type: 'supplier',
              },
            );
          }
        };

        const editSupplier = () => {
          if (item.id) {
            emitCustomEvent<'editSupplier', ProductContactConstraint>(
              'editSupplier',
              item,
            );
          }
        };

        const title =
          item.relatedContactSnapshot.type === 'person'
            ? `${item.relatedContactSnapshot.firstName || ''} ${
                item.relatedContactSnapshot.lastName || ''
              }`
            : item.relatedContactSnapshot.companyName;

        const priceWithSuffix = `${getValueWithCurrency(
          item.unitAmount,
          item.currency,
        ).format()} ${item.currency ? item.currency.toUpperCase() : 'EUR'}`;

        const taxValue = item?.taxValue;

        const description = (
          <SupplierDesc>
            <Space direction="horizontal">
              <div>
                {intl.formatMessage({
                  id: 'sales.salesTab.unitAmount.label',
                  defaultMessage: 'Price',
                })}
                :
              </div>
              <div>{priceWithSuffix}</div>
            </Space>
            {taxValue && (
              <Space direction="horizontal">
                <div>
                  {intl.formatMessage({
                    id: 'product.salesTab.selectTag.taxField.label',
                    defaultMessage: 'Tax',
                  })}
                  :
                </div>
                <div>{taxValue}%</div>
              </Space>
            )}
            <Space direction="horizontal">
              <div>
                {intl.formatMessage({
                  id: 'standard.delivery.time.days',
                  defaultMessage: 'Standard delivery time (days)',
                })}
                :
              </div>
              <div>{item.standardDeliveryTime || ''}</div>
            </Space>
          </SupplierDesc>
        );
        return (
          <List.Item
            actions={[
              <Button icon={<EditOutlined />} onClick={editSupplier} />,
              <Button icon={<DeleteOutlined />} onClick={removeItem} danger />,
            ]}
          >
            <Skeleton avatar loading={false} title={false} active>
              <List.Item.Meta
                avatar={
                  item.relatedContactSnapshot?.avatar ? (
                    <Avatar src={item.relatedContactSnapshot?.avatar} />
                  ) : undefined
                }
                title={
                  <Space direction="horizontal" size="large">
                    <i>{title}</i>
                    {item.isDefault && (
                      <Tag color="green">
                        {intl.formatMessage({
                          id: 'default.supplier',
                          defaultMessage: 'Default supplier',
                        })}
                      </Tag>
                    )}
                  </Space>
                }
                description={description}
              />
            </Skeleton>
          </List.Item>
        );
      }}
    />
  );
};

export default Suppliers;
