import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Space } from 'antd';
import { getUnitGroupsForEcosystemSelector } from '@core/layout/store/unitGroupsSaga/selectors';
import { Italic } from '@apps/products/widgets/styles.sc';
import { Product } from '@apps/products/services';

type SelectedUnitProps = {
  ecosystemId: string;
  item: Product;
};

const SelectedUnitInCard: FC<SelectedUnitProps> = ({ ecosystemId, item }) => {
  const unitGroups = useSelector(
    getUnitGroupsForEcosystemSelector(ecosystemId),
  );

  if (!unitGroups || !unitGroups.groups) {
    return null;
  }

  return (
    <Space direction="horizontal">
      <div>
        <FormattedMessage
          id="products.selectedunit.label"
          defaultMessage="Unit"
        />
        :
      </div>
      <Italic>
        {item.unit ? (
          <FormattedMessage
            id={`products.unit.${item.unit}`}
            defaultMessage={item.unit}
          />
        ) : (
          ''
        )}
      </Italic>
    </Space>
  );
};

export default SelectedUnitInCard;
