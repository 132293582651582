import React, { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Badge, Button } from 'antd';
import { CartWrapper } from '@components/Cart/Cart.sc';
import { getCart } from '@core/store/cart';
import CartModal from '@components/Cart/CartModal';

const Cart: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const cart = useSelector(getCart).data;

  const hideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const itemsInBadge = useMemo(() => {
    if (cart.length < 1) {
      return 0;
    }
    return cart
      .map((entry) => entry.quantity)
      .reduce((acc, item) => {
        return acc + item;
      }, 0);
  }, [cart]);

  if (itemsInBadge < 1) {
    return null;
  }

  return (
    <CartWrapper>
      <Button
        shape="circle"
        type="link"
        icon={
          <Badge count={itemsInBadge} size="small">
            <ShoppingCartOutlined style={{ fontSize: 18 }} />
          </Badge>
        }
        size="large"
        onClick={() => setShowModal(true)}
      />
      <CartModal
        cart={cart}
        visible={showModal}
        onCancel={hideModal}
        onOk={hideModal}
        width="100vw"
      />
    </CartWrapper>
  );
};

export default Cart;
