import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import { ColumnType } from 'antd/lib/table';
import { Card, message, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  StyledEditIcon,
  StyledTable,
  StyledButton,
} from '@pages/settings/components';
import { getCommissions } from '@apps/directSales/services/commisions/getCommissions';
import { Commission } from '@apps/directSales/services/commisions/namespace';
import CommissionModal from '@apps/directSales/settings/Commissions/CommissionModal';
import ExpandableRow from '@apps/directSales/settings/Commissions/ExpandableRow';
import RelatedLevelRenderer from '@apps/directSales/settings/Commissions/components/RelatedLevelRenderer';
import ReportTriggerControl from '@apps/directSales/settings/Commissions/ReportTriggerControl';
import { CommissionsExtra } from '@apps/directSales/styles.sc';
import generatePeriodReport from '@apps/directSales/services/commisions/generatePeriodReport';
import calculateSaleCommissions from '@apps/directSales/services/commisions/calculateSaleCommissions';
import { generateCommissionsExport } from '@apps/directSales/services/commisions/generateCommissionsExport';
import { saveAs } from 'file-saver';
import sendReports from '@apps/directSales/services/commisions/sendReports';
import downloadReports from '@apps/directSales/services/commisions/downloadReports';
import intl from '../../../../../intl';

const Commissions: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commissions, setCommissions] = useState<Commission[]>([]);
  const [selectedCommission, setSelectedCommission] =
    useState<Commission | null>(null);
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getCommissions(uid)
        ?.then(setCommissions)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const onOk = useCallback(() => {
    setVisible(false);
    if (uid) {
      setLoading(true);
      getCommissions(uid)
        ?.then(setCommissions)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const handleGenerateReportClick = useCallback(
    (period: string) => {
      setLoading(true);
      generatePeriodReport({
        period,
        ecosystemId: uid,
      })?.finally(() => {
        setLoading(false);
      });
    },
    [uid],
  );

  const handleEmailSend = useCallback(
    (period: string) => {
      setLoading(true);
      //TODO: from and locale should be taken from ecosystem settings
      sendReports({
        period,
        ecosystemId: uid,
        locale: 'de',
        from: 'app@pepperparties.de',
      })?.finally(() => {
        setLoading(false);
      });
    },
    [uid],
  );

  const handleCalculateSaleCommissionClick = useCallback(
    (period: string) => {
      setLoading(true);
      calculateSaleCommissions({
        period,
        ecosystemId: uid,
      })?.finally(() => {
        setLoading(false);
      });
    },
    [uid],
  );

  const handleExportReportClick = useCallback(
    async (period: string) => {
      setLoading(true);
      const csv = await generateCommissionsExport({ ecosystemId: uid, period });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      setLoading(false);
      saveAs(blob, `commissions_${period}.csv`);
    },
    [uid],
  );

  const handleReportsDownload = useCallback(
    (period: string) => {
      setLoading(true);
      downloadReports({
        period,
        ecosystemId: uid,
      })
        ?.then((result) => {
          if (result === '') {
            message.info(
              intl.formatMessage({
                id: 'commissions.download.reports.notfound',
                defaultMessage: 'Reports bundle not found',
              }),
            );
          }
          if (result === null) {
            message.error(
              intl.formatMessage({
                id: 'commissions.download.reports.error',
                defaultMessage: 'Error while download reports',
              }),
            );
          }
          if (result) {
            const blobUrl = window.URL.createObjectURL(result as Blob);
            const link = document.createElement('a');
            link.download = `reports_${uid}_${period}.zip`;
            link.href = blobUrl;
            document.body.appendChild(link);
            link.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
              }),
            );
            document.body.removeChild(link);
          }
        })
        ?.catch((e) => {
          console.error(e);
          message.error(
            intl.formatMessage({
              id: 'commissions.download.reports.error',
              defaultMessage: 'Error while download reports',
            }),
          );
        })
        ?.finally(() => {
          setLoading(false);
        });
    },
    [uid],
  );

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="commissions.rule.name"
            defaultMessage="Rule name"
          />
        ),
        sorter: true,
        dataIndex: 'name',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="commissions.related.level"
            defaultMessage="Related level"
          />
        ),
        sorter: true,
        dataIndex: 'relatedLevel',
        ellipsis: true,
        render: (value) => {
          return <RelatedLevelRenderer value={value} />;
        },
      },
      {
        title: (
          <FormattedMessage id="commissions.sales" defaultMessage="Sales" />
        ),
        sorter: true,
        dataIndex: 'sales',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="commissions.team.size"
            defaultMessage="Team size"
          />
        ),
        sorter: true,
        dataIndex: 'teamSize',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="commissions.team.sales"
            defaultMessage="Team sales"
          />
        ),
        sorter: true,
        dataIndex: 'teamSales',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="commissions.member.bonus"
            defaultMessage="Member bonus"
          />
        ),
        sorter: true,
        dataIndex: 'memberBonus',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="commissions.yearlyJoker"
            defaultMessage="Yearly joker"
          />
        ),
        sorter: true,
        dataIndex: 'yearlyJoker',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: Commission) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedCommission(record);
                setVisible(true);
              }}
            />
          </>
        ),
        fixed: 'right',
      },
    ],
    [],
  );
  return (
    <Spin spinning={loading}>
      <Card
        title={
          <FormattedMessage
            id="consultants.commissions"
            defaultMessage="Commissions"
          />
        }
        extra={
          <CommissionsExtra>
            <ReportTriggerControl
              handleCalculateSaleCommissionClick={
                handleCalculateSaleCommissionClick
              }
              handleGenerateReportClick={handleGenerateReportClick}
              handleExportReportClick={handleExportReportClick}
              handleEmailsSend={handleEmailSend}
              handleReportsDownload={handleReportsDownload}
            />
            <StyledButton
              type="primary"
              size="small"
              onClick={() => setVisible(true)}
            >
              <FormattedMessage
                id="consultants.settings.commissions.createNewButton"
                defaultMessage="Create new commission rule"
              />
            </StyledButton>
          </CommissionsExtra>
        }
      >
        <StyledTable
          dataSource={commissions}
          columns={columns}
          bordered
          tableLayout="auto"
          pagination={false}
          rowKey="id"
          expandable={{
            expandedRowRender: (record: any) => (
              <ExpandableRow record={record} />
            ),
          }}
        />
        <CommissionModal
          commission={selectedCommission}
          visible={visible}
          ecosystemId={uid}
          onCancel={onCancel}
          onOk={onOk}
        />
      </Card>
    </Spin>
  );
};

export default Commissions;
