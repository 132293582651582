import moment from 'moment';

const momentToFormatString = (values: Record<string, any>, format?: string) => {
  const entries = Object.entries(values);
  const converted = entries.map(([key, value]) =>
    moment.isMoment(value)
      ? [key, (value as moment.Moment).format(format)]
      : [key, value],
  );
  return Object.fromEntries(converted);
};

/* Check if string is valid date */
function checkIfValidDate(str: string) {
  // Regular expression to check if string is valid date
  const regexExp =
    /^(0?[1-9]|[12][0-9]|3[01])[\.\-](0?[1-9]|[12][0-9]|3[01])[\.\-]\d{4}$/;

  return regexExp.test(str);
}

export const mapObjectsWithDateStringsToMoments = (
  values: Record<string, any>,
) => {
  const entries = Object.entries(values);
  const converted = entries.map(([key, value]) =>
    checkIfValidDate(value)
      ? [key, moment(value, moment.defaultFormat)]
      : [key, value],
  );
  return Object.fromEntries(converted);
};

export default momentToFormatString;
