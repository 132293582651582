import React, { FC } from 'react';
import { Card } from 'antd';
import TaxCategories from '@apps/finance/settings/taxCategories/TaxCategories';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import isSkrAlreadySet from '@apps/finance/settings/isSkrAlreadySet';
import SKRSetup from '@apps/finance/settings/taxCategories/SKRSetup';

const TaxCategoriesSettings: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const ecosystem = useSelector(getEcosystemById(uid)) as Ecosystem;
  const skrNotSet = isSkrAlreadySet(ecosystem);
  return <Card>{skrNotSet ? <SKRSetup /> : <TaxCategories />}</Card>;
};

export default TaxCategoriesSettings;
