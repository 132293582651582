import { styled } from '@styles/themes';
import { Tabs as AntdTabs } from 'antd';

const Tabs = styled(AntdTabs)`
  margin: -12px 0;
  .ant-tabs-nav {
    .anticon {
      margin-right: 7px;
    }
  }

  & {
    .ant-tabs-content-holder {
      height: calc(100vh - 180px);
      overflow: auto;
    }
  }
`;

export default Tabs;
