import { StripeProduct } from '@services/stripe/namespace';

export enum StripeProductsActionTypes {
  SET_STRIPE_PRODUCTS = 'SET_STRIPE_PRODUCTS',
}

export const setStripeProducts = (payload: StripeProduct[]) => ({
  type: StripeProductsActionTypes.SET_STRIPE_PRODUCTS,
  payload,
});

export type SetStripeProducts = ReturnType<typeof setStripeProducts>;

export type StripeProductsActions = SetStripeProducts;
