import React, {
  FC,
  useMemo,
  useEffect,
  useState,
  useCallback,
  createContext,
} from 'react';
import { ColumnType } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl/lib';
import { Avatar, Button, message, Space, Switch, Modal } from 'antd';
import StyledTable from '@pages/settings/components/StyledTable';
import getLicencesForMembers from '@services/licences/getLicencesForMembers';
import OwnerActionMenu from '@pages/settings/team/OwnerActionMenu';
import {
  changeRelation,
  getEcosystemRelationsEcosystem,
} from '@services/ecosystems';
import updateLicence from '@services/licences/updateLicence';
import { useIntl } from 'react-intl';
import setSeatsForApp from '@services/licences/setSeatsForApp';
import getLicencePoolConfig from '@pages/settings/team/getLicencePoolConfig';
import ActiveTag from '@pages/settings/team/ActiveTag';
import InActiveTag from '@pages/settings/team/InActiveTag';
import BlockedTag from './BlockedTag';
import {
  StripeProduct,
  SubscriptionWithEcosystem,
} from '@services/stripe/namespace';
import stripe from '@services/stripe';
import { useCustomEventListener } from '@core/services';
import createLicenceForMember from '@services/licences/createLicenceForMember';
import { useHistory } from 'react-router-dom';
import calculateSeatsForEcosystem from '@services/licences/calculateSeatsForEcosystem';
import getSubscriptionBy from '@services/subscriptions/getSubscriptionBy';

type MembersListProps = {
  members: EcosystemMember[];
  loading: boolean;
  handleDelete(memberUid: string): void;
  ecosystem?: CompanyEcosystem;
  user?: User | null;
};

type SeatsPoolContextType = {
  seats: Seat[];
  member: MemberWithLicences | null;
  appId: string;
  selectedSub: Subscription | null;
  setSelectedSub?(sub: Subscription): void;
};

export const SeatsPoolContext = createContext<SeatsPoolContextType>({
  seats: [],
  member: null,
  appId: '',
  selectedSub: null,
});

const MembersList: FC<MembersListProps> = ({
  members,
  loading,
  handleDelete,
  ecosystem,
  user,
}) => {
  const history = useHistory();
  const [modal, contextHolder] = Modal.useModal();
  const intl = useIntl();
  const [membersWithLicences, setMembersWithLicences] = useState<
    MemberWithLicences[]
  >([]);
  const [relations, setRelations] = useState<EcosystemRelation[]>([]);
  const [licences, setLicences] = useState<Licence[]>([]);
  const [seats, setSeats] = useState<Seat[]>([]);
  const [processing, setProcessing] = useState(loading);
  const [selectedMember, setSelectedMember] =
    useState<MemberWithLicences | null>(null);
  const [stripeProduct, setStripeProduct] = useState<StripeProduct | null>(
    null,
  );
  const [selectedSub, setSelectedSub] = useState<Subscription | null>(null);
  const [apps, setApps] = useState<StripeProduct[]>([]);
  const [ecoSub, setEcoSub] = useState<SubscriptionWithEcosystem | null>(null);

  useEffect(() => {
    if (ecosystem?.id) {
      getSubscriptionBy({
        field: 'ecosystem',
        value: ecosystem.id,
      })?.then((res) => {
        setEcoSub(res);
      });
    }
  }, [ecosystem?.id]);

  useEffect(() => {
    stripe.getProducts().then((result) => setApps(result.data));
  }, []);

  const handleBlock = useCallback(
    async ({ memberId, isBlocked }) => {
      const relationToChange = relations.find(
        (relation) => relation.memberId === memberId,
      );
      if (relationToChange) {
        setProcessing(true);
        const changedRelation = {
          ...relationToChange,
          disabled: isBlocked,
        };
        await changeRelation(changedRelation, relationToChange.id as string)
          .then(() => {
            setRelations(
              relations.map((relation) => {
                if (relation.id === relationToChange.id) {
                  return changedRelation;
                }
                return relation;
              }),
            );
          })
          .finally(() => {
            setProcessing(false);
          });
      }
    },
    [relations],
  );

  const roles: Record<EcosystemMember['role'], JSX.Element> = useMemo(
    () => ({
      owner: (
        <FormattedMessage
          id="settings.memberslist.role.owner"
          defaultMessage="Owner"
        />
      ),
      member: (
        <FormattedMessage
          id="settings.memberslist.role.teammember"
          defaultMessage="Team Member"
        />
      ),
    }),
    [],
  );

  useEffect(() => {
    if (user && ecosystem) {
      setProcessing(true);
      const seatsPromise = calculateSeatsForEcosystem({
        ecosystemId: ecosystem.id,
        userId: user.uid,
      })?.then((results) => {
        setSeats(results);
      });
      const licencesPromise = getLicencesForMembers({
        ecosystemId: ecosystem.id,
        membersIds: [user.uid],
      }).then((results) => {
        if (results && results[0]) {
          setLicences(results[0]);
        }
      });
      Promise.all([seatsPromise, licencesPromise]).finally(() => {
        setProcessing(false);
      });
    }
  }, [user, ecosystem]);

  useEffect(() => {
    if (ecosystem) {
      setProcessing(true);
      getLicencesForMembers({
        ecosystemId: ecosystem.id,
        membersIds: members.map((member) => member.id),
      })
        .then((results) => {
          if (results) {
            let licences = {} as Record<string, Licence[]>;
            results.forEach((result) => {
              if (result) {
                const data = result.reduce((acc, item) => {
                  return {
                    ...acc,
                    [item.ownerId]: acc[item.ownerId]
                      ? [...acc[item.ownerId], item]
                      : [item],
                  };
                }, {} as Record<string, Licence[]>);
                licences = {
                  ...licences,
                  ...data,
                };
              }
            });
            const extendedMembers = members.map((member) => {
              return {
                ...member,
                licences: licences[member.id] || [],
              };
            });
            setMembersWithLicences(extendedMembers);
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [ecosystem, members]);

  useEffect(() => {
    if (ecosystem) {
      setProcessing(true);
      getEcosystemRelationsEcosystem(ecosystem.id)
        ?.then((results) => {
          if (results) {
            setRelations(results);
          }
        })
        .finally(() => {
          setProcessing(false);
        });
    }
  }, [ecosystem]);

  const handleLicenceChange = useCallback(
    (licence: Licence, status: boolean) => {
      const seat = seats.find(
        (seat) =>
          seat.appId === licence.appId && seat.subId === licence.subscription,
      );
      const freeSeats = seat?.seats || 0;

      if (status && !freeSeats) {
        message.error(
          intl.formatMessage({
            id: 'no.more.licence',
            defaultMessage:
              'You have no more free licence. Please, deactivate one or buy more',
          }),
        );
        return;
      }
      setProcessing(true);
      const newLicence: Licence = {
        ...licence,
        status: status ? 'active' : 'inactive',
      };
      updateLicence({
        licence: newLicence,
        id: licence.id as string,
      })
        ?.then(() => {
          const newSeats = status ? freeSeats - 1 : freeSeats + 1;
          setSeatsForApp({
            appId: newLicence.appId,
            ecosystemId: newLicence.ecosystem,
            seats: newSeats,
            subId: newLicence.subscription,
          });
          if (!seat) {
            setSeats([
              ...seats,
              {
                appId: newLicence.appId,
                subId: newLicence.subscription,
                ecosystem: newLicence.ecosystem,
                seats: newSeats,
              },
            ]);
          } else {
            setSeats(
              seats.map((seat) => {
                if (
                  seat.appId === newLicence.appId &&
                  seat.subId === newLicence.subscription
                ) {
                  return {
                    ...seat,
                    seats: newSeats,
                  };
                }
                return seat;
              }),
            );
          }
          const newMembers = membersWithLicences.map((member) => {
            const foundLicence = member.licences.find(
              (entry) => entry.id === licence.id,
            );

            if (!foundLicence) {
              return member;
            }
            return {
              ...member,
              licences: member.licences.map((entry) =>
                entry.id === foundLicence.id ? newLicence : entry,
              ),
            };
          });
          setMembersWithLicences(newMembers);
          setLicences(
            licences.map((lic) => (lic.id === licence.id ? newLicence : lic)),
          );
        })
        .finally(() => {
          setProcessing(false);
        });
    },
    [intl, licences, membersWithLicences, seats],
  );

  useCustomEventListener('saveLicence', () => {
    if (selectedSub && stripeProduct && selectedMember?.id && ecosystem) {
      const seat = seats.find(
        (seat) =>
          seat.appId === stripeProduct.id && seat.subId === selectedSub.id,
      );

      if (seat) {
        setProcessing(true);
        createLicenceForMember({
          memberId: selectedMember.id,
          product: stripeProduct,
          ecosystemId: ecosystem?.id,
          subscription: selectedSub.id,
        })
          ?.then((newLicence) => {
            if (newLicence) {
              setSeatsForApp({
                appId: newLicence.appId,
                ecosystemId: newLicence.ecosystem,
                seats: seat.seats - 1,
                subId: newLicence.subscription,
              })?.then(() => {
                setSeats(
                  seats.map((s) => {
                    if (
                      s.subId === selectedSub.id &&
                      s.appId === stripeProduct.id
                    ) {
                      return {
                        ...s,
                        seats: seat.seats - 1,
                      };
                    } else {
                      return s;
                    }
                  }),
                );
                setSelectedSub(null);
                setSelectedMember(null);
                setStripeProduct(null);
                setLicences([...licences, newLicence]);
                const newMembers = membersWithLicences.map((member) => {
                  if (member.id !== newLicence.ownerId) {
                    return member;
                  }

                  return {
                    ...member,
                    licences: [...member.licences, newLicence],
                  };
                });
                setMembersWithLicences(newMembers);
              });
            }
          })
          ?.finally(() => {
            setProcessing(false);
          });
      }
    }
  });

  useCustomEventListener('cancelSaveLicence', () => {
    setSelectedSub(null);
    setSelectedMember(null);
    setStripeProduct(null);
  });

  const handleAddLicenceSelect = useCallback(
    async ({ member, appId, seatsForApp }) => {
      setProcessing(true);
      const product = await stripe.getProduct(appId);
      setProcessing(false);
      if (product) {
        setStripeProduct(product);
        setSelectedMember(member);
        const config = getLicencePoolConfig({
          intl,
          product,
          seatsForApp,
        });
        modal.confirm(config);
      }
    },
    [intl, modal],
  );

  const redirectToStore = useCallback(
    (appId: string) => {
      if (ecosystem) {
        history.push(
          `/settings/${ecosystem.id}/applications/store/details/${appId}`,
        );
      }
    },
    [ecosystem, history],
  );

  const appColumns: ColumnType<any>[] = useMemo(
    () =>
      apps.map((app) => {
        const appId = app.id;
        let freeSeats = 0;
        let total = 0;
        const seatsForApp = seats.filter((seat) => seat.appId === appId);
        if (ecoSub) {
          total =
            ecoSub.items.data.find((it) => it.price.product === appId)
              ?.quantity || 0;
        }
        if (seatsForApp.length) {
          freeSeats = seatsForApp.reduce((acc, item) => acc + item.seats, 0);
        }
        return {
          width: 180,
          key: appId,
          title: (
            <div>
              <FormattedMessage
                id={`${app.name}`}
                defaultMessage={`${app.name}`}
              />{' '}
              ({total}/{freeSeats})
            </div>
          ),
          render: (_v, record: MemberWithLicences) => {
            const userLicence = record.licences.find(
              (lic) => lic.appId === appId,
            );

            if (userLicence) {
              if (userLicence.blocked) {
                return <BlockedTag />;
              }
              if (userLicence.status === 'active') {
                return (
                  <Space size="small" direction="horizontal">
                    <ActiveTag />
                    <Switch
                      disabled={loading || processing}
                      checked={true}
                      onClick={() => handleLicenceChange(userLicence, false)}
                    />
                  </Space>
                );
              }
              if (userLicence.status === 'inactive') {
                return (
                  <Space size="small" direction="horizontal">
                    <InActiveTag />
                    <Switch
                      disabled={loading || processing}
                      checked={false}
                      onClick={() => {
                        handleLicenceChange(userLicence, true);
                      }}
                    />
                  </Space>
                );
              }
            }

            if (freeSeats) {
              return (
                <Button
                  onClick={async () => {
                    await handleAddLicenceSelect({
                      member: record,
                      appId,
                      seatsForApp,
                    });
                  }}
                >
                  <FormattedMessage
                    id="add.licence"
                    defaultMessage="Add licence"
                  />
                </Button>
              );
            } else {
              return (
                <Button onClick={() => redirectToStore(appId)}>
                  <FormattedMessage
                    id="buy.licence"
                    defaultMessage="Buy licence"
                  />
                </Button>
              );
            }
          },
        };
      }),
    [
      apps,
      seats,
      loading,
      processing,
      handleLicenceChange,
      handleAddLicenceSelect,
      redirectToStore,
      ecoSub,
    ],
  );

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="settings.memberslist.header.user"
            defaultMessage="User"
          />
        ),
        width: 250,
        sorter: (a, b) => a.displayName.localeCompare(b.displayName),
        dataIndex: 'displayName',
        ellipsis: true,
        render: (_v, record: MemberWithLicences) => {
          const relation = relations.find(
            (relation) => relation.memberId === record.id,
          );
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'relative',
              }}
            >
              {relation && relation.disabled && <BlockedTag />}
              <Space key={record.id} direction="horizontal" size="small">
                {record.photoURL && <Avatar src={record.photoURL} />}
                <span>{record.displayName}</span>
              </Space>
              <div>
                {record.role !== 'owner' && relation ? (
                  <OwnerActionMenu
                    member={record}
                    relation={relation}
                    handleDelete={() => handleDelete(record.id)}
                    handleBlock={() =>
                      handleBlock({
                        memberId: record.id,
                        isBlocked: !relation.disabled,
                      })
                    }
                  />
                ) : null}
              </div>
            </div>
          );
        },
      },
      {
        width: '15vw',
        title: (
          <FormattedMessage
            id="settings.memberslist.header.email"
            defaultMessage="E-mail address"
          />
        ),
        sorter: (a, b) => a.email.localeCompare(b.email),
        dataIndex: 'email',
        ellipsis: true,
      },
      {
        width: '8vw',
        title: (
          <FormattedMessage
            id="settings.memberslist.header.role"
            defaultMessage="Role"
          />
        ),
        sorter: (a, b) => a.role.localeCompare(b.role),
        dataIndex: 'role',
        defaultSortOrder: 'descend',
        ellipsis: true,
        render: (_v, record: MemberWithLicences) => (
          <span key={record.id}>{roles[record.role] || record.role}</span>
        ),
      },
      ...appColumns,
    ],
    [appColumns, relations, handleDelete, handleBlock, roles],
  );

  return (
    <SeatsPoolContext.Provider
      value={{
        seats,
        member: selectedMember,
        appId: stripeProduct?.id || '',
        selectedSub,
        setSelectedSub,
      }}
    >
      <StyledTable
        dataSource={membersWithLicences}
        columns={columns}
        bordered
        loading={processing || loading}
        pagination={false}
        rowKey="email"
        scroll={{ x: 800 }}
      />
      {contextHolder}
    </SeatsPoolContext.Provider>
  );
};

export default MembersList;
