import { Cart, CartEntry, CARTS } from '@services/cart/namespace';
import { firebase } from '@services/Firebase';
import isCartEntryEqual from '@services/cart/isCartEntryEqual';
import moment from 'moment';

const removeEntryFromCart = async (cartEntry: CartEntry, userId: string) => {
  const cartRef = firebase.db?.ref(`${CARTS}/${userId}/cart`);

  if (!cartRef) {
    throw Error('Firebase real time base is not ready');
  }

  const cart = await cartRef.once('value');

  if (cart) {
    if (!cart.exists()) {
      return;
    } else {
      const cartObject = cart.val() as Cart;
      const cartEntries = cartObject.entries;
      const foundedEntryIndex = cartEntries.findIndex((entry) =>
        isCartEntryEqual(entry, cartEntry),
      );

      if (foundedEntryIndex !== -1) {
        const newEntries = cartEntries.filter(
          (_entry, index) => index !== foundedEntryIndex,
        );

        if (newEntries.length < 1) {
          await cartRef.remove();
        } else {
          await cartRef.update({
            entries: newEntries,
            timestamp: moment().valueOf(),
          });
        }
      }
    }
  }
};

export default removeEntryFromCart;
