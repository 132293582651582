import { UploadOutlined } from '@ant-design/icons';
import { Spin, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React from 'react';

interface IUploadModalProps {
  fileAccept: string;
  description: string;
  handleFileUpload: (file: RcFile) => Promise<void>;
  disabled: boolean;
  textOnLoading: string;
}

const UploadModal: React.FC<IUploadModalProps> = ({
  handleFileUpload,
  disabled,
  description,
  textOnLoading,
  fileAccept,
}) => {
  return (
    <Upload.Dragger
      accept={fileAccept}
      showUploadList={false}
      customRequest={({ file }) => handleFileUpload(file as RcFile)}
      disabled={disabled}
    >
      {disabled ? (
        <Spin tip={textOnLoading}>
          <UploadOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
        </Spin>
      ) : (
        <>
          <UploadOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
          <p>{description}</p>
        </>
      )}
    </Upload.Dragger>
  );
};

export default UploadModal;
