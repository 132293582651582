import React, { FC, useCallback, useState } from 'react';
import { Spin } from 'antd';
import RichTextEditor from '@components/RichTextEditor';
import {
  Consultant,
  updateConsultant,
} from '@apps/directSales/mainMenu/Consultants/services';

type Props = {
  consultantId: string;
  consultant: Consultant | null;
  about?: string;
  ecosystemId: string;
};
const ConsultantAbout: FC<Props> = ({ about, consultantId, consultant }) => {
  const [saving, setSaving] = useState(false);
  const handleSave = useCallback(
    (about: string) => {
      if (consultantId && consultant && about) {
        setSaving(true);
        updateConsultant(consultantId, {
          ...consultant,
          about,
        }).finally(() => {
          setSaving(false);
        });
      }
    },
    [consultant, consultantId],
  );
  return (
    <Spin spinning={saving}>
      <RichTextEditor content={about} onSaveClick={handleSave} />
    </Spin>
  );
};

export default ConsultantAbout;
