type DocumentTypeSettings = {
  docType: string;
  isActive: boolean;
};

const defaultDocTypes: DocumentTypeSettings[] = [
  {
    docType: 'catalogue',
    isActive: false,
  },
  {
    docType: 'certificate',
    isActive: false,
  },
  {
    docType: 'commonDocumentation',
    isActive: false,
  },
  {
    docType: 'courtFiles',
    isActive: false,
  },
  {
    docType: 'creditNote',
    isActive: false,
  },
  {
    docType: 'deliveryNote',
    isActive: false,
  },
  {
    docType: 'employeeAbsenceNote',
    isActive: false,
  },
  {
    docType: 'employmentContract',
    isActive: false,
  },
  {
    docType: 'financialContract',
    isActive: false,
  },
  {
    docType: 'leaseContract',
    isActive: false,
  },
  {
    docType: 'nda',
    isActive: false,
  },
  {
    docType: 'order',
    isActive: false,
  },
  {
    docType: 'orderConfirmation',
    isActive: false,
  },
  {
    docType: 'rentalAgreement',
    isActive: false,
  },
  {
    docType: 'report',
    isActive: false,
  },
  {
    docType: 'requestForQuotation',
    isActive: false,
  },
  {
    docType: 'technicalDrawing',
    isActive: false,
  },
  {
    docType: 'termsAndConditions',
    isActive: false,
  },
  {
    docType: 'invoice',
    isActive: false,
  },
  {
    docType: 'performaInvoice',
    isActive: false,
  },
  {
    docType: 'dunningLetter',
    isActive: false,
  },
  {
    docType: 'quotation',
    isActive: false,
  },
  {
    docType: 'picture',
    isActive: false,
  },
  {
    docType: 'letter',
    isActive: false,
  },
  {
    docType: 'shareholderResolution',
    isActive: false,
  },
  {
    docType: 'businessRegistration',
    isActive: false,
  },
  {
    docType: 'crExtract',
    isActive: false,
  },
  {
    docType: 'notaryDeed',
    isActive: false,
  },
  {
    docType: 'dataPrivacyStatement',
    isActive: false,
  },
  {
    docType: 'certificateOfRegistration',
    isActive: false,
  },
];

export default defaultDocTypes;
