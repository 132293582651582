import React, { useState } from 'react';
import { Button } from 'antd';
import { post, get, deleteRequest } from '@core/services/api';
import { rootStore } from 'app/store';
import { getEcosystems } from '@components/EcosystemIndicator/store';
import { getContacts } from '@apps/contacts/services';
import { CONTACTS_ACTION } from '@apps/contacts';

const FILES = [
  'https://firebasestorage.googleapis.com/v0/b/todix-platform-ui.appspot.com/o/documents%2Fdoc1.pdf?alt=media&token=5d9f5f4b-e1eb-4974-95ba-754ebde3f63b',
  'https://firebasestorage.googleapis.com/v0/b/todix-platform-ui.appspot.com/o/documents%2Fdoc2.pdf?alt=media&token=d3e452d2-9279-4392-b75c-b07df485ff52',
  'https://firebasestorage.googleapis.com/v0/b/todix-platform-ui.appspot.com/o/documents%2Fdoc3.pdf?alt=media&token=f761d2d3-f937-438b-8be8-0003920dcc29',
  'https://firebasestorage.googleapis.com/v0/b/todix-platform-ui.appspot.com/o/documents%2Fdoc4.pdf?alt=media&token=787cfdcf-6f77-4404-9bc5-81a2e71155a6',
  'https://firebasestorage.googleapis.com/v0/b/todix-platform-ui.appspot.com/o/documents%2Fdoc5.pdf?alt=media&token=def3e5cf-d5f6-4541-8e6c-d4abd3d2f8fd',
];
const getRandomFile = () => FILES[Math.floor(Math.random() * FILES.length)];

const getRandomEcosystem = () => {
  const ecosystems = getEcosystems(rootStore.getState());
  return ecosystems[Math.floor(Math.random() * ecosystems.length)];
};
const getRandomContact = async () => {
  const response = await getContacts({}, 'view-contacts' as CONTACTS_ACTION);
  const contacts = response.results;
  const contact = contacts[Math.floor(Math.random() * contacts.length)];
  return contact;
};
const getRandomDoc = async () => {
  const ecosystem = getRandomEcosystem();
  const contact = await getRandomContact();
  if (!contact) {
    return false;
  }
  return {
    documentUrl: getRandomFile(),
    type: 'invoice',
    ecosystem: ecosystem.id,
    category: 'incoming',
    fileDetails: {
      format: 'pdf',
      size: 1000,
    },
    financialRelevant: false,
    // @ts-ignore
    relatedContact: contact.id,
    relatedDocuments: [],
    uploadingUser: 'John',
    creatingUser: {
      displayName: 'John',
      photoURL: '',
      email: 'john.doe@test.com',
    },
    creationDate: '2020-11-19T11:58:25+00:00',
    uploadDate: '2020-11-19T11:58:25+00:00',
    receivingDate: '2020-11-19',
    origin: 'documents',
    tags: [],
    notes: 'lorem ipsum',
  };
};

const COLLECTION = 'documents';

const Settings = () => {
  const [loading, setLoading] = useState(false);
  return (
    <>
      Import operation take a while because request for data is going through
      cors proxy.
      <div />
      <Button
        disabled={loading}
        onClick={() => {
          setLoading(true);
          get(COLLECTION)
            ?.then((docs) =>
              Promise.all(
                (docs as object[]).map((doc: any) =>
                  deleteRequest(`${COLLECTION}/${doc.id}`),
                ),
              ),
            )
            .then(() => {
              setLoading(false);
              alert('finish');
            });
        }}
      >
        delete all
      </Button>
      <Button
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          const doc = await getRandomDoc();
          if (!doc) {
            return false;
          }
          return post(COLLECTION, doc)?.then(() => {
            setLoading(false);
            alert('finish');
          });
        }}
      >
        import
      </Button>
    </>
  );
};

export default Settings;
