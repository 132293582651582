import {
  WeekPlanEntry,
  WEEK_PLAN_COLLECTION_NAME,
  GetApiResponse,
} from '@apps/weekplan/services/namespace';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import { get as apiGet } from '@services/api';
import getFilteredResults from '@core/helpers/getFilteredResults';
import appConfig from '@apps/weekplan';

const getWeekPlans: GetApiResponse = (passedParams, action) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 1000,
    fields: ['id', 'ecosystem'],
    filters: {},
    sort: [],
    ...passedParams,
  };

  // @ts-ignore
  return apiGet(WEEK_PLAN_COLLECTION_NAME)?.then((data: WeekPlanEntry[]) => {
    return getFilteredResults({
      data,
      params,
      activeEcosystemsIds,
    });
  });
};

export default getWeekPlans;
