import React, { FC, useState, useEffect, useCallback } from 'react';
import { Tree, Spin } from 'antd';
import { getTask, Task } from '@apps/tasks/services';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { FormattedMessage } from 'react-intl';
import { ApiOutlined, DeleteOutlined } from '@ant-design/icons';
import SpinContainer from '@components/SpinContainer';
import SelectTasks from '@apps/tasks/mainMenu/components/Form/SelectTasks';
import createRelation from '@services/entityRelations/createRelation';
import removeRelation from '@services/entityRelations/removeRelation';
import Tabs from '@components/Tabs';
import AppConfig from '@apps/tasks';
import {
  GridContainer,
  TaskTitleContainer,
} from '@apps/tasks/widgets/RelatedTasks.sc';

const { TabPane } = Tabs;

type RelatedTasksProps = {
  projectId: string;
  ecosystem?: string;
  onTaskSelect(task: Task): void;
  readOnly: boolean;
};

type Relations = Record<string, string>;

type RelatedTasksWidget = (props: RelatedTasksProps) => JSX.Element;

const RelatedTasks: FC<RelatedTasksProps> = ({
  projectId,
  ecosystem,
  readOnly,
  onTaskSelect,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [relations, setRelations] = useState<Relations>({});
  const [tree, setTree] = useState<DataNode[]>([]);

  const handleSubtaskSubmission = useCallback(() => {
    if (projectId && ecosystem) {
      setIsFetching(true);
      getChildrenForParent({
        parentId: projectId,
        childType: 'task',
        ecosystem,
      })
        .then((relations) => {
          setRelations(
            relations.reduce((acc, item) => {
              return {
                ...acc,
                [item.childId]: item.id as string,
              };
            }, {} as Relations),
          );
          const tasksPromises = relations.map((relation) =>
            getTask(relation.childId),
          );
          Promise.all(tasksPromises).then((tasks) => {
            setTasks(tasks);
          });
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [ecosystem, projectId]);

  const handleTaskDeSelect = useCallback(
    (taskId) => {
      if (projectId) {
        if (relations[taskId]) {
          removeRelation(relations[taskId]).then(() => {
            handleSubtaskSubmission();
          });
        }
      }
    },
    [handleSubtaskSubmission, projectId, relations],
  );

  useEffect(() => {
    if (tasks.length && ecosystem) {
      const treeData: DataNode[] = tasks.map((task) => {
        return {
          key: task.id as string,
          title: (
            <TaskTitleContainer>
              <span onClick={() => !readOnly && task && onTaskSelect(task)}>
                {task?.title}
              </span>
              <DeleteOutlined
                onClick={() => task && handleTaskDeSelect(task.id)}
                style={{ marginLeft: '10px' }}
              />
            </TaskTitleContainer>
          ),
          selectable: false,
          checkable: false,
          icon: (
            <ApiOutlined
              onClick={() => !readOnly && task && onTaskSelect(task)}
            />
          ),
        };
      });
      setTree(treeData);
    }
  }, [
    ecosystem,
    handleTaskDeSelect,
    onTaskSelect,
    readOnly,
    tasks,
    tasks.length,
  ]);

  useEffect(() => {
    if (projectId && ecosystem) {
      setIsFetching(true);
      getChildrenForParent({
        parentId: projectId,
        childType: 'task',
        ecosystem,
      })
        .then((relations) => {
          const tasksPromises = relations.map((relation) =>
            getTask(relation.childId),
          );
          Promise.all(tasksPromises).then((tasks) => {
            setTasks(tasks);
            setRelations(
              relations.reduce((acc, item) => {
                return {
                  ...acc,
                  [item.childId]: item.id as string,
                };
              }, {} as Relations),
            );
          });
        })
        .finally(() => {
          setIsFetching(false);
        });
    }
  }, [projectId, ecosystem, readOnly]);

  const handleTaskSelect = useCallback(
    (taskId) => {
      if (projectId && ecosystem) {
        if (!relations[taskId]) {
          createRelation({
            parentId: projectId,
            parentType: 'project',
            parentRefCollection: 'projects',
            childId: taskId,
            childType: 'task',
            childRefCollection: 'tasks',
            ecosystem,
          }).then(() => {
            handleSubtaskSubmission();
          });
        }
      }
    },
    [projectId, ecosystem, relations, handleSubtaskSubmission],
  );

  const updateTreeData = useCallback(
    (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
      list.map((node) => {
        if (node.key === key) {
          return {
            ...node,
            children,
          };
        }
        if (node.children) {
          return {
            ...node,
            children: updateTreeData(node.children, key, children),
          };
        }
        return node;
      }),
    [],
  );

  const onLoadData = useCallback(
    (treeNode: EventDataNode) => {
      if (!ecosystem) {
        return Promise.resolve();
      }
      const keyParts = (treeNode.key as string).split('-');
      const key = keyParts[keyParts.length - 1];
      return getChildrenForParent({
        parentId: key,
        ecosystem,
        childType: 'task',
      }).then((entities) => {
        const tasksPromises = entities
          .map((entity) => entity.childId)
          .map((id) => getTask(id));
        Promise.all(tasksPromises).then((results) => {
          setTree((origin) =>
            updateTreeData(
              origin,
              treeNode.key,
              results.map((task) => ({
                title: task.title,
                key: `${treeNode.key}-${task.id as string}`,
                selectable: false,
                checkable: false,
              })),
            ),
          );
        });
      });
    },
    [ecosystem, updateTreeData],
  );

  return (
    <Spin spinning={isFetching} indicator={<SpinContainer />}>
      <GridContainer>
        <div>
          {!readOnly && projectId && ecosystem && (
            <SelectTasks
              readOnly={readOnly}
              subTasks={tasks}
              parentId={projectId}
              parentType="project"
              ecosystem={ecosystem}
              handleTaskSelect={handleTaskSelect}
              handleTaskDeselect={handleTaskDeSelect}
              handleClear={() => console.warn('clear')}
            />
          )}
          <Tree
            multiple={false}
            style={{
              marginBottom: '20px',
            }}
            showIcon
            showLine
            defaultExpandAll
            treeData={tree}
            loadData={onLoadData}
          />
        </div>
      </GridContainer>
    </Spin>
  );
};

const getRelatedTasksTab: RelatedTasksWidget = (props) => {
  return (
    <TabPane
      key={AppConfig.todixId}
      tab={
        <>
          <AppConfig.mainMenu.icon />{' '}
          <FormattedMessage
            id="tasks.related.tasks"
            defaultMessage="Related tasks"
          />
        </>
      }
    >
      <RelatedTasks {...props} />
    </TabPane>
  );
};

export default getRelatedTasksTab;
