import React from 'react';
import { Form } from 'antd';
import { ProductPrice } from '@apps/products/services';
import StandardPrice from '@apps/sales/widgets/SalesTab/Pricing/PriceForm/Price/StandardPrice';
const { Item } = Form;

const Price: React.FC = () => {
  return (
    <Item
      noStyle
      shouldUpdate={(prevValues, nextValues) =>
        prevValues.model !== nextValues.model
      }
    >
      {({ getFieldValue }) => {
        const model: ProductPrice['model'] = getFieldValue('model');

        if (model === 'standard') {
          return <StandardPrice />;
        }
        return null;
      }}
    </Item>
  );
};

export default Price;
