export type UserFieldOption = {
  label: string;
  value: string | number;
};

export type UserField = {
  id?: string;
  ecosystemId: string;
  required?: boolean;
  label: string;
  value?: string | number;
  type: 'text' | 'number' | 'dropdown' | 'datepicker';
  options?: UserFieldOption[];
  timestamp?: number;
};

export type PriceModel = 'standard' | 'package';

export type PriceType = 'one_time' | 'recurring';

export type PriceBillingScheme = 'per_unit' | 'tiered';

export type PriceCurrencyOption = {
  unitAmount: number;
  unitAmountDecimal: string;
  isTaxInclusive?: boolean;
};

export type ProductPrice = {
  id?: string;
  ecosystemId: string;
  isActive: boolean;
  isArchived: boolean;
  isDefaultPrice?: boolean;
  isFallbackPrice?: boolean;
  isTimeValid?: boolean;
  constraints: {
    validDateRange: number[] | null;
    validOrderType?: string;
    validCommissionLevel?: string;
    validCountry?: string;
  };
  created: number;
  currency: string;
  currencyOptions?: Record<string, PriceCurrencyOption>;
  name?: string;
  description?: string;
  productId: string;
  timestamp?: number;
  model: PriceModel;
  type: PriceType;
  billingScheme: PriceBillingScheme;
  unitAmount: number;
  isTaxInclusive?: boolean;
  customerLimit: number;
  orderLimit: number;
  commissionFactor?: number; // in %
};

export type SaleDetails = {
  salesUnit: string;
  unitBasePrice?: string;
  amountBaseUnit?: number;
  amountPerUnit?: number;
  weight?: any;
  volume?: any;
  content?: any;
  warrantyMonths?: number;
  deliveryTimeDays?: number;
  withExtraBox?: boolean;
  boxId?: string;
  minAmount?: number;
  tariffNumber?: any;
  isTariffNumberChecked?: boolean;
  taxCategory?: string;
  isActive?: boolean;
  baseUnitRequired?: boolean;
  noDeliveryCost?: boolean;
};

export type Product = {
  ecosystem: string;
  id?: string;
  isActive: boolean;
  tax?: number;
  productCreationDate: string;
  productName: string;
  shortDescription: string;
  productNumber: string;
  description: string;
  productGroup: string[];
  tags: string[];
  unit: string;
  creator: User;
  saleDetails: SaleDetails;
  price?: number;
  amount?: number;
  discount?: number;
  timestamp?: number;
  assetUrl?: string;
  assetFormat?: string;
  groupPurchase?: string;
  groupSales?: string;
  eanCode?: string;
  userFields?: UserField[];
};

export type GetProductsParams = {
  query?: string;
  offset?: number;
  limit?: number;
  fields?: string[];
  filters?: object;
  sort?: string[];
};

export type ApiResponse = {
  results: object[];
  info: {
    results: number;
  };
};

export const PRODUCTS_COLLECTION_NAME = 'products';

export const USER_FIELDS_COLLECTION_NAME = 'productUserFields';

export const PRODUCT_PRICES = 'productPrices';
