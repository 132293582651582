import { IGetRowsParams } from 'ag-grid-community';
import { FilterTextObject } from '@services/filtering';
import { getConsultantsWithStats } from '@apps/directSales/mainMenu/Consultants/services';

export const getRows = (
  params: IGetRowsParams & { ecosystems?: string[]; query?: string },
) => {
  const filtersEntries = Object.entries(params.filterModel).map(
    ([key, filterObj]) => [
      key,
      // @ts-ignore
      filterObj,
    ],
  );
  if (!params.filterModel.ecosystem && params.ecosystems) {
    filtersEntries.push([
      'ecosystem',
      {
        filter: params.ecosystems.join('|'),
        filterType: 'text',
        type: 'contains',
      } as FilterTextObject,
    ]);
  }
  getConsultantsWithStats({
    query: params.query,
    offset: params.startRow,
    limit: params.endRow - params.startRow,
    fields: [
      'ecosystem',
      'id',
      'consultantName',
      'consultantSurname',
      'consultantEmail',
      'consultantBirthday',
      'consultantPhone',
      'consultantStreet',
      'consultantStreetNo',
      'consultantZip',
      'consultantCity',
      'consultantRef',
      'consultantParent',
      'consultantNo',
      'consultantTaxNo',
      'consultantUser',
      'isActive',
      'validated',
      'validationDate',
      'validatedById',
      'consultantLevel',
      'consultantRank',
      'description',
      'applicationDate',
      'careerplanConfirmed',
      'privacyProtectionConfirmed',
      'agbConfirmed',
      'iban',
      'bic',
    ],
    filters: Object.fromEntries(filtersEntries),
    sort: params.sortModel.map(
      (model: { colId: string; sort: string }) =>
        `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
    ),
  }).then((response) => {
    params.successCallback(response.results, response.info.results);
  });
};
