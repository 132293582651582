import {
  EcosystemRelationAction,
  EcosystemRelationsTypes,
} from '@core/store/ecosystemRelations/actions';

export type EcosystemRelationsState = {
  data: EcosystemRelation[];
  isLoading: boolean;
};

export const initial: EcosystemRelationsState = {
  data: [],
  isLoading: false,
};

export const ecosystemRelationsReducer = (
  state = initial,
  action: EcosystemRelationAction,
) => {
  switch (action.type) {
    case EcosystemRelationsTypes.SET_RELATIONS: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case EcosystemRelationsTypes.UPDATE_RELATION: {
      const { relation } = action.payload;
      const currentRelation = state.data.find((rel) => rel.id === relation.id);
      if (currentRelation) {
        return {
          ...state,
          data: state.data.map((rel) => {
            if (rel.id === relation.id) {
              return relation;
            }
            return rel;
          }),
        };
      } else {
        return {
          ...state,
          data: [...state.data, relation],
        };
      }
    }
    default:
      return state;
  }
};
