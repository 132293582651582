import { styled } from '@styles/themes';

export const FlexDiv = styled.div`
  display: flex;
`;

export const FlexDivColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivBoldMarginRight = styled.div`
  font-weight: bold;
  margin-right: 10px;
`;

export const SpanMarginRight = styled.span`
  margin-right: 10px;
`;

export const CommissionRulesLabel = styled.div`
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
`;

export const ScrollableColumn = styled.div`
  overflow: auto;
  max-height: 75vh;
`;

export const ReportTriggerControlWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
`;

export const CommissionsExtra = styled.div`
  display: flex;
  align-items: center;
`;
