import { jsPDF } from 'jspdf';
import { TemplateProps } from '@apps/sales/mainMenu/components/pdfTemplates/namespace';

const addEcosystemData = (
  doc: jsPDF,
  props: TemplateProps,
  lastYPosition: number,
): number => {
  const { ecosystem } = props;
  let newLastYPosition = lastYPosition;
  if (ecosystem && ecosystem.address) {
    const { name } = ecosystem;
    const { street, number, country, zipcode, city } = ecosystem.address;
    if (street && number !== undefined) {
      doc.setFont('times', 'normal', 'normal');
      doc.setFontSize(12);
      if (name) {
        doc.text(name, 150, newLastYPosition);
      }
      if (street && number) {
        newLastYPosition = newLastYPosition + 5;
        doc.text(`${street}, ${number}`, 150, newLastYPosition);
      }
      if (zipcode && city) {
        newLastYPosition = newLastYPosition + 5;
        doc.text(`${zipcode}, ${city}`, 150, newLastYPosition);
      }
      if (country) {
        newLastYPosition = newLastYPosition + 5;
        doc.text(country, 150, newLastYPosition);
      }
    }
  }
  return newLastYPosition;
};

export default addEcosystemData;
