export enum EcosystemRelationsTypes {
  SET_RELATIONS = 'SET_RELATIONS',
  UPDATE_RELATION = 'UPDATE_RELATION',
}

type SetRelationsPayload = {
  relations: EcosystemRelation[];
};

export const setRelations = (payload: SetRelationsPayload) =>
  ({
    type: EcosystemRelationsTypes.SET_RELATIONS,
    payload,
  } as const);

export type SetRelationsAction = ReturnType<typeof setRelations>;

type UpdateRelationPayload = {
  relation: EcosystemRelation;
};

export const updateRelation = (payload: UpdateRelationPayload) =>
  ({
    type: EcosystemRelationsTypes.UPDATE_RELATION,
    payload,
  } as const);

export type UpdateRelationAction = ReturnType<typeof updateRelation>;

export type EcosystemRelationAction = SetRelationsAction | UpdateRelationAction;
