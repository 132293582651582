import React, { FC, useState, useEffect } from 'react';
import { fetchUser } from '@services/users';
import { Avatar, Space, Spin, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';

type Props = {
  id: string;
};

const FetchedUser: FC<Props> = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    fetchUser(id)
      .then((user: User) => {
        setUser(user);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const tempUser = id ? id.includes('tempId') : '';

  return (
    <Space direction="horizontal" size="small">
      {isLoading && <Spin spinning={true} />}
      {user?.photoURL && <Avatar src={user.photoURL} />}
      {user?.displayName && !tempUser && <span>{user.displayName}</span>}
      {!isLoading && (tempUser || !user) && (
        <Tag color="blue">
          <FormattedMessage
            id="users.external"
            defaultMessage="External user"
          />
        </Tag>
      )}
    </Space>
  );
};

export default FetchedUser;
