import React, { FC, useState, useCallback, useEffect, useMemo } from 'react';
import { Card, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { styled } from '@styles/themes';
import StorageModal from '@apps/warehouse/settings/StorageModal';
import { useParams } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import { UseParamsType } from '@pages/settings';
import { StyledEditIcon, StyledTable } from '@pages/settings/components';
import fetchWarehouseStoragesForEcosystem from '@apps/warehouse/services/fetchWarehouseStoragesForEcosystem';
import { WarehouseStorage } from '@apps/warehouse/services/namespace';

const StyledButton = styled(Button)`
  && {
    flex-direction: row-reverse;

    .anticon {
      margin-left: 4px;
    }
  }
`;

const Settings: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storages, setStorages] = useState<WarehouseStorage[]>([]);
  const [selectedStorage, setSelectedStorage] =
    useState<WarehouseStorage | null>(null);
  useEffect(() => {
    if (uid) {
      setLoading(true);
      fetchWarehouseStoragesForEcosystem(uid)
        ?.then(setStorages)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);
  const onOk = useCallback(() => {
    setVisible(false);
    if (uid) {
      setLoading(true);
      fetchWarehouseStoragesForEcosystem(uid)
        ?.then(setStorages)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);
  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="warehouse.form.name.label"
            defaultMessage="Name"
          />
        ),
        sorter: true,
        dataIndex: 'name',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: WarehouseStorage) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedStorage(record);
                setVisible(true);
              }}
            />
          </>
        ),
      },
    ],
    [],
  );
  return (
    <Card
      title={
        <FormattedMessage
          id="warehouse.settings.header"
          defaultMessage="Warehouse storages"
        />
      }
      extra={
        <StyledButton
          type="primary"
          size="small"
          onClick={() => setVisible(true)}
        >
          <FormattedMessage
            id="warehouse.settings.storage.createNewButton"
            defaultMessage="Create new storage"
          />
        </StyledButton>
      }
    >
      <StyledTable
        dataSource={storages}
        columns={columns}
        bordered
        tableLayout="auto"
        loading={loading}
        pagination={false}
        rowKey="id"
      />
      <StorageModal
        visible={visible}
        ecosystemId={uid}
        storage={selectedStorage}
        onCancel={onCancel}
        onOk={onOk}
      />
    </Card>
  );
};

export default Settings;
