import React, { FC } from 'react';
import { Form } from 'antd';

const { Item } = Form;

const HiddenFields: FC = () => {
  return (
    <>
      <Item name="ecosystem" hidden={true} />
      <Item name="uploadDate" hidden={true} />
    </>
  );
};

export default HiddenFields;
