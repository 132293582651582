import products from '@apps/products';
import documents from '@apps/documents';
import sales from '@apps/sales';
import weekplan from '@apps/weekplan';
import projects from '@apps/projects';
import tasks from '@apps/tasks';
import contacts from '@apps/contacts';
import warehouse from '@apps/warehouse';
import directSales from '@apps/directSales';
import directSalesClient from '@apps/directSalesClient';
import finance from '@apps/finance';
import purchases from '@apps/purchases';
import { FileApp } from '@apps/AppTypes';

export const connectedApps: FileApp[] = [
  products,
  documents,
  sales,
  weekplan,
  projects,
  tasks,
  contacts,
  warehouse,
  directSales,
  directSalesClient,
  finance,
  purchases,
];
