import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Card, Tooltip } from 'antd';
import { styled } from '@styles/themes';
import { ColumnType } from 'antd/lib/table';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import { ProductGroupFormModal } from './ProductGroupFormModal';
import {
  deleteProductGroup,
  getEcosystemProductGroups,
  updateProductGroup,
} from '@services/productGroups';
import { ProductGroup } from '@services/productGroups/namespace';
import { Button } from '@todix/ui-components';
import { rootStore } from '@core/store';
import {
  StyledSwitch,
  StyledTable,
  StyledDeleteIcon,
  StyledEditIcon,
} from '@pages/settings/components';

const StyledButton = styled(Button)`
  && {
    flex-direction: row-reverse;

    .anticon {
      margin-left: 4px;
    }
  }
`;

const ProductGroups: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [loading, setLoading] = useState(true);
  // normally it would be good to have some library to manage cache refresh e.g. react-query-cache
  const [refreshProductGroupsState, setRefreshProductGroups] = useState<any>({
    init: 'init',
  });
  const [productGroups, setProductGroups] = useState<ProductGroup[]>([]);
  const [selectedProductGroup, setSelectedProductGroup] = useState<
    ProductGroup | undefined
  >(undefined);
  const [visible, setVisible] = useState(false);
  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  const refreshProductGroups = () => {
    setRefreshProductGroups({});
  };

  useEffect(() => {
    getEcosystemProductGroups(uid)
      ?.then((results) => {
        setLoading(false);

        if (results) {
          setProductGroups(results);
          rootStore.dispatch({
            type: 'SET_ECOSYSTEM_PRODUCT_GROUP',
            payload: results || [],
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [uid, refreshProductGroupsState]);

  const handleProductChange = useCallback(async (product: ProductGroup) => {
    setLoading(true);
    await updateProductGroup(product);
    refreshProductGroups();
    setLoading(false);
  }, []);

  const handleProductDeletion = useCallback(async (productId: string) => {
    setLoading(true);
    await deleteProductGroup(productId);
    refreshProductGroups();
    setLoading(false);
  }, []);

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="settings.products.groups.list.header.name"
            defaultMessage="Name"
          />
        ),
        sorter: true,
        dataIndex: 'name',
        ellipsis: true,
        render: (_v, record: ProductGroup) => record.name,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: ProductGroup) => (
          <Tooltip
            title={
              <FormattedMessage
                id="settings.products.groups.list.isActive.tooltip"
                defaultMessage="Activate to make this product group available to be set for new products"
              />
            }
          >
            <StyledSwitch
              key={record.id}
              checked={record.isActive}
              onChange={() =>
                handleProductChange({
                  ...record,
                  isActive: !record.isActive,
                } as ProductGroup)
              }
              checkedChildren={activated}
              unCheckedChildren={deactivated}
            />
          </Tooltip>
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: ProductGroup) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedProductGroup(record);
                setVisible(true);
              }}
            />
            <StyledDeleteIcon
              onClick={() => handleProductDeletion(record.id || '')}
            />
          </>
        ),
      },
    ],
    [activated, deactivated, handleProductChange, handleProductDeletion],
  );

  return (
    <Card
      title={
        <FormattedMessage
          id="settings.products.groups.title"
          defaultMessage="settings.products.groups.title"
        />
      }
      extra={
        <StyledButton
          type="primary"
          size="small"
          onClick={() => setVisible(true)}
        >
          <FormattedMessage
            id="settings.products.groups.createNewButton"
            defaultMessage="settings.products.groups.createNewButton"
          />
        </StyledButton>
      }
    >
      <StyledTable
        dataSource={productGroups}
        columns={columns}
        bordered
        tableLayout="auto"
        loading={loading}
        pagination={false}
        rowKey="id"
      />
      <ProductGroupFormModal
        visible={visible}
        selectedProductGroup={selectedProductGroup}
        setSelectedProductGroup={setSelectedProductGroup}
        setVisible={setVisible}
        refreshProductGroups={refreshProductGroups}
        ecosystemId={uid}
      />
    </Card>
  );
};

export default ProductGroups;
