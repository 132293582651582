import SettingsConfig from '@apps/contacts/settings';
import MainMenuConfig from '@apps/contacts/mainMenu';
import { FileApp } from '@apps/AppTypes';

export type CONTACTS_ACTION =
  | 'view-contacts'
  | 'create-contacts'
  | 'update-contacts'
  | 'delete-contacts';

export type CONTACTS_PERMISSION = 'all' | 'none';

export default {
  todixId: 'contacts',
  link: '/app/contacts',
  name: 'contacts',
  entityType: 'contact',
  collectionName: 'contacts',
  localizationPath: 'contacts/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.contacts.createNewLink.new.contact',
    },
  ],
  widgets: [
    {
      name: 'DashboardWidget',
      path: 'contacts/widgets/DashboardWidget',
    },
    {
      name: 'StatisticsWidget',
      path: 'contacts/widgets/StatisticsWidget',
    },
    {
      name: 'AddNewContactWidget',
      path: 'contacts/widgets/AddNewContactWidget',
    },
    {
      name: 'AppRolesForm',
      path: 'contacts/widgets/AppRolesForm',
    },
  ],
  actions: [
    'view-contacts',
    'create-contacts',
    'update-contacts',
    'delete-contacts',
  ] as CONTACTS_ACTION[],
  permissions: ['all', 'none'] as CONTACTS_PERMISSION[],
} as FileApp;
