import React, { FC, useEffect, useState } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import { Spin, Typography, Progress } from 'antd';
import EcosystemAvatar from '@components/EcosystemAvatar';
import { getTasks, Task } from '@apps/tasks/services';
import { StyledDashboardCard } from '@pages/dashboard';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Icon } from '@todix/ui-components';
import AppConfig from '@apps/tasks';
import HugeButton from '@components/HugeButton';
import WidgetCard from '@components/WidgetCard';
import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';

const { Text } = Typography;

const DashboardWidget: FC = () => {
  const [spinning, setSpinning] = useState(true);
  const [tasks, setTasks] = useState<Task[]>([]);
  const activeEcosystems = useSelector(getActiveEcosystems);
  const intl = useIntl();

  useEffect(() => {
    if (activeEcosystems.length) {
      setSpinning(true);
      getTasks(
        {
          fields: [
            'id',
            'ecosystem',
            'creationDate',
            'createdBy',
            'title',
            'description',
            'dueDate',
            'assignee',
            'progress',
            'startDate',
            'doneDate',
            'documents',
            'tags',
          ],
          limit: 3,
        },
        'view-tasks',
      )
        .then((response) => {
          setTasks(response.results as Task[]);
          setSpinning(false);
        })
        .catch((e) => {
          console.error(e);
          setSpinning(false);
        });
    }
  }, [activeEcosystems.length, setTasks]);

  return (
    <StyledDashboardCard bordered={false}>
      <Spin spinning={spinning} style={{ height: '100%' }} />
      {tasks.length ? (
        <ReactCardCarousel autoplay spread="narrow" alignment="horizontal">
          {tasks.map((task, index) => (
            <WidgetCard
              key={index}
              title={
                <div>
                  <EcosystemAvatar id={task.ecosystem} /> {task.title}
                </div>
              }
              bordered={false}
              extra={
                <Link to={`/app/${AppConfig.todixId}`}>
                  {intl.formatMessage({
                    id: 'dashboard.tasks.link',
                    defaultMessage: 'Go to tasks',
                  })}
                </Link>
              }
            >
              <div>
                {intl.formatMessage({
                  id: 'tasks.columns.progress.header',
                  defaultMessage: 'Progress',
                })}
                :{' '}
                <Progress
                  percent={task.progress}
                  status={task.progress < 100 ? 'active' : 'success'}
                />
              </div>
              <div>
                {intl.formatMessage({
                  id: 'tasks.columns.dueDate.header',
                  defaultMessage: 'Due Date',
                })}
                : <Text strong>{task.dueDate}</Text>
              </div>
            </WidgetCard>
          ))}
        </ReactCardCarousel>
      ) : (
        !spinning && (
          <Link to={`/app/${AppConfig.todixId}/create`}>
            <HugeButton type="dashed" icon={<Icon.CarryOutOutlined />}>
              <FormattedMessage
                id="dashboard.empty.createtask"
                defaultMessage="Create your first Task now"
              />
            </HugeButton>
          </Link>
        )
      )}
    </StyledDashboardCard>
  );
};

export default DashboardWidget;
