import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const Settings = lazy(
  () => import(/* webpackChunkName: "tasksApp" */ './Settings'),
);

export default {
  appSubmenuName: 'app.tasks.mainMenu.name',
  icon: Icon.RocketOutlined,
  sections: [
    {
      link: 'tasks_settings',
      title: 'settings.tasks.manage',
      component: Settings,
    },
  ],
};
