import React, { FC } from 'react';
import { Product } from '@apps/products/services';
import { Input, Table, Form as aForm, InputNumber, DatePicker } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectedUnit from '@apps/products/mainMenu/components/SelectedUnit';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import { TaxSelect } from '@apps/sales/widgets/SalesTab/TaxSelect';
const { Item } = aForm;
const { TextArea } = Input;

type ProductsTableType = {
  products: Product[];
  readOnly: boolean;
  form: any;
  ecosystemId?: string;
  formValues: any;
};

const StyledTable = styled(Table)`
  .ant-form-item {
    margin-bottom: 0;
  }
  tr.ant-table-expanded-row > td {
    background-color: #ffffff;
  }

  tr.ant-table-expanded-row:hover > td {
    background-color: #fbfbfb;
  }
`;

const StyledDeliveryDateItem = styled(Item)`
  width: 30%;

  & .ant-form-item-label {
    max-width: 100%;
    flex: 1;
  }
`;

export const ProductsTable: FC<ProductsTableType> = ({
  products,
  readOnly,
  ecosystemId,
  form,
  formValues,
}) => {
  const dataSource = products;
  // initial values by product manipulation:

  const intl = useIntl();

  const columns = [
    {
      title: null,
      key: 'action',
      render: (_value: any, record: any) => {
        return (
          <DeleteOutlined
            onClick={() => {
              const allFormValues = form.getFieldsValue();
              allFormValues.products.splice(record.key, 1);
              form.setFieldsValue(allFormValues);
            }}
          />
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="products.form.productnumber.label"
          defaultMessage="Product number"
        />
      ),
      dataIndex: 'productNumber',
      key: 'productNumber',
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item
            name={
              namePrefix !== undefined
                ? [namePrefix, `productNumber`]
                : `productNumber`
            }
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="products.form.productnumber.error.missing"
                    defaultMessage="Product number is missing"
                  />
                ),
              },
              {
                type: 'string',
                max: 40,
                message: (
                  <FormattedMessage
                    id="products.form.productnumber.error"
                    defaultMessage="Product number cannot be longer than 40 characters"
                  />
                ),
              },
            ]}
          >
            <Input
              readOnly
              style={{
                minWidth: 120,
              }}
              name={`${namePrefix}productNumber`}
              placeholder={intl.formatMessage({
                id: 'products.form.productnumber.placeholder',
                defaultMessage: 'Product number...',
              })}
              autoComplete="nope"
            />
          </Item>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="products.form.productname.label"
          defaultMessage="Product name"
        />
      ),
      dataIndex: 'productName',
      key: 'productName',
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item
            name={
              namePrefix !== undefined
                ? [namePrefix, `productName`]
                : `productName`
            }
            required
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="products.form.productname.error.missing"
                    defaultMessage="Product name is missing"
                  />
                ),
              },
              {
                type: 'string',
                max: 40,
                message: (
                  <FormattedMessage
                    id="products.form.productname.error"
                    defaultMessage="Product name cannot be longer than 40 characters"
                  />
                ),
              },
            ]}
          >
            <Input
              name={`${namePrefix}productName`}
              style={{
                minWidth: 120,
              }}
              placeholder={intl.formatMessage({
                id: 'products.form.productname.placeholder',
                defaultMessage: 'Product name...',
              })}
              autoComplete="nope"
              readOnly={readOnly}
            />
          </Item>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="products.selectedunit.label"
          defaultMessage="Unit"
        />
      ),
      dataIndex: 'unit',
      key: 'unit',
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <SelectedUnit
            ecosystemId={ecosystemId}
            namePrefix={namePrefix}
            withLabel={false}
            selectStyle={{ width: 150 }}
          />
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="sales.createForm.amount.label"
          defaultMessage="Amount"
        />
      ),
      dataIndex: 'amount',
      key: 'amount',
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item
            name={namePrefix !== undefined ? [namePrefix, `amount`] : `amount`}
          >
            <Input
              name={`${namePrefix}amount`}
              type={'number'}
              min={'0'}
              style={{ width: 100 }}
              step={'1'}
              placeholder={intl.formatMessage({
                id: 'sales.createForm.amount.label',
                defaultMessage: 'Amount',
              })}
            />
          </Item>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="sales.createForm.netPrice.label"
          defaultMessage="Net price"
        />
      ),
      dataIndex: 'price',
      key: 'price',
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item
            name={namePrefix !== undefined ? [namePrefix, `price`] : `price`}
          >
            <InputNumber
              name={`${namePrefix}price`}
              type={'number'}
              min={'0'}
              step={0.1}
              decimalSeparator={'.'}
              placeholder={intl.formatMessage({
                id: 'sales.createForm.netPrice.label',
                defaultMessage: 'Net price',
              })}
            />
          </Item>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="sales.createForm.tax.label"
          defaultMessage="Tax (%)"
        />
      ),
      dataIndex: 'tax',
      key: 'tax',
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item name={namePrefix !== undefined ? [namePrefix, `tax`] : `tax`}>
            <TaxSelect
              ecosystemId={ecosystemId}
              readOnly={readOnly}
              style={{ width: 170 }}
            />
          </Item>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="sales.createForm.grossPrice.label"
          defaultMessage="Gross price"
        />
      ),
      dataIndex: 'grossPrice',
      key: 'grossPrice',
      width: 100,
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item dependencies={[`${namePrefix}.price`, `${namePrefix}.amount`]}>
            {() => {
              const { price, tax } = form.getFieldValue().products[namePrefix];
              const sum = (Number(price) * (100 + Number(tax))) / 100;
              return (
                <span style={{ width: 30, display: 'block' }}>
                  {sum ? sum.toFixed(2) : '-'}
                </span>
              );
            }}
          </Item>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="sales.createForm.sumNet.label"
          defaultMessage="Sum net"
        />
      ),
      dataIndex: 'sumNet',
      key: 'sumNet',
      width: 100,
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item dependencies={[`${namePrefix}.price`, `${namePrefix}.amount`]}>
            {() => {
              const { price, amount } =
                form.getFieldValue().products[namePrefix];
              const sum = Number(price) * Number(amount);
              return (
                <span style={{ width: 30, display: 'block' }}>
                  {sum ? sum.toFixed(2) : '-'}
                </span>
              );
            }}
          </Item>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="sales.createForm.sumGross.label"
          defaultMessage="Sum gross"
        />
      ),
      dataIndex: 'sumGross',
      key: 'sumGross',
      width: 100,
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item dependencies={[`${namePrefix}.price`, `${namePrefix}.amount`]}>
            {() => {
              const { price, amount, tax } =
                form.getFieldValue().products[namePrefix];
              const sum =
                (Number(price) * Number(amount) * (100 + Number(tax))) / 100;
              return (
                <span style={{ width: 30, display: 'block' }}>
                  {sum ? sum.toFixed(2) : '-'}
                </span>
              );
            }}
          </Item>
        );
      },
    },
    {
      title: (
        <FormattedMessage
          id="sales.createForm.discount.label"
          defaultMessage="Discount"
        />
      ),
      dataIndex: 'discount',
      key: 'discount',
      render: (_value: any, _record: any, namePrefix: number) => {
        return (
          <Item
            name={
              namePrefix !== undefined ? [namePrefix, `discount`] : `discount`
            }
          >
            <Input
              name={`${namePrefix}discount`}
              type={'number'}
              style={{ minWidth: 80 }}
              min={'0'}
              step={'1'}
              max={'100'}
              placeholder={intl.formatMessage({
                id: 'sales.createForm.discount.label',
                defaultMessage: 'Discount (%)',
              })}
            />
          </Item>
        );
      },
    },
  ];

  return (
    <StyledTable
      dataSource={dataSource}
      columns={columns}
      tableLayout="auto"
      pagination={false}
      rowKey={(_product: any, index) => index as number}
      summary={() => (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={columns.length - 1}>
              <FormattedMessage
                id="sales.createForm.summary.label"
                defaultMessage="Summary"
              />
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1}>
              {formValues?.products?.reduce(
                (previousValue: number, product: any) => {
                  const sum =
                    (product.amount *
                      product.price *
                      (100 - product.discount)) /
                    100;
                  return previousValue + (sum || 0);
                },
                0,
              )}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
      expandable={{
        expandedRowKeys: dataSource.map((_el, index) => index),
        showExpandColumn: false,
        expandedRowRender: (_record: any, namePrefix: number) => {
          return (
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <Item
                style={{ width: '100%' }}
                name={
                  namePrefix !== undefined
                    ? [namePrefix, `description`]
                    : `description`
                }
                label={
                  <FormattedMessage
                    id="products.form.description.label"
                    defaultMessage="Description"
                  />
                }
                rules={[
                  {
                    type: 'string',
                    max: 500,
                    message: (
                      <FormattedMessage
                        id="products.form.description.error"
                        defaultMessage="Description cannot be longer than 500 characters"
                      />
                    ),
                  },
                ]}
              >
                <TextArea
                  name={`${namePrefix}description`}
                  placeholder={intl.formatMessage({
                    id: 'products.form.description.placeholder',
                    defaultMessage: 'Maximum 500 characters...',
                  })}
                  readOnly={readOnly}
                />
              </Item>
              <StyledDeliveryDateItem
                name={
                  namePrefix !== undefined
                    ? [namePrefix, `deliveryDate`]
                    : `deliveryDate`
                }
                label={
                  <FormattedMessage
                    id="sales.createForm.deliveryDate.label"
                    defaultMessage="Delivery Date"
                  />
                }
              >
                <DatePicker
                  style={{
                    maxWidth: '100%',
                    flex: 1,
                  }}
                  placeholder={intl.formatMessage({
                    id: 'tasks.form.duedate.placeholder',
                    defaultMessage: 'Select date',
                  })}
                />
              </StyledDeliveryDateItem>
            </div>
          );
        },
      }}
    />
  );
};
