import React, { FC, useEffect, useState } from 'react';
import { Avatar, Space, Spin } from 'antd';
import { fetchUser } from '@services/users';
import { Contact, getContact } from '@apps/contacts/services';

export type UserEntryType = {
  name: string;
  avatar: string;
};

type UserEntryProps = {
  userEntry?: UserEntryType;
  id: string;
  type: 'user' | 'contact';
  onFetch(userEntry: UserEntryType): void;
};

const UserEntry: FC<UserEntryProps> = ({ userEntry, onFetch, type, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserEntryType | undefined>(
    userEntry,
  );

  useEffect(() => {
    if (!id && !userEntry) {
      return;
    }

    if (!userEntry) {
      setIsLoading(true);
      const promise: Promise<any> =
        type === 'user' ? fetchUser(id) : getContact(id);
      promise
        .then((resource) => {
          if (resource.type) {
            const { type, avatar, companyName, firstName, lastName } =
              resource as Contact;
            const newUserEntry: UserEntryType = {
              avatar: avatar || '',
              name:
                type === 'company'
                  ? `${companyName}`
                  : `${firstName} ${lastName}`,
            };
            setCurrentUser(newUserEntry);
            onFetch(newUserEntry);
            return;
          }
          const { photoURL, displayName } = resource as User;
          const newUserEntry: UserEntryType = {
            avatar: photoURL || '',
            name: displayName,
          };
          setCurrentUser(newUserEntry);
          onFetch(newUserEntry);
          return;
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id, onFetch, type, userEntry]);

  return (
    <Space direction="horizontal" size="small">
      {isLoading && <Spin spinning={true} />}
      {currentUser?.avatar && <Avatar src={currentUser.avatar} />}
      {currentUser?.name && <span>{currentUser.name}</span>}
    </Space>
  );
};

export default UserEntry;
