import React, { FC, useMemo, useCallback, useContext } from 'react';
import {
  createProductPrice,
  ProductPrice,
  updateProductPrice,
} from '@apps/products/services';
import { Form, Modal } from 'antd';
import currency from 'currency.js';
import intl from '../../../../../../intl';
import PriceForm from '@apps/sales/widgets/SalesTab/Pricing/PriceForm';
import removeUndefined from '@core/helpers/removeUndefined';
import moment from 'moment';
import SalesTabContext from '@apps/sales/widgets/SalesTab/context';

type PriceModalProps = {
  visible: boolean;
  price: ProductPrice | null;
  onCancel(): void;
  onOk(): void;
};

const PriceModal: FC<PriceModalProps> = ({
  price,
  visible,
  onOk,
  onCancel,
}) => {
  const { ecosystem, productId } = useContext(SalesTabContext);
  const [form] = Form.useForm<ProductPrice>();

  const okText = useMemo(() => {
    if (price && price.id) {
      return intl.formatMessage({
        id: 'button.save',
        defaultMessage: 'Save',
      });
    }
    return intl.formatMessage({
      id: 'button.create',
      defaultMessage: 'Create',
    });
  }, [price]);

  const cancelText = useMemo(() => {
    return intl.formatMessage({
      id: 'button.cancel',
      defaultMessage: 'Cancel',
    });
  }, []);

  const modalTitle = useMemo(() => {
    return !price
      ? intl.formatMessage({
          id: 'sales.salesTab.addPrice.title',
          defaultMessage: 'Add a new price',
        })
      : intl.formatMessage({
          id: 'sales.salesTab.editPrice.title',
          defaultMessage: 'Edit price',
        });
  }, [price]);

  const handleOk = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        let formValues = removeUndefined(values) as ProductPrice;
        const priceConstraints = removeUndefined(
          formValues?.constraints,
        ) as ProductPrice['constraints'];
        const validDateRange = priceConstraints.validDateRange
          ? [
              moment(priceConstraints.validDateRange[0]).valueOf(),
              moment(priceConstraints.validDateRange[1]).valueOf(),
            ]
          : null;
        formValues = {
          ...formValues,
          unitAmount: currency(formValues.unitAmount).value,
          constraints: {
            ...priceConstraints,
            validDateRange,
          },
        };
        if (price && price.id) {
          //update price
          updateProductPrice(price.id, { ...price, ...formValues })
            ?.then(() => {
              form.resetFields();
              onOk();
            })
            ?.then(() => {
              form.resetFields();
              onOk();
            });
        } else {
          //create price
          if (ecosystem && ecosystem.id && productId) {
            createProductPrice({
              ...formValues,
              ecosystemId: ecosystem?.id,
              productId,
            })?.then(() => {
              form.resetFields();
              onOk();
            });
          }
        }
      })
      .catch((info) => {
        console.warn('Validation failed: ', info);
      });
  }, [ecosystem, form, onOk, price, productId]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const initialValues = useMemo(() => {
    return price
      ? {
          ...price,
          currency: price.currency || ecosystem?.currency || 'EUR',
          constraints: {
            ...price.constraints,
            validDateRange: price.constraints?.validDateRange
              ? [
                  moment(price.constraints?.validDateRange[0]),
                  moment(price.constraints?.validDateRange[1]),
                ]
              : undefined,
          },
        }
      : {
          model: 'standard',
          currency: ecosystem?.currency || 'EUR',
          isTaxInclusive: true,
          isActive: false,
          isArchived: false,
          productId,
        };
  }, [ecosystem?.currency, price, productId]);

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={handleCancel}
      title={modalTitle}
      width="60vw"
    >
      <PriceForm
        form={form}
        layout="vertical"
        name="priceForm"
        initialValues={initialValues}
      />
    </Modal>
  );
};

export default PriceModal;
