import React, { FC } from 'react';
import { Form, Input } from 'antd';
import intl from '../../../../../../intl';

const { Item } = Form;

const SimpleProductNumber: FC = () => {
  return (
    <Item
      name="productNumber"
      label={intl.formatMessage({
        id: 'products.form.productnumber.label',
        defaultMessage: 'Product number',
      })}
      required
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'products.form.productnumber.error.missing',
            defaultMessage: 'Product number is missing',
          }),
        },
        {
          validator: (_rule, value: string) => {
            return new Promise((resolve, reject) => {
              if (value) {
                if (value.includes('/')) {
                  return reject(
                    intl.formatMessage({
                      id: 'product.number.syntax.error',
                      defaultMessage: 'Cannot use / in product number',
                    }),
                  );
                } else {
                  return resolve('');
                }
              }
            });
          },
        },
      ]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'products.form.productnumber.placeholder',
          defaultMessage: 'Product number...',
        })}
        autoComplete="nope"
      />
    </Item>
  );
};

export default SimpleProductNumber;
