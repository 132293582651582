import { getWithId } from '@services/api';
import {
  BATCH_NUMBERS,
  BatchNumberEntry,
} from '@apps/warehouse/services/namespace';

const getBatchNumber = (id: string) =>
  getWithId(`${BATCH_NUMBERS}/${id}`) as Promise<BatchNumberEntry>;

export default getBatchNumber;
