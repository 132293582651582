import { Consultant } from '@apps/directSales/mainMenu/Consultants/services/namespace';
import { firebase } from '@services/Firebase';
import moment from 'moment';
import createConsultant from './createConsultant';

type ConsultantId = string;
type ConsultantParentId = string;

const createConsultants = async (
  consultants: Partial<Consultant>[],
  ecosystemId: string,
) => {
  const consultantParentMap: Record<ConsultantId, ConsultantParentId> = {};

  const promises = await (async () => {
    const promises = [];

    for (const consultant of consultants) {
      const { consultantParent, consultantNo } = consultant;
      if (consultantNo && consultantParent) {
        consultantParentMap[consultantNo] = consultantParent;
      }

      if (!consultant.applicationDate) {
        delete consultant.applicationDate;
      } else {
        const applicationDate = moment(
          consultant.applicationDate,
          moment.defaultFormat,
        );
        consultant.applicationDate = applicationDate.format(
          moment.defaultFormat,
        );
      }

      if (!consultant.consultantBirthday) {
        delete consultant.consultantBirthday;
      } else {
        consultant.consultantBirthday = moment(
          consultant.consultantBirthday,
        ).toISOString();
      }

      const isNewConsultant = (
        await getConsultantSnapByNoAndEcosystem(consultantNo!, ecosystemId)
      )?.empty;

      if (!isNewConsultant) {
        throw new Error(
          `Consultant ${consultant.consultantName} ${consultant.consultantSurname} with consultant number ${consultantNo} already exists within your organization.`,
        );
      }

      promises.push(createConsultant(consultant));
    }

    return promises;
  })();

  const [results] = await Promise.all(promises);

  const batch = firebase.firestore?.batch();

  if (Object.keys(consultantParentMap).length > 0) {
    for (const [kidId, parentId] of Object.entries(consultantParentMap)) {
      const kidSnapshot = (
        await getConsultantSnapByNoAndEcosystem(kidId, ecosystemId)
      )?.docs[0];

      const parentSnapshot = (
        await getConsultantSnapByNoAndEcosystem(parentId, ecosystemId)
      )?.docs[0];

      if (kidSnapshot && parentSnapshot) {
        batch?.set(
          kidSnapshot.ref,
          { consultantParent: parentSnapshot.id },
          { merge: true },
        );
      }
    }
  }

  await batch?.commit();

  return results;
};

export default createConsultants;

const getConsultantSnapByNoAndEcosystem = async (
  consultantNo: string,
  ecosystemId: string,
) => {
  return await firebase.firestore
    ?.collection('consultants')
    .where('consultantNo', '==', consultantNo)
    .where('ecosystem', '==', ecosystemId)
    .get();
};
