import React, { FC } from 'react';
import { Form, Collapse } from 'antd';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { Document } from '@apps/documents/service/namespace';
import { FormattedMessage } from 'react-intl/lib';
import { getName } from '@core/helpers/documents';
import { IntlShape, useIntl } from 'react-intl';

const { Panel } = Collapse;

const getNamesFromDocs = (value: Document[], intl: IntlShape) => {
  if (!value) {
    return [];
  }

  return value.map((doc) => {
    return getName(doc, intl);
  });
};

const RelatedDocuments: FC<FieldProps> = ({ field, ...rest }) => {
  const intl = useIntl();
  const { getFieldValue } = useFormContext();
  const { fieldName, label, className, style } = field;
  const value = getFieldValue(fieldName);
  const names = getNamesFromDocs(
    value && value.length ? value : rest.initialValue || [],
    intl,
  );

  return (
    <Form.Item
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      colon={false}
      labelAlign="left"
      shouldUpdate
    >
      {() => {
        return names.map((name, ind) => (
          <Collapse key={name}>
            <Panel
              key={ind}
              className={className}
              style={style}
              header={name}
              showArrow={false}
              disabled
            />
          </Collapse>
        ));
      }}
    </Form.Item>
  );
};

export default RelatedDocuments;
