import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const Documents = lazy(
  () => import(/* webpackChunkName: "documentsApp" */ './Documents'),
);

const Inbox = lazy(
  () => import(/* webpackChunkName: "documentsApp" */ './Inbox'),
);

const Archive = lazy(
  () => import(/* webpackChunkName: "documentsApp" */ './Archive'),
);

export default {
  appSubmenuName: 'app.documents.mainMenu.name',
  icon: Icon.FileOutlined,
  component: Documents,
  sections: [
    {
      link: 'inbox',
      title: 'app.documents.mainMenu.inbox.title',
      component: Inbox,
      icon: Icon.UploadOutlined,
    },
    {
      link: 'archive',
      title: 'app.documents.mainMenu.archive.title',
      component: Archive,
      icon: Icon.FileOutlined,
    },
  ],
};
