import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import DetailedContact from '@apps/contacts/mainMenu/components/DetailedContact';
import {
  CompanyContact,
  getContact,
  PersonContact,
  updateContact,
} from '@apps/contacts/services';
import ContactsAppConfig, { CONTACTS_ACTION } from '@apps/contacts';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

type Props = RouteComponentProps & {
  match: {
    params: {
      id: string;
    };
  };
};

const Edit: React.FC<Props> = (props) => {
  const id = props.match.params.id;
  const [details, setDetails] = useState<Partial<
    PersonContact & CompanyContact
  > | null>(null);
  const intl = useIntl();
  useEffect(() => {
    getContact(id)?.then(setDetails);
  }, [id]);
  const action = 'update-contacts' as CONTACTS_ACTION;
  const canProceed = details
    ? checkUserPermissions(
        rootStore.getState(),
        ContactsAppConfig.todixId,
        action,
        details.ecosystem as string,
      )
    : false;
  if (!canProceed) {
    return <Redirect to={`/app/${ContactsAppConfig.todixId}/${id}`} />;
  }
  return (
    <DetailedContact
      id={id}
      initialValues={details as Partial<PersonContact & CompanyContact>}
      onSubmit={(values: object) => {
        if (canProceed) {
          updateContact(id, values)
            ?.then(() => {
              props.history.push(`/app/${ContactsAppConfig.todixId}`);
              // message.success(
              //   intl.formatMessage({
              //     id: 'contacts.edit.success',
              //     defaultMessage: 'Contact updated',
              //   }),
              // );
            })
            .catch(() => {
              message.error(
                intl.formatMessage({
                  id: 'contacts.edit.error',
                  defaultMessage: 'Error while contact creating',
                }),
              );
            });
        }
      }}
      readOnly={!canProceed}
      action={action}
    />
  );
};

export default Edit;
