import React, { FC, useState, useCallback, useEffect } from 'react';
import { Form as aForm, Button } from 'antd';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { FormattedMessage } from 'react-intl';
import AppConfig, { TASKS_ACTION } from '@apps/tasks';

import SpinContainer from '@components/SpinContainer';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import { getTask, Task } from '@apps/tasks/services';
import SelectedEcosystem from '@components/SelectedEcosystem';
import { initialTags } from '../SelectedTags';
import SubTaskForm from '@apps/tasks/mainMenu/components/Form/SubTaskForm';
import Assignee from '@apps/tasks/mainMenu/components/Form/Assignee';
import Followers from '@apps/tasks/mainMenu/components/Form/Followers';
import Title from '@apps/tasks/mainMenu/components/Form/Title';
import Description from '@apps/tasks/mainMenu/components/Form/Description';
import Status from '@apps/tasks/mainMenu/components/Form/Status';
import Progress from '@apps/tasks/mainMenu/components/Form/Progress';
import DueDate from '@apps/tasks/mainMenu/components/Form/DueDate';
import CreationDate from '@apps/tasks/mainMenu/components/Form/CreationDate';
import Estimation from '@apps/tasks/mainMenu/components/Form/Estimation';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';
import Tasks from '@apps/tasks/mainMenu/components/Form/Tasks';
import SelectTasks from '@apps/tasks/mainMenu/components/Form/SelectTasks';
import createRelation from '@services/entityRelations/createRelation';
import removeRelation from '@services/entityRelations/removeRelation';
import FormContainer from '@components/FormContainer';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

const { Item } = aForm;

const defaultValues: Partial<Task> = {
  ...initialTags,
};

type Relations = Record<string, string>;

export type FormProps = {
  id?: string;
  initialValues: Partial<Task>;
  onSubmit?: (v: Task) => void;
  onEcosystemChange?: (id: string) => void;
  readOnly?: boolean;
  action: TASKS_ACTION;
};

export const Form: FC<FormProps> = ({
  id,
  initialValues: initialValuesProp,
  onSubmit,
  readOnly = true,
  onEcosystemChange,
  action,
}) => {
  const [form] = useForm();
  const canProceed = initialValuesProp?.ecosystem
    ? checkUserPermissions(
        rootStore.getState(),
        AppConfig.todixId,
        action,
        initialValuesProp?.ecosystem as string,
      )
    : false;
  const [relations, setRelations] = useState<Relations>({});
  const [subTasks, setSubTasks] = useState<Partial<Task>[]>([]);
  const [selectedSubtask, setSelectedSubtask] = useState<Partial<Task> | null>(
    null,
  );
  const [isProgressEnabled, setIsProgressEnabled] = useState(
    initialValuesProp.status && initialValuesProp.status !== 'done',
  );
  const [isSubTaskFormVisible, setIsSubTaskFormVisible] = useState(false);
  const closeSubTaskForm = useCallback(() => {
    setIsSubTaskFormVisible(false);
  }, []);
  const handleSubtaskSubmission = useCallback(() => {
    if (id) {
      getChildrenForParent({
        parentId: id,
        childType: 'task',
        ecosystem: form.getFieldValue('ecosystem'),
      }).then((entities) => {
        setRelations(
          entities.reduce((acc, item) => {
            return {
              ...acc,
              [item.childId]: item.id as string,
            };
          }, {} as Relations),
        );
        const promises = entities.map((entity) => {
          return getTask(entity.childId);
        });
        Promise.all(promises).then((children) => {
          setSubTasks(children);
        });
      });
    }
  }, [form, id]);
  const handleSubTaskDelete = useCallback(
    (subTaskId: string) => {
      setSubTasks(subTasks.filter((subTask) => subTask?.id !== subTaskId));
    },
    [subTasks],
  );

  useEffect(() => {
    if (id) {
      getChildrenForParent({
        parentId: id,
        childType: 'task',
        ecosystem: form.getFieldValue('ecosystem'),
      }).then((entities) => {
        setRelations(
          entities.reduce((acc, item) => {
            return {
              ...acc,
              [item.childId]: item.id as string,
            };
          }, {} as Relations),
        );
        const promises = entities.map((entity) => {
          return getTask(entity.childId);
        });
        Promise.all(promises).then((children) => {
          setSubTasks(children);
        });
      });
    }
  }, [form, id]);

  const handleTaskSelect = useCallback(
    (taskId) => {
      if (id && canProceed) {
        if (!relations[taskId]) {
          createRelation({
            parentId: id,
            parentType: 'task',
            parentRefCollection: 'tasks',
            childId: taskId,
            childType: 'task',
            childRefCollection: 'tasks',
            ecosystem: form.getFieldValue('ecosystem'),
          }).then(() => {
            handleSubtaskSubmission();
          });
        }
      }
    },
    [canProceed, form, handleSubtaskSubmission, id, relations],
  );

  const handleTaskDeSelect = useCallback(
    (taskId) => {
      if (id && canProceed) {
        if (relations[taskId]) {
          removeRelation(relations[taskId]).then(() => {
            handleSubtaskSubmission();
          });
        }
      }
    },
    [canProceed, handleSubtaskSubmission, id, relations],
  );

  const handleSubmit = useCallback(
    (values) => {
      if (onSubmit && canProceed) {
        const parsedValues = { ...values };
        if (parsedValues.dueDate) {
          parsedValues.dueDate = parsedValues.dueDate.format();
        }
        onSubmit(parsedValues);
      }
    },
    [canProceed, onSubmit],
  );

  const handleValuesChange = useCallback(
    (changedValues: { status: string }) => {
      if (changedValues.status === 'done') {
        form.setFieldsValue({ progress: '100' });
        setIsProgressEnabled(false);
      } else if (changedValues.status) {
        setIsProgressEnabled(true);
      }
    },
    [form],
  );

  if (initialValuesProp === null) {
    return <SpinContainer />;
  }

  const initialValues = {
    ...defaultValues,
    ...initialValuesProp,
    creationDate: initialValuesProp.creationDate
      ? moment(initialValuesProp.creationDate, moment.defaultFormat)
      : moment(),
    dueDate:
      initialValuesProp.dueDate &&
      moment(initialValuesProp.dueDate, moment.defaultFormat),
    progress: initialValuesProp.progress ? initialValuesProp.progress : '0',
    assignee: initialValuesProp.assignee || '',
    followers: initialValuesProp.followers || [],
  };

  const canBeProceed = readOnly || !canProceed;

  return (
    <>
      <AppEntityForm
        elId={id}
        appId={AppConfig.todixId}
        initialValues={initialValues}
        providedForm={form}
        onSubmit={handleSubmit}
        onValuesChange={handleValuesChange}
        readOnly={canBeProceed}
        disabledEditButton={canBeProceed}
        disabledSubmitButton={canBeProceed}
      >
        {() => {
          return (
            <FormContainer>
              <div>
                <SelectedEcosystem
                  appId={AppConfig.todixId}
                  onChange={(id: string) => {
                    if (onEcosystemChange) {
                      onEcosystemChange(id);
                    }
                  }}
                  action={action}
                />
                <Title readOnly={canBeProceed} />
                <Description readOnly={canBeProceed} />
                <Status readOnly={canBeProceed} />
                <Progress
                  isProgressEnabled={isProgressEnabled || false}
                  readOnly={canBeProceed}
                />
                <DueDate readOnly={canBeProceed} />
                <CreationDate />
                <Estimation readOnly={canBeProceed} />
                <Item name="creator" />
              </div>
              <div>
                <Assignee readOnly={canBeProceed} />
                <Followers readOnly={canBeProceed} />
                {!canBeProceed && id && (
                  <Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        <SelectTasks
                          readOnly={canBeProceed}
                          subTasks={subTasks}
                          parentId={id}
                          parentType="task"
                          ecosystem={getFieldValue('ecosystem')}
                          handleTaskSelect={handleTaskSelect}
                          handleTaskDeselect={handleTaskDeSelect}
                          handleClear={() => console.warn('clear')}
                        />
                      );
                    }}
                  </Item>
                )}
                <Item noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Tasks
                      parentType="task"
                      ecosystem={getFieldValue('ecosystem')}
                      id={id}
                      readOnly={canBeProceed}
                      tasks={subTasks}
                      onSubtaskClick={(subTask) => {
                        setSelectedSubtask(subTask);
                        setIsSubTaskFormVisible(true);
                      }}
                    />
                  )}
                </Item>

                {!canBeProceed && id && (
                  <Item>
                    <Button
                      type="primary"
                      onClick={() => setIsSubTaskFormVisible(true)}
                      disabled={isSubTaskFormVisible}
                    >
                      <FormattedMessage
                        id="tasks.form.create.subtask.button"
                        defaultMessage="Create sub task"
                      />
                    </Button>
                  </Item>
                )}
              </div>
            </FormContainer>
          );
        }}
      </AppEntityForm>
      {id && (
        <SubTaskForm
          parentId={id}
          ecosystem={form.getFieldValue('ecosystem')}
          selectedSubtask={selectedSubtask}
          isVisible={isSubTaskFormVisible}
          onClose={closeSubTaskForm}
          onSubmit={handleSubtaskSubmission}
          onDelete={handleSubTaskDelete}
          readOnly={canBeProceed}
        />
      )}
    </>
  );
};
