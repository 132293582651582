import { styled } from '@styles/themes';

export const StyledInnerLabel = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.gray};
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
