import { post } from '@services/api';
import {
  Purchase,
  PURCHASES_COLLECTION,
} from '@apps/purchases/services/namespace';
import moment from 'moment';

const createPurchase = (purchase: Purchase) => {
  return post(`${PURCHASES_COLLECTION}`, {
    ...purchase,
    products: purchase.products.map((product) =>
      JSON.parse(JSON.stringify(product)),
    ),
    contact: JSON.parse(JSON.stringify(purchase.contact)),
    timestamp: moment().valueOf(),
    contactSnapshot: JSON.parse(JSON.stringify(purchase.contactSnapshot)),
  } as Purchase);
};

export default createPurchase;
