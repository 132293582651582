import React, { FC } from 'react';
import { Popover } from 'antd';
import { Sale } from '@apps/sales/services';
import calculateTotalsForProducts from '@apps/sales/mainMenu/components/SaleCreator/calculateTotalsForProducts';
import { useIntl } from 'react-intl';
import {
  PriceGrid,
  PriceLabel,
  PriceValue,
} from '@apps/sales/widgets/styles.sc';
import withSuffix from '@core/helpers/withSuffix';

type Props = {
  value?: Sale['products'];
};

const SumCellRenderer: FC<Props> = ({ value }) => {
  const intl = useIntl();
  if (!value) {
    return null;
  }
  const { total, totalNetPrice, taxes, totalDiscountedPrice } =
    calculateTotalsForProducts(value);

  const content = () => {
    return (
      <PriceGrid>
        <PriceLabel>
          {intl.formatMessage({
            id: 'sales.createForm.sumNet.label',
            defaultMessage: 'Sum net',
          })}
        </PriceLabel>
        <PriceValue>
          {withSuffix({
            value: totalNetPrice
              ? `${totalNetPrice}`.replaceAll(',', '').replaceAll('.', ',')
              : '',
          })}
        </PriceValue>
        {Object.keys(taxes)
          .sort()
          .map((tax, index) => {
            return (
              <React.Fragment key={index}>
                <PriceLabel>{`${intl.formatMessage({
                  id: 'sales.salesTab.pricePreview.tax',
                  defaultMessage: 'Tax',
                })} (${tax} %)`}</PriceLabel>
                <PriceValue>
                  {withSuffix({
                    value: taxes[tax]
                      ? `${taxes[tax]}`.replaceAll(',', '').replaceAll('.', ',')
                      : '',
                  })}
                </PriceValue>
              </React.Fragment>
            );
          })}

        <PriceLabel>
          {intl.formatMessage({
            id: 'sales.createForm.discountedPrice.label',
            defaultMessage: 'Discounted price',
          })}
        </PriceLabel>
        <PriceValue>
          {withSuffix({
            value: totalDiscountedPrice
              ? `${totalDiscountedPrice}`
                  .replaceAll(',', '')
                  .replaceAll('.', ',')
              : '',
          })}
        </PriceValue>
      </PriceGrid>
    );
  };
  return (
    <Popover content={content}>
      {withSuffix({
        value: total ? `${total}`.replaceAll(',', '').replaceAll('.', ',') : '',
      })}
    </Popover>
  );
};

export default SumCellRenderer;
