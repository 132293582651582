import {
  EntityRelation,
  ENTITY_RELATIONS_COLLECTION,
} from '@services/entityRelations/namespace';
import { post } from '@services/api';
import moment from 'moment';

const createRelation = (relation: EntityRelation) => {
  return post(
    `${ENTITY_RELATIONS_COLLECTION}`,
    {
      ...relation,
      timestamp: moment().valueOf(),
    },
    {
      message: false,
    },
  );
};

export default createRelation;
