import React, { FC, useState, useCallback } from 'react';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { ProductContactConstraint } from '@apps/products/services';
import { useForm } from 'antd/lib/form/Form';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Card } from '@todix/ui-components';
import Tabs from '@components/Tabs';
import intl from '../../../../../intl';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import SimpleProductNumber from '@apps/products/mainMenu/components/DetailedProduct/SimpleProductNumber';
import SimpleProductName from '@apps/products/mainMenu/components/DetailedProduct/SimpleProductName';
import SelectedUnit from '@apps/products/mainMenu/components/SelectedUnit';
import { Button, Form, message, Spin } from 'antd';
import Supplier from '@apps/purchases/mainMenu/individualRequestsList/Supplier';
import Price from '@apps/purchases/mainMenu/individualRequestsList/Price';
import NeedAmount from '@apps/purchases/mainMenu/components/individualRequest/NeedAmount';
import NeedDate from '@apps/purchases/mainMenu/components/individualRequest/NeedDate';
import moment from 'moment';
import { Document } from '@apps/documents/service';
import {
  ButtonContainerModal,
  PushToInnerContainer,
  TopContainerPushTo,
} from '@apps/purchases/mainMenu/components/styles.sc';
import PurchaseStep from '@apps/purchases/mainMenu/components/PurchaseStep';
import {
  Bucket,
  BucketPosition,
  BucketStep,
  createBucket,
  getBucketsPerDetails,
  updateBucket,
} from '@apps/purchases/services/buckets';
import { ListEvents } from '@components/List';
import {
  createIndividualPurchaseRequest,
  updateIndividualPurchaseRequest,
} from '@apps/purchases/services/individualPurchaseRequests';
import ExpectedDeliveryDate from '@apps/purchases/mainMenu/individualRequestsList/ExpectedDeliveryDate';
import DescriptionWithIndicator from '@apps/purchases/mainMenu/individualRequestsList/DescriptionWithIndicator';
import InvoiceData from '@apps/purchases/mainMenu/individualRequestsList/InvoiceData';
import Tax from '@apps/purchases/mainMenu/individualRequestsList/Tax';
import PositionTotal from '@apps/purchases/mainMenu/individualRequestsList/PositionTotal';
import { getDocument } from '@apps/documents/service';
import createRelation from '@services/entityRelations/createRelation';
import CostCenter from '@apps/purchases/mainMenu/components/individualRequest/CostCenter';
import ProjectSelector from '@apps/purchases/mainMenu/individualRequestsList/ProjectSelector';
import { updatePositionMonitor } from '@apps/purchases/services/positionMonitor';

const { TabPane } = Tabs;

const { Item } = Form;

export type PushToEventName = 'pushTo';

export type PushToEventPayload = {
  request: IndividualPurchaseRequest;
  constraints: ProductContactConstraint[];
  allowingTypes: BucketStep[];
};

type PushToProps = {};

const PushTo: FC<PushToProps> = () => {
  const [form] = useForm();
  const [request, setRequest] = useState<IndividualPurchaseRequest | null>(
    null,
  );
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [constraints, setConstraints] = useState<ProductContactConstraint[]>(
    [],
  );
  const [constraintPrice, setConstraintPrice] = useState<number | null>(null);
  const [bucket, setBucket] = useState<Bucket | null>(null);
  const [allowingTypes, setAllowingTypes] = useState<BucketStep[]>([]);

  const calculateTotalsForBucket = useCallback(
    (selectedBucket?: Bucket) => {
      const positionSum =
        selectedBucket?.positions.reduce((acc, el) => {
          let posSum = el.unitAmount * el.needAmount;
          if (el.tax) {
            posSum += posSum * (el.tax / 100);
          }
          return (acc += posSum);
        }, 0) || 0;
      const unitAmount = form.getFieldValue('unitAmount') || 0;
      const needAmount = form.getFieldValue('needAmount') || 0;
      const tax = form.getFieldValue('tax') || 0;
      const totalPosition = needAmount * unitAmount;
      const totalTaxPosition = tax ? totalPosition * (tax / 100) : 0;
      const totalGrossPosition = totalPosition + totalTaxPosition;
      form.setFieldsValue({
        ...form.getFieldsValue(),
        totalPosition,
        totalTaxPosition,
        totalGrossPosition,
        positionSum: positionSum + totalGrossPosition,
      });
    },
    [form],
  );

  const calculateTotals = useCallback(() => {
    const stepType = form.getFieldValue('stepType');
    if (stepType === 'invoiced') {
      let positionSum = 0;
      if (bucket) {
        positionSum = bucket.positions.reduce((acc, el) => {
          let posSum = el.unitAmount * el.needAmount;
          if (el.tax) {
            posSum += posSum * (el.tax / 100);
          }
          return (acc += posSum);
        }, 0);
      }
      const unitAmount = form.getFieldValue('unitAmount') || 0;
      const needAmount = form.getFieldValue('needAmount') || 0;
      const tax = form.getFieldValue('tax') || 0;
      const totalPosition = needAmount * unitAmount;
      const totalTaxPosition = tax ? totalPosition * (tax / 100) : 0;
      const totalGrossPosition = totalPosition + totalTaxPosition;
      form.setFieldsValue({
        ...form.getFieldsValue(),
        totalPosition,
        totalTaxPosition,
        totalGrossPosition,
        positionSum: positionSum + totalGrossPosition,
      });
    }
  }, [bucket, form]);

  useCustomEventListener<PushToEventName, PushToEventPayload>(
    'pushTo',
    async (data) => {
      if (data && data.request && data.constraints && data.allowingTypes) {
        setLoading(true);
        setAllowingTypes(data.allowingTypes);
        setRequest(data.request);
        setConstraints(data.constraints);
        if (data.constraints) {
          const defaultConstraint = data.constraints.find((el) => el.isDefault);
          const selectedConstraint = defaultConstraint || data.constraints[0];
          if (selectedConstraint) {
            const expectedDeliveryDate = moment().add(
              Number(selectedConstraint.standardDeliveryTime),
              'days',
            );
            form.setFieldsValue({
              ...form.getFieldsValue(),
              costCenter:
                selectedConstraint.costCenter || data.request.costCenter,
              productNumber: data.request.productNumber,
              productName: data.request.productName,
              productId: data.request.productId,
              relatedContact: selectedConstraint.relatedContact,
              relatedContactSnapshot: selectedConstraint.relatedContactSnapshot,
              unit: selectedConstraint.unit,
              unitAmount: selectedConstraint.unitAmount,
              tax: selectedConstraint.taxValue,
              currency: selectedConstraint.currency,
              standardDeliveryTime: selectedConstraint.standardDeliveryTime,
              expectedDeliveryDate,
              description: data.request.description,
              stepType: data.allowingTypes[0],
              needDate: data.request?.needDate
                ? moment(data.request?.needDate)
                : null,
            });

            if (selectedConstraint.relatedContact && data.request.ecosystem) {
              //find and set related bucket
              const openedBuckets = await getBucketsPerDetails({
                status: data.request?.status || 'draft',
                stepType: data.request?.stepType || 'ordered',
                relatedContact: selectedConstraint.relatedContact,
                ecosystem: data.request?.ecosystem as string,
              });

              if (openedBuckets.length) {
                const selectedBucket = openedBuckets[0];
                setBucket(selectedBucket);
                if (data.allowingTypes[0] === 'invoiced') {
                  const positions = selectedBucket.positions.reduce(
                    (acc, el) => {
                      let posSum = el.unitAmount * el.needAmount;
                      if (el.tax) {
                        posSum += posSum + posSum * (el.tax / 100);
                      }
                      return (acc += posSum);
                    },
                    0,
                  );
                  form.setFieldsValue({
                    ...form.getFieldsValue(),
                    positionSum: positions,
                  });
                }
              } else {
                setBucket(null);
              }
              setLoading(false);
              setShow(true);
            }
          }
        }
      }
    },
  );

  const onSubmit = useCallback(
    async (values: any) => {
      if (!request) {
        return;
      }
      setLoading(true);

      if (values.stepType === 'cancelled') {
        try {
          let requestId = request.id;
          if (requestId) {
            await updateIndividualPurchaseRequest(requestId, {
              ...request,
              status: 'cancelled',
              stepType: 'cancelled',
              project: values.project || '',
            });

            emitCustomEvent<ListEvents>('refreshList');
            message.success(
              intl.formatMessage({
                id: 'request.cancelled',
                defaultMessage: 'Request has been cancelled',
              }),
            );
            setBucket(null);
            form.setFieldsValue({});
            setLoading(false);
            setShow(false);
            return;
          }
        } catch (e) {
          console.error(e);
          setLoading(false);
          message.error(
            intl.formatMessage({
              id: 'error.request',
              defaultMessage: 'Error while processing request',
            }),
          );
          return;
        }
      }

      const bucketPosition: BucketPosition = {
        creationDate: request.creationDate,
        productName: values.productName,
        productNumber: values.productNumber,
        needAmount: values.needAmount,
        deliveredAmount: request.deliveredAmount | 0,
        needDate: values.needDate,
        expectedDeliveryDate: values.expectedDeliveryDate,
        currency: 'EUR',
        unitAmount: values.unitAmount,
        tax: values.tax,
        ecosystem: request.ecosystem as string,
        creator: request.creator,
        productId: request.productId,
        description: values.description || '',
        shortDescription: request?.shortDescription || '',
        unit: values.unit,
        id: request?.id,
        relatedDocumentId: values.relatedDocumentId || '',
        costCenter: values.costCenter || '',
        project: values.project || '',
        hasBatchNumber: request.hasBatchNumber,
        hasSerialNumber: request.hasSerialNumber,
      };
      if (bucket && request) {
        //update bucket (add position)
        try {
          let requestId = request.id;
          if (requestId) {
            await updateIndividualPurchaseRequest(requestId, {
              ...request,
              bucketId: bucket.id as string,
              status: bucket.status,
              stepType: bucket.stepType,
              relatedDocumentId: values.relatedDocumentId || '',
              project: values.project || '',
            });
          } else {
            requestId = await createIndividualPurchaseRequest({
              ...request,
              ...bucketPosition,
              bucketId: bucket.id as string,
              status: bucket.status,
              stepType: bucket.stepType,
              relatedDocumentId: values.relatedDocumentId || '',
              deliveredAmount: 0,
              project: values.project || '',
            });
          }
          const updatedBucket = bucket;
          updatedBucket.positions = [
            ...updatedBucket.positions,
            {
              ...bucketPosition,
              id: requestId,
            },
          ];
          await updateBucket(bucket.id as string, updatedBucket);

          emitCustomEvent<ListEvents>('refreshList');
          message.success(
            intl.formatMessage({
              id: 'bucket.updated',
              defaultMessage: 'Bucket has been updated',
            }),
          );
          setBucket(null);
          form.setFieldsValue({});
          setLoading(false);
          setShow(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          message.error(
            intl.formatMessage({
              id: 'error.request',
              defaultMessage: 'Error while processing request',
            }),
          );
        }
      } else {
        //create a new one
        const newBucket: Bucket = {
          draftSaved: false,
          type: 'supplier',
          status: 'draft',
          relatedContact: values.relatedContact,
          relatedContactSnapshot: values.relatedContactSnapshot,
          relatedDocumentId: values.relatedDocumentId || '',
          stepType: values.stepType,
          ecosystem: request?.ecosystem as string,
          positions: [bucketPosition],
        };
        try {
          const bucketId = await createBucket(newBucket);
          let requestId = request.id;
          if (requestId) {
            await updateIndividualPurchaseRequest(requestId, {
              ...request,
              bucketId: bucketId,
              status: newBucket.status,
              stepType: newBucket.stepType,
              relatedDocumentId: values.relatedDocumentId || '',
              project: values.project || '',
            });
          } else {
            requestId = await createIndividualPurchaseRequest({
              ...request,
              ...bucketPosition,
              bucketId: bucketId,
              status: newBucket.status,
              stepType: newBucket.stepType,
              relatedDocumentId: values.relatedDocumentId || '',
              deliveredAmount: 0,
              project: values.project || '',
            });
            const updatedBucket = newBucket;
            updatedBucket.positions = [
              {
                ...bucketPosition,
                id: requestId,
              },
            ];
            await updateBucket(bucketId as string, updatedBucket);
          }

          if (values.stepType === 'invoiced' && values.relatedDocumentId) {
            await createRelation({
              timestamp: moment().unix(),
              ecosystem: request.ecosystem as string,
              parentId: bucketId,
              parentType: 'bucket',
              parentRefCollection: 'buckets',
              childId: values.relatedDocumentId,
              childType: 'document',
              childRefCollection: 'documents',
            });

            const document = await getDocument(values.relatedDocumentId);
            if (document && document.payments && document.payments.length > 0) {
              const totalPayments = document.payments.reduce(
                (sum, payment) => sum + (payment.payAmount || 0),
                0,
              );
              const totalAmount = document.totalGrossAmount || 0;

              let paymentStatus = 'unpaid';
              if (totalPayments >= totalAmount) {
                paymentStatus = 'paid';
              } else if (totalPayments > 0) {
                paymentStatus = 'partlyPaid';
              }

              if (requestId) {
                await updatePositionMonitor(requestId, {
                  [paymentStatus]: {
                    status: 'draft',
                    relatedDocumentId: values.relatedDocumentId,
                    timestamp: moment(document.receivingDate).valueOf(),
                    bucketId: bucketId,
                  },
                });
              }
            }
          }

          emitCustomEvent<ListEvents>('refreshList');
          setBucket(null);
          form.setFieldsValue({});
          setLoading(false);
          setShow(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
          message.error(
            intl.formatMessage({
              id: 'error.request',
              defaultMessage: 'Error while processing request',
            }),
          );
        }
      }
    },
    [bucket, form, request],
  );

  const setLayoutByStepType = useCallback(
    (stepType: BucketStep) => {
      if (stepType === 'invoiced') {
        //set invoice layout
        form.setFieldsValue({
          ...form.getFieldsValue(),
        });
      }
    },
    [form],
  );

  const handleStepTypeSelect = useCallback(
    async (stepType: BucketStep) => {
      const formValues = form.getFieldsValue();
      const relatedContact = form.getFieldValue('relatedContact');
      const openedBuckets = await getBucketsPerDetails(
        stepType === 'invoiced'
          ? {
              status: 'draft',
              stepType,
              relatedContact,
              ecosystem: request?.ecosystem as string,
              relatedDocumentId: formValues.relatedDocumentId || '',
            }
          : {
              status: 'draft',
              stepType,
              relatedContact,
              ecosystem: request?.ecosystem as string,
            },
      );

      if (openedBuckets.length) {
        const selectedBucket = openedBuckets[0];
        setBucket(selectedBucket);
        if (stepType === 'invoiced') {
          const document = await getDocument(
            selectedBucket.relatedDocumentId || '',
          );
          if (document) {
            calculateTotalsForBucket(selectedBucket);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              documentNumber: document.docNumber,
              documentDate: document.creationDate,
              documentTotalAmount: document.totalGrossAmount || 0,
              relatedDocumentId: selectedBucket.relatedDocumentId || '',
            });
          }
        }
      } else {
        setBucket(null);
        calculateTotalsForBucket();
      }
      setLayoutByStepType(stepType);
    },
    [calculateTotalsForBucket, form, request?.ecosystem, setLayoutByStepType],
  );

  const handleSupplierSet = useCallback(async () => {
    const supplierId = form.getFieldValue('relatedContact');
    const stepType = form.getFieldValue('stepType');
    const relatedDocumentId = form.getFieldValue('relatedDocumentId');
    const constraint = constraints.find(
      (el) => el.relatedContact === supplierId,
    );
    if (constraint) {
      const formValues = form.getFieldsValue();
      form.setFieldsValue({
        ...formValues,
        unitAmount: constraint.unitAmount,
        tax: constraint.taxValue || 0,
        unit: constraint.unit,
        currency: constraint.currency,
        standardDeliveryTime: constraint.standardDeliveryTime,
        expectedDeliveryDate: null,
      });
      setConstraintPrice(constraint.unitAmount);
      //find and set related bucket
      const openedBuckets = await getBucketsPerDetails(
        stepType === 'invoiced'
          ? {
              status: 'draft',
              stepType: stepType,
              relatedContact: supplierId,
              ecosystem: request?.ecosystem as string,
              relatedDocumentId: relatedDocumentId || '',
            }
          : {
              status: 'draft',
              stepType: stepType,
              relatedContact: supplierId,
              ecosystem: request?.ecosystem as string,
            },
      );

      if (openedBuckets.length) {
        const selectedBucket = openedBuckets[0];
        setBucket(selectedBucket);
        if (formValues.stepType === 'invoiced') {
          const document = await getDocument(
            selectedBucket.relatedDocumentId || '',
          );
          if (document) {
            calculateTotalsForBucket(selectedBucket);
            form.setFieldsValue({
              ...form.getFieldsValue(),
              documentNumber: document.docNumber,
              documentDate: document.creationDate,
              documentTotalAmount: document.totalGrossAmount || 0,
              relatedDocumentId: selectedBucket.relatedDocumentId || '',
            });
          }
        }
      } else {
        setBucket(null);
        calculateTotalsForBucket();
      }
    }
  }, [form, constraints, request?.ecosystem, calculateTotalsForBucket]);

  const handleInvoiceSelect = useCallback(
    async (document: Document) => {
      const formValues = form.getFieldsValue();
      const stepType = formValues.stepType;
      const openedBuckets = await getBucketsPerDetails({
        status: 'draft',
        stepType,
        relatedContact: formValues.relatedContact,
        ecosystem: formValues.ecosystem,
        relatedDocumentId: document.id,
      });
      if (openedBuckets.length) {
        const selectedBucket = openedBuckets[0];
        setBucket(selectedBucket);
        if (stepType === 'invoiced') {
          calculateTotalsForBucket(selectedBucket);
        }
      } else {
        setBucket(null);
        calculateTotalsForBucket();
      }
    },
    [calculateTotalsForBucket, form],
  );

  const initialValues = {
    ...request,
    needDate: request?.needDate ? moment(request?.needDate) : null,
    expectedDeliveryDate: null,
    relatedDocumentId: '',
  };

  if (!request) {
    return null;
  }

  return (
    <DraggableModalProvider>
      <DraggableModal
        confirmLoading={true}
        visible={show}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setShow(false);
        }}
      >
        <Spin spinning={loading}>
          <Card actions={[<div />]}>
            <Tabs defaultActiveKey="1">
              <TabPane
                key="1"
                tab={intl.formatMessage({
                  id: `push.to`,
                  defaultMessage: 'Push to...',
                })}
              >
                <AppEntityForm
                  appId={''}
                  autoComplete="off"
                  initialValues={initialValues}
                  name="pushTo"
                  providedForm={form}
                  readOnly={loading}
                  onSubmit={onSubmit}
                  hideButtons={true}
                  onValuesChange={calculateTotals}
                >
                  {() => {
                    return (
                      <PushToInnerContainer>
                        <Form.Item name="relatedContactSnapshot" hidden />
                        <TopContainerPushTo>
                          <PurchaseStep
                            allowingTypes={allowingTypes}
                            fieldName="stepType"
                            onStepTypeSelect={handleStepTypeSelect}
                          />
                          <Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              const stepType = getFieldValue(
                                'stepType',
                              ) as BucketStep;
                              return (
                                <Supplier
                                  constraints={constraints}
                                  onContactSet={handleSupplierSet}
                                  disabled={
                                    ['invoiced', 'cancelled'].includes(
                                      stepType,
                                    ) && request?.bucketId !== undefined
                                  }
                                />
                              );
                            }}
                          </Item>
                        </TopContainerPushTo>
                        <InvoiceData onInvoiceSelect={handleInvoiceSelect} />
                        <SimpleProductNumber />
                        <SimpleProductName />
                        <Price constraintPrice={constraintPrice} />
                        <Tax />
                        <SelectedUnit ecosystemId={request?.ecosystem} />
                        <Item noStyle shouldUpdate>
                          {({ getFieldValue }) => {
                            const stepType = getFieldValue(
                              'stepType',
                            ) as BucketStep;
                            if (stepType !== 'invoiced') {
                              return (
                                <>
                                  <NeedDate />
                                  <ExpectedDeliveryDate />
                                  <DescriptionWithIndicator
                                    description={request?.description || ''}
                                  />
                                </>
                              );
                            }
                            return null;
                          }}
                        </Item>
                        <NeedAmount readOnly={false} />
                        <ProjectSelector
                          ecosystem={request?.ecosystem as string}
                        />
                        <CostCenter readOnly={false} />
                        <PositionTotal />
                        <ButtonContainerModal>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={loading}
                          >
                            {intl.formatMessage({
                              id: 'push',
                              defaultMessage: 'Push',
                            })}
                          </Button>
                        </ButtonContainerModal>
                        <Item name="relatedDocumentId" hidden />
                        <Item name="ecosystem" hidden />
                      </PushToInnerContainer>
                    );
                  }}
                </AppEntityForm>
              </TabPane>
            </Tabs>
          </Card>
        </Spin>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default PushTo;
