import React, { FC } from 'react';
import AutoCompleteProduct from './AutoCompleteProduct';
import { Form as AForm } from 'antd';
import { Product } from '@apps/products/services';
import _ from 'lodash';
import { ProductsTable } from '@apps/products/widgets/ProductsTable';

type AutoCompleteProductProps = {
  disabled: boolean;
  form: any;
  label: any;
  formValues: any;
  required?: boolean;
};

export const FormAutoCompleteProducts: FC<AutoCompleteProductProps> = ({
  form,
  label,
  required,
  formValues,
  ...rest
}) => {
  return (
    <>
      <AForm.Item
        dependencies={['ecosystem']}
        required={required}
        label={label}
      >
        {() => {
          const disabled = !form.getFieldValue('ecosystem');

          return (
            <>
              <AutoCompleteProduct
                ecosystem={form.getFieldValue('ecosystem')}
                onSelect={(passedProduct: Product) => {
                  const products = form.getFieldValue('products');
                  // default discount values
                  passedProduct.discount = 0;
                  passedProduct.amount = 1;
                  passedProduct.price = passedProduct.price
                    ? passedProduct.price
                    : 0;

                  form.setFieldsValue({
                    products: _.uniqBy([...products, passedProduct], 'id'),
                  });
                }}
                {...rest}
                disabled={disabled}
              />
            </>
          );
        }}
      </AForm.Item>
      <AForm.List name="products" initialValue={[]}>
        {(fields: any) => (
          <div>
            <ProductsTable
              products={fields}
              ecosystemId={form.getFieldValue('ecosystem')}
              readOnly={false}
              formValues={formValues}
              form={form}
            />
          </div>
        )}
      </AForm.List>
    </>
  );
};
