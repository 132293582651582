import SettingsConfig from '@apps/finance/settings';
import MainMenuConfig from '@apps/finance/mainMenu';
import { FileApp } from '@apps/AppTypes';

export default {
  todixId: 'finance',
  link: '/app/finance',
  name: 'finance',
  entityType: 'finance',
  collectionName: 'finance',
  localizationPath: 'finance/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  widgets: [],
} as FileApp;
