import * as React from 'react';
import { ThemeContext } from 'styled-components';
import { TinyArea } from '@ant-design/charts';
import { TinyAreaConfig } from '@ant-design/charts/lib/tinyArea';

const SparklineChart: React.FC<TinyAreaConfig> = (config) => {
  const { colors } = React.useContext(ThemeContext);
  return (
    <TinyArea
      {...config}
      point={{
        shape: 'circle',
        color: colors.main.primary,
      }}
      line={{
        color: colors.main.primary,
      }}
      areaStyle={{
        fill: colors.main.primary,
        fillOpacity: 0.2,
      }}
      width={130}
    />
  );
};

export default SparklineChart;
