export type CartEntry = {
  userId: string;
  priceId: string;
  productId: string;
  productName: string;
  ecosystem: string;
  quantity: number;
};

export type Cart = {
  timestamp: number;
  entries: CartEntry[];
};

export const CARTS = 'carts';
