import { styled } from '@styles/themes';

export const CartWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  border-left: 1px solid ${(props) => props.theme.colors.scrollbar.track};

  svg:hover {
    cursor: pointer;
  }
`;
