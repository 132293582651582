import { connect } from 'react-redux';
import { compose } from 'redux';

import { RootStore } from '@core/store';
import {
  getEcosystems,
  setEcosystemAvailability,
} from '@components/EcosystemIndicator/store';
import { EcosystemSwitches } from '@components/EcosystemSwitches/EcosystemSwitches';
import { getEcosystemRelations } from '@core/store/ecosystemRelations';

const mapState = (state: RootStore) => ({
  ecosystems: getEcosystems(state),
  ecosystemsRelations: getEcosystemRelations(state),
});

const mapDispatch = {
  setEcosystemAvailability,
};

export type StateProps = ReturnType<typeof mapState>;

export type DispatchProps = typeof mapDispatch;

export default compose(connect(mapState, mapDispatch))(EcosystemSwitches);
