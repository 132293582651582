import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import View from '@apps/finance/mainMenu/routes/View';

const Finance: FC<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}`}>
        <View />
      </Route>
    </Switch>
  );
};

export default Finance;
