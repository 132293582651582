import { FormattedMessage } from 'react-intl';
import { Form, Input, Select } from 'antd';
import { MIN_LENGTH_SELECT } from '@apps/sales/settings/RangeOfNumbers/consts';
import React from 'react';

const columns = [
  {
    title: (
      <FormattedMessage
        id={`settings.sales.rangeOfNumbers.table.header.fileType`}
        defaultMessage={`File type`}
      />
    ),
    render: (_text: any, record: any) => (
      <FormattedMessage
        id={`${record.fileType}`}
        defaultMessage={`${record.fileType}`}
      />
    ),
  },
  {
    title: (
      <FormattedMessage
        id={`settings.sales.rangeOfNumbers.table.header.abbreviation`}
        defaultMessage={`Abbreviation`}
      />
    ),
    render: (_text: any, record: any) => (
      <>
        {/* To keep next number in form and do not overide it */}
        <Form.Item
          name={['fileTypes', record.fileType, 'nextNumber']}
          style={{ display: 'none' }}
        />
        <Form.Item name={['fileTypes', record.fileType, 'abbreviation']}>
          <Input />
        </Form.Item>
      </>
    ),
  },
  {
    title: (
      <FormattedMessage
        id={`settings.sales.rangeOfNumbers.table.header.freeText`}
        defaultMessage={`Free text`}
      />
    ),
    render: (_text: any, record: any) => (
      <Form.Item name={['fileTypes', record.fileType, 'freeText']}>
        <Input />
      </Form.Item>
    ),
  },
  {
    title: (
      <FormattedMessage
        id={`settings.sales.rangeOfNumbers.table.header.minLength`}
        defaultMessage={`Min length`}
      />
    ),
    render: (_text: any, record: any) => (
      <Form.Item name={['fileTypes', record.fileType, 'minLength']}>
        <Select
          placeholder={
            <FormattedMessage
              id="settings.sales.rangeOfNumbers.minLength.select.label"
              defaultMessage="min length"
            />
          }
          options={Object.keys(MIN_LENGTH_SELECT).map((key: string) => ({
            label: key,
            value: MIN_LENGTH_SELECT[key],
          }))}
        />
      </Form.Item>
    ),
  },
];

export default columns;
