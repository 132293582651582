import { FilterTagsObject } from '@services/filtering/index';
import get from 'lodash.get';

const tagsMatcher = (filterObject: FilterTagsObject, row: any, key: string) => {
  if (filterObject.type === 'contains') {
    const tags = get(row, key);
    return tags
      ? tags.filter((tag: string) =>
          tag.toLowerCase().includes(filterObject?.filter.toLowerCase() || ''),
        ).length > 0
      : false;
  }

  return false;
};

export default tagsMatcher;
