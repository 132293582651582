import React, { FC, useRef, useState } from 'react';
import { Collapse } from 'antd';
import { useCustomEventListener } from '@core/services';
import {
  ON_FINISH_FAILED_EVENT,
  OnFinishFailedEvent,
} from '@components/AppEntityForm/AppEntityForm';
import { FormattedMessage, useIntl } from 'react-intl';
import ArrayOfFields from '@apps/contacts/mainMenu/components/DetailContactForm/ArrayOfFields';
import { getEmailsLabels } from '@services/dictionary';
import {
  MailOutlined,
  MailTwoTone,
  PlusSquareOutlined,
} from '@ant-design/icons';
import Warning from '@apps/contacts/mainMenu/components/DetailContactForm/Warning';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import ContentIndicator from '@apps/contacts/mainMenu/components/DetailContactForm/ContentIndicator';

const { Panel } = Collapse;

type Props = {};

const Email: FC<Props> = () => {
  const intl = useIntl();
  const { readOnly, getFieldValue } = useFormContext();
  const emailsOptions = getEmailsLabels(intl);
  const [hasError, setHasError] = useState(false);
  const addEmailRef = useRef<any>(() => {});
  const activePanel = useRef<string[]>([]);

  useCustomEventListener<'onFinishFailed', OnFinishFailedEvent>(
    ON_FINISH_FAILED_EVENT,
    (data) => {
      if (data) {
        const addressError =
          data.errorInfo.errorFields.filter((err) =>
            err.name.includes('emails'),
          ).length > 0;
        if (addressError) {
          setHasError(true);
        } else {
          setHasError(false);
        }
      }
    },
  );

  return (
    <Collapse
      onChange={(keys) => {
        activePanel.current = keys as string[];
      }}
      expandIcon={() =>
        hasError ? (
          <div>
            <Warning />
            <ContentIndicator
              emptyIcon={<MailOutlined />}
              contentIcon={<MailTwoTone />}
              emptyIndicator={() =>
                getFieldValue('emails')
                  ? (getFieldValue('emails') as []).length < 1
                  : true
              }
            />
          </div>
        ) : (
          <div>
            <ContentIndicator
              emptyIcon={<MailOutlined />}
              contentIcon={<MailTwoTone />}
              emptyIndicator={() =>
                getFieldValue('emails')
                  ? (getFieldValue('emails') as []).length < 1
                  : true
              }
            />
          </div>
        )
      }
    >
      <Panel
        header={
          <FormattedMessage
            id="contacts.general.email.label"
            defaultMessage="Email"
          />
        }
        key="1"
        extra={
          !readOnly ? (
            <PlusSquareOutlined
              onClick={(event) => {
                if (activePanel.current.length) {
                  event.stopPropagation();
                }
                addEmailRef.current();
              }}
            />
          ) : undefined
        }
        forceRender
      >
        <ArrayOfFields
          label={intl.formatMessage({
            id: 'detailcontactform.generalinformation.email.label',
            defaultMessage: 'E-mail#',
          })}
          options={emailsOptions}
          arrayName="emails"
          secondInputRules={[
            {
              type: 'email',
              required: true,
              message: intl.formatMessage({
                id: 'detailcontactform.generalinformation.email.error',
                defaultMessage: 'Must be valid email address',
              }),
            },
          ]}
          firstInputNameField="label"
          secondInputNameField="email"
          firstInputPlaceholder={intl.formatMessage({
            id: 'detailcontactform.generalinformation.email.firstplaceholder',
            defaultMessage: 'E-mail label',
          })}
          secondInputPlaceholder={intl.formatMessage({
            id: 'detailcontactform.generalinformation.email.secondplaceholder',
            defaultMessage: 'E-mail address',
          })}
          addNextButtonLabel={intl.formatMessage({
            id: 'detailcontactform.generalinformation.email.add',
            defaultMessage: 'Add e-mail address',
          })}
          standardFieldName="standardEmail"
          defaultValue={
            emailsOptions
              ? emailsOptions[0]
              : {
                  label: 'primary',
                  value: 'primary',
                  standard: true,
                }
          }
          hideButton
          addEntryRef={addEmailRef}
        />
      </Panel>
    </Collapse>
  );
};

export default Email;
