import { FilterUserObject } from '@services/filtering/index';
import get from 'lodash.get';

const userMatcher = (filterObject: FilterUserObject, row: any, key: string) => {
  if (filterObject.type === 'contains') {
    const value = get(row, key);
    if (value) {
      return value.displayName
        ?.toLowerCase()
        .includes(filterObject.filter.toLowerCase());
    } else {
      return true;
    }
  }
  return false;
};

export default userMatcher;
