import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import {
  getSalesOrderTypes,
  OrderType,
  updateSalesOrderType,
} from '@apps/sales/services/orderTypes';
import { FormattedMessage } from 'react-intl';
import { ColumnType } from 'antd/lib/table';
import { Card, Tooltip } from 'antd';
import {
  StyledButton,
  StyledEditIcon,
  StyledSwitch,
  StyledTable,
} from '@pages/settings/components';
import OrderTypeModal from '@apps/sales/settings/OrderTypes/OrderTypeModal';

const OrderTypes: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderTypes, setOrderTypes] = useState<OrderType[]>([]);
  const [selectedOrderType, setSelectedOrderType] = useState<OrderType | null>(
    null,
  );

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getSalesOrderTypes(uid)
        ?.then(setOrderTypes)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const handleOrderTypeChange = useCallback(
    async (orderType: OrderType) => {
      if (orderType.id) {
        try {
          setLoading(true);
          await updateSalesOrderType(orderType.id, orderType);
          setOrderTypes(
            orderTypes.map((item) => {
              if (item.id === orderType.id) {
                return orderType;
              }
              return item;
            }),
          );
          setLoading(false);
        } catch (e) {
          console.error(e);
          setLoading(false);
        }
      }
    },
    [orderTypes],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
    setSelectedOrderType(null);
  }, []);

  const onOk = useCallback(() => {
    setVisible(false);
    if (uid) {
      setLoading(true);
      getSalesOrderTypes(uid)
        ?.then(setOrderTypes)
        .finally(() => {
          setLoading(false);
          setSelectedOrderType(null);
        });
    }
  }, [uid]);

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="sales.createNew.name.label"
            defaultMessage="Name"
          />
        ),
        sorter: true,
        dataIndex: 'name',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: OrderType) => (
          <Tooltip
            title={
              <FormattedMessage
                id="app.sales.settings.isActive.tooltip"
                defaultMessage="app.sales.settings.isActive.tooltip"
              />
            }
          >
            <StyledSwitch
              key={record.id}
              checked={record.isActive}
              onChange={() =>
                handleOrderTypeChange({
                  ...record,
                  isActive: !record.isActive,
                } as OrderType)
              }
              checkedChildren={activated}
              unCheckedChildren={deactivated}
            />
          </Tooltip>
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: OrderType) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedOrderType(record);
                setVisible(true);
              }}
            />
          </>
        ),
      },
    ],
    [activated, deactivated, handleOrderTypeChange],
  );

  return (
    <Card
      title={
        <FormattedMessage
          id="settings.sales.groups.orderTypes.menuTitle"
          defaultMessage="Order types"
        />
      }
      extra={
        <StyledButton
          type="primary"
          size="small"
          onClick={() => setVisible(true)}
        >
          <FormattedMessage
            id="sales.settings.orderType.createNewButton"
            defaultMessage="Create new order type"
          />
        </StyledButton>
      }
    >
      <StyledTable
        dataSource={orderTypes}
        columns={columns}
        bordered
        tableLayout="auto"
        loading={loading}
        pagination={false}
        rowKey="id"
      />
      <OrderTypeModal
        orderType={selectedOrderType}
        visible={visible}
        ecosystemId={uid}
        onCancel={onCancel}
        onOk={onOk}
      />
    </Card>
  );
};

export default OrderTypes;
