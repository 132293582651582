import { Tabs } from 'antd';
import styled from 'styled-components';

const TabsLeftAligned = styled(Tabs)`
  & > .ant-tabs-nav {
    .ant-tabs-nav-list {
      margin-right: auto;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.colors.main.primary};
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.colors.main.primary};
  }
  .ant-tabs-nav::before {
    border-bottom-color: #e8e8e8;
  }

  & {
    .ant-tabs-content-holder {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;

export default TabsLeftAligned;
