import React, { CSSProperties, FC, useEffect, useState } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import { Space, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import EcosystemAvatar from '@components/EcosystemAvatar';
import {
  SaleOperation,
  getSalesOperations,
} from '@apps/sales/services/saleOperation';
import { StyledDashboardCard } from '@pages/dashboard';
import SalesAppConfig, { SALES_ACTION } from '@apps/sales';
import HugeButton from '@components/HugeButton';
import WidgetCard from '@components/WidgetCard';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';
import { CompanyContact } from '@apps/contacts/services';
import stepTypeIconMapper from '@apps/sales/mainMenu/stepTypeIconMapper';
import saleStatusColors from '@apps/sales/mainMenu/saleStatusColors';
import { StatusIconContainer } from '@apps/sales/mainMenu/styles.sc';
import calculateTotalsForProducts from '@apps/sales/mainMenu/components/SaleCreator/calculateTotalsForProducts';
import withSuffix from '@core/helpers/withSuffix';

const DashboardWidget: FC = () => {
  const [spinning, setSpinning] = useState(true);
  const [sales, setSales] = useState<SaleOperation[]>([]);
  const activeEcosystems = useSelector(getActiveEcosystems);
  const intl = useIntl();

  useEffect(() => {
    if (activeEcosystems.length) {
      setSpinning(true);
      getSalesOperations(
        {
          fields: [
            'id',
            'ecosystem',
            'contact',
            'step',
            'status',
            'contactSnapshot',
            'products',
          ],
          limit: 3,
        },
        'view-sales' as SALES_ACTION,
      )
        ?.then(async (response) => {
          setSales(response.results as SaleOperation[]);
          setSpinning(false);
        })
        .catch((e) => {
          console.error(e);
          setSpinning(false);
        });
    }
  }, [activeEcosystems.length, setSales]);

  return (
    <StyledDashboardCard bordered={false}>
      <Spin spinning={spinning} style={{ height: '100%' }} />
      {sales.length ? (
        <ReactCardCarousel autoplay spread="narrow" alignment="horizontal">
          {sales.map((sale, index) => {
            const contact = sale.contactSnapshot;
            const { status, step, products } = sale;
            const { total } = calculateTotalsForProducts(products);
            let contactName = '';
            if (contact?.type === 'person') {
              contactName = `${contact?.firstName || ''} ${
                contact?.lastName || ''
              }`;
            }
            if (contact?.type === 'company') {
              contactName = `${(contact as CompanyContact).companyName || ''}`;
            }
            const StepIcon: FC<{ style: CSSProperties }> =
              stepTypeIconMapper[step];
            const color = saleStatusColors[status];
            return (
              <WidgetCard
                key={index}
                title={
                  <div>
                    <EcosystemAvatar id={sale.ecosystem} /> {contactName}
                  </div>
                }
                bordered={false}
                extra={
                  <Link to={`app/${SalesAppConfig.todixId}`}>
                    {intl.formatMessage({
                      id: 'app.sales.dashboard.link',
                      defaultMessage: 'Go to sales',
                    })}
                  </Link>
                }
              >
                <Space>
                  <StatusIconContainer
                    style={{
                      backgroundColor: color || '#8c8c8c',
                    }}
                  >
                    <StepIcon key={index} style={{ color: '#fff' }} />
                  </StatusIconContainer>
                  <div>
                    <FormattedMessage
                      id={`settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${step}`}
                      defaultMessage={`settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${step}`}
                    />
                  </div>
                  <div>
                    {withSuffix({
                      value: total,
                    })}
                  </div>
                </Space>
              </WidgetCard>
            );
          })}
        </ReactCardCarousel>
      ) : (
        !spinning && (
          <Link to={`app/${SalesAppConfig.todixId}/create`}>
            <HugeButton type="dashed" icon={<SalesAppConfig.mainMenu.icon />}>
              <FormattedMessage
                id="app.sales.dashboard.empty.link"
                defaultMessage="Create your first Sale now"
              />
            </HugeButton>
          </Link>
        )
      )}
    </StyledDashboardCard>
  );
};

export default DashboardWidget;
