import React, { FC } from 'react';
import { Form } from 'antd';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { Radio } from '@components/FormFields';
import { FormattedMessage } from 'react-intl/lib';

const RadioField: FC<FieldProps> = ({ field, children, ...rest }) => {
  const { readOnly, getFieldValue } = useFormContext();
  const {
    fieldName,
    label,
    rules,
    className,
    style,
    readOnly: fieldReadOnly,
  } = field;
  const value = getFieldValue(fieldName);

  return (
    <Form.Item
      name={fieldName}
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      rules={rules?.map((r) => {
        r.message =
          r.message && typeof r.message === 'string' ? (
            <FormattedMessage
              id={`${r.message}`}
              defaultMessage={`${r.message}`}
            />
          ) : (
            r.message
          );
        return r;
      })}
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <Radio
        label={
          value ? <FormattedMessage id={value} defaultMessage={value} /> : value
        }
        name={fieldName}
        disabled={fieldReadOnly ? fieldReadOnly : readOnly}
        className={className}
        style={style}
        inGroup
        checked={!!value}
      />
    </Form.Item>
  );
};

export default RadioField;
