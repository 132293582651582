import React, { forwardRef } from 'react';
import { Input } from 'antd';
import useInputFilter, {
  FilterProps,
} from '@components/List/CustomsFilters/useInputFilter';
import { FilterWrapper } from '@components/List/CustomsFilters/styles';

export default forwardRef((props: FilterProps, ref) => {
  const { onValueChange, inputPlaceholder } = useInputFilter({
    ref,
    type: 'contains',
    filterType: 'city',
    placeholder: {
      id: 'contacts.general.city.label',
      defaultMessage: 'City',
    },
    props,
  });

  return (
    <FilterWrapper>
      <Input
        id="cityFilter"
        onChange={onValueChange}
        placeholder={inputPlaceholder}
      />
    </FilterWrapper>
  );
});
