import React from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';

const CompanyName = ({ readOnly }: { readOnly?: boolean }) => (
  <Form.Item
    name="companyName"
    label={
      <FormattedMessage
        id="documentprocessing.step2.companyname.label"
        defaultMessage="Company name"
      />
    }
    required={true}
    rules={[
      {
        required: true,
        message: (
          <FormattedMessage
            id="documentprocessing.step2.companyname.error.required"
            defaultMessage="Missing company name"
          />
        ),
      },
      {
        type: 'string',
        max: 40,
        message: (
          <FormattedMessage
            id="documentprocessing.step2.companyname.error.max"
            defaultMessage="Company name cannot be longer than 40 characters"
          />
        ),
      },
    ]}
  >
    <Input readOnly={readOnly} disabled={readOnly} />
  </Form.Item>
);

export default CompanyName;
