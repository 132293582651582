import { all, takeLatest, put, fork, call } from 'redux-saga/effects';
import {
  getApplications,
  getFavAppsIds,
  putFavAppsIds,
} from '@services/applications';
import {
  DashboardActionsTypes,
  fetchApplicationsFailed,
  fetchApplicationsSuccess,
} from './actions';

function* fetchApplicationsSaga() {
  try {
    // @ts-ignore
    const favApplications = yield call(getFavAppsIds);
    yield put(
      fetchApplicationsSuccess({
        applications: getApplications(),
        favApplications,
      }),
    );
  } catch (e) {
    yield put(fetchApplicationsFailed(`Error ${e}`));
  }
}

function* watchGetApplications() {
  yield takeLatest(
    DashboardActionsTypes.FETCH_APPLICATIONS,
    fetchApplicationsSaga,
  );
}

function* setFavApps(action: { payload: string[] }) {
  try {
    // TODO: (mocked) replace with real data
    yield call(putFavAppsIds, action.payload);
  } catch (_e) {}
}
function* watchSetFavApps() {
  yield takeLatest(
    // @ts-ignore
    DashboardActionsTypes.SET_FAV_APPLICATIONS,
    setFavApps,
  );
}

export default function* dashboardSaga() {
  yield all([fork(watchGetApplications), fork(watchSetFavApps)]);
}
