import React, { FC } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Toggle } from '@todix/ui-components';

const { Item } = Form;

type ActivationToggleProps = {
  readOnly: boolean;
};

const ActivationToggle: FC<ActivationToggleProps> = ({ readOnly }) => {
  return (
    <Item
      name="isActive"
      label={
        <FormattedMessage
          id="products.form.isactive.label"
          defaultMessage="Active Product"
        />
      }
      valuePropName="checked"
    >
      <Toggle disabled={readOnly} />
    </Item>
  );
};

export default ActivationToggle;
