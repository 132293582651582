import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { IGetRowsParams } from 'ag-grid-community';
import { FilterTextObject } from '@services/filtering';
import getWeekPlans from '@apps/weekplan/services/getWeekPlans';
import AppView from '@components/AppView';
import columns from '@apps/weekplan/mainMenu/columns';
import SideContent from '@apps/weekplan/mainMenu/SideContent';
import optionsMapper from '@core/helpers/optionsMapper';
import deleteWeekPlan from '@apps/weekplan/services/deleteWeekPlan';
import appConfig from '@apps/weekplan';

const WeekPlans: FC = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();

  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getWeekPlans(
      {
        query: params.query,
        offset: params.startRow,
        limit: params.endRow - params.startRow,
        fields: ['id', 'name', 'ecosystem', 'lanes'],
        filters: Object.fromEntries(filtersEntries),
        sort: params.sortModel.map(
          (model: { colId: string; sort: string }) =>
            `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
        ),
      },
      'view-weekplans',
    )?.then((response) => {
      params.successCallback(response.results, response.info.results);
    });
  };

  return (
    <AppView
      hideQuickFilter
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'app.weekplan.list.title',
            defaultMessage: 'WeekPlans',
          }),
        ],
      ]}
      columns={useMemo(() => columns(intl), [intl])}
      getRows={getRows}
      sideContent={SideContent}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      onRemoveItem={deleteWeekPlan}
      options={optionsMapper(['edit', 'delete'], intl)}
      appConfig={appConfig}
    />
  );
};

export default WeekPlans;
