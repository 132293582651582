import { getEcosystem, patchEcosystem } from '@services/ecosystems';

type SaveSKRProps = {
  skrCode: string;
  ecosystemId: string;
};

const saveSKR = async ({ skrCode, ecosystemId }: SaveSKRProps) => {
  const ecosystem = await getEcosystem(ecosystemId);
  if (ecosystem) {
    await patchEcosystem(ecosystemId, {
      ...ecosystem,
      skr: skrCode,
    });
  }
};

export default saveSKR;
