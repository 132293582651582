import { GetFilterParams } from '@apps/directSales/mainMenu/Consultants/services/index';
import { ConsultantCommissionState } from '@apps/directSales/services/commisions';

export const CONSULTANTS_COLLECTION_NAME = 'consultants';

export type Consultant = {
  ecosystem: string;
  id?: string;
  photoURL?: string;
  consultantName: string;
  consultantSurname: string;
  consultantEmail: string;
  consultantBirthday: any;
  consultantPhone: string;
  consultantStreet: string;
  consultantStreetNo: string;
  consultantZip: string;
  consultantCity: string;
  consultantRef: string;
  consultantUser?: string;
  consultantParent: string;
  isActive: boolean;
  validated: boolean;
  validationDate?: any;
  validatedById?: string;
  consultantLevel: string;
  consultantRank: string;
  description: string;
  applicationDate: string;
  careerplanConfirmed: boolean;
  privacyProtectionConfirmed: boolean;
  agbConfirmed: boolean;
  consultantTaxNo?: string;
  consultantNo?: string;
  joker?: number;
  iban?: string;
  bic?: string;
  originConsultantEmail?: string;
  about?: string;
};

export type ConsultantWithStats = Consultant & {
  stats: ConsultantCommissionState | null;
};

export type ApiResponse = {
  results: object[];
  info: {
    results: number;
  };
};

export type GetConsultants = (params: GetFilterParams) => Promise<ApiResponse>;
