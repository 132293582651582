import ApiService from '@services/config';
import { AxiosResponse } from 'axios';
import { CountryEntry } from '@services/dictionary';

export const checkIfPhotoAvailable = async (photoURL: string | null) => {
  if (!photoURL) {
    return false;
  }

  try {
    await ApiService.get<AxiosResponse<CountryEntry[]>>(photoURL);
    return true;
  } catch {
    return false;
  }
};
