import { RootStore } from '@core/store';
import { DashboardState } from '@core/layout/namespace';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';
import { getEcosystemById } from '@components/EcosystemIndicator/store/selectors';
import { connectedApps } from '@apps/connectedApps';
import { RegisteredFileApp } from '@apps/AppTypes';

export const getDashboardState = (state: RootStore): DashboardState =>
  state.dashboard;

export const getLoading = (state: RootStore) =>
  getDashboardState(state).isLoading;

export const getApplications = (state: RootStore): ApplicationEntry[] =>
  getDashboardState(state).applications;

export const getFavAppsIds = (state: RootStore): string[] =>
  state.userPreferences.data.favouriteApplications || [];

export const getFavApplications = (state: RootStore): ApplicationEntry[] => {
  const apps = getApplications(state);
  // keep the order of fav apps
  return getFavAppsIds(state)
    .map((favId) => apps.find((app) => app.id === favId))
    .filter((app) => !!app) as ApplicationEntry[];
};

export const getAllActiveAppsIds = (
  state: RootStore,
): ApplicationEntry['id'][] => {
  const activeEcosystems = getActiveEcosystems(state);
  const applicationsIdsArray = activeEcosystems
    .map((eco) => eco.applications || [])
    .flat();
  const applicationsIds = [...new Set(applicationsIdsArray)];
  return applicationsIds;
};

export const getActiveEcosystemsApps = (
  state: RootStore,
): ApplicationEntry[] => {
  const applicationsIds = getAllActiveAppsIds(state);

  return getApplications(state).filter((app) =>
    applicationsIds.includes(app.id),
  );
};

export const getMenuApplications = (state: RootStore): ApplicationEntry[] => {
  const favApps = getFavApplications(state);
  const allActiveAppIds = getAllActiveAppsIds(state);
  if (favApps.length) {
    return favApps.filter((app) => allActiveAppIds.includes(app.id));
  }
  return getActiveEcosystemsApps(state);
};

export const getStripeProductsAsApps = (
  state: RootStore,
): RegisteredFileApp[] => {
  const { stripeProducts } = state;
  return connectedApps
    .map((el) => {
      const app = stripeProducts.find(
        (stripeProduct) => stripeProduct.todixId === el.todixId,
      );
      if (app) {
        return {
          ...el,
          appId: app.id,
        } as RegisteredFileApp;
      }
      return null;
    })
    .filter((el) => el !== null) as RegisteredFileApp[];
};

export const getActiveApplications = (
  state: RootStore,
  source: 'library' | 'fav',
): ApplicationEntry[] => {
  const applications =
    source === 'library' ? getApplications(state) : getFavApplications(state);
  const activeEcosystemsIds = getActiveEcosystems(state).map((eco) => eco.id);
  return applications
    .map((app) => ({
      ...app,
      ecosystemIds: app.ecosystemIds.filter((id) =>
        activeEcosystemsIds.includes(id),
      ),
    }))
    .filter((apps) => apps.ecosystemIds.length);
};

export const getManageMenuState = (state: RootStore): boolean =>
  getDashboardState(state).showManageMenu;

export const getEcosystemApplications =
  (ecosystemId: string) =>
  (state: RootStore): ApplicationEntry[] => {
    const ecosystem = getEcosystemById(ecosystemId)(state);
    return getApplications(state).filter((app) =>
      ecosystem?.applications?.includes(app.id),
    );
  };
