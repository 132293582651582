import { Style } from '@react-pdf/types';
import { Product, ProductPrice } from '@apps/products/services';
import { ApiResponse, GetParams } from '@apps/weekplan/services';
import { DeliveryTermsType } from '@apps/sales/services/salesDeliveryTerms';
import { PaymentTerm } from '@apps/sales/services/salesPaymentTerms';
import { SALES_ACTION } from '@apps/sales';

export type ProductWithPrice = {
  ecosystem?: string;
  type?: string;
  netPrice?: number;
  tax?: number;
  taxValue?: number;
  discountedPrice?: number;
  grossPrice?: number;
  totalNetPrice?: number;
  totalGrossPrice?: number;
  discount?: number;
  saleDetails?: Product['saleDetails'];
  id?: string;
  key: number;
  productNumber?: string;
  productId?: string;
  productName?: string;
  amount?: number;
  price?: number;
  salesUnit?: Product['saleDetails']['salesUnit'];
  taxCategory?: Product['saleDetails']['taxCategory'];
  taxCategories?: Product['saleDetails']['taxCategory'][];
  prices?: ProductPrice[];
  description?: ProductPrice['description'];
  isDirty?: boolean;
  commissionFactor?: number; // in %
};

export type LayoutSection = {
  tooltip?: string;
  style?: Style;
  field: string;
  readOnly?: boolean;
  type?: 'inputText' | 'inputNumber' | 'select' | 'datePicker';
};

export type PageLayout = LayoutSection[];

export type Sale = {
  id?: string;
  ecosystem: string;
  sellerId: string;
  contact: any;
  products: ProductWithPrice[];
  contactSnapshot: any;
  timestamp?: any;
  deliveryTerms: DeliveryTermsType | null;
  paymentTerms: PaymentTerm | null;
  paymentStatus:
    | 'running'
    | 'cancelled'
    | 'expired'
    | 'error'
    | 'refused'
    | 'paid';
  shipmentStatus?:
    | 'pending'
    | 'cancelled'
    | 'delivered'
    | 'error'
    | 'delayed'
    | 'refused';
  orderId?: string;
};

export type GetApiResponse = (
  params: GetParams,
  action: SALES_ACTION,
) => Promise<ApiResponse> | undefined;

export const SALES_COLLECTION_NAME = 'sales';
