import { IntlShape } from 'react-intl';
import dateMatcher from '@services/filtering/dateMatcher';
import userMatcher from '@services/filtering/userMatcher';
import relatedContactMatcher from '@services/filtering/relatedContactMatcher';
import documentNameMatcher from '@services/filtering/documentNameMatcher';
import cityMatcher from '@services/filtering/cityMatcher';
import tagsMatcher from '@services/filtering/tagsMatcher';
import phoneNumberMatcher from '@services/filtering/phoneNumberMatcher';
import emailMatcher from '@services/filtering/emailMatcher';
import textMatcher from '@services/filtering/textMatcher';
import activeStatusMatcher from '@services/filtering/activeStatusMatcher';
import progressMatcher from '@services/filtering/progressMatcher';
import addressMatcher from '@services/filtering/addressMatcher';
import numberMatcher from '@services/filtering/numberMatcher';

export type FilterType =
  | 'contains'
  | 'equals'
  | 'notEqual'
  | 'notContains'
  | 'startsWith'
  | 'endsWith'
  | 'lessThan'
  | 'lessThanOrEqual'
  | 'greaterThan'
  | 'greaterThanOrEqual'
  | 'inRange'
  | 'empty';

export type FilterTextObject = {
  filterType: 'text';
  type: FilterType;
  filter: string;
};

export type FilterDocumentNameObject = {
  filterType: 'documentName';
  type: FilterType;
  filter: string;
};

export type FilterCreatingUserObject = {
  filterType: 'creatingUser';
  type: FilterType;
  filter: string;
};

export type FilterUploadingUserObject = {
  filterType: 'uploadingUser';
  type: FilterType;
  filter: string;
};

export type FilterUserObject =
  | FilterCreatingUserObject
  | FilterUploadingUserObject;

export type FilterRelatedContactObject = {
  filterType: 'relatedContact';
  type: FilterType;
  filter: string;
};

export type FilterTagsObject = {
  filterType: 'tags';
  type: FilterType;
  filter: string;
};

export type FilterDateObject = {
  filterType: 'date';
  dateFrom: string;
  dateTo: string | null;
  type: FilterType;
};

export type FilterPhoneNumberObject = {
  filterType: 'phoneNumber';
  type: FilterType;
  filter: string;
};

export type AddressType =
  | 'city'
  | 'additionalInfo'
  | 'country'
  | 'district'
  | 'label'
  | 'number'
  | 'street'
  | 'zipcode';

export type FilterAddressObject = {
  filterType: Exclude<AddressType, 'city'>;
  type: FilterType;
  filter: string;
};

export type FilterCityObject = {
  filterType: 'city';
  type: FilterType;
  filter: string;
};

export type FilterEmailObject = {
  filterType: 'email';
  type: FilterType;
  filter: string;
};

export type FilterActiveStatusObject = {
  filterType: 'active';
  type: FilterType;
  filter: boolean | null;
};

export type FilterProgressObject = {
  filterType: 'progress';
  type: FilterType;
  filter: string;
};

export type FilterNumberObject = {
  filterType: 'number';
  type: FilterType;
  filter: number;
  filterTo?: number;
};

export type FilterInputObject =
  | FilterCreatingUserObject
  | FilterUploadingUserObject
  | FilterRelatedContactObject
  | FilterTagsObject
  | FilterPhoneNumberObject
  | FilterCityObject
  | FilterEmailObject
  | FilterAddressObject;

export type FilterObject =
  | FilterTextObject
  | FilterDateObject
  | FilterDocumentNameObject
  | FilterInputObject
  | FilterActiveStatusObject
  | FilterProgressObject
  | FilterNumberObject;

export const getFilteredData = (
  data: any[],
  filterEntries: any[],
  intl?: IntlShape,
) => {
  return data.filter((row) =>
    filterEntries.every(([key, filter]) => {
      const filterObject = filter as any;

      if (filterObject?.filterType === 'date') {
        return dateMatcher(filterObject, row, key);
      }

      if (
        filterObject.filterType === 'creatingUser' ||
        filterObject.filterType === 'uploadingUser'
      ) {
        return userMatcher(filterObject, row, key);
      }

      if (filterObject.filterType === 'relatedContact') {
        return relatedContactMatcher(filterObject, row);
      }

      if (filterObject.filterType === 'documentName') {
        return documentNameMatcher(filterObject, row, intl as IntlShape);
      }

      if (filterObject.filterType === 'city') {
        return cityMatcher(filterObject, row, key);
      }

      //TODO: filterType number is one that is built one into ag-grid, so we cant override it in address
      if (
        filterObject.filterType === 'country' ||
        filterObject.filterType === 'additionalInfo' ||
        filterObject.filterType === 'district' ||
        filterObject.filterType === 'label' ||
        filterObject.filterType === 'street' ||
        filterObject.filterType === 'zipcode'
      ) {
        return addressMatcher(filterObject, row, key);
      }

      if (filterObject.filterType === 'tags') {
        return tagsMatcher(filterObject, row, key);
      }

      if (filterObject.filterType === 'phoneNumber') {
        return phoneNumberMatcher(filterObject, row, key);
      }

      if (filterObject.filterType === 'email') {
        return emailMatcher(filterObject, row, key);
      }

      if (filterObject.filterType === 'text') {
        return textMatcher(filterObject, row, key);
      }

      if (filterObject.filterType === 'active') {
        return activeStatusMatcher(filterObject, row, key);
      }

      if (filterObject.filterType === 'progress') {
        return progressMatcher(filterObject, row, key);
      }

      if (filterObject.filterType === 'number') {
        return numberMatcher(filterObject, row, key);
      }

      return false;
    }),
  );
};
