import * as React from 'react';
import { Radio } from 'antd';
import { RadioGroupProps } from 'antd/lib/radio';

type Props = RadioGroupProps & {
  name?: string;
};

const RadioGroup: React.FC<Props> = (props) => {
  return (
    <>
      <div>
        <Radio.Group {...props}>{props.children}</Radio.Group>
      </div>
    </>
  );
};

export default RadioGroup;
