import React from 'react';
import { Form } from 'antd';
import StandardPrice from './StandardPrice';
const { Item } = Form;

const Price: React.FC = () => {
  return (
    <Item
      noStyle
      shouldUpdate={(prevValues, nextValues) =>
        prevValues.model !== nextValues.model
      }
    >
      <StandardPrice />
    </Item>
  );
};

export default Price;
