import React from 'react';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl/lib';

const InActiveTag = () => (
  <Tag color="warning">
    <FormattedMessage id="tag.inactive" defaultMessage="Inactive" />
  </Tag>
);

export default InActiveTag;
