import { firebase } from '@services/Firebase';
import {
  Product,
  PRODUCTS_COLLECTION_NAME,
} from '@apps/products/services/namespace';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig, { PRODUCTS_ACTION } from '@apps/products';

const getProductsForEcosystem = (
  ecosystem: string,
  action: PRODUCTS_ACTION,
) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  if (!activeEcosystemsIds?.includes(ecosystem)) {
    return Promise.resolve([] as Product[]);
  }
  return firebase.firestore
    ?.collection(PRODUCTS_COLLECTION_NAME)
    .where('ecosystem', '==', ecosystem)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs as Product[];
    });
};

export default getProductsForEcosystem;
