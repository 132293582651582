const dateFilter = {
  buttons: ['reset'],
  browserDatePicker: true,
  filterOptions: [
    'contains',
    'notContains',
    'lessThan',
    'greaterThan',
    'inRange',
  ],
  defaultOption: 'contains',
};

export default dateFilter;
