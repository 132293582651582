import { styled } from '@styles/themes';
import { Layout } from 'antd';

const { Content, Sider } = Layout;

export const StackedViewLayout = styled(Layout)`
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  gap: 20px;
  & .ant-layout-sider {
    background: transparent;
  }
`;

export const StackedViewContent = styled(Content)`
  width: 100% !important;
`;

export const StackedViewSider = styled(Sider)`
  min-width: 92px !important;
  width: 100% !important;

  & {
    .ant-layout-sider-trigger {
      max-width: 80px !important;
      background-color: transparent !important;
      bottom: 32px;
    }
  }
`;
