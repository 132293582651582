import * as React from 'react';
import {
  InputProps as AntdInputProps,
  PasswordProps as AntdPasswordProps,
  TextAreaProps as AntdTextAreaProps,
} from 'antd/lib/input';
import { Input as AntdInput } from 'antd';
//@ts-ignore
import TextArea from 'antd/lib/input/TextArea';
import Password from 'antd/lib/input/Password';
import { Label } from '@components/FormFields';

type CommonProps = {
  name: string;
  label?: string;
};

type InputType = React.ForwardRefExoticComponent<
  AntdInputProps & React.RefAttributes<AntdInput> & CommonProps
> & {
  Password: React.ForwardRefExoticComponent<
    AntdPasswordProps & React.RefAttributes<typeof Password> & CommonProps
  >;
  TextArea: React.ForwardRefExoticComponent<
    //@ts-ignore
    AntdTextAreaProps & React.RefAttributes<TextArea> & CommonProps
  >;
};

export type InputProps = AntdInputProps & CommonProps;

export type TextAreaProps = AntdTextAreaProps & CommonProps;

export type PasswordProps = AntdPasswordProps & CommonProps;

const InputRef = React.forwardRef(
  (
    {
      name,
      type,
      id,
      label,
      onChange: passedOnChange,
      onBlur: passedOnBlur,
      ...restProps
    }: InputProps,
    ref: React.Ref<AntdInput>,
  ) => {
    return (
      <div>
        {label && <Label htmlFor={id}>{label}</Label>}
        <AntdInput
          ref={ref}
          onChange={(event) => {
            passedOnChange && passedOnChange(event);
          }}
          onBlur={(event) => {
            passedOnBlur && passedOnBlur(event);
          }}
          {...restProps}
        />
      </div>
    );
  },
);

const Input = InputRef as unknown as InputType;

Input.TextArea = React.forwardRef(
  (
    {
      name,
      id,
      label,
      onChange: passedOnChange,
      onBlur: passedOnBlur,
      ...restProps
    }: TextAreaProps,
    //@ts-ignore
    ref: React.Ref<TextArea>,
  ) => {
    return (
      <div>
        {label && <Label htmlFor={id}>{label}</Label>}
        <AntdInput.TextArea
          ref={ref}
          onChange={(event) => {
            passedOnChange && passedOnChange(event);
          }}
          onBlur={(event) => {
            passedOnBlur && passedOnBlur(event);
          }}
          {...restProps}
        />
      </div>
    );
  },
);

Input.Password = React.forwardRef(
  (
    {
      name,
      id,
      label,
      onChange: passedOnChange,
      onBlur: passedOnBlur,
      ...restProps
    }: PasswordProps,
    ref: React.Ref<typeof Password>,
  ) => {
    return (
      <div>
        {label && <Label htmlFor={id}>{label}</Label>}
        <AntdInput.Password
          ref={ref}
          onChange={(event) => {
            passedOnChange && passedOnChange(event);
          }}
          onBlur={(event) => {
            passedOnBlur && passedOnBlur(event);
          }}
          {...restProps}
        />
      </div>
    );
  },
);

export default Input;
