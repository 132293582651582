import React, { FC, useState, useCallback, useEffect } from 'react';
import { Form, Select, Space } from 'antd';
import { Document } from '@apps/documents/service';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';
import { getDocumentsByContactAndEcosystem } from '@apps/documents/service';
import intl from '../../../../../intl';
import withSuffix from '@core/helpers/withSuffix';

const { Item } = Form;

type InvoiceSelectorProps = {
  supplierId: string;
  ecosystem: string;
  onInvoiceSelect(document: Document): void;
};

type Options = {
  value: string;
  'data-forsearch': string;
  label: any;
};

const InvoiceSelector: FC<InvoiceSelectorProps> = ({
  ecosystem,
  supplierId,
  onInvoiceSelect,
}) => {
  const { setFieldsValue, getFieldsValue, getFieldValue } = useFormContext();
  const [options, setOptions] = useState<Options[]>([]);
  const [supplierDocs, setSupplierDocs] = useState<Document[]>([]);

  useEffect(() => {
    if (supplierId) {
      getDocumentsByContactAndEcosystem(ecosystem, supplierId, 'invoice').then(
        (documents) => {
          setSupplierDocs(documents);
          setOptions(
            documents.map((doc, index) => {
              return {
                value: doc.id as string,
                'data-forsearch': `${doc.docNumber} ${
                  doc.relatedContactSnapshot?.firstName || ''
                } ${doc.relatedContactSnapshot?.lastName || ''} ${
                  doc.relatedContactSnapshot?.companyName || ''
                }`,
                label: (
                  <Space size="small" direction="vertical" key={index}>
                    {doc.docNumber && (
                      <div>
                        {intl.formatMessage({
                          id: 'invoice.number',
                          defaultMessage: 'Invoice number',
                        })}
                        : {doc.docNumber}
                      </div>
                    )}
                    {doc.creationDate && (
                      <div>
                        {intl.formatMessage({
                          id: 'documents.columns.creationDate.header',
                          defaultMessage: 'Creation date',
                        })}
                        : {doc.creationDate}
                      </div>
                    )}
                    {doc.totalGrossAmount && (
                      <div>
                        {intl.formatMessage({
                          id: 'documents.columns.totalGrossAmount.header',
                          defaultMessage: 'Total gross amount',
                        })}
                        :{' '}
                        {withSuffix({
                          value: doc.totalGrossAmount,
                        })}
                      </div>
                    )}
                  </Space>
                ),
              };
            }),
          );
        },
      );
    }
  }, [ecosystem, getFieldValue, supplierId]);

  const handleChange = useCallback(
    (id) => {
      const document = supplierDocs.find((doc) => doc.id === id);
      if (document) {
        setFieldsValue({
          ...getFieldsValue(),
          documentNumber: document.docNumber,
          documentDate: document.creationDate,
          documentTotalAmount: document.totalGrossAmount || 0,
          relatedDocumentId: id,
        });
        onInvoiceSelect(document);
      }
    },
    [supplierDocs, setFieldsValue, getFieldsValue, onInvoiceSelect],
  );
  return (
    <Item
      name="relatedDocumentId"
      required
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'select.related.invoice',
            defaultMessage: 'Please select related invoice',
          }),
        },
      ]}
    >
      <Select
        style={{ minWidth: '300px' }}
        showSearch={true}
        onChange={handleChange}
        filterOption={(inputValue, option) => {
          return option
            ? option['data-forsearch']
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            : false;
        }}
        placeholder={
          <FormattedMessage
            id="select.invoice"
            defaultMessage="Select invoice..."
          />
        }
        options={options}
      />
    </Item>
  );
};

export default InvoiceSelector;
