import {
  FilterInputObject,
  FilterTextObject,
  FilterType,
} from '@services/filtering';
import { useEffect, useImperativeHandle, useState } from 'react';
import { useIntl } from 'react-intl';

export type FilterProps = {
  filterChangedCallback: any;
};

type Placeholder = {
  id: string;
  defaultMessage: string;
};

type UseInputFilterProps = {
  ref: any;
  props: FilterProps;
  filterType: FilterInputObject['filterType'];
  type: FilterType;
  placeholder: Placeholder;
};

const useInputFilter = ({
  ref,
  props,
  filterType,
  type,
  placeholder,
}: UseInputFilterProps) => {
  const [input, setInput] = useState('');
  const intl = useIntl();

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return !!input;
      },

      getModel() {
        return input
          ? ({
              filter: input,
              filterType,
              type,
            } as FilterTextObject)
          : null;
      },

      getModelAsString() {
        return '';
      },

      setModel(model: any) {
        setInput(model?.input || '');
      },
    };
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [input]);

  const onValueChange = (event: any) => {
    setInput(event.target.value);
    props.filterChangedCallback({
      input,
    });
  };

  return { onValueChange, inputPlaceholder: intl.formatMessage(placeholder) };
};

export default useInputFilter;
