import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import ProjectSelector from '@apps/purchases/mainMenu/individualRequestsList/ProjectSelector';

const { Item } = Form;

type ProjectProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const Project: FC<ProjectProps> = ({ namePrefix, readOnly }) => {
  const { getFieldValue } = useFormContext();
  const [ecosystem, setEcosystem] = useState<string>('');

  useEffect(() => {
    const currentEcosystem = getFieldValue('ecosystem');
    if (currentEcosystem) {
      setEcosystem(currentEcosystem);
    }
  }, [getFieldValue]);

  if (!ecosystem) {
    return (
      <Item
        name={namePrefix !== undefined ? [namePrefix, `project`] : `project`}
        label={<FormattedMessage id="project" defaultMessage="Project" />}
      >
        <div style={{ color: '#999' }}>
          <FormattedMessage
            id="project.select.ecosystem.first"
            defaultMessage="Select ecosystem first"
          />
        </div>
      </Item>
    );
  }

  return <ProjectSelector ecosystem={ecosystem} disabled={readOnly} />;
};

export default Project;
