import { FilterPhoneNumberObject } from '@services/filtering/index';
import get from 'lodash.get';
import { Phone } from '@apps/contacts/services';

const phoneNumberMatcher = (
  filterObject: FilterPhoneNumberObject,
  row: any,
  key: string,
) => {
  if (filterObject.type === 'contains') {
    const phones = get(row, key) as Phone[];
    return phones
      ? phones.filter((phone) =>
          phone.number.includes(filterObject?.filter || ''),
        ).length > 0
      : false;
  }

  return false;
};

export default phoneNumberMatcher;
