import { Sale } from '@apps/sales/services/namespace';
import { Document, getDocumentsByIds } from '@apps/documents/service';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';

const getDocumentsRelatedWithSale = async (sale: Sale): Promise<Document[]> => {
  const saleDocumentRelations = await getChildrenForParent({
    parentId: sale.id as string,
    ecosystem: sale.ecosystem,
    childType: 'document',
  });

  const docIds = saleDocumentRelations.map((el) => el.childId);
  return await getDocumentsByIds(docIds);
};

export default getDocumentsRelatedWithSale;
