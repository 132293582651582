import React, { FC } from 'react';
import { FormProps, Form } from 'antd';
import intl from '../../../../../../../intl';
import ProductDetailsToggle from './ProductDetailsToggle';
import { FlexSpaceBetween } from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/styles.sc';
import Price from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/priceForm/price';

const { Item } = Form;

const PriceForm: FC<FormProps> = (formProps) => {
  return (
    <Form {...formProps}>
      <div>
        <FlexSpaceBetween>
          <ProductDetailsToggle
            name="isActive"
            label={intl.formatMessage({
              id: 'settings.list.header.status',
              defaultMessage: 'Status',
            })}
            checkedChildren={intl.formatMessage({
              id: 'settings.status.activated',
              defaultMessage: 'Activated',
            })}
            unCheckedChildren={intl.formatMessage({
              id: 'settings.status.deactivated',
              defaultMessage: 'Deactivated',
            })}
          />
          <ProductDetailsToggle
            name="isDefaultPrice"
            label={intl.formatMessage({
              id: 'sales.salesTab.isDefaultPrice.label',
              defaultMessage: 'Default price',
            })}
          />
        </FlexSpaceBetween>
        <Price />
      </div>
      <Item hidden name="isArchived" />
      <Item hidden name="currency" />
    </Form>
  );
};

export default PriceForm;
