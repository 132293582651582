import React, { FC } from 'react';
import { Form, Row, Select, Col, Button, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Phone } from '@apps/contacts/services';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPhonesLabels } from '@services/dictionary';

type SelectWrappedProps = {
  value?: Phone[];
  onChange?: (value?: Phone[]) => void;
};

const SelectWrapped: FC<SelectWrappedProps> = ({
  value = [],
  onChange,
  ...props
}) => {
  const intl = useIntl();
  const phonesOptions = getPhonesLabels(intl);
  const options = value.map(({ label, number }) => ({
    label: `${number} - ${label}`,
    value: number,
  }));
  return (
    <Form.List name="phones">
      {(_fields, { add }) => {
        return (
          <>
            <Row gutter={16}>
              <Col flex="auto">
                <Select
                  options={options}
                  value={options[0]?.value}
                  onChange={() => {
                    if (onChange) {
                      onChange(value);
                    }
                  }}
                  {...props}
                />
              </Col>
              <Col flex="32px">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    add({
                      label: phonesOptions[0].value,
                      number: '',
                      standard: true,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};

const PhonePick = () => {
  const intl = useIntl();
  const phonesOptions = getPhonesLabels(intl);

  return (
    <>
      <Form.Item
        name="phones"
        label={
          <FormattedMessage
            id="documentprocessing.step2.phonepick.label"
            defaultMessage="Phone"
          />
        }
      >
        <SelectWrapped />
      </Form.Item>
      <Form.List name="phones">
        {(fields, { remove }, { errors }) => {
          return (
            <>
              {(fields as any).map(
                ({ key, name, fieldKey, ...restField }: any, index: any) => {
                  return index === fields.length - 1 ? (
                    <Row style={{ marginBottom: '24px' }} key={key}>
                      <Col flex={1}>
                        <Form.Item
                          {...restField}
                          noStyle
                          name={[name, 'label']}
                          fieldKey={[fieldKey, 'label']}
                        >
                          <Select
                            placeholder={intl.formatMessage({
                              id: 'detailcontactform.generalinformation.phone.firstplaceholder',
                              defaultMessage: 'Phone label',
                            })}
                            options={phonesOptions}
                          />
                        </Form.Item>
                      </Col>
                      <Col flex={1}>
                        <Form.Item
                          {...restField}
                          noStyle
                          name={[name, 'number']}
                          fieldKey={[fieldKey, 'number']}
                          required
                        >
                          <Input
                            autoComplete="nope"
                            placeholder={intl.formatMessage({
                              id: 'detailcontactform.generalinformation.phone.secondplaceholder',
                              defaultMessage: 'Phone number',
                            })}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <MinusCircleOutlined
                          style={{
                            position: 'absolute',
                            right: '0px',
                            top: '-20px',
                          }}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </Col>
                    </Row>
                  ) : null;
                },
              )}
              <Form.ErrorList errors={errors} />
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default PhonePick;
