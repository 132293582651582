import React from 'react';
import { Form, Select } from 'antd';
import { useCurrencies } from '@pages/settings/components/useCurrencies';
const { Item } = Form;

const Currency: React.FC = () => {
  const [areCurrenciesLoading, currencies] = useCurrencies();
  return (
    <Item name="currency" noStyle>
      <Select
        disabled={true}
        style={{
          minWidth: '100px',
        }}
        loading={areCurrenciesLoading}
        showSearch
        options={currencies.map((el) => ({
          label: el,
          value: el,
        }))}
      />
    </Item>
  );
};

export default Currency;
