import { firebase } from '@services/Firebase';

type Payload = {
  period: string;
  ecosystemId: string;
};
const calculateSaleCommissions = (payload: Payload) =>
  firebase.functions?.httpsCallable('createSalesPermissions')(payload);

export default calculateSaleCommissions;
