import React, { FC } from 'react';
import { message } from 'antd';
import { Document } from '@apps/documents/service/namespace';
import storage from '@services/Firebase/Storage';
import { RcFile } from 'rc-upload/lib/interface';
import { Upload } from '@components/FormFields';
import { MAXIMUM_FILE_SIZE } from '@apps/contacts/mainMenu/components/DetailContactForm/AssetUpload';
import intl from '../../../../intl';
import { postDocument } from '@apps/documents/service';
import { firebase } from '@services/Firebase';
import moment from 'moment';

type AddArchivedDocumentProps = {
  readOnly: boolean;
  ecosystem: string;
  onDocAdd(doc: Partial<Document>): void;
};

const AddArchivedDocument: FC<AddArchivedDocumentProps> = ({
  readOnly,
  onDocAdd,
  ecosystem,
}) => {
  const user = firebase.auth.currentUser;

  return (
    <div>
      <Upload
        showAvatar={false}
        disabled={readOnly || !ecosystem}
        multiple={false}
        showUploadList={false}
        accept="image/x-png,image/jpeg"
        beforeUpload={(file) => {
          const canBeUploaded = file.size / 1024 / 1024 < MAXIMUM_FILE_SIZE;

          if (!canBeUploaded) {
            message.error(
              intl.formatMessage(
                {
                  id: 'detailcontactform.avatarupload.error',
                  defaultMessage: 'File must be smaller than {max}MB!',
                },
                { max: MAXIMUM_FILE_SIZE },
              ),
            );
          }

          return canBeUploaded;
        }}
        customRequest={(event) => {
          storage.upload(
            `documents/${(event.file as RcFile).name}`,
            event.file as Blob,
            (progress: any) => {
              if (progress.status === 'success') {
                const documentToArchive: Partial<Document> = {
                  ecosystem,
                  documentUrl: progress.downloadUrl as string,
                  fileDetails: {
                    format: (event.file as RcFile).type.split('/')[1] as string,
                    size: (event.file as RcFile).size,
                  },
                  type: 'picture',
                  uploadingUser: user?.uid,
                  creatingUser: user?.uid,
                  uploadDate: new Date().toISOString(),
                  receivingDate: moment().format(moment.defaultFormat),
                  issuingDate: moment().format(moment.defaultFormat),
                  creationDate: moment().format(moment.defaultFormat),
                  relatedContact: '',
                  relatedContactSnapshot: {},
                  isDraft: false,
                  category: 'none',
                };

                postDocument(documentToArchive).then((id) => {
                  onDocAdd({
                    ...documentToArchive,
                    id,
                  });
                  if (event) {
                    //@ts-ignore
                    event.onSuccess(progress, event.file);
                  }
                });
              }
            },
          );
        }}
      />
    </div>
  );
};

export default AddArchivedDocument;
