import React, { FC, useState, useEffect, useRef } from 'react';
import { AutoComplete, Input, Row, Col, Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ApiResponse } from '@apps/contacts/services';
import { FormattedMessage, useIntl } from 'react-intl';
import { FilterTextObject } from '@services/filtering';
import { Product, getProducts } from '@apps/products/services';
import { ProductCreationModal } from '@apps/products/widgets/ProductCreationModal';

const { Panel } = Collapse;

type AutoCompleteLabelProps = {
  product: Product;
  activeKey?: string;
  setActiveKey: React.Dispatch<React.SetStateAction<string | undefined>>;
};
export const AutoCompleteLabel: FC<AutoCompleteLabelProps> = ({
  product,
  activeKey,
  setActiveKey,
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (panelRef.current !== null) {
      // @ts-ignore
      panelRef.current
        .querySelector('.ant-collapse-arrow')
        .addEventListener('click', (e) => {
          e.stopPropagation();
          setActiveKey(product.id !== activeKey ? product.id : undefined);
        });
    }
  }, [panelRef, product.id, activeKey, setActiveKey]);
  return (
    <div ref={panelRef}>
      <Collapse ghost activeKey={activeKey} expandIconPosition="right">
        <Panel
          key={product.id as string}
          forceRender
          header={
            <Row align="middle">
              <Col>
                {product.productName} {product.productNumber}
              </Col>
            </Row>
          }
        >
          <Row style={{ flexWrap: 'nowrap' }}>
            <Col flex="36px" />
          </Row>
        </Panel>
      </Collapse>
    </div>
  );
};

type AutoCompleteContactProps = {
  disabled: boolean;
  onSelect: (product: Product) => void;
  ecosystem?: string;
};

const AutoCompleteContact: FC<AutoCompleteContactProps> = ({
  disabled = false,
  onSelect,
  ecosystem,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [reloadProductsTrigger, setReloadProductsTrigger] = useState({});
  const intl = useIntl();
  useEffect(() => {
    getProducts(
      {
        fields: [
          'id',
          'ecosystem',
          'isActive',
          'productCreationDate',
          'productGroup',
          'productName',
          'productNumber',
          'unit',
          'description',
          'tags',
          'price',
          'tax',
        ],
        filters: {
          ecosystem: {
            filter: ecosystem,
            type: 'contains',
            filterType: 'text',
          } as FilterTextObject,
        },
      },
      'view-products',
    )?.then((res: ApiResponse) => {
      const results = (res.results as Product[]).filter((el) => el.isActive);

      setProducts(results);
    });
  }, [ecosystem, reloadProductsTrigger]);
  const searchLowerCase = search.toLowerCase();
  const options = products
    .filter(
      (c) =>
        c.productName?.toLowerCase().includes(searchLowerCase) ||
        c.productNumber?.toLowerCase().includes(searchLowerCase),
    )
    // limit results to 20
    .slice(0, 20)
    .map((c) => ({
      label: (
        <AutoCompleteLabel
          product={c}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
      ),
      value: c.id,
    }));

  return (
    <>
      <AutoComplete
        // clear input if it is disabled
        value={disabled ? '' : value}
        disabled={disabled}
        dropdownClassName="contacts-autocomplete-dropdown"
        // @ts-ignore
        options={options}
        onSearch={setSearch}
        onSelect={(productId) => {
          onSelect(products.filter((product) => productId === product.id)[0]);
          setValue('');
        }}
        onChange={setValue}
        style={{ width: '100%' }}
      >
        <Input
          prefix={<SearchOutlined />}
          placeholder={intl.formatMessage({
            id: 'widget.autocompleteproduct.placeholder',
            defaultMessage: 'Search in Products',
          })}
        />
      </AutoComplete>
      {!disabled && (
        <ProductCreationModal
          OnClickEl={(props: any) => (
            <a style={{ marginTop: 4, display: 'block' }} {...props}>
              <FormattedMessage
                id="product.widget.searchProduct.addNewProduct.label"
                defaultMessage="Add new product"
              />
            </a>
          )}
          onSubmit={() => setReloadProductsTrigger({})}
        />
      )}
    </>
  );
};

export default AutoCompleteContact;
