import { UserPreferences } from '@services/userPreferences/namespace';

export enum UserPreferencesActionTypes {
  UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES',
}

export const updateUserPreferences = (payload: UserPreferences) => ({
  type: UserPreferencesActionTypes.UPDATE_USER_PREFERENCES,
  payload,
});

export type UpdateUserPreferencesAction = ReturnType<
  typeof updateUserPreferences
>;

export type UserPreferencesAction = UpdateUserPreferencesAction;
