import React from 'react';
import intl from '../../../../../../../../intl';
import ProductDetailsToggle from '@apps/sales/widgets/SalesTab/Details/ProductDetailsToggle';
import ProductDetailsInput from '@apps/sales/widgets/SalesTab/Details/ProductDetailsInput';
import UnitAmount from '@apps/sales/widgets/SalesTab/Pricing/PriceForm/Price/UnitAmount';

const StandardPrice: React.FC = () => {
  return (
    <>
      <UnitAmount />
      <ProductDetailsToggle
        name="isTaxInclusive"
        label={intl.formatMessage({
          id: 'sales.salesTab.isTaxInclusive.label',
          defaultMessage: 'Is task inclusive?',
        })}
      />
      <ProductDetailsInput
        name="description"
        label={intl.formatMessage({
          id: 'sales.salesTab.description.label',
          defaultMessage: 'Description',
        })}
      />
    </>
  );
};

export default StandardPrice;
