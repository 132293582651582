import { styled } from '@styles/themes';
import { Input, Form, Tag, Button } from 'antd';
import MoneyInput from '@components/MoneyInput';

const { Item } = Form;

export const ProductsWithPricesContainer = styled.div`
  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }

  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
`;

export const SmallItalic = styled.div`
  font-size: 11px;
  font-style: italic;
`;

export const ShortInput = styled(Input)`
  max-width: 64px;
`;

export const ItemNoMargin = styled(Item)`
  margin: 0;
`;

export const StyledMoneyInput = styled(MoneyInput)`
  //max-width: 80px;
`;

export const ModifiedIndicator = styled(Tag)`
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  border: 0;
  display: flex;
  padding: 5px;
  z-index: 11;

  > * {
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const NewPositionButton = styled(Button)`
  margin-top: 10px;
`;

export const CustomCell = styled.td`
  max-width: 160px;
`;

export const AddProductButton = styled(Button)``;
