import { styled } from '@styles/themes';
import AppView from '@core/components/AppView';

export const InboxWrapper = styled(AppView)`
  && {
    .ant-tabs-content-holder {
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
`;
