import React, { FC } from 'react';
import { FooterGrid } from '@apps/purchases/mainMenu/components/styles.sc';
import { PurchaseOperationSettings } from '@apps/purchases/services/purchaseOperation';

type FooterProps = {
  user: User | null;
  ecosystem: Ecosystem;
  settings: PurchaseOperationSettings | null;
};

const Footer: FC<FooterProps> = ({ settings }) => {
  return (
    <FooterGrid>
      <div>
        {settings?.footer &&
          settings.footer.content[0]?.split('\n').map((el, index) => {
            return <div key={index}>{el}</div>;
          })}
      </div>
      <div>
        {settings?.footer &&
          settings.footer.content[1]?.split('\n').map((el, index) => {
            return <div key={index}>{el}</div>;
          })}
      </div>
      <div>
        {settings?.footer ? (
          settings.footer.content[2]?.split('\n').map((el, index) => {
            return <div key={index}>{el}</div>;
          })
        ) : (
          <div />
        )}
      </div>
      <div>
        {settings?.footer ? (
          settings.footer.content[3]?.split('\n').map((el, index) => {
            return <div key={index}>{el}</div>;
          })
        ) : (
          <div />
        )}
      </div>
    </FooterGrid>
  );
};

export default Footer;
