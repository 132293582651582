import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import {
  Tabs,
  Modal,
  message,
  Form,
  Select,
  DatePicker,
  Input,
  Spin,
} from 'antd';
import { DashboardOutlined } from '@ant-design/icons';

import { Task, getTask, deleteTask, patchTask } from '@apps/tasks/services';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import { FormattedMessage, useIntl } from 'react-intl';
import { getEcosystemsOptions } from '@apps/documents/mainMenu/Details/Form/formInjectors';
import optionsMapper from '@core/helpers/optionsMapper';
import AppConfig from '@apps/tasks';
import WidgetResolver from '@components/WidgetResolver';

const { TabPane } = Tabs;

const formLayout = {
  wrapperCol: {
    span: 12,
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 10,
    },
    xxl: {
      span: 6,
    },
    offset: 0,
  },
};

export const SideContent: FC<{ id: string }> = ({ id }) => {
  const [task, setTask] = useState<Task | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getTask(id)
        .then((task) =>
          setTask({
            ...task,
            creationDate:
              task.creationDate &&
              moment(task.creationDate, moment.defaultFormat),
          }),
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);
  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={
        <ActionRenderer
          path={`/app/${AppConfig.todixId}/${id}`}
          options={optionsMapper(['open', 'edit', 'delete'], intl)}
          handleDelete={() => {
            deleteTask(id)
              ?.then(() => {
                // message.success(
                //   intl.formatMessage({
                //     id: 'tasks.deletetask.success',
                //     defaultMessage: 'Task deleted',
                //   }),
                // );
              })
              .catch(() => {
                message.error(
                  intl.formatMessage({
                    id: 'tasks.deletetask.error',
                    defaultMessage: 'Error while deleting task',
                  }),
                );
              });
          }}
          extraOptions={[
            {
              onClick: () => {
                Modal.confirm({
                  content: intl.formatMessage(
                    {
                      id: 'tasks.sidecontent.confirmation',
                      defaultMessage:
                        'Are you sure you want to {action} this item?',
                    },
                    { action: 'TODOD' },
                  ),
                  onOk: () => {
                    patchTask(id, {})
                      .then(() => {
                        // message.success(
                        //   intl.formatMessage({
                        //     id: 'tasks.patchtask.success',
                        //     defaultMessage: 'Task updated',
                        //   }),
                        // );
                      })
                      .catch(() => {
                        message.error(
                          intl.formatMessage({
                            id: 'tasks.patchtask.error',
                            defaultMessage: 'Error while updating task',
                          }),
                        );
                      });
                  },
                });
              },
            },
          ]}
          intl={intl}
        />
      }
    >
      <TabPane
        key="1"
        tab={
          <>
            <DashboardOutlined />{' '}
            <FormattedMessage
              id="tasks.sidecontent.basetab"
              defaultMessage="Base Tab"
            />
          </>
        }
      >
        <Spin spinning={isLoading}>
          {!!task && !isLoading && (
            <Form
              initialValues={{
                ...task,
                creationDate: moment(task.creationDate),
                followers: task.followers || [],
              }}
              colon={false}
              labelAlign="left"
              {...formLayout}
            >
              <Form.Item
                name="ecosystem"
                label={
                  <FormattedMessage
                    id="tasks.sidecontent.generalinformation.ecosystem.label"
                    defaultMessage="Ecosystem"
                  />
                }
              >
                <Select
                  options={getEcosystemsOptions()}
                  showArrow={false}
                  bordered={false}
                  disabled
                />
              </Form.Item>
              <Form.Item
                name="creationDate"
                label={
                  <FormattedMessage
                    id="tasks.sidecontent.generalinformation.creationdate.label"
                    defaultMessage="Creation date"
                  />
                }
              >
                <DatePicker format={moment.defaultFormat} disabled />
              </Form.Item>
              <Form.Item
                name="title"
                label={
                  <FormattedMessage
                    id="t.sidecontent.generalinformation.title.label"
                    defaultMessage="Title"
                  />
                }
              >
                <Input readOnly />
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="assignee"
                      label={
                        <FormattedMessage
                          id="tasks.assignee.label"
                          defaultMessage="Assignee"
                        />
                      }
                    >
                      <WidgetResolver
                        appName="projects"
                        widgetName="MemberSelector"
                        injectedProps={{
                          placeholder: (
                            <FormattedMessage
                              id="tasks.assignee.addNew.button"
                              defaultMessage="Assign new user"
                            />
                          ),
                          ecosystem: getFieldValue('ecosystem'),
                          value: getFieldValue('assignee'),
                          readOnly: true,
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="followers"
                      label={
                        <FormattedMessage
                          id="tasks.followers.label"
                          defaultMessage="Followers"
                        />
                      }
                    >
                      <WidgetResolver
                        appName="projects"
                        widgetName="MemberSelector"
                        injectedProps={{
                          placeholder: (
                            <FormattedMessage
                              id="tasks.follower.addNew.button"
                              defaultMessage="Add new follower"
                            />
                          ),
                          ecosystem: getFieldValue('ecosystem'),
                          value: getFieldValue('followers'),
                          readOnly: true,
                        }}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Form>
          )}
        </Spin>
      </TabPane>
      {/* <TabPane
        key="2"
        tab={
          <>
            <HistoryOutlined />{' '}
            <FormattedMessage
              id="tasks.sidecontent.timeline"
              defaultMessage="Timeline"
            />
          </>
        }
      /> */}
    </Tabs>
  );
};
