import styled from 'styled-components';

const AgThemeTodix = styled.div`
  height: 100%;
  .ag-root {
    background: #fff;
  }
  .ag-header {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  }
  .ag-header,
  .ag-header-row {
    top: 0 !important;
    height: 40px !important;
    min-height: 40px !important;
  }

  .ag-header-cell {
    //border: solid 1px #e8e8e8;
    background-color: #fafafa;
    height: 40px;
    padding: 10px 24px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.text.normal};
  }
  .ag-header-cell:hover {
    border-right: solid 1px #e8e8e8;
  }
  .ag-row .ag-cell:first-of-type {
    font-weight: 600;
  }
  .ag-row-hover {
    background: #f6ffed70;
    cursor: pointer;
  }
  .ag-row-selected {
    background: #f6ffed;
  }
  .ag-cell {
    border: solid 1px #e8e8e8;
    padding: 16px 24px;
    color: #262626;

    .ant-input-affix-wrapper {
      border: 0;
      background-color: transparent;

      input {
        background-color: transparent !important;
      }
    }
  }
  .ag-input-wrapper {
    width: 16px;
    margin-right: 10px;
  }

  .ag-menu {
    background: #fff;
    padding: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    min-width: 100px;
    .ag-filter-condition {
      display: none;
    }
    .ag-filter-body-wrapper {
      .ag-filter-body[ref='eCondition2Body'] {
        display: none;
      }
      .ag-filter-select[ref='eOptions2'] {
        display: none;
      }
    }
  }
  // .ag-select {
  //   display: none;
  // }
  .ag-picker-field-wrapper {
    border: solid 1px #ccc;
    padding: 2px 10px;
    margin-bottom: 8px;
  }
  .ag-list {
    background: #fff;
  }
  .ag-menu {
    padding: 10px 16px;
    .ag-input-wrapper {
      margin: 0;
      width: initial;
    }
    .ag-input-field-input {
      border: solid 1px #d9d9d9;
      border-radius: 2px;
      padding: 2px 8px;
    }
  }
  .ag-tooltip {
    background: #fff;
    border: solid 1px #ccc;
  }
`;

export default AgThemeTodix;
