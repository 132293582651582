import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type NeedAmountProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const NeedAmount: FC<NeedAmountProps> = ({ namePrefix, readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name={
        namePrefix !== undefined ? [namePrefix, `needAmount`] : `needAmount`
      }
      label={<FormattedMessage id="need.amount" defaultMessage="Need amount" />}
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="need.amount.missing"
              defaultMessage="Need amount is missing"
            />
          ),
        },
      ]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'need.amount',
          defaultMessage: 'Need amount',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default NeedAmount;
