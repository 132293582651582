import React from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getCountries } from '@services/dictionary';
import SelectWithSearch from '@components/SelectWithSearch';

const countries = getCountries();
const CountryOfRegistration = ({ readOnly }: { readOnly?: boolean }) => {
  return (
    <Form.Item
      name="registrationCountry"
      label={
        <FormattedMessage
          id="documentprocessing.step2.countryofregistration.label"
          defaultMessage="Country of Registration"
        />
      }
      required={false}
      rules={[
        {
          required: false,
          message: (
            <FormattedMessage
              id="documentprocessing.step2.countryofregistration.error.required"
              defaultMessage="Missing country of registration"
            />
          ),
        },
      ]}
    >
      <SelectWithSearch
        placeholder={
          <FormattedMessage
            id="documentprocessing.step2.countryofregistration.placeholder"
            defaultMessage="Select country of registration"
          />
        }
        loading={!countries.length}
        options={countries.map((entry) => ({
          label: entry.countryName,
          value: entry.countryName,
        }))}
        disabled={readOnly}
      />
    </Form.Item>
  );
};

export default CountryOfRegistration;
