import React, { FC, useState, useEffect } from 'react';
import { Commission } from '@apps/directSales/services/commisions';
import { Spin } from 'antd';
import { getConsultantLevel } from '@apps/directSales/services/consultantLevel';

type Props = {
  value: Commission['relatedLevel'];
};

const RelatedLevelRenderer: FC<Props> = ({ value }) => {
  const [level, setLevel] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (value) {
      getConsultantLevel(value)
        .then((entry) => {
          setLevel(entry.level);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [value]);

  if (loading) {
    return <Spin spinning={loading} />;
  }

  if (level) {
    return <span>{level}</span>;
  }

  return <div />;
};

export default RelatedLevelRenderer;
