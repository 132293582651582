import React from 'react';
import moment from 'moment';

export type Props = {
  colDef: any;
  data: any;
  value: any;
  [key: string]: any;
};

const DateRenderer = ({ value }: Props) => {
  if (!value) {
    return '';
  }

  if (
    !Number.isInteger(value) &&
    !moment.isMoment(value) &&
    !value?.includes('T') &&
    !value?.includes('Z')
  ) {
    return value;
  }

  const stringFromMoment = moment(value).format(moment.defaultFormat);
  return (
    <span>
      {stringFromMoment.includes('Invalid') ? value : stringFromMoment}
    </span>
  );
};

export default DateRenderer;
