import React from 'react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getLanguages } from '@services/dictionary';
import SelectWithSearch from '@components/SelectWithSearch';

const langs = getLanguages();
const LanguageOfContact = ({
  readOnly,
  required = true,
}: {
  readOnly?: boolean;
  required?: boolean;
}) => {
  return (
    <Form.Item
      name="contactLanguage"
      label={
        <FormattedMessage
          id="documentprocessing.languageofcontact.label"
          defaultMessage="Language of Contact"
        />
      }
      required={required}
      rules={
        required
          ? [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="documentprocessing.languageofcontact.error"
                    defaultMessage="Missing language of contact"
                  />
                ),
              },
            ]
          : []
      }
    >
      <SelectWithSearch
        placeholder={
          <FormattedMessage
            id="documentprocessing.languageofcontact.placeholder"
            defaultMessage="Select language of contact"
          />
        }
        loading={!langs.length}
        options={langs.map((entry) => ({
          label: entry,
          value: entry,
        }))}
        disabled={readOnly}
      />
    </Form.Item>
  );
};

export default LanguageOfContact;
