import React, { FC } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import { IntlShape } from 'react-intl';
import { getMoneyValue } from '@components/MoneyInput';
import { currencySigns } from '@services/currency';

const HEADER_FONT_SIZE = 9;
const CELL_FONT_SIZE = 10;
const padding4px = '4px 4px';

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    border: '1px solid #f0f0f0',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: HEADER_FONT_SIZE,
    backgroundColor: '#fafafa',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  itemCellPosition: {
    fontSize: CELL_FONT_SIZE,
    minWidth: '32px',
    maxWidth: '32px',
    padding: padding4px,
    borderRight: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
  },
  itemCellTax: {
    fontSize: CELL_FONT_SIZE,
    minWidth: '32px',
    maxWidth: '32px',
    padding: padding4px,
    borderRight: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
  },
  itemCellProduct: {
    fontSize: CELL_FONT_SIZE,
    minWidth: '100px',
    maxWidth: '100px',
    padding: padding4px,
    borderRight: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
  },
  itemCellAmount: {
    fontSize: CELL_FONT_SIZE,
    minWidth: '56px',
    maxWidth: '56px',
    padding: padding4px,
    borderRight: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
  },
  itemCellUnit: {
    fontSize: CELL_FONT_SIZE,
    minWidth: '72px',
    maxWidth: '72px',
    padding: padding4px,
    borderRight: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
  },
  itemCellPrice: {
    fontSize: CELL_FONT_SIZE,
    minWidth: '56px',
    maxWidth: '56px',
    padding: padding4px,
    borderRight: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
  },
  itemCellDiscount: {
    fontSize: CELL_FONT_SIZE,
    minWidth: '56px',
    maxWidth: '56px',
    padding: padding4px,
    borderRight: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
  },
  itemCell: {
    fontSize: CELL_FONT_SIZE,
    padding: padding4px,
    borderRight: '1px solid #f0f0f0',
    borderBottom: '1px solid #f0f0f0',
  },
});

type Props = {
  products: SalesFormValues['products'];
  intl: IntlShape;
};

const ProductsTablePDF: FC<Props> = ({ products, intl }) => {
  return (
    <View style={styles.table}>
      <View style={styles.header}>
        <Text style={styles.itemCellPosition}>
          {intl.formatMessage({
            id: 'sales.createForm.position.label',
            defaultMessage: 'Pos.',
          })}
        </Text>
        <Text style={styles.itemCellProduct}>
          {intl.formatMessage({
            id: 'sales.createNew.item',
            defaultMessage: 'Product/Service',
          })}
        </Text>
        <Text style={styles.itemCellAmount}>
          {intl.formatMessage({
            id: 'sales.createForm.amount.label',
            defaultMessage: 'Amount',
          })}
        </Text>
        <Text style={styles.itemCellUnit}>
          {intl.formatMessage({
            id: 'products.selectedunit.label',
            defaultMessage: 'Unit',
          })}
        </Text>
        <Text style={styles.itemCellPrice}>
          {intl.formatMessage({
            id: 'sales.createForm.netPrice.label',
            defaultMessage: 'Net price',
          })}
        </Text>
        <Text style={styles.itemCellDiscount}>
          {intl.formatMessage({
            id: 'sales.createForm.discount.label',
            defaultMessage: 'Discount (%)',
          })}
        </Text>
        <Text style={styles.itemCellPrice}>
          {intl.formatMessage({
            id: 'sales.createForm.discountedPrice.label',
            defaultMessage: 'Discounted price',
          })}
        </Text>
        <Text style={styles.itemCellTax}>
          {intl.formatMessage({
            id: 'sales.createForm.tax.label',
            defaultMessage: 'Tax (%)',
          })}
        </Text>
        <Text style={styles.itemCellPrice}>
          {intl.formatMessage({
            id: 'sales.createForm.grossPrice.label',
            defaultMessage: 'Gross price',
          })}
        </Text>
      </View>
      {products.map((prod, index) => {
        const pos = index + 1;
        return (
          <View style={styles.item} key={index}>
            <Text style={styles.itemCellPosition}>{pos}</Text>
            <View style={styles.itemCellProduct} key={index}>
              <Text>
                {prod?.productName || ''}
                {prod?.productNumber ? ` (${prod?.productNumber})` : ''}
              </Text>
              <Text style={{ fontStyle: 'italic' }}>
                {prod?.description || ''}
              </Text>
            </View>
            <Text style={styles.itemCellAmount}>{prod.amount}</Text>
            <Text style={styles.itemCellUnit}>
              {prod?.salesUnit || prod.saleDetails?.salesUnit || ''}
            </Text>
            <Text style={styles.itemCellPrice}>
              {getMoneyValue({
                value: prod?.price ? `${prod.price}` : '',
                prefix: currencySigns['EUR'],
              })?.result || ''}
            </Text>
            <Text style={styles.itemCellDiscount}>{prod.discount || 0}</Text>
            <Text style={styles.itemCellPrice}>
              {getMoneyValue({
                value: prod?.discountedPrice
                  ? `${prod.discountedPrice}`
                  : `${prod.price || 0}`,
                prefix: currencySigns['EUR'],
              })?.result || ''}
            </Text>
            <Text style={styles.itemCellTax}>
              {prod.taxValue !== undefined ? `${prod.taxValue}%` : '19%'}
            </Text>
            <Text style={styles.itemCellPrice}>
              {getMoneyValue({
                value: prod?.grossPrice
                  ? `${prod.grossPrice}`
                  : `${prod.grossPrice || 0}`,
                prefix: currencySigns['EUR'],
              })?.result || ''}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export default ProductsTablePDF;
