import React, { FC, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Descriptions, AutoComplete, Input, Collapse } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getDocumentsArchived, Document } from '@apps/documents/service';
import { ApiResponse } from '@apps/contacts/services';
import { FormattedMessage, useIntl } from 'react-intl';
import UserAvatar from '@components/UserAvatar';
import { FilterTextObject } from '@services/filtering';
import { getName } from '@core/helpers/documents';

const { Panel } = Collapse;

const AutoCompleteDesc = styled(Descriptions)`
  .ant-descriptions-item-label,
  .ant-descriptions-item-content {
    font-size: 12px;
  }
`;
type AutoCompleteLabelProps = {
  document: Document;
  activeKey?: string;
  setActiveKey: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const AutoCompleteLabel: FC<AutoCompleteLabelProps> = ({
  document,
  activeKey,
  setActiveKey,
}) => {
  const intl = useIntl();
  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (panelRef.current !== null) {
      // @ts-ignore
      panelRef.current
        .querySelector('.ant-collapse-arrow')
        .addEventListener('click', (e) => {
          e.stopPropagation();
          setActiveKey(document.id !== activeKey ? document.id : undefined);
        });
    }
  }, [panelRef, document.id, activeKey, setActiveKey]);
  return (
    <div ref={panelRef}>
      <Collapse ghost activeKey={activeKey} expandIconPosition="right">
        <Panel
          key={document.id as string}
          forceRender
          header={<span>{getName(document, intl)}</span>}
        >
          <AutoCompleteDesc column={1} size="small">
            <Descriptions.Item
              label={
                <FormattedMessage
                  id="documents.autocompletedocument.item.type.label"
                  defaultMessage="Type"
                />
              }
            >
              {document.type}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <FormattedMessage
                  id="documents.autocompletedocument.item.number.label"
                  defaultMessage="Number"
                />
              }
            >
              {document.docNumber}
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <FormattedMessage
                  id="documents.autocompletedocument.item.uploaduser.label"
                  defaultMessage="Upload user"
                />
              }
            >
              {typeof document.uploadingUser === 'string' ? (
                document.uploadingUser
              ) : (
                <UserAvatar user={document.uploadingUser as CommonUserData} />
              )}
            </Descriptions.Item>
          </AutoCompleteDesc>
        </Panel>
      </Collapse>
    </div>
  );
};

type AutoCompleteDocumentProps = {
  ecosystem?: string;
  disabled?: boolean;
  hiddenValues?: string[];
  onSelect?: (id: string) => void;
};

const AutoCompleteDocument: FC<AutoCompleteDocumentProps> = ({
  disabled = false,
  hiddenValues = [],
  onSelect,
  ecosystem,
  // ...rest
}) => {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>(undefined);
  const [value, setValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const intl = useIntl();
  useEffect(() => {
    getDocumentsArchived('view-documents', {
      fields: [
        'id',
        'type',
        'category',
        'docNumber',
        'uploadingUser',
        'receivingDate',
        'relatedContact',
        'relatedContactSnapshot',
      ],
      filters: {
        ecosystem: {
          filter: ecosystem,
          type: 'contains',
          filterType: 'text',
        } as FilterTextObject,
      },
    }).then((res: ApiResponse) => {
      setDocuments(res.results as Document[]);
    });
  }, []);

  const options = documents
    .filter((document) => !hiddenValues.includes(document.id as string))
    .filter((doc) => {
      const lowerCaseSearch = search.toLowerCase();

      if (
        doc.docNumber &&
        doc.docNumber.toLowerCase().includes(lowerCaseSearch)
      ) {
        return true;
      }
      if (
        doc.type &&
        intl
          .formatMessage({
            id: `documents.documentTypes.${doc.type}`,
            defaultMessage: doc.type,
          })
          .toLowerCase()
          .includes(lowerCaseSearch)
      ) {
        return true;
      }
      if (
        doc.relatedContactSnapshot &&
        doc.relatedContactSnapshot.companyName &&
        doc.relatedContactSnapshot.companyName
          .toLowerCase()
          .includes(lowerCaseSearch)
      ) {
        return true;
      }
      if (
        doc.relatedContactSnapshot &&
        `${doc.relatedContactSnapshot.firstName}${doc.relatedContactSnapshot.lastName}`
          .toLowerCase()
          .includes(lowerCaseSearch)
      ) {
        return true;
      }
      return false;
    })
    .map((document) => ({
      label: (
        <AutoCompleteLabel
          document={document}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
        />
      ),
      value: document.id,
    }));
  return (
    <AutoComplete
      value={value}
      disabled={disabled}
      dropdownClassName="contacts-autocomplete-dropdown"
      // @ts-ignore
      options={options}
      onSearch={setSearch}
      onSelect={(id) => {
        setValue('');
        if (onSelect) {
          onSelect(id as string);
        }
      }}
      style={{ width: '100%' }}
      onChange={(value) => {
        setValue(value);
      }}
    >
      <Input
        prefix={<SearchOutlined />}
        placeholder={intl.formatMessage({
          id: 'documents.autocompletedocument.placeholder',
          defaultMessage: 'Search in Documents',
        })}
      />
    </AutoComplete>
  );
};

export default AutoCompleteDocument;
