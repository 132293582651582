import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, DatePicker } from 'antd';
import moment from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { FlowOption } from '@apps/warehouse/services/namespace';

const { Item } = Form;

type DateEntryProps = {
  flow: FlowOption | null;
};

const DateEntry: FC<DateEntryProps> = () => {
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <Item
      name="timestamp"
      label={
        <FormattedMessage
          id="warehouse.create.entry.date"
          defaultMessage="Date"
        />
      }
    >
      <DatePicker
        format={moment.defaultFormat}
        disabledDate={disabledDate}
        getPopupContainer={() => {
          const mainElement = document
            .getElementsByClassName('fullscreen')
            .item(0) as HTMLElement;
          return mainElement || document.body;
        }}
      />
    </Item>
  );
};

export default DateEntry;
