import moment from 'moment';

export const TASKS_COLLECTION_NAME = 'tasks';

type Assignee = {
  displayName: string;
  email: string;
  photoURL?: string;
  id?: string;
};

export type Task = {
  id?: string;
  ecosystem: string;
  creationDate: moment.Moment | string;
  createdBy: string;
  title: string;
  description: string;
  dueDate: moment.Moment | string;
  status: 'open' | 'inProgress' | 'done';
  assignee: string;
  followers?: string[];
  assignees?: Assignee[];
  progress: number;
  startDate: string;
  doneDate: string;
  creator: User;
  documents: string[];
  tags: string[];
  timestamp?: number;
};
