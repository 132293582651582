import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { IGetRowsParams } from 'ag-grid-community';
import { FilterTextObject } from '@services/filtering';
import AppView from '@components/AppView';
import columns from '@apps/sales/mainMenu/salesList/columns';
import optionsMapper from '@core/helpers/optionsMapper';
import getSales from '@apps/sales/services/getSales';
import appConfig from '@apps/sales';

const SalesList: FC = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();

  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getSales(
      {
        query: params.query,
        offset: params.startRow,
        limit: params.endRow - params.startRow,
        fields: [
          'id',
          'name',
          'ecosystem',
          'contact',
          'contactSnapshot',
          'paymentStatus',
          'shipmentStatus',
          'products',
          'timestamp',
        ],
        filters: Object.fromEntries(filtersEntries),
        sort: params.sortModel.map(
          (model: { colId: string; sort: string }) =>
            `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
        ),
      },
      'view-sales',
    )?.then((response) => {
      params.successCallback(response.results, response.info.results);
    });
  };

  return (
    <AppView
      hideQuickFilter
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'app.sales',
            defaultMessage: 'Sales',
          }),
        ],
      ]}
      columns={useMemo(() => columns(intl), [intl])}
      getRows={getRows}
      sideContent={() => <div />}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      options={optionsMapper(['open'], intl)}
      appConfig={appConfig}
    />
  );
};

export default SalesList;
