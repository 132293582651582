import { styled } from '@styles/themes';
import { Layout } from 'antd';

export const LogoWrapper = styled.div`
  width: 304px;
  min-width: 304px;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: solid 1px #fafafa;
  font-family: Kanit;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: left;
  color: #135200;
  user-select: none;
`;

export const Logo = styled.div`
  width: 32px;
  height: 30px;
  margin-left: 41px;
  margin-right: 15px;
`;

export const Header = styled(Layout.Header)`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.03);
  background-color: ${(props) => props.theme.colors.background.light};
  padding: 0 24px 0 0;
  height: 56px;
  line-height: unset;
  display: flex;
  align-items: center;
  width: 100%;
`;
