import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import dateFilter from '@core/helpers/dateFilter';
import getAddressColumn from './components/AddressColumn';
import {
  ColSpanParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';

export const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="ecosystem"
      cellRenderer={'ecosystemRenderer'}
      filter="ecosystemFilter"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      headerName={intl.formatMessage({
        id: 'contacts.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
    />
    <AgGridColumn
      field="Image"
      cellRenderer={'avatarRenderer'}
      filter={false}
      maxWidth={110}
      headerName={intl.formatMessage({
        id: 'contacts.columns.image.header',
        defaultMessage: 'Image',
      })}
    />
    <AgGridColumn
      field="firstName"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'contacts.columns.firstname.header',
        defaultMessage: 'First Name',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="lastName"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'contacts.columns.lastname.header',
        defaultMessage: 'Last Name',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="nickName"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'detailcontactform.personinfo.nickname.label',
        defaultMessage: 'Nickname',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="companyName"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'detailcontactform.generalinformation.type.company',
        defaultMessage: 'Company',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="employer"
      filter={true}
      headerName={intl.formatMessage({
        id: 'detailcontactform.personinfo.employer.label',
        defaultMessage: 'Employer',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="department"
      filter={true}
      headerName={intl.formatMessage({
        id: 'detailcontactform.personinfo.department.label',
        defaultMessage: 'Department',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="titlePosition"
      filter={true}
      headerName={intl.formatMessage({
        id: 'detailcontactform.personinfo.titleposition.label',
        defaultMessage: 'Title position',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="birthDate"
      headerName={intl.formatMessage({
        id: 'detailcontactform.personinfo.birthdate.label',
        defaultMessage: 'Birth date',
      })}
      filter="agDateColumnFilter"
      filterParams={dateFilter}
    />
    <AgGridColumn
      filter="phoneNumberFilter"
      field="phones"
      valueGetter={({ data }: ValueGetterParams) =>
        data?.phones && data?.phones.length
          ? data.standardPhone && data?.phones[data.standardPhone]
            ? data?.phones[data.standardPhone].number
            : data?.phones[0].number
          : null
      }
      headerName={intl.formatMessage({
        id: 'contacts.columns.phone.header',
        defaultMessage: 'Phone',
      })}
    />
    <AgGridColumn
      field="emails"
      filter="emailFilter"
      valueGetter={({ data }: ValueGetterParams) =>
        data?.emails && data?.emails.length
          ? data.standardEmail && data?.emails[data.standardEmail]
            ? data?.emails[data.standardEmail].email
            : data?.emails[0].email
          : null
      }
      headerName={intl.formatMessage({
        id: 'contacts.columns.email.header',
        defaultMessage: 'Email',
      })}
    />
    <AgGridColumn
      filter="cityFilter"
      field="addresses"
      valueGetter={({ data }: ValueGetterParams) =>
        data?.addresses && data?.addresses.length
          ? data?.standardInvoiceAddress &&
            data?.addresses[data?.standardInvoiceAddress]
            ? data?.addresses[data?.standardInvoiceAddress].city
            : data?.addresses[0].city
          : null
      }
      filterValueGetter={({ data }: ValueGetterParams) =>
        data?.addresses && data?.addresses.length
          ? data?.standardInvoiceAddress &&
            data?.addresses[data?.standardInvoiceAddress]
            ? data?.addresses[data?.standardInvoiceAddress].city
            : data?.addresses[0].city
          : null
      }
      headerName={intl.formatMessage({
        id: 'contacts.columns.city.header',
        defaultMessage: 'City',
      })}
    />
    {getAddressColumn({
      fieldName: 'additionalInfo',
      textDefaultMessage: 'Additional Info',
      textId: 'contacts.columns.additionalInfo.header',
      intl: intl,
    })}
    {getAddressColumn({
      fieldName: 'country',
      textDefaultMessage: 'Country',
      textId: 'contacts.columns.country.header',
      intl: intl,
    })}
    {getAddressColumn({
      fieldName: 'district',
      textDefaultMessage: 'District',
      textId: 'contacts.columns.district.header',
      intl: intl,
    })}
    {getAddressColumn({
      fieldName: 'label',
      textDefaultMessage: 'Label',
      textId: 'contacts.columns.label.header',
      intl: intl,
    })}
    {getAddressColumn({
      fieldName: 'number',
      textDefaultMessage: 'Number',
      textId: 'contacts.columns.number.header',
      intl: intl,
    })}
    {getAddressColumn({
      fieldName: 'street',
      textDefaultMessage: 'Street',
      textId: 'contacts.columns.street.header',
      intl: intl,
    })}
    {getAddressColumn({
      fieldName: 'zipcode',
      textDefaultMessage: 'Zipcode',
      textId: 'contacts.columns.zipcode.header',
      intl: intl,
    })}
    {getAddressColumn({
      fieldName: 'additionalInfo',
      textDefaultMessage: 'Additional Info',
      textId: 'contacts.columns.additionalInfo.header',
      intl: intl,
    })}
    <AgGridColumn
      field="memo"
      filter={true}
      headerName={intl.formatMessage({
        id: 'detailcontactform.generalinformation.memo.label',
        defaultMessage: 'Memo',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      cellRenderer={'actionRenderer'}
      maxWidth={70}
      filter={false}
    />
  </>
);
