import React from 'react';
import MoneyInput from '@components/MoneyInput';
import { styled } from '@styles/themes';

export type MoneyValueRendererProps = {
  colDef: any;
  data: any;
  value: any;
  [key: string]: any;
};

export const StyledMoneyInput = styled(MoneyInput)`
  input {
    text-align: right;
  }
`;

const MoneyValueRenderer = ({ value, currency }: MoneyValueRendererProps) => {
  if (value === null || value === undefined) {
    return '';
  }

  return (
    <StyledMoneyInput value={value} currency={currency || 'EUR'} readOnly />
  );
};

export default MoneyValueRenderer;
