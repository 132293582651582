import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Product } from '@apps/products/services/namespace';
import getProductsForEcosystem from '@apps/products/services/getProductsForEcosystem';
import intl from 'intl';

type ProductSelectorProps = SelectProps & {
  selectedEcosystem: string | null;
  onProductSelect?(product: Product): void;
};

const ProductSelector: FC<ProductSelectorProps> = ({
  selectedEcosystem,
  onProductSelect,
  ...rest
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedEcosystem) {
      setLoading(true);
      getProductsForEcosystem(selectedEcosystem, 'view-products')
        ?.then(setProducts)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedEcosystem]);

  const options = useMemo(() => {
    return products.map((product) => ({
      value: product.id,
      'data-forsearch': `${product.productNumber || ''} ${
        product.productName || ''
      }`,
      label: (
        <div
          key={product.id}
        >{`${product.productNumber} - ${product.productName}`}</div>
      ),
    }));
  }, [products]);

  const handleSelect = useCallback(
    (productId: any) => {
      const product = products.find((product) => product.id === productId);
      if (product && onProductSelect) {
        onProductSelect(product);
      }
    },
    [products, onProductSelect],
  );

  return (
    <Select
      {...rest}
      className="animate__animated animate__slideInLeft animate__faster"
      disabled={!selectedEcosystem || loading}
      loading={loading}
      onSelect={handleSelect}
      style={{ width: '50%', marginBottom: 16 }}
      placeholder={intl.formatMessage({
        id: 'warehouse.create.select.product.placeholder',
        defaultMessage: 'Select product...',
      })}
      showSearch
      filterOption={(inputValue, option) => {
        return option
          ? option['data-forsearch']
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          : false;
      }}
      options={options}
    />
  );
};

export default ProductSelector;
