import React, { FC, useCallback, useState } from 'react';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { firebase } from '@services/Firebase';
import { Form } from 'antd';
import AppConfig, { WAREHOUSE_ACTION } from '@apps/warehouse';
import FormContainer from '@components/FormContainer';
import { Document } from '@apps/documents/service/namespace';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import SelectOptionFlow from '@apps/warehouse/mainMenu/components/FormComponents/SelectOptionFlow';
import SelectedEcosystem from '@components/SelectedEcosystem';
import DateEntry from '@apps/warehouse/mainMenu/components/FormComponents/DateEntry';
import Order from '@apps/warehouse/mainMenu/components/FormComponents/Order';
import Supplier from '@apps/warehouse/mainMenu/components/FormComponents/Supplier';
import User from '@apps/warehouse/mainMenu/components/FormComponents/User';
import Reason from '@apps/warehouse/mainMenu/components/FormComponents/Reason';
import { FlowOption, ProductEntry } from '@apps/warehouse/services/namespace';
import DeliveryNoteSelect from './FormComponents/DeliveryNoteSelect';
import {
  BucketPosition,
  Bucket,
} from '@apps/purchases/services/buckets/namespace';
import { getBucket } from '@apps/purchases/services/buckets';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';

type FlowManagementFormProps = {
  entries: ProductEntry[];
  setEntries(entry: ProductEntry[]): void;
  onSubmit(values: any): void;
  onEcosystemSelect(id: string): void;
  ecosystemId?: string;
  action: WAREHOUSE_ACTION;
  onFlowSelect?(flow: FlowOption | null): void;
  entriesValid?: boolean;
};

const FlowManagementForm: FC<FlowManagementFormProps> = ({
  onSubmit,
  onEcosystemSelect,
  entries,
  setEntries,
  ecosystemId,
  action,
  onFlowSelect,
  entriesValid = true,
}) => {
  const user = firebase.auth.currentUser;
  const [flow, setFlow] = useState<FlowOption | null>(null);
  const [form] = useForm();
  const onOptionChange = useCallback(
    (option) => {
      setFlow(option as FlowOption);
      if (onFlowSelect) {
        onFlowSelect(option as FlowOption);
      }
      form.setFieldsValue({
        supplier: '',
        orderId: '',
      });
    },
    [form, onFlowSelect],
  );
  const onEcosystemChange = useCallback(
    (id: string) => {
      form.setFieldsValue({
        supplier: '',
        orderId: '',
      });
      setEntries([]);
      onEcosystemSelect(id);
    },
    [setEntries, form, onEcosystemSelect],
  );
  const handleSubmitWithEntries = useCallback(
    (values) => {
      if (entries.length) {
        onSubmit({
          ...values,
          entries,
          flow,
        });
      }
    },
    [entries, flow, onSubmit],
  );

  const handleOrderSelect = async (
    orderBucket: Bucket,
    mode: 'add' | 'replace',
    order: Document,
  ) => {
    const { positions } = orderBucket;
    const ecosystem = form.getFieldValue('ecosystem');
    const relatedBuckets = await getChildrenForParent({
      ecosystem: ecosystem,
      parentId: orderBucket.id as string,
      childType: 'bucket',
    });
    const bucketsToFetch = relatedBuckets.map((el) => getBucket(el.childId));
    const alreadyDeliveredBuckets = await Promise.all(bucketsToFetch);

    const deliveredAmountsPerProduct = alreadyDeliveredBuckets.reduce(
      (acc, bucket) => {
        bucket.positions.forEach((position) => {
          if (position.productId) {
            if (!acc[position.productId]) {
              acc[position.productId] = 0;
            }
            acc[position.productId] += position.deliveredAmount || 0;
          }
        });
        return acc;
      },
      {} as Record<string, number>,
    );

    if (mode === 'replace') {
      const newEntries = positions
        .filter((pos) => {
          if (deliveredAmountsPerProduct[pos.productId as string]) {
            return (
              deliveredAmountsPerProduct[pos.productId as string] !==
              pos.needAmount
            );
          } else {
            return pos.deliveredAmount !== pos.needAmount;
          }
        })
        .map((pos: BucketPosition, index: number) => ({
          id: pos.id,
          key: index,
          productId: pos.productId,
          productName: pos.productName,
          productNumber: pos.productNumber,
          hasSerialNumber: pos.hasSerialNumber,
          hasBatchNumber: pos.hasBatchNumber,
          unitAmount: pos.unitAmount,
          amount: deliveredAmountsPerProduct[pos.productId as string]
            ? pos.needAmount -
              deliveredAmountsPerProduct[pos.productId as string]
            : pos.needAmount,
          deliveredAmount: deliveredAmountsPerProduct[pos.productId as string]
            ? pos.needAmount -
              deliveredAmountsPerProduct[pos.productId as string]
            : pos.needAmount,
          unit: pos.unit,
          order,
          orderBucket,
        }));
      setEntries(newEntries);
    } else {
      const newPositions = positions
        .filter(
          (pos) =>
            !entries.some(
              (entry) =>
                entry.productId === pos.productId &&
                entry.order?.id === order.id,
            ),
        )
        .filter((pos) => {
          if (deliveredAmountsPerProduct[pos.productId as string]) {
            return (
              deliveredAmountsPerProduct[pos.productId as string] !==
              pos.needAmount
            );
          } else {
            return pos.deliveredAmount !== pos.needAmount;
          }
        });
      const newEntries = newPositions.map(
        (pos: BucketPosition, index: number) => ({
          id: pos.id,
          key: entries.length + index,
          productId: pos.productId,
          productName: pos.productName,
          productNumber: pos.productNumber,
          hasSerialNumber: pos.hasSerialNumber,
          hasBatchNumber: pos.hasBatchNumber,
          unitAmount: pos.unitAmount,
          amount: deliveredAmountsPerProduct[pos.productId as string]
            ? pos.needAmount -
              deliveredAmountsPerProduct[pos.productId as string]
            : pos.needAmount,
          deliveredAmount: deliveredAmountsPerProduct[pos.productId as string]
            ? pos.needAmount -
              deliveredAmountsPerProduct[pos.productId as string]
            : pos.needAmount,
          unit: pos.unit,
          order,
          orderBucket,
        }),
      );
      setEntries([...entries, ...newEntries]);
    }
  };

  return (
    <AppEntityForm
      appId={AppConfig.todixId}
      initialValues={{
        timestamp: moment(),
        user: user?.uid,
        ecosystem: ecosystemId,
      }}
      providedForm={form}
      onSubmit={handleSubmitWithEntries}
      disabledSubmitButton={
        flow === null ||
        !form.getFieldValue('ecosystem') ||
        entries.length < 1 ||
        !entriesValid
      }
      readOnly={false}
    >
      {() => {
        return (
          <>
            <FormContainer>
              <div>
                <SelectOptionFlow onOptionChange={onOptionChange} />
                <DateEntry flow={flow} />
                {flow === 'incoming' && (
                  <>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, curr) =>
                        prev.ecosystem !== curr.ecosystem
                      }
                    >
                      {() => <Supplier />}
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, curr) =>
                        prev.supplier !== curr.supplier ||
                        prev.ecosystem !== curr.ecosystem ||
                        prev.deliveryNote !== curr.deliveryNote
                      }
                    >
                      {() => (
                        <>
                          <DeliveryNoteSelect />
                          <Order onOrderSelect={handleOrderSelect} />
                        </>
                      )}
                    </Form.Item>
                  </>
                )}
                {flow !== 'incoming' && <Reason />}
              </div>
              <div>
                <SelectedEcosystem
                  appId={AppConfig.todixId}
                  onChange={(id: string) => {
                    if (onEcosystemChange) {
                      onEcosystemChange(id);
                    }
                  }}
                  action={action}
                />
                <Form.Item noStyle shouldUpdate={true}>
                  {() => (
                    <>
                      <User />
                    </>
                  )}
                </Form.Item>
              </div>
            </FormContainer>
          </>
        );
      }}
    </AppEntityForm>
  );
};

export default FlowManagementForm;
