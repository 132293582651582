import React, { FC } from 'react';
import { Tabs } from 'antd';
import { Switch, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { AppSection } from '@apps/AppTypes';

const { TabPane } = Tabs;

type Props = RouteComponentProps & {
  sections: AppSection[];
  ecoId: string;
  appId: string;
  match: {
    params: {
      section: string;
    };
  };
};

const AppsSettingsTabs: FC<Props> = ({ match, history, sections }) => {
  return (
    <Switch>
      <Tabs
        defaultActiveKey={match.params.section}
        onChange={(key) => {
          history.push(`${key}`);
        }}
      >
        {sections.map((section) => {
          const { isDisabled, isHidden, title, link } = section;
          if (isHidden) {
            const hidden = isHidden();
            if (hidden) {
              return null;
            }
          }
          if (isDisabled) {
            const disabled = isDisabled();
            return (
              <TabPane
                tab={<FormattedMessage id={title} defaultMessage={title} />}
                key={link}
                disabled={disabled}
              >
                {disabled ? <div /> : <section.component />}
              </TabPane>
            );
          } else {
            return (
              <TabPane
                tab={<FormattedMessage id={title} defaultMessage={title} />}
                key={link}
              >
                <section.component />
              </TabPane>
            );
          }
        })}
      </Tabs>
    </Switch>
  );
};

export default AppsSettingsTabs;
