import { post } from '@services/api';
import {
  PRODUCT_PRICES,
  ProductPrice,
} from '@apps/products/services/namespace';
import moment from 'moment/moment';

const createProductPrice = (price: ProductPrice) => {
  return post(PRODUCT_PRICES, {
    ...price,
    timestamp: moment().valueOf(),
  });
};

export default createProductPrice;
