import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Badge } from 'antd';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { AvatarHandlerWrapper } from './AvatarHandler.sc';
import UserMenu from '@core/layout/components/UserMenu';
import DropdownWithFullScreenMode from '@components/DropdownWithFullScreenMode';
import { getUser } from '@core/store/user';
import SpinContainer from '@components/SpinContainer';
import { useCustomEventListener } from '@core/services';
import { Invitation } from '@services/invitations/namespace';

export const AvatarHandler: FC = () => {
  const [visible, setVisible] = useState(false);
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const { data: user, isLoading } = useSelector(getUser);

  useCustomEventListener('new_invitations', (data) => {
    if (data) {
      const invitation = data as unknown as Invitation;
      setInvitations([...invitations, invitation]);
    }
  });

  useCustomEventListener('remove_invitation', (data) => {
    if (data) {
      const invitation = data as unknown as Invitation;
      setInvitations(invitations.filter((inv) => inv.id !== invitation.id));
    }
  });

  useCustomEventListener('approved_invitation', (data) => {
    const invitation = data as unknown as Invitation;
    setInvitations(invitations.filter((inv) => inv.id !== invitation.id));
  });

  if (!user || isLoading) {
    return <SpinContainer />;
  }

  const handleMenuClick: MenuClickEventHandler = () => {
    setVisible(false);
  };

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag);
  };

  return (
    <AvatarHandlerWrapper>
      <DropdownWithFullScreenMode
        overlay={
          <UserMenu
            handleMenuClick={handleMenuClick}
            invitations={invitations}
          />
        }
        trigger={['click']}
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        {invitations.length > 0 ? (
          <Badge size="small" count={invitations.length}>
            <Avatar src={user?.photoURL || ''} />
          </Badge>
        ) : (
          <Avatar src={user?.photoURL || ''} />
        )}
      </DropdownWithFullScreenMode>
    </AvatarHandlerWrapper>
  );
};
