import React, { FC } from 'react';
import { styled } from '@styles/themes';
import { WarningTwoTone } from '@ant-design/icons';

const Wrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 14px;
`;

const Warning: FC = () => {
  return (
    <Wrapper>
      <WarningTwoTone twoToneColor="#f5222d" />
    </Wrapper>
  );
};

export default Warning;
