import * as React from 'react';
import { getCurrencies } from '@services/dictionary';

export const useCurrencies = () => {
  const [currencies, setCurrencies] = React.useState<string[]>([]);
  const [areCurrenciesLoading, setAreCurrenciesLoading] = React.useState(true);

  React.useEffect(() => {
    getCurrencies()
      .then((r) => {
        setCurrencies(Object.keys(r));
      })
      .finally(() => setAreCurrenciesLoading(false));
  }, []);

  return [areCurrenciesLoading, currencies] as [boolean, string[]];
};
