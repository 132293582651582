import React from 'react';
import Modal from '@components/Modal';
import {
  resetDocument,
  Document,
  deleteDocument,
} from '@apps/documents/service';
import { emitCustomEvent } from '@core/services';
import { ListEvents } from '@components/List';
import { FormattedMessage, IntlShape } from 'react-intl';
import { AppViewEvent, SelectItemPayload } from '@components/AppView';
import { ExtraOptions } from '@components/AppView/ActionRenderer';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig, { DOCUMENTS_ACTION } from '@apps/documents';
import { message } from 'antd';

export type AddPaymentEvent = 'addPayment';

export type AddPaymentEventPayload = {
  item: Partial<Document>;
};

const getExtraOptions = (
  item: Partial<Document>,
  intl: IntlShape,
): ExtraOptions => {
  const ecosystemId = item?.ecosystem || '';
  const canEdit = checkUserPermissions(
    rootStore.getState(),
    appConfig.todixId,
    'update-documents' as DOCUMENTS_ACTION,
    ecosystemId,
  );

  const canDelete = checkUserPermissions(
    rootStore.getState(),
    appConfig.todixId,
    'delete-documents' as DOCUMENTS_ACTION,
    ecosystemId,
  );

  const addPayment: MenuItemProps = {
    children: intl.formatMessage({
      id: 'documents.getextraoptions.addpayment',
      defaultMessage: 'add payment',
    }),
    danger: false,
    onClick: () => {
      emitCustomEvent<AppViewEvent, SelectItemPayload>('selectItem', {
        id: item.id as string,
        extraAction() {
          setTimeout(() => {
            emitCustomEvent<AddPaymentEvent, AddPaymentEventPayload>(
              'addPayment',
              {
                item,
              },
            );
          }, 100);
        },
      });
    },
  };

  const reset: MenuItemProps = {
    children: intl.formatMessage({
      id: 'documents.getextraoptions.reset',
      defaultMessage: 'reset',
    }),
    danger: true,
    onClick: () => {
      Modal.confirm({
        content: (
          <FormattedMessage
            id="documents.getextraoptions.reset.confirm"
            defaultMessage="Are you sure you want to reset this item?"
          />
        ),
        onOk: () => {
          if (item.id) {
            resetDocument(item.id)?.then(() => {
              emitCustomEvent<ListEvents>('refreshList');
            });
          }
        },
      });
    },
  };

  if (!canEdit) {
    return [];
  }

  const options = [];

  if (item.type?.toLowerCase() === 'invoice') {
    options.push(addPayment);
  }

  if (!item?.createdBySystem) {
    options.push(reset);
  }

  if (canDelete && !item?.createdBySystem && item?.id) {
    const deleteOption: MenuItemProps = {
      children: intl.formatMessage({
        id: 'appview.delete',
        defaultMessage: 'delete',
      }),
      danger: true,
      onClick: () => {
        Modal.confirm({
          content: intl.formatMessage({
            id: 'appview.delete.confirm',
            defaultMessage: 'Are you sure you want to remove this item?',
          }),
          onOk: () => {
            deleteDocument(item.id as string).catch(() => {
              message.error(
                intl.formatMessage({
                  id: 'products.deletedocument.error',
                  defaultMessage: 'Error while deleting document',
                }),
              );
            });
          },
        });
      },
    };

    options.push(deleteOption);
  }

  return options;
};

export default getExtraOptions;
