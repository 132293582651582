import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { FormattedMessage } from 'react-intl/lib';
import { useIntl } from 'react-intl';

const TextAreaField: FC<FieldProps> = ({ field, children, ...rest }) => {
  const { readOnly } = useFormContext();
  const intl = useIntl();
  const {
    fieldName,
    label,
    rules,
    className,
    style,
    placeholder,
    readOnly: fieldReadOnly,
    wrapperStyle,
  } = field;
  return (
    <Form.Item
      name={fieldName}
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      rules={rules?.map((r) => {
        r.message =
          r.message && typeof r.message === 'string' ? (
            <FormattedMessage
              id={`${r.message}`}
              defaultMessage={`${r.message}`}
            />
          ) : (
            r.message
          );
        return r;
      })}
      colon={false}
      labelAlign="left"
      {...rest}
      style={wrapperStyle}
    >
      <Input.TextArea
        name={fieldName}
        placeholder={
          placeholder
            ? intl.formatMessage({
                id: placeholder,
                defaultMessage: placeholder,
              })
            : placeholder
        }
        readOnly={fieldReadOnly ? fieldReadOnly : readOnly}
        className={className}
        style={style}
      />
    </Form.Item>
  );
};

export default TextAreaField;
