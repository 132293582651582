import { Form, Input } from 'antd';
import React, { FC, useState, useRef, useContext, useEffect } from 'react';
import {
  ProductEntry,
  WarehouseStorage,
} from '@apps/warehouse/services/namespace';
import EditableContext from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/context';
import UnitSelector from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/UnitSelector';
import StorageSelector from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/StorageSelector';

type EditableCellProps = {
  title: React.ReactNode;
  editable: boolean;
  type?: 'dropdown' | 'number' | 'string';
  children: React.ReactNode;
  dataIndex: keyof ProductEntry;
  record: ProductEntry;
  handleSave: (record: ProductEntry) => void;
  selectedEcosystem: string | null;
};

const EditableCell: FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  selectedEcosystem,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave({ ...record, ...values });
      toggleEdit();
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleStorageSelect = async (storage: WarehouseStorage) => {
    try {
      const values = await form.validateFields();
      handleSave({
        ...record,
        ...values,
        storageId: storage.id,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const handleUnitSelect = async (unit: string) => {
    try {
      const values = await form.validateFields();
      handleSave({
        ...record,
        ...values,
        unit,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (dataIndex === 'storageId') {
    childNode = (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <StorageSelector
          selectedEcosystem={selectedEcosystem}
          onStorageSelect={handleStorageSelect}
        />
      </Form.Item>
    );

    return <td {...restProps}>{childNode}</td>;
  }

  if (dataIndex === 'unit') {
    childNode = (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <UnitSelector
          selectedEcosystem={selectedEcosystem}
          dataIndex={dataIndex}
          onUnitSelect={handleUnitSelect}
        />
      </Form.Item>
    );

    return <td {...restProps}>{childNode}</td>;
  }

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          type={type === 'number' ? 'number' : 'text'}
          ref={inputRef}
          onInput={(e) =>
            form.setFieldsValue({ [dataIndex]: e.currentTarget.value })
          }
          onPressEnter={save}
          onBlur={save}
        />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  } else if (dataIndex) {
    childNode = (
      <Form.Item noStyle shouldUpdate={true}>
        {() => (
          <Form.Item name={dataIndex}>
            {dataIndex === 'productNumber' ||
              (dataIndex === 'productName' && <Input readOnly />)}
          </Form.Item>
        )}
      </Form.Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
