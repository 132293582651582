import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig, { PURCHASES_ACTION } from '@apps/purchases';
import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import { PurchaseOperationSettings } from '@apps/purchases/services/purchaseOperation/namespace';
import { patch, post } from '@services/api';
import moment from 'moment/moment';

const COLLECTION_NAME = 'purchaseOperationSettings';

export const getPurchaseOperationSettings = (
  step: string,
  ecosystemId: string,
) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-purchases' as PURCHASES_ACTION,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds.includes(ecosystemId)) {
    return Promise.resolve([]);
  }

  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .where('ecosystem', '==', ecosystemId)
    .where('step', '==', step)
    .get()
    .then(getArrayWithIds) as Promise<PurchaseOperationSettings[]>;
};

export const updatePurchaseOperationSettings = (
  id: string,
  saleOperationSettings: PurchaseOperationSettings,
) =>
  patch(`${COLLECTION_NAME}/${id}`, {
    ...saleOperationSettings,
    timestamp: moment().valueOf(),
  });

export const addPurchaseOperation = (
  saleOperationSettings: PurchaseOperationSettings,
) =>
  post(`${COLLECTION_NAME}`, {
    ...saleOperationSettings,
    timestamp: moment().valueOf(),
  });
