import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { Project } from '@apps/projects/services/namespace';
import { getProjectsPerDetails } from '@apps/projects/services/getProjectsPerDetails';

type ProjectSelectorProps = SelectProps & {
  selectedEcosystem: string | null;
  onProjectSelect?(project: Project): void;
};

const ProjectSelector: FC<ProjectSelectorProps> = ({
  selectedEcosystem,
  onProjectSelect,
  ...rest
}) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedEcosystem) {
      setLoading(true);
      getProjectsPerDetails({ ecosystem: selectedEcosystem })
        ?.then(setProjects)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedEcosystem]);

  const options = useMemo(() => {
    return projects.map((project) => ({
      value: project.id,
      'data-forsearch': `${project.name || ''} ${project.startDate || ''} ${
        project.endDate || ''
      }`,
      label: <div key={project.id}>{project.name}</div>,
    }));
  }, [projects]);

  const handleSelect = useCallback(
    (projectId: any) => {
      const project = projects.find((project) => project.id === projectId);
      if (project && onProjectSelect) {
        onProjectSelect(project);
      }
    },
    [projects, onProjectSelect],
  );

  return (
    <Select
      {...rest}
      disabled={!selectedEcosystem || loading}
      loading={loading}
      onSelect={handleSelect}
      showSearch
      filterOption={(inputValue, option) => {
        return option
          ? option['data-forsearch']
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          : false;
      }}
      options={options}
    />
  );
};

export default ProjectSelector;
