import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import dateFilter from '@core/helpers/dateFilter';
import {
  ColSpanParams,
  ValueFormatterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';

export const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="productName"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'products.columns.productname.header',
        defaultMessage: 'Product Name',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="ecosystem"
      headerName={intl.formatMessage({
        id: 'products.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      cellRenderer="ecosystemRenderer"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      filter="ecosystemFilter"
    />
    <AgGridColumn
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      field="creator.displayName"
      headerName={intl.formatMessage({
        id: 'products.columns.creator.header',
        defaultMessage: 'Creator',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="productNumber"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'products.columns.productNumber.header',
        defaultMessage: 'Product Number',
      })}
      sortable
      minWidth={175}
    />
    <AgGridColumn
      field="isActive"
      filter="activeStatusFilter"
      headerName={intl.formatMessage({
        id: 'products.columns.isactive.header',
        defaultMessage: 'Active / Inactive',
      })}
      sortable
      minWidth={170}
      valueFormatter={({ value }: ValueFormatterParams) =>
        value ? 'Active' : 'Inactive'
      }
    />
    <AgGridColumn
      field="productCreationDate"
      headerName={intl.formatMessage({
        id: 'products.columns.productcreationdate.header',
        defaultMessage: 'Product Creation Date',
      })}
      sortable
      minWidth={210}
      //valueFormatter={({ value }) => moment(value).format(moment.defaultFormat)}
      filterParams={dateFilter}
      filter={'agDateColumnFilter'}
    />
    <AgGridColumn
      field="productGroup"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'products.columns.productgroup.header',
        defaultMessage: 'Product Group',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn cellRenderer="actionRenderer" maxWidth={70} />
  </>
);
