import { firebase } from '@services/Firebase';
import {
  Invitation,
  INVITATION_COLLECTION,
} from '@services/invitations/namespace';

const updateInvitationStatus = async (
  invitation: Invitation,
  status: Invitation['status'],
) => {
  await firebase.db?.ref(`${INVITATION_COLLECTION}/${invitation.id}`).set({
    ...invitation,
    status,
  });
};

export default updateInvitationStatus;
