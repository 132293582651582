import React, { FC, useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { getTasksByEcosystemId, Task } from '@apps/tasks/services';
import getParentsForChild from '@services/entityRelations/getParentsForChild';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';

const { Item } = Form;

type SelectTasksProps = {
  parentId: string;
  parentType: string;
  ecosystem: string;
  readOnly: boolean;
  subTasks: Partial<Task>[];
  handleTaskSelect(id: any): void;
  handleTaskDeselect(id: any): void;
  handleClear(): void;
};

const SelectTasks: FC<SelectTasksProps> = ({
  readOnly,
  ecosystem,
  subTasks,
  parentId,
  parentType,
  handleTaskSelect,
  handleClear,
  handleTaskDeselect,
}) => {
  const [tasksForSelection, setTasksForSelection] = useState<Task[]>(
    (subTasks as Task[]) || [],
  );

  useEffect(() => {
    if (ecosystem) {
      getTasksByEcosystemId(ecosystem).then((tasks) => {
        const subTasksIds = subTasks.map((subTask) => subTask.id);
        let filteredTasks: Task[] = [];
        const tasksPromises = tasks
          .map((task) => task.id)
          .map((id) => {
            return getParentsForChild({
              childId: id as string,
              parentType: 'task',
              ecosystem,
            }).then((results) => {
              if (results.length < 1) {
                const task = tasks.find((task) => task.id === id);
                if (task) {
                  filteredTasks.push(task);
                }
              }
            });
          });

        Promise.all(tasksPromises).then(() => {
          if (parentType === 'task') {
            getParentsForChild({
              childId: parentId,
              parentType,
              ecosystem,
            }).then((parents) => {
              const childrenIds = parents.map((parent) => parent.parentId);
              const tasksToSelect = tasks.filter(
                (task) =>
                  !childrenIds.includes(task.id as string) &&
                  !subTasksIds.includes(task.id) &&
                  task.id !== parentId,
              );
              setTasksForSelection(tasksToSelect);
            });
          }
          if (parentType === 'project') {
            getChildrenForParent({
              parentId,
              childType: 'task',
              ecosystem,
            }).then((children) => {
              const childrenIds = children.map((child) => child.childId);
              const tasksToSelect = filteredTasks.filter(
                (task) => !childrenIds.includes(task.id as string),
              );
              setTasksForSelection(tasksToSelect);
            });
          }
        });
      });
    }
  }, [ecosystem, parentId, subTasks, parentType]);

  return (
    <Item
      label={
        <FormattedMessage
          id="tasks.form.select.subTasks"
          defaultMessage="Select sub tasks"
        />
      }
    >
      <Select
        allowClear
        mode="multiple"
        disabled={readOnly}
        placeholder={
          <FormattedMessage
            id="tasks.form.select.subTasks"
            defaultMessage="Select sub tasks"
          />
        }
        onSelect={handleTaskSelect}
        onDeselect={handleTaskDeselect}
        onClear={handleClear}
        options={tasksForSelection.map((task) => ({
          label: task.title,
          value: task.id,
        }))}
      />
    </Item>
  );
};

export default SelectTasks;
