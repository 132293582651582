import { database } from 'firebase';
import { firebase } from '@services/Firebase';

const handleRelationChange = (data: database.DataSnapshot, userId: string) => {
  const relation = data.val() as EcosystemRelation;

  if (relation.memberId === userId) {
    firebase.initEcosystems();
  }
};

const handleRemoveRelation = (data: database.DataSnapshot, userId: string) => {
  const relation = data.val() as EcosystemRelation;

  if (relation.memberId === userId) {
    firebase.initEcosystems();
  }
};

const ecosystemRelationsListener = (userId: string, db: database.Database) => {
  const ecosystemsRelationsRef = db
    .ref(`ecosystemsRelations`)
    .orderByChild('memberId')
    .equalTo(userId);

  ecosystemsRelationsRef.on('child_changed', (data) => {
    handleRelationChange(data, userId);
  });

  ecosystemsRelationsRef.on('child_removed', (data) => {
    handleRemoveRelation(data, userId);
  });
};

export default ecosystemRelationsListener;
