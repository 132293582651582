import React, { FC, useMemo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl/lib';
import { CartEntry } from '@services/cart/namespace';
import StyledTable from '@pages/settings/components/StyledTable';
import EcosystemAvatar from '@components/EcosystemAvatar';
import { ColumnType } from 'antd/lib/table';
import Application from '@components/Cart/Application';
import EntryAction from '@components/Cart/EntryAction';
import CartTotalPrice from '@components/Cart/CartTotalPrice';
import removeEntryFromCart from '@services/cart/removeEntryFromCart';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import { removeEntryCart } from '@core/store/cart';
import { emitCustomEvent } from '@core/services';
import { StripePrice, StripeProduct } from '@services/stripe/namespace';
import Quantity from '@components/Cart/Quantity';
import { FreeTrialTag } from '@pages/settings/store/AppDetails.sc';
import moment from 'moment';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import PriceDetails from '@pages/settings/store/PriceDetails';

type CartEntryProps = {
  cart: CartEntry[];
  isFetching: boolean;
  stripePrices: StripePrice[];
  stripeProducts: StripeProduct[];
  showTrial: boolean;
  stripeSettings: StripeSettings | null;
};

const CartEntriesTable: FC<CartEntryProps> = ({
  cart,
  isFetching,
  stripePrices,
  stripeProducts,
  showTrial,
  stripeSettings,
}) => {
  const { data: user, isLoading } = useSelector(getUser);
  const ecosystem = useSelector(
    getEcosystemById(cart.map((el) => el.ecosystem)[0] || ''),
  ) as Ecosystem;
  const dispatch = useDispatch();

  const removeEntry = useCallback(
    async (entry: CartEntry) => {
      if (user && !isLoading) {
        await removeEntryFromCart(entry, user.uid);
        dispatch(removeEntryCart(entry));
        emitCustomEvent('entry_removed', entry);
      }
    },
    [user, isLoading, dispatch],
  );
  const columns: ColumnType<any>[] = useMemo(() => {
    return [
      {
        key: 'ecosystem',
        dataIndex: 'ecosystem',
        title: <FormattedMessage id="ecosystem" defaultMessage="Ecosystem" />,
        render: (value) => <EcosystemAvatar id={value} />,
      },
      {
        key: 'product',
        dataIndex: 'productId',
        title: (
          <FormattedMessage id="application" defaultMessage="Application" />
        ),
        render: (value) => {
          const product = stripeProducts.find((price) => price.id === value);
          return product ? <Application product={product} /> : null;
        },
      },
      {
        key: 'quantity',
        dataIndex: 'quantity',
        title: <FormattedMessage id="quantity" defaultMessage="Qty" />,
        render: (value, entry: CartEntry) => {
          return (
            <Quantity
              qty={value}
              disabled={isFetching}
              entry={entry}
              ecosystem={ecosystem}
            />
          );
        },
      },
      {
        key: 'price',
        dataIndex: 'priceId',
        title: <FormattedMessage id="price" defaultMessage="Price" />,
        render: (value, record) => {
          const price = stripePrices.find((price) => price.id === value);
          if (price && price.unit_amount) {
            return (
              <div>
                {price && (
                  <PriceDetails
                    price={price}
                    qty={record.quantity}
                    showTrial={showTrial}
                  />
                )}
                {showTrial && stripeSettings?.trialEnd && (
                  <FreeTrialTag color="red">
                    <FormattedMessage
                      id="free.trial.till"
                      defaultMessage="Free till"
                      values={{
                        date: moment
                          .unix(stripeSettings?.trialEnd)
                          .format(moment.defaultFormat),
                      }}
                    />
                  </FreeTrialTag>
                )}
                {showTrial && !stripeSettings?.trialEnd && (
                  <FreeTrialTag color="red">
                    <FormattedMessage
                      id="free.trial"
                      defaultMessage="Free trial"
                      values={{
                        days: stripeSettings?.trialPeriodDays || 30,
                      }}
                    />
                  </FreeTrialTag>
                )}
              </div>
            );
          }
          return null;
        },
      },
      {
        key: 'actions',
        render: (_v, entry) => {
          return (
            <EntryAction
              entry={entry}
              onRemoveEntry={() => removeEntry(entry)}
            />
          );
        },
      },
    ];
  }, [
    isFetching,
    removeEntry,
    showTrial,
    stripePrices,
    stripeProducts,
    stripeSettings?.trialEnd,
    stripeSettings?.trialPeriodDays,
    ecosystem,
  ]);
  return (
    <>
      <StyledTable
        loading={isFetching}
        columns={columns}
        dataSource={cart}
        pagination={false}
        rowKey="id"
      />
      <CartTotalPrice
        cart={cart}
        prices={stripePrices}
        showTrial={showTrial}
        stripeSettings={stripeSettings}
      />
    </>
  );
};

export default CartEntriesTable;
