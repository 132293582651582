import React, { FC, useMemo, useState, useCallback } from 'react';
import SpinContainer from '@components/SpinContainer';
import { Spin } from 'antd';
import { StyledSwitch } from '@pages/settings/components';
import { FormattedMessage } from 'react-intl';
import {
  Consultant,
  patchConsultant,
} from '@apps/directSales/mainMenu/Consultants/services';
import { firebase } from '@services/Firebase';
import moment from 'moment';

type Props = {
  data?: Partial<Consultant>;
  value?: boolean;
};
const ValidationCellRenderer: FC<Props> = ({ value, data }) => {
  const activated = useMemo(
    () => (
      <FormattedMessage
        id="consultants.consultant.validated"
        defaultMessage="Validated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="consultants.consultant.unvalidated"
        defaultMessage="Unvalidated"
      />
    ),
    [],
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback(
    (status: boolean) => {
      setIsLoading(true);
      try {
        const user = firebase.auth.currentUser;
        const filteredData = JSON.parse(JSON.stringify(data));
        const updatedConsultant: Consultant = {
          ...filteredData,
          validated: status,
          validatedById: status ? (user?.uid as string) : '',
          validationDate: status ? moment().valueOf() : '',
        };
        patchConsultant(
          updatedConsultant.id as string,
          updatedConsultant,
        ).finally(() => {
          setIsLoading(false);
        });
      } catch (e) {
        setIsLoading(false);
      }
    },
    [data],
  );

  return (
    <Spin spinning={isLoading} indicator={<SpinContainer />}>
      <StyledSwitch
        onChange={handleChange}
        checkedChildren={activated}
        defaultChecked={value}
        unCheckedChildren={deactivated}
      />
    </Spin>
  );
};

export default ValidationCellRenderer;
