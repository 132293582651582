import React, { FC } from 'react';
import { Commission } from '@apps/directSales/services/commisions';
import { currencySigns } from '@services/currency';
import { useIntl } from 'react-intl';

type Props = {
  value: Commission['recruitingBonus'];
};

const RecruitingBonusRenderer: FC<Props> = ({ value }) => {
  const intl = useIntl();
  if (value) {
    return (
      <div>
        {intl.formatMessage(
          {
            id: 'commissions.recruitingBonus.couponPerConsultant',
            defaultMessage: 'commissions.recruitingBonus.couponPerConsultant',
          },
          {
            coupon: `${value.coupon}${currencySigns['EUR']}`,
            sale: `${value.minRecruiterSale}${currencySigns['EUR']}`,
          },
        )}
      </div>
    );
  }
  return <div />;
};

export default RecruitingBonusRenderer;
