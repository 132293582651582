import { IntlShape } from 'react-intl';
import { ActionOption } from '@components/AppView';

const optionsMapper = (
  optionsArray: string[],
  intl: IntlShape,
): ActionOption[] =>
  optionsArray.map((option) => ({
    action: option,
    label: intl.formatMessage({
      id: `appview.${option}`,
      defaultMessage: option,
    }),
  }));

export default optionsMapper;
