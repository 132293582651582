import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type LinkProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const Project: FC<LinkProps> = ({ namePrefix, readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name={
        namePrefix !== undefined ? [namePrefix, `costCenter`] : `costCenter`
      }
      label={<FormattedMessage id="cost.center" defaultMessage="Cost Center" />}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'cost.center',
          defaultMessage: 'Cost Center',
        })}
        autoComplete="nope"
        readOnly={readOnly}
        disabled={readOnly}
        type="number"
      />
    </Item>
  );
};

export default Project;
