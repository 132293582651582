import { IntlShape } from 'react-intl';
import moment from 'moment';
import { Document, DocumentCategory } from '@apps/documents/service/namespace';
import { getContactName } from '@apps/documents/service';

type ValueGetter = (row: any, intl?: IntlShape) => any;

export const nameValueGetter: ValueGetter = (
  row: Document,
  intl?: IntlShape,
): string => {
  if (!row || !intl) {
    return '';
  }

  let issuingDate = '';

  if (row.issuingDate) {
    if (moment.isMoment(row.issuingDate)) {
      issuingDate = row.issuingDate.format(moment.defaultFormat);
    } else {
      if (
        !Number.isInteger(row.issuingDate) &&
        !moment.isMoment(row.issuingDate) &&
        !row.issuingDate?.includes('T') &&
        !row.issuingDate?.includes('Z')
      ) {
        issuingDate = row.issuingDate;
      } else {
        try {
          issuingDate = moment(row.issuingDate).format(moment.defaultFormat);
        } catch (e) {
          console.error(e);
          issuingDate = row.issuingDate;
        }
      }
    }
  }

  const directionMap: Record<DocumentCategory, string> = {
    incoming: intl.formatMessage({
      id: 'documents.documentTypes.category.incoming',
      defaultMessage: `from`,
    }),
    outgoing: intl.formatMessage({
      id: 'documents.documentTypes.category.outgoing',
      defaultMessage: `to`,
    }),
    none: intl.formatMessage({
      id: 'documents.documentTypes.category.none',
      defaultMessage: `of`,
    }),
  };

  const type = row.type
    ? intl.formatMessage({
        id: `documents.documentTypes.${row.type}`,
        defaultMessage: `documents.documentTypes.${row.type}`,
      })
    : intl.formatMessage({
        id: 'documents.unknown',
        defaultMessage: `unknown`,
      });

  let contactName = intl.formatMessage({
    id: 'documents.unknowncontact',
    defaultMessage: `Unknown Contact`,
  });

  if (row.relatedContactSnapshot) {
    contactName = getContactName(row.relatedContactSnapshot) as string;
  }

  return intl.formatMessage(
    {
      id: 'documents.inbox.processingheader.documentname',
      defaultMessage: `{type} {of} {issuingDate} {category} {contact}`,
    },
    {
      type,
      of: intl.formatMessage({
        id: 'documents.documentTypes.category.none',
        defaultMessage: `of`,
      }),
      issuingDate: issuingDate || '',
      category: directionMap[row.category],
      contact: contactName,
    },
  );
};
