import React, { FC } from 'react';
import { Document } from '@apps/documents/service/namespace';

type OrderRendererProps = {
  value?: Document;
};

const OrderRenderer: FC<OrderRendererProps> = ({ value }) => {
  if (!value) {
    return <span>—</span>;
  }

  const documentLabel = `${value.documentNumber || value.docNumber || ''}`;

  return <span>{documentLabel}</span>;
};

export default OrderRenderer;
