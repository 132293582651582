import React, { FC, useEffect, useState } from 'react';
import { Card, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { styled } from '@styles/themes';
import {
  getEcosystemProductUserFields,
  UserField,
} from '@apps/products/services';
import { useForm } from 'antd/lib/form/Form';
import FieldResolver from '@apps/products/mainMenu/components/Form/UserFields/FieldResolver';
const { Item } = Form;

const FieldContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.1fr;
  grid-column-gap: 10px;
`;

type UserFieldsProps = {
  readOnly: boolean;
  ecosystemId?: string;
  form: any;
};

const UserFields: FC<UserFieldsProps> = ({ ecosystemId, form, readOnly }) => {
  const [formInstance] = useForm(form);
  const [isLoading, setIsLoading] = useState(false);
  const [userFields, setUserFields] = useState<UserField[]>([]);
  useEffect(() => {
    if (ecosystemId && formInstance) {
      setIsLoading(true);
      getEcosystemProductUserFields(ecosystemId)
        ?.then((fetchedFields) => {
          setUserFields(fetchedFields);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [ecosystemId, formInstance]);

  return (
    <Card
      loading={isLoading}
      title={
        <FormattedMessage
          id="settings.products.userFields.menuTitle"
          defaultMessage="settings.products.userFields.menuTitle"
        />
      }
    >
      {userFields.map((field, index) => {
        return (
          <FieldContainer key={index}>
            {Object.keys(field).map((key) => {
              return <Item name={['userFields', index, key]} hidden={true} />;
            })}
            <Item noStyle={true} shouldUpdate={true}>
              {() => {
                return (
                  <Item
                    name={['userFields', index, 'value']}
                    label={field?.label || ''}
                    rules={[
                      {
                        required: field?.required,
                      },
                    ]}
                  >
                    <FieldResolver
                      userField={field}
                      value={field.value || ''}
                      readOnly={readOnly}
                      onChange={(value: string | number) => {
                        formInstance.setFields([
                          {
                            name: ['userFields', index, 'value'],
                            value,
                          },
                        ]);
                      }}
                    />
                  </Item>
                );
              }}
            </Item>
          </FieldContainer>
        );
      })}
    </Card>
  );
};

export default UserFields;
