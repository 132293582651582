import React from 'react';
import { Text, MenuItem } from '@todix/ui-components';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
function getItem(
  label: string,
  key: React.Key,
  link: string,
  icon?: React.ReactNode,
) {
  return (
    <MenuItem key={key}>
      <Link to={link}>
        {icon}
        <Text level={3}>
          <FormattedMessage id={label} defaultMessage={label} />
        </Text>
      </Link>
    </MenuItem>
  );
}

const items: any[] = [
  getItem('Manage users', '1', '/admin/users'),
  getItem('Setup platform', '2', '/admin/setup-platform'),
];

const AdminLeftMenu: React.FC = () => {
  return (
    <aside>
      <Menu
        style={{ width: 256 }}
        defaultSelectedKeys={['1']}
        mode={'vertical'}
        theme={'light'}
      >
        {items}
      </Menu>
    </aside>
  );
};

export default AdminLeftMenu;
