import React from 'react';
import { EcosystemIndicator } from '@components/EcosystemIndicator/EcosystemIndicator';
import { FormattedMessage } from 'react-intl';
import {
  StyledCollapsedMenu,
  StyledCollapsedSubMenu,
  StyledMenuLink,
} from '@core/layout/components/LeftMenu/LeftMenu.sc';
import { Menu } from 'antd';
import {
  EcosystemState,
  getActiveEcosystems,
} from '@components/EcosystemIndicator/store';
import { getActivatedFileApps } from '@core/helpers/apps';
import { FileApp, RegisteredFileApp } from '@apps/AppTypes';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { getLicences } from '@core/store/licences';
const { Item: AntItem } = Menu;

type ItemProps = {
  ecosystems: EcosystemState;
  licences: Record<string, Licence>;
  fileApp: RegisteredFileApp;
  collapsed: boolean;
};

const MenuItem: React.FC<ItemProps> = ({
  ecosystems,
  licences,
  fileApp,
  collapsed,
  ...props
}) => {
  const licencesData = Object.values(licences);
  if ('sections' in fileApp.mainMenu) {
    return (
      <StyledCollapsedSubMenu
        collapsed={collapsed}
        icon={<fileApp.mainMenu.icon />}
        title={
          <>
            {!collapsed && (
              <EcosystemIndicator
                style={{
                  float: 'right',
                }}
                ecosystems={ecosystems.filter((ecosystem) => {
                  const allowedApps = licencesData.filter(
                    (licence) =>
                      licence.ecosystem === ecosystem.id &&
                      licence.status === 'active' &&
                      licence.appId === fileApp.appId,
                  );
                  return allowedApps.length > 0;
                })}
                small
              />
            )}
            {!collapsed && (
              <FormattedMessage
                id={fileApp.mainMenu.appSubmenuName}
                defaultMessage={fileApp.mainMenu.appSubmenuName}
              />
            )}
          </>
        }
        key={fileApp.appId}
        {...props}
      >
        {fileApp.mainMenu.sections?.map((item) => (
          <AntItem key={`${fileApp.todixId}/${item.link}`}>
            <StyledMenuLink to={`/app/${fileApp.todixId}/${item.link}`}>
              <span>
                {item.icon && <item.icon style={{ marginRight: 8 }} />}
                {!collapsed && (
                  <FormattedMessage
                    id={item.title}
                    defaultMessage={item.title}
                  />
                )}
              </span>
            </StyledMenuLink>
          </AntItem>
        ))}
      </StyledCollapsedSubMenu>
    );
  }
  return (
    <StyledCollapsedMenu collapsed={collapsed} {...props} key={fileApp.appId}>
      <StyledMenuLink to={`/app/${fileApp.todixId}`}>
        <span>
          <fileApp.mainMenu.icon style={{ marginRight: 8 }} />
          {!collapsed && (
            <FormattedMessage
              id={fileApp.mainMenu.appSubmenuName}
              defaultMessage={fileApp.mainMenu.appSubmenuName}
            />
          )}
        </span>
        {!collapsed && (
          <EcosystemIndicator
            ecosystems={ecosystems.filter((ecosystem) => {
              const allowedApps = licencesData.filter(
                (licence) =>
                  licence.ecosystem === ecosystem.id &&
                  licence.status === 'active' &&
                  licence.appId === fileApp.appId,
              );
              return allowedApps.length > 0;
            })}
            small
          />
        )}
      </StyledMenuLink>
    </StyledCollapsedMenu>
  );
};

export const AppsMainMenuRouting = () => {
  const activeEcosystems = useSelector(getActiveEcosystems);
  const licences = useSelector(getLicences);
  const { data } = licences;

  let activeEcosystemsApps: FileApp[] = activeEcosystems.reduce(
    (acc: any, ecosystem: Ecosystem) => {
      const activatedFileApps = getActivatedFileApps(ecosystem, data);
      // check for duplicates
      activatedFileApps.forEach((app) => {
        acc.push(app);
      });
      return acc;
    },
    [],
  );

  activeEcosystemsApps = _.uniqBy(activeEcosystemsApps, 'appId');

  return (
    <>
      {activeEcosystemsApps.map((fileApp, index) => {
        if ('component' in fileApp.mainMenu) {
          return (
            <Route
              key={`${fileApp.todixId}_${index}`}
              path={`/app/${fileApp.todixId}`}
              component={fileApp.mainMenu.component}
            />
          );
        } else {
          return (
            <span key={`${fileApp.todixId}_${index}`}>
              {fileApp.mainMenu.sections?.map((section, index) => (
                <Route
                  key={`${section.link}_${index}`}
                  path={`/app/${fileApp.todixId}/${section.link}`}
                  component={section.component}
                />
              ))}
            </span>
          );
        }
      })}
    </>
  );
};

type GetAppsMainMenuType = (
  collapsed: boolean,
  uniqueActiveEcosystemsApps: RegisteredFileApp[],
  ecosystems: Ecosystem[],
  licences: Record<string, Licence>,
) => any;

// belive me or not but it cannot be a component because Menu direct descendand needs to be an Item - <Fragment/> does not work either.
export const getAppsMainMenu: GetAppsMainMenuType = (
  collapsed,
  uniqueActiveEcosystemsApps,
  ecosystems,
  licences,
) => {
  return uniqueActiveEcosystemsApps.map((fileApp) => (
    <MenuItem
      collapsed={collapsed}
      key={fileApp.appId}
      ecosystems={ecosystems}
      licences={licences}
      fileApp={fileApp}
    />
  ));
};
