import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { Avatar, Form, Select, Space, Button } from 'antd';
import { Contact, getContactsForEcosystem } from '@apps/contacts/services';
import { FormattedMessage, useIntl } from 'react-intl';
import { FlexJustifyEnd } from '@apps/documents/mainMenu/Inbox/DocumentProcessing/styles.sc';
import { emitCustomEvent } from '@core/services';
import StyledItemForm from '@apps/documents/mainMenu/components/StyledItemForm';
import WidgetResolver from '@components/WidgetResolver';
import { useFormContext } from '@components/FormWithReminder/useFormContext';

const { Item } = Form;
const { Option } = Select;

type Props = {
  ecosystem: string;
  onNewContactSubmit(id: string): void;
};

const RelatedContact: FC<Props> = ({ ecosystem, onNewContactSubmit }) => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const { readOnly } = useFormContext();

  useEffect(() => {
    if (ecosystem) {
      setLoading(true);
      getContactsForEcosystem(ecosystem, 'view-contacts')
        ?.then((results) => {
          setContacts(results);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem]);

  const options = useMemo(() => {
    return contacts.map((contact, index) => {
      return (
        <Option key={index} value={contact.id}>
          <Space size="small" direction="horizontal">
            <Avatar shape="circle" src={contact.avatar} size="small" />
            {contact.type === 'company' && <span>{contact.companyName}</span>}
            {contact.type === 'person' && (
              <span>
                {contact.firstName} {contact.lastName}
              </span>
            )}
          </Space>
        </Option>
      );
    });
  }, [contacts]);

  const handleOnSelect = useCallback(
    (id) => {
      emitCustomEvent(
        'setRelatedContact',
        contacts.find((contact) => contact.id === id),
      );
    },
    [contacts],
  );

  const handleNewContactSubmit = useCallback(
    async (contact: Contact) => {
      setContacts([...contacts, contact]);
      emitCustomEvent('setRelatedContact', contact);
      onNewContactSubmit(contact.id as string);
    },
    [onNewContactSubmit, contacts],
  );

  return (
    <div>
      <StyledItemForm
        name="relatedContact"
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'documentprocessing.step2.relatedcontact.error',
              defaultMessage: 'Missing related contact',
            }),
          },
        ]}
      >
        <Select
          disabled={readOnly}
          loading={loading}
          placeholder={
            <FormattedMessage
              id="documentprocessing.step5.relatedcontact.label"
              defaultMessage="documentprocessing.step5.relatedcontact.label"
            />
          }
          onSelect={handleOnSelect}
        >
          {options}
        </Select>
      </StyledItemForm>
      {!readOnly && (
        <FlexJustifyEnd style={{ marginBottom: '24px' }}>
          <WidgetResolver
            appName="contacts"
            widgetName="AddNewContactWidget"
            injectedProps={{
              OnClickEl: (props: any) => (
                <Button type="primary" {...props} disabled={readOnly}>
                  <FormattedMessage
                    id="documentprocessing.step2.relatedcontact.header"
                    defaultMessage="New contact"
                  />
                </Button>
              ),
              onSubmit: (contact: Contact) => handleNewContactSubmit(contact),
            }}
          />
        </FlexJustifyEnd>
      )}
    </div>
  );
};

export default () => {
  return (
    <Item shouldUpdate={true} noStyle>
      {({ getFieldValue, getFieldsValue, setFieldsValue }) => {
        const ecosystem = getFieldValue('ecosystem');
        const docType = getFieldValue('type');
        return docType ? (
          <RelatedContact
            ecosystem={ecosystem}
            onNewContactSubmit={(id) => {
              setFieldsValue({
                ...getFieldsValue(),
                relatedContact: id,
              });
            }}
          />
        ) : null;
      }}
    </Item>
  );
};
