import { firebase } from '@services/Firebase';
import {
  USER_FIELDS_COLLECTION_NAME,
  UserField,
} from '@apps/products/services/namespace';

const getEcosystemProductUserFields = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection(USER_FIELDS_COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((query) =>
      query.docs.map((doc) => ({ ...doc.data(), id: doc.id } as UserField)),
    );
};

export default getEcosystemProductUserFields;
