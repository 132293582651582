import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getDocument } from '@apps/documents/service';
import DetailedDocument from '@apps/documents/mainMenu/DetailedDocument';
import { DOCUMENTS_ACTION } from '@apps/documents';

type Props = RouteComponentProps & {
  match: {
    params: {
      id: string;
    };
  };
};

const Preview: React.FC<Props> = (props) => {
  const id = props.match.params.id;
  const [details, setDetails] = useState<any>(null);
  useEffect(() => {
    getDocument(id)?.then(setDetails);
  }, [id]);

  return (
    <DetailedDocument
      readOnly
      id={id}
      initialValues={details}
      hideReadonlySubmitButton={true}
      action={'view-documents' as DOCUMENTS_ACTION}
    />
  );
};

export default Preview;
