import React, { FC } from 'react';
import {
  Document,
  Image as PDFImage,
  Page,
  Text,
  View,
} from '@react-pdf/renderer';
import { styles } from '@apps/sales/mainMenu/components/pdfTemplates/styleSheet';
import EmptyPage from '@apps/sales/mainMenu/components/pdfTemplates/EmptyPage';

type PdfTemplateData = {
  ecosystem?: Ecosystem;
  avatarBase64?: string | null;
};

const PdfTemplate: FC<PdfTemplateData> = ({ ecosystem, avatarBase64 }) => {
  if (!ecosystem) {
    return <EmptyPage />;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {ecosystem && (
          <View style={styles.section}>
            {ecosystem.address ? (
              <Text
                style={styles.companyAddress}
              >{`${ecosystem.address.street}, ${ecosystem.address.number}, ${ecosystem.address.city}, ${ecosystem.address.zipcode}, ${ecosystem.address.country}`}</Text>
            ) : (
              // for spacing reasons
              <Text> </Text>
            )}
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {avatarBase64 && (
                <PDFImage
                  src={avatarBase64}
                  style={{ width: 24, height: 24 }}
                />
              )}
              <Text style={{ marginLeft: 8, fontSize: '11px' }}>
                {ecosystem.name}
              </Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default PdfTemplate;
