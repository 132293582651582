import * as React from 'react';
import { Radio as AntdRadio } from 'antd';
import { RadioProps } from 'antd/lib/radio';
import { StyledInnerLabel } from '@components/FormFields/common.sc';

type Props = RadioProps & {
  name?: string;
  label?: React.ReactNode;
  inGroup?: boolean;
};

const Radio: React.FC<Props> = (props) => {
  if (props.inGroup) {
    return (
      <AntdRadio {...props}>
        <StyledInnerLabel>{props.label}</StyledInnerLabel>
      </AntdRadio>
    );
  }

  return (
    <>
      <div>
        <AntdRadio {...props}>{props.children}</AntdRadio>
      </div>
    </>
  );
};

export default Radio;
