import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from 'antd';
import { StyledButton } from '@pages/settings/components';
import moment from 'moment';
import { ReportTriggerControlWrapper } from '@apps/directSales/styles.sc';
import { DatePickerProps } from 'antd/lib/date-picker';

type Props = {
  handleGenerateReportClick(period: string): void;
  handleCalculateSaleCommissionClick(period: string): void;
  handleExportReportClick(period: string): void;
  handleEmailsSend(period: string): void;
  handleReportsDownload(period: string): void;
};

const monthFormat = 'YYYY_M';

const disabledDate = (current: moment.Moment) =>
  current && current > moment().endOf('month');

const ReportTriggerControl: FC<Props> = ({
  handleGenerateReportClick,
  handleCalculateSaleCommissionClick,
  handleExportReportClick,
  handleEmailsSend,
  handleReportsDownload,
}) => {
  const [period, setPeriod] = useState(moment().format(monthFormat));
  const onChange: DatePickerProps['onChange'] = (_date, dateString) => {
    setPeriod(dateString);
  };
  return (
    <ReportTriggerControlWrapper>
      <DatePicker
        defaultValue={moment()}
        format={monthFormat}
        picker="month"
        disabledDate={disabledDate}
        onChange={onChange}
      />
      <StyledButton
        size="small"
        onClick={() => handleGenerateReportClick(period)}
      >
        <FormattedMessage
          id="commissions.generate.report"
          defaultMessage="Generate report"
        />
      </StyledButton>
      <StyledButton
        size="small"
        onClick={() => handleCalculateSaleCommissionClick(period)}
      >
        <FormattedMessage
          id="commissions.calculate.sale.commissions"
          defaultMessage="Calculate sale commissions"
        />
      </StyledButton>
      <StyledButton
        size="small"
        onClick={() => handleExportReportClick(period)}
      >
        <FormattedMessage
          id="commissions.export.sale.commissions"
          defaultMessage="Export sale commissions"
        />
      </StyledButton>
      <StyledButton size="small" onClick={() => handleReportsDownload(period)}>
        <FormattedMessage
          id="commissions.download.reports"
          defaultMessage="Download reports"
        />
      </StyledButton>
      <StyledButton size="small" onClick={() => handleEmailsSend(period)}>
        <FormattedMessage
          id="commissions.send.emails"
          defaultMessage="Send reports"
        />
      </StyledButton>
    </ReportTriggerControlWrapper>
  );
};

export default ReportTriggerControl;
