import { CartEntry } from '@services/cart/namespace';

export enum CartActionTypes {
  INIT_CART = 'INIT_CART',
  UPDATE_CART = 'UPDATE_CART',
  REMOVE_ENTRY_CART = 'REMOVE_ENTRY_CART',
  REMOVE_CART = 'REMOVE_CART',
}

export const removeCart = () =>
  ({
    type: CartActionTypes.REMOVE_CART,
  } as const);

export type RemoveCartAction = ReturnType<typeof removeCart>;

type RemoveEmptyCartPayload = CartEntry;

export const removeEntryCart = (payload: RemoveEmptyCartPayload) =>
  ({
    type: CartActionTypes.REMOVE_ENTRY_CART,
    payload,
  } as const);

export type RemoveEntryCartAction = ReturnType<typeof removeEntryCart>;

type UpdateCartPayload = {
  cartEntry: CartEntry;
  override?: boolean;
};

export const updateCart = (payload: UpdateCartPayload) =>
  ({
    type: CartActionTypes.UPDATE_CART,
    payload,
  } as const);

export type UpdateCartAction = ReturnType<typeof updateCart>;

type InitCartPayload = CartEntry[];

export const initCart = (payload: InitCartPayload) =>
  ({
    type: CartActionTypes.INIT_CART,
    payload,
  } as const);

export type InitCartAction = ReturnType<typeof initCart>;

export type CartAction =
  | UpdateCartAction
  | InitCartAction
  | RemoveEntryCartAction
  | RemoveCartAction;
