import * as React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Card } from '@todix/ui-components';
import {
  DashboardOutlined,
  FileOutlined,
  NodeIndexOutlined,
} from '@ant-design/icons';
import DetailContactForm, {
  DetailContactFormProps,
} from '@apps/contacts/mainMenu/components/DetailContactForm';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import {
  AddressEntry,
  CompanyContact,
  PersonContact,
  deleteContact,
} from '@apps/contacts/services';
import removeUndefined from '@core/helpers/removeUndefined';
import Documents from './Documents';
import { FormattedMessage, useIntl } from 'react-intl';
import { getContactName } from '@apps/documents/service';
import optionsMapper from '@core/helpers/optionsMapper';
import ContactsAppConfig, { CONTACTS_ACTION } from '@apps/contacts';
import Relations from '@components/Relations';
import { useEffect } from 'react';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import Tabs from '@components/Tabs';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

const { TabPane } = Tabs;

type DetailedContactProps = {
  id?: string;
  action: string;
} & Partial<DetailContactFormProps>;

const breadcrumbHeader = (values: Partial<PersonContact & CompanyContact>) => {
  if (values) {
    return getContactName(values) || '';
  }
  return '';
};

const DetailedContact: React.FC<DetailedContactProps> = ({
  id,
  initialValues = {},
  onSubmit,
  onCancel,
  readOnly,
  shouldRedirect,
  action,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const canUpdate = initialValues
    ? checkUserPermissions(
        rootStore.getState(),
        ContactsAppConfig.todixId,
        'update-contacts' as CONTACTS_ACTION,
        initialValues?.ecosystem as string,
      )
    : false;
  const canDelete = initialValues
    ? checkUserPermissions(
        rootStore.getState(),
        ContactsAppConfig.todixId,
        'delete-contacts' as CONTACTS_ACTION,
        initialValues?.ecosystem as string,
      )
    : false;

  const parseValues = (values: PersonContact | CompanyContact) => {
    let parsedValues = values;

    if (values.type === 'person' && (values as PersonContact).birthDate) {
      (parsedValues as PersonContact).birthDate = moment(
        (values as PersonContact).birthDate,
      ).format();
    }

    if (
      values.type === 'company' &&
      (values as CompanyContact).foundationDate
    ) {
      (parsedValues as CompanyContact).foundationDate = moment(
        (values as CompanyContact).foundationDate,
      ).format();
    }

    if (parsedValues.addresses) {
      parsedValues.addresses = parsedValues.addresses.map(
        (address) => removeUndefined(address) as AddressEntry,
      );
    }

    if (onSubmit) {
      onSubmit(parsedValues);
    }
  };

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${ContactsAppConfig.todixId}`,
        intl.formatMessage({
          id: 'contacts.detailedcontact.nav.contacts',
          defaultMessage: 'Contacts',
        }),
      ],
      id
        ? [
            `/app/${ContactsAppConfig.todixId}/${id}`,
            breadcrumbHeader(initialValues),
          ]
        : [
            `/app/${ContactsAppConfig.todixId}/create`,
            intl.formatMessage({
              id: 'contacts.detailedcontact.nav.create',
              defaultMessage: 'Create',
            }),
          ],
    ];
    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [id, initialValues, intl]);

  return (
    <>
      <Card>
        <Tabs
          defaultActiveKey="1"
          onChange={() => {}}
          tabBarExtraContent={
            id && (
              <ActionRenderer
                path={`/app/${ContactsAppConfig.todixId}/${id}`}
                options={canDelete ? optionsMapper(['delete'], intl) : []}
                handleDelete={() => {
                  if (canDelete) {
                    deleteContact(id)?.then(() => {
                      history.push(`/app/${ContactsAppConfig.todixId}`);
                    });
                  }
                }}
                intl={intl}
                appConfig={ContactsAppConfig}
                ecosystemId={initialValues?.ecosystem}
              />
            )
          }
        >
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="detailcontact.tab.base"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <DetailContactForm
              id={id}
              initialValues={initialValues}
              onSubmit={(values) => parseValues(values)}
              onCancel={onCancel}
              readOnly={readOnly || !canUpdate}
              shouldRedirect={shouldRedirect}
              action={action}
              canUpdate={canUpdate}
            />
          </TabPane>
          {id && (
            <TabPane
              key="2"
              tab={
                <>
                  <FileOutlined />{' '}
                  <FormattedMessage
                    id="detailcontact.tab.documents"
                    defaultMessage="Documents"
                  />
                </>
              }
            >
              <Documents contactId={id} />
            </TabPane>
          )}
          <TabPane
            key="3"
            disabled={!id || !initialValues?.ecosystem}
            tab={
              <>
                <NodeIndexOutlined />{' '}
                <FormattedMessage
                  id="relations.tab"
                  defaultMessage="Relations"
                />
              </>
            }
          >
            {initialValues?.ecosystem && id && (
              <Relations
                ecosystem={initialValues.ecosystem}
                entityId={id}
                entityType="contact"
              />
            )}
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};

export default DetailedContact;
