import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Consultants from '@apps/directSales/mainMenu/Consultants/consultants';
import Parties from '@apps/directSales/mainMenu/Parties/parties';
import News from '@apps/directSales/mainMenu/News/news';
import ConsultantDetails from '@apps/directSales/mainMenu/Consultants/ConsultantDetails';

const Routing: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/consultants/:id/edit`}>
        <ConsultantDetails readOnly={false} />
      </Route>
      <Route exact path={`${path}/consultants/:id`}>
        <ConsultantDetails readOnly={true} />
      </Route>
      <Route exact path={`${path}/create-consultant`}>
        <ConsultantDetails readOnly={false} />
      </Route>
      <Route path={`${path}/consultants`}>
        <Consultants />
      </Route>
      <Route path={`${path}/parties`}>
        <Parties />
      </Route>
      <Route path={`${path}/news`}>
        <News />
      </Route>
    </Switch>
  );
};

export default Routing;
