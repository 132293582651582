import { ApiOptions, put, post } from '../api';
import {
  RangeOfNumber,
  RangeOfNumbersType,
} from '@services/rangeOfNumbers/namespace';
import { removeUndefinedProperties } from '@services/utils';
import { firebase } from '@services/Firebase';
import moment from 'moment/moment';
import { supportedFileTypes } from '@apps/sales/settings/RangeOfNumbers/consts';
import intl from '../../../intl';

export * from './namespace';

const COLLECTION_NAME = 'salesRangeOfNumbers';

export const createSalesRangeOfNumbers = (
  rangeOfNumbers: RangeOfNumbersType,
  options?: ApiOptions,
) =>
  post(
    `${COLLECTION_NAME}`,
    {
      ...removeUndefinedProperties(rangeOfNumbers),
      timestamp: moment().valueOf(),
    },
    options,
  );

export const addSalesRangeOfNumbers = (
  rangeOfNumbers: RangeOfNumbersType,
  id: string,
  options?: ApiOptions,
) =>
  put(
    `${COLLECTION_NAME}/${id}`,
    {
      ...removeUndefinedProperties(rangeOfNumbers),
      timestamp: moment().valueOf(),
    },
    options,
  );

export const getRangeOfNumbersByEcosystem = (
  id: string,
): Promise<any | null> | undefined => {
  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(id)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return doc.data() as RangeOfNumbersType;
      } else {
        return {
          ecosystem: id,
          id,
          fileTypes: {
            ...Object.keys(supportedFileTypes).reduce((prev: any, fileType) => {
              prev[fileType] = {
                abbreviation: intl.formatMessage({
                  id: `settings.sales.rangeOfNumbers.supportedFileTypes.abbreviation.${fileType}`,
                  defaultMessage: `settings.sales.rangeOfNumbers.supportedFileTypes.abbreviation.${fileType}`,
                }),
                minLength: 2,
              } as RangeOfNumber;
              return prev;
            }, {} as RangeOfNumbersType['fileTypes']),
          },
        } as RangeOfNumbersType;
      }
    });
};
