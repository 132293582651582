import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { FileApp } from '@apps/AppTypes';

export type TASKS_ACTION =
  | 'view-tasks'
  | 'create-tasks'
  | 'update-tasks'
  | 'delete-tasks';

export type TASKS_PERMISSION = 'all' | 'none';

export default {
  todixId: 'tasks',
  link: '/app/tasks',
  name: 'tasks',
  entityType: 'task',
  collectionName: 'tasks',
  localizationPath: 'tasks/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.tasks.createNewLink.new.task',
    },
  ],
  listCellRenderers: [
    {
      name: 'tasksInProjectCellRenderer',
      path: 'tasks/widgets/TasksInProjectCellRenderer',
    },
  ],
  widgets: [
    {
      name: 'TreeNode',
      path: 'tasks/widgets/TreeNode',
    },
    {
      name: 'DashboardWidget',
      path: 'tasks/widgets/DashboardWidget',
    },
    {
      name: 'StatisticsWidget',
      path: 'tasks/widgets/StatisticsWidget',
    },
    {
      name: 'AppRolesForm',
      path: 'tasks/widgets/AppRolesForm',
    },
  ],
  actions: [
    'view-tasks',
    'create-tasks',
    'update-tasks',
    'delete-tasks',
  ] as TASKS_ACTION[],
  permissions: ['all', 'none'] as TASKS_PERMISSION[],
} as FileApp;
