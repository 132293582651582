import moment from 'moment';
import {
  WeekPlanEntry,
  WEEK_PLAN_COLLECTION_NAME,
} from '@apps/weekplan/services/namespace';
import { post } from '@services/api';

const addWeekPlan = (weekPlanEntry: WeekPlanEntry): Promise<string> =>
  post(`${WEEK_PLAN_COLLECTION_NAME}`, {
    ...weekPlanEntry,
    timestamp: moment().valueOf(),
  });

export default addWeekPlan;
