import { lazy } from 'react';
import {
  ShopOutlined,
  TeamOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';

const Routing = lazy(
  () => import(/* webpackChunkName: "directSalesApp" */ './Routing'),
);

const Consultants = lazy(
  () =>
    import(
      /* webpackChunkName: "directSalesApp" */ './Consultants/consultants'
    ),
);

const Parties = lazy(
  () => import(/* webpackChunkName: "directSalesApp" */ './Parties/parties'),
);
const News = lazy(
  () => import(/* webpackChunkName: "directSalesApp" */ './News/news'),
);

export default {
  appSubmenuName: 'app.directsales',
  icon: ShopOutlined,
  component: Routing,
  sections: [
    {
      link: 'consultants',
      title: 'app.directsales.consultants',
      component: Consultants,
      icon: TeamOutlined,
    },
    {
      link: 'parties',
      title: 'app.directsales.parties',
      component: Parties,
      icon: ShoppingOutlined,
    },
    {
      link: 'news',
      title: 'app.directSales.news',
      component: News,
      icon: ShoppingOutlined,
    },
  ],
};
