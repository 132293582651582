import { firebase } from '@services/Firebase';
import { Consultant } from '@apps/directSales/mainMenu/Consultants/services/namespace';

const createConsultant = async (consultant: Partial<Consultant>) => {
  if (
    consultant.consultantEmail &&
    consultant.consultantName &&
    consultant.consultantSurname
  ) {
    return firebase.functions
      ?.httpsCallable('registerConsultant')(consultant)
      .then(() => {
        return consultant;
      });
  }
  return null;
};

export default createConsultant;
