import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Select, Space } from 'antd';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import {
  PaymentTerm,
  getActivePaymentTerms,
} from '@apps/purchases/services/paymentTerms';

type Props = {
  onPaymentTermsSet(): void;
};

const PaymentTermsSearch: FC<Props> = ({ onPaymentTermsSet }) => {
  const { getFieldValue, setFieldsValue, getFieldsValue } = useFormContext();
  const ecosystem = getFieldValue('ecosystem');

  const [terms, setTerms] = useState<PaymentTerm[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (ecosystem) {
      setLoading(true);
      getActivePaymentTerms(ecosystem)
        ?.then((results) => {
          setTerms(results);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem]);

  const handleChange = useCallback(
    (id) => {
      const term = terms.find((term) => term.id === id);
      if (term) {
        setFieldsValue({
          ...getFieldsValue(),
          paymentTerms: term,
        });
        onPaymentTermsSet();
      }
    },
    [terms, getFieldsValue, onPaymentTermsSet, setFieldsValue],
  );

  const options = useMemo(() => {
    return terms.map((term, index) => {
      return {
        value: term.id,
        'data-forsearch': `${term?.name || ''} ${term?.description || ''} ${
          term?.id || ''
        } ${term?.category || ''}`,
        label: (
          <Space size="small" direction="horizontal" key={index}>
            {term.name && <div>({term.name})</div>}
            {term.description && <div>{term.description}</div>}
          </Space>
        ),
      };
    });
  }, [terms]);
  return (
    <Select
      showSearch={true}
      loading={loading}
      onChange={handleChange}
      filterOption={(inputValue, option) => {
        return option
          ? option['data-forsearch']
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          : false;
      }}
      placeholder={
        <FormattedMessage
          id="sales.settings.paymentTerms.header"
          defaultMessage="Payment terms"
        />
      }
      options={options}
    />
  );
};

export default PaymentTermsSearch;
