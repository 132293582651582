import React, { FC } from 'react';

import { Select } from '@components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUnitGroupsForEcosystemSelector } from '@core/layout/store/unitGroupsSaga/selectors';
import { Form as aForm } from 'antd';
import intl from '../../../../../../intl';

const { Item } = aForm;

export const initialUnit = {
  unit: '',
};

const { Option, OptGroup } = Select;

const SelectedUnit: FC<any> = ({
  ecosystemId,
  namePrefix,
  withLabel = true,
  selectStyle,
}) => {
  const { readOnly, getFieldValue } = useFormContext();
  const ecosystem = getFieldValue('ecosystem') || ecosystemId;
  const unitGroups = useSelector(getUnitGroupsForEcosystemSelector(ecosystem));

  if (!unitGroups || !unitGroups.groups) {
    return null;
  }

  return (
    <Item noStyle shouldUpdate={true}>
      {() => {
        return (
          <Item
            name={namePrefix !== undefined ? [namePrefix, `unit`] : `unit`}
            label={
              withLabel && (
                <FormattedMessage
                  id="products.selectedunit.label"
                  defaultMessage="Unit"
                />
              )
            }
          >
            <Select
              disabled={readOnly}
              style={selectStyle}
              allowClear
              optionLabelProp="label"
              name="unit"
              placeholder={
                <FormattedMessage
                  id="products.selectedunit.placeholder"
                  defaultMessage="Select unit..."
                />
              }
            >
              {unitGroups.groups.map((unitGroup) => (
                <OptGroup label={unitGroup.name}>
                  {unitGroup.units
                    .filter((unit) => unit.isActive)
                    .map(({ name }) => (
                      <Option
                        value={name}
                        key={name}
                        label={intl.formatMessage({
                          id: `products.unit.${name}`,
                          defaultMessage: name,
                        })}
                      >
                        {intl.formatMessage({
                          id: `products.unit.${name}`,
                          defaultMessage: name,
                        })}
                      </Option>
                    ))}
                </OptGroup>
              ))}
            </Select>
          </Item>
        );
      }}
    </Item>
  );
};

export default SelectedUnit;
