import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import { ConsultantWithStats } from '@apps/directSales/mainMenu/Consultants/services';
import withSuffix from '@core/helpers/withSuffix';
import {
  ColSpanParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';

export const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="ecosystem"
      cellRenderer={'ecosystemRenderer'}
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      headerName={intl.formatMessage({
        id: 'consultants.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      filter="ecosystemFilter"
    />
    <AgGridColumn
      field="consultantNo"
      filter={true}
      lockPosition
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantNo.header',
        defaultMessage: 'Consultant No',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantName"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantName.header',
        defaultMessage: 'Consultant Name',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantSurname"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantSurname.header',
        defaultMessage: 'Consultant Surname',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantEmail"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantEmail.header',
        defaultMessage: 'Consultant Email',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantStreet"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantStreet.header',
        defaultMessage: 'Consultant Street',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantStreetNo"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantStreetNo.header',
        defaultMessage: 'Consultant Street No',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantZip"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantZip.header',
        defaultMessage: 'Consultant Zip',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantCity"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantCity.header',
        defaultMessage: 'Consultant City',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantParent"
      cellRenderer="consultantParentCellRenderer"
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantParent.header',
        defaultMessage: 'Consultant Parent',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="isActive"
      cellRenderer={'activationCellRenderer'}
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.isActive.header',
        defaultMessage: 'Active',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="validated"
      cellRenderer={'validationCellRenderer'}
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.validated.header',
        defaultMessage: 'Validated',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="validationDate"
      filter={true}
      cellRenderer={'timestampRenderer'}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.validationDate.header',
        defaultMessage: 'ValidationDate',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantBirthday"
      filter={true}
      cellRenderer={'timestampRenderer'}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantBirthday.header',
        defaultMessage: 'consultantBirthday',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="validatedById"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.validatedById.header',
        defaultMessage: 'Validator',
      })}
      cellRenderer="userRenderer"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="description"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.description.header',
        defaultMessage: 'description',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantRank"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantRank.header',
        defaultMessage: 'Consultant Rank',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="applicationDate"
      filter={true}
      cellRenderer={'timestampRenderer'}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.applicationDate.header',
        defaultMessage: 'Application Date',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="PrivacyProtectionConfirmed"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.PrivacyProtectionConfirmed.header',
        defaultMessage: 'Privacy Protection Confirmed',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="careerplanConfirmed"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.careerplanConfirmed.header',
        defaultMessage: 'Careerplan Confirmed',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="agbConfirmed"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.agbConfirmed.header',
        defaultMessage: 'AGB Confirmed',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="consultantLevel"
      filter={true}
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.relatedLevel || '';
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.consultantLevel.label',
        defaultMessage: 'Consultant Level',
      })}
    />
    <AgGridColumn
      cellRenderer={'actionRenderer'}
      maxWidth={70}
      filter={false}
      pinned="right"
    />
    <AgGridColumn
      cellRenderer="mobileLicenseCellRenderer"
      headerName={intl.formatMessage({
        id: 'consultants.columns.license.header',
        defaultMessage: 'Mobile license',
      })}
      pinned="left"
      filter={false}
      minWidth={120}
    />
    <AgGridColumn
      field="iban"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.iban.header',
        defaultMessage: 'Iban',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="bic"
      filter={true}
      sortable
      headerName={intl.formatMessage({
        id: 'consultants.columns.bic.header',
        defaultMessage: 'BIC',
      })}
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
    />
    <AgGridColumn
      field="stats.sales"
      filter={'agNumberColumnFilter'}
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return withSuffix({
          value: consultant?.stats?.sales || 0,
        });
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.sales || 0;
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'commissions.sales',
        defaultMessage: 'Sales',
      })}
    />
    <AgGridColumn
      field="stats.teamSales"
      filter={'agNumberColumnFilter'}
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return withSuffix({
          value: consultant?.stats?.teamSales || 0,
        });
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.teamSales || 0;
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'commissions.team.sales',
        defaultMessage: 'Team sales',
      })}
    />
    <AgGridColumn
      field="stats.commissionBonus"
      filter={'agNumberColumnFilter'}
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return withSuffix({
          value: consultant?.stats?.commissionBonus || 0,
          suffix: '%',
        });
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.commissionBonus || 0;
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'commissions.commission.bonus',
        defaultMessage: 'Commission bonus',
      })}
    />
    <AgGridColumn
      field="stats.memberBonus"
      filter={'agNumberColumnFilter'}
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return withSuffix({
          value: consultant?.stats?.memberBonus || 0,
          suffix: '%',
        });
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.memberBonus || 0;
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'commissions.member.bonus',
        defaultMessage: 'Member bonus',
      })}
    />
    <AgGridColumn
      field="stats.period"
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.period || '';
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.period || '';
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'commissions.last.period',
        defaultMessage: 'Last period',
      })}
    />
    <AgGridColumn
      field="stats.teamSize"
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.teamSize || 0;
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.teamSize || 0;
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'commissions.team.size',
        defaultMessage: 'Team size',
      })}
    />
    <AgGridColumn
      field="stats.newJoiners"
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.newJoiners || 0;
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.newJoiners || 0;
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'active.recruiting',
        defaultMessage: 'Active recruiting',
      })}
    />
    <AgGridColumn
      field="stats.recruitingBonus"
      filter={'agNumberColumnFilter'}
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return withSuffix({
          value: consultant?.stats?.recruitingBonus || 0,
        });
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.recruitingBonus || 0;
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'commissions.recruitingBonus',
        defaultMessage: 'Recruiting bonus',
      })}
    />
    <AgGridColumn
      field="stats.productBonus"
      filter={'agNumberColumnFilter'}
      valueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return withSuffix({
          value: consultant?.stats?.productBonus || 0,
        });
      }}
      filterValueGetter={({ data }: ValueGetterParams) => {
        const consultant = data as ConsultantWithStats;
        return consultant?.stats?.productBonus || 0;
      }}
      sortable
      headerName={intl.formatMessage({
        id: 'commissions.productBonus',
        defaultMessage: 'Product bonus',
      })}
    />
    <AgGridColumn
      field="id"
      cellRenderer="reportEmailStatusCellRenderer"
      headerName={intl.formatMessage({
        id: 'commissions.email.report.status',
        defaultMessage: 'Last email report',
      })}
    />
  </>
);
