import React, { FC } from 'react';
import SelectedEcosystem from '@apps/products/mainMenu/components/Form/components/SelectedEcosystem';
import CreationDate from '@apps/products/mainMenu/components/Form/components/CreationDate';
import Creator from '@apps/products/mainMenu/components/Form/components/Creator';
import { Spin } from 'antd';
import SpinContainer from '@components/SpinContainer';
import MediaViewer from '@components/MediaViewer';
import { PRODUCTS_ACTION } from '@apps/products';

type RightColumnProps = {
  ecosystemId?: string;
  readOnly: boolean;
  form: any;
  namePrefix?: string | number;
  isLoading: boolean;
  relatedDocuments: any[];
  action: PRODUCTS_ACTION;
};

const RightColumn: FC<RightColumnProps> = ({
  isLoading,
  relatedDocuments,
  action,
}) => {
  return (
    <div>
      <SelectedEcosystem action={action} />
      <Creator />
      <CreationDate />
      <div>
        <Spin spinning={isLoading} indicator={<SpinContainer />}>
          <MediaViewer
            showPlayButton={false}
            thumbnailPosition="top"
            items={relatedDocuments.map((doc) => ({
              url: doc.documentUrl,
              thumbnailUrl:
                doc.fileDetails.format === 'pdf'
                  ? doc.thumbnailUrl || 'placeholder'
                  : doc.documentUrl,
              format: doc.fileDetails.format,
            }))}
          />
        </Spin>
      </div>
    </div>
  );
};

export default RightColumn;
