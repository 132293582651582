import { useSalesDeliveryTerms } from '@apps/sales/settings/DeliveryTerms/useDeliveryTerms';
import { Input, Form as aForm } from 'antd';
import React, { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
const { Item } = aForm;
const { TextArea } = Input;

type DeliveryTermsType = {
  form: any;
};

export const DeliveryTerms: FC<DeliveryTermsType> = ({ form }) => {
  const intl = useIntl();
  const [, deliveryTerms] = useSalesDeliveryTerms(
    form.getFieldsValue().ecosystem,
  );

  useEffect(() => {
    const allFormValues = form.getFieldsValue();
    form.setFieldsValue({
      ...allFormValues,
      shortage: deliveryTerms ? deliveryTerms.shortage : '',
      longName: deliveryTerms ? deliveryTerms.longName : '',
      category: deliveryTerms ? deliveryTerms.category : '',
      description: deliveryTerms ? deliveryTerms.description : '',
    });
  }, [deliveryTerms]);

  return (
    <>
      <Item
        label={intl.formatMessage({
          id: 'sales.createNew.headerDescription.label',
          defaultMessage: 'Header description',
        })}
        name="headerDescription"
      >
        <TextArea
          maxLength={250}
          name={`headerDescription`}
          placeholder={intl.formatMessage({
            id: 'sales.createNew.headerDescription.label',
            defaultMessage: 'Header description',
          })}
        />
      </Item>
      <Item
        label={intl.formatMessage({
          id: 'sales.createNew.footerDescription.label',
          defaultMessage: 'Footer description',
        })}
        name="footerDescription"
      >
        <TextArea
          maxLength={250}
          name={`footerDescription`}
          placeholder={intl.formatMessage({
            id: 'sales.createNew.footerDescription.label',
            defaultMessage: 'Footer description',
          })}
        />
      </Item>
      <Item
        label={intl.formatMessage({
          id: 'sales.createNew.delivery.label',
          defaultMessage: 'Delivery',
        })}
        name="delivery"
      >
        <Input
          name={`delivery`}
          placeholder={intl.formatMessage({
            id: 'sales.createNew.delivery.label',
            defaultMessage: 'Delivery',
          })}
        />
      </Item>
      <Item
        label={intl.formatMessage({
          id: 'sales.createNew.paymentTerms.label',
          defaultMessage: 'Payment terms',
        })}
        name="paymentTerms"
      >
        <Input
          name={`paymentTerms`}
          placeholder={intl.formatMessage({
            id: 'sales.createNew.paymentTerms.label',
            defaultMessage: 'Payment terms',
          })}
        />
      </Item>
    </>
  );
};
