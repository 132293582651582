import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  archiveProductPurchasePrice,
  getProductPurchasePrices,
  ProductPurchasePrice,
} from '@apps/products/services';
import { BigBold, FlexSpaceBetween } from './styles.sc';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { ColumnType } from 'antd/lib/table';
import StyledTable from '@pages/settings/components/StyledTable';
import PriceName from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/PriceName';
import PriceStatus from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/PriceStatus';
import { StyledDeleteIcon, StyledEditIcon } from '@pages/settings/components';
import PriceModal from '@apps/purchases/mainMenu/purchasesTabForProduct/purchasePricing/PriceModal';

type PurchasePricingProps = {
  readOnly: boolean;
  ecosystem: Ecosystem;
  productId?: string;
};

const PurchasePricing: FC<PurchasePricingProps> = ({
  readOnly,
  ecosystem,
  productId,
}) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState<ProductPurchasePrice[]>([]);
  const [selectedPrice, setSelectedPrice] =
    useState<ProductPurchasePrice | null>(null);

  useEffect(() => {
    if (productId && ecosystem?.id) {
      setLoading(true);
      getProductPurchasePrices({
        ecosystemId: ecosystem.id,
        productId,
        skipArchived: true,
      })
        ?.then((fetchedPrices) => {
          setPrices(fetchedPrices);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem?.id, productId]);

  const onCancel = useCallback(() => {
    setVisible(false);
    setSelectedPrice(null);
  }, []);

  const onOk = useCallback(() => {
    setVisible(false);
    if (productId && ecosystem) {
      setLoading(true);
      getProductPurchasePrices({
        ecosystemId: ecosystem.id,
        productId,
        skipArchived: true,
      })
        ?.then(setPrices)
        .finally(() => {
          setLoading(false);
          setSelectedPrice(null);
        });
    }
  }, [ecosystem, productId]);

  const handlePriceChange = useCallback(async () => {
    if (productId && ecosystem) {
      setLoading(true);
      getProductPurchasePrices({
        ecosystemId: ecosystem.id,
        productId,
        skipArchived: true,
      })
        ?.then(setPrices)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem, productId]);

  const handlePriceArchive = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await archiveProductPurchasePrice(id);
        await handlePriceChange();
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    },
    [handlePriceChange],
  );

  const title = useMemo(
    () => () =>
      (
        <FlexSpaceBetween>
          <BigBold>
            <FormattedMessage
              id="sales.salesTab.pricing.header"
              defaultMessage="Pricing"
            />
          </BigBold>
          {!readOnly && productId && (
            <Button
              disabled={readOnly || !productId}
              onClick={() => setVisible(true)}
            >
              <FormattedMessage
                id="sales.salesTab.addPrice.button"
                defaultMessage="Add price"
              />
            </Button>
          )}
          {!readOnly && !productId && (
            <div>
              <FormattedMessage
                id="sales.add.product.first"
                defaultMessage="Please save the product first to add a price to the table."
              />
            </div>
          )}
        </FlexSpaceBetween>
      ),
    [productId, readOnly],
  );

  const columns: ColumnType<any>[] = useMemo(() => {
    const cols: ColumnType<any>[] = [
      {
        title: (
          <FormattedMessage
            id="sales.salesTab.unitAmount.label"
            defaultMessage="Price"
          />
        ),
        sorter: true,
        ellipsis: true,
        render: (_v, record: ProductPurchasePrice) => (
          <PriceName price={record} />
        ),
      },
    ];

    if (readOnly) {
      return cols;
    }

    return [
      ...cols,
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: ProductPurchasePrice) => (
          <PriceStatus price={record} handlePriceChange={handlePriceChange} />
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: ProductPurchasePrice) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedPrice(record);
                setVisible(true);
              }}
            />
            <StyledDeleteIcon
              onClick={() => handlePriceArchive(record.id || '')}
            />
          </>
        ),
      },
    ];
  }, [handlePriceChange, handlePriceArchive, readOnly]);

  return (
    <>
      <StyledTable
        columns={columns}
        dataSource={prices}
        bordered
        tableLayout="auto"
        loading={loading}
        title={title}
        rowKey="id"
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: 5,
        }}
      />
      <PriceModal
        visible={visible}
        price={selectedPrice}
        onOk={onOk}
        onCancel={onCancel}
      />
    </>
  );
};

export default PurchasePricing;
