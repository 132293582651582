import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl/lib';
import { Tag } from 'antd';

type SubscriptionStatusProps = {
  status: Subscription['status'];
};

const SubscriptionStatus: FC<SubscriptionStatusProps> = ({ status }) => {
  const color = useMemo(() => {
    if (status === 'active') {
      return 'success';
    }
    if (status === 'unpaid') {
      return 'error';
    }
    return 'default';
  }, [status]);
  return (
    <Tag color={color}>
      <FormattedMessage id={`status.${status}`} defaultMessage={status} />
    </Tag>
  );
};

export default SubscriptionStatus;
