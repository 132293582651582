import React, { FC, useState, useCallback, useRef } from 'react';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Card, message, Spin } from 'antd';
import { Button, Form, Input } from 'antd';
import Tabs from '@components/Tabs';
import styled from 'styled-components';
import intl from '../../../../../intl';
import { useCustomEventListener } from '@core/services';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { updateIndividualPurchaseRequest } from '@apps/purchases/services/individualPurchaseRequests';
import {
  PositionMonitor,
  updatePositionMonitor,
} from '@apps/purchases/services/positionMonitor';
import moment from 'moment/moment';

const { Item } = Form;

const { TabPane } = Tabs;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
`;

const FormContainer = styled.div`
  padding: 16px;
`;

export type ShippingDetailsEventName = 'showShippingDetails';

export interface ShippingDetailsEventPayload {
  request: IndividualPurchaseRequest;
  readOnly?: boolean;
}

type ShippingDetailsProps = {};

const ShippingDetails: FC<ShippingDetailsProps> = () => {
  const [form] = Form.useForm();
  const [request, setRequest] = useState<IndividualPurchaseRequest | null>(
    null,
  );
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const readOnlyRef = useRef(false);

  const onSubmit = useCallback(
    async (values: any) => {
      if (request && request.id) {
        try {
          setLoading(true);
          await updateIndividualPurchaseRequest(request.id, {
            ...values,
          });
          const monitorUpdate: Partial<PositionMonitor> = {
            tracked: {
              status: 'done',
              relatedDocumentId: '',
              timestamp: moment().valueOf(),
            },
          };
          await updatePositionMonitor(request.id, monitorUpdate);
          setLoading(false);
          setShow(false);
          form.setFieldsValue({});
          setRequest(null);
        } catch (e) {
          console.error(e);
          setLoading(false);
          message.error(
            intl.formatMessage({
              id: 'error.request',
              defaultMessage: 'Error while processing request',
            }),
          );
        }
      }
    },
    [form, request],
  );

  const handleCancel = useCallback(() => {
    setShow(false);
    form.resetFields();
  }, [form]);

  useCustomEventListener<ShippingDetailsEventName, ShippingDetailsEventPayload>(
    'showShippingDetails',
    (data) => {
      if (data?.request) {
        readOnlyRef.current = data.readOnly || false;
        setRequest(data.request);
        setShow(true);
      }
    },
  );

  if (!request) {
    return null;
  }

  const initialValues = {
    shipper: request.shipper || '',
    trackingCode: request.trackingCode || '',
    trackingLink: request.trackingLink || '',
  };

  const readOnly = loading || readOnlyRef.current;

  return (
    <DraggableModalProvider>
      <DraggableModal
        confirmLoading={true}
        visible={show}
        footer={null}
        onCancel={handleCancel}
      >
        <Spin spinning={loading}>
          <Card actions={[<div />]}>
            <Tabs defaultActiveKey="1">
              <TabPane
                key="1"
                tab={intl.formatMessage({
                  id: 'shipping.details',
                  defaultMessage: 'Shipping Details',
                })}
              >
                <AppEntityForm
                  appId={''}
                  autoComplete="off"
                  initialValues={initialValues}
                  name="shippingDetails"
                  providedForm={form}
                  readOnly={readOnly}
                  onSubmit={onSubmit}
                  hideButtons={true}
                >
                  {() => {
                    return (
                      <FormContainer>
                        <Item
                          name="shipper"
                          label={intl.formatMessage({
                            id: 'shipper',
                            defaultMessage: 'Shipper',
                          })}
                        >
                          <Input readOnly={readOnly} />
                        </Item>
                        <Item
                          name="trackingCode"
                          label={intl.formatMessage({
                            id: 'tracking.code',
                            defaultMessage: 'Tracking Code',
                          })}
                          required
                          rules={[
                            {
                              required: true,
                              message: intl.formatMessage({
                                id: 'tracking.code.required',
                                defaultMessage: 'Tracking Code is required',
                              }),
                            },
                          ]}
                        >
                          <Input readOnly={readOnly} />
                        </Item>
                        <Item
                          name="trackingLink"
                          label={intl.formatMessage({
                            id: 'tracking.link',
                            defaultMessage: 'Tracking Link',
                          })}
                          rules={[
                            {
                              type: 'url',
                            },
                          ]}
                        >
                          <Input type="url" readOnly={readOnly} />
                        </Item>
                        <ButtonContainer>
                          <Button onClick={handleCancel}>
                            {readOnlyRef.current
                              ? intl.formatMessage({
                                  id: 'button.close',
                                  defaultMessage: 'Close',
                                })
                              : intl.formatMessage({
                                  id: 'button.cancel',
                                  defaultMessage: 'Cancel',
                                })}
                          </Button>
                          {!readOnlyRef.current && (
                            <Button
                              type="primary"
                              htmlType="submit"
                              disabled={readOnly}
                            >
                              {intl.formatMessage({
                                id: 'button.save',
                                defaultMessage: 'Save',
                              })}
                            </Button>
                          )}
                        </ButtonContainer>
                      </FormContainer>
                    );
                  }}
                </AppEntityForm>
              </TabPane>
            </Tabs>
          </Card>
        </Spin>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default ShippingDetails;
