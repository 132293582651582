import React, { CSSProperties, FC, useState } from 'react';
import { Style } from '@react-pdf/types';
import { IntlShape } from 'react-intl';
import { Contact } from '@apps/contacts/services';
import { Text, View } from '@react-pdf/renderer';
import ContactSearch from '@apps/purchases/mainMenu/components/ContactSearch';
import useOutsideClick from '@core/hooks/useOutsideClick';
import { Hoverable } from '@apps/purchases/mainMenu/components/styles.sc';

type Props = {
  style?: Style;
  intl: IntlShape;
  forPdf: boolean;
  contact?: Contact;
  disabled?: boolean;
};

const ContactDetails: FC<Props> = ({
  forPdf,
  style,
  contact,
  disabled = false,
}) => {
  const [editMode, setEditMode] = useState(false);
  const ref = useOutsideClick({
    callback: () => setEditMode(false),
  });

  const address = contact?.addresses
    ? contact.addresses.find((add) => add.standard)
    : undefined;

  if (forPdf) {
    return (
      <View style={style}>
        <Text>
          {contact?.type === 'person'
            ? `${contact.firstName || ''} ${contact.lastName || ''}`
            : `${contact?.companyName || ''}`}
        </Text>
        {address && (
          <Text>{`${address.street || ''}, ${address.number || ''}`}</Text>
        )}
        {address && (
          <Text>{`${address.zipcode || ''}, ${address.city || ''}`}</Text>
        )}
        {address && address.country && (
          <Text>{`${address.country || ''}`}</Text>
        )}
      </View>
    );
  }

  if (!contact || editMode) {
    return (
      <div ref={ref} style={style as CSSProperties}>
        <ContactSearch
          onContactSet={() => setEditMode(false)}
          disabled={disabled}
        />
      </div>
    );
  }

  return (
    <Hoverable style={style as CSSProperties}>
      <div onClick={() => !disabled && setEditMode(true)}>
        <div>
          {contact?.type === 'person'
            ? `${contact.firstName || ''} ${contact.lastName || ''}`
            : `${contact?.companyName || ''}`}
        </div>
        {address && (
          <div>
            <div>{`${address.street || ''}, ${address.number || ''}`}</div>
            <div>{`${address.zipcode || ''}, ${address.city || ''}`}</div>
            <div>{`${address.country || ''}`}</div>
          </div>
        )}
      </div>
    </Hoverable>
  );
};

export default ContactDetails;
