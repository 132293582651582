import { firebase } from '@services/Firebase';
import { LICENCES } from '@services/licences/namespace';

type Payload = {
  ownerId: string;
  ecosystem: string;
};

const removeLicencesForUserInEcosystem = async ({
  ownerId,
  ecosystem,
}: Payload) => {
  const toRemove = await firebase?.db
    ?.ref(`${LICENCES}/${ecosystem}`)
    .orderByChild('ownerId')
    .equalTo(ownerId)
    .once('value');

  if (!toRemove?.exists()) {
    return;
  }

  toRemove?.forEach((snapshot) => {
    const licence = snapshot.val() as Licence;
    if (licence.ownerId === ownerId) {
      snapshot.ref.remove();
    }
  });
};

export default removeLicencesForUserInEcosystem;
