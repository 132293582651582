import moment from 'moment';
import { put } from '@services/api';
import {
  Project,
  PROJECTS_COLLECTION_NAME,
} from '@apps/projects/services/namespace';
import { emitCustomEvent } from '@core/services';

const updateProject = (id: string, project: Project) =>
  put(`${PROJECTS_COLLECTION_NAME}/${id}`, {
    ...project,
    timestamp: moment().valueOf(),
  }).then(() => {
    emitCustomEvent('projectSubmitted', {
      id,
      project,
    });
  });

export default updateProject;
