import React, { FC } from 'react';
import { MenuProps as AntdMenuProps } from 'antd/lib/menu';
import { MenuStyle } from './Menu.sc';

export type MenuProps = AntdMenuProps;

export const Menu: FC<MenuProps> = ({ children, ...rest }) => {
  // @ts-ignore
  return <MenuStyle {...rest}>{children}</MenuStyle>;
};
