import React, { FC } from 'react';
import { Task } from '@apps/tasks/services';

type TreeNodeProps = {
  resource: Record<string, any>;
};

const TreeNode: FC<TreeNodeProps> = ({ resource }) => {
  const task = resource as Task;
  return <span>{task?.title || ''}</span>;
};

export default TreeNode;
