import React, { FC, useEffect, useState } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import { Spin, Typography, Avatar } from 'antd';
import { StyledDashboardCard } from '@pages/dashboard';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Contact, getContacts } from '@apps/contacts/services';
import { Icon } from '@todix/ui-components';
import HugeButton from '@components/HugeButton';
import WidgetCard from '@components/WidgetCard';
import ContactsAppConfig, { CONTACTS_ACTION } from '@apps/contacts';
import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';

const { Text } = Typography;

const DashboardWidget: FC = () => {
  const [spinning, setSpinning] = useState(true);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const activeEcosystems = useSelector(getActiveEcosystems);
  const intl = useIntl();

  useEffect(() => {
    if (activeEcosystems.length) {
      setSpinning(true);
      getContacts(
        {
          fields: [
            'id',
            'type',
            'ecosystem',
            'avatar',
            'firstName',
            'lastName',
            'companyName',
            'emails',
            'phones',
            'addresses',
          ],
          limit: 3,
        },
        'view-contacts' as CONTACTS_ACTION,
      )
        .then((response) => {
          setContacts(response.results as Contact[]);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setSpinning(false);
        });
    }
  }, [activeEcosystems.length, setContacts]);

  return (
    <StyledDashboardCard bordered={false}>
      <Spin spinning={spinning} style={{ height: '100%' }} />
      {contacts.length ? (
        <ReactCardCarousel autoplay spread="narrow" alignment="vertical">
          {contacts.map((contact, index) => (
            <WidgetCard
              key={index}
              title={
                <div>
                  <Avatar size="small" src={contact.avatar} />{' '}
                  <span>
                    {contact.type === 'person'
                      ? `${contact.firstName || ''} ${contact.lastName || ''}`
                      : `${contact.companyName || ''}`}
                  </span>
                </div>
              }
              bordered={false}
              extra={
                <Link to={`app/${ContactsAppConfig.todixId}`}>
                  {intl.formatMessage({
                    id: 'dashboard.contacts.link',
                    defaultMessage: 'Go to contacts',
                  })}
                </Link>
              }
            >
              {contact.emails && contact.emails?.length > 0 && (
                <div>
                  {intl.formatMessage({
                    id: 'detailcontactform.generalinformation.email.label',
                    defaultMessage: 'Email#',
                  })}
                  :{' '}
                  <Text strong>
                    {contact.emails?.map((entry) => entry.email).join(', ')}
                  </Text>
                </div>
              )}
              {contact.phones && contact.phones?.length > 0 && (
                <div>
                  {intl.formatMessage({
                    id: 'documentprocessing.step2.newcontact.phone.label',
                    defaultMessage: 'Phone#',
                  })}
                  :{' '}
                  <Text strong>
                    {contact.phones?.map((phone) => phone.number).join(', ')}
                  </Text>
                </div>
              )}
            </WidgetCard>
          ))}
        </ReactCardCarousel>
      ) : (
        !spinning && (
          <Link to={`app/${ContactsAppConfig.todixId}/create`}>
            <HugeButton type="primary" icon={<Icon.PlusOutlined />}>
              <FormattedMessage
                id="dashboard.empty.createcontact"
                defaultMessage="Create your first Contact now"
              />
            </HugeButton>
          </Link>
        )
      )}
    </StyledDashboardCard>
  );
};

export default DashboardWidget;
