import get from 'lodash.get';
import set from 'lodash.set';
import {
  post,
  get as apiGet,
  getWithId,
  deleteRequest,
  put,
  patch,
} from '@services/api';
import { emitCustomEvent } from '@core/services';
import {
  Consultant,
  CONSULTANTS_COLLECTION_NAME,
  GetConsultants,
} from './namespace';
import { ListEvents } from '@components/List';
import { getFilteredData } from '@services/filtering';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import moment from 'moment';
import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import getAnyRelations from '@services/entityRelations/getAnyRelations';
import removeRelation from '@services/entityRelations/removeRelation';
import appConfig from '@apps/directSales';

export * from './namespace';

export { default as getConsultantsWithStats } from './getConsultantsWithStats';

export type GetFilterParams = {
  query?: string;
  offset?: number;
  limit?: number;
  fields?: string[];
  filters?: object;
  sort?: string[];
};

type ApiResponse = {
  results: object[];
  info: {
    results: number;
  };
};

export type GetFilteredData = (params: GetFilterParams) => Promise<ApiResponse>;

// @ts-ignore
export const getConsultants: GetConsultants = (p) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    '',
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 1000,
    fields: ['id', 'ecosystem'],
    filters: {},
    sort: [],
    ...p,
  };
  // @ts-ignore
  return apiGet(CONSULTANTS_COLLECTION_NAME)?.then((data: Consultant[]) => {
    // filter, sort, limit (need info about all records)
    const filterEntries = Object.entries(params.filters);
    const shouldFilter = filterEntries.length;
    const shouldSort = !!params.sort.length;
    const filteredByEcosystems = data.filter((row) =>
      activeEcosystemsIds.includes(row.ecosystem as string),
    );
    const filteredByQuery = filteredByEcosystems.filter((row) =>
      params.fields.some((path) => {
        const fieldValue = get(row, path);
        if (typeof fieldValue !== 'string') {
          return false;
        }
        const queryLower = params.query.toLowerCase();
        return fieldValue.toLowerCase().includes(queryLower);
      }),
    );
    const filtered = shouldFilter
      ? getFilteredData(filteredByQuery, filterEntries)
      : filteredByQuery;
    const sorted = shouldSort
      ? [...filtered].sort((rowA, rowB) => {
          const sortKey = params.sort[0];
          const desc = sortKey.split('').includes('-');
          const path = sortKey
            .split('')
            .filter((c) => c !== '-')
            .join('');
          const valueA = get(rowA, path);
          const valueB = get(rowB, path);
          if (valueA === valueB) {
            return 0;
          }
          if (valueA < valueB) {
            return desc ? 1 : -1;
          } else {
            return desc ? -1 : 1;
          }
        })
      : filtered;
    const choosenFields = sorted.map((row) => {
      const newRow = {};
      params.fields.forEach((path: string): void => {
        const value = get(row, path);
        set(newRow, path, value);
      });
      return newRow;
    });
    const results = choosenFields.length;
    const page = choosenFields.slice(
      params.offset,
      params.offset + params.limit,
    );
    return {
      results: page,
      info: {
        results,
      },
    };
  });
};

export const getConsultant = (id: string) =>
  getWithId(`${CONSULTANTS_COLLECTION_NAME}/${id}`) as Promise<Consultant>;

export const updateConsultant = (id: string, consultant: Consultant) =>
  put(`${CONSULTANTS_COLLECTION_NAME}/${id}`, {
    ...consultant,
    timestamp: moment().valueOf(),
  });

export const patchConsultant = async (
  id: string,
  consultant: Partial<Consultant>,
) => {
  const response = await patch(`${CONSULTANTS_COLLECTION_NAME}/${id}`, {
    ...consultant,
    timestamp: moment().valueOf(),
  });
  // TODO: Have a common name for event that will refresh the list
  emitCustomEvent('refreshList');
  return response;
};

export const postConsultant = (consultant: Consultant) =>
  post(CONSULTANTS_COLLECTION_NAME, {
    ...consultant,
    timestamp: moment().valueOf(),
  });

export const deleteConsultant = (id: string) =>
  deleteRequest(`${CONSULTANTS_COLLECTION_NAME}/${id}`)?.then((response) => {
    emitCustomEvent<ListEvents>('refreshList');
    //search consultant as parent
    getAnyRelations({
      key: 'parentId',
      value: id,
    }).then((relations) => {
      relations.forEach((relation) => {
        removeRelation(relation.id as string);
      });
    });
    //search consultant as child
    getAnyRelations({
      key: 'childId',
      value: id,
    }).then((relations) => {
      relations.forEach((relation) => {
        removeRelation(relation.id as string);
      });
    });
    return response;
  });

export const getConsultantsByEcosystemId = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection(CONSULTANTS_COLLECTION_NAME)
    .where('ecosystem', '==', ecosystemId)
    .get()
    .then(getArrayWithIds) as Promise<Consultant[]>;
};

export const getConsultantByConsultantNo = (consultantNo: string) => {
  return firebase.firestore
    ?.collection(CONSULTANTS_COLLECTION_NAME)
    .where('consultantNo', '==', consultantNo)
    .get()
    .then(getArrayWithIds) as Promise<Consultant[]>;
};

export const getConsultantsByConsultantParent = (
  consultantParent: string,
  ecosystem: string,
) => {
  return firebase.firestore
    ?.collection(CONSULTANTS_COLLECTION_NAME)
    .where('consultantParent', '==', consultantParent)
    .where('ecosystem', '==', ecosystem)
    .get()
    .then(getArrayWithIds) as Promise<Consultant[]>;
};
