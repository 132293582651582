import React, { FC } from 'react';
import { ColumnMovedEvent, ColumnResizedEvent } from 'ag-grid-community';
import List, { ListProps } from '.';
import localStorage from '@services/local-storage';

const ListWithOrderSave: FC<ListProps> = ({
  path,
  onCellClickPath,
  onGridReady,
  ...props
}) => {
  const saveColumnsState = (e: ColumnMovedEvent | ColumnResizedEvent) => {
    const columnsState = e.columnApi.getColumnState();
    const orderState = columnsState.map(({ colId, width }) => ({
      colId,
      width,
    }));
    localStorage.set(path, orderState);
  };
  return (
    <List
      path={path}
      onCellClickPath={onCellClickPath}
      onColumnMoved={saveColumnsState}
      onColumnResized={saveColumnsState}
      onGridReady={(e) => {
        const columnsState = localStorage.get(path);
        if (columnsState) {
          e.columnApi.applyColumnState({
            state: columnsState,
            applyOrder: true,
          });
        }
        if (onGridReady) {
          onGridReady(e);
        }
      }}
      {...props}
    />
  );
};

export default ListWithOrderSave;
