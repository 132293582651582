import React, { FC, useRef, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import FirebaseApp from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { firebase } from '@core/services/Firebase';
import withGuest from '@core/hoc/withGuest';
import { setUser, fetchUser, updateUser, patchUser } from '@services/users';
import { useHistory } from 'react-router-dom';
import * as H from 'history';
import { generateKeywords } from '@core/helpers/keywords';
import { useDefaultPhotoUrls } from '@pages/sign/useDefaultPhotoUrls';
import { checkIfPhotoAvailable } from '@services/photos';
import { rootStore } from '@core/store';
import { SetUserAction } from '@core/store/user';
import { useTOSAndPP } from './useTOPAndPP';
import { useIntl } from 'react-intl';
import useScript from '@pages/sign/useScript';

export const convertUser = async (
  user: User,
  defaultPhotoUrls: string[],
  isPhotoAvailable: boolean,
) => {
  const photoURL =
    isPhotoAvailable && user.photoURL
      ? user.photoURL
      : defaultPhotoUrls[Math.floor(Math.random() * defaultPhotoUrls.length)];

  return {
    photoURL,
    displayName: user.displayName as string,
    email: user.email as string,
  };
};

const getUiConfig = (
  history: H.History,
  defaultPhotoUrls: string[],
  tos: string | null,
  pp: string | null,
): firebaseui.auth.Config => ({
  signInSuccessUrl: '/',
  signInOptions: [
    {
      provider: FirebaseApp.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: true,
    },
  ],
  privacyPolicyUrl: pp ? pp : '/privacy_policy',
  tosUrl: tos ? tos : '/tos',
  callbacks: {
    signInSuccessWithAuthResult(authResult): boolean {
      const user = authResult.user;
      fetchUser(user.uid)
        .then(async (fetchedUser) => {
          const isPhotoAvailable = await checkIfPhotoAvailable(user.photoURL);
          const convertedUser = await convertUser(
            user,
            defaultPhotoUrls,
            isPhotoAvailable,
          );
          const keywords = [
            ...new Set([
              ...generateKeywords(convertedUser.displayName as string),
              ...generateKeywords(convertedUser.email as string),
            ]),
          ];

          if (!isPhotoAvailable && user.photoURL) {
            await patchUser(user.uid, {
              photoURL: convertedUser.photoURL,
            });

            fetchedUser.photoURL = convertedUser.photoURL;

            rootStore.dispatch({
              type: 'SET_USER',
              payload: fetchedUser,
            } as SetUserAction);
          }

          if (!user?.emailVerified && !fetchedUser) {
            firebase.auth.currentUser?.sendEmailVerification().then(() => {
              setUser(user.uid, {
                ...convertedUser,
                emailVerified: false,
                verificationEmailSend: true,
                keywords,
              })?.then(() => {
                history.push('/');
              });
            });
          }
          if (
            fetchedUser &&
            fetchedUser.emailVerified !== user?.emailVerified
          ) {
            updateUser(user?.uid, {
              emailVerified: user?.emailVerified,
              email: convertedUser.email,
              displayName: convertedUser.displayName,
              photoURL: convertedUser.photoURL,
              keywords,
            }).then(() => {
              history.push('/');
            });
          }
          if (
            fetchedUser &&
            fetchedUser.email !== convertedUser.email &&
            user?.emailVerified
          ) {
            // need to update changes
            updateUser(user?.uid, {
              emailVerified: true,
              email: convertedUser.email,
              displayName: convertedUser.displayName,
              photoURL: convertedUser.photoURL,
              keywords,
            }).then(() => {
              history.push('/');
            });
          } else {
            history.push('/');
          }
        })
        .catch(() => {
          history.push('/');
        });

      return false;
    },
  },
});

const Container = styled('div')`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignProvider: FC = () => {
  const defaultPhotoUrls = useDefaultPhotoUrls();
  const [tos, pp] = useTOSAndPP();

  if (defaultPhotoUrls === null) {
    return null;
  }

  return <Sign defaultPhotoUrls={defaultPhotoUrls} tos={tos} pp={pp} />;
};

type SignProps = {
  defaultPhotoUrls: string[];
  tos: string | null;
  pp: string | null;
};

const FIREBASEUI_CONTAINER_ID = 'firebaseui_container';

const Sign: FC<SignProps> = ({ defaultPhotoUrls, tos, pp }) => {
  const container = useRef<HTMLDivElement>(null);
  const app = useRef<any>(null);
  const history = useHistory();
  const uiConfig = useMemo(
    () => getUiConfig(history, defaultPhotoUrls, tos, pp),
    [history, defaultPhotoUrls, tos, pp],
  );
  const intl = useIntl();
  const locale = intl.locale;
  const user = firebase.auth.currentUser;

  if (user) {
    history.push('/');
  }

  const state = useScript(
    `https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth__${
      locale || 'de'
    }.js`,
  );

  useEffect(() => {
    if (state.value === 'loading') return;
    if (state.value === 'error') throw state.error;
    (async () => {
      //if (app.current) await app.current.delete();
      if (container.current) {
        container.current.innerHTML = '';
      }
      let firebaseUI;
      try {
        firebaseUI =
          window.firebaseui.auth.AuthUI.getInstance() ||
          new window.firebaseui.auth.AuthUI(firebase.auth);
      } catch (e) {
        console.warn(e);
        firebaseUI = window.firebaseui.auth.AuthUI.getInstance();
      }
      if (firebaseUI && firebase && firebase.app) {
        firebaseUI.start(`#${FIREBASEUI_CONTAINER_ID}`, uiConfig);
        app.current = firebase.app;
      }
    })();
  }, [state.error, state.value, uiConfig]);

  return (
    <>
      <link
        type="text/css"
        rel="stylesheet"
        href="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css"
      />
      <Container ref={container} id={FIREBASEUI_CONTAINER_ID} />
    </>
  );
};

export default withGuest(SignProvider);
