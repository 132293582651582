import { connect } from 'react-redux';
import { compose } from 'redux';

import { CreateButtonPlatform } from './CreateButtonPlatform';
import { RootStore } from '@core/store';
import { getActiveEcosystemsApps } from '@core/layout/store/selectors';

const mapState = (state: RootStore) => ({
  applications: getActiveEcosystemsApps(state),
});

const mapDispatch = {};

export type CreateButtonContextAction = {
  app: string;
  link: string;
};

export type StateProps = ReturnType<typeof mapState>;

export type DispatchProps = typeof mapDispatch;

export default compose(connect(mapState, mapDispatch))(CreateButtonPlatform);
