import React from 'react';
import { IGetRowsParams, IFilterDef } from 'ag-grid-community';
import { IntlShape, useIntl } from 'react-intl';
import { message } from 'antd';
import {
  deleteDocument,
  Document,
  getDocumentsArchived,
} from '@apps/documents/service';
import AppView from '@core/components/AppView';
import SideContent from '@apps/documents/mainMenu/Archive/SideContent';
import getExtraOptions from './getExtraOptions';
import { layoutsMap } from '@apps/documents/service/layouts';
import { LayoutField } from '@apps/documents/service/namespace';
import columns from '@apps/documents/mainMenu/Archive/columns';
import { FilterTextObject } from '@services/filtering';
import optionsMapper from '@core/helpers/optionsMapper';
import DocumentAppConfig from '../../index';

export type GetRowsParams = IGetRowsParams & {
  ecosystems?: string[];
  query?: string;
  fields?: string[];
};

export const getRows = (params: GetRowsParams, intl?: IntlShape) => {
  const {
    fields = [
      'id',
      'receivingDate',
      'ecosystem',
      'type',
      'category',
      'tags',
      'uploadDate',
      'creationDate',
      'creatingUser',
      'uploadingUser',
      'origin',
      'relatedContact',
      'relatedContactSnapshot',
      'notes',
    ],
  } = params;

  const filtersEntries = Object.entries(params.filterModel).map(
    ([key, filterObj]) => [key, filterObj as IFilterDef],
  );
  if (!params.filterModel.ecosystem && params.ecosystems) {
    filtersEntries.push([
      'ecosystem',
      {
        filter: params.ecosystems.join('|'),
        filterType: 'text',
        type: 'contains',
      } as FilterTextObject,
    ]);
  }
  getDocumentsArchived(
    'view-documents',
    {
      query: params.query,
      offset: params.startRow,
      limit: params.endRow - params.startRow,
      fields,
      filters: Object.fromEntries(filtersEntries),
      sort: params.sortModel.map(
        (model: { colId: string; sort: string }) =>
          `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
      ),
    },
    intl,
  ).then((response) => {
    params.successCallback(response.results, response.info.results);
  });
};

const getGetRows =
  (fields: string[], intl: IntlShape) => (params: GetRowsParams) =>
    getRows({ fields, ...params }, intl);

const Archive = () => {
  const intl = useIntl();

  const layouts = { ...layoutsMap };
  const entries = Object.entries(layouts).map(([key, value]) => [
    key,
    value('detailedForm'),
  ]);

  const layoutsDetails = Object.fromEntries(entries);
  // @ts-ignore
  const fields = Object.values(layoutsDetails)
    // @ts-ignore
    .map((fields: any) =>
      fields.map((field: LayoutField) => ({
        fieldName: field.fieldName,
        type: field.type,
      })),
    )
    .flat();
  const fieldsUnique = fields.reduce(
    (items, item) =>
      items.find((x: any) => x.fieldName === item.fieldName)
        ? [...items]
        : [...items, item],
    [],
  );

  return (
    <AppView
      breadcrumbItems={[
        [
          `/app/${DocumentAppConfig.todixId}/archive`,
          intl.formatMessage({
            id: 'app.documents',
            defaultMessage: 'Documents',
          }),
        ],
      ]}
      columns={columns(fieldsUnique, intl)}
      getRows={getGetRows(
        [
          'id',
          'uploadingUser',
          'creatingUser',
          ...fieldsUnique.map((entry: any) => entry.fieldName),
        ],
        intl,
      )}
      sideContent={SideContent}
      path={`/app/${DocumentAppConfig.todixId}`}
      onCellClickPath={`/app/${DocumentAppConfig.todixId}`}
      tabs={['list']}
      onRemoveItem={(id) =>
        deleteDocument(id).catch(() => {
          message.error(
            intl.formatMessage({
              id: 'products.deletedocument.error',
              defaultMessage: 'Error while deleting document',
            }),
          );
        })
      }
      getExtraOptions={(item: Partial<Document>) => getExtraOptions(item, intl)}
      options={optionsMapper(['open', 'delete'], intl)}
      hideQuickFilter
      appConfig={DocumentAppConfig}
    />
  );
};

export default Archive;
