import { PROJECTS_COLLECTION_NAME } from '@apps/projects/services/namespace';
import { deleteRequest } from '@services/api';
import { emitCustomEvent } from '@core/services';
import { ListEvents } from '@components/List';
import getAnyRelations from '@services/entityRelations/getAnyRelations';
import removeRelation from '@services/entityRelations/removeRelation';

const deleteProject = (id: string) =>
  deleteRequest(`${PROJECTS_COLLECTION_NAME}/${id}`)?.then((response) => {
    emitCustomEvent<ListEvents>('refreshList');
    //search project as parent
    getAnyRelations({
      key: 'parentId',
      value: id,
    }).then((relations) => {
      relations.forEach((relation) => {
        removeRelation(relation.id as string);
      });
    });
    //search project as child
    getAnyRelations({
      key: 'childId',
      value: id,
    }).then((relations) => {
      relations.forEach((relation) => {
        removeRelation(relation.id as string);
      });
    });
    return response;
  });

export default deleteProject;
