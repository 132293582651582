import React, { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'antd';
import SelectedEcosystem from '@components/SelectedEcosystem';
import AppConfig from '@apps/directSales';
import InputField from '@components/InputField';
import SwitchField from '@components/SwitchField';
import ConsultantContext from '@apps/directSales/mainMenu/Consultants/components/formContext';
import MobileLicenseManager from '@apps/directSales/mainMenu/Consultants/components/MobileLicenceManager';

const { Item } = Form;

const RightColumn: FC = () => {
  const { readOnly, consultantId } = useContext(ConsultantContext);
  return (
    <div>
      <SelectedEcosystem
        disabled={readOnly || consultantId}
        appId={AppConfig.todixId}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          name: 'consultantParent',
          label: (
            <FormattedMessage
              id="consultants.columns.consultantParent.header"
              defaultMessage="Consultant Parent"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          name: 'consultantLevel',
          required: true,
          label: (
            <FormattedMessage
              id="consultants.columns.consultantLevel.label"
              defaultMessage="Consultant Level"
            />
          ),
        }}
      />
      <InputField
        disabled={readOnly}
        formItemProps={{
          name: 'consultantRank',
          required: true,
          label: (
            <FormattedMessage
              id="consultants.columns.consultantRank.header"
              defaultMessage="Consultant Rank"
            />
          ),
        }}
      />
      <Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const isActive = getFieldValue('isActive');
          return (
            <SwitchField
              disabled={readOnly}
              checked={isActive}
              formItemProps={{
                name: 'isActive',
                label: (
                  <FormattedMessage
                    id="consultants.columns.isActive.header"
                    defaultMessage="Consultant Active"
                  />
                ),
              }}
            />
          );
        }}
      </Item>
      <SwitchField
        disabled={readOnly || !consultantId}
        formItemProps={{
          name: 'validated',
          label: (
            <FormattedMessage
              id="consultants.columns.validated.header"
              defaultMessage="Consultant validated"
            />
          ),
        }}
      />
      <SwitchField
        disabled={readOnly}
        formItemProps={{
          name: 'careerplanConfirmed',
          label: (
            <FormattedMessage
              id="consultants.columns.careerplanConfirmed.header"
              defaultMessage="Careerplan confirmed"
            />
          ),
        }}
      />
      <SwitchField
        disabled={readOnly}
        formItemProps={{
          name: 'privacyProtectionConfirmed',
          label: (
            <FormattedMessage
              id="consultants.columns.PrivacyProtectionConfirmed.header"
              defaultMessage="Privacy Protection confirmed"
            />
          ),
        }}
      />
      <SwitchField
        disabled={readOnly}
        formItemProps={{
          name: 'agbConfirmed',
          label: (
            <FormattedMessage
              id="consultants.columns.agbConfirmed.header"
              defaultMessage="AGB Confirmed"
            />
          ),
        }}
      />
      <Item shouldUpdate noStyle>
        {({ getFieldsValue }) => {
          const consultant = getFieldsValue();
          return (
            <Item
              name="mobileLicense"
              label={
                <FormattedMessage
                  id="consultants.columns.license.header"
                  defaultMessage="Mobile license"
                />
              }
            >
              <MobileLicenseManager
                consultant={{ ...consultant, id: consultantId }}
              />
            </Item>
          );
        }}
      </Item>
    </div>
  );
};

export default RightColumn;
