import { Editor, Element as SlateElement } from 'slate';

const isBlockActive = (editor: any, format: any, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as Record<any, any>)[blockType] === format,
    }),
  );

  return !!match;
};

export default isBlockActive;
