import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;
const { TextArea } = Input;

type DescriptionProps = {
  readOnly: boolean;
};

const Description: FC<DescriptionProps> = ({ readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name="description"
      label={
        <FormattedMessage
          id="tasks.form.description.label"
          defaultMessage="Description"
        />
      }
      rules={[
        {
          type: 'string',
          max: 500,
          message: (
            <FormattedMessage
              id="tasks.form.description.error"
              defaultMessage="Description cannot be longer than 500 characters"
            />
          ),
        },
      ]}
    >
      <TextArea
        name="description"
        placeholder={intl.formatMessage({
          id: 'tasks.form.description.placeholder',
          defaultMessage: 'Maximum 500 characters...',
        })}
        readOnly={readOnly}
      />
    </Item>
  );
};

export default Description;
