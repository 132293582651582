import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const RangeOfNumbers = lazy(
  () =>
    import(
      /* webpackChunkName: "salesApp" */ '@apps/sales/settings/RangeOfNumbers'
    ),
);
const DeliveryTerms = lazy(
  () =>
    import(
      /* webpackChunkName: "salesApp" */ '@apps/sales/settings/DeliveryTerms'
    ),
);
const PaymentTerms = lazy(
  () =>
    import(
      /* webpackChunkName: "salesApp" */ '@apps/sales/settings/PaymentTerms'
    ),
);
const OrderTypes = lazy(
  () =>
    import(
      /* webpackChunkName: "salesApp" */ '@apps/sales/settings/OrderTypes'
    ),
);
const OperationSteps = lazy(
  () =>
    import(
      /* webpackChunkName: "salesApp" */ '@apps/sales/settings/OperationSteps'
    ),
);

export default {
  appSubmenuName: 'app.sales.mainMenu.name',
  icon: Icon.AuditOutlined,
  sections: [
    {
      link: 'rangeOfNumbers',
      title: 'settings.sales.groups.rangeOfNumbers.menuTitle',
      component: RangeOfNumbers,
    },
    {
      link: 'deliveryTerms',
      title: 'settings.sales.groups.deliveryTerms.menuTitle',
      component: DeliveryTerms,
    },
    {
      link: 'paymentTerms',
      title: 'settings.sales.groups.paymentTerms.menuTitle',
      component: PaymentTerms,
    },
    {
      link: 'orderTypes',
      title: 'settings.sales.groups.orderTypes.menuTitle',
      component: OrderTypes,
    },
    {
      link: 'operation_steps',
      title: 'sales.operation.steps.settings',
      component: OperationSteps,
    },
  ],
};
