import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Empty } from 'antd';
import { connect } from 'react-redux';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { RootStore } from '@core/store';
import SpinContainer from '@components/SpinContainer';
import {
  getDocumentsInbox,
  getAllInboxes,
  Document,
} from '@apps/documents/service';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';
import { EcosystemState } from '@components/EcosystemIndicator/store/reducers';
import ResourceFilter, {
  getFilteredResources,
} from '@components/AppView/ResourceFilter';
import { FilterWithInboxes } from '@apps/documents/mainMenu/Upload';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import _ from 'lodash';
import { StackedSortType } from '@components/AppView/index';
import MediaViewer from '@components/MediaViewer';
import ImageGallery from 'react-image-gallery';
import ImageWrapper from '@components/AppView/ImageWrapper';

export type OwnStackedProps = {
  selectedRow: string | null;
  setSelectedRow: (id: string | null) => void;
  handleNavigation(cb: () => void): void;
  headerColRef?: any;
  docsToProcessing?: Partial<Document>[];
  showNavigation: boolean;
};

export type StackedProps = OwnStackedProps & {
  activeEcosystems: EcosystemState;
  stackedSort: StackedSortType;
};

export type StackedItemsLoadedEventPayload = {
  items: number;
};

const Stacked: FC<StackedProps> = ({
  activeEcosystems,
  selectedRow,
  setSelectedRow,
  handleNavigation,
  headerColRef,
  stackedSort,
  docsToProcessing,
  showNavigation,
}) => {
  const [loading, setLoading] = useState(true);
  const [startIndex, setStartIndex] = useState(0);
  //TODO: staff related with resources should be pass to this component - each app works with different resources
  const [resources, setResources] = useState<any>([]);
  const [filters, setFilters] = useState<FilterWithInboxes[]>([]);
  const [resourcesFiltered, setResourcesFiltered] = useState<any>([]);
  const intl = useIntl();
  const carouselRef = useRef<ImageGallery | null>(null);

  useEffect(() => {
    if (resourcesFiltered) {
      emitCustomEvent('stackedItemsLoaded', {
        items: resourcesFiltered.length,
      });
    }
  }, [resourcesFiltered]);

  useEffect(() => {
    getAllInboxes('update-documents').then((inboxes) => {
      const initialFilters = activeEcosystems.map((eco) => ({
        id: eco.id,
        name: eco.name,
        avatarUrl: eco.avatarUrl,
        inboxes: inboxes
          .filter((inbox) => inbox.ecosystem === eco.id)
          .map((inbox) => ({
            id: inbox.id,
            name: inbox.name,
            avatarUrl: eco.avatarUrl,
            disabled: !inbox.isActive,
            inFilter: inbox.isActive,
          })),
        inFilter: true,
      }));

      setFilters(initialFilters);
    });
  }, [activeEcosystems, intl]);

  useEffect(() => {
    if (resources.length === 0) {
      return;
    }

    setFilters(
      filters.map((filter) => {
        const inboxes = filter.inboxes.map((inbox) => ({
          ...inbox,
          amount: resources.reduce((acc: number, res: any) => {
            if (res.inbox === inbox.id) {
              acc += 1;
            }
            return acc;
          }, 0),
        }));
        return {
          ...filter,
          inboxes,
          amount: inboxes.reduce((acc, inbox) => (acc += inbox.amount), 0),
        };
      }),
    );
  }, [resources]);

  const getDocsFromInbox = useCallback(() => {
    const processingDocs = (results: Document[]) => {
      results = _.orderBy(results, ['timestamp'], [stackedSort]);
      const newResourceFiltered = results.filter((r: any) =>
        activeEcosystems.map((e: any) => e.id).includes(r.ecosystem),
      );

      const resources =
        results[0] &&
        newResourceFiltered &&
        newResourceFiltered.filter((el: any) => el.id === selectedRow);

      if (
        (selectedRow === null && results[0]) ||
        (resources && resources.length === 0)
      ) {
        const { id } = results[0];
        setSelectedRow(id as string);
      } else if (newResourceFiltered) {
        setSelectedRow(null);
      }

      const resourcesAfterFiltersApplied = getFilteredResources(
        filters,
        newResourceFiltered,
        activeEcosystems,
      );

      setResources(results);
      setLoading(false);
      setResourcesFiltered(resourcesAfterFiltersApplied);

      // to hide sideview with unknown data
      if (resourcesAfterFiltersApplied.length === 0) {
        setSelectedRow(null);
      }
    };

    if (docsToProcessing) {
      let results = docsToProcessing;
      results = _.orderBy(results, ['timestamp'], [stackedSort]);
      const newResourceFiltered = results.filter((r: any) =>
        activeEcosystems.map((e: any) => e.id).includes(r.ecosystem),
      );

      const resources =
        results[0] &&
        newResourceFiltered &&
        newResourceFiltered.filter((el: any) => el.id === selectedRow);

      if (
        (selectedRow === null && results[0]) ||
        (resources && resources.length === 0)
      ) {
        const { id } = results[0];
        setSelectedRow(id as string);
      } else if (newResourceFiltered) {
        setSelectedRow(null);
      }

      setResources(results);
      setLoading(false);
      setResourcesFiltered(newResourceFiltered);
    } else {
      getDocumentsInbox('view-documents').then(({ results }) => {
        processingDocs(results);
      });
    }
  }, [
    docsToProcessing,
    stackedSort,
    selectedRow,
    filters,
    activeEcosystems,
    setSelectedRow,
  ]);

  useEffect(() => {
    if (resources[0] && selectedRow === null) {
      const { id } = resources[0];
      setSelectedRow(id as string);
    }
  }, [stackedSort, resources, selectedRow, setSelectedRow]);

  useEffect(() => {
    setLoading(true);
  }, [activeEcosystems, stackedSort]);

  useEffect(() => {
    if (loading) {
      getDocsFromInbox();
    }
  }, [
    activeEcosystems,
    stackedSort,
    getDocsFromInbox,
    loading,
    selectedRow,
    setSelectedRow,
  ]);

  const items = useMemo(() => {
    return resourcesFiltered.map((res: Partial<Document>) => ({
      url: res.documentUrl,
      thumbnailUrl:
        res?.fileDetails?.format === 'pdf'
          ? res.thumbnailUrl || 'placeholder'
          : res.documentUrl,
      format: res?.fileDetails?.format || '',
    }));
  }, [resourcesFiltered]);

  useCustomEventListener<string, string>('jumpToNextDoc', (id) => {
    if (id && resourcesFiltered) {
      const res = resourcesFiltered as any[];
      const lastIndex = res.length - 1;
      const foundIndex = res.findIndex((r) => r.id === id);
      const index = foundIndex >= lastIndex ? 0 : foundIndex + 1;
      const newId = resourcesFiltered[index].id;
      setSelectedRow(newId);
      setStartIndex(index);
    }
  });

  // to keep filter visible
  if (loading && resources.length) {
    return (
      <>
        {headerColRef.current && (
          <ResourceFilter
            element={headerColRef.current}
            activeEcosystems={activeEcosystems}
            filters={filters}
            resources={resources}
            selectedRow={selectedRow}
            onFiltersChange={setFilters}
            setSelectedRow={setSelectedRow}
            onResourcesChange={setResourcesFiltered}
          />
        )}
        <SpinContainer />
      </>
    );
  } else if (loading) {
    return <SpinContainer />;
  }

  if ((!loading && !resources.length) || !selectedRow) {
    return (
      <>
        <ResourceFilter
          element={headerColRef.current}
          activeEcosystems={activeEcosystems}
          filters={filters}
          resources={resources}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          onFiltersChange={setFilters}
          onResourcesChange={setResourcesFiltered}
        />
        <Empty
          description={
            <FormattedHTMLMessage
              id="documents.noitems"
              defaultMessage="No documents available"
            />
          }
        />
      </>
    );
  }

  return (
    <>
      {!loading && (
        <>
          {(headerColRef.current ||
            (resources.length && !resourcesFiltered.length)) && (
            <ResourceFilter
              element={headerColRef.current}
              activeEcosystems={activeEcosystems}
              filters={filters}
              resources={resources}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              onFiltersChange={setFilters}
              onResourcesChange={setResourcesFiltered}
            />
          )}
          <MediaViewer
            carouselRef={carouselRef}
            startIndex={startIndex}
            items={items}
            width={'100vw'}
            height={'100vh'}
            showPlayButton={false}
            showNav={showNavigation}
            thumbnailPosition="left"
            onLeftClick={handleNavigation}
            onRightClick={handleNavigation}
            onThumbnailClickCb={handleNavigation}
            onSlide={(index) => {
              const id = resourcesFiltered[index].id;
              setSelectedRow(id);
              setStartIndex(index);
            }}
            imageRenderItem={(el) => <ImageWrapper element={el} />}
          />
        </>
      )}
    </>
  );
};

const mapState = (state: RootStore) => ({
  activeEcosystems: getActiveEcosystems(state),
});

export default connect(mapState)(Stacked);
