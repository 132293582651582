import React, { FC } from 'react';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';
import { firebase } from '@services/Firebase';
import { useSelector } from 'react-redux';
import { getOwnerEcosystem } from '@components/EcosystemIndicator/store';

const trustPages = ['account', 'create'];

const RouteForOwner: FC<RouteProps> = ({ component, ...rest }) => {
  const location = useLocation();
  const pathParts = location.pathname.split('/');
  const user = firebase.auth.currentUser;
  const ownerEcosystems = useSelector(getOwnerEcosystem(user?.uid as string));
  const canSee =
    trustPages.includes(pathParts[2]) ||
    ownerEcosystems.find((eco) => eco.id === pathParts[2]);
  const Component = component as React.ComponentType<RouteProps>;

  return (
    <Route
      {...rest}
      render={(props) =>
        canSee ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default RouteForOwner;
