import * as React from 'react';
import { Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';

const PopoverWithFullScreenMode: React.FC<PopoverProps> = (props) => (
  <Popover
    getPopupContainer={() => {
      const mainElement = document
        .getElementsByClassName('fullscreen')
        .item(0) as HTMLElement;
      return mainElement || document.body;
    }}
    {...props}
  />
);

export default PopoverWithFullScreenMode;
