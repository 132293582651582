import { jsPDF } from 'jspdf';
import { TemplateProps } from '@apps/sales/mainMenu/components/pdfTemplates/namespace';

const addContact = (
  doc: jsPDF,
  props: TemplateProps,
  lastYPosition: number,
): number => {
  const { values } = props;
  const { relatedContactSnapshot: contact } = values;
  let newLastYPosition = lastYPosition;

  const address = contact?.addresses
    ? contact.addresses.find((add) => add.standard)
    : undefined;

  if (contact) {
    const contactName =
      contact?.type === 'person'
        ? `${contact.firstName || ''} ${contact.lastName || ''}`
        : `${contact?.companyName || ''}`;
    doc.setFont('times', 'normal', 'normal');
    doc.setFontSize(12);
    doc.text(contactName, 20, newLastYPosition);
    const streetNumber = address
      ? `${address.street || ''}, ${address.number || ''}`
      : '';
    if (streetNumber) {
      newLastYPosition = newLastYPosition + 5;
      doc.text(streetNumber, 20, newLastYPosition);
    }
    const zipCodeCity = address
      ? `${address.zipcode || ''}, ${address.city || ''}`
      : '';
    if (zipCodeCity) {
      newLastYPosition = newLastYPosition + 5;
      doc.text(zipCodeCity, 20, newLastYPosition);
    }
    if (address && address.country) {
      newLastYPosition = newLastYPosition + 5;
      doc.text(address.country, 20, newLastYPosition);
    }
  }

  return newLastYPosition;
};

export default addContact;
