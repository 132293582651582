import { GridApi } from 'ag-grid-community';
import { useIntl } from 'react-intl';
import React, { useCallback } from 'react';
import {
  ActionRenderer,
  ActionRendererProps,
} from '@components/AppView/ActionRenderer';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

export type ListActionRendererProps = {
  data: {
    id: string;
    isActive?: boolean;
    [key: string]: any;
  };
  api: GridApi;
  context?: any;
};

const ListActionRenderer = (props: ListActionRendererProps) => {
  const intl = useIntl();
  const { context } = props;
  const { getExtraOptions, onRemoveItem, options, appConfig } = context;
  const passedOptions: ActionRendererProps['options'] = options || [
    'open',
    'edit',
    'delete',
  ];
  const ecosystemId = props.data?.ecosystem || props.data?.ecosystemId || '';
  let filteredOptions = passedOptions;
  let canEdit = false;
  let canDelete = false;
  if (appConfig && ecosystemId) {
    const editAction = appConfig.actions.filter((action: string) =>
      action.includes('update'),
    )[0];
    const deleteAction = appConfig.actions.filter((action: string) =>
      action.includes('delete'),
    )[0];
    canEdit = checkUserPermissions(
      rootStore.getState(),
      appConfig.todixId,
      editAction || '',
      ecosystemId,
    );
    if (!canEdit) {
      filteredOptions = passedOptions.filter((el) => el.action !== 'edit');
    }
    canDelete = checkUserPermissions(
      rootStore.getState(),
      appConfig.todixId,
      deleteAction || '',
      ecosystemId,
    );
    if (!canDelete) {
      filteredOptions = passedOptions.filter((el) => el.action !== 'delete');
    }
  }

  const refreshList = useCallback(() => {
    props.api.deselectAll();
    props.api.purgeInfiniteCache();
  }, []);

  if (!props.data) {
    return '';
  }

  return (
    <ActionRenderer
      path={`${context.path}/${props.data.id}`}
      options={filteredOptions}
      extraOptions={getExtraOptions && getExtraOptions(props.data, refreshList)}
      handleDelete={() => {
        try {
          onRemoveItem(props.data.id)?.then(() => {
            props.api.deselectAll();
            props.api.purgeInfiniteCache();
          });
        } catch (e) {
          console.error(e);
          props.api.deselectAll();
          props.api.purgeInfiniteCache();
        }
      }}
      intl={intl}
      ecosystemId={ecosystemId}
      appConfig={appConfig}
    />
  );
};

export default ListActionRenderer;
