import { jsPDF } from 'jspdf';
import { TemplateProps } from '@apps/sales/mainMenu/components/pdfTemplates/namespace';
import { SaleOperationSettings } from '@apps/sales/services/saleOperation';

const addFooter = (
  doc: jsPDF,
  props: TemplateProps,
  lastYPosition: number,
  user: User,
  saleOperationSettings?: SaleOperationSettings,
) => {
  const { ecosystem, intl } = props;
  let newLastYPosition = lastYPosition;
  if (ecosystem) {
    doc.setFont('times', 'normal', 'normal');
    doc.setFontSize(8);
    let xPosition = 5;
    if (saleOperationSettings && saleOperationSettings.footer.content) {
      saleOperationSettings.footer.content.forEach((part) => {
        part.split('\n').forEach((element) => {
          doc.text(element, xPosition, newLastYPosition, { maxWidth: 40 });
          newLastYPosition = newLastYPosition + 3;
        });
        newLastYPosition = lastYPosition;
        xPosition = xPosition + 50;
      });
      return;
    } else if (ecosystem.address) {
      const { name } = ecosystem;
      const { street, number, country, zipcode, city } = ecosystem.address;
      if (street && number !== undefined) {
        if (name) {
          doc.text(name, xPosition, newLastYPosition);
        }
        if (street && number) {
          newLastYPosition = newLastYPosition + 3;
          doc.text(`${street}, ${number}`, xPosition, newLastYPosition);
        }
        if (zipcode && city) {
          newLastYPosition = newLastYPosition + 3;
          doc.text(`${zipcode}, ${city}`, xPosition, newLastYPosition);
        }
        if (country) {
          newLastYPosition = newLastYPosition + 3;
          doc.text(country, xPosition, newLastYPosition);
        }
      }
    }
    newLastYPosition = lastYPosition;
    xPosition = 60;
    const { displayName, email } = user;
    doc.text(
      intl.formatMessage({
        id: 'sales.managing.director',
        defaultMessage: 'Managing director',
      }),
      xPosition,
      newLastYPosition,
    );
    newLastYPosition = newLastYPosition + 3;
    doc.text(displayName, xPosition, newLastYPosition);
    newLastYPosition = newLastYPosition + 3;
    doc.text(email, xPosition, newLastYPosition);
    if (ecosystem.bankAccount) {
      newLastYPosition = lastYPosition;
      xPosition = 120;
      const { bankName, iban, swift } = ecosystem.bankAccount;
      doc.text(bankName, xPosition, newLastYPosition);
      newLastYPosition = newLastYPosition + 3;
      doc.text(`IBAN ${iban}`, xPosition, newLastYPosition);
      newLastYPosition = newLastYPosition + 3;
      doc.text(`SWIFT ${swift}`, xPosition, newLastYPosition);
    }
  }
};

export default addFooter;
