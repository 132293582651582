import React, { useState, useEffect } from 'react';
import { deleteDocument, Document, getDocument } from '@apps/documents/service';
import { Row, Col, message } from 'antd';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import SpinContainer from '@components/SpinContainer';
import DocumentProcessing from '@apps/documents/mainMenu/Inbox/DocumentProcessing';
import ProcessingHeader from '@apps/documents/mainMenu/Inbox/ProcessingHeader';
import Download from '@components/Download';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl/lib';
import { emitCustomEvent } from '@core/services';
import optionsMapper from '@core/helpers/optionsMapper';
import DocumentAppConfig, { DOCUMENTS_ACTION } from '@apps/documents';
import DocumentInfoPopup from '@apps/documents/mainMenu/Inbox/DocumentInfoPopup';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

type SideContentProps = {
  id: string;
};

const SideContent: React.FC<SideContentProps> = ({ id }) => {
  const [details, setDetails] = useState<Document | null>(null);
  const intl = useIntl();
  useEffect(() => {
    setDetails(null);
    // @ts-ignore
    getDocument(id)?.then((response) => setDetails(response as Document));
  }, [id]);

  const action = 'delete-documents' as DOCUMENTS_ACTION;
  const canDelete = details
    ? checkUserPermissions(
        rootStore.getState(),
        DocumentAppConfig.todixId,
        action,
        details.ecosystem as string,
      )
    : false;

  if (details === null) {
    return <SpinContainer />;
  }

  return (
    <>
      {/* after antd update to 4.8 it can be replaced with wrap={false} */}
      <Row
        style={{
          flexWrap: 'nowrap',
        }}
      >
        <Col flex="auto">
          <ProcessingHeader document={details} showExtraDetails={false} />
        </Col>
        <Col flex="24x">
          <ActionRenderer
            path={`/app/${DocumentAppConfig.todixId}/${id}`}
            options={canDelete ? optionsMapper(['delete'], intl) : []}
            handleDelete={() => {
              if (canDelete) {
                deleteDocument(id)
                  ?.then(() => {
                    emitCustomEvent('documentSaved');
                  })
                  .catch(() => {
                    message.error(
                      intl.formatMessage({
                        id: 'products.deletedocument.error',
                        defaultMessage: 'Error while deleting document',
                      }),
                    );
                  });
              }
            }}
            extraOptions={[
              {
                children: (
                  <Download
                    url={details.documentUrl}
                    filename={`${details.id}.${details.fileDetails.format}`}
                  >
                    <FormattedMessage
                      id="documents.inbox.sidecontent.download"
                      defaultMessage="download"
                    />
                  </Download>
                ),
              },
              {
                children: (
                  <DocumentInfoPopup document={details}>
                    <a href="">
                      <FormattedMessage
                        id="documents.inbox.sidecontent.upload.details"
                        defaultMessage="upload details"
                      />
                    </a>
                  </DocumentInfoPopup>
                ),
              },
            ]}
            intl={intl}
          />
        </Col>
      </Row>
      <DocumentProcessing document={details} id={id} />
    </>
  );
};

export default SideContent;
