import React, { FC } from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

type AddCardLinkProps = {
  onClick(props: any): void;
  canProceed: boolean;
};

const AddCardLink: FC<AddCardLinkProps> = ({ onClick, canProceed }) => {
  return (
    <Button
      onClick={(props) => canProceed && onClick(props)}
      disabled={!canProceed}
    >
      <FormattedMessage
        id="app.weekplan.addCardLink"
        defaultMessage="Add new task"
      />
    </Button>
  );
};

export default AddCardLink;
