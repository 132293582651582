export enum PermissionActionTypes {
  INIT_PERMISSION = 'INIT_PERMISSION',
  UPDATE_PERMISSION = 'UPDATE_PERMISSION',
  DELETE_PERMISSION = 'DELETE_PERMISSION',
}

type InitPermissionPayload = {
  ecosystemId: string;
  permissions: Record<string, Record<string, string>>;
};

export const initPermission = (payload: InitPermissionPayload) =>
  ({
    type: PermissionActionTypes.INIT_PERMISSION,
    payload,
  } as const);

export type InitPermissionAction = ReturnType<typeof initPermission>;

type UpdatePermissionPayload = {
  ecosystemId: string;
  permissions: Record<string, Record<string, string>>;
};

export const updatePermission = (payload: UpdatePermissionPayload) =>
  ({
    type: PermissionActionTypes.UPDATE_PERMISSION,
    payload,
  } as const);

export type UpdatePermissionAction = ReturnType<typeof updatePermission>;

type DeletePermissionPayload = {
  ecosystemId: string;
  appId: string;
};

export const deletePermission = (payload: DeletePermissionPayload) =>
  ({
    type: PermissionActionTypes.DELETE_PERMISSION,
    payload,
  } as const);

export type DeletePermissionAction = ReturnType<typeof deletePermission>;

export type PermissionsAction =
  | UpdatePermissionAction
  | DeletePermissionAction
  | InitPermissionAction;
