import currency from 'currency.js';
import { currencySigns } from '@services/currency';

type WithSuffixPayload = {
  value: number | string;
  suffix?: string;
};
const withSuffix = ({ value, suffix }: WithSuffixPayload) => {
  try {
    if (!Number.isNaN(value)) {
      if (!suffix) {
        const symbol = currencySigns['EUR'];
        const price = currency(value, {
          separator: '.',
          decimal: ',',
          precision: 2,
          pattern: '!#',
          symbol,
        }).format();
        return price;
      }
      if (typeof value == 'number') {
        return `${value.toFixed(2)}${suffix}`;
      } else {
        return `${value}${suffix}`;
      }
    }
    return `${value}${suffix}`;
  } catch (e) {
    console.error(e);
    return `${value}`;
  }
};

export default withSuffix;
