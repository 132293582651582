import React, { FC, useState } from 'react';
import { Form, Space, Input, Select, Collapse } from 'antd';
import { Radio, RadioGroup } from '@components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import {
  getCountries,
  getLanguages,
  getCompanyTypes,
  getRegisterCourt,
} from '@services/dictionary';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectWithSearch from '@components/SelectWithSearch';
import { ProfileOutlined } from '@ant-design/icons';
import { useCustomEventListener } from '@core/services';
import {
  ON_FINISH_FAILED_EVENT,
  OnFinishFailedEvent,
} from '@components/AppEntityForm/AppEntityForm';
import Warning from '@apps/contacts/mainMenu/components/DetailContactForm/Warning';

const countries = getCountries();
const langs = getLanguages();
const { Panel } = Collapse;

const fieldNames = [
  'registrationCountry',
  'contactLanguage',
  'companyType',
  'registerCourt',
  'crn',
  'taxNumber',
  'vatNumber',
  'vatStatus',
  'owner',
  'url',
];

const CompanyDetails: FC = () => {
  const { getFieldValue } = useFormContext();
  const [vatStatus, setVatStatus] = useState(getFieldValue('vatStatus'));
  const [hasError, setHasError] = useState(false);
  const intl = useIntl();

  useCustomEventListener<'onFinishFailed', OnFinishFailedEvent>(
    ON_FINISH_FAILED_EVENT,
    (data) => {
      if (data) {
        const error =
          data.errorInfo.errorFields.filter(
            (err) =>
              fieldNames.filter((name) => err.name.includes(name)).length > 0,
          ).length > 0;
        if (error) {
          setHasError(true);
        } else {
          setHasError(false);
        }
      }
    },
  );

  return (
    <Collapse
      expandIcon={() =>
        hasError ? (
          <div>
            <Warning />
            <ProfileOutlined />
          </div>
        ) : (
          <ProfileOutlined />
        )
      }
    >
      <Panel
        header={
          <FormattedMessage
            id="detailcontactform.companydetails.title"
            defaultMessage="Company Details"
          />
        }
        key="1"
      >
        <Space direction="vertical" style={{ display: 'flex', flex: 1 }}>
          <Form.Item
            name="registrationCountry"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.registrationcountry.label"
                defaultMessage="Country of Registration"
              />
            }
            required={false}
            rules={[
              {
                required: false,
                message: intl.formatMessage({
                  id: 'detailcontactform.companydetails.registrationcountry.error',
                  defaultMessage: 'Missing country of registration',
                }),
              },
            ]}
          >
            <SelectWithSearch
              aria-required="true"
              placeholder={
                <FormattedMessage
                  id="detailcontactform.companydetails.registrationcountry.placeholder"
                  defaultMessage="Select country of registration"
                />
              }
              loading={!countries.length}
              options={countries.map((entry) => ({
                label: entry.countryName,
                value: entry.countryName,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="contactLanguage"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.contactlanguage.label"
                defaultMessage="Language of Contact"
              />
            }
            required={false}
            rules={[
              {
                required: false,
                message: intl.formatMessage({
                  id: 'detailcontactform.companydetails.contactlanguage.error',
                  defaultMessage: 'Missing language of contact',
                }),
              },
            ]}
          >
            <SelectWithSearch
              placeholder={
                <FormattedMessage
                  id="detailcontactform.companydetails.contactlanguage.placeholder"
                  defaultMessage="Select language of contact"
                />
              }
              aria-required="true"
              loading={!langs.length}
              options={langs.map((entry) => ({
                label: entry,
                value: entry,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="companyType"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.companytype.label"
                defaultMessage="Company type"
              />
            }
            required={false}
            rules={[
              {
                required: false,
                message: intl.formatMessage({
                  id: 'detailcontactform.companydetails.companytype.error',
                  defaultMessage: 'Missing company type',
                }),
              },
            ]}
          >
            <Select
              placeholder={
                <FormattedMessage
                  id="detailcontactform.companydetails.companytype.placeholder"
                  defaultMessage="Select company type"
                />
              }
              aria-required="true"
              options={getCompanyTypes().map((entry) => ({
                label: entry,
                value: entry,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="registerCourt"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.registercourt.label"
                defaultMessage="Register court"
              />
            }
            required={false}
            rules={[
              {
                required: false,
                message: intl.formatMessage({
                  id: 'detailcontactform.companydetails.registercourt.error',
                  defaultMessage: 'Missing register court',
                }),
              },
            ]}
          >
            <Select
              placeholder={
                <FormattedMessage
                  id="detailcontactform.companydetails.registercourt.placeholder"
                  defaultMessage="Select register court"
                />
              }
              aria-required="true"
              options={getRegisterCourt().map((entry) => ({
                label: entry,
                value: entry,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="crn"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.crn.label"
                defaultMessage="CRN"
              />
            }
            required={false}
            rules={[
              {
                required: false,
                message: intl.formatMessage({
                  id: 'detailcontactform.companydetails.crn.error',
                  defaultMessage: 'Missing CRN',
                }),
              },
            ]}
          >
            <Input
              name="crn"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.companydetails.crn.placeholder',
                defaultMessage: 'Type commercial register number (CRN) here...',
              })}
              autoComplete="nope"
            />
          </Form.Item>
          <Form.Item
            name="taxNumber"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.tax.label"
                defaultMessage="Tax number"
              />
            }
            required={false}
            rules={[
              {
                required: false,
                message: intl.formatMessage({
                  id: 'detailcontactform.companydetails.tax.error',
                  defaultMessage: 'Missing tax number',
                }),
              },
            ]}
          >
            <Input
              name="taxNumber"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.companydetails.tax.placeholder',
                defaultMessage:
                  'Type tax number here i.e. DE 561885965 here...',
              })}
              autoComplete="nope"
            />
          </Form.Item>
          {vatStatus === 'vat' && (
            <Form.Item
              name="vatNumber"
              label={
                <FormattedMessage
                  id="detailcontactform.companydetails.vatnumber.label"
                  defaultMessage="VAT number"
                />
              }
              required={false}
              rules={[
                {
                  required: false,
                  message: intl.formatMessage({
                    id: 'detailcontactform.companydetails.vatnumber.error',
                    defaultMessage: 'Missing VAT number',
                  }),
                },
              ]}
            >
              <Input
                name="vatNumber"
                placeholder={intl.formatMessage({
                  id: 'detailcontactform.companydetails.vatnumber.placeholder',
                  defaultMessage:
                    'Type tax number here i.e. 561/5526/9986 here...',
                })}
                autoComplete="nope"
              />
            </Form.Item>
          )}
          <Form.Item
            name="vatStatus"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.vatstatus.label"
                defaultMessage="VAT status"
              />
            }
          >
            <RadioGroup
              name="vatStatus"
              value={vatStatus}
              onChange={(e) => {
                setVatStatus(e.target.value);
              }}
            >
              <Radio
                name="vatStatus"
                value="vat"
                inGroup
                label={
                  <span>
                    <FormattedMessage
                      id="detailcontactform.companydetails.vatstatus.vat"
                      defaultMessage="VAT"
                    />
                  </span>
                }
              />
              <Radio
                name="vatStatus"
                value="noVat"
                inGroup
                label={
                  <span>
                    <FormattedMessage
                      id="detailcontactform.companydetails.vatstatus.novat"
                      defaultMessage="no VAT"
                    />
                  </span>
                }
              />
            </RadioGroup>
          </Form.Item>
          <Form.Item
            name="owner"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.owner.label"
                defaultMessage="CEO / owner"
              />
            }
            required={false}
            rules={[
              {
                required: false,
                message: intl.formatMessage({
                  id: 'detailcontactform.companydetails.owner.error.required',
                  defaultMessage: 'Missing owner',
                }),
              },
              {
                type: 'string',
                max: 40,
                message: intl.formatMessage({
                  id: 'detailcontactform.companydetails.owner.error.max',
                  defaultMessage: 'Owner cannot be longer than 40 characters',
                }),
              },
            ]}
          >
            <Input
              name="owner"
              type="search"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.companydetails.owner.placeholder',
                defaultMessage: 'Find in Contacts...',
              })}
              autoComplete="nope"
            />
          </Form.Item>
          <Form.Item
            name="url"
            label={
              <FormattedMessage
                id="detailcontactform.companydetails.url.label"
                defaultMessage="Url"
              />
            }
          >
            <Input
              name="url"
              type="search"
              placeholder={intl.formatMessage({
                id: 'detailcontactform.companydetails.url.placeholder',
                defaultMessage: 'Type url here...',
              })}
              autoComplete="nope"
            />
          </Form.Item>
        </Space>
      </Panel>
    </Collapse>
  );
};

export default CompanyDetails;
