import {
  WeekPlanEntry,
  WEEK_PLAN_COLLECTION_NAME,
} from '@apps/weekplan/services/namespace';
import { getWithId } from '@services/api';

const getWeekPlanById = (id: string) => {
  return getWithId(
    `${WEEK_PLAN_COLLECTION_NAME}/${id}`,
  ) as Promise<WeekPlanEntry>;
};

export default getWeekPlanById;
