import { IUserCommission, isUserCommission } from './isUserCommission';
import { toArray } from './toArray';
import moment from 'moment/moment';

export const parseToCommissions = (
  fileContent: string,
  ecosystemId: string,
) => {
  const parsedData = toArray(JSON.parse(fileContent));

  const commissions: Partial<CommissionEntry>[] = [];
  for (const [index, data] of parsedData.entries()) {
    if (!isUserCommission(data)) {
      throw new Error(`Unexpected JSON file schema at position: ${index + 1}`);
    }

    commissions.push(buildCommission(data, ecosystemId));
  }

  return commissions;
};

export interface CommissionEntry {
  id?: string;
  ecosystem: string;
  period: string;
  timestamp: number;
  sellerId: string;
  sellerPeriodToken: string;
  saleId: string;
  contactSnapshot: any;
  contact: any;
  commissionSale: number;
  consultantRecruitingTime: string | null;
}

const buildCommission = (
  data: IUserCommission,
  ecosystemId: string,
): Partial<CommissionEntry> => {
  const date = data.paid ? new Date(data.paid) : new Date();
  const period = `${date.getUTCFullYear()}_${date.getUTCMonth() + 1}`;

  return {
    ecosystem: ecosystemId,
    period,
    timestamp: moment(data.paid).valueOf(),
    sellerId: data.sponsor_id.toString(),
    sellerPeriodToken: `${data.sponsor_id}_${period}_${ecosystemId}`,
    contactSnapshot: {
      firstName: data.firstname,
      lastName: data.lastname,
      type: 'person',
    },
    commissionSale: data.total_cleaned_provision_price,
    consultantRecruitingTime: `${date.getUTCFullYear()}_${
      date.getUTCMonth() + 1
    }`, //this need to be taken from consultant, current value can be fallback
    saleId: `${data.order_id}` || '',
  };
};
