import { Typography } from 'antd';
import { styled } from '@styles/themes';

const { Text } = Typography;

export const ProjectDetailsFieldContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 20px;
  grid-template-areas:
    'top top'
    'bottom bottom';
`;

export const StyledSectionTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.text.gray};
  font-weight: 600;
`;
