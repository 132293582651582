import { database } from 'firebase';
import { notification } from 'antd';
import { Invitation } from '@services/invitations/namespace';
import { emitCustomEvent } from '@core/services';
import intl from '../../../intl';

const handleInvitation = (data: database.DataSnapshot) => {
  const invitation = data.val() as Invitation;
  if (invitation.status === 'PENDING') {
    emitCustomEvent('new_invitations', {
      ...invitation,
      id: data.key,
    });
    notification.open({
      key: 'invitations',
      message: intl.formatMessage({
        id: 'notification.title',
        defaultMessage: 'News',
      }),
      description: intl.formatMessage({
        id: 'notification.description.invitation',
        defaultMessage: 'Du hast neue Einladungen',
      }),
    });
  }
};

const handleApprove = (data: database.DataSnapshot) => {
  const invitation = data.val() as Invitation;
  if (invitation.status === 'APPROVED') {
    emitCustomEvent('approved_invitation', {
      ...invitation,
      id: data.key,
    });
  }
};

const invitationsListener = (recipientEmail: string, db: database.Database) => {
  const invitationsRef = db
    .ref(`invitations`)
    .orderByChild('recipientEmail')
    .equalTo(recipientEmail);
  invitationsRef.on('child_added', (data) => {
    handleInvitation(data);
  });
  invitationsRef.on('child_changed', (data) => {
    handleApprove(data);
  });
};

export default invitationsListener;
