import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import intl from '../../../../../intl';

const { Item } = Form;

const ExpectedDeliveryDate: FC = () => {
  return (
    <Item
      name="expectedDeliveryDate"
      label={intl.formatMessage({
        id: 'expected.delivery.date',
        defaultMessage: 'Expected delivery date',
      })}
    >
      <DatePicker disabled format={moment.defaultFormat} />
    </Item>
  );
};

export default ExpectedDeliveryDate;
