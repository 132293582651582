import addressFieldFilter from '@components/List/CustomsFilters/AddressFieldFilter';
import { AgGridColumn } from 'ag-grid-react';
import React from 'react';
import { IntlShape } from 'react-intl';
import { AddressType } from '@services/filtering';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';

type AddressColumnProps = {
  fieldName: AddressType;
  textDefaultMessage: string;
  textId: string;
  intl: IntlShape;
};

const getAddressColumn = ({
  fieldName,
  textDefaultMessage,
  textId,
  intl,
}: AddressColumnProps) => (
  <AgGridColumn
    filter={true}
    field="addresses"
    filterFramework={addressFieldFilter({
      fieldName,
      textDefaultMessage,
      textId,
    })}
    valueGetter={({ data }: ValueGetterParams) =>
      data?.addresses && data?.addresses.length
        ? data?.standardInvoiceAddress &&
          data?.addresses[data?.standardInvoiceAddress]
          ? data?.addresses[data?.standardInvoiceAddress][fieldName]
          : data?.addresses[0][fieldName]
        : null
    }
    filterValueGetter={({ data }: ValueGetterParams) =>
      data?.addresses && data?.addresses.length
        ? data?.standardInvoiceAddress &&
          data?.addresses[data?.standardInvoiceAddress]
          ? data?.addresses[data?.standardInvoiceAddress][fieldName]
          : data?.addresses[0][fieldName]
        : null
    }
    headerName={intl.formatMessage({
      id: textId,
      defaultMessage: textDefaultMessage,
    })}
  />
);

export default getAddressColumn;
