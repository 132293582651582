import { styled } from '@styles/themes';
import { Modal } from 'antd';

export const ProductModal = styled(Modal)`
  top: 24px;

  .form-with-reminder-buttons {
    position: relative !important;
    display: flex;
    justify-content: end;
  }

  & {
    .ant-modal-close-x {
      width: 28px !important;
      height: 28px !important;
      line-height: 28px !important;
    }
  }
`;
