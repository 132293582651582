import React, { FC, useState } from 'react';
import { Badge, Spin } from 'antd';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';

type TasksInProjectCellRendererProps = {
  value?: string;
  data?: {
    ecosystem: string;
  };
};

const TasksInProjectCellRenderer: FC<TasksInProjectCellRendererProps> = (
  props,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tasksCount, setTasksCount] = useState(0);

  if (props) {
    const { value, data } = props;
    if (value && data) {
      getChildrenForParent({
        parentId: value,
        childType: 'task',
        ecosystem: data.ecosystem,
      })
        .then((tasks) => {
          setTasksCount(tasks.length);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }
  return (
    <Spin spinning={isLoading}>
      {!isLoading && <Badge count={tasksCount} showZero />}
    </Spin>
  );
};

export default TasksInProjectCellRenderer;
