export const COMMISSIONS_SETTINGS_COLLECTION_NAME = 'commissionsSettings';
export const COMMISSIONS_TOTALS_COLLECTION = 'commissionTotals';
export const COMMISSION_PERIOD_REPORTS = 'commissionPeriodReports';

export type ConsultantReportLink = {
  consultantId: string;
  link: string;
};

export type CommissionPeriodReportEntry = {
  status: 'PENDING' | 'SUCCESS' | 'ERROR';
  errorMessage: string;
  links: ConsultantReportLink[];
};

export type CommissionThreshold = {
  minThreshold: number;
  commissionBonus: number;
};

export type ProductBonus = {
  coupon: number;
  maxCoupon: number;
  perEachCommission: number;
};

export type RecruitingBonus = {
  minRecruiterSale: number;
  coupon: number;
};

export type Commission = {
  id?: string;
  ecosystemId: string;
  name: string;
  relatedLevel: string;
  sales: number;
  commissionThresholds: CommissionThreshold[];
  teamSize?: number;
  teamSales?: number;
  memberBonus?: number; // percent
  recruitingBonus?: RecruitingBonus;
  productBonus?: ProductBonus;
  yearlyJoker?: number;
};

export type ConsultantCommissionState = {
  id?: string;
  ecosystemId: string;
  period: string;
  consultantId: string;
  consultantName?: string;
  relatedLevel: string;
  commissionBonus: number;
  sales: number;
  netSales: number;
  teamSize: number;
  teamSales: number;
  teamNetSales: number;
  members: string[];
  parentId?: string;
  memberBonus: number;
  productBonus: number;
  recruitingBonus: number;
  newJoiners: number;
  yearlyJoker: number;
  consultantRecruitingPeriod: string | null;
  reportLink?: string;
  timestamp: number;
};

export type CommissionPeriodStats = {
  [period: string]: Record<string, ConsultantCommissionState>;
};
