import React, { FC, useState, useEffect } from 'react';
import SelectedEcosystem from '@apps/products/mainMenu/components/Form/components/SelectedEcosystem';
import CreationDate from '@apps/products/mainMenu/components/Form/components/CreationDate';
import Creator from '@apps/products/mainMenu/components/Form/components/Creator';
import { Spin, Form, Switch, Modal, Tooltip } from 'antd';
import SpinContainer from '@components/SpinContainer';
import MediaViewer from '@components/MediaViewer';
import { PRODUCTS_ACTION } from '@apps/products';
import { FormattedMessage, useIntl } from 'react-intl';

type RightColumnProps = {
  ecosystemId?: string;
  readOnly: boolean;
  form: any;
  namePrefix?: string | number;
  isLoading: boolean;
  relatedDocuments: any[];
  action: PRODUCTS_ACTION;
  initialValues?: any;
};

const RightColumn: FC<RightColumnProps> = ({
  isLoading,
  relatedDocuments,
  action,
  readOnly,
  form,
  initialValues,
}) => {
  const intl = useIntl();
  const [serialNumberConfirmVisible, setSerialNumberConfirmVisible] =
    useState(false);
  const [batchNumberConfirmVisible, setBatchNumberConfirmVisible] =
    useState(false);
  const [tempSerialNumberValue, setTempSerialNumberValue] = useState(false);
  const [tempBatchNumberValue, setTempBatchNumberValue] = useState(false);
  const [savedSerialNumber, setSavedSerialNumber] = useState(
    initialValues?.hasSerialNumber || false,
  );
  const [savedBatchNumber, setSavedBatchNumber] = useState(
    initialValues?.hasBatchNumber || false,
  );

  useEffect(() => {
    if (initialValues) {
      setSavedSerialNumber(initialValues.hasSerialNumber || false);
      setSavedBatchNumber(initialValues.hasBatchNumber || false);
    }
  }, [initialValues]);

  const handleSerialNumberChange = (checked: boolean) => {
    if (checked && !savedSerialNumber) {
      setTempSerialNumberValue(checked);
      setSerialNumberConfirmVisible(true);
    } else {
      form.setFieldsValue({ hasSerialNumber: savedSerialNumber });
    }
  };

  const handleBatchNumberChange = (checked: boolean) => {
    if (checked && !savedBatchNumber) {
      setTempBatchNumberValue(checked);
      setBatchNumberConfirmVisible(true);
    } else {
      form.setFieldsValue({ hasBatchNumber: savedBatchNumber });
    }
  };

  const confirmSerialNumberActivation = () => {
    form.setFieldsValue({ hasSerialNumber: tempSerialNumberValue });
    setSerialNumberConfirmVisible(false);
  };

  const cancelSerialNumberActivation = () => {
    form.setFieldsValue({ hasSerialNumber: savedSerialNumber });
    setSerialNumberConfirmVisible(false);
  };

  const confirmBatchNumberActivation = () => {
    form.setFieldsValue({ hasBatchNumber: tempBatchNumberValue });
    setBatchNumberConfirmVisible(false);
  };

  const cancelBatchNumberActivation = () => {
    form.setFieldsValue({ hasBatchNumber: savedBatchNumber });
    setBatchNumberConfirmVisible(false);
  };

  return (
    <div>
      <SelectedEcosystem action={action} />
      <Creator />
      <CreationDate />
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.unit !== currentValues.unit
        }
      >
        {({ getFieldValue }) => {
          const unit = getFieldValue('unit');
          const isItemUnit = unit === 'item';

          return (
            <Tooltip
              title={
                !isItemUnit && !savedSerialNumber
                  ? intl.formatMessage({
                      id: 'products.form.hasSerialNumber.itemOnly',
                      defaultMessage:
                        'Serial number can only be activated for products with "Item" unit',
                    })
                  : ''
              }
            >
              <div>
                <Form.Item
                  name="hasSerialNumber"
                  valuePropName="checked"
                  label={
                    <FormattedMessage
                      id="products.form.hasSerialNumber.label"
                      defaultMessage="Has Serial Number"
                    />
                  }
                >
                  <Switch
                    disabled={
                      readOnly ||
                      savedSerialNumber ||
                      (!isItemUnit && !savedSerialNumber)
                    }
                    onChange={handleSerialNumberChange}
                  />
                </Form.Item>
              </div>
            </Tooltip>
          );
        }}
      </Form.Item>

      <Form.Item
        name="hasBatchNumber"
        valuePropName="checked"
        label={
          <FormattedMessage
            id="products.form.hasBatchNumber.label"
            defaultMessage="Has Batch Number"
          />
        }
      >
        <Switch
          disabled={readOnly || savedBatchNumber}
          onChange={handleBatchNumberChange}
        />
      </Form.Item>
      <div>
        <Spin spinning={isLoading} indicator={<SpinContainer />}>
          <MediaViewer
            showPlayButton={false}
            thumbnailPosition="top"
            items={relatedDocuments.map((doc) => ({
              url: doc.documentUrl,
              thumbnailUrl:
                doc.fileDetails.format === 'pdf'
                  ? doc.thumbnailUrl || 'placeholder'
                  : doc.documentUrl,
              format: doc.fileDetails.format,
            }))}
          />
        </Spin>
      </div>

      <Modal
        title={
          <FormattedMessage
            id="products.form.serialNumber.activation"
            defaultMessage="Serial Number Activation"
          />
        }
        visible={serialNumberConfirmVisible}
        onOk={confirmSerialNumberActivation}
        onCancel={cancelSerialNumberActivation}
        okText={intl.formatMessage({ id: 'common.ok', defaultMessage: 'OK' })}
        cancelText={intl.formatMessage({
          id: 'common.cancel',
          defaultMessage: 'Cancel',
        })}
      >
        <p>
          {intl.formatMessage(
            {
              id: 'products.form.numberActivation.warning',
              defaultMessage:
                'This activation cannot be undone. Please be aware that you have to enter {type} for each delivery and Movement',
            },
            {
              type: intl.formatMessage({
                id: 'products.form.serialNumber.label',
                defaultMessage: 'Serial Number',
              }),
            },
          )}
        </p>
      </Modal>

      <Modal
        title={
          <FormattedMessage
            id="products.form.batchNumber.activation"
            defaultMessage="Batch Number Activation"
          />
        }
        visible={batchNumberConfirmVisible}
        onOk={confirmBatchNumberActivation}
        onCancel={cancelBatchNumberActivation}
        okText={intl.formatMessage({ id: 'common.ok', defaultMessage: 'OK' })}
        cancelText={intl.formatMessage({
          id: 'common.cancel',
          defaultMessage: 'Cancel',
        })}
      >
        <p>
          {intl.formatMessage(
            {
              id: 'products.form.numberActivation.warning',
              defaultMessage:
                'This activation cannot be undone. Please be aware that you have to enter {type} for each delivery and Movement',
            },
            {
              type: intl.formatMessage({
                id: 'products.form.batchNumber.label',
                defaultMessage: 'Batch Number',
              }),
            },
          )}
        </p>
      </Modal>
    </div>
  );
};

export default RightColumn;
