import React, { FC, useEffect, useState } from 'react';
import { MIN_LENGTH_SELECT, supportedFileTypes } from './consts';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from 'antd/lib/card';
import { Form, Button, Input, Select } from 'antd';
import { useParams } from 'react-router-dom';
import SpinContainer from '@components/SpinContainer';
import { addSalesRangeOfNumbers } from '@services/rangeOfNumbers';
import { useRangeOfNumbers } from '@apps/sales/settings/RangeOfNumbers/useRangeOfNumbers';
import StyledTable from '../../../../pages/settings/components/StyledTable';

const RangeOfNumbers: FC<any> = () => {
  const intl = useIntl();
  const { uid: ecoId } = useParams<any>();
  const [areDataRetrieved, rangeOfNumbers, setRangeOfNumbers] =
    useRangeOfNumbers(ecoId);
  const [isLoading, setIsLoading] = useState<boolean>(areDataRetrieved);

  useEffect(() => {
    setIsLoading(areDataRetrieved);
  }, [areDataRetrieved]);

  const columns = [
    {
      title: (
        <FormattedMessage
          id={`settings.sales.rangeOfNumbers.table.header.fileType`}
          defaultMessage={`File type`}
        />
      ),
      render: (_text: any, record: any) => (
        <FormattedMessage
          id={`settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${record.fileType}`}
          defaultMessage={`settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${record.fileType}`}
        />
      ),
    },
    {
      title: (
        <FormattedMessage
          id={`settings.sales.rangeOfNumbers.table.header.abbreviation`}
          defaultMessage={`Abbreviation`}
        />
      ),
      render: (_text: any, record: any) => (
        <>
          {/* To keep next number in form and do not overide it */}
          <Form.Item
            name={['fileTypes', record.fileType, 'nextNumber']}
            style={{ display: 'none' }}
          />
          <Form.Item name={['fileTypes', record.fileType, 'abbreviation']}>
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      title: (
        <FormattedMessage
          id={`settings.sales.rangeOfNumbers.table.header.freeText`}
          defaultMessage={`Free text`}
        />
      ),
      render: (_text: any, record: any) => (
        <Form.Item name={['fileTypes', record.fileType, 'freeText']}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: (
        <FormattedMessage
          id={`settings.sales.rangeOfNumbers.table.header.minLength`}
          defaultMessage={`Min length`}
        />
      ),
      render: (_text: any, record: any) => (
        <Form.Item name={['fileTypes', record.fileType, 'minLength']}>
          <Select
            placeholder={
              <FormattedMessage
                id="settings.sales.rangeOfNumbers.minLength.select.label"
                defaultMessage="min length"
              />
            }
            options={Object.keys(MIN_LENGTH_SELECT).map((key: string) => ({
              label: key,
              value: MIN_LENGTH_SELECT[key],
            }))}
          />
        </Form.Item>
      ),
    },
  ];

  if (isLoading) {
    return <SpinContainer />;
  }

  return (
    <Card
      title={
        <FormattedMessage
          id="settings.sales.groups.rangeOfNumbers.menuTitle"
          defaultMessage="settings.sales.groups.rangeOfNumbers.menuTitle"
        />
      }
    >
      <Form
        onFinish={(values) => {
          addSalesRangeOfNumbers(
            {
              ...values,
              ecosystemId: ecoId,
            },
            ecoId,
          )
            .then(() =>
              setRangeOfNumbers({
                ...values,
                ecosystemId: ecoId,
              }),
            )
            .finally(() => setIsLoading(false));
        }}
        initialValues={{
          fileTypes: (rangeOfNumbers && rangeOfNumbers.fileTypes) || {
            ...Object.keys(supportedFileTypes).reduce((prev: any, fileType) => {
              prev[fileType] = {
                abbreviation: intl.formatMessage({
                  id: `settings.sales.rangeOfNumbers.supportedFileTypes.abbreviation.${fileType}`,
                  defaultMessage: `settings.sales.rangeOfNumbers.supportedFileTypes.abbreviation.${fileType}`,
                }),
              };
              return prev;
            }, {}),
          },
        }}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <StyledTable
          dataSource={Object.keys(supportedFileTypes).map((el) => ({
            fileType: el,
          }))}
          columns={columns}
          tableLayout="auto"
          pagination={false}
          rowKey={(_product: any, index: any) => index as number}
        />
        <Button
          type="primary"
          disabled={isLoading}
          htmlType="submit"
          style={{ marginTop: 18, marginLeft: 'auto' }}
        >
          <FormattedMessage id="button.save" defaultMessage="Save" />
        </Button>
      </Form>
    </Card>
  );
};

export default RangeOfNumbers;
