import React, { FC } from 'react';
import { Select } from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';

type Props = SelectProps<SelectValue> & {
  width?: number | string;
};

const SelectWithSearch: FC<Props> = ({ width, ...rest }) => (
  <Select
    showSearch
    style={width ? { width: width } : {}}
    optionFilterProp="label"
    filterOption={(input, option) => {
      if (option && typeof option.label === 'string') {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }
      return true;
    }}
    // need update to antd 4.9
    // filterSort={(optionA, optionB) => {
    //   if (
    //     optionA &&
    //     typeof optionA.label === 'string' &&
    //     optionB &&
    //     typeof optionB.label === 'string'
    //   ) {
    //     return optionA.label
    //       .toLowerCase()
    //       .localeCompare(optionB.label.toLowerCase());
    //   }
    //   return 0;
    // }}
    {...rest}
  />
);

export default SelectWithSearch;
