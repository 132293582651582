import moment from 'moment';
import { patch } from '@services/api';
import { PRODUCT_PURCHASE_PRICES } from '@apps/products/services/namespace';

const archiveProductPurchasePrice = (id: string) =>
  patch(`${PRODUCT_PURCHASE_PRICES}/${id}`, {
    isArchived: true,
    timestamp: moment().valueOf(),
  });

export default archiveProductPurchasePrice;
