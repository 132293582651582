import React, { FC, useState, useCallback } from 'react';
import { Form, Input, Table, Select, Button } from 'antd';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import { useForm } from 'antd/lib/form/Form';
import SalesAppConfig from '@apps/sales';
import { useIntl } from 'react-intl';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { ActionsContainer, NameWithActions } from '@components/common.sc';
import { createAppRole, updateAppRole } from '@services/roles/appRoles';

const { Item } = Form;

type Props = {
  role: AppRole;
  inEditMode?: boolean;
  onSubmitStart(values: any): void;
  onSubmitSuccess(values: any): void;
  onSubmitError(error?: string): void;
};

const AppRolesForm: FC<Props> = ({
  role,
  onSubmitStart,
  onSubmitError,
  onSubmitSuccess,
  inEditMode = false,
}) => {
  const [form] = useForm();
  const [isEditMode, setIsEditMode] = useState(inEditMode);
  const onSubmit = useCallback(
    (values) => {
      onSubmitStart(values);
      if (role.id) {
        updateAppRole({
          ...role,
          ...values,
        })
          .then(() => {
            setIsEditMode(false);
            onSubmitSuccess({
              ...values,
              id: role.id,
            });
          })
          .catch((reason) => {
            setIsEditMode(false);
            onSubmitError(reason);
          });
      } else {
        createAppRole({
          ...role,
          ...values,
        })
          .then((id) => {
            setIsEditMode(false);
            onSubmitSuccess({
              ...values,
              id,
            });
          })
          .catch((reason) => {
            setIsEditMode(false);
            onSubmitError(reason);
          });
      }
    },
    [onSubmitError, onSubmitStart, onSubmitSuccess, role],
  );
  const intl = useIntl();
  const columns = [
    {
      title: intl.formatMessage({
        id: 'action',
        defaultMessage: 'Action',
      }),
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: intl.formatMessage({
        id: 'rights',
        defaultMessage: 'Rights',
      }),
      dataIndex: 'rights',
      key: 'rights',
      render: (values: string[], entry: any) => {
        const fieldName = `permission.${entry.actionId}`.split('.');
        return (
          <Item
            name={fieldName}
            key={`permission.${entry.actionId}`}
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'permission.missing',
                  defaultMessage: 'Permission is required',
                }),
              },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              disabled={!isEditMode}
              options={values.map((value) => ({
                label: intl.formatMessage({
                  id: `permission.${value}`,
                  defaultMessage: `permission.${value}`,
                }),
                value,
              }))}
            />
          </Item>
        );
      },
    },
  ];
  const actionRights = [
    {
      key: '1',
      action: intl.formatMessage({
        id: 'view.sales',
        defaultMessage: 'View sales',
      }),
      actionId: 'view-sales',
      rights: SalesAppConfig.permissions || [],
    },
    {
      key: '2',
      action: intl.formatMessage({
        id: 'create.sales',
        defaultMessage: 'Create sales',
      }),
      actionId: 'create-sales',
      rights: SalesAppConfig.permissions || [],
    },
    {
      key: '3',
      action: intl.formatMessage({
        id: 'update.sales',
        defaultMessage: 'Update sales',
      }),
      actionId: 'update-sales',
      rights: SalesAppConfig.permissions || [],
    },
    {
      key: '4',
      action: intl.formatMessage({
        id: 'delete.sales',
        defaultMessage: 'Delete sales',
      }),
      actionId: 'delete-sales',
      rights: SalesAppConfig.permissions || [],
    },
  ];
  return (
    <div>
      <AppEntityForm
        appId={SalesAppConfig.todixId}
        autoComplete="off"
        initialValues={role}
        name="appRolesForm_sales"
        providedForm={form}
        readOnly={!isEditMode}
        onSubmit={onSubmit}
        hideButtons={true}
      >
        {() => {
          return (
            <div>
              <NameWithActions>
                <Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'missing.name',
                        defaultMessage: 'Name is required',
                      }),
                    },
                  ]}
                >
                  <Input
                    disabled={!isEditMode}
                    placeholder={intl.formatMessage({
                      id: 'name',
                      defaultMessage: 'Name',
                    })}
                  />
                </Item>
                <ActionsContainer>
                  {!isEditMode && (
                    <Button
                      icon={<EditOutlined />}
                      htmlType="button"
                      onClick={() => setIsEditMode(true)}
                    />
                  )}
                  {isEditMode && (
                    <>
                      <Button icon={<SaveOutlined />} htmlType="submit" />
                    </>
                  )}
                </ActionsContainer>
              </NameWithActions>
              <Table
                dataSource={actionRights}
                columns={columns}
                pagination={false}
              />
            </div>
          );
        }}
      </AppEntityForm>
    </div>
  );
};

export default AppRolesForm;
