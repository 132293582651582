import React, { FC } from 'react';
import { Popover } from 'antd';
import { Document } from '@apps/documents/service';
import { DocumentInfo } from '@apps/documents/mainMenu/Inbox/DocumentInfo';

type DocumentInfoPopupProps = {
  document: Document;
};

const DocumentInfoPopup: FC<DocumentInfoPopupProps> = ({
  document,
  children,
}) => {
  return (
    <Popover
      content={<DocumentInfo document={document} />}
      trigger="click"
      placement="left"
    >
      {children}
    </Popover>
  );
};

export default DocumentInfoPopup;
