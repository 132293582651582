import { styled } from '@styles/themes';
import { Radio } from 'antd';

const { Group } = Radio;

export const GridStyleRadioGroup = styled(Group)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  & {
    .ant-radio-button-checked,
    .ant-radio-button-wrapper-checked {
      color: ${(props) => props.theme.colors.text.light} !important;
      background-color: ${(props) =>
        props.theme.colors.main.primary} !important;
    }

    .ant-radio-button-wrapper {
      border: 0 !important;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
    }
  }
`;

export const LeftColumn = styled.div``;

export const RightColumn = styled.div``;

export const FlexJustifyEnd = styled.div`
  display: flex;
  justify-content: end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SaveButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 8px;
`;

export const GridSection = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 10px;

  @media (max-width: 1600px) {
    grid-template-columns: 1fr;
    && {
      .ant-form-item-control {
        min-width: 100%;
      }

      .ant-form-item-label {
        min-width: fit-content;
      }
    }
  }

  && {
    .ant-form-item-hidden {
      display: none !important;
    }
    .ant-form-item {
      display: grid;
    }
    .ant-form-item-control {
      width: 100%;
      max-width: 100% !important;
    }
    .ant-picker {
      width: 100%;
    }
    .ant-form-item-label {
      max-width: 100% !important;
    }
  }
`;

export const DocumentSpecificGridSection = styled(GridSection)`
  @media (max-width: 1600px) {
    display: block;
  }
`;

export const DocumentWrapper = styled.div`
  & {
    .ant-form-item-label {
      margin-top: -8px;
    }
  }
`;
