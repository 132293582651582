import React from 'react';
import { FormattedMessage } from 'react-intl';

export type GenderRendererProps = {
  colDef: any;
  data: any;
  value: any;
  [key: string]: any;
};

const GenderRenderer = ({ value }: GenderRendererProps) => {
  if (!value) {
    return '';
  }
  return (
    <span>
      <FormattedMessage
        id={`detailcontactform.personinfo.gender.${value}`}
        defaultMessage={value}
      />
    </span>
  );
};

export default GenderRenderer;
