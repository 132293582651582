import React, { FC, useCallback, useEffect } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { postTask, Task, updateTask } from '@apps/tasks/services';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import createRelation from '@services/entityRelations/createRelation';
import removeRelation from '@services/entityRelations/removeRelation';
import getRelation from '@services/entityRelations/getRelation';

type SubTaskFormProps = {
  ecosystem: string;
  isVisible: boolean;
  onClose(): void;
  onSubmit(): void;
  onDelete(subtaskId: string): void;
  selectedSubtask: Partial<Task> | null;
  parentId: string;
  readOnly: boolean;
};

const SubTaskForm: FC<SubTaskFormProps> = ({
  isVisible,
  onClose,
  ecosystem,
  onSubmit,
  selectedSubtask,
  parentId,
  readOnly,
  onDelete,
}) => {
  const [form] = Form.useForm();
  const { data: user } = useSelector(getUser);

  useEffect(() => {
    if (selectedSubtask) {
      form.setFieldsValue({
        ...selectedSubtask,
      });
    }
  }, [form, selectedSubtask]);

  const handleSubmit = useCallback(
    (values) => {
      if (selectedSubtask?.id) {
        updateTask(selectedSubtask.id, {
          ...selectedSubtask,
          title: values.title,
        } as Task).finally(() => {
          onClose();
        });
        return;
      }
      if (user) {
        postTask({
          ecosystem,
          creator: user,
          createdBy: user.uid,
          title: values.title,
          progress: 0,
          assignee: '',
          followers: [],
          creationDate: moment().format(moment.defaultFormat),
          dueDate: '',
          startDate: '',
          doneDate: '',
          status: 'open',
          description: '',
          documents: [],
          tags: [],
        })
          .then((childId) => {
            createRelation({
              childId,
              childRefCollection: 'tasks',
              childType: 'task',
              parentId,
              parentRefCollection: 'tasks',
              parentType: 'task',
              ecosystem: form.getFieldValue('ecosystem'),
            }).then(() => {
              form.resetFields();
              onSubmit();
            });
          })
          .finally(() => {
            onClose();
          });
      }
    },
    [ecosystem, form, onClose, onSubmit, parentId, selectedSubtask, user],
  );

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleDelete = useCallback(() => {
    if (selectedSubtask?.id) {
      getRelation({ parentId, childId: selectedSubtask.id }).then(
        (relation) => {
          if (relation.length) {
            removeRelation(relation[0].id as string).finally(() => {
              onDelete(selectedSubtask.id as string);
              onClose();
            });
          }
        },
      );
    }
  }, [onClose, onDelete, parentId, selectedSubtask?.id]);

  return (
    <Modal
      closable={false}
      visible={isVisible}
      footer={[
        selectedSubtask?.id ? (
          <Button
            type="primary"
            danger
            disabled={readOnly}
            onClick={handleDelete}
          >
            <FormattedMessage
              id="tasks.button.removeFromTask"
              defaultMessage="Remove from task"
            />
          </Button>
        ) : undefined,
        <Button
          type="primary"
          htmlType="submit"
          form="newSubTask"
          disabled={readOnly}
        >
          <FormattedMessage id="button.save" defaultMessage="Save" />
        </Button>,
        <Button onClick={handleCancel}>
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>,
      ]}
    >
      <Form name="newSubTask" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label={
            <FormattedMessage
              id="tasks.form.title.label"
              defaultMessage="Title"
            />
          }
          required
          rules={[
            {
              type: 'string',
              max: 40,
              required: true,
              message: (
                <FormattedMessage
                  id="tasks.form.title.error"
                  defaultMessage="Title may not be empty and cannot be longer than 40 characters"
                />
              ),
            },
          ]}
        >
          <Input disabled={readOnly} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SubTaskForm;
