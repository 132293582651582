import SettingsConfig from './settings';
import MainMenuConfig from './mainMenu';
import { FileApp } from '@apps/AppTypes';
import { PROJECTS_COLLECTION_NAME } from '@apps/projects/services';

export type PROJECTS_ACTION =
  | 'view-projects'
  | 'create-projects'
  | 'update-projects'
  | 'delete-projects';

export type PROJECTS_PERMISSION = 'all' | 'none';

export default {
  todixId: 'projects',
  link: '/app/projects',
  name: 'projects',
  entityType: 'project',
  collectionName: PROJECTS_COLLECTION_NAME,
  localizationPath: 'projects/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.projects.createNewLink.new.project',
    },
  ],
  listCellRenderers: [
    {
      name: 'projectNameRenderer',
      path: 'projects/widgets/ProjectNameRenderer',
    },
    {
      name: 'projectsCellRenderer',
      path: 'projects/widgets/ProjectsCellRenderer',
    },
    {
      name: 'favProjectCellRenderer',
      path: 'projects/widgets/FavProjectCellRenderer',
    },
  ],
  widgets: [
    {
      name: 'MemberSelector',
      path: 'projects/widgets/MemberSelector',
    },
    {
      name: 'TreeNode',
      path: 'projects/widgets/TreeNode',
    },
    {
      name: 'StatisticsWidget',
      path: 'projects/widgets/StatisticsWidget',
    },
    {
      name: 'DashboardWidget',
      path: 'projects/widgets/DashboardWidget',
    },
    {
      name: 'AppRolesForm',
      path: 'projects/widgets/AppRolesForm',
    },
  ],
  actions: [
    'view-projects',
    'create-projects',
    'update-projects',
    'delete-projects',
  ] as PROJECTS_ACTION[],
  permissions: ['all', 'none'] as PROJECTS_PERMISSION[],
} as FileApp;
