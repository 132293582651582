import React, { FC, useState, useEffect, useCallback } from 'react';
import { Form, Select, Space } from 'antd';
import { Project } from '@apps/projects/services';
import intl from '../../../../../intl';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { getProjectsPerDetails } from '@apps/projects/services/getProjectsPerDetails';

const { Item } = Form;
const { Option } = Select;

type ProjectSelectorProps = {
  ecosystem: string;
  disabled?: boolean;
  namePrefix?: string | number;
};

type ProjectOption = {
  value: string;
  'data-forsearch': string;
  label: React.ReactNode;
};

const ProjectSelector: FC<ProjectSelectorProps> = ({
  ecosystem,
  disabled,
  namePrefix,
}) => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [options, setOptions] = useState<ProjectOption[]>([]);
  const { setFieldsValue } = useFormContext();

  const fetchProjects = useCallback(async () => {
    if (!ecosystem) return;

    setLoading(true);
    try {
      const projects = await getProjectsPerDetails({ ecosystem });
      setProjects(projects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  }, [ecosystem]);

  useEffect(() => {
    fetchProjects();
  }, [fetchProjects]);

  useEffect(() => {
    if (projects.length) {
      const projectOptions = projects.map((project) => ({
        value: project.id as string,
        'data-forsearch': project.name,
        label: (
          <Space>
            <span>{project.name}</span>
          </Space>
        ),
      }));
      setOptions(projectOptions);
    }
  }, [projects]);

  const handleProjectChange = (projectId: string) => {
    const selectedProject = projects.find(
      (project) => project.id === projectId,
    );
    if (selectedProject) {
      setFieldsValue({
        project: selectedProject.id,
      });
    }
  };

  return (
    <Item
      name={namePrefix !== undefined ? [namePrefix, 'project'] : 'project'}
      label={intl.formatMessage({
        id: 'app.projects.createNewLink.new.project',
        defaultMessage: 'Project',
      })}
    >
      <Select
        showSearch
        allowClear
        loading={loading}
        disabled={disabled}
        placeholder={intl.formatMessage({
          id: 'select.project',
          defaultMessage: 'Select project',
        })}
        optionFilterProp="data-forsearch"
        onChange={handleProjectChange}
        filterOption={(input, option) =>
          (option?.['data-forsearch'] as string)
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
      >
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            data-forsearch={option['data-forsearch']}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default ProjectSelector;
