import { getWithId } from '@services/api';
import {
  PURCHASES_COLLECTION,
  Purchase,
} from '@apps/purchases/services/namespace';

const getPurchase = (id: string): Promise<Purchase> => {
  return getWithId(`${PURCHASES_COLLECTION}/${id}`) as Promise<Purchase>;
};

export default getPurchase;
