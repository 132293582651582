import { lazy } from 'react';
import { ShopOutlined } from '@ant-design/icons';

const RangeOfNumbers = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ '@apps/purchases/settings/rangeOfNumbers'
    ),
);
const DeliveryTerms = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ '@apps/purchases/settings/deliveryTerms'
    ),
);

const PaymentTerms = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ '@apps/purchases/settings/paymentTerms'
    ),
);
const OrderTypes = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ '@apps/purchases/settings/orderTypes'
    ),
);
const OperationSteps = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ '@apps/purchases/settings/operationSteps'
    ),
);

export default {
  appSubmenuName: 'app.purchases',
  icon: ShopOutlined,
  sections: [
    {
      link: 'rangeOfNumbers',
      title: 'settings.sales.groups.rangeOfNumbers.menuTitle',
      component: RangeOfNumbers,
    },
    {
      link: 'deliveryTerms',
      title: 'settings.sales.groups.deliveryTerms.menuTitle',
      component: DeliveryTerms,
    },
    {
      link: 'paymentTerms',
      title: 'settings.sales.groups.paymentTerms.menuTitle',
      component: PaymentTerms,
    },
    {
      link: 'orderTypes',
      title: 'settings.sales.groups.orderTypes.menuTitle',
      component: OrderTypes,
    },
    {
      link: 'operation_steps',
      title: 'sales.operation.steps.settings',
      component: OperationSteps,
    },
  ],
};
