import { database } from 'firebase';
import { firebase } from '@services/Firebase';
import { notification } from 'antd';
import moment from 'moment/moment';
import { emitCustomEvent } from '@core/services';

const COLLECTION_NAME = 'notifications';

export const createNotification = async (notification: TodixNotification) => {
  return firebase.db
    ?.ref(COLLECTION_NAME)
    .push({
      ...notification,
      timestamp: moment().valueOf(),
    })
    .then((result) => ({
      ...notification,
      id: result.key as string,
    }));
};

export const removeNotification = async (id: string) => {
  return firebase.db?.ref(`${COLLECTION_NAME}/${id}`).remove();
};

export const NOTIFICATION_EVENT = 'notification';

const handleNotification = (data: database.DataSnapshot) => {
  const notificationData = data.val() as TodixNotification;

  emitCustomEvent(NOTIFICATION_EVENT, {
    ...notificationData,
    id: data.key,
  });
  notification.open({
    key: 'notifications',
    message: notificationData.title,
    description: notificationData.description,
    onClick: async () => {
      if (data.key) {
        await removeNotification(data.key);
      }
    },
    onClose: async () => {
      if (data.key) {
        await removeNotification(data.key);
      }
    },
    duration: 10,
  });
};

export const notificationListener = (
  recipientId: string,
  db: database.Database,
) => {
  const invitationsRef = db
    .ref(COLLECTION_NAME)
    .orderByChild('recipientId')
    .equalTo(recipientId);
  invitationsRef.on('child_added', (data) => {
    handleNotification(data);
  });
};
