import { jsPDF } from 'jspdf';
import addAvatar from '@apps/sales/mainMenu/components/pdfTemplates/tools/addAvatar';
import addDocNumber from '@apps/sales/mainMenu/components/pdfTemplates/tools/addDocNumber';
import addContact from '@apps/sales/mainMenu/components/pdfTemplates/tools/addContact';
import addEcosystemData from '@apps/sales/mainMenu/components/pdfTemplates/tools/addEcosystemData';
import { TemplateProps } from '@apps/sales/mainMenu/components/pdfTemplates/namespace';
import addProductsTable from '@apps/sales/mainMenu/components/pdfTemplates/tools/addProductsTable';
import addOrderId from '@apps/sales/mainMenu/components/pdfTemplates/tools/addOrderId';
import addFooter from '@apps/sales/mainMenu/components/pdfTemplates/tools/addFooter';
import { fetchUser } from '@services/users';
import { getSaleOperationSettings } from '@apps/sales/services/saleOperation/saleOperationSettings';

const createMainTemplate = async (props: TemplateProps): Promise<jsPDF> => {
  const doc = new jsPDF();
  const { ecosystem, values } = props;
  const { type } = values;
  let lastYPosition = 20;
  await addAvatar(doc, props, lastYPosition);
  lastYPosition = 50;
  lastYPosition = addEcosystemData(doc, props, lastYPosition);
  lastYPosition = lastYPosition + 10;
  lastYPosition = addContact(doc, props, lastYPosition);
  lastYPosition = lastYPosition + 10;
  addDocNumber(doc, props, lastYPosition);
  addOrderId(doc, props, lastYPosition);
  lastYPosition = lastYPosition + 15;
  addProductsTable(doc, props, lastYPosition, type as string);
  lastYPosition = 275;
  if (ecosystem && type) {
    const saleOperationSettings = await getSaleOperationSettings(
      type,
      ecosystem.id,
    );
    const ecosystemOwner = await fetchUser(ecosystem.ownerUid);
    if (saleOperationSettings.length) {
      addFooter(
        doc,
        props,
        lastYPosition,
        ecosystemOwner,
        saleOperationSettings[0],
      );
    } else {
      addFooter(doc, props, lastYPosition, ecosystemOwner);
    }
  }

  doc.close();
  return doc;
};

export default createMainTemplate;
