import React, { FC } from 'react';
import { Form, Space, Button, InputNumber } from 'antd';
import { IntlShape } from 'react-intl';
import {
  MinusCircleOutlined,
  PercentageOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { CommissionRulesLabel } from '@apps/directSales/styles.sc';
import { transformFloat } from '@apps/directSales/settings/Commissions/components/commonHelpers';
import { currencySigns } from '@services/currency';

const { Item, List } = Form;

type Props = {
  intl: IntlShape;
};

const CommissionThresholds: FC<Props> = ({ intl }) => {
  return (
    <>
      <CommissionRulesLabel>
        {intl.formatMessage({
          id: 'commissions.commission',
          defaultMessage: 'Commission',
        })}
      </CommissionRulesLabel>
      <List name="commissionThresholds">
        {(fields, { remove, add }) => {
          return (
            <>
              <Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {intl.formatMessage({
                    id: 'commissions.commission.add',
                    defaultMessage: 'Add commission threshold',
                  })}
                </Button>
              </Item>
              {fields.map((field) => {
                return (
                  <Space key={field.key} direction="horizontal">
                    <Item
                      {...field}
                      name={[field.name, 'minThreshold']}
                      label={intl.formatMessage({
                        id: 'commissions.commission.min.threshold',
                        defaultMessage: 'Min threshold',
                      })}
                      normalize={transformFloat}
                      rules={[
                        {
                          type: 'number',
                          min: 0,
                          transform: transformFloat,
                        },
                      ]}
                    >
                      <InputNumber
                        type="number"
                        min={0}
                        step={0.01}
                        addonAfter={currencySigns['EUR']}
                      />
                    </Item>
                    <Item
                      {...field}
                      name={[field.name, 'commissionBonus']}
                      label={intl.formatMessage({
                        id: 'commissions.commission.bonus',
                        defaultMessage: 'Commission bonus',
                      })}
                      normalize={transformFloat}
                      rules={[
                        {
                          type: 'number',
                          min: 0,
                          transform: transformFloat,
                        },
                      ]}
                    >
                      <InputNumber
                        type=" number"
                        min={0}
                        step={0.01}
                        addonAfter={<PercentageOutlined />}
                      />
                    </Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                );
              })}
            </>
          );
        }}
      </List>
    </>
  );
};

export default CommissionThresholds;
