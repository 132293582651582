import React, { FC } from 'react';
import { Form, InputProps } from 'antd';
import { ItemNoMargin } from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import FloatNumberedField from '@components/FloatNumberedField';
const { Item } = Form;

type Props = InputProps & {
  name: string;
  handleSave(): void;
};

const calculateValues = (
  name: string,
  value: number,
  formValues: Record<string, any>,
) => {
  const { discountedPrice, amount } = formValues;
  const taxValue = value || 0;
  const grossPrice = Number(
    Number(discountedPrice + discountedPrice * (taxValue / 100)).toFixed(2),
  );
  const totalGrossPrice = amount * grossPrice;
  return {
    grossPrice,
    totalGrossPrice,
    [name]: taxValue,
  };
};

const TaxSelection: FC<Props> = ({ name, onBlur, handleSave, disabled }) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldsValue, setFieldsValue }) => {
        const formValues = getFieldsValue();

        return (
          <ItemNoMargin name={name}>
            <FloatNumberedField
              disabled={disabled}
              allowNegative={false}
              max={100}
              decimalSeparator={','}
              decimalScale={2}
              thousandSeparator={'.'}
              fixedDecimalScale
              onBlur={onBlur}
              onChange={(value: any) => {
                const newValues = calculateValues(name, value, formValues);

                setFieldsValue({
                  ...formValues,
                  ...newValues,
                });

                if (handleSave) {
                  handleSave();
                }
              }}
            />
          </ItemNoMargin>
        );
      }}
    </Item>
  );
};

export default TaxSelection;
