import React, { useEffect, useState } from 'react';
import { Avatar, Form, Input, Space } from 'antd';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import {
  CompanyContact,
  Contact,
  getContact,
  PersonContact,
} from '@apps/contacts/services';
import { FormattedMessage, useIntl } from 'react-intl/lib';

type ContactFieldState = {
  avatarUrl: string;
  name: string;
};

const ContactField: React.FC<FieldProps> = ({
  field,
  children,
  initialValue,
  ...rest
}) => {
  const intl = useIntl();
  const [contact, setContact] = useState<ContactFieldState | null>(null);
  const { getFieldValue } = useFormContext();
  const { fieldName, label, className, style, placeholder } = field;
  const id = getFieldValue(fieldName) || initialValue;

  useEffect(() => {
    if (id) {
      getContact(id).then((response: Contact) => {
        if (response.type === 'person') {
          const { avatar, firstName, lastName } = response as PersonContact;
          setContact({
            avatarUrl: avatar || '',
            name: `${firstName} ${lastName}`,
          });
        }
        if (response.type === 'company') {
          const { avatar, companyName } = response as CompanyContact;
          setContact({
            avatarUrl: avatar || '',
            name: companyName,
          });
        }
      });
    }
  }, [id]);

  return (
    <Form.Item
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <Space direction="horizontal" className={className} style={style}>
        <Avatar shape="circle" src={contact?.avatarUrl} />
        <Input
          placeholder={
            placeholder
              ? intl.formatMessage({
                  id: placeholder,
                  defaultMessage: placeholder,
                })
              : placeholder
          }
          readOnly={true}
          value={contact?.name || ''}
          style={{ border: 0 }}
        />
      </Space>
    </Form.Item>
  );
};

export default ContactField;
