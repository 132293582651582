import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const { Item } = Form;

const CreationDate: FC = () => {
  return (
    <Item
      name="creationDate"
      label={
        <FormattedMessage
          id="products.sidecontent.generalinformation.productcreationdate.label"
          defaultMessage="Creation date"
        />
      }
    >
      <DatePicker format={moment.defaultFormat} disabled />
    </Item>
  );
};

export default CreationDate;
