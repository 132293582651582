import React, { FC, useMemo } from 'react';
import { FileApp } from '@apps/AppTypes';
import { Select, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FlexSelect } from '@components/Relations/Relations.sc';

const { Option } = Select;

type RelationsFilterProps = {
  availableApps: FileApp[];
  onRelationFilterChange(option: unknown): void;
};

const RelationsFilter: FC<RelationsFilterProps> = ({
  availableApps,
  onRelationFilterChange,
}) => {
  const options = useMemo(() => {
    return availableApps.map((app, index) => {
      return (
        <Option value={app.name} label={app.name} key={index}>
          <Space direction="horizontal" size="small">
            <span role="img" aria-label={app.name}>
              <app.mainMenu.icon />
            </span>
            <span>{app.name}</span>
          </Space>
        </Option>
      );
    });
  }, [availableApps]);

  return (
    <FlexSelect
      optionLabelProp="label"
      onChange={onRelationFilterChange}
      placeholder={
        <FormattedMessage
          id="relations.relations.filter.placeholder"
          defaultMessage="Select resource type"
        />
      }
    >
      {options}
    </FlexSelect>
  );
};

export default RelationsFilter;
