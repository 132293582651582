import React, { FC } from 'react';
import { InputProps } from 'antd/lib/input';
import { InputStyled, Wrapper } from './Search.sc';
import { SearchOutlined } from '@ant-design/icons';

export const Search: FC<InputProps> = (props) => (
  <Wrapper>
    <SearchOutlined />
    <InputStyled {...props} bordered={false} />
  </Wrapper>
);
