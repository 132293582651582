import React from 'react';
import moment from 'moment';

export type Props = {
  colDef: any;
  data: any;
  value: any;
  [key: string]: any;
};

export const getStringDateFromValue = (value: any) => {
  if (!value) {
    return '';
  }

  if (
    !Number.isInteger(value) &&
    !moment.isMoment(value) &&
    !value?.includes('T') &&
    !value?.includes('Z')
  ) {
    return value;
  }

  const stringFromMoment = moment(value).format(moment.defaultFormat);
  return stringFromMoment.includes('Invalid') ? value : stringFromMoment;
};

const DateRenderer = ({ value }: Props) => {
  return <span>{getStringDateFromValue(value)}</span>;
};

export default DateRenderer;
