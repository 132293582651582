import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import {
  BATCH_NUMBERS,
  BatchNumberEntry,
} from '@apps/warehouse/services/namespace';

type Payload = {
  ecosystem: string;
  productId?: string;
  positionId?: string;
};

const getBatchNumbers = ({ ecosystem, productId, positionId }: Payload) => {
  let query = (firebase.firestore as firebase.firestore.Firestore)
    .collection(BATCH_NUMBERS)
    .where('ecosystem', '==', ecosystem);

  if (productId) {
    query = query.where('productId', '==', productId);
  }

  if (positionId) {
    query = query.where('positionId', '==', positionId);
  }

  return query.get().then(getArrayWithIds) as Promise<BatchNumberEntry[]>;
};

export default getBatchNumbers;
