import { Consultant } from '@apps/directSales/mainMenu/Consultants/services';

export const isConsultant = (obj: any): obj is Consultant => {
  if (!obj) {
    return false;
  }

  const isRequiredFieldValid =
    typeof obj?.consultantNo === 'string' &&
    typeof obj?.ecosystem === 'string' &&
    typeof obj?.consultantName === 'string' &&
    typeof obj?.consultantSurname === 'string' &&
    typeof obj?.consultantEmail === 'string';

  const isOptionalFieldValid =
    (obj.photoURL === undefined || typeof obj.photoURL === 'string') &&
    (obj.consultantBirthday === undefined ||
      typeof obj?.consultantBirthday === 'string') &&
    (obj.consultantPhone === undefined ||
      typeof obj?.consultantPhone === 'string') &&
    (obj.consultantStreet === undefined ||
      typeof obj?.consultantStreet === 'string') &&
    (obj.consultantStreetNo === undefined ||
      typeof obj?.consultantStreetNo === 'string') &&
    (obj.consultantZip === undefined ||
      typeof obj?.consultantZip === 'string') &&
    (obj.consultantCity === undefined ||
      typeof obj?.consultantCity === 'string') &&
    (obj.consultantRef === undefined ||
      typeof obj?.consultantRef === 'string') &&
    (obj.consultantParent === undefined ||
      typeof obj?.consultantParent === 'string') &&
    (obj.consultantLevel === undefined ||
      typeof obj?.consultantLevel === 'string') &&
    (obj.consultantRank === undefined ||
      typeof obj?.consultantRank === 'string') &&
    (obj.description === undefined || typeof obj?.description === 'string') &&
    (obj.applicationDate === undefined ||
      typeof obj?.applicationDate === 'string') &&
    (obj.careerplanConfirmed === undefined ||
      typeof obj?.careerplanConfirmed === 'boolean') &&
    (obj.privacyProtectionConfirmed === undefined ||
      typeof obj?.privacyProtectionConfirmed === 'boolean') &&
    (obj.agbConfirmed === undefined ||
      typeof obj?.agbConfirmed === 'boolean') &&
    (obj.consultantTaxNo === undefined ||
      typeof obj?.consultantTaxNo === 'string') &&
    (obj.joker === undefined || typeof obj?.joker === 'number') &&
    (obj.iban === undefined || typeof obj?.iban === 'string') &&
    (obj.bic === undefined || typeof obj?.bic === 'string');

  return isOptionalFieldValid && isRequiredFieldValid;
};
