import React, { FC } from 'react';
import { Form } from 'antd';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl/lib';
import MoneyInputInFormItem from '@components/MoneyField/MoneyInputInFormItem';

const { Item } = Form;

const MoneyField: FC<FieldProps> = ({ field, ...rest }) => {
  const { readOnly } = useFormContext();
  const intl = useIntl();
  const {
    fieldName,
    label,
    rules,
    className,
    style,
    placeholder,
    readOnly: fieldReadOnly,
  } = field;
  return (
    <Item
      name={fieldName}
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      rules={rules?.map((r) => {
        r.message =
          r.message && typeof r.message === 'string' ? (
            <FormattedMessage
              id={`${r.message}`}
              defaultMessage={`${r.message}`}
            />
          ) : (
            r.message
          );
        return r;
      })}
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <MoneyInputInFormItem
        className={className}
        style={style}
        readOnly={fieldReadOnly ? fieldReadOnly : readOnly}
        placeholder={
          placeholder
            ? intl.formatMessage({
                id: placeholder,
                defaultMessage: placeholder,
              })
            : placeholder
        }
      />
    </Item>
  );
};

export default MoneyField;
