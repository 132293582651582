import React, { FC, useState, useEffect } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { removeUndefinedProperties } from '@services/utils';
import moment from 'moment';

import SpinContainer from '@components/SpinContainer';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import FormContainer from '@components/FormContainer';

import { Product, UserField } from '@apps/products/services';
import { initialTags } from '../SelectedTags';
import { initialProductGroup } from '../SelectedProductGroup';
import { initialUnit } from '../SelectedUnit';

import ProductsAppConfig, { PRODUCTS_ACTION } from '@apps/products';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';
import { Document } from '@apps/documents/service/namespace';
import { getDocumentsByIds } from '@apps/documents/service';
import { emitCustomEvent, useCustomEventListener } from '@core/services';
import createRelation from '@services/entityRelations/createRelation';
import { ListEvents } from '@components/List';
import LeftColumn from '@apps/products/mainMenu/components/Form/LeftColumn';
import RightColumn from '@apps/products/mainMenu/components/Form/RightColumn';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import UserFields from '@apps/products/mainMenu/components/Form/UserFields';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

const defaultValues: Partial<Product> = {
  isActive: true,
  ...initialTags,
  ...initialProductGroup,
  ...initialUnit,
};

export type FormProps = {
  id?: string;
  initialValues: Partial<Product>;
  onSubmit?: (product: Product, documentsToAdd: Document[] | undefined) => void;
  onCancel?: () => void;
  readOnly?: boolean;
  columns?: boolean;
  providedForm?: any;
  name?: string;
  shouldRedirect?: boolean;
  action: PRODUCTS_ACTION;
  canUpdate: boolean;
};

export type DocumentWithRelationFlag = Document & {
  relationCreated?: boolean;
};

export const Form: FC<FormProps> = ({
  id,
  initialValues: initialValuesProp,
  onSubmit,
  onCancel,
  name,
  readOnly = true,
  providedForm,
  shouldRedirect,
  action,
  canUpdate,
}) => {
  const { data: user } = useSelector(getUser);
  const [form] = useForm(providedForm);
  const [ecosystemId, setEcosystemId] = useState(initialValuesProp.ecosystem);
  const [isLoading, setIsLoading] = useState(false);
  const [relatedDocuments, setRelatedDocuments] = useState<
    DocumentWithRelationFlag[]
  >([]);

  useCustomEventListener<string, Document>(
    'relationCreated',
    (data?: Document) => {
      if (data) {
        setRelatedDocuments([
          ...relatedDocuments,
          {
            ...data,
            relationCreated: true,
          },
        ]);
      }
    },
  );

  useCustomEventListener<string, Document>(
    'relationToAdd',
    (data?: Document) => {
      if (data) {
        setRelatedDocuments([
          ...relatedDocuments,
          {
            ...data,
            relationCreated: false,
          },
        ]);
      }
    },
  );

  useCustomEventListener<string, Document>(
    'relationRemoved',
    (data?: Document) => {
      if (data) {
        setRelatedDocuments(
          relatedDocuments.filter((doc) => doc.id !== data.id),
        );
      }
    },
  );

  useCustomEventListener('documentRemoved', (data: any) => {
    if (data && data.id) {
      setRelatedDocuments(relatedDocuments.filter((doc) => doc.id !== data.id));
    }
  });

  useEffect(() => {
    if (initialValuesProp !== null && id) {
      setIsLoading(true);
      getChildrenForParent({
        ecosystem: initialValuesProp.ecosystem as string,
        parentId: id,
        childType: 'document',
      })
        .then((children) => {
          const childrenIds = children.map((child) => child.childId);
          getDocumentsByIds(childrenIds)
            .then((documents) => {
              setRelatedDocuments(documents);
              emitCustomEvent<string, Document[]>(
                'relatedDocumentsChanged',
                documents,
              );
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [initialValuesProp, id]);

  useCustomEventListener<string, Partial<Document>[]>(
    'documentsSaved',
    (docs) => {
      let incomingDocs: DocumentWithRelationFlag[] = [];
      const promises = docs?.map((doc) => {
        if (id && ecosystemId && doc?.ecosystem === ecosystemId) {
          return createRelation({
            parentId: id,
            parentType: 'product',
            parentRefCollection: 'products',
            childId: doc?.id as string,
            childType: 'document',
            childRefCollection: 'documents',
            ecosystem: ecosystemId,
          }).then(() => {
            incomingDocs = [
              ...incomingDocs,
              {
                ...(doc as Document),
                relationCreated: true,
              },
            ];
          });
        } else if (ecosystemId && doc?.ecosystem === ecosystemId) {
          incomingDocs = [
            ...incomingDocs,
            {
              ...(doc as Document),
              relationCreated: false,
            },
          ];
          return Promise.resolve();
        }
        return Promise.resolve();
      });

      if (promises) {
        Promise.all(promises).then(() => {
          const docs = [...relatedDocuments, ...incomingDocs];
          setRelatedDocuments(docs);
          emitCustomEvent<string, Document[]>('relatedDocumentsChanged', docs);
          emitCustomEvent<ListEvents>('refreshList');
        });
      }
    },
  );

  useCustomEventListener<string, Partial<Document>>('documentSaved', (doc) => {
    if (id && ecosystemId && doc?.ecosystem === ecosystemId) {
      createRelation({
        parentId: id,
        parentType: 'product',
        parentRefCollection: 'products',
        childId: doc?.id as string,
        childType: 'document',
        childRefCollection: 'documents',
        ecosystem: ecosystemId,
      }).then(() => {
        const docs = [
          ...relatedDocuments,
          {
            ...(doc as Document),
            relationCreated: true,
          },
        ];
        setRelatedDocuments(docs);
        emitCustomEvent<string, Document[]>('relatedDocumentsChanged', docs);
        emitCustomEvent<ListEvents>('refreshList');
      });
    } else if (ecosystemId && doc?.ecosystem === ecosystemId) {
      const docs = [
        ...relatedDocuments,
        {
          ...(doc as Document),
          relationCreated: false,
        },
      ];
      setRelatedDocuments(docs);
      emitCustomEvent<string, Document[]>('relatedDocumentsChanged', docs);
      emitCustomEvent<ListEvents>('refreshList');
    }
  });

  if (initialValuesProp === null) {
    return <SpinContainer />;
  }

  const initialValues = {
    ...defaultValues,
    ...initialValuesProp,
    creator: id ? initialValuesProp.creator || '' : user?.uid || '',
    assetUrl: initialValuesProp.assetUrl || '',
    assetFormat: initialValuesProp.assetFormat || '',
    productCreationDate: initialValuesProp.productCreationDate
      ? moment(initialValuesProp.productCreationDate, moment.defaultFormat)
      : moment(),
  };

  const canProceed = initialValuesProp
    ? checkUserPermissions(
        rootStore.getState(),
        ProductsAppConfig.todixId,
        'update-products' as PRODUCTS_ACTION,
        initialValuesProp?.ecosystem as string,
      )
    : false;

  return (
    <AppEntityForm
      elId={id}
      appId={ProductsAppConfig.todixId}
      autoComplete="off"
      initialValues={initialValues}
      name={name}
      providedForm={providedForm || form}
      onSubmit={(product) => {
        const filteredProduct = {
          ...product,
          userFields: product.userFields
            ? product.userFields.map((field: UserField) =>
                removeUndefinedProperties(field),
              )
            : [],
        };
        if (onSubmit) {
          onSubmit(
            filteredProduct,
            relatedDocuments.filter((doc) => !doc.relationCreated),
          );
        }
      }}
      onValuesChange={(values: any) => {
        if (values && values.ecosystem !== ecosystemId) {
          setEcosystemId(values.ecosystem);
        }
      }}
      readOnly={readOnly || !canProceed || !canUpdate}
      onCancel={onCancel}
      shouldRedirect={shouldRedirect}
      disabledEditButton={!canProceed || !canUpdate}
      disabledSubmitButton={!canProceed || !canUpdate}
    >
      {() => {
        return (
          <>
            <FormContainer>
              <LeftColumn
                readOnly={readOnly || !canProceed || !canUpdate}
                ecosystemId={ecosystemId}
                form={form}
              />
              <RightColumn
                readOnly={readOnly || !canProceed || !canUpdate}
                ecosystemId={ecosystemId}
                form={form}
                isLoading={isLoading}
                relatedDocuments={relatedDocuments}
                action={action}
              />
            </FormContainer>
            <UserFields
              ecosystemId={ecosystemId}
              form={form}
              readOnly={readOnly || !canProceed || !canUpdate}
            />
          </>
        );
      }}
    </AppEntityForm>
  );
};
