import { connect } from 'react-redux';
import EcosystemAvatar, { EcosystemAvatarProps } from './EcosystemAvatar';
import { getEcosystemById } from '../EcosystemIndicator/store/selectors';
import { RootStore } from '@core/store';

type Props = EcosystemAvatarProps & {
  id: string;
};

const mapState = (state: RootStore, props: Props) => {
  const ecosystem = getEcosystemById(props.id)(state);
  return {
    name: ecosystem?.name,
    avatarUrl: ecosystem?.avatarUrl,
  };
};

export default connect(mapState)(EcosystemAvatar);
