import React, { FC } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Create from '@apps/warehouse/mainMenu/routes/Create';
import View from '@apps/warehouse/mainMenu/routes/View';

const Warehouse: FC<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`}>
        <Create />
      </Route>
      <Route path={`${path}`}>
        <View />
      </Route>
    </Switch>
  );
};

export default Warehouse;
