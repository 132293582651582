import * as React from 'react';
import { Col, Form, Row, Space, Select, Input, Button } from 'antd';
import { Radio } from '@components/FormFields';
import { Text } from '@todix/ui-components';
import { Rule } from 'antd/lib/form';
import { LabeledValue, SelectProps } from 'antd/lib/select';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';
import { DeleteOutlined } from '@ant-design/icons';
import { MaxItem } from '@apps/contacts/mainMenu/components/DetailContactForm/DetailContactForm.sc';

type Props = {
  label: string;
  options: SelectProps<string>['options'];
  arrayName: string;
  firstInputNameField: string;
  firstInputPlaceholder?: string;
  firstInputRules?: Rule[];
  secondInputNameField: string;
  secondInputPlaceholder?: string;
  secondInputRules?: Rule[];
  addNextButtonLabel: string;
  standardFieldName: string;
  defaultValue?: LabeledValue & {
    standard?: boolean;
  };
  shouldAddEntry?: boolean;
  hideButton?: boolean;
  addEntryRef?: any;
};

const ArrayOfFields: React.FC<Props> = ({
  label,
  options,
  arrayName,
  firstInputNameField,
  firstInputRules,
  firstInputPlaceholder,
  secondInputNameField,
  secondInputRules,
  secondInputPlaceholder,
  addNextButtonLabel,
  standardFieldName,
  defaultValue = {},
  shouldAddEntry = false,
  hideButton = false,
  addEntryRef,
}) => {
  const { getFieldValue, setFields, readOnly } = useFormContext();
  const [checkedValue, setCheckedValue] = React.useState(
    getFieldValue(standardFieldName),
  );

  return (
    <>
      {/* This form item is needed to see values in form*/}
      <Form.Item name={standardFieldName} hidden={true} />
      <Form.List name={arrayName}>
        {(fields, { add, remove }) => {
          const addEntry = () => {
            // first item will be added
            const standard = fields.length === 0;
            add({
              ...defaultValue,
              standard,
            });
            if (standard) {
              setFields([
                {
                  name: standardFieldName,
                  value: 0,
                },
              ]);
              setCheckedValue(0);
            }
          };

          if (shouldAddEntry && fields.length === 0) {
            addEntry();
          }

          if (addEntryRef) {
            addEntryRef.current = addEntry;
          }
          return (
            <>
              {(fields as any).map(({ key, ...field }: any, index: any) => (
                <>
                  <MaxItem key={key} label={`${label}${index + 1}`}>
                    <Row
                      justify="space-between"
                      gutter={[8, 8]}
                      style={{ maxWidth: '90%' }}
                    >
                      <Col span="12">
                        <Form.Item
                          {...field}
                          noStyle
                          name={[field.name, firstInputNameField]}
                          fieldKey={[field.fieldKey, firstInputNameField]}
                          rules={firstInputRules}
                        >
                          <Select
                            disabled={readOnly}
                            placeholder={firstInputPlaceholder}
                            options={options}
                          />
                        </Form.Item>
                      </Col>
                      <Col span="12">
                        <Form.Item
                          {...field}
                          noStyle
                          name={[field.name, secondInputNameField]}
                          fieldKey={[field.fieldKey, secondInputNameField]}
                          rules={secondInputRules}
                        >
                          <Input
                            readOnly={readOnly}
                            autoComplete="nope"
                            placeholder={secondInputPlaceholder}
                          />
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item
                          name={[field.name, 'standard']}
                          fieldKey={[field.fieldKey, 'standard']}
                          noStyle
                        >
                          <Space size="small" direction="horizontal">
                            <Radio
                              disabled={readOnly}
                              name={standardFieldName}
                              checked={checkedValue === index}
                              onChange={(_e) => {
                                setFields([
                                  {
                                    name: standardFieldName,
                                    value: index,
                                  },
                                ]);
                                setCheckedValue(index);
                              }}
                            />
                            <Text level={2}>
                              <FormattedMessage
                                id="detailcontactform.dynamicfieldtwoinputs.text"
                                defaultMessage="Standard"
                              />
                            </Text>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                    {!readOnly && (
                      <DeleteOutlined
                        style={{ position: 'absolute', right: 0, top: 0 }}
                        onClick={() => {
                          remove(field.name);
                          if (checkedValue === index) {
                            setFields([
                              {
                                name: standardFieldName,
                                value: index >= 1 ? index - 1 : 0,
                              },
                            ]);
                            setCheckedValue(index >= 1 ? index - 1 : 0);
                          }
                        }}
                      />
                    )}
                  </MaxItem>
                </>
              ))}
              {!readOnly && !hideButton && (
                <Form.Item>
                  <Button size="small" type="primary" onClick={addEntry} block>
                    {addNextButtonLabel}
                  </Button>
                </Form.Item>
              )}
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default ArrayOfFields;
