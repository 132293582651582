import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import {
  StyledEditIcon,
  StyledSwitch,
  StyledTable,
} from '@pages/settings/components';
import { FormattedMessage } from 'react-intl';
import { styled } from '@styles/themes';
import { Button, Card, Tooltip } from 'antd';
import { useParams } from 'react-router-dom';
import { ColumnType } from 'antd/lib/table';
import { UseParamsType } from '@pages/settings';
import {
  ConsultantLevelType,
  getConsultantLevels,
  updateConsultantLevel,
} from '@apps/directSales/services/consultantLevel';
import ConsultantLevelModal from '@apps/directSales/settings/ConsultantLevel/ConsultantLevelModal';

const StyledButton = styled(Button)`
  && {
    flex-direction: row-reverse;

    .anticon {
      margin-left: 4px;
    }
  }
`;

const ConsultantLevels: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [consultantLevel, setConsultantLevel] = useState<ConsultantLevelType[]>(
    [],
  );
  const [selectedConsultantLevels, setSelectedConsultantLevels] =
    useState<ConsultantLevelType | null>(null);

  useEffect(() => {
    if (uid) {
      setLoading(true);
      getConsultantLevels(uid)
        ?.then(setConsultantLevel)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  const handleLevelChange = useCallback(
    async (level: ConsultantLevelType) => {
      const { id } = level;
      if (id) {
        setLoading(true);
        await updateConsultantLevel(id, level);
        setConsultantLevel(
          consultantLevel.map((item) => {
            if (item.id === id) {
              return level;
            }
            return item;
          }),
        );
        setLoading(false);
      }
    },
    [consultantLevel],
  );

  const columns: ColumnType<any>[] = useMemo(
    () => [
      {
        title: (
          <FormattedMessage
            id="consultants.level.title"
            defaultMessage="Title"
          />
        ),
        sorter: true,
        dataIndex: 'level',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="directSales.createNew.shortage.label"
            defaultMessage="Shortage"
          />
        ),
        sorter: true,
        dataIndex: 'shortage',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="sales.createNew.longName.label"
            defaultMessage="Long name"
          />
        ),
        sorter: true,
        dataIndex: 'longName',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="sales.createNew.category.label"
            defaultMessage="Category"
          />
        ),
        sorter: true,
        dataIndex: 'category',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="sales.createNew.description.label"
            defaultMessage="Description"
          />
        ),
        sorter: true,
        dataIndex: 'description',
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: ConsultantLevelType) => (
          <Tooltip
            title={
              <FormattedMessage
                id="app.sales.settings.isActive.tooltip"
                defaultMessage="app.sales.settings.isActive.tooltip"
              />
            }
          >
            <StyledSwitch
              key={record.id}
              checked={record.isActive}
              onChange={() =>
                handleLevelChange({
                  ...record,
                  isActive: !record.isActive,
                } as ConsultantLevelType)
              }
              checkedChildren={activated}
              unCheckedChildren={deactivated}
            />
          </Tooltip>
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: ConsultantLevelType) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedConsultantLevels(record);
                setVisible(true);
              }}
            />
          </>
        ),
      },
    ],
    [activated, deactivated, handleLevelChange],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const onOk = useCallback(() => {
    setVisible(false);
    if (uid) {
      setLoading(true);
      getConsultantLevels(uid)
        ?.then(setConsultantLevel)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [uid]);

  // @ts-ignore
  return (
    <Card
      title={
        <FormattedMessage
          id="consultants.consultant.levels"
          defaultMessage="Consultant Levels"
        />
      }
      extra={
        <StyledButton
          type="primary"
          size="small"
          onClick={() => setVisible(true)}
        >
          <FormattedMessage
            id="consultants.settings.consultant.level.createNewButton"
            defaultMessage="Create new consultant level"
          />
        </StyledButton>
      }
    >
      <StyledTable
        dataSource={consultantLevel}
        columns={columns}
        bordered
        tableLayout="auto"
        loading={loading}
        pagination={false}
        rowKey="id"
      />
      <ConsultantLevelModal
        level={selectedConsultantLevels}
        visible={visible}
        ecosystemId={uid}
        onCancel={onCancel}
        onOk={onOk}
      />
    </Card>
  );
};

export default ConsultantLevels;
