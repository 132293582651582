import * as React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { Wrapper, PageTitle, CountTag } from './BreadcrumbsNavigation.sc';

type Props = {
  children?: React.ReactNode;
  items: string[][];
  style?: object;
  rowsCount?: number;
};

const BreadcrumbsNavigation: React.FC<Props> = ({
  children,
  items,
  style,
  rowsCount,
}) => {
  return (
    <Wrapper style={style}>
      {children && <PageTitle level={3}>{children}</PageTitle>}
      <Breadcrumb separator=">">
        {items.map((item) => (
          <Breadcrumb.Item key={item[0]}>
            <Link to={item[0]}>{item[1]}</Link>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      {items.length < 2 &&
      items.length > 0 &&
      rowsCount !== undefined &&
      rowsCount >= 0 ? (
        <CountTag color="purple">{rowsCount}</CountTag>
      ) : undefined}
    </Wrapper>
  );
};

export default BreadcrumbsNavigation;
