import React, { FC } from 'react';
import { Form, Input, InputProps, FormItemProps } from 'antd';

const { Item } = Form;

type Props = InputProps & {
  formItemProps: FormItemProps;
};

const InputField: FC<Props> = ({ formItemProps, ...inputProps }) => {
  return (
    <Item {...formItemProps} onReset={formItemProps.onReset as any}>
      <Input {...inputProps} />
    </Item>
  );
};
export default InputField;
