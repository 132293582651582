import React, { FC, useCallback } from 'react';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { useForm } from 'antd/lib/form/Form';
import AppConfig, { PURCHASES_ACTION } from '@apps/purchases';
import {
  AppEntityForm,
  ON_FINISH_FAILED_EVENT,
  OnFinishFailedEvent,
} from '@components/AppEntityForm/AppEntityForm';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { emitCustomEvent } from '@core/services';
import FormContainer from '@components/FormContainer';
import LeftColumn from '@apps/purchases/mainMenu/components/individualRequest/LeftColumn';
import RightColumn from '@apps/purchases/mainMenu/components/individualRequest/RightColumn';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import moment from 'moment/moment';

export type IndividualRequestFormProps = {
  id?: string;
  initialValues: Partial<IndividualPurchaseRequest>;
  onSubmit: (individualRequest: IndividualPurchaseRequest) => void;
  onCancel?: () => void;
  readOnly?: boolean;
  shouldRedirect?: boolean;
  action: PURCHASES_ACTION;
};

const IndividualRequestForm: FC<IndividualRequestFormProps> = ({
  id,
  initialValues: initialValuesProp,
  onSubmit,
  readOnly = true,
  shouldRedirect = true,
  onCancel,
  action,
}) => {
  const [form] = useForm();
  const { data: user } = useSelector(getUser);
  const initialValues = {
    ...initialValuesProp,
    creator: id ? initialValuesProp.creator || '' : user || '',
    creationDate: initialValuesProp.creationDate
      ? moment(initialValuesProp.creationDate, moment.defaultFormat)
      : moment(),
  };

  const onFinishFailed = useCallback((errorInfo: ValidateErrorEntity) => {
    emitCustomEvent(ON_FINISH_FAILED_EVENT, {
      errorInfo,
    } as OnFinishFailedEvent);
  }, []);

  const handleSubmit = useCallback(
    (values: IndividualPurchaseRequest) => {
      if (user) {
        const payload: IndividualPurchaseRequest = {
          ...values,
          creator: user,
          toBeStocked: !!values.toBeStocked,
        };
        onSubmit(payload);
      }
    },
    [onSubmit, user],
  );

  return (
    <AppEntityForm
      elId={id}
      autoComplete="off"
      initialValues={initialValues}
      appId={AppConfig.todixId}
      cancelLink={`/app/${AppConfig.todixId}/purchase_request`}
      name="individualRequest"
      onSubmit={handleSubmit}
      onFinishFailed={onFinishFailed}
      onCancel={onCancel}
      readOnly={readOnly}
      shouldRedirect={shouldRedirect}
      providedForm={form}
      disabledEditButton={readOnly}
      disabledSubmitButton={readOnly}
    >
      {() => {
        return (
          <FormContainer>
            <LeftColumn
              readOnly={readOnly}
              ecosystemId={initialValues?.ecosystem}
            />
            <RightColumn readOnly={readOnly} action={action} />
          </FormContainer>
        );
      }}
    </AppEntityForm>
  );
};

export default IndividualRequestForm;
