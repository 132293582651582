import { Icon } from '@todix/ui-components';
import { get, put } from '../api';
import { RegisteredFileApp } from '@apps/AppTypes';

export const DashboardRegisteredFileApp = {
  appId: 'dashboard',
  link: '/',
  name: 'Dashboard',
  collectionName: 'dashboard',
  entityType: 'dashboard',
  hidden: false,
  todixId: 'dashboard',
  mainMenu: {
    icon: Icon.DashboardOutlined,
    appSubmenuName: 'Dashboard',
    title: 'Dashboard',
  },
} as unknown as RegisteredFileApp;

export const mockedApplications: ApplicationEntry[] = [
  {
    id: '0',
    version: '1.0',
    name: 'Dashboard',
    imageUrl: Icon.DashboardOutlined,
    contextName: 'dashboard',
    ecosystemIds: ['1', '2', '3', '4'],
  },
  {
    id: '1',
    version: '1.0',
    name: 'Contacts',
    imageUrl: Icon.TeamOutlined,
    contextName: 'contacts',
    ecosystemIds: ['1', '2', '3', '4'],
    createButtonAction: '/contacts/create',
  },
  {
    id: '2',
    version: '1.0',
    name: 'Documents',
    imageUrl: Icon.FileOutlined,
    contextName: 'documents',
    ecosystemIds: ['1', '2', '3'],
    createButtonAction: '/documents/create',
    submenu: [
      {
        title: 'Inbox',
        link: '/documents/inbox',
        imageUrl: Icon.UploadOutlined,
      },
      {
        title: 'Archive',
        link: '/documents',
        imageUrl: Icon.FolderOpenOutlined,
      },
    ],
  },
  {
    id: '5',
    version: '1.0',
    name: 'Products',
    imageUrl: Icon.ShoppingOutlined,
    contextName: 'products',
    ecosystemIds: ['1'],
    createButtonAction: '/products/create',
  },
];

export const getApplications = (): ApplicationEntry[] => {
  return mockedApplications;
};

type GetFavAppsIds = () => Promise<string[]>;

export const getFavAppsIds: GetFavAppsIds = () =>
  // @ts-ignore
  get('platform/favApplications')?.then((data) =>
    data ? Object.values(data) : [],
  );

type PutFavAppsIds = (ids: string[]) => Promise<void>;

export const putFavAppsIds: PutFavAppsIds = (ids) =>
  // @ts-ignore
  put(
    'platform/favApplications',
    ids.reduce(
      (prev, curr, index) => ({
        ...prev,
        [index]: curr,
      }),
      {},
    ),
  );
