import { RangeOfNumbersType } from '@services/rangeOfNumbers';
import { ApiOptions, put } from '@services/api';
import { removeUndefinedProperties } from '@services/utils';
import { COLLECTION_NAME } from '@apps/purchases/services/rangeOfNumbers/namespace';

const addPurchasesRangeOfNumbers = (
  rangeOfNumbers: RangeOfNumbersType,
  id: string,
  options?: ApiOptions,
) =>
  put(
    `${COLLECTION_NAME}/${id}`,
    removeUndefinedProperties(rangeOfNumbers),
    options,
  );

export default addPurchasesRangeOfNumbers;
