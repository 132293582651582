import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import {
  ENTITY_RELATIONS_COLLECTION,
  EntityRelation,
} from '@services/entityRelations/namespace';

type GetAnyRelationsPayload = {
  key: keyof EntityRelation;
  value: string;
};

const getAnyRelations = ({ key, value }: GetAnyRelationsPayload) => {
  return firebase.firestore
    ?.collection(ENTITY_RELATIONS_COLLECTION)
    .where(key, '==', value)
    .get()
    .then(getArrayWithIds) as Promise<EntityRelation[]>;
};

export default getAnyRelations;
