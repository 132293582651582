import * as React from 'react';
import { Mentions as AntdMentions, Tag } from 'antd';
import { MentionProps as AntdMentionsProps } from 'antd/lib/mentions';
import { Label } from '@components/FormFields';

type MentionProps = Omit<AntdMentionsProps, 'value'> & {
  label?: React.ReactNode;
  value?: string[];
  customTagRenderer?(value: string, index: number): React.ReactNode;
  setValue(value: string[]): void;
};

const MentionsWithTags: React.FC<MentionProps> = (props: MentionProps) => {
  const [mentions, setMentions] = React.useState('');

  const {
    label,
    id,
    name,
    value,
    customTagRenderer,
    onChange,
    setValue,
    ...restProps
  } = props;

  return (
    <>
      <div>
        {label && <Label htmlFor={id}>{label}</Label>}
        <AntdMentions
          value={mentions}
          onChange={(text) => {
            setMentions(text);
          }}
          onSelect={(option) => {
            setValue(
              value && option.value
                ? value.concat(option.value as string)
                : [option.value as string],
            );
            setMentions('');
          }}
          onBlur={(e) => e.persist()}
          split={','}
          {...restProps}
          style={{ marginBottom: 20 }}
        />
        {value
          ? value.map((v, i) => {
              const el = customTagRenderer ? customTagRenderer(v, i) : v;
              return (
                <Tag
                  id={v}
                  key={v + i}
                  closable
                  onClose={() => {
                    setValue(value?.filter((item) => item !== v));
                  }}
                  style={{ border: 0, background: 'transparent' }}
                >
                  {el}
                </Tag>
              );
            })
          : null}
      </div>
    </>
  );
};

export default MentionsWithTags;
