import React, { FC, useEffect, useMemo, useCallback } from 'react';
import { Form, Input, Modal } from 'antd';
import intl from '../../../../../intl';
import {
  DeliveryTermsType,
  postSalesDeliveryTerms,
  updateSalesDeliveryTerms,
} from '@apps/sales/services/salesDeliveryTerms';
import { StyledSwitch } from '@pages/settings/components';
import { FormattedMessage } from 'react-intl';

const { Item } = Form;
const { TextArea } = Input;

type Props = {
  ecosystemId: string;
  visible: boolean;
  terms: DeliveryTermsType | null;
  onCancel(): void;
  onOk(): void;
};

const DeliveryTermsModal: FC<Props> = ({
  ecosystemId,
  visible,
  terms = {},
  onOk,
  onCancel,
}) => {
  const [form] = Form.useForm<DeliveryTermsType>();

  const okText = useMemo(() => {
    if (terms && terms.id) {
      return intl.formatMessage({
        id: 'button.save',
        defaultMessage: 'Save',
      });
    }
    return intl.formatMessage({
      id: 'button.create',
      defaultMessage: 'Create',
    });
  }, [terms]);

  const cancelText = useMemo(() => {
    return intl.formatMessage({
      id: 'button.cancel',
      defaultMessage: 'Cancel',
    });
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [form, visible]);

  const handleOk = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        if (terms && terms.id) {
          updateSalesDeliveryTerms(terms.id, { ...terms, ...values })?.then(
            () => {
              form.resetFields();
              onOk();
            },
          );
        } else {
          postSalesDeliveryTerms({
            ...values,
            ecosystemId,
          })?.then(() => {
            form.resetFields();
            onOk();
          });
        }
      })
      .catch((info) => {
        console.warn('Validation failed: ', info);
      });
  }, [ecosystemId, form, onOk, terms]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  return (
    <Modal
      visible={visible}
      okText={okText}
      cancelText={cancelText}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={terms || {}}
      >
        <Item
          label={intl.formatMessage({
            id: 'sales.createNew.shortage.label',
            defaultMessage: 'Shortage',
          })}
          name="shortage"
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'sales.createNew.shortage.label',
              defaultMessage: 'Shortage',
            })}
          />
        </Item>
        <Item
          label={intl.formatMessage({
            id: 'sales.createNew.longName.label',
            defaultMessage: 'Long name',
          })}
          name="longName"
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'sales.createNew.longName.label',
              defaultMessage: 'Long name',
            })}
          />
        </Item>
        <Item
          label={intl.formatMessage({
            id: 'sales.createNew.category.label',
            defaultMessage: 'Category',
          })}
          name="category"
        >
          <Input
            placeholder={intl.formatMessage({
              id: 'sales.createNew.category.label',
              defaultMessage: 'Category',
            })}
          />
        </Item>
        <Item
          label={intl.formatMessage({
            id: 'sales.createNew.description.label',
            defaultMessage: 'Description',
          })}
          name="description"
        >
          <TextArea
            maxLength={250}
            placeholder={intl.formatMessage({
              id: 'sales.createNew.description.label',
              defaultMessage: 'Description',
            })}
          />
        </Item>
        <Item noStyle shouldUpdate={true}>
          {({ getFieldValue, setFieldsValue }) => {
            const isActive = getFieldValue('isActive');
            return (
              <Item
                label={intl.formatMessage({
                  id: 'sales.createNew.active.label',
                  defaultMessage: 'Active',
                })}
                name="isActive"
              >
                <StyledSwitch
                  onChange={(value) => setFieldsValue({ isActive: value })}
                  checkedChildren={activated}
                  unCheckedChildren={deactivated}
                  defaultChecked={isActive}
                />
              </Item>
            );
          }}
        </Item>
      </Form>
    </Modal>
  );
};

export default DeliveryTermsModal;
