import React, { FC } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

type ProductSelectorProps = SelectProps & {
  selectedEcosystem: string | null;
  onProductSelect?(id: any, rowIndex: number): void;
  selectedProductId?: string;
  rowIndex: number;
  prodOptions: any[];
};

const ProductSelector: FC<ProductSelectorProps> = ({
  onProductSelect,
  selectedEcosystem,
  prodOptions,
  rowIndex,
  selectedProductId,
  ...rest
}) => {
  return (
    <Select
      showSearch={true}
      className="animate__animated animate__slideInLeft animate__faster"
      onSelect={(id) => onProductSelect && onProductSelect(id, rowIndex)}
      filterOption={(inputValue, option) => {
        return option
          ? option['data-forsearch']
              .toLowerCase()
              .includes(inputValue.toLowerCase())
          : false;
      }}
      disabled={!selectedEcosystem}
      options={prodOptions}
      value={selectedProductId}
      {...rest}
    />
  );
};

export default ProductSelector;
