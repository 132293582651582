import moment from 'moment';
import { patch } from '@services/api';
import {
  PRODUCT_PURCHASE_PRICES,
  ProductPurchasePrice,
} from '@apps/products/services/namespace';

const updateProductPurchasePrice = (id: string, price: ProductPurchasePrice) =>
  patch(`${PRODUCT_PURCHASE_PRICES}/${id}`, {
    ...price,
    timestamp: moment().valueOf(),
  });

export default updateProductPurchasePrice;
