export enum EcosystemActionsTypes {
  SET_ECOSYSTEMS = 'SET_ECOSYSTEMS',
  SET_ECOSYSTEM_AVAILABILITY = 'SET_ECOSYSTEM_AVAILABILITY',
  UPDATE_ECOSYSTEM = 'UPDATE_ECOSYSTEM',
  REMOVE_ECOSYSTEM = 'REMOVE_ECOSYSTEM',
}

export const removeEcosystem = (payload: string) => ({
  type: EcosystemActionsTypes.REMOVE_ECOSYSTEM,
  payload,
});

export const updateEcosystem = (payload: Ecosystem) => ({
  type: EcosystemActionsTypes.UPDATE_ECOSYSTEM,
  payload,
});

export const setEcosystems = (payload: Ecosystem[]) => ({
  type: EcosystemActionsTypes.SET_ECOSYSTEMS,
  payload,
});

type EcosystemAvailabilityPayload = {
  ecosystemId: string;
  isActive: boolean;
};

export const setEcosystemAvailability = (
  payload: EcosystemAvailabilityPayload,
) =>
  ({
    type: EcosystemActionsTypes.SET_ECOSYSTEM_AVAILABILITY,
    payload,
  } as const);

export type SetEcosystemAvailability = ReturnType<
  typeof setEcosystemAvailability
>;
export type SetEcosystems = ReturnType<typeof setEcosystems>;

export type UpdateEcosystem = ReturnType<typeof updateEcosystem>;

export type RemoveEcosystem = ReturnType<typeof removeEcosystem>;

export type EcosystemActions =
  | SetEcosystemAvailability
  | SetEcosystems
  | UpdateEcosystem
  | RemoveEcosystem;
