import React, { FC, lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const View = lazy(
  () => import(/* webpackChunkName: "productsApp" */ './routes/view'),
);

const Create = lazy(
  () => import(/* webpackChunkName: "productsApp" */ './routes/create'),
);

const Edit = lazy(
  () => import(/* webpackChunkName: "productsApp" */ './routes/edit'),
);

const Preview = lazy(
  () => import(/* webpackChunkName: "productsApp" */ './routes/preview'),
);

const Products: FC<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create`}>
        <Create />
      </Route>
      <Route path={`${path}/:id/edit`}>
        <Edit />
      </Route>
      <Route path={`${path}/:id`}>
        <Preview />
      </Route>
      <Route path={`${path}`}>
        <View />
      </Route>
    </Switch>
  );
};

export default Products;
