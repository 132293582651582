import {
  WAREHOUSE_STORAGE,
  WarehouseStorage,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';

const fetchWarehouseStoragesForEcosystem = (ecosystemId: string) => {
  return firebase.db
    ?.ref(`${WAREHOUSE_STORAGE}/${ecosystemId}`)
    .once('value')
    .then((results) => {
      if (!results.exists()) {
        return [];
      }

      const arrResults = Object.entries(results.val() as object);
      return arrResults.map((pair) => {
        return {
          ...pair[1],
          id: pair[0],
        };
      }) as WarehouseStorage[];
    });
};

export default fetchWarehouseStoragesForEcosystem;
