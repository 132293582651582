import React, { CSSProperties, FC } from 'react';
import { Style } from '@react-pdf/types';
import { Text, View, Image } from '@react-pdf/renderer';

type LogoWithNameProps = {
  name?: string;
  url?: string;
  style?: Style;
  forPdf: boolean;
};

const LogoWithName: FC<LogoWithNameProps> = ({ style, name, url, forPdf }) => {
  return forPdf ? (
    <View style={style}>
      {name && <Text>{name}</Text>}
      {url && <Image src={url} style={{ width: '20mm', height: 'auto' }} />}
    </View>
  ) : (
    <div style={style as CSSProperties}>
      <div>{name || ''}</div>
      {url && (
        <img alt="logo" src={url} style={{ width: '20mm', height: 'auto' }} />
      )}
    </div>
  );
};

export default LogoWithName;
