import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Form } from 'antd';

import { RootStore } from '@core/store';
import { Select } from '@components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';
import { getActiveProductGroups } from '@core/store/productGroups/selectors';

const { Item } = Form;
const { Option } = Select;

const mapStateToProps = (state: RootStore) => ({
  productGroups: getActiveProductGroups(state),
});

export type StateProps = ReturnType<typeof mapStateToProps>;

export type ProductGroupsType = {
  form: any;
  namePrefix?: string | number;
};

const ProductGroups: FC<StateProps & ProductGroupsType> = ({
  productGroups,
  namePrefix,
}) => {
  const { readOnly } = useFormContext();
  return (
    <Item noStyle shouldUpdate={true}>
      {(props) => {
        const ecosystem = props.getFieldValue('ecosystem');
        return (
          <Item
            name={
              namePrefix !== undefined
                ? [namePrefix, `productGroup`]
                : `productGroup`
            }
            dependencies={['ecosystem']}
            label={
              <FormattedMessage
                id="products.selectedproductgroup.label"
                defaultMessage="Product group"
              />
            }
          >
            <Select
              disabled={readOnly}
              allowClear
              optionLabelProp="label"
              name="productGroup"
              placeholder={
                <FormattedMessage
                  id="products.selectedproductgroup.placeholder"
                  defaultMessage="Select product group..."
                />
              }
            >
              {productGroups
                .filter(
                  (productGroup) => productGroup.ecosystemId === ecosystem,
                )
                .map(({ id, name }) => (
                  <Option key={id} value={name} label={name}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Item>
        );
      }}
    </Item>
  );
};

export default connect(mapStateToProps)(ProductGroups);
