import { deleteRequest, get, patch, put } from '../api';
import { firebase } from '@services/Firebase';
import { Unit, UnitGroup, UnitGroups } from '@services/unitGroups/namespace';
import { firestore } from 'firebase';
import * as firebaseCore from 'firebase/app';

const COLLECTION_NAME = 'unitGroups';

export const createUnitGroups = (ecosystemId: string) => {
  return put(`${COLLECTION_NAME}/${ecosystemId}`, { ecosystemId, groups: [] });
};

export const addUnitGroup = (unitGroup: UnitGroups, ecosystemId: string) => {
  let unitGroups = firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(ecosystemId);

  if (!unitGroups) {
    return null;
  }

  return unitGroups.update({
    groups: firebaseCore.firestore.FieldValue.arrayUnion(unitGroup),
  });
};

export const deleteUnitGroup = (
  unitGroups: UnitGroups,
  unitGroupName: string,
  ecosystemId: string,
) => {
  let unitGroupsDoc = firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(ecosystemId);

  if (!unitGroupsDoc) {
    return null;
  }

  return unitGroupsDoc.update({
    groups: unitGroups.groups.filter(
      (unitGroup) => unitGroup.name !== unitGroupName,
    ),
  });
};

export const updateUnitGroupName = (
  newUnitGroupName: string,
  unitGroups: UnitGroups,
  unitGroupName: string,
  ecosystemId: string,
) => {
  let unitGroupsDoc = firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(ecosystemId);

  if (!unitGroupsDoc) {
    return null;
  }

  return unitGroupsDoc.update({
    groups: unitGroups.groups.map((unitGroup) => {
      if (unitGroup.name === unitGroupName) {
        return {
          ...unitGroup,
          name: newUnitGroupName,
        };
      }
      return unitGroup;
    }),
  });
};

export const addUnit = (
  unit: Unit,
  ecosystemId: string,
  unitGroups: UnitGroups,
  unitGroup: UnitGroup,
) => {
  let unitGroupsDoc = firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(ecosystemId);

  if (!unitGroupsDoc) {
    return null;
  }

  const newGroups = unitGroups.groups.map((el) => {
    // find unitGroup to be extended
    if (el.name === unitGroup.name) {
      // if already exists
      if (el.units.find((elUnit) => elUnit.name === unit.name)) {
        return el;
      }
      // add new one
      return {
        ...el,
        units: [...el.units, unit],
      };
    }
    // return other unitGroups without change
    else {
      return el;
    }
  });

  return unitGroupsDoc.set({
    ...unitGroups,
    groups: newGroups,
  });
};

export const updateUnit = (
  newUnit: Unit,
  updatedUnitName: string,
  ecosystemId: string,
  unitGroups: UnitGroups,
  unitGroup: UnitGroup,
) => {
  let unitGroupsDoc = firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(ecosystemId);

  if (!unitGroupsDoc) {
    return null;
  }

  const newGroups = unitGroups.groups.map((el) => {
    // find unitGroup to be extended
    if (el.name === unitGroup.name) {
      // add new one
      return {
        ...el,
        units: el.units.map((elUnit) => {
          if (elUnit.name === updatedUnitName) {
            return newUnit;
          } else {
            return elUnit;
          }
        }),
      };
    }
    // return other unitGroups without change
    else {
      return el;
    }
  });

  return unitGroupsDoc.set({
    ...unitGroups,
    groups: newGroups,
  });
};

export const deleteUnit = (
  updatedUnitName: string,
  ecosystemId: string,
  unitGroups: UnitGroups,
  unitGroup: UnitGroup,
) => {
  let unitGroupsDoc = firebase.firestore
    ?.collection(COLLECTION_NAME)
    .doc(ecosystemId);

  if (!unitGroupsDoc) {
    return null;
  }

  const newGroups = unitGroups.groups.map((el) => {
    // find unitGroup to be extended
    if (el.name === unitGroup.name) {
      // add new one
      return {
        ...el,
        units: el.units.filter((elUnit) => elUnit.name !== updatedUnitName),
      };
    }
    // return other unitGroups without change
    else {
      return el;
    }
  });

  return unitGroupsDoc.set({
    ...unitGroups,
    groups: newGroups,
  });
};

export const updateUnitGroups = (unitGroups: UnitGroups) => {
  const data = { ...unitGroups };
  // @ts-ignore
  delete data.ecosystemId;
  return patch(`${COLLECTION_NAME}/${unitGroups.ecosystemId}`, data);
};

export const deleteUnitGroups = (unitId: string) => {
  return deleteRequest(`${COLLECTION_NAME}/${unitId}`);
};

export const getEcosystemUnitGroups = (ecosystemId: string) => {
  return (get(`${COLLECTION_NAME}/${ecosystemId}`) as any).then(
    (data: any) => ({
      ...data,
      ecosystemId,
    }),
  );
};

export const getMultipleEcosystemUnitGroups = (uids: string[]) => {
  return uids.length
    ? firebase.firestore
        ?.collection(COLLECTION_NAME)
        .where(firestore.FieldPath.documentId(), 'in', uids)
        .get()
        .then((querySnapshot) => {
          const docs: object[] = [];
          // querySnapshot do not support map
          querySnapshot.forEach((doc) => {
            docs.push({
              ...doc.data(),
              ecosystemId: doc.id,
            });
          });

          return docs as any[];
        })
    : Promise.resolve([]);
};
