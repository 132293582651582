import React, { FC } from 'react';
import { ReactImageGalleryItem } from 'react-image-gallery';
import { FilePdfTwoTone } from '@ant-design/icons';

type Props = {
  element: ReactImageGalleryItem;
};

const PDFThumbnail: FC<Props> = ({ element }) => {
  return element.thumbnail !== 'placeholder' ? (
    <span className="image-gallery-thumbnail-inner">
      <img
        className="image-gallery-thumbnail-image"
        src={element.thumbnail}
        height={element.thumbnailHeight}
        width={element.thumbnailWidth}
        alt={element.thumbnailAlt}
        title={element.thumbnailTitle}
        loading={element.thumbnailLoading}
      />
    </span>
  ) : (
    <span className="image-gallery-thumbnail-inner">
      <FilePdfTwoTone style={{ fontSize: '32px' }} />
    </span>
  );
};

export default PDFThumbnail;
