import {
  WAREHOUSE_PRODUCTS_STATUS,
  WarehouseStorageStatus,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';

type Payload = {
  ecosystem: string;
  storageId: string;
  productId: string;
};

const fetchProductStats = ({ ecosystem, storageId, productId }: Payload) => {
  return firebase.db
    ?.ref(`${WAREHOUSE_PRODUCTS_STATUS}/${ecosystem}/${storageId}/${productId}`)
    .once('value')
    .then((results) => {
      if (!results.exists()) {
        return Promise.resolve({} as WarehouseStorageStatus);
      }

      return results.val() as WarehouseStorageStatus;
    });
};

export default fetchProductStats;
