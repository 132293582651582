import React, { forwardRef } from 'react';
import { Input } from 'antd';
import useInputFilter, {
  FilterProps,
} from '@components/List/CustomsFilters/useInputFilter';
import { FilterWrapper } from '@components/List/CustomsFilters/styles';
import { AddressType } from '@services/filtering';

type AddressFieldFilterParams = {
  fieldName: AddressType;
  textId: string;
  textDefaultMessage: string;
};

const addressFieldFilter = ({
  fieldName,
  textId,
  textDefaultMessage,
}: AddressFieldFilterParams) =>
  forwardRef((props: FilterProps, ref) => {
    const { onValueChange, inputPlaceholder } = useInputFilter({
      ref,
      type: 'contains',
      filterType: fieldName,
      placeholder: {
        id: textId,
        defaultMessage: textDefaultMessage,
      },
      props,
    });

    return (
      <FilterWrapper>
        <Input
          id="cityFilter"
          onChange={onValueChange}
          placeholder={inputPlaceholder}
        />
      </FilterWrapper>
    );
  });

export default addressFieldFilter;
