import React from 'react';
import { Tag } from 'antd';
import { FormattedMessage } from 'react-intl/lib';

const ActiveTag = () => (
  <Tag color="success">
    <FormattedMessage id="tag.active" defaultMessage="Active" />
  </Tag>
);

export default ActiveTag;
