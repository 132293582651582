export const WAREHOUSE_STORAGE = 'warehouse_storage';

export const WAREHOUSE_HISTORY = 'warehouse_history';

export const TOTAL_STATS_HISTORY_COLLECTION = 'warehouse_history_total';

export const WAREHOUSE_PRODUCTS_STATUS = 'warehouse_products_status';

export const WAREHOUSE_PRODUCTS_STATUS_HISTORY =
  'warehouse_products_status_history';

export type WarehouseStorageEntry = {
  id?: string;
  location?: string;
  productId: string;
  storageId: string;
  maximumLevel: number | null;
  targetLevel: number | null;
  orderLevel: number | null;
  criticalLevel: number | null;
};

export type WarehouseStorageStatus = {
  inStock: number | null;
  incoming: number | null;
  outgoing: number | null;
  reserved: number | null;
  available: number | null;
  average: number | null;
  value: number | null;
};

export type HistoryTotalEntry = WarehouseStorageStatus & {
  id?: string;
  flowType: FlowOption;
  timestamp: number;
};

export type WarehouseStorage = {
  id?: string;
  ecosystem: string;
  name: string;
  timestamp?: number;
  entries: Record<string, WarehouseStorageEntry>;
};

export type FlowOption =
  | 'incoming'
  | 'manual_fill_up'
  | 'manual_take_out'
  | 'inventory_correction';

export type WarehouseHistoryEntry = {
  id?: string;
  timestamp: number | string;
  amount: number;
  flowType: FlowOption;
  storageId: string;
  orderNo?: string;
  numberDeliveryNote?: string;
  supplier?: string;
  productId: string;
  productName: string;
  expectedDate?: number;
  unit: string;
  pastDate?: number;
  value: number;
  user: string;
};

export type ProductEntry = {
  key: number;
  productNumber?: string;
  productId?: string;
  productName?: string;
  amount?: number;
  unit?: string;
  storageId?: string;
};

export type WarehouseFlow = {
  ecosystem: string;
  flow: FlowOption;
  orderId: string;
  supplier: string;
  timestamp: number;
  reason: string;
  user: string;
  entries: {
    amount: string;
    key: number;
    productId: string;
    productName: string;
    storageId: string;
    unit: string;
  }[];
};
