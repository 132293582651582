import React, { FC, useState, useEffect } from 'react';
import { Text } from '@todix/ui-components';
import styled from 'styled-components';
import EcosystemAvatar from '@components/EcosystemAvatar';
import { Document, DocumentCategory } from '@apps/documents/service';
import { useCustomEventListener } from '@core/services';
import { DocumentProcessingEvents } from '@apps/documents/mainMenu/DocumentProcessing';
import { CompanyContact, Contact, getContact } from '@apps/contacts/services';
import { FormattedMessage } from 'react-intl/lib';
import { useIntl } from 'react-intl';
import moment from 'moment';

const InboxLabel = styled('span')`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text.gray};
  border: solid 1px #d9d9d9;
  background-color: #f5f5f5;
  padding: 1px 8px 2px;
  border-radius: 2px;
  margin-left: 15px;
  display: inline-block;
`;

const StyledEcosystemAvatar = styled(EcosystemAvatar)`
  margin-left: 15px;
`;

type ProcessingHeaderProps = {
  document: Document;
  showExtraDetails?: boolean;
};

//make processing header more dynamic
const ProcessingHeader: FC<ProcessingHeaderProps> = ({
  document,
  showExtraDetails = true,
}) => {
  const [doc, setDoc] = useState(document);
  const [contact, setContact] = useState<Contact | null>(null);
  const intl = useIntl();

  useEffect(() => {
    if (doc.relatedContact) {
      getContact(doc.relatedContact).then(setContact);
    }
  }, [doc]);

  const directionMap: Record<DocumentCategory, string> = {
    incoming: intl.formatMessage({
      id: 'documents.documentTypes.category.incoming',
      defaultMessage: `incoming`,
    }),
    outgoing: intl.formatMessage({
      id: 'documents.documentTypes.category.outgoing',
      defaultMessage: `outgoing`,
    }),
    none: intl.formatMessage({
      id: 'documents.documentTypes.category.none',
      defaultMessage: `no direction`,
    }),
  };

  useCustomEventListener<DocumentProcessingEvents, Contact>(
    'setRelatedContact',
    (passedContact) => {
      if (passedContact) {
        setContact(passedContact);
      } else {
        setContact(null);
      }
    },
  );

  useCustomEventListener<DocumentProcessingEvents, Document>(
    'setDocument',
    (document) => {
      if (document) {
        setDoc({
          ...doc,
          receivingDate: document.receivingDate
            ? document.receivingDate
            : doc.receivingDate,
          type: document.type ? document.type : doc.type,
          category: document.category ? document.category : doc.category,
          issuingDate: document.issuingDate
            ? document.issuingDate
            : doc.issuingDate,
        });
      }
    },
  );

  const contactName = contact
    ? contact.type === 'person'
      ? `${contact.firstName} ${contact.lastName}`
      : (contact as CompanyContact).companyName
    : '';

  const type = doc.type
    ? intl.formatMessage({
        id: `documents.documentTypes.${doc.type}`,
        defaultMessage: `documents.documentTypes.${doc.type}`,
      })
    : '';

  return (
    <Text level={4}>
      {type && !doc.issuingDate && (
        <FormattedMessage
          id="documents.inbox.processingheader.documentname"
          defaultMessage="{type} {issuingDate} {category} {contact}"
          values={{
            type,
            issuingDate: doc.issuingDate
              ? moment.isMoment(doc.issuingDate)
                ? (doc.issuingDate as moment.Moment).format(
                    moment.defaultFormat,
                  )
                : doc.issuingDate
              : '',
            category: directionMap[doc.category],
            contact: contactName,
          }}
        />
      )}
      {type && doc.issuingDate && (
        <FormattedMessage
          id="documents.inbox.processingheader.documentname.of"
          defaultMessage="{type} of {issuingDate} {category} {contact}"
          values={{
            type,
            issuingDate: doc.issuingDate
              ? moment.isMoment(doc.issuingDate)
                ? (doc.issuingDate as moment.Moment).format(
                    moment.defaultFormat,
                  )
                : doc.issuingDate
              : '',
            category: directionMap[doc.category],
            contact: contactName,
          }}
        />
      )}
      {showExtraDetails && (
        <>
          <InboxLabel>
            <FormattedMessage
              id="docuemnts.inbox.processingheader.label"
              defaultMessage="Inbox name"
            />
          </InboxLabel>
          <StyledEcosystemAvatar id={doc.ecosystem} />
        </>
      )}
    </Text>
  );
};

export default ProcessingHeader;
