import { styled } from '@styles/themes';

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex: 1;
`;

export const TopActionsWrapper = styled.div`
  display: flex;
`;

export const SearchWrapper = styled.div`
  margin-left: 32px;
  //max-width: 320px;
  height: 100%;
  display: flex;
`;
