import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { ApiOutlined, DeleteOutlined } from '@ant-design/icons';
import { Empty, Spin } from 'antd';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { EntityRelation } from '@services/entityRelations/namespace';
import fetchResource from '@components/Relations/fetchResource';
import { FileApp } from '@apps/AppTypes';
import {
  RelationsTree,
  TaskTitleContainer,
} from '@components/Relations/Relations.sc';
import getAnyRelations from '@services/entityRelations/getAnyRelations';
import WidgetResolver from '@components/WidgetResolver';
import removeRelation from '@services/entityRelations/removeRelation';
import SpinContainer from '@components/SpinContainer';

type CurrentRelationsProps = {
  currentRelations: EntityRelation[];
  availableApps: FileApp[];
  setCurrentRelations?: Dispatch<SetStateAction<EntityRelation[]>>;
  asParentRelations?: boolean;
};

const CurrentRelations: FC<CurrentRelationsProps> = ({
  currentRelations,
  availableApps,
  setCurrentRelations,
  asParentRelations = false,
}) => {
  const [isProcessing, setIsProcessing] = useState(true);
  const [tree, setTree] = useState<DataNode[]>([]);

  const handleResourceDeselect = useCallback(
    (relationId?: string) => {
      if (relationId) {
        removeRelation(relationId).then(() => {
          if (setCurrentRelations) {
            setCurrentRelations(
              currentRelations.filter((relation) => relation.id !== relationId),
            );
          }
        });
      }
    },
    [setCurrentRelations, currentRelations],
  );

  useEffect(() => {
    setIsProcessing(true);
    const promises = currentRelations.map((relation) => {
      return fetchResource({
        collection: asParentRelations
          ? relation.parentRefCollection
          : relation.childRefCollection,
        id: asParentRelations ? relation.parentId : relation.childId,
        relationId: relation.id,
      });
    });
    Promise.all(promises)
      .then((result) => {
        const treeData = result
          .filter((res) => res !== null)
          .map((resource) => {
            const app = availableApps.find(
              (app) => app.collectionName === resource?.collection,
            );
            const Icon = app ? app.mainMenu.icon : ApiOutlined;
            const title = app ? (
              <WidgetResolver
                appName={app.name}
                widgetName="TreeNode"
                injectedProps={{ resource }}
              />
            ) : (
              <span>{resource?.id}</span>
            );
            return {
              key: resource?.id as string,
              title: (
                <TaskTitleContainer>
                  {title}
                  {!asParentRelations && (
                    <DeleteOutlined
                      onClick={() =>
                        resource && handleResourceDeselect(resource.relationId)
                      }
                      style={{ marginLeft: '10px' }}
                    />
                  )}
                </TaskTitleContainer>
              ),
              selectable: false,
              checkable: false,
              icon: <Icon />,
            } as DataNode;
          });
        setTree(treeData);
      })
      .finally(() => {
        setIsProcessing(false);
      });
  }, [
    asParentRelations,
    availableApps,
    currentRelations,
    handleResourceDeselect,
  ]);

  const updateTreeData = useCallback(
    (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
      list.map((node) => {
        if (node.key === key) {
          return {
            ...node,
            children,
          };
        }
        if (node.children) {
          return {
            ...node,
            children: updateTreeData(node.children, key, children),
          };
        }
        return node;
      }),
    [],
  );

  const onLoadData = useCallback(
    (treeNode: EventDataNode) => {
      const keyParts = (treeNode.key as string).split('-');
      const key = keyParts[keyParts.length - 1];

      setIsProcessing(true);

      return getAnyRelations({
        key: 'parentId',
        value: key,
      }).then((entities) => {
        const promises = entities.map((entity) => {
          return fetchResource({
            collection: entity.childRefCollection,
            id: entity.childId,
          });
        });
        Promise.all(promises)
          .then((results) => {
            setTree((origin) =>
              updateTreeData(
                origin,
                treeNode.key,
                results
                  .filter((result) => result !== null)
                  .map((resource) => {
                    const app = availableApps.find(
                      (app) => app.collectionName === resource?.collection,
                    );
                    const Icon = app ? app.mainMenu.icon : ApiOutlined;
                    const title = app ? (
                      <WidgetResolver
                        appName={app.name}
                        widgetName="TreeNode"
                        injectedProps={{ resource }}
                      />
                    ) : (
                      <span>{resource?.id}</span>
                    );
                    return {
                      title: (
                        <TaskTitleContainer>
                          {title}
                          {!asParentRelations && (
                            <DeleteOutlined
                              onClick={() =>
                                resource && handleResourceDeselect(resource.id)
                              }
                              style={{ marginLeft: '10px' }}
                            />
                          )}
                        </TaskTitleContainer>
                      ),
                      key: `${treeNode.key}-${resource?.id as string}`,
                      selectable: false,
                      checkable: false,
                      icon: <Icon />,
                    };
                  }),
              ),
            );
          })
          .finally(() => {
            setIsProcessing(false);
          });
      });
    },
    [asParentRelations, availableApps, handleResourceDeselect, updateTreeData],
  );
  return (
    <>
      <div>
        {asParentRelations ? (
          <FormattedMessage
            id="relations.parentsRelations"
            defaultMessage="Is a part of:"
          />
        ) : (
          <FormattedMessage
            id="relations.currentRelations"
            defaultMessage="Consists of:"
          />
        )}
      </div>
      <Spin indicator={<SpinContainer />} spinning={isProcessing}>
        <RelationsTree
          multiple={false}
          style={{
            marginBottom: '20px',
          }}
          showIcon
          showLine
          treeData={tree}
          loadData={onLoadData}
        />
        {tree.length < 1 && !isProcessing && <Empty />}
      </Spin>
    </>
  );
};

export default CurrentRelations;
