import React from 'react';
import { Redirect } from 'react-router-dom';
import SpinContainer from '@components/SpinContainer';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';

const withAuth = (Component: any) => {
  return (props: any) => {
    const { data: user, isLoading } = useSelector(getUser);

    if (isLoading) {
      return <SpinContainer />;
    }
    if (user === null) {
      return <Redirect to="/sign" />;
    }
    if (user && !user.emailVerified) {
      return <Redirect to="/verifyemail" />;
    }

    return <Component {...props} />;
  };
};

export default withAuth;
