import { Form, Input, Tooltip } from 'antd';
import React, { FC, useState, useRef, useContext, useEffect } from 'react';
import { ProductWithPrice } from '@apps/sales/services/namespace';
import EditableContext from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/context';
import UnitSelector from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/UnitSelector';
import PriceInOrder from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/PriceInOrder';
import PriceOutput from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/PriceOutput';
import Discount from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/Discount';
import TaxSelection from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/TaxSelection';
import Amount from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/Amount';
import ProductSelector from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/ProductSelector';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  ModifiedIndicator,
  CustomCell,
} from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import { Product } from '@apps/products/services';
import intl from '../../../../../../../../intl';

const { Item } = Form;

type EditableCellProps = {
  title: React.ReactNode;
  editable: boolean;
  type?: 'dropdown' | 'number' | 'string' | 'unit' | 'price';
  children: React.ReactNode;
  dataIndex: keyof ProductWithPrice;
  record: ProductWithPrice;
  handleSave: (record: ProductWithPrice) => void;
  prodOptions: any[];
  selectedEcosystem: string | null;
  handleProductSelect: (id: string) => void;
  handleProductCreate: (product: Product) => void;
  readOnly: boolean;
};

const EditableCell: FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  selectedEcosystem,
  prodOptions,
  readOnly,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current!.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (record) {
      form.setFieldsValue({
        ...record,
      });
    }
  }, [form, record]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const save = async () => {
    if (readOnly) {
      return;
    }
    try {
      const values: Record<string, string | number> =
        await form.validateFields();
      const newValues = { ...record, ...values };
      handleSave(newValues);
      toggleEdit();
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (dataIndex === 'productId') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '160px' }}>
        {record.isDirty && (
          <Tooltip
            title={intl.formatMessage({
              id: 'product.modified',
              defaultMessage: 'This product has been modified',
            })}
          >
            <ModifiedIndicator
              icon={<ExclamationCircleOutlined />}
              color="warning"
            />
          </Tooltip>
        )}
        <ProductSelector
          disabled={readOnly}
          rowIndex={record.key}
          selectedProductId={record.productId}
          selectedEcosystem={selectedEcosystem}
          prodOptions={prodOptions}
          onProductSelect={restProps.handleProductSelect}
          style={{
            width: '100%',
          }}
        />
      </CustomCell>
    );
  }

  if (dataIndex === 'amount') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '64px' }}>
        <Amount
          name="amount"
          onBlur={save}
          onPressEnter={save}
          handleSave={save}
          disabled={readOnly}
        />
      </CustomCell>
    );
  }

  if (dataIndex === 'taxValue') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '64px' }}>
        <TaxSelection
          name="taxValue"
          onBlur={save}
          onPressEnter={save}
          handleSave={save}
          disabled={readOnly}
        />
      </CustomCell>
    );
  }

  if (dataIndex === 'discount') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '64px' }}>
        <Discount
          name="discount"
          onBlur={save}
          handleSave={save}
          disabled={readOnly}
        />
      </CustomCell>
    );
  }

  if (dataIndex === 'discountedPrice') {
    return (
      <td {...restProps}>
        <PriceOutput name="discountedPrice" />
      </td>
    );
  }

  if (dataIndex === 'grossPrice') {
    return (
      <td {...restProps}>
        <PriceOutput name="grossPrice" />
      </td>
    );
  }

  if (dataIndex === 'totalNetPrice') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '100px' }}>
        <PriceOutput name="totalNetPrice" />
      </CustomCell>
    );
  }

  if (dataIndex === 'totalGrossPrice') {
    return (
      <CustomCell {...restProps}>
        <PriceOutput name="totalGrossPrice" />
      </CustomCell>
    );
  }

  if (dataIndex === 'price') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '100px' }}>
        <PriceInOrder
          product={record}
          prices={record.prices || []}
          //onBlur={save}
          handleSave={save}
          disabled={readOnly}
        />
      </CustomCell>
    );
  }

  if (type === 'unit') {
    return (
      <CustomCell {...restProps} style={{ minWidth: '120px' }}>
        <Item style={{ margin: 0 }} name={['salesUnit']}>
          <UnitSelector
            defaultValue={record.saleDetails?.salesUnit}
            selectedEcosystem={selectedEcosystem}
            onBlur={save}
            style={{
              width: '100%',
            }}
            disabled={readOnly}
          />
        </Item>
      </CustomCell>
    );
  }

  if (editable) {
    childNode = editing ? (
      <Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          type={type === 'number' ? 'number' : 'text'}
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          disabled={readOnly}
        />
      </Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
        <Item name={dataIndex} hidden={true} />
      </div>
    );
  } else if (dataIndex) {
    childNode = (
      <Item noStyle shouldUpdate={true}>
        {() => (
          <Item name={dataIndex} style={{ margin: 0 }}>
            <Input readOnly />
          </Item>
        )}
      </Item>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default EditableCell;
