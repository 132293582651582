import React, { FC } from 'react';
import { NumberInput } from '@components/MoneyInput';
import { NumericFormatProps } from 'react-number-format/types/types';

type Props = NumericFormatProps & {
  onChange?(payload: any): void;
};

const FloatNumberedField: FC<Props> = ({ onChange, ...rest }) => {
  return (
    <NumberInput
      tabIndex={0}
      onValueChange={({ floatValue }) => {
        onChange?.(floatValue);
      }}
      fixedDecimalScale
      {...rest}
    />
  );
};

export default FloatNumberedField;
