import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ColumnType } from 'antd/lib/table';
import StyledTable from '@pages/settings/components/StyledTable';
import {
  FlexSpaceBetween,
  BigBold,
} from '@apps/sales/widgets/SalesTab/styles.sc';
import PriceModal from '@apps/sales/widgets/SalesTab/Pricing/PriceModal';
import { ProductPrice, getProductPrices } from '@apps/products/services';
import { StyledDeleteIcon, StyledEditIcon } from '@pages/settings/components';
import PriceName from '@apps/sales/widgets/SalesTab/Pricing/PriceName';
import PriceStatus from '@apps/sales/widgets/SalesTab/Pricing/PriceStatus';
import archiveProductPrice from '../../../../products/services/archiveProductPrice';
import SalesTabContext from '@apps/sales/widgets/SalesTab/context';

const Pricing: FC = () => {
  const { readOnly, ecosystem, productId } = useContext(SalesTabContext);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState<ProductPrice[]>([]);
  const [selectedPrice, setSelectedPrice] = useState<ProductPrice | null>(null);

  useEffect(() => {
    if (productId && ecosystem?.id) {
      setLoading(true);
      getProductPrices({
        ecosystemId: ecosystem.id,
        productId,
        skipArchived: true,
      })
        ?.then(setPrices)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem?.id, productId]);

  const onCancel = useCallback(() => {
    setVisible(false);
    setSelectedPrice(null);
  }, []);

  const onOk = useCallback(() => {
    setVisible(false);
    if (productId && ecosystem) {
      setLoading(true);
      getProductPrices({
        ecosystemId: ecosystem.id,
        productId,
        skipArchived: true,
      })
        ?.then(setPrices)
        .finally(() => {
          setLoading(false);
          setSelectedPrice(null);
        });
    }
  }, [ecosystem, productId]);

  const handlePriceChange = useCallback(async () => {
    if (productId && ecosystem) {
      setLoading(true);
      getProductPrices({
        ecosystemId: ecosystem.id,
        productId,
        skipArchived: true,
      })
        ?.then(setPrices)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystem, productId]);

  const handlePriceArchive = useCallback(
    async (id: string) => {
      setLoading(true);
      try {
        await archiveProductPrice(id);
        await handlePriceChange();
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    },
    [handlePriceChange],
  );

  const title = useMemo(
    () => () =>
      (
        <FlexSpaceBetween>
          <BigBold>
            <FormattedMessage
              id="sales.salesTab.pricing.header"
              defaultMessage="Pricing"
            />
          </BigBold>
          {!readOnly && productId && (
            <Button
              disabled={readOnly || !productId}
              onClick={() => setVisible(true)}
            >
              <FormattedMessage
                id="sales.salesTab.addPrice.button"
                defaultMessage="Add price"
              />
            </Button>
          )}
          {!readOnly && !productId && (
            <div>
              <FormattedMessage
                id="sales.add.product.first"
                defaultMessage="Please save the product first to add a price to the table."
              />
            </div>
          )}
        </FlexSpaceBetween>
      ),
    [productId, readOnly],
  );

  const columns: ColumnType<any>[] = useMemo(() => {
    const cols: ColumnType<any>[] = [
      {
        title: (
          <FormattedMessage
            id="sales.salesTab.unitAmount.label"
            defaultMessage="Price"
          />
        ),
        sorter: true,
        ellipsis: true,
        render: (_v, record: ProductPrice) => <PriceName price={record} />,
      },
    ];

    if (readOnly) {
      return cols;
    }

    return [
      ...cols,
      {
        title: (
          <FormattedMessage
            id="settings.list.header.status"
            defaultMessage="Status"
          />
        ),
        dataIndex: 'isActive',
        render: (_v, record: ProductPrice) => (
          <PriceStatus price={record} handlePriceChange={handlePriceChange} />
        ),
      },
      {
        title: (
          <FormattedMessage
            id="settings.list.header.actions"
            defaultMessage="Actions"
          />
        ),
        render: (_v, record: ProductPrice) => (
          <>
            <StyledEditIcon
              onClick={() => {
                setSelectedPrice(record);
                setVisible(true);
              }}
            />
            <StyledDeleteIcon
              onClick={() => handlePriceArchive(record.id || '')}
            />
          </>
        ),
      },
    ];
  }, [handlePriceChange, handlePriceArchive, readOnly]);
  return (
    <>
      <StyledTable
        columns={columns}
        dataSource={prices}
        bordered
        tableLayout="auto"
        loading={loading}
        title={title}
        rowKey="id"
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: 5,
        }}
      />
      <PriceModal
        visible={visible}
        price={selectedPrice}
        onOk={onOk}
        onCancel={onCancel}
      />
    </>
  );
};

export default Pricing;
