import { database } from 'firebase';
import { firebase } from '@services/Firebase';
import ThenableReference = firebase.database.ThenableReference;
import { RootStore, rootStore } from '@core/store';
import { getAppRolesByEcosystem } from '@services/roles/appRoles';
import { setUserPermissions } from '@services/permissions';
import { createNotification } from '@services/notifications';
import intl from '../../../intl';
import moment from 'moment/moment';

const COLLECTION = 'companyRoles';

export const getCompanyRoles = (
  ecosystemId: string,
): Promise<EcosystemRole[]> => {
  if (!firebase?.db) {
    return Promise.resolve([] as EcosystemRole[]);
  }

  return firebase?.db
    ?.ref(`${COLLECTION}/${ecosystemId}`)
    .once('value')
    .then((snapshot) => {
      const records = snapshot.val() as Record<string, EcosystemRole>;
      if (!records) {
        return [] as EcosystemRole[];
      }
      return Object.entries(records).map(
        (entry) =>
          ({
            ...entry[1],
            id: entry[0],
          } as EcosystemRole),
      );
    });
};

export const createCompanyRole = (
  ecosystemId: string,
  role: EcosystemRole,
): ThenableReference | undefined => {
  return firebase?.db?.ref(`${COLLECTION}/${ecosystemId}`).push({
    ...role,
    timestamp: moment().valueOf(),
  });
};

export const getCompanyRole = (
  ecosystemId: string,
  id: string,
): Promise<EcosystemRole> | undefined => {
  return firebase?.db
    ?.ref(`${COLLECTION}/${ecosystemId}/${id}`)
    .once('value')
    .then((res) => {
      return res.val() as EcosystemRole;
    });
};

export const updateCompanyRole = (
  ecosystemId: string,
  id: string,
  role: EcosystemRole,
) => {
  return firebase?.db?.ref(`${COLLECTION}/${ecosystemId}/${id}`).update({
    ...role,
    timestamp: moment().valueOf(),
  });
};

const handleCompanyRoleChange = (
  role: EcosystemRole,
  userId: string,
  ecosystem: Ecosystem,
) => {
  const store = rootStore.getState() as RootStore;
  const { permissions } = store;
  const userPermissions = permissions.data;
  Object.entries(userPermissions).forEach((entry) => {
    const ecosystemId = entry[0];
    if (role.ecosystemId === ecosystemId) {
      const matchedPermissions = entry[1] as any;
      const { companyRole } = matchedPermissions;
      if (companyRole) {
        getCompanyRole(ecosystemId, companyRole)?.then((role) => {
          if (role) {
            getAppRolesByEcosystem(ecosystemId)?.then((appRoles) => {
              //create new user permissions
              let newPermissions: Record<string, Record<string, string>> = {};
              Object.entries(role.permission).map((entries) => {
                const todixId = entries[0];
                const appRoleId = entries[1];
                const appRole = appRoles.find((el) => el.id === appRoleId);
                newPermissions = {
                  ...newPermissions,
                  [todixId]: appRole?.permission || {},
                };
              });
              const newUserPermissions = {
                userId,
                ecosystemId,
                companyRoleId: companyRole,
                permissions: newPermissions,
              };

              setUserPermissions(newUserPermissions)?.then(() => {
                createNotification({
                  ecosystemId,
                  recipientId: userId,
                  title: intl.formatMessage({
                    id: 'new.permissions',
                    defaultMessage: 'New permissions',
                  }),
                  description: intl.formatMessage(
                    {
                      id: 'update.company.role.desc',
                      defaultMessage: 'update.company.role.desc',
                    },
                    {
                      role: role.name,
                      ecosystem: ecosystem?.name || '',
                    },
                  ),
                });
              });
            });
          }
        });
      }
    }
  });
};

const updatePermissions = (
  data: database.DataSnapshot,
  userId: string,
  ecosystem: Ecosystem,
) => {
  const payload = data.val() as any;
  if (payload) {
    handleCompanyRoleChange(payload, userId, ecosystem);
  }
};

export const companyRolesListener = (
  userId: string,
  ecosystems: Ecosystem[],
  db?: database.Database,
) => {
  const filteredEcosystems = ecosystems.filter((el) => el.ownerUid !== userId);

  filteredEcosystems.forEach((ecosystem) => {
    const ref = db?.ref(`${COLLECTION}/${ecosystem.id}`);
    ref?.on('child_changed', (data: any) => {
      updatePermissions(data, userId, ecosystem);
    });
  });
};
