import React from 'react';
import { DatePicker, Form } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import intl from '../../../../../../intl';

const { RangePicker } = DatePicker;
const { Item } = Form;

const PriceDateConstraints: React.FC = () => {
  // eslint-disable-next-line arrow-body-style
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current <= moment().endOf('day');
  };

  return (
    <Item name={['constraints', 'validDateRange']}>
      <RangePicker
        disabledDate={disabledDate}
        placeholder={[
          intl.formatMessage({
            id: 'sales.salesTab.priceConstraints.validFrom',
            defaultMessage: 'Valid from',
          }),
          intl.formatMessage({
            id: 'sales.salesTab.priceConstraints.validTill',
            defaultMessage: 'Valid till',
          }),
        ]}
      />
    </Item>
  );
};

export default PriceDateConstraints;
