import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import { Button, Dropdown, Menu } from 'antd';
import Modal from '@components/Modal';
import { FormattedMessage, useIntl } from 'react-intl/lib';
import moreIcon from '@components/AppView/assets/more.svg';

type OwnerActionMenuProps = {
  member: MemberWithLicences;
  relation: EcosystemRelation;
  handleDelete(memberUid: string): void;
  handleBlock(memberUid: string, blocked: boolean): void;
};

type OverlayProps = OwnerActionMenuProps & {
  intl: IntlShape;
};

const getOverlay = ({
  intl,
  handleDelete,
  handleBlock,
  relation,
}: OverlayProps) => {
  return (
    <Menu>
      <Menu.Item
        danger
        onClick={() => {
          Modal.confirm({
            content: relation.disabled
              ? intl.formatMessage({
                  id: 'settings.memberslist.unblock.confirm"',
                  defaultMessage: 'Are you sure you want to unblock user?',
                })
              : intl.formatMessage({
                  id: 'settings.memberslist.block.confirm',
                  defaultMessage: 'Are you sure you want to block user?',
                }),
            onOk: handleBlock,
          });
        }}
      >
        {relation.disabled ? (
          <FormattedMessage
            id="settings.memberslist.unblock.menu"
            defaultMessage="Unblock"
          />
        ) : (
          <FormattedMessage
            id="settings.memberslist.block.menu"
            defaultMessage="Block"
          />
        )}
      </Menu.Item>
      <Menu.Item
        danger
        onClick={() => {
          Modal.confirm({
            content: intl.formatMessage({
              id: 'settings.memberslist.delete.confirm',
              defaultMessage: 'Are you sure you want to remove user?',
            }),
            onOk: handleDelete,
          });
        }}
      >
        <FormattedMessage
          id="settings.memberslist.delete.menu"
          defaultMessage="Delete from ecosystem"
        />
      </Menu.Item>
    </Menu>
  );
};

const OwnerActionMenu: FC<OwnerActionMenuProps> = ({
  member,
  handleDelete,
  handleBlock,
  relation,
}) => {
  const intl = useIntl();
  return (
    <Dropdown
      key={member.id}
      overlay={getOverlay({
        member,
        relation,
        intl,
        handleDelete: () => handleDelete(member.id),
        handleBlock: () => handleBlock(member.id, !relation.disabled),
      })}
    >
      <Button
        type="text"
        size="small"
        icon={<img src={moreIcon} alt="more" />}
      />
    </Dropdown>
  );
};

export default OwnerActionMenu;
