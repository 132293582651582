import { createContext } from 'react';

type Context = {
  ecosystem: Ecosystem | null;
  readOnly?: boolean;
  loading: boolean;
  setLoading?: any;
  consultantId?: string;
};

const ConsultantContext = createContext<Context>({
  ecosystem: null,
  loading: false,
});

export default ConsultantContext;
