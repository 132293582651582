import { lazy } from 'react';
import {
  ShopOutlined,
  TeamOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';

const Routing = lazy(
  () => import(/* webpackChunkName: "directSalesClientApp" */ './Routing'),
);

const Consultants = lazy(
  () => import(/* webpackChunkName: "directSalesClientApp" */ './Consultants'),
);

const Parties = lazy(
  () => import(/* webpackChunkName: "directSalesClientApp" */ './Parties'),
);

export default {
  appSubmenuName: 'app.directsalesclient',
  icon: ShopOutlined,
  component: Routing,
  sections: [
    {
      link: 'consultants',
      title: 'app.directsalesclient.consultants',
      component: Consultants,
      icon: TeamOutlined,
    },
    {
      link: 'parties',
      title: 'app.directsalesclient.parties',
      component: Parties,
      icon: ShoppingOutlined,
    },
  ],
};
