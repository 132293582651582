import { LICENCES } from '@services/licences/namespace';
import { firebase } from '@services/Firebase';

type Payload = {
  ecosystemId: string;
  membersIds: string[];
};

const getLicencesForMembers = async ({
  ecosystemId,
  membersIds,
}: Payload): Promise<(Licence[] | undefined)[]> => {
  if (!firebase?.db) {
    return [];
  }

  const licencesPromises = membersIds.map((id) => {
    return firebase?.db
      ?.ref(`${LICENCES}/${ecosystemId}`)
      .orderByChild('ownerId')
      .equalTo(id)
      .once('value')
      .then((results) => {
        const records = results.val() as Record<string, Licence>;
        if (!records) {
          return [] as Licence[];
        }
        return Object.entries(records).map(
          (entry) =>
            ({
              ...entry[1],
              id: entry[0],
            } as Licence),
        );
      });
  });

  return await Promise.all(licencesPromises);
};

export default getLicencesForMembers;
