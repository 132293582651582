import React, { FC } from 'react';
import { Form, Input, Badge } from 'antd';
import {
  UpCircleFilled,
  DownCircleFilled,
  CheckCircleFilled,
} from '@ant-design/icons';
import { Toggle } from '@todix/ui-components';
import { InvoiceDataContainer } from '@apps/purchases/mainMenu/components/styles.sc';
import intl from '../../../../../intl';
import { BucketStep } from '@apps/purchases/services/buckets';
import MoneyInput from '@components/MoneyInput';
import { Document } from '@apps/documents/service';
import InvoiceSelector from '@apps/purchases/mainMenu/individualRequestsList/InvoiceSelector';

const { Item } = Form;

type Props = {
  onInvoiceSelect(document: Document): void;
};

const InvoiceData: FC<Props> = ({ onInvoiceSelect }) => {
  return (
    <Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const stepType = getFieldValue('stepType') as BucketStep;
        if (stepType === 'invoiced') {
          const currency = getFieldValue('currency');
          const supplierId = getFieldValue('relatedContact');
          const ecosystem = getFieldValue('ecosystem');
          const documentTotalAmount = getFieldValue('documentTotalAmount');
          const positionSum = getFieldValue('positionSum');
          let BadgeIcon: React.ForwardRefExoticComponent<any> =
            CheckCircleFilled;
          let color = '#0E7D40';
          if (positionSum < documentTotalAmount) {
            BadgeIcon = DownCircleFilled;
            color = '#f5222d';
          }
          if (positionSum > documentTotalAmount) {
            BadgeIcon = UpCircleFilled;
            color = '#f5222d';
          }
          return (
            <InvoiceDataContainer>
              <div>
                <InvoiceSelector
                  ecosystem={ecosystem}
                  supplierId={supplierId}
                  onInvoiceSelect={onInvoiceSelect}
                />
                <Item
                  name="documentNumber"
                  label={intl.formatMessage({
                    id: 'invoice.number',
                    defaultMessage: 'Invoice number',
                  })}
                >
                  <Input readOnly disabled />
                </Item>
                <Item
                  name="documentDate"
                  label={intl.formatMessage({
                    id: 'date',
                    defaultMessage: 'Date',
                  })}
                >
                  <Input readOnly disabled />
                </Item>
                <Item
                  name="documentTotalAmount"
                  label={intl.formatMessage({
                    id: 'total.amount',
                    defaultMessage: 'Total amount',
                  })}
                >
                  <MoneyInput
                    currency={currency}
                    readOnly
                    disabled
                    allowNegative={false}
                  />
                </Item>
              </div>
              <div>
                <Item
                  name="documentUpfrontPayment"
                  label={intl.formatMessage({
                    id: 'upfront.payment',
                    defaultMessage: 'Upfront payment',
                  })}
                  valuePropName="checked"
                >
                  <Toggle disabled />
                </Item>
                <Item
                  name="positionSum"
                  label={intl.formatMessage({
                    id: 'sum.of.positions',
                    defaultMessage: 'Sum of positions',
                  })}
                >
                  <Badge dot count={<BadgeIcon style={{ color }} />}>
                    <MoneyInput
                      currency={currency}
                      value={positionSum}
                      readOnly
                      disabled
                      allowNegative={false}
                    />
                  </Badge>
                </Item>
              </div>
            </InvoiceDataContainer>
          );
        }
        return null;
      }}
    </Item>
  );
};

export default InvoiceData;
