import React, { FC } from 'react';
import { Document } from '@apps/documents/service';
import { nameValueGetter } from '@apps/documents/mainMenu/Archive/valueGetters';
import { useIntl } from 'react-intl';

type TreeNodeProps = {
  resource: Record<string, any>;
};

const TreeNode: FC<TreeNodeProps> = ({ resource }) => {
  const document = resource as Document;
  const intl = useIntl();
  const name = nameValueGetter(document, intl);
  return <span>{name || ''}</span>;
};

export default TreeNode;
