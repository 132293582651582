import { patch } from '@services/api';
import { emitCustomEvent } from '@core/services';
import {
  PRODUCTS_COLLECTION_NAME,
  Product,
} from '@apps/products/services/namespace';

const patchProduct = async (id: string, product: Partial<Product>) => {
  const response = await patch(`${PRODUCTS_COLLECTION_NAME}/${id}`, product);
  // TODO: Have a common name for event that will refresh the list
  emitCustomEvent('productRemoved');
  return response;
};

export default patchProduct;
