import React, { FC, useState, useEffect, useCallback } from 'react';
import { Collapse, Divider, Button } from 'antd';
import debounce from 'lodash.debounce';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Contact, getContact } from '@apps/contacts/services';
import { ChildrenPassedProps } from '@apps/documents/mainMenu/DocumentProcessing/Step';
import AutoCompleteContact from '@apps/documents/mainMenu/AutoCompleteContact';
import StepForm from '@apps/documents/mainMenu/DocumentProcessing/StepForm';
import { emitCustomEvent } from '@core/services';
import { DocumentProcessingEvents } from '@apps/documents/mainMenu/DocumentProcessing/index';
import { InputField } from '@apps/documents/mainMenu/components/FormFields';
import { FormattedMessage, useIntl } from 'react-intl';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import { useSelector } from 'react-redux';
import Modal from '@components/Modal';
import ExistingContact from '@apps/documents/mainMenu/DocumentProcessing/Step2/ExistingContact';
import NewContact from '@apps/documents/mainMenu/DocumentProcessing/Step2/NewContact';
import { DocumentType } from '@apps/documents/service';

const { Panel } = Collapse;

const handleContactChange = (
  setFunction: (c: Contact) => void,
  contact: Contact,
) => {
  if (setFunction) {
    setFunction(contact);
  }
};

const notRequiredDocTypes: DocumentType[] = [
  'certificate',
  'commonDocumentation',
];

const debouncedHandleContactChange = debounce(handleContactChange, 500);

type ExtraButtonProps = {
  onClick: () => void;
};

const DeleteButton: FC<ExtraButtonProps> = ({ onClick }) => (
  <Button
    type="text"
    size="small"
    icon={<CloseSquareOutlined />}
    onClick={onClick}
  />
);

const Step2: FC<ChildrenPassedProps> = ({
  document,
  triggerValidator,
  ...rest
}) => {
  const intl = useIntl();
  const [isExistedContact, setIsExistedContact] = useState(
    !!rest.initialValues?.relatedContactSnapshot,
  );
  const [contact, setContact] = useState<Contact | null>(
    rest.initialValues?.relatedContact
      ? {
          id: rest.initialValues.relatedContact,
        }
      : null,
  );
  const [details, setDetails] = useState<Contact | null>(
    rest.initialValues?.relatedContactSnapshot || null,
  );
  const ecosystem = useSelector(
    getEcosystemById(rest.initialValues?.ecosystem),
  ) as Ecosystem;

  useEffect(() => {
    triggerValidator('step2');
  }, [document.type]);

  useEffect(() => {
    if (rest?.initialValues?.relatedContact) {
      getContact(rest.initialValues.relatedContact as string).then((r) => {
        setDetails(r as Contact);
        setContact(r as Contact);
      });
    }
  }, [rest?.initialValues?.relatedContact]);

  const handleSelect = (passedContact: Contact, existed: boolean) => {
    setContact(passedContact);
    getContact(passedContact?.id as string).then((details) => {
      setDetails(details);
      setIsExistedContact(existed);
    });

    if (rest.form) {
      rest.form.setFields([
        {
          name: 'relatedContact',
          value: passedContact.id,
        },
      ]);

      triggerValidator('step2');
    }
  };

  useEffect(() => {
    if (details && details.id && details.id !== 'undefined') {
      emitCustomEvent<DocumentProcessingEvents>('setRelatedContact', details);
    }
  }, [details]);

  useEffect(() => {
    if (contact) {
      emitCustomEvent<DocumentProcessingEvents>('setRelatedContact', contact);
    }
  }, [contact]);

  const handleDelete = useCallback(() => {
    setContact(null);
    setDetails(null);
    if (rest.form) {
      rest.form.setFields([
        {
          name: 'relatedContact',
          value: '',
        },
        {
          name: 'relatedContactSnapshot',
          value: null,
        },
      ]);

      emitCustomEvent<DocumentProcessingEvents, null>(
        'setRelatedContact',
        null,
      );

      triggerValidator('step2');
    }
  }, [rest.form, triggerValidator]);

  const handleContactChange = useCallback((passedContact) => {
    const newContact = {
      ...passedContact,
      foundationDate:
        passedContact.foundationDate && passedContact.foundationDate.format(),
      id: contact?.id ? contact?.id : passedContact.id,
    };

    debouncedHandleContactChange(setContact, newContact);

    emitCustomEvent<DocumentProcessingEvents>('setRelatedContact', newContact);
  }, []);

  const displayCollapse = !!contact || true;
  return (
    <>
      <StepForm {...rest}>
        <InputField
          field={{
            fieldType: 'input',
            fieldName: 'relatedContact',
            rules: [
              {
                required: !notRequiredDocTypes.includes(
                  document.type as DocumentType,
                ),
                message: intl.formatMessage({
                  id: 'documentprocessing.step2.relatedcontact.error',
                  defaultMessage: 'Missing related contact',
                }),
              },
            ],
          }}
          hidden
        />
      </StepForm>
      {!rest.hidden && (
        <>
          <AutoCompleteContact
            onSelect={handleSelect}
            disabled={!!contact}
            ecosystem={rest?.initialValues?.ecosystem}
          />
          {displayCollapse && <Divider />}
          {displayCollapse && (
            <Collapse>
              {contact && contact.id && details && isExistedContact && (
                <Panel
                  key="edit"
                  header={
                    details.type === 'person'
                      ? `${details.firstName || ''} ${details.lastName || ''}`
                      : details.companyName || ''
                  }
                  extra={<DeleteButton onClick={handleDelete} />}
                  collapsible="header"
                >
                  <ExistingContact
                    details={{
                      ...details,
                      id: contact.id,
                    }}
                    onValuesChange={handleContactChange}
                    handleCancel={() => {}}
                    onFinish={() => {}}
                  />
                </Panel>
              )}
              {contact && !isExistedContact && (
                <Panel
                  key="create"
                  header={
                    contact ? (
                      contact.type === 'person' ? (
                        `${contact.firstName || ''} ${contact.lastName || ''}`
                      ) : (
                        contact.companyName || ''
                      )
                    ) : (
                      <FormattedMessage
                        id="documentprocessing.step2.relatedcontact.header"
                        defaultMessage="New contact"
                      />
                    )
                  }
                  extra={<DeleteButton onClick={handleDelete} />}
                >
                  <NewContact
                    details={contact}
                    handleCancel={() => {
                      Modal.confirm({
                        content: intl.formatMessage({
                          id: 'documentprocessing.step2.relatedcontact.confirm',
                          defaultMessage: 'You really want to quit?',
                        }),
                        onOk: handleDelete,
                      });
                    }}
                    ecosystems={[ecosystem]}
                    onValuesChange={handleContactChange}
                    onFinish={(id) => {
                      setDetails(null);
                      handleSelect(
                        {
                          id,
                        },
                        true,
                      );
                    }}
                  />
                </Panel>
              )}
            </Collapse>
          )}
        </>
      )}
    </>
  );
};

export default Step2;
