import React, { FC } from 'react';
import NavigationPrompt, {
  NavigationPromptProps,
} from 'react-router-navigation-prompt';
import Modal from '@components/Modal';
import { useIntl } from 'react-intl/lib';

type Props = Omit<
  NavigationPromptProps,
  'location' | 'children' | 'history' | 'match'
>;

const UnsavedChangesPrompt: FC<Props> = ({ ...props }) => {
  const intl = useIntl();
  return (
    <NavigationPrompt
      // Confirm navigation if going to a path that does not start with current path:
      {...props}
    >
      {({ isActive, onCancel, onConfirm }) => {
        if (isActive) {
          Modal.confirm({
            content: intl.formatMessage({
              id: 'unsavedchanges.discard.confirm',
              defaultMessage: 'Discard changes?',
            }),
            onOk: onConfirm,
            okText: intl.formatMessage({
              id: 'button.ok',
              defaultMessage: 'Ok',
            }),
            onCancel: onCancel,
            cancelText: intl.formatMessage({
              id: 'button.cancel',
              defaultMessage: 'Cancel',
            }),
          });
        }
        return null;
      }}
    </NavigationPrompt>
  );
};

export default UnsavedChangesPrompt;
