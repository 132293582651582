import React, { FC } from 'react';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { FormattedMessage } from 'react-intl';
import { Card } from 'antd';
import Tabs from '@components/Tabs';
import { DashboardOutlined } from '@ant-design/icons';
import IndividualRequestForm from '@apps/purchases/mainMenu/components/individualRequest/InvidualRequestForm';
import { PURCHASES_ACTION } from '@apps/purchases';

const { TabPane } = Tabs;

export type IndividualRequestProps = {
  id?: string;
  initialValues: Partial<IndividualPurchaseRequest>;
  onSubmit: (individualRequest: IndividualPurchaseRequest) => void;
  onCancel?: () => void;
  readOnly?: boolean;
  shouldRedirect?: boolean;
  action: PURCHASES_ACTION;
};
const IndividualRequest: FC<IndividualRequestProps> = ({
  id,
  initialValues = {},
  onSubmit,
  onCancel,
  readOnly,
  shouldRedirect,
  action,
}) => {
  const handleSubmit = (values: IndividualPurchaseRequest) => {
    onSubmit(values);
  };
  return (
    <Card>
      <Tabs defaultActiveKey="1" onChange={() => {}}>
        <TabPane
          key="1"
          tab={
            <>
              <DashboardOutlined />{' '}
              <FormattedMessage
                id="detailcontact.tab.base"
                defaultMessage="Base Tab"
              />
            </>
          }
        >
          <IndividualRequestForm
            id={id}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            readOnly={readOnly}
            shouldRedirect={shouldRedirect}
            action={action}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default IndividualRequest;
