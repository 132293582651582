import { styled } from '@styles/themes';

export const AvatarHandlerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-left: 1px solid ${(props) => props.theme.colors.scrollbar.track};

  img {
    vertical-align: baseline;
  }

  img:hover {
    cursor: pointer;
  }
`;
