import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import {
  ENTITY_RELATIONS_COLLECTION,
  EntityRelation,
} from '@services/entityRelations/namespace';

type GetChildrenForParentPayload = {
  parentId: string;
  childType: string;
  ecosystem: string;
};

const getChildrenForParent = ({
  parentId,
  childType,
  ecosystem,
}: GetChildrenForParentPayload) => {
  return firebase.firestore
    ?.collection(ENTITY_RELATIONS_COLLECTION)
    .where('parentId', '==', parentId)
    .where('childType', '==', childType)
    .where('ecosystem', '==', ecosystem)
    .get()
    .then(getArrayWithIds) as Promise<EntityRelation[]>;
};

export default getChildrenForParent;
