import { styled } from '@styles/themes';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  font-size: 21px;
  margin-right: 18px;
`;

export const SelectStep = styled(Select)`
  flex: 1;
  max-width: 528px;
  overflow: hidden;

  .ant-select-selection-item {
    font-weight: bold;
  }
`;

export const OptionContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

export const OptionLabel = styled.span`
  overflow: hidden;
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ButtonSectionLeft = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const ButtonSectionRight = styled.div`
  ${ButtonSectionLeft};
  justify-content: flex-end;
`;

export const StepLabel = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  color: ${({ theme }) => theme.colors.text.light};
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.colors.text.gray};
  font-weight: bold;
  font-size: ${({ theme }) => theme.textSize['1']};

  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 12px;
    line-height: 1;
    content: '*';
  }
`;
