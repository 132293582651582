import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ColumnType } from 'antd/lib/table';
import { Button, Popconfirm } from 'antd';
import { CloseOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ProductWithPrice } from '@apps/sales/services/namespace';
import EditableRow from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/EditableRow';
import EditableCell from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/EditableCell';
import getProductsForEcosystem from '@apps/products/services/getProductsForEcosystem';
import {
  AddProductButton,
  NewPositionButton,
  ProductsWithPricesContainer,
} from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import { getProductPrices, Product } from '@apps/products/services';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { ProductTable } from '@apps/sales/mainMenu/components/SaleCreator/components/styles.sc';
import ExpandedRow from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/ExpandedRow';
import { DocumentType } from '@apps/documents/service';
import { ProductCreationModal } from '@apps/products/widgets/ProductCreationModal';

type Props = {
  entries: ProductWithPrice[];
  setEntries(entry: ProductWithPrice[]): void;
  count: number;
  setCount(count: number): void;
  selectedEcosystem: string | null;
  saleId?: string;
  documentType: DocumentType;
  readOnly?: boolean;
};

const ProductWithPriceEntries: FC<Props> = ({
  entries,
  setEntries,
  count,
  setCount,
  selectedEcosystem,
  saleId,
  documentType,
  readOnly = false,
}) => {
  const intl = useIntl();
  const { getFieldsValue } = useFormContext();
  const [products, setProducts] = useState<ProductWithPrice[]>([]);
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const handleDelete = useCallback(
    (record: ProductWithPrice) => {
      const { key } = record;
      const newData = entries.filter((item) => item.key !== key);
      setEntries(newData);
    },
    [entries, setEntries],
  );

  const handleSave = useCallback(
    (row: ProductWithPrice) => {
      const newData = [...entries];
      const index = newData.findIndex((item) => row.key === item.key);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
        isDirty: !row.isDirty
          ? item.price !== row.price ||
            item.salesUnit !== row.salesUnit ||
            item.description !== row.description ||
            item.taxValue !== row.taxValue
          : row.isDirty,
      });
      setEntries(newData);
    },
    [entries, setEntries],
  );

  const prodOptions = useMemo(() => {
    return products.map((prod) => {
      return {
        value: prod.id,
        label: `${prod.productName} ${prod.productNumber}`,
        'data-forsearch': `${prod.productName || ''} ${
          prod.productNumber || ''
        }`,
      };
    });
  }, [products]);

  const addNewPosition = useCallback(() => {
    const newData: ProductWithPrice = {
      key: count,
      amount: 1,
      description: '',
      salesUnit: '',
      taxCategory: '',
      taxValue: 0,
      prices: [],
      taxCategories: [],
      price: 0,
      discountedPrice: 0,
      discount: 0,
      grossPrice: 0,
      totalNetPrice: 0,
      totalGrossPrice: 0,
    };
    setEntries([...entries, newData]);
    setCount(count + 1);
  }, [count, entries, setCount, setEntries]);

  const updateEntries = useCallback(
    async (rowIndex: number, selectedProduct?: ProductWithPrice) => {
      if (selectedProduct && selectedEcosystem) {
        const { taxCategories } = getFieldsValue();
        const prices = await getProductPrices({
          ecosystemId: selectedEcosystem,
          productId: selectedProduct.id as string,
          skipArchived: true,
        });
        const defaultPrice = prices?.find((p) => p.isDefaultPrice);
        const defaultPriceValue = defaultPrice?.unitAmount || 0;
        const defaultCommissionFactor = defaultPrice?.commissionFactor || 1;
        const price =
          selectedProduct?.price !== undefined
            ? selectedProduct.price
            : defaultPriceValue;
        const taxValue =
          (taxCategories as any[])?.find(
            (tax) =>
              `${tax.id}` === `${selectedProduct.saleDetails?.taxCategory}`,
          )?.amount || 0;
        const isTaxInclusive = defaultPrice?.isTaxInclusive || false;
        const oldEntry = entries[rowIndex];
        const newData: ProductWithPrice = {
          ...oldEntry,
          amount: 1,
          productId: selectedProduct.id,
          productName: selectedProduct.productName,
          productNumber: selectedProduct.productNumber,
          description: selectedProduct.description,
          saleDetails: selectedProduct.saleDetails,
          salesUnit: selectedProduct.saleDetails?.salesUnit || '',
          taxCategory: selectedProduct.saleDetails?.taxCategory || '',
          taxValue,
          prices: prices || [],
          taxCategories: taxCategories || [],
          price: isTaxInclusive ? price - price * (taxValue / 100) : price,
          discountedPrice: isTaxInclusive
            ? price - price * (taxValue / 100)
            : price,
          discount: 0,
          grossPrice: isTaxInclusive ? price : price + price * (taxValue / 100),
          totalNetPrice: isTaxInclusive
            ? price - price * (taxValue / 100)
            : price,
          totalGrossPrice: isTaxInclusive
            ? price
            : price + price * (taxValue / 100),
          commissionFactor: defaultCommissionFactor,
        };
        setEntries(
          entries.map((entry, ind) => {
            if (ind === rowIndex) {
              return newData;
            }
            return entry;
          }),
        );
      }
    },
    [entries, getFieldsValue, selectedEcosystem, setEntries],
  );

  const handleSelect = useCallback(
    async (id, rowIndex: number) => {
      const selectedProduct = products.find((prod) => prod.id === id);
      await updateEntries(rowIndex, selectedProduct);
    },
    [products, updateEntries],
  );

  const handleProductCreate = useCallback(
    async (product: Product) => {
      if (readOnly) {
        return;
      }
      const key = count - 1;
      setProducts([
        ...products,
        {
          ...product,
          key,
        },
      ]);
      addNewPosition();
      await updateEntries(key, {
        ...product,
        key,
      });
    },
    [addNewPosition, count, products, readOnly, updateEntries],
  );

  const defaultColumns: (ColumnType<any> & {
    editable?: boolean;
    dataIndex?: string;
    type?: 'dropdown' | 'number' | 'string' | 'unit' | 'price';
    selectedEcosystem?: Props['selectedEcosystem'];
    data?: any;
  })[] =
    documentType === 'deliveryNote'
      ? [
          {
            title: intl.formatMessage({
              id: 'sales.createForm.position.label',
              defaultMessage: 'Pos.',
            }),
            render: (_v, _r, index) => {
              const pos = index + 1;
              return <span>{pos}</span>;
            },
          },
          {
            title: intl.formatMessage({
              id: 'sales.createNew.item',
              defaultMessage: 'Product/Service',
            }),
            dataIndex: 'productId',
            type: 'string',
            editable: true,
            selectedEcosystem,
          },
          {
            render: (_, _record: ProductWithPrice) =>
              entries.length >= 1 ? (
                <ProductCreationModal
                  OnClickEl={(props: any) => (
                    <a {...props}>
                      <AddProductButton
                        icon={<PlusSquareOutlined />}
                        size="small"
                        shape="default"
                        type="primary"
                      />
                    </a>
                  )}
                  onSubmit={handleProductCreate}
                  onCancel={console.log}
                  showExtraContent={false}
                />
              ) : null,
          },
          {
            title: intl.formatMessage({
              id: 'warehouse.create.table.header.amount',
              defaultMessage: 'Amount',
            }),
            dataIndex: 'amount',
            editable: true,
            type: 'number',
          },
          {
            title: intl.formatMessage({
              id: 'warehouse.create.table.header.unit',
              defaultMessage: 'Unit',
            }),
            editable: true,
            type: 'unit',
            dataIndex: 'salesUnit',
            selectedEcosystem,
          },
        ]
      : [
          {
            title: intl.formatMessage({
              id: 'sales.createForm.position.label',
              defaultMessage: 'Pos.',
            }),
            render: (_v, _r, index) => {
              const pos = index + 1;
              return <span>{pos}</span>;
            },
          },
          {
            title: intl.formatMessage({
              id: 'sales.createNew.item',
              defaultMessage: 'Product/Service',
            }),
            dataIndex: 'productId',
            type: 'string',
            editable: true,
            selectedEcosystem,
          },
          {
            render: (_, _record: ProductWithPrice) =>
              entries.length >= 1 ? (
                <ProductCreationModal
                  OnClickEl={(props: any) => (
                    <a {...props}>
                      <AddProductButton
                        icon={<PlusSquareOutlined />}
                        size="small"
                        shape="default"
                        type="primary"
                        disabled={readOnly}
                      />
                    </a>
                  )}
                  onSubmit={handleProductCreate}
                  onCancel={console.log}
                  showExtraContent={false}
                />
              ) : null,
          },
          {
            title: intl.formatMessage({
              id: 'warehouse.create.table.header.amount',
              defaultMessage: 'Amount',
            }),
            dataIndex: 'amount',
            editable: true,
            type: 'number',
          },
          {
            title: intl.formatMessage({
              id: 'warehouse.create.table.header.unit',
              defaultMessage: 'Unit',
            }),
            editable: true,
            type: 'unit',
            dataIndex: 'salesUnit',
            selectedEcosystem,
          },
          {
            title: intl.formatMessage({
              id: 'sales.createForm.netPrice.label',
              defaultMessage: 'Net Price',
            }),
            editable: true,
            type: 'price',
            dataIndex: 'price',
            selectedEcosystem,
          },
          {
            title: intl.formatMessage({
              id: 'sales.createForm.tax.label',
              defaultMessage: 'Tax (%)',
            }),
            dataIndex: 'taxValue',
            selectedEcosystem,
          },
          {
            title: intl.formatMessage({
              id: 'sales.createForm.discount.label',
              defaultMessage: 'Discount (%)',
            }),
            editable: true,
            type: 'number',
            dataIndex: 'discount',
            selectedEcosystem,
          },
          {
            title: intl.formatMessage({
              id: 'sales.createForm.sumNet.label',
              defaultMessage: 'Sum net',
            }),
            dataIndex: 'totalNetPrice',
            selectedEcosystem,
          },
          {
            render: (_, record: ProductWithPrice) =>
              entries.length >= 1 ? (
                <Popconfirm
                  title={intl.formatMessage({
                    id: 'warehouse.create.product.remove.confirm',
                    defaultMessage: 'Sure to delete?',
                  })}
                  onConfirm={() => !readOnly && handleDelete(record)}
                >
                  <Button
                    icon={<CloseOutlined />}
                    size="small"
                    shape="circle"
                    disabled={readOnly}
                  />
                </Popconfirm>
              ) : null,
          },
        ];

  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record: ProductWithPrice) => ({
        record,
        data: col.data,
        editable: col.editable,
        dataIndex: col.dataIndex,
        type: col.type,
        title: col.title,
        selectedEcosystem: col.selectedEcosystem,
        handleSave,
        handleProductSelect: handleSelect,
        prodOptions,
        handleProductCreate,
        readOnly,
      }),
    };
  });

  useEffect(() => {
    if (selectedEcosystem) {
      if (!saleId) {
        setProducts([]);
      }
      getProductsForEcosystem(selectedEcosystem, 'view-products')
        ?.then((prods) => {
          setProducts(
            prods.map((prod, key) => ({
              ...prod,
              key,
            })),
          );
        })
        ?.catch((e) => {
          console.warn(e);
        });
    }
  }, [saleId, selectedEcosystem]);

  return (
    <ProductsWithPricesContainer>
      <ProductTable
        pagination={false}
        components={components}
        rowClassName={() =>
          'editable-row animate__animated animate__slideInUp animate__faster'
        }
        bordered
        dataSource={entries}
        columns={columns as any}
        expandable={{
          expandedRowRender: (record: any, namePrefix: number) => (
            <ExpandedRow
              record={record}
              namePrefix={namePrefix}
              onBlur={handleSave}
            />
          ),
        }}
      />
      <div>
        <NewPositionButton
          disabled={readOnly}
          icon={<PlusSquareOutlined />}
          size="middle"
          shape="default"
          type="primary"
          onClick={() => !readOnly && addNewPosition()}
        >
          {intl.formatMessage({
            id: 'sales.new.position',
            defaultMessage: 'New Position',
          })}
        </NewPositionButton>
      </div>
    </ProductsWithPricesContainer>
  );
};

export default ProductWithPriceEntries;
