import React, { FC, useCallback, useState } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { Wrapper } from '@core/layout/components/UserMenu/UserMenu.sc';
import { MenuItem, Icon } from '@todix/ui-components';
import Modal from '@components/Modal';
import { firebase } from '@services/Firebase';
import { FormattedMessage, useIntl } from 'react-intl';
import { Invitation } from '@services/invitations/namespace';
import InvitationsModal from '@core/layout/components/InvitationsModal';

type Props = {
  handleMenuClick: MenuClickEventHandler;
  invitations: Invitation[];
};

export const UserMenu: FC<Props> = ({ handleMenuClick, invitations }) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  const hideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const logoutHandler: MenuClickEventHandler = useCallback(
    (info) => {
      Modal.confirm({
        content: intl.formatMessage({
          id: 'usermenu.logout.confirm',
          defaultMessage: 'Are you sure?',
        }),
        onOk: () => {
          firebase.auth.signOut().then(() => {
            handleMenuClick(info);
            window.location.reload();
          });
        },
        onCancel: () => {
          handleMenuClick(info);
        },
      });
    },
    [handleMenuClick, intl],
  );

  return (
    <Wrapper>
      <Menu onClick={handleMenuClick} selectable={false}>
        <MenuItem
          key={1}
          icon={<Icon.LogoutOutlined />}
          onClick={logoutHandler}
        >
          <FormattedMessage id="usermenu.logout" defaultMessage="Log out" />
        </MenuItem>
        <MenuItem key={2} icon={<Icon.ProfileOutlined />}>
          <Link to={`/settings`}>
            <FormattedMessage
              id="usermenu.profile"
              defaultMessage="Profile Settings"
            />
          </Link>
        </MenuItem>
        {invitations.length > 0 && (
          <MenuItem
            key={3}
            icon={<Icon.UsergroupAddOutlined />}
            onClick={() => setShowModal(true)}
          >
            <FormattedMessage
              id="settings.team.invitations"
              defaultMessage="Invitations"
            />
          </MenuItem>
        )}
        {/* <MenuItem key={4} icon={<Icon.CustomerServiceOutlined />}>
          <FormattedMessage
            id="usermenu.customersupport"
            defaultMessage="Customer Support"
          />
        </MenuItem> */}
      </Menu>
      <InvitationsModal
        footer={false}
        invitations={invitations}
        visible={showModal}
        onCancel={hideModal}
        onOk={hideModal}
        width="50vw"
        intl={intl}
      />
    </Wrapper>
  );
};
