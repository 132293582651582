import React, { FC, CSSProperties } from 'react';
import { Style } from '@react-pdf/types';
import { Text, View } from '@react-pdf/renderer';

type Props = {
  style?: Style;
  forPdf: boolean;
  address?: CommonEcosystemData['address'];
  name?: CommonEcosystemData['name'];
};

const EcosystemAddress: FC<Props> = ({ style, address, forPdf, name }) => {
  return forPdf ? (
    <View style={style}>
      <Text>
        {name || ''}
        {address &&
          `${address.street}, ${address.number}, ${address.city}, ${
            address.zipcode
          }, ${address.country || ''}`}
      </Text>
    </View>
  ) : (
    <div style={style as CSSProperties}>
      {name && <span>{name}</span>}
      {address && (
        <div>
          {`${address.street}, ${address.number}, ${address.city}, ${
            address.zipcode
          }, ${address.country || ''}`}
        </div>
      )}
    </div>
  );
};

export default EcosystemAddress;
