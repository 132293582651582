import { firebase } from '@services/Firebase';
import { patch, post } from '@services/api';
import {
  getCompanyRoles,
  updateCompanyRole,
} from '@services/roles/companyRoles';

const COLLECTION_NAME = 'appRoles';

export const createAppRole = (appRole: AppRole): Promise<string> => {
  return post(COLLECTION_NAME, { ...appRole });
};

export const updateAppRole = (appRole: AppRole): Promise<void> => {
  return patch(`${COLLECTION_NAME}/${appRole.id}`, appRole).then(async () => {
    //update connected company roles
    const companyRoles = await getCompanyRoles(appRole.ecosystemId);
    const matchedRoles = companyRoles.filter((role) => {
      try {
        return JSON.stringify(role.permission).includes(appRole.id as string);
      } catch (e) {
        console.error(e);
        return false;
      }
    });
    await Promise.all(
      matchedRoles.map(async (role) => {
        updateCompanyRole(role.ecosystemId, role.id as string, role);
      }),
    );
  });
};

export const getAppRolesByEcosystem = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((query) =>
      query.docs.map((doc) => ({ ...doc.data(), id: doc.id } as AppRole)),
    );
};
