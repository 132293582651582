import React, { FC } from 'react';
import MoneyInput, { Props as MoneyInputProps } from '@components/MoneyInput';

type Props = MoneyInputProps & {
  onChange?(payload: any): void;
};

const MoneyInputInFormItem: FC<Props> = ({ onChange, ...rest }) => {
  return (
    <MoneyInput
      tabIndex={0}
      onValueChange={({ floatValue }) => {
        onChange?.(floatValue);
      }}
      {...rest}
    />
  );
};

export default MoneyInputInFormItem;
