import React, { FC } from 'react';
import { Badge } from 'antd';

type ProjectNameRendererProps = {
  data: {
    color: string;
    name: string;
  };
};
const ProjectNameRenderer: FC<ProjectNameRendererProps> = (props) => {
  const { data } = props;
  if (data) {
    const { color, name } = data;
    return (
      <Badge
        color={color}
        text={<span style={{ color, fontWeight: 600 }}>{name}</span>}
      />
    );
  }
  return null;
};

export default ProjectNameRenderer;
