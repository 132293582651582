import { post } from '@services/api';
import { Sale, SALES_COLLECTION_NAME } from '@apps/sales/services/namespace';
import moment from 'moment';

const createSale = (sale: Sale) => {
  return post(`${SALES_COLLECTION_NAME}`, {
    ...sale,
    products: sale.products.map((product) =>
      JSON.parse(JSON.stringify(product)),
    ),
    contact: JSON.parse(JSON.stringify(sale.contact)),
    timestamp: moment().valueOf(),
    paymentTerms: JSON.parse(JSON.stringify(sale.paymentTerms)),
    deliveryTerms: JSON.parse(JSON.stringify(sale.deliveryTerms)),
    contactSnapshot: JSON.parse(JSON.stringify(sale.contactSnapshot)),
  } as Sale);
};

export default createSale;
