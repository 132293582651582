import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select } from 'antd';
import { getDocumentsTypesSettings } from '@apps/documents/service';
import { emitCustomEvent } from '@core/services';
import StyledItemForm from '@apps/documents/mainMenu/components/StyledItemForm';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { DocumentType } from '@apps/documents/service/namespace';

const { Item } = Form;

type DocumentTypeProps = {
  ecosystem: string;
  allowingTypes?: DocumentType[];
};

const DocumentType: FC<DocumentTypeProps> = ({
  ecosystem,
  allowingTypes = [],
}) => {
  const intl = useIntl();
  const { readOnly } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [docTypes, setDocTypes] = useState<string[]>([]);

  const onSelect = useCallback((type) => {
    emitCustomEvent('setDocument', {
      type,
    });
  }, []);

  useEffect(() => {
    if (ecosystem) {
      setLoading(true);
      getDocumentsTypesSettings(ecosystem)
        ?.then((results) => {
          if (results) {
            setDocTypes(
              results.docTypes
                .filter((entry) => entry.isActive)
                .map((entry) => entry.docType),
            );
            setLoading(false);
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [ecosystem]);

  return (
    <StyledItemForm
      name="type"
      rules={[
        {
          required: true,
          message: intl.formatMessage({
            id: 'documentprocessing.step1.type.error',
            defaultMessage: 'documentprocessing.step1.type.error',
          }),
        },
      ]}
    >
      <Select
        showSearch
        disabled={readOnly}
        optionFilterProp="label"
        placeholder={intl.formatMessage({
          id: `documentprocessing.step1.type.placeholder`,
          defaultMessage: 'Please select document type...',
        })}
        onSelect={onSelect}
        loading={loading}
        filterOption={(input, option) => {
          if (option && typeof option.label === 'string') {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return true;
        }}
        options={docTypes
          .filter((docType) =>
            allowingTypes?.length
              ? allowingTypes.includes(docType as unknown as DocumentType)
              : true,
          )
          .map((docType) => {
            return {
              label: intl.formatMessage({
                id: `documents.documentTypes.${docType}`,
                defaultMessage: docType,
              }),
              value: docType,
            };
          })}
      />
    </StyledItemForm>
  );
};

type Props = {
  allowingTypes?: DocumentType[];
};

export default ({ allowingTypes }: Props) => {
  return (
    <Item shouldUpdate={true} noStyle>
      {({ getFieldValue }) => {
        const ecosystem = getFieldValue('ecosystem');
        return (
          <DocumentType ecosystem={ecosystem} allowingTypes={allowingTypes} />
        );
      }}
    </Item>
  );
};
