import React, { FC, useState, useCallback } from 'react';
import {
  DraggableModal,
  DraggableModalProvider,
} from 'ant-design-draggable-modal';
import { Spin } from 'antd';
import { useCustomEventListener } from '@core/services';
import { Document } from '@apps/documents/service';
import BaseTab from '@apps/documents/mainMenu/Details/BaseTab';
import styled from 'styled-components';

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export type DocumentPreviewEventName = 'showDocumentPreview';

export interface DocumentPreviewEventPayload {
  document: Document;
}

type DocumentPreviewModalProps = {
  cancelLink?: string;
};

const DocumentPreviewModal: FC<DocumentPreviewModalProps> = ({
  cancelLink,
}) => {
  const [document, setDocument] = useState<Document | null>(null);
  const [show, setShow] = useState(false);
  const [loading] = useState(false);

  const handleCancel = useCallback(() => {
    setShow(false);
    setDocument(null);
  }, []);

  useCustomEventListener<DocumentPreviewEventName, DocumentPreviewEventPayload>(
    'showDocumentPreview',
    (data) => {
      if (data?.document) {
        setDocument(data.document);
        setShow(true);
      }
    },
  );

  if (!document) {
    return null;
  }

  return (
    <DraggableModalProvider>
      <DraggableModal
        visible={show}
        footer={null}
        onCancel={handleCancel}
        title={document.docNumber || document.documentNumber}
        width="70%"
      >
        <Spin spinning={loading}>
          <ModalContent>
            <BaseTab
              initialValues={document}
              readOnly={true}
              cancelLink={cancelLink}
              onCancelClick={handleCancel}
            />
          </ModalContent>
        </Spin>
      </DraggableModal>
    </DraggableModalProvider>
  );
};

export default DocumentPreviewModal;
