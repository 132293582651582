import { firebase } from '@services/Firebase';
import { SUBSCRIPTIONS } from '@services/subscriptions/namespace';
import { SubscriptionWithEcosystem } from '@services/stripe/namespace';

type FilterSub = {
  field: string;
  value: string;
};
const getSubscriptionBy = (
  filter: FilterSub,
): Promise<SubscriptionWithEcosystem | null> | undefined => {
  return firebase?.db
    ?.ref(`${SUBSCRIPTIONS}`)
    .orderByChild(filter.field)
    .equalTo(filter.value)
    .once('value')
    .then((result) => {
      if (result.exists()) {
        const sub = result.val() as Record<string, SubscriptionWithEcosystem>;
        return Object.values(sub)[0] as SubscriptionWithEcosystem;
      }
      return null;
    });
};

export default getSubscriptionBy;
