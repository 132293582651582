import React, { FC } from 'react';
import { FooterGrid } from '@apps/sales/mainMenu/components/SaleCreator/styles.sc';
import { useIntl } from 'react-intl';
import { SaleOperationSettings } from '@apps/sales/services/saleOperation';

type FooterProps = {
  user: User | null;
  ecosystem: Ecosystem;
  settings: SaleOperationSettings | null;
};

const Footer: FC<FooterProps> = ({ user, ecosystem, settings }) => {
  const intl = useIntl();
  return (
    <FooterGrid>
      <div>
        {settings?.footer ? (
          settings.footer.content[0]?.split('\n').map((el, index) => {
            return <div key={index}>{el}</div>;
          })
        ) : (
          <>
            <div>
              {intl.formatMessage({
                id: 'sales.managing.director',
                defaultMessage: 'Managing director',
              })}
            </div>
            <div>{user?.displayName || ''}</div>
            <div>{user?.email || ''}</div>
          </>
        )}
      </div>
      <div>
        {settings?.footer ? (
          settings.footer.content[1]?.split('\n').map((el, index) => {
            return <div key={index}>{el}</div>;
          })
        ) : (
          <>
            {ecosystem.bankAccount && (
              <div>
                <div>{ecosystem.bankAccount.bankName || ''}</div>
                <div>IBAN: {ecosystem.bankAccount.iban || ''}</div>
                <div>SWIFT: {ecosystem.bankAccount.swift || ''}</div>
              </div>
            )}
          </>
        )}
      </div>
      <div>
        {settings?.footer ? (
          settings.footer.content[2]?.split('\n').map((el, index) => {
            return <div key={index}>{el}</div>;
          })
        ) : (
          <div />
        )}
      </div>
      <div>
        {settings?.footer ? (
          settings.footer.content[3]?.split('\n').map((el, index) => {
            return <div key={index}>{el}</div>;
          })
        ) : (
          <div />
        )}
      </div>
    </FooterGrid>
  );
};

export default Footer;
