import React, { FC } from 'react';
import { IntlShape } from 'react-intl';
import intl from '../../../../../../intl';
import { Input, Form } from 'antd';

const { Item } = Form;

type Props = {
  intl: IntlShape;
};

const TitleInput: FC<Props> = () => {
  return (
    <Item
      label={intl.formatMessage({
        id: 'consultants.level.title',
        defaultMessage: 'Level',
      })}
      name="level"
      required
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'consultants.level.title',
          defaultMessage: 'Level',
        })}
      />
    </Item>
  );
};

export default TitleInput;
