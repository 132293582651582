import { styled } from '@styles/themes';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';

const StyledTable = styled(Table)<TableProps<any>>`
  .ant-table-thead {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.06);
  }
  .ant-table-thead > tr > th {
    background: #ffffff;
  }

  .ant-table-tbody > tr > td {
    padding: 8px 8px !important;
  }

  .ant-row {
    margin-bottom: 0 !important;
  }
`;

export default StyledTable;
