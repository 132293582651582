import currency from 'currency.js';
import { currencySigns } from '@services/currency';

type WithSuffixPayload = {
  value: number;
  suffix?: string;
};
const withSuffix = ({ value, suffix }: WithSuffixPayload) => {
  try {
    if (!Number.isNaN(value)) {
      if (!suffix) {
        const symbol = currencySigns['EUR'];
        const price = currency(value, {
          separator: '.',
          decimal: ',',
          precision: 2,
          pattern: '#!',
          symbol,
        }).format();
        return price;
      }
      return `${value.toFixed(2)}${suffix}`;
    }
    return `${value}${suffix}`;
  } catch (e) {
    console.log(e);
    return `${value}`;
  }
};

export default withSuffix;
