import { FilterDateObject } from '@services/filtering/index';
import get from 'lodash.get';
import moment from 'moment';

const dateMatcher = (filterObject: FilterDateObject, row: any, key: string) => {
  let value = get(row, key);
  if (Number.isInteger(value)) {
    value = value = moment(value).format(moment.defaultFormat);
  } else if (
    value &&
    !Number.isInteger(value) &&
    value.includes('T') &&
    value.includes('Z')
  ) {
    // so this ISO format, need to change to our format
    value = moment(value).format(moment.defaultFormat);
  }
  const dateFrom = moment(filterObject.dateFrom);
  const dp = value
    ? !value.includes('.')
      ? moment(value).format(moment.defaultFormat)?.split('.')
      : value.split('.')
    : [];
  const fdp = dateFrom.format(moment.defaultFormat).split('.');

  if (filterObject?.type === 'contains') {
    // we only handling date granulation to day.month.year (no time part)
    if (dp.length === 3 && fdp.length === 3) {
      return dp[0] === fdp[0] && dp[1] === fdp[1] && dp[2] === fdp[2];
    }
    return false;
  }

  if (filterObject?.type === 'notContains') {
    if (!value) {
      return true;
    }
    // we only handling date granulation to day.month.year (no time part)
    if (dp.length === 3 && fdp.length === 3) {
      return dp[0] !== fdp[0] || dp[1] !== fdp[1] || dp[2] !== fdp[2];
    }
    return false;
  }

  if (filterObject?.type === 'lessThan') {
    const momentValue = moment(value, moment.defaultFormat);
    return momentValue.isBefore(dateFrom);
  }

  if (filterObject?.type === 'greaterThan') {
    const momentValue = moment(value, moment.defaultFormat);
    return momentValue.isAfter(dateFrom);
  }

  if (filterObject?.type === 'inRange') {
    const momentValue = moment(value, moment.defaultFormat);
    const dateTo = moment(filterObject.dateTo);
    return momentValue.isBetween(dateFrom, dateTo, undefined, '[]');
  }

  return false;
};

export default dateMatcher;
