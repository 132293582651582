import React, { lazy, Suspense } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Route, Switch } from 'react-router-dom';
import PlatformLayout from '@core/layout';
import SpinContainer from '@components/SpinContainer';
import Sign from '@pages/sign';
import { AppsMainMenuRouting } from '@apps/AppsMainMenuRouting';
import Dashboard from '@pages/dashboard';
import Success from '@pages/order/Success';
import VerifyEmail from '@pages/verifyEmail';

const SettingsLazy = lazy(
  () => import(/* webpackChunkName: "settingsApp" */ '@pages/settings'),
);

const ToS = lazy(() => import(/* webpackChunkName: "tos" */ '@pages/tos'));

const PrivacyPolicy = lazy(
  () => import(/* webpackChunkName: "tos" */ '@pages/privacy_policy'),
);

const ActionHandler = lazy(
  () => import(/* webpackChunkName: "actionHandler" */ '@pages/actionHandler'),
);

const UsersLazy = lazy(
  () => import(/* webpackChunkName: "adminApp" */ '@pages/admin/users/index'),
);

const AdminLazy = lazy(
  () =>
    import(
      /* webpackChunkName: "adminApp" */ '@pages/admin/setupPlatform/SetupPlatform'
    ),
);
export const AppRoutes = () => {
  const handle = useFullScreenHandle();

  return (
    <FullScreen handle={handle}>
      <Suspense fallback={<SpinContainer />}>
        <Switch>
          <Route path="/admin/users" component={UsersLazy} />
          <Route path="/admin/setup-platform" component={AdminLazy} exact />
          <Route path="/action_handler" component={ActionHandler} />
          <Route path="/sign" component={Sign} />
          <Route path="/tos" component={ToS} />
          <Route path="/privacy_policy" component={PrivacyPolicy} />
          <Route path="/stripe_success" component={Success} />
          <Route path="/verifyemail" component={VerifyEmail} />
          <PlatformLayout fullScreenHandler={handle}>
            <Suspense fallback={<SpinContainer />}>
              <Switch>
                <Route path="/settings/:uid?" component={SettingsLazy} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/" component={Dashboard} exact />
                <AppsMainMenuRouting />
              </Switch>
            </Suspense>
          </PlatformLayout>
        </Switch>
      </Suspense>
    </FullScreen>
  );
};
