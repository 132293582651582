import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import intl from '../../../../../../intl';

const { Item } = Form;

type Props = {
  readOnly?: boolean;
};

const NeedDate: FC<Props> = ({ readOnly }) => {
  const disabledDate = (current: moment.Moment) =>
    current && current <= moment().subtract(1, 'day').endOf('day');
  return (
    <Item
      name="needDate"
      label={<FormattedMessage id="need.date" defaultMessage="Need date" />}
      required
      rules={[
        {
          required: true,
          message: (
            <FormattedMessage
              id="need.date.missing"
              defaultMessage="Need date is missing"
            />
          ),
        },
      ]}
    >
      <DatePicker
        disabled={readOnly}
        format={moment.defaultFormat}
        disabledDate={disabledDate}
        placeholder={intl.formatMessage({
          id: 'select.date',
          defaultMessage: 'Select date',
        })}
      />
    </Item>
  );
};

export default NeedDate;
