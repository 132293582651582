type AfterDaysTerm = {
  type: 'afterDays';
  value: number;
};

type Immediately = {
  type: 'immediately';
};

type AlreadyPaid = {
  type: 'alreadyPaid';
};

export type Term = AfterDaysTerm | Immediately | AlreadyPaid;

export type PaymentTerm = {
  id?: string;
  ecosystemId: string;
  name: string;
  category?: string;
  description?: string;
  isActive?: boolean;
  timestamp?: number;
  term?: Term;
};

export const PURCHASES_PAYMENT_TERMS_COLLECTION_NAME = 'purchasesPaymentTerms';
