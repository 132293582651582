import { firebase } from '@services/Firebase';
import { LICENCES } from '@services/licences/namespace';
import stripe from '@services/stripe';
import { database } from 'firebase/app';

type Payload = {
  ecosystemId: string;
  userId: string;
};

const calculateSeatsForEcosystem = ({
  ecosystemId,
  userId,
}: Payload): Promise<Seat[]> | undefined => {
  if (!firebase?.db) {
    return Promise.resolve([]);
  }
  return stripe
    .getActiveSubscriptions({
      ecosystemId,
      userId,
    })
    .then((subs) => {
      return (firebase.db as database.Database)
        .ref(`${LICENCES}/${ecosystemId}`)
        .once('value')
        .then((results) => {
          if (!results.exists()) {
            return Promise.resolve([]);
          }
          const records = results.val() as Record<string, Licence>;
          if (!records) {
            return Promise.resolve([]);
          }
          const licences = Object.values(records);
          let seats: Seat[] = [];
          subs.forEach((sub) => {
            sub.items.data.forEach((subItem) => {
              const appId = subItem.price.product;
              const licInUse = licences.filter(
                (lic) =>
                  lic.status === 'active' &&
                  lic.ecosystem === sub.ecosystem &&
                  lic.appId === appId,
              );

              const seat = {
                ecosystem: sub.ecosystem,
                subId: sub.id,
                appId,
                seats: subItem.quantity - licInUse.length,
              } as Seat;
              seats = [...seats, seat];
            });
          });

          return seats;
        });
    });
};

export default calculateSeatsForEcosystem;
