import React, { FC, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useIntl } from 'react-intl';
import { WarehouseStorageStatus } from '@apps/warehouse/services/namespace';
import fetchProductStats from '@apps/warehouse/services/fetchProductStats';
import ProductStat from '@apps/warehouse/widgets/WarehouseForm/ProductStat';

type ProductStatsProps = {
  storageId: string;
  ecosystem: string;
  productId: string;
};

const ProductStats: FC<ProductStatsProps> = ({
  productId,
  ecosystem,
  storageId,
}) => {
  const intl = useIntl();
  const [productsStats, setProductStats] = useState<
    Partial<WarehouseStorageStatus>
  >({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchProductStats({
      productId,
      ecosystem,
      storageId,
    })
      ?.then(setProductStats)
      .finally(() => {
        setIsLoading(false);
      });
  }, [productId, ecosystem, storageId]);

  return (
    <div>
      <Spin spinning={isLoading}>
        <ProductStat
          label={intl.formatMessage({
            id: 'warehousetab.product.stats.inStock',
            defaultMessage: 'Currently in stock',
          })}
          placeholder={'0'}
          value={productsStats.inStock}
        />
        <ProductStat
          label={intl.formatMessage({
            id: 'warehousetab.product.stats.incoming',
            defaultMessage: 'Incoming',
          })}
          placeholder={'0'}
          value={productsStats.incoming}
        />
        <ProductStat
          label={intl.formatMessage({
            id: 'warehousetab.product.stats.reserved',
            defaultMessage: 'Reserved',
          })}
          placeholder={'0'}
          value={productsStats.reserved}
        />
        <ProductStat
          label={intl.formatMessage({
            id: 'warehousetab.product.stats.outgoing',
            defaultMessage: 'Outgoing',
          })}
          placeholder={'0'}
          value={productsStats.outgoing}
        />
        <ProductStat
          label={intl.formatMessage({
            id: 'warehousetab.product.stats.available',
            defaultMessage: 'Available',
          })}
          placeholder={'0'}
          value={productsStats.available}
        />
        <ProductStat
          label={intl.formatMessage({
            id: 'warehousetab.product.stats.value',
            defaultMessage: 'Aver. EUR',
          })}
          placeholder={'0'}
          value={productsStats.value}
        />
      </Spin>
    </div>
  );
};

export default ProductStats;
