import React, { FC, useRef } from 'react';
import { Form } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import TextArea from 'antd/lib/input/TextArea';
import { ProductWithPrice } from '@apps/purchases/services';

const { Item } = Form;

type Props = {
  namePrefix: number;
  record: ProductWithPrice;
  onBlur(newRecord: ProductWithPrice): void;
};

const ExpandedRow: FC<Props> = ({ record, namePrefix, onBlur }) => {
  const intl = useIntl();
  const descriptionRef = useRef('');
  return (
    <Item
      initialValue={record.description}
      name={
        namePrefix !== undefined
          ? ['products', namePrefix, `description`]
          : `description`
      }
      rules={[
        {
          type: 'string',
          max: 5000,
          message: (
            <FormattedMessage
              id="products.form.description.error"
              defaultMessage="Description cannot be longer than 5000 characters"
            />
          ),
        },
      ]}
    >
      <TextArea
        placeholder={intl.formatMessage({
          id: 'products.form.description.placeholder',
          defaultMessage: 'Maximum 500 characters...',
        })}
        onChange={(event) => {
          descriptionRef.current = event.target.value;
        }}
        onBlur={() => {
          onBlur({
            ...record,
            description: descriptionRef.current,
          });
        }}
      />
    </Item>
  );
};

export default ExpandedRow;
