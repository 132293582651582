import React, { FC } from 'react';
import { Form } from 'antd';
import { Upload } from '@components/FormFields';
import storage from '@services/Firebase/Storage';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { useIntl } from 'react-intl';
import { RcFile } from 'rc-upload/lib/interface';

export const MAXIMUM_FILE_SIZE = 2; //in MBs

type AssetUploadProps = {
  showAvatar?: boolean;
  fieldName: string;
  acceptMime?: string;
  label: string;
  onUploadSuccess?(file: RcFile): void;
};

const AssetUpload: FC<AssetUploadProps> = ({
  label,
  fieldName,
  acceptMime = 'image/x-png,image/jpeg',
  showAvatar = true,
  onUploadSuccess,
}) => {
  const intl = useIntl();
  const { getFieldValue, setFields, readOnly } = useFormContext();

  const normFile = (e: any) => {
    return e.file.response ? e.file.response.downloadUrl : e.file;
  };

  return (
    <Form.Item
      label={label}
      shouldUpdate={(prev, curr) => prev[fieldName] !== curr[fieldName]}
    >
      {() => {
        return (
          <Form.Item name={fieldName} getValueFromEvent={normFile}>
            <Upload
              showAvatar={showAvatar}
              disabled={readOnly}
              multiple={false}
              src={getFieldValue(fieldName)}
              showUploadList={false}
              accept={acceptMime}
              beforeUpload={(file) => {
                setFields([
                  {
                    name: fieldName,
                    errors: [],
                  },
                ]);
                const canBeUploaded =
                  file.size / 1024 / 1024 < MAXIMUM_FILE_SIZE;

                if (!canBeUploaded) {
                  setFields([
                    {
                      name: fieldName,
                      errors: [
                        intl.formatMessage(
                          {
                            id: 'detailcontactform.avatarupload.error',
                            defaultMessage:
                              'File must be smaller than {max}MB!',
                          },
                          { max: MAXIMUM_FILE_SIZE },
                        ),
                      ],
                    },
                  ]);
                }

                return canBeUploaded;
              }}
              customRequest={(event) => {
                storage.upload(
                  (event.file as RcFile).name,
                  event.file as Blob,
                  (progress: any) => {
                    if (progress.status === 'success') {
                      setFields([
                        {
                          name: fieldName,
                          value: progress.downloadUrl,
                        },
                      ]);

                      if (onUploadSuccess) {
                        onUploadSuccess(event.file as RcFile);
                      }

                      if (event) {
                        //@ts-ignore
                        event.onSuccess(progress, event.file);
                      }
                    }
                  },
                );
              }}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default AssetUpload;
