// Example locale file for English, give this to your locale team to translate
export default {
  // Set Filter
  selectAll: '(Alle auswählen)',
  selectAllSearchResults: '(Alle Suchergebnisse auswählen)',
  searchOoo: 'Suchen...',
  blanks: '(Leer)',
  noMatches: 'Keine Treffer',

  // Number Filter & Text Filter
  filterOoo: 'Filter...',
  equals: 'gleich',
  notEqual: 'ungleich',
  empty: 'eins auswählen',

  // Number Filter
  lessThan: 'Kleiner als',
  greaterThan: 'Größer als',
  lessThanOrEqual: 'Kleiner oder gleich',
  greaterThanOrEqual: 'Größer oder gleich',
  inRange: 'Bereich',
  inRangeStart: 'von',
  inRangeEnd: 'bis',

  // Text Filter
  contains: 'Beinhaltet',
  notContains: 'Beinhaltet nicht',
  startsWith: 'Beginnt mit',
  endsWith: 'Endet mit',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'UND',
  orCondition: 'ODER',

  // Filter Buttons
  applyFilter: 'Anwenden',
  resetFilter: 'Zurücksetzen',
  clearFilter: 'Löschen',
  cancelFilter: 'Abbrechen',

  // Filter Titles
  textFilter: 'Textfilter',
  numberFilter: 'Zahlenfilter',
  dateFilter: 'Datumsfilter',
  setFilter: 'Filter einstellen',

  // Side Bar
  columns: 'Spalten',
  filters: 'Filter',

  // columns tool panel
  pivotMode: 'Pivot Modus',
  groups: 'Zeilengruppe',
  rowGroupColumnsEmptyMessage: 'Für Zeilengruppe hierher verschieben',
  values: 'Werte',
  valueColumnsEmptyMessage: 'Zum Zusammenfassen hierher verschieben',
  pivots: 'Pivotspalte',
  pivotColumnsEmptyMessage: 'Hierher verschieben um Pivotspalte anzuwenden',

  // Header of the Default Group Column
  group: 'Gruppe',

  // Other
  loadingOoo: 'Lädt...',
  noRowsToShow: 'Keine Zeilen zum anzeigen',
  enabled: 'Aktiviert',

  // Menu
  pinColumn: 'Spalte anheften',
  pinLeft: 'Links anheften',
  pinRight: 'Rechts anheften',
  noPin: 'Nicht anheften',
  valueAggregation: 'Werte aggregieren',
  autosizeThiscolumn: 'Spalte automatisch anpassen',
  autosizeAllColumns: 'Alle Spalten automatisch anpassen',
  groupBy: 'Gruppieren',
  ungroupBy: 'Gruppierung aufheben',
  resetColumns: 'Spalten zurücksetzen',
  expandAll: 'Alle ausklappen',
  collapseAll: 'Alle einklappen',
  copy: 'Kopieren',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Kopieren mit Überschrift',
  paste: 'Einfügen',
  ctrlV: 'Ctrl+V',
  export: 'Exportieren',
  csvExport: 'CSV Export',
  excelExport: 'Excel Export',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Sum',
  min: 'Min',
  max: 'Max',
  none: 'Keine',
  count: 'Zählen',
  avg: 'Mittelwert',
  filteredRows: 'Gefiltert',
  selectedRows: 'Ausgewählt',
  totalRows: 'Zeilen insgesamt',
  totalAndFilteredRows: 'Zeilen',
  more: 'Mehr',
  to: 'bis',
  of: 'von',
  page: 'Seite',
  nextPage: 'Nächste Seite',
  lastPage: 'Letzte Seite',
  firstPage: 'Erste Seite',
  previousPage: 'Vorherige Seite',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Pivottabelle & Pivotmodus',
  pivotChart: 'Pivottabelle',
  chartRange: 'Tabellenbereich',

  columnChart: 'Säulendiagramm',
  groupedColumn: 'Gruppiert',
  stackedColumn: 'Gestapelt',
  normalizedColumn: '100% Gestapelt',

  barChart: 'Balkendiagramm',
  groupedBar: 'Gruppiert',
  stackedBar: 'Gestapelt',
  normalizedBar: '100% gestapelt',

  pieChart: 'Kreisdiagramm',
  pie: 'Kreis',
  doughnut: 'Ring',

  line: 'Linie',

  xyChart: 'Streudiagramm',
  scatter: 'Streudiagramm',
  bubble: 'Blasendiagramm',

  areaChart: 'Flächendiagramm',
  area: 'Fläche',
  stackedArea: 'Gestapelt',
  normalizedArea: '100% gestapelt',

  histogramChart: 'Histogramm',

  // Charts
  pivotChartTitle: 'Pivottabelle',
  rangeChartTitle: 'Spannweitenkarte',
  settings: 'Einstellungen',
  data: 'Daten',
  format: 'Format',
  categories: 'Kategorien',
  defaultCategory: '(Keine)',
  series: 'Serie',
  xyValues: 'X Y Werte',
  paired: 'Gepaart',
  axis: 'Achse',
  navigator: 'Navigator',
  color: 'Farbe',
  thickness: 'Stärke',
  xType: 'X Typ',
  automatic: 'Automatisch',
  category: 'Kategorie',
  number: 'Nummer',
  time: 'Zeit',
  xRotation: 'X Rotation',
  yRotation: 'Y Rotation',
  ticks: 'Zeichen',
  width: 'Stärke',
  height: 'Größe',
  length: 'Länge',
  padding: 'Füllung',
  spacing: 'Abstand',
  chart: 'Tabelle',
  title: 'Titel',
  titlePlaceholder: 'Tabellenname - Doppelklick zum bearbeiten',
  background: 'Hintergrund',
  font: 'Schrift',
  top: 'Oben',
  right: 'Rechts',
  bottom: 'Unten',
  left: 'Links',
  labels: 'Label',
  size: 'Größe',
  minSize: 'Minimalgröße',
  maxSize: 'Maximalgröße',
  legend: 'Legende',
  position: 'Position',
  markerSize: 'Markergröße',
  markerStroke: 'Markierungsstrich',
  markerPadding: 'Markerrand',
  itemSpacing: 'Element Abstand',
  itemPaddingX: 'Element Abstand X',
  itemPaddingY: 'Element Abstand Y',
  layoutHorizontalSpacing: 'Horizontaler Abstand',
  layoutVerticalSpacing: 'Vertikaler Abstand',
  strokeWidth: 'Markierungsbreite',
  offset: 'Versatz',
  offsets: 'Versätze',
  tooltips: 'Kurzinfo',
  callout: 'Textbox',
  markers: 'Marker',
  shadow: 'Schattierung',
  blur: 'Blur',
  xOffset: 'X Versatz',
  yOffset: 'Y Versatz',
  lineWidth: 'Linienstärke',
  normal: 'Normal',
  bold: 'Fett',
  italic: 'Kursiv',
  boldItalic: 'Fett Kursiv',
  predefined: 'Voreingestellt',
  fillOpacity: 'Füllstärke',
  strokeOpacity: 'Linienstärke',
  histogramBinCount: 'Bin count',
  columnGroup: 'Spalte',
  barGroup: 'Balken',
  pieGroup: 'Kreis',
  lineGroup: 'Linie',
  scatterGroup: 'Punkte',
  areaGroup: 'Fläche',
  histogramGroup: 'Histogram',
  groupedColumnTooltip: 'Gruppiert',
  stackedColumnTooltip: 'Gestapelt',
  normalizedColumnTooltip: '100% Gestapelt',
  groupedBarTooltip: 'Gruppiert',
  stackedBarTooltip: 'Gestapelt',
  normalizedBarTooltip: '100% gestapelt',
  pieTooltip: 'Kreis',
  doughnutTooltip: 'Ring',
  lineTooltip: 'Linie',
  groupedAreaTooltip: 'Fläche',
  stackedAreaTooltip: 'Gestapelt',
  normalizedAreaTooltip: '100% gestapelt',
  scatterTooltip: 'Streudiagramm',
  bubbleTooltip: 'Blasen',
  histogramTooltip: 'Histogramm',
  noDataToChart: 'Keine Daten für Diagramm verfügbar.',
  pivotChartRequiresPivotMode: 'Pivottabelle benötigt aktivierten Pivotmodus.',
  chartSettingsToolbarTooltip: 'Menü',
  chartLinkToolbarTooltip: 'Verlinkt mit Gitter',
  chartUnlinkToolbarTooltip: 'Nicht verlinkt mit Gitter',
  chartDownloadToolbarTooltip: 'Tabelle herunterladen',

  // ARIA
  ariaHidden: 'versteckt',
  ariaVisible: 'sichtbar',
  ariaChecked: 'überprüft',
  ariaUnchecked: 'ungeprüft',
  ariaIndeterminate: 'unbestimmt',
  ariaColumnSelectAll: 'Toggle wählt alle Spalten aus',
  ariaInputEditor: 'Eingabeeditor',
  ariaDateFilterInput: 'Datumsfiltereingabe',
  ariaFilterInput: 'Filtereingabe',
  ariaFilterColumnsInput: 'Spaltenfiltereingabe',
  ariaFilterValue: 'Filterwert',
  ariaFilterFromValue: 'Filter von Wert',
  ariaFilterToValue: 'Filter bis Wert',
  ariaFilteringOperator: 'Filteroperator',
  ariaColumnToggleVisibility: 'Spaltentoggle Sichtbarkeit',
  ariaColumnGroupToggleVisibility: 'Spaltengruppierungstoggle Sichtbarkeit',
  ariaRowSelect: 'Leertaste drücken um Zeile auszuwählen',
  ariaRowDeselect: 'Leertaste drücken um Zeile abzuwählen',
  ariaRowToggleSelection: 'Leertaste drücken um Zeilen auszuwählen',
  ariaRowSelectAll: 'Leertaste drücken um alle Zeilen auszuwählen',
  ariaSearch: 'Suchen',
  ariaSearchFilterValues: 'Suche Filterwerte',
};
