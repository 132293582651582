export type PurchaseOperationStatus =
  | 'empty'
  | 'inProgress'
  | 'draft'
  | 'done'
  | 'cancelled'
  | 'overDelivered';

const purchaseStatusColors: Record<PurchaseOperationStatus, string> = {
  empty: '#d9d9d9',
  inProgress: '#1890ff',
  draft: '#1890ff',
  done: '#52c41a',
  cancelled: '#ff4d4f',
  overDelivered: '#fadb14',
};

export default purchaseStatusColors;
