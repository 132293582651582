import React from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const FirstName = ({ readOnly }: { readOnly?: boolean }) => {
  const intl = useIntl();
  return (
    <Form.Item
      name="firstName"
      label={
        <FormattedMessage
          id="documentprocessing.step2.firstname.label"
          defaultMessage="First name"
        />
      }
      rules={[
        {
          type: 'string',
          max: 40,
          message: (
            <FormattedMessage
              id="documentprocessing.step2.firstname.error.max"
              defaultMessage="First time cannot be longer than 40 characters"
            />
          ),
        },
      ]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'documentprocessing.step2.firstname.placeholder',
          defaultMessage: 'First name...',
        })}
        autoComplete="nope"
        disabled={readOnly}
      />
    </Form.Item>
  );
};

export default FirstName;
