import React, { FC } from 'react';
import { Form } from 'antd';

type Props = {
  emptyIcon: React.ReactNode;
  contentIcon: React.ReactNode;
  emptyIndicator(): boolean;
};

const ContentIndicator: FC<Props> = ({
  emptyIndicator,
  contentIcon,
  emptyIcon,
}) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {() => {
        return emptyIndicator() ? emptyIcon : contentIcon;
      }}
    </Form.Item>
  );
};

export default ContentIndicator;
