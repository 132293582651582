import React from 'react';
import {
  EditOutlined,
  UnorderedListOutlined,
  CheckOutlined,
  DropboxOutlined,
  EuroOutlined,
  WalletOutlined,
  NotificationOutlined,
  ContainerOutlined,
  ForwardOutlined,
} from '@ant-design/icons';

const stepTypeIconMapper: Record<string, React.FC> = {
  requestForQuotation: EditOutlined,
  order: UnorderedListOutlined,
  supplierOrderConfirmation: CheckOutlined,
  upfrontInvoice: ContainerOutlined,
  incomingDeliveryNote: ForwardOutlined,
  goodsReciept: DropboxOutlined,
  invoice: EuroOutlined,
  creditNote: WalletOutlined,
  deliveryReminder: NotificationOutlined,
};

export default stepTypeIconMapper;
