import { LayoutField, DocumentType } from '@apps/documents/service/namespace';
import invoiceLayouts from '@apps/documents/service/invoice.layouts';
import performaInvoiceLayouts from '@apps/documents/service/performaInvoice.layouts';
import dunningLetterLayouts from '@apps/documents/service/dunningLetter.layouts';
import quotationLayouts from '@apps/documents/service/quotation.layouts';
import newTypeLayouts from '@apps/documents/service/newtype.layouts';
import notaryDeedLayouts from '@apps/documents/service/notaryDeed.layouts';
import crExtractLayouts from '@apps/documents/service/crExtract.layouts';
import shareholderResolutionLayouts from '@apps/documents/service/shareholderResolution.layouts';
import businessRegistrationLayouts from '@apps/documents/service/businessRegistration.layouts';
import letterLayouts from '@apps/documents/service/letter.layouts';
import pictureLayouts from '@apps/documents/service/picture.layouts';
import catalogueLayouts from '@apps/documents/service/catalogue.layouts';
import certificateLayouts from '@apps/documents/service/certificate.layouts';
import commonDocumentationLayouts from '@apps/documents/service/commonDocumentation.layouts';
import courtFilesLayouts from '@apps/documents/service/courtFiles.layouts';
import creditNoteLayouts from '@apps/documents/service/creditNote.layouts';
import deliveryNoteLayouts from '@apps/documents/service/deliveryNote.layouts';
import employeeAbsenceNoteLayouts from '@apps/documents/service/employeeAbsenceNote.layouts';
import employmentContractLayouts from '@apps/documents/service/employmentContract.layouts';
import financialContractLayouts from '@apps/documents/service/financialContract.layouts';
import leaseContractLayouts from '@apps/documents/service/leaseContract.layouts';
import ndaLayouts from '@apps/documents/service/nda.layouts';
import orderLayouts from '@apps/documents/service/order.layouts';
import dataPrivacyStatement from '@apps/documents/service/dataPrivacyStatement.layouts';
import orderConfirmationLayouts from '@apps/documents/service/orderConfirmation.layouts';
import rentalAgreementLayouts from '@apps/documents/service/rentalAgreement.layouts';
import requestForQuotationLayouts from '@apps/documents/service/requestForQuotation.layouts';
import technicalDrawingLayouts from '@apps/documents/service/technicalDrawing.layouts';
import termsAndConditionsLayouts from '@apps/documents/service/termsAndConditions.layouts';
import reportLayouts from '@apps/documents/service/report.layouts';
import certificateOfRegistrationLayouts from '@apps/documents/service/certificateOfRegistration.layouts';

export type LayoutDestination =
  | 'detailedForm'
  | 'sideView'
  | 'processing'
  | 'processingSummary';

export const layoutsMap: Record<
  DocumentType,
  (destination: LayoutDestination) => LayoutField[]
> = {
  businessRegistration: (destination) =>
    businessRegistrationLayouts[destination],
  catalogue: (destination) => catalogueLayouts[destination],
  certificate: (destination) => certificateLayouts[destination],
  commonDocumentation: (destination) => commonDocumentationLayouts[destination],
  courtFiles: (destination) => courtFilesLayouts[destination],
  creditNote: (destination) => creditNoteLayouts[destination],
  crExtract: (destination) => crExtractLayouts[destination],
  deliveryNote: (destination) => deliveryNoteLayouts[destination],
  dunningLetter: (destination) => dunningLetterLayouts[destination],
  employeeAbsenceNote: (destination) => employeeAbsenceNoteLayouts[destination],
  employmentContract: (destination) => employmentContractLayouts[destination],
  financialContract: (destination) => financialContractLayouts[destination],
  invoice: (destination) => invoiceLayouts[destination],
  performaInvoice: (destination) => performaInvoiceLayouts[destination],
  leaseContract: (destination) => leaseContractLayouts[destination],
  letter: (destination) => letterLayouts[destination],
  nda: (destination) => ndaLayouts[destination],
  notaryDeed: (destination) => notaryDeedLayouts[destination],
  order: (destination) => orderLayouts[destination],
  dataPrivacyStatement: (destination) => dataPrivacyStatement[destination],
  orderConfirmation: (destination) => orderConfirmationLayouts[destination],
  certificateOfRegistration: (destination) =>
    certificateOfRegistrationLayouts[destination],
  picture: (destination) => pictureLayouts[destination],
  quotation: (destination) => quotationLayouts[destination],
  rentalAgreement: (destination) => rentalAgreementLayouts[destination],
  report: (destination) => reportLayouts[destination],
  requestForQuotation: (destination) => requestForQuotationLayouts[destination],
  shareholderResolution: (destination) =>
    shareholderResolutionLayouts[destination],
  technicalDrawing: (destination) => technicalDrawingLayouts[destination],
  termsAndConditions: (destination) => termsAndConditionsLayouts[destination],
  newType: (destination) => newTypeLayouts[destination],
  productPicture: (destination) => pictureLayouts[destination],
};

export const getLayout = (
  type: DocumentType,
  readOnly = true,
  destination: LayoutDestination,
): LayoutField[] => {
  const layoutFunc = layoutsMap[type];

  if (!layoutFunc) {
    throw new Error(`Unsupported type of document: ${type}`);
  }

  let layout = layoutFunc(destination);

  if (!layout) {
    throw new Error(`Unsupported layout destination: ${destination}`);
  }

  if (readOnly) {
    layout = layout.map((item) => ({ ...item, readOnly: true }));
  }

  return layout;
};
