import { UserAction, UserActionTypes } from '@core/store/user/actions';

export type UserState = {
  data: User | null | undefined;
  isLoading: boolean;
};

export const initial: UserState = {
  data: undefined,
  isLoading: true,
};

export const userReducer = (state = initial, action: UserAction) => {
  switch (action.type) {
    case UserActionTypes.SET_USER: {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    case UserActionTypes.SET_USER_APPS_ORDER: {
      return {
        ...state,
        data: {
          ...state.data,
          menuAppsOrder: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
