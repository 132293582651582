import { BucketStatus } from '@apps/purchases/services/buckets';

export const COLLECTION = 'positionMonitor';

export type MonitorStatus = {
  status?: BucketStatus;
  relatedDocumentId?: string;
  timestamp?: number;
  bucketId?: string;
};

export type PositionMonitor = Record<string, MonitorStatus>;
