import React, { FC, useCallback, useEffect, useState } from 'react';
import { Checkbox, Spin } from 'antd';
import getRelation from '@services/entityRelations/getRelation';
import removeRelation from '@services/entityRelations/removeRelation';
import createRelation from '@services/entityRelations/createRelation';
import SpinContainer from '@components/SpinContainer';
import { emitCustomEvent } from '@core/services';

type RelationSelectRendererProps = {
  data: {
    id: string;
    ecosystem: string;
  };
  colDef: {
    colId: string;
  };
};

const RelationSelectRenderer: FC<RelationSelectRendererProps> = ({
  data,
  colDef: { colId },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [relationId, setRelationId] = useState('');

  useEffect(() => {
    if (data) {
      const parentParts = colId.split(':');
      if (!parentParts[0] || !parentParts[1]) {
        return;
      }
      setIsLoading(true);
      getRelation({
        parentId: parentParts[1],
        childId: data.id,
        ecosystem: data.ecosystem,
      })
        .then((relation) => {
          if (relation.length) {
            setRelationId(relation[0].id as string);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [colId, data]);

  const handleChange = useCallback(
    (event) => {
      if (data) {
        const parentParts = colId.split(':');
        if (event.target.checked) {
          if (!parentParts[0] || !parentParts[1]) {
            emitCustomEvent('relationToAdd', data);
            setRelationId('undefined');
            return;
          }
          setIsLoading(true);
          createRelation({
            ecosystem: data.ecosystem,
            parentId: parentParts[1],
            parentType: parentParts[0],
            childId: data.id,
            childType: 'document', //TODO: get child type instead hardcoded
            parentRefCollection: '',
            childRefCollection: '',
          })
            .then((id) => {
              setRelationId(id);
              emitCustomEvent('relationCreated', data);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          if (!parentParts[0] || !parentParts[1]) {
            emitCustomEvent('relationRemoved', data);
            setRelationId('');
            return;
          }
          removeRelation(relationId)
            .then(() => {
              setRelationId('');
              emitCustomEvent('relationRemoved', data);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    },
    [colId, data, relationId],
  );
  return (
    <Spin spinning={isLoading} indicator={<SpinContainer />}>
      <Checkbox onChange={handleChange} checked={!!relationId} />
    </Spin>
  );
};

export default RelationSelectRenderer;
