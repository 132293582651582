import React, { FC, useState, useEffect, useMemo } from 'react';
import { Modal, Input, Button, Form, Row, Col, Typography } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { PlusCircleOutlined } from '@ant-design/icons';
import getBatchNumbers from '@apps/warehouse/services/getBatchNumbers';
import {
  BatchNumberEntry,
  FlowOption,
} from '@apps/warehouse/services/namespace';

const { Text } = Typography;

const BatchNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;

  & {
    .ant-row {
      margin-bottom: 10px !important;
    }
  }
`;

const StyledModal = styled(Modal)`
  top: 24px !important;
  padding-bottom: 0 !important;

  & {
    .ant-modal-body {
      height: 80vh;
      overflow: auto;
    }
  }
`;

const TotalAmount = styled(Text)<{ isComplete: boolean }>`
  font-weight: bold;
  color: ${(props) => (props.isComplete ? '#52c41a' : '#ff4d4f')};
  margin-bottom: 16px;
  display: block;
`;

const PlusIcon = styled(PlusCircleOutlined)`
  font-size: 20px;
  color: #1890ff;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    color: #40a9ff;
  }
`;

interface BatchEntry {
  batchNumber: string;
  amount: number;
}

interface BatchNumbersModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (batchEntries: BatchNumberEntry[]) => void;
  productId?: string;
  positionId?: string;
  ecosystem: string;
  quantity: number;
  initialBatchNumbers?: BatchNumberEntry[];
  flowType?: FlowOption | null;
}

const BatchNumbersModal: FC<BatchNumbersModalProps> = ({
  visible,
  onCancel,
  onSave,
  productId,
  ecosystem,
  quantity,
  positionId,
  initialBatchNumbers,
  flowType,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [batchEntries, setBatchEntries] = useState<BatchEntry[]>([
    { batchNumber: '', amount: 1 },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<number, string>>({});
  const [existingBatchEntries, setExistingBatchEntries] = useState<
    BatchNumberEntry[]
  >([]);
  const [amountToRemove, setAmountToRemove] = useState<Record<number, number>>(
    {},
  );

  const totalAssignedAmount = useMemo(() => {
    if (flowType === 'manual_take_out') {
      return Object.values(amountToRemove).reduce(
        (sum, amount) => sum + (amount || 0),
        0,
      );
    }
    return batchEntries.reduce((sum, entry) => sum + (entry.amount || 0), 0);
  }, [batchEntries, amountToRemove, flowType]);

  const remainingAmount = useMemo(() => {
    return quantity - totalAssignedAmount;
  }, [quantity, totalAssignedAmount]);

  const isComplete = useMemo(() => {
    return remainingAmount === 0;
  }, [remainingAmount]);

  useEffect(() => {
    if (visible) {
      setLoading(true);
      setAmountToRemove({});

      if (initialBatchNumbers && initialBatchNumbers.length > 0) {
        const initialEntries = initialBatchNumbers.map((entry) => ({
          batchNumber: entry.batchNumber,
          amount: entry.amount || 1,
        }));

        if (
          initialEntries.reduce((sum, entry) => sum + entry.amount, 0) <
          quantity
        ) {
          initialEntries.push({ batchNumber: '', amount: 1 });
        }

        setBatchEntries(initialEntries);
        setExistingBatchEntries([]);
        setLoading(false);
      } else {
        getBatchNumbers({
          ecosystem,
          positionId: flowType === 'manual_take_out' ? undefined : positionId,
          productId,
        })
          .then((entries) => {
            if (entries?.length) {
              setExistingBatchEntries(entries);

              const fetchedEntries = entries.map((entry) => ({
                batchNumber: entry.batchNumber,
                amount: entry.amount || 1,
              }));

              if (flowType === 'manual_take_out') {
                const initialAmountToRemove: Record<number, number> = {};
                fetchedEntries.forEach((_, index) => {
                  initialAmountToRemove[index] = 0;
                });
                setAmountToRemove(initialAmountToRemove);
              }

              if (
                fetchedEntries.reduce((sum, entry) => sum + entry.amount, 0) <
                quantity
              ) {
                fetchedEntries.push({ batchNumber: '', amount: 1 });
              }

              setBatchEntries(fetchedEntries);
            } else {
              setBatchEntries([{ batchNumber: '', amount: 1 }]);
              setExistingBatchEntries([]);
            }
            setLoading(false);
          })
          .catch(() => {
            setBatchEntries([{ batchNumber: '', amount: 1 }]);
            setExistingBatchEntries([]);
            setLoading(false);
          });
      }

      setErrors({});
    }
  }, [
    visible,
    quantity,
    ecosystem,
    positionId,
    productId,
    initialBatchNumbers,
    flowType,
  ]);

  const handleBatchNumberChange = (index: number, value: string) => {
    const isExistingEntry = existingBatchEntries.some(
      (entry) => entry.batchNumber === batchEntries[index].batchNumber,
    );

    if (isExistingEntry) {
      return;
    }

    const newBatchEntries = [...batchEntries];
    newBatchEntries[index].batchNumber = value;
    setBatchEntries(newBatchEntries);

    if (errors[index]) {
      const newErrors = { ...errors };
      delete newErrors[index];
      setErrors(newErrors);
    }

    if (value) {
      const duplicateIndices = newBatchEntries
        .map((entry, idx) => {
          const isExisting = existingBatchEntries.some(
            (e) => e.batchNumber === entry.batchNumber,
          );
          if (isExisting) return -1;
          return entry.batchNumber === value && idx !== index ? idx : -1;
        })
        .filter((idx) => idx !== -1);

      if (duplicateIndices.length > 0) {
        const newErrors = { ...errors };

        newErrors[index] = intl.formatMessage({
          id: 'warehouse.batch.numbers.input.error.duplicate',
          defaultMessage: 'This batch number is already in use',
        });

        setErrors(newErrors);
      }
    }
  };

  const handleAmountChange = (index: number, value: number) => {
    const isExistingEntry = existingBatchEntries.some(
      (entry) => entry.batchNumber === batchEntries[index].batchNumber,
    );

    if (isExistingEntry) {
      return;
    }

    const newBatchEntries = [...batchEntries];
    newBatchEntries[index].amount = value;
    setBatchEntries(newBatchEntries);
  };

  const addBatchEntry = () => {
    setBatchEntries([...batchEntries, { batchNumber: '', amount: 1 }]);
  };

  const removeBatchEntry = (index: number) => {
    const isExistingEntry = existingBatchEntries.some(
      (entry) => entry.batchNumber === batchEntries[index].batchNumber,
    );

    if (isExistingEntry) {
      return;
    }

    const newBatchEntries = [...batchEntries];
    newBatchEntries.splice(index, 1);
    setBatchEntries(newBatchEntries);
  };

  const handleAmountToRemoveChange = (index: number, value: number) => {
    const maxAmount = batchEntries[index].amount;

    if (value > maxAmount) {
      value = maxAmount;
    }

    setAmountToRemove((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const validateBatchEntries = async () => {
    setLoading(true);
    const newErrors: Record<number, string> = {};
    let hasErrors = false;

    const seen = new Set<string>();
    const duplicates = new Set<string>();

    batchEntries.forEach((entry) => {
      if (!entry.batchNumber) return;

      const isExisting = existingBatchEntries.some(
        (e) => e.batchNumber === entry.batchNumber,
      );
      if (isExisting) return;

      if (seen.has(entry.batchNumber)) {
        duplicates.add(entry.batchNumber);
      } else {
        seen.add(entry.batchNumber);
      }
    });

    batchEntries.forEach((entry, index) => {
      if (entry.batchNumber && duplicates.has(entry.batchNumber)) {
        const isExisting = existingBatchEntries.some(
          (e) => e.batchNumber === entry.batchNumber,
        );
        if (!isExisting) {
          newErrors[index] = intl.formatMessage({
            id: 'warehouse.batch.numbers.input.error.duplicate',
            defaultMessage: 'This batch number is already in use',
          });
          hasErrors = true;
        }
      }

      if (entry.amount <= 0) {
        newErrors[index] = intl.formatMessage({
          id: 'warehouse.batch.numbers.input.error.amount',
          defaultMessage: 'Amount must be greater than 0',
        });
        hasErrors = true;
      }

      if (flowType === 'manual_take_out') {
        const isExisting = existingBatchEntries.some(
          (e) => e.batchNumber === entry.batchNumber,
        );

        if (isExisting) {
          const amountToRemoveValue = amountToRemove[index] || 0;

          if (amountToRemoveValue > entry.amount) {
            newErrors[index] = intl.formatMessage({
              id: 'warehouse.batch.numbers.input.error.remove.amount.exceed',
              defaultMessage: 'Amount to remove cannot exceed available amount',
            });
            hasErrors = true;
          }
        }
      }
    });

    setErrors(newErrors);
    setLoading(false);
    return !hasErrors;
  };

  const handleSave = async () => {
    const isValid = await validateBatchEntries();

    if (isValid) {
      const batchNumberEntries: BatchNumberEntry[] = [];

      batchEntries.forEach((entry, index) => {
        if (entry.batchNumber) {
          const isExistingEntry = existingBatchEntries.some(
            (e) => e.batchNumber === entry.batchNumber,
          );

          const finalAmount =
            flowType === 'manual_take_out' && isExistingEntry
              ? amountToRemove[index] || 0
              : entry.amount;

          if (
            flowType === 'manual_take_out' &&
            isExistingEntry &&
            finalAmount === 0
          ) {
            return;
          }

          batchNumberEntries.push({
            batchNumber: entry.batchNumber,
            amount: finalAmount,
            ecosystem,
          });
        }
      });

      onSave(batchNumberEntries);
    }
  };

  return (
    <StyledModal
      title={intl.formatMessage({
        id: 'warehouse.batch.numbers.modal.title',
        defaultMessage: 'Batch Numbers',
      })}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {intl.formatMessage({
            id: 'button.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={loading}
          onClick={handleSave}
        >
          {intl.formatMessage({
            id: 'button.save',
            defaultMessage: 'Save',
          })}
        </Button>,
      ]}
    >
      <TotalAmount isComplete={isComplete}>
        {intl.formatMessage(
          {
            id:
              flowType === 'manual_take_out'
                ? 'warehouse.batch.numbers.total.amount.remove'
                : 'warehouse.batch.numbers.total.amount',
            defaultMessage:
              flowType === 'manual_take_out'
                ? 'Total amount to remove: {total} (Remaining: {remaining})'
                : 'Total amount: {total} (Remaining: {remaining})',
          },
          {
            total: quantity,
            remaining: remainingAmount,
          },
        )}
      </TotalAmount>

      <Form form={form} layout="vertical">
        <BatchNumbersContainer>
          {batchEntries.map((entry, index) => {
            const isExisting = existingBatchEntries.some(
              (e) => e.batchNumber === entry.batchNumber,
            );
            const isLastEntry = index === batchEntries.length - 1;

            return (
              <Form.Item
                key={index}
                validateStatus={errors[index] ? 'error' : ''}
                help={errors[index]}
              >
                <Row gutter={8} align="middle">
                  <Col span={flowType === 'manual_take_out' ? 8 : 10}>
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'warehouse.batch.numbers.input.placeholder',
                        defaultMessage: 'Enter batch number...',
                      })}
                      value={entry.batchNumber}
                      onChange={(e) =>
                        handleBatchNumberChange(index, e.target.value)
                      }
                      readOnly={isExisting}
                      disabled={isExisting}
                      style={
                        isExisting
                          ? { backgroundColor: '#f5f5f5', color: '#666' }
                          : {}
                      }
                    />
                  </Col>
                  <Col span={4}>
                    <Input
                      type="number"
                      min={1}
                      placeholder={intl.formatMessage({
                        id: 'warehouse.batch.numbers.amount.placeholder',
                        defaultMessage: 'Amount',
                      })}
                      value={entry.amount}
                      onChange={(e) =>
                        handleAmountChange(index, parseInt(e.target.value) || 0)
                      }
                      readOnly={isExisting}
                      disabled={isExisting}
                      style={
                        isExisting
                          ? { backgroundColor: '#f5f5f5', color: '#666' }
                          : {}
                      }
                    />
                  </Col>

                  {flowType === 'manual_take_out' && isExisting && (
                    <Col span={4}>
                      <Input
                        type="number"
                        min={0}
                        max={entry.amount}
                        placeholder={intl.formatMessage({
                          id: 'warehouse.batch.numbers.amount.remove.placeholder',
                          defaultMessage: 'Remove',
                        })}
                        value={amountToRemove[index] || 0}
                        onChange={(e) =>
                          handleAmountToRemoveChange(
                            index,
                            parseInt(e.target.value) || 0,
                          )
                        }
                        style={{
                          backgroundColor: '#fff',
                          borderColor: '#666',
                        }}
                      />
                    </Col>
                  )}

                  <Col
                    span={4}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    {!isExisting && batchEntries.length > 1 && (
                      <Button danger onClick={() => removeBatchEntry(index)}>
                        {intl.formatMessage({
                          id: 'button.remove',
                          defaultMessage: 'Remove',
                        })}
                      </Button>
                    )}
                    {isLastEntry && !isExisting && (
                      <PlusIcon onClick={addBatchEntry} />
                    )}
                  </Col>
                </Row>
              </Form.Item>
            );
          })}
        </BatchNumbersContainer>
      </Form>
    </StyledModal>
  );
};

export default BatchNumbersModal;
