import React, { FC, useState } from 'react';
import { Modal, Button, Table } from 'antd';
import { Document } from '@apps/documents/service/namespace';
import { FormattedMessage, useIntl } from 'react-intl';
import { getContactName } from '@apps/documents/service';
import { Bucket } from '@apps/purchases/services/buckets/namespace';
import { getBucketsPerDetails } from '@apps/purchases/services/buckets';

interface OrderSelectionModalProps {
  visible: boolean;
  onCancel: () => void;
  onAddToTable: (bucket: Bucket, order: Document) => void;
  onReplaceTable: (bucket: Bucket, order: Document) => void;
  documents: Document[];
  ecosystem: string;
  supplierId: string;
}

const OrderSelectionModal: FC<OrderSelectionModalProps> = ({
  visible,
  onCancel,
  onAddToTable,
  onReplaceTable,
  documents,
  ecosystem,
  supplierId,
}) => {
  const intl = useIntl();
  const [selectedOrder, setSelectedOrder] = useState<Document | null>(null);
  const [loading, setLoading] = useState(false);

  const handleOrderAction = async (mode: 'add' | 'replace') => {
    if (!selectedOrder) return;

    setLoading(true);
    try {
      const buckets = await getBucketsPerDetails({
        ecosystem,
        relatedContact: supplierId,
        stepType: 'ordered',
        status: 'done',
        relatedDocumentId: selectedOrder.id,
      });

      if (buckets.length > 0) {
        const bucket = buckets[0];
        if (mode === 'add') {
          onAddToTable(bucket, selectedOrder);
        } else {
          onReplaceTable(bucket, selectedOrder);
        }
      }
    } catch (error) {
      console.error('Error fetching bucket positions:', error);
    } finally {
      setLoading(false);
      onCancel();
    }
  };

  const columns = [
    {
      title: intl.formatMessage({
        id: 'warehouse.order.number',
        defaultMessage: 'Order Number',
      }),
      dataIndex: 'documentNumber',
      key: 'documentNumber',
      render: (_text: string, record: Document) =>
        record.documentNumber || record.docNumber || '',
    },
    {
      title: intl.formatMessage({
        id: 'date',
        defaultMessage: 'Date',
      }),
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (date: string) => `${date}`,
    },
    {
      title: intl.formatMessage({
        id: 'supplier',
        defaultMessage: 'Supplier',
      }),
      dataIndex: 'relatedContactSnapshot',
      key: 'supplier',
      render: (contact: any) => (contact ? getContactName(contact) : ''),
    },
  ];

  return (
    <Modal
      title={intl.formatMessage({
        id: 'warehouse.create.order.select',
        defaultMessage: 'Select Order',
      })}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="add"
          type="primary"
          loading={loading}
          disabled={!selectedOrder}
          onClick={() => handleOrderAction('add')}
        >
          <FormattedMessage
            id="warehouse.order.addToTable"
            defaultMessage="Add to table"
          />
        </Button>,
        <Button
          key="replace"
          type="primary"
          loading={loading}
          disabled={!selectedOrder}
          onClick={() => handleOrderAction('replace')}
        >
          <FormattedMessage
            id="warehouse.order.replaceTable"
            defaultMessage="Replace table"
          />
        </Button>,
      ]}
    >
      <Table
        rowSelection={{
          type: 'radio',
          onChange: (_, selectedRows) => setSelectedOrder(selectedRows[0]),
        }}
        dataSource={documents}
        columns={columns}
        rowKey="id"
      />
    </Modal>
  );
};

export default OrderSelectionModal;
