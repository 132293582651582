import React, { FC } from 'react';
import { LayoutField, FieldType } from '@apps/documents/service/namespace';
import {
  FieldProps,
  SelectField,
  InputField,
  TextAreaField,
  SeparatedValues,
  Contact,
  User,
  RadioField,
  DatePickerField,
  RadioGroupField,
  CostStructure,
  RelatedDocuments,
} from '@apps/documents/mainMenu/components/FormFields';
import SelectSearchField from '../components/FormFields/SelectSearchField';
import RelatedContact from '../components/FormFields/RelatedContact';
import Payments from '@apps/documents/mainMenu/components/FormFields/Payments';

export type LayoutRendererProps = {
  layoutFields: LayoutField[];
};

export const fieldTypeComponentMap: Record<FieldType, FC<FieldProps>> = {
  input: InputField,
  search: () => <div />,
  select: SelectField,
  selectSearch: SelectSearchField,
  ecosystemSelect: SelectField,
  datepicker: DatePickerField,
  textarea: TextAreaField,
  radio: RadioField,
  radioGroup: RadioGroupField,
  switch: () => <div>switch</div>,
  separatedValues: SeparatedValues,
  contact: Contact,
  user: User,
  uploadingUser: User,
  relatedContactSnapshot: RelatedContact,
  costStructure: CostStructure,
  payments: Payments,
  relatedDocuments: RelatedDocuments,
};

const LayoutRenderer: FC<LayoutRendererProps> = ({ layoutFields }) => (
  <>
    {layoutFields.map((field, index) => {
      const FieldComponent = fieldTypeComponentMap[field.fieldType];

      if (FieldComponent) {
        return (
          <FieldComponent key={`${field.fieldName}${index}`} field={field} />
        );
      }

      return null;
    })}
  </>
);

export default LayoutRenderer;
