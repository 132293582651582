import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import AppView from '@components/AppView';
import appConfig, { PURCHASES_ACTION } from '@apps/purchases';
import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { FilterTextObject } from '@services/filtering';
import {
  getIndividualPurchaseRequests,
  updateIndividualPurchaseRequest,
} from '@apps/purchases/services/individualPurchaseRequests';
import columns from './columns';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import optionsMapper from '@core/helpers/optionsMapper';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import { message, Spin, Tooltip } from 'antd';
import { createActivity, emitCustomEvent } from '@core/services';
import { ListEvents } from '@components/List';
import { postProduct, Product } from '@apps/products/services';
import { RequestModal } from '@apps/products/widgets/styles.sc';
import RequestFormProductModal from '@apps/products/widgets/RequestFormProductModal';
import getProductContactConstraints from '../../../products/services/getProductContactConstraints';
import PushTo, {
  PushToEventName,
  PushToEventPayload,
} from '@apps/purchases/mainMenu/individualRequestsList/PushTo';
import { getAllowingTypes } from '@apps/purchases/mainMenu/components/utils/utils';
import { positionMonitorListener } from '@apps/purchases/services/positionMonitor';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import ShippingDetails, {
  ShippingDetailsEventName,
  ShippingDetailsEventPayload,
} from '@apps/purchases/mainMenu/individualRequestsList/ShippingDetails';
import { getPositionMonitor } from '@apps/purchases/services/positionMonitor';
import DocumentPreviewModal from '@apps/purchases/widgets/DocumentPreviewModal';

const IndividualRequestsList: FC = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [spinning, setSpinning] = useState(false);
  const { data: user, isLoading } = useSelector(getUser);
  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);
  useEffect(() => {
    if (gridApi !== null) {
      //TODO: prepare listener integration
      const onChanged = console.warn;
      const { unsubscribe } = positionMonitorListener({
        onChanged,
      });

      return () => {
        unsubscribe();
      };
    }
    return () => {};
  }, [gridApi]);
  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getIndividualPurchaseRequests(
      {
        query: params.query,
        offset: params.startRow,
        limit: params.endRow - params.startRow,
        fields: [
          'id',
          'productName',
          'productNumber',
          'productId',
          'project',
          'costCenter',
          'ecosystem',
          'needDate',
          'needAmount',
          'description',
          'creator',
          'takeBy',
          'alreadyProduct',
          'relatedDocumentId',
          'timestamp',
          'status',
          'stepType',
          'bucketId',
          'prevBucketId',
          'shipper',
          'trackingCode',
          'trackingLink',
          'hasBatchNumber',
          'hasSerialNumber',
        ],
        filters: Object.fromEntries(filtersEntries),
        sort: params.sortModel.map(
          (model: { colId: string; sort: string }) =>
            `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
        ),
      },
      'view-purchases-requests',
    )?.then((response) => {
      params.successCallback(response.results, response.info.results);
    });
  };

  return (
    <Spin spinning={spinning}>
      <>
        <AppView
          hideQuickFilter
          breadcrumbItems={[
            [
              path,
              intl.formatMessage({
                id: 'app.purchases.positions',
                defaultMessage: 'Positions',
              }),
            ],
          ]}
          columns={useMemo(() => columns(intl), [intl])}
          getRows={getRows}
          sideContent={() => <div />}
          path={path}
          onCellClickPath={path}
          tabs={['list']}
          options={optionsMapper(['open'], intl)}
          appConfig={appConfig}
          onGridReady={onGridReady}
          fetchExtraOptions={(item: IndividualPurchaseRequest) => {
            if (item.status === 'draft') {
              return Promise.resolve([
                {
                  children: (
                    <Tooltip
                      title={
                        <FormattedMessage id="draft" defaultMessage="Draft" />
                      }
                    >
                      {intl.formatMessage({
                        id: 'push.to',
                        defaultMessage: 'Push to...',
                      })}
                    </Tooltip>
                  ),
                  disabled: true,
                },
              ]);
            }
            const ecosystemId = item?.ecosystem || '';
            const canEdit = checkUserPermissions(
              rootStore.getState(),
              appConfig.todixId,
              'update-purchases-requests' as PURCHASES_ACTION,
              ecosystemId,
            );

            if (!canEdit) {
              return Promise.resolve([]);
            }

            if (item.alreadyProduct && item.productId && item.id) {
              return Promise.all([
                getProductContactConstraints({
                  type: 'supplier',
                  productId: item.productId as string,
                  ecosystemId,
                }),
                getPositionMonitor(item.id),
              ]).then(([constraints, monitor]) => {
                const disabled = !constraints || !constraints?.length;
                const isOrdered = monitor?.ordered?.status === 'done';

                const options = [];

                options.push({
                  children: disabled ? (
                    <Tooltip
                      title={
                        <FormattedMessage
                          id="no.supplier"
                          defaultMessage="No supplier"
                        />
                      }
                    >
                      {intl.formatMessage({
                        id: 'push.to',
                        defaultMessage: 'Push to...',
                      })}
                    </Tooltip>
                  ) : (
                    intl.formatMessage({
                      id: 'push.to',
                      defaultMessage: 'Push to...',
                    })
                  ),
                  disabled,
                  onClick: () => {
                    const allowingTypes = getAllowingTypes(
                      item?.status,
                      item?.stepType,
                      monitor,
                    );

                    emitCustomEvent<PushToEventName, PushToEventPayload>(
                      'pushTo',
                      {
                        request: item,
                        constraints,
                        allowingTypes,
                      },
                    );
                  },
                });

                if (isOrdered) {
                  options.push({
                    children: intl.formatMessage({
                      id: item.trackingCode ? 'show.tracking' : 'add.tracking',
                      defaultMessage: item.trackingCode
                        ? 'Show Tracking'
                        : 'Add Tracking',
                    }),
                    onClick: () => {
                      emitCustomEvent<
                        ShippingDetailsEventName,
                        ShippingDetailsEventPayload
                      >('showShippingDetails', {
                        request: item,
                        readOnly: !!item.trackingCode,
                      });
                    },
                  });
                }

                return options;
              });
            } else {
              return Promise.resolve([]);
            }
          }}
          getExtraOptions={(item: IndividualPurchaseRequest) => {
            const ecosystemId = item?.ecosystem || '';
            const canEdit = checkUserPermissions(
              rootStore.getState(),
              appConfig.todixId,
              'update-purchases-requests' as PURCHASES_ACTION,
              ecosystemId,
            );

            const takeBy = item.takeBy;
            const isTaker = takeBy?.uid === user?.uid;

            if (!canEdit) {
              return [];
            }

            let options: any[] = [];

            if (takeBy) {
              if (isTaker) {
                options = [
                  ...options,
                  {
                    children: intl.formatMessage({
                      id: 'give.back',
                      defaultMessage: 'Give Back',
                    }),
                    onClick: async () => {
                      if (!isLoading && item.id && user) {
                        try {
                          await updateIndividualPurchaseRequest(item.id, {
                            ...item,
                            takeBy: null,
                          });
                          await createActivity({
                            ecosystemId: item.ecosystem,
                            userId: user.uid,
                            title: 'request.given.back',
                            content: user.displayName,
                          });
                          emitCustomEvent<ListEvents>('refreshList');
                        } catch (e) {
                          message.error(
                            intl.formatMessage({
                              id: 'error.updating',
                              defaultMessage: 'Error while updating entry',
                            }),
                          );
                        }
                      }
                    },
                  },
                ];
              }
            } else {
              options = [
                ...options,
                {
                  children: intl.formatMessage({
                    id: 'take',
                    defaultMessage: 'Take',
                  }),
                  onClick: async () => {
                    if (!isLoading && item.id && user) {
                      try {
                        await updateIndividualPurchaseRequest(item.id, {
                          ...item,
                          takeBy: user,
                        });
                        await createActivity({
                          ecosystemId: item.ecosystem,
                          userId: user.uid,
                          title: 'request.taken.by',
                          content: user.displayName,
                        });
                        emitCustomEvent<ListEvents>('refreshList');
                      } catch (e) {
                        message.error(
                          intl.formatMessage({
                            id: 'error.updating',
                            defaultMessage: 'Error while updating entry',
                          }),
                        );
                      }
                    }
                  },
                },
              ];
            }

            let onClose = () => {};

            const submit = async (product: Product) => {
              if (!isLoading && item.id && user) {
                try {
                  setSpinning(true);
                  const productId = await postProduct({
                    ...product,
                  });
                  await updateIndividualPurchaseRequest(item.id, {
                    ...item,
                    alreadyProduct: true,
                    productId,
                  });
                  emitCustomEvent<ListEvents>('refreshList');
                  if (onClose) {
                    onClose();
                    setSpinning(false);
                  }
                } catch (e) {
                  console.error(e);
                  setSpinning(false);
                }
              }
            };

            options = [
              ...options,
              {
                children: intl.formatMessage({
                  id: 'transfer.to.product',
                  defaultMessage: 'Transfer to Product',
                }),
                disabled: !!item?.alreadyProduct,
                onClick: () => {
                  RequestModal.confirm({
                    className: 'request-modal',
                    content: (
                      <RequestFormProductModal
                        initialValues={{ ...item }}
                        onSubmit={submit}
                        user={user}
                      />
                    ),
                    icon: null,
                    okText: intl.formatMessage({
                      id: 'create.product',
                      defaultMessage: 'Create product',
                    }),
                    onOk: (close) => {
                      onClose = close;
                    },
                    okButtonProps: {
                      htmlType: 'submit',
                      form: 'productBasics',
                    },
                  });
                },
              },
            ];

            return options;
          }}
        />
        <PushTo />
        <ShippingDetails />
        <DocumentPreviewModal
          cancelLink={`/app/${appConfig.todixId}/banf_list`}
        />
      </>
    </Spin>
  );
};

export default IndividualRequestsList;
