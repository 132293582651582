import React, { FC } from 'react';
import { Tag } from 'antd';
import { Sale } from '@apps/sales/services';
import { useIntl } from 'react-intl';

type Props = {
  value: Sale['paymentStatus'];
};

const colors: Record<Sale['paymentStatus'], string> = {
  paid: '#0e7d40',
  error: '#f5222d',
  refused: '#f5222d',
  expired: '#595959',
  running: '#1890ff',
  cancelled: '#faad14',
};
const SalePaymentStatusCellRenderer: FC<Props> = ({ value }) => {
  const intl = useIntl();
  const status: Sale['paymentStatus'] = value || 'running';
  return (
    <Tag color={colors[status] || colors.running}>
      {intl.formatMessage({
        id: `sales.payment.${status}`,
        defaultMessage: status,
      })}
    </Tag>
  );
};

export default SalePaymentStatusCellRenderer;
