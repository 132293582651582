import { connect } from 'react-redux';
import { compose } from 'redux';

import { RootStore } from '@core/store';

import { DashboardLayout, OwnProps } from '@core/layout/components';
import { getDashboardState } from '@core/layout/store/selectors';
import { fetchApplications } from '@core/layout/store';
import withAuth from '@core/hoc/withAuth';

const mapState = (state: RootStore, ownProps: OwnProps) => ({
  ...getDashboardState(state),
  ...ownProps,
});

const mapDispatch = {
  fetchApplications: fetchApplications,
};

export type StateProps = ReturnType<typeof mapState>;

export type DispatchProps = typeof mapDispatch;

export default compose(
  withAuth,
  connect(mapState, mapDispatch),
)(DashboardLayout);
