import { firebase } from '@services/Firebase';
import {
  PRODUCT_PRICES,
  ProductPrice,
} from '@apps/products/services/namespace';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig, { PRODUCTS_ACTION } from '@apps/products';

type Payload = {
  ecosystemId: string;
  productId: string;
  skipArchived?: boolean;
};

const getProductPrices = ({
  ecosystemId,
  productId,
  skipArchived,
}: Payload) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-products' as PRODUCTS_ACTION,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds.includes(ecosystemId)) {
    return Promise.resolve([]);
  }

  let query:
    | firebase.firestore.Query<firebase.firestore.DocumentData>
    | undefined = firebase.firestore
    ?.collection(PRODUCT_PRICES)
    .where('ecosystemId', '==', ecosystemId)
    .where('productId', '==', productId);

  if (skipArchived) {
    query = query?.where('isArchived', '!=', true);
  }

  return query
    ?.get()
    .then((query) =>
      query.docs.map((doc) => ({ ...doc.data(), id: doc.id } as ProductPrice)),
    );
};

export default getProductPrices;
