import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { message } from 'antd';
import { DashboardOutlined, NodeIndexOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Card } from '@todix/ui-components';

import { deleteTask } from '@apps/tasks/services';
import { Task } from '@apps/tasks/services';
import Tabs from '@components/Tabs';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import optionsMapper from '@core/helpers/optionsMapper';
import AppConfig, { TASKS_ACTION } from '@apps/tasks';

import { Form, FormProps } from '../Form';
import Relations from '@components/Relations';
import { emitCustomEvent } from '@core/services';
import { AppViewNavigationChange } from '@core/layout/components/AppViewNavigation';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

const { TabPane } = Tabs;

type DetailedTaskProps = Partial<
  FormProps & {
    id: string;
    ecosystemId?: string;
  }
> & {
  action: TASKS_ACTION;
};

export const DetailedTask: FC<DetailedTaskProps> = ({
  id,
  initialValues = {},
  onSubmit,
  readOnly,
  ecosystemId,
  action,
}) => {
  const [ecosystem, setEcosystem] = useState(
    ecosystemId || initialValues?.ecosystem || '',
  );
  const history = useHistory();
  const intl = useIntl();
  const parseValues = (values: Task) => {
    const parsedValues = {
      ...values,
      creationDate: moment(values.creationDate).format(),
    } as Task;

    if (onSubmit) {
      onSubmit(parsedValues);
    }
  };

  const canDelete = ecosystemId
    ? checkUserPermissions(
        rootStore.getState(),
        AppConfig.todixId,
        'delete-tasks' as TASKS_ACTION,
        ecosystemId,
      )
    : false;

  useEffect(() => {
    const breadcrumbItems = [
      [
        `/app/${AppConfig.todixId}`,
        intl.formatMessage({
          id: 'tasks.detailedtask.nav.tasks',
          defaultMessage: 'Tasks',
        }),
      ],
      id
        ? [`/app/${AppConfig.todixId}/${id}`, initialValues?.title as string]
        : [
            `/app/${AppConfig.todixId}/create`,
            intl.formatMessage({
              id: 'tasks.detailedtasks.nav.create',
              defaultMessage: 'Create',
            }),
          ],
    ];
    emitCustomEvent<string, AppViewNavigationChange>(
      'appViewNavigationChange',
      {
        rowsCount: 0,
        currentTab: '',
        breadcrumbItems,
      },
    );
    return () => {
      emitCustomEvent<string, AppViewNavigationChange>(
        'appViewNavigationChange',
        {
          rowsCount: 0,
          currentTab: '',
          breadcrumbItems: [],
        },
      );
    };
  }, [id, initialValues, intl]);

  return (
    <>
      <Card>
        <Tabs
          defaultActiveKey="1"
          onChange={() => {}}
          tabBarExtraContent={
            id && (
              <ActionRenderer
                path={`/app/${AppConfig.todixId}/${id}`}
                options={canDelete ? optionsMapper(['delete'], intl) : []}
                handleDelete={() => {
                  if (canDelete) {
                    deleteTask(id)
                      ?.then(() => {
                        // message.success(
                        //   intl.formatMessage({
                        //     id: 'tasks.deletetask.success',
                        //     defaultMessage: 'Task deleted',
                        //   }),
                        // );
                        history.push(`/app/${AppConfig.todixId}`);
                      })
                      .catch(() => {
                        message.error(
                          intl.formatMessage({
                            id: 'tasks.deletetask.error',
                            defaultMessage: 'Error while deleting task',
                          }),
                        );
                      });
                  }
                }}
                intl={intl}
              />
            )
          }
        >
          <TabPane
            key="1"
            tab={
              <>
                <DashboardOutlined />{' '}
                <FormattedMessage
                  id="tasks.detailedtask.basetab"
                  defaultMessage="Base Tab"
                />
              </>
            }
          >
            <Form
              id={id}
              initialValues={
                ecosystemId ? { ecosystem: ecosystemId } : initialValues
              }
              onSubmit={(values) => parseValues(values)}
              onEcosystemChange={setEcosystem}
              readOnly={readOnly}
              action={action}
            />
          </TabPane>
          <TabPane
            key="2"
            disabled={!initialValues?.id || !ecosystem}
            tab={
              <>
                <NodeIndexOutlined />{' '}
                <FormattedMessage
                  id="relations.tab"
                  defaultMessage="Relations"
                />
              </>
            }
          >
            {ecosystem && initialValues?.id && (
              <Relations
                ecosystem={ecosystem}
                entityId={initialValues?.id}
                entityType="task"
              />
            )}
          </TabPane>
        </Tabs>
      </Card>
    </>
  );
};
