export enum DashboardActionsTypes {
  FETCH_APPLICATIONS = 'FETCH_APPLICATIONS',
  FETCH_APPLICATIONS_SUCCESS = 'FETCH_APPLICATIONS_SUCCESS',
  FETCH_APPLICATIONS_FAILED = 'FETCH_APPLICATIONS_FAILED',

  SET_FAV_APPLICATIONS = 'SET_FAV_APPLICATIONS',
  SET_APPLICATION_CONTEXT = 'SET_APPLICATION_CONTEXT',
}

export const setFavApplications = (payload: string[]) =>
  ({
    type: DashboardActionsTypes.SET_FAV_APPLICATIONS,
    payload,
  } as const);

export type SetFavApplicationsAction = ReturnType<typeof setFavApplications>;

export const fetchApplications = () =>
  ({
    type: DashboardActionsTypes.FETCH_APPLICATIONS,
  } as const);

export type FetchApplicationsAction = ReturnType<typeof fetchApplications>;

type FetchApplicationsSuccessPayload = {
  applications: ApplicationEntry[];
  favApplications: string[];
};

export const fetchApplicationsSuccess = (
  payload: FetchApplicationsSuccessPayload,
) =>
  ({
    type: DashboardActionsTypes.FETCH_APPLICATIONS_SUCCESS,
    payload,
  } as const);

export type FetchApplicationsSuccessAction = ReturnType<
  typeof fetchApplicationsSuccess
>;

export const fetchApplicationsFailed = (error: string) =>
  ({
    type: DashboardActionsTypes.FETCH_APPLICATIONS_FAILED,
    error,
  } as const);

export type FetchApplicationsFailedAction = ReturnType<
  typeof fetchApplicationsFailed
>;

export const setApplicationContext = (payload: ContextActionPayload) =>
  ({
    type: DashboardActionsTypes.SET_APPLICATION_CONTEXT,
    payload,
  } as const);

export type SetApplicationContextAction = ReturnType<
  typeof setApplicationContext
>;

export type DashboardActions =
  | SetFavApplicationsAction
  | FetchApplicationsAction
  | FetchApplicationsSuccessAction
  | FetchApplicationsFailedAction
  | SetApplicationContextAction;
