import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Form } from 'antd';

const { Item } = Form;

const NumberDeliveryNote: FC = () => {
  const intl = useIntl();
  return (
    <Item
      className="animate__animated animate__slideInLeft animate__faster"
      name="reason"
      label={
        <FormattedMessage
          id="warehouse.create.reason"
          defaultMessage="Reason"
        />
      }
      rules={[
        {
          type: 'string',
          max: 180,
          message: (
            <FormattedMessage
              id="warehouse.create.reason.error"
              defaultMessage="Reason cannot be longer than 180 characters"
            />
          ),
        },
      ]}
    >
      <Input.TextArea
        placeholder={intl.formatMessage({
          id: 'warehouse.create.reason.placeholder',
          defaultMessage: 'Reason...',
        })}
      />
    </Item>
  );
};

export default NumberDeliveryNote;
