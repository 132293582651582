import { firebase } from '@services/Firebase';

type Payload = {
  period: string;
  ecosystemId: string;
  locale?: string;
  from?: string;
};
const sendReports = (payload: Payload) =>
  firebase.functions?.httpsCallable('sendCommissionReports')(payload);

export default sendReports;
