import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Form } from 'antd';

const { Item } = Form;

type TitleProps = {
  readOnly: boolean;
};

const Title: FC<TitleProps> = ({ readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name="title"
      label={
        <FormattedMessage id="tasks.form.title.label" defaultMessage="Title" />
      }
      required
      rules={[
        {
          type: 'string',
          max: 40,
          required: true,
          message: (
            <FormattedMessage
              id="tasks.form.title.error"
              defaultMessage="Title may not be empty and cannot be longer than 40 characters"
            />
          ),
        },
      ]}
    >
      <Input
        name="title"
        placeholder={intl.formatMessage({
          id: 'tasks.form.title.placeholder',
          defaultMessage: 'Title...',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default Title;
