import React, { FC, lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const PurchasesList = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './purchasesList'),
);

const Create = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './routes/Create'),
);

const Edit = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './routes/Edit'),
);

const PurchaseRequest = lazy(
  () => import(/* webpackChunkName: "purchasesApp" */ './PurchaseRequest'),
);

const CreateIndividualRequest = lazy(
  () =>
    import(
      /* webpackChunkName: "purchasesApp" */ './routes/CreateIndividualRequest'
    ),
);

const BANFList = lazy(
  () =>
    import(/* webpackChunkName: "purchasesApp" */ './individualRequestsList'),
);

const Sales: FC<{}> = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/banf_list`}>
        <BANFList />
      </Route>
      <Route path={`${path}/create`} component={Create} />
      <Route path={`${path}/purchase_request`}>
        <PurchaseRequest />
      </Route>
      <Route path={`${path}/create_individual_request`}>
        <CreateIndividualRequest />
      </Route>
      <Route path={`${path}/purchases_list`}>
        <PurchasesList />
      </Route>
      <Route exact path={`${path}/:id`} component={Edit} />
    </Switch>
  );
};

export default Sales;
