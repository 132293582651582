import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import {
  ENTITY_RELATIONS_COLLECTION,
  EntityRelation,
} from '@services/entityRelations/namespace';

type GetAnyParentsForChildPayload = {
  childId: string;
  ecosystem: string;
};

const getAnyParentsForChild = ({
  childId,
  ecosystem,
}: GetAnyParentsForChildPayload) => {
  return firebase.firestore
    ?.collection(ENTITY_RELATIONS_COLLECTION)
    .where('childId', '==', childId)
    .where('ecosystem', '==', ecosystem)
    .get()
    .then(getArrayWithIds) as Promise<EntityRelation[]>;
};

export default getAnyParentsForChild;
