import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Consultant,
  getConsultantsByConsultantParent,
} from '@apps/directSales/mainMenu/Consultants/services';
import { ColumnType } from 'antd/lib/table';
import StyledTable from '../../../../../pages/settings/components/StyledTable';
import getLatestCommissionPeriodStatsForConsultant from '@apps/directSales/services/commisions/getLatestCommissionPeriodStatsForConsultant';
import { ConsultantCommissionState } from '@apps/directSales/services/commisions';
import withSuffix from '@core/helpers/withSuffix';

type Props = {
  consultantId: string;
  ecosystemId: string;
};

const ConsultantTeam: FC<Props> = ({ ecosystemId, consultantId }) => {
  const [loading, setLoading] = useState(false);
  const [consultants, setConsultants] = useState<Consultant[]>([]);
  const [commissions, setCommissions] = useState<ConsultantCommissionState[]>(
    [],
  );

  const columns: ColumnType<any>[] = useMemo(() => {
    const cols: ColumnType<any>[] = [
      {
        title: (
          <FormattedMessage
            id="consultants.columns.consultantName.header"
            defaultMessage="Consultant Name"
          />
        ),
        dataIndex: 'consultantName',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="consultants.columns.consultantSurname.header"
            defaultMessage="Consultant Surname"
          />
        ),
        dataIndex: 'consultantSurname',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="consultants.columns.consultantEmail.header"
            defaultMessage="Consultant Email"
          />
        ),
        dataIndex: 'consultantEmail',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="consultants.columns.consultantStreet.header"
            defaultMessage="Consultant Street"
          />
        ),
        dataIndex: 'consultantStreet',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="consultants.columns.consultantStreetNo.header"
            defaultMessage="Consultant Street No"
          />
        ),
        dataIndex: 'consultantStreetNo',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="consultants.columns.consultantZip.header"
            defaultMessage="Consultant ZIP"
          />
        ),
        dataIndex: 'consultantZip',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="consultants.columns.consultantCity.header"
            defaultMessage="Consultant City"
          />
        ),
        dataIndex: 'consultantCity',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage
            id="consultants.consultant.phone"
            defaultMessage="Consultant Phone"
          />
        ),
        dataIndex: 'consultantPhone',
        sorter: true,
        ellipsis: true,
      },
      {
        title: (
          <FormattedMessage id="commissions.sales" defaultMessage="Sales" />
        ),
        sorter: true,
        ellipsis: true,
        render: (_v, record: Consultant) => {
          const commission = commissions.find(
            (entry) => entry.consultantId === record.id,
          );
          return (
            <div>
              {withSuffix({
                value: commission?.sales || 0,
              })}
            </div>
          );
        },
      },
    ];
    return cols;
  }, [commissions]);

  useEffect(() => {
    if (consultantId && ecosystemId) {
      setLoading(true);
      getConsultantsByConsultantParent(consultantId, ecosystemId)
        ?.then((fetchedConsultants) => {
          setConsultants(fetchedConsultants);
          if (fetchedConsultants) {
            const consultantCommissionsPromises = fetchedConsultants.map(
              (entry) =>
                getLatestCommissionPeriodStatsForConsultant(
                  ecosystemId,
                  entry?.id || '',
                )?.then((results) => {
                  if (results && Array.isArray(results)) {
                    return results[0];
                  }
                  return null;
                }),
            );
            Promise.all(consultantCommissionsPromises)
              .then((results) => {
                const stats = results.filter((entry) => !!entry);
                setCommissions(stats as ConsultantCommissionState[]);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [ecosystemId, consultantId]);

  return (
    <StyledTable
      columns={columns}
      dataSource={consultants}
      bordered
      tableLayout="auto"
      loading={loading}
      rowKey="id"
      pagination={{
        hideOnSinglePage: true,
        defaultPageSize: 25,
      }}
    />
  );
};

export default ConsultantTeam;
