import { firebase } from '@services/Firebase';
import { LICENCES } from '@services/licences/namespace';

type Payload = {
  licence: Licence;
  id: string;
};

const updateLicence = ({ licence, id }: Payload) => {
  return firebase?.db
    ?.ref(`${LICENCES}/${licence.ecosystem}/${id}`)
    .update(licence);
};

export default updateLicence;
