import { deleteRequest, patch, post } from '../api';
import { firebase } from '@services/Firebase';
import { ProductGroup } from '@services/productGroups/namespace';

const COLLECTION_NAME = 'productGroups';

export const createProductGroup = (product: ProductGroup) => {
  return post(COLLECTION_NAME, { ...product });
};

export const updateProductGroup = (product: ProductGroup) => {
  const data = { ...product };
  delete data.id;
  return patch(`${COLLECTION_NAME}/${product.id}`, data);
};

export const deleteProductGroup = (productId: string) => {
  return deleteRequest(`${COLLECTION_NAME}/${productId}`);
};

export const getEcosystemProductGroups = (ecosystemId: string) => {
  return firebase.firestore
    ?.collection(COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((query) =>
      query.docs.map((doc) => ({ ...doc.data(), id: doc.id } as ProductGroup)),
    );
};

export const getMultipleEcosystemProductGroups = (uids: string[]) => {
  return uids.length
    ? firebase.firestore
        ?.collection(COLLECTION_NAME)
        .where('ecosystemId', 'in', uids)
        .get()
        .then((querySnapshot) => {
          const docs: object[] = [];
          // querySnapshot do not support map
          querySnapshot.forEach((doc) => {
            docs.push({
              ...doc.data(),
              id: doc.id,
            });
          });

          return docs as any[];
        })
    : Promise.resolve([]);
};
