import { WEEKPLAN_ACTION } from '@apps/weekplan';

export type LaneCard = {
  id: string;
  title?: string;
  resource?: string;
  description?: string;
};

export type Lane = {
  id: string;
  title: string;
  cards: LaneCard[];
};

export type WeekPlanEntry = {
  ecosystem: string;
  name: string;
  lanes: Lane[];
};

export type GetParams = {
  query?: string;
  offset?: number;
  limit?: number;
  fields?: string[];
  filters?: object;
  sort?: string[];
};

export type ApiResponse = {
  results: object[];
  info: {
    results: number;
  };
};

export type GetApiResponse = (
  params: GetParams,
  action: WEEKPLAN_ACTION,
) => Promise<ApiResponse> | undefined;

export const WEEK_PLAN_COLLECTION_NAME = 'weekPlan';
