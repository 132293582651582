import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tabs, Form, Select, Input } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { Text } from '@todix/ui-components';
import { SideContentProps } from '@components/AppView';
import { getContact, Contact, deleteContact } from '@apps/contacts/services';
import SpinContainer from '@components/SpinContainer';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import { getEcosystemsOptions } from '@apps/documents/mainMenu/Details/Form/formInjectors';
import optionsMapper from '@core/helpers/optionsMapper';
import ContactsAppConfig from '@apps/contacts';

const { TabPane } = Tabs;

const formLayout = {
  wrapperCol: {
    span: 12,
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 10,
    },
    xxl: {
      span: 6,
    },
    offset: 0,
  },
};
export const SideContent: React.FC<SideContentProps> = ({ id }) => {
  const [details, setDetails] = useState<Contact | null>(null);
  useEffect(() => {
    getContact(id)?.then((response) => setDetails(response as Contact));
  }, [id]);
  const intl = useIntl();
  const information = details && [
    {
      id: 'contacts.general.type.label',
      defaultMessage: 'Type of contact',
      value: details.type
        ? intl.formatMessage({
            id: `detailcontactform.generalinformation.type.${details.type}`,
            defaultMessage: details.type,
          })
        : '-',
    },
    {
      id: 'contacts.general.gender.label',
      defaultMessage: 'Gender',
      value: details.gender
        ? intl.formatMessage({
            id: `detailcontactform.generalinformation.gender.${details.gender}`,
            defaultMessage: details.gender,
          })
        : '-',
    },
    {
      id: 'contacts.general.companyname.label',
      defaultMessage: 'Company name',
      value: details.companyName || '-',
    },
    {
      id: 'contacts.general.employer.label',
      defaultMessage: 'Employer',
      value: details.employer || '-',
    },
    {
      id: 'contacts.general.phone.label',
      defaultMessage: 'Phone',
      value: details.phones?.[0]?.number || '-',
    },
    {
      id: 'contacts.general.email.label',
      defaultMessage: 'Email',
      value: details.emails?.[0]?.email || '-',
    },
    {
      id: 'contacts.general.invoiceemail.label',
      defaultMessage: 'Invoice Email Address',
      value: details.invoiceEmail || '-',
    },
    {
      id: 'contacts.general.address.label',
      defaultMessage: 'Address',
      value: details.addresses?.[0]?.label || '-',
    },
    {
      id: 'contacts.general.country.label',
      defaultMessage: 'Country',
      value: details.addresses?.[0]?.country || '-',
    },
    /*  {
      id: 'contacts.general.district.label',
      defaultMessage: 'District/State',
      value: details.addresses?.[0]?.district || '-',
    },*/
    {
      id: 'contacts.general.zip.label',
      defaultMessage: 'ZIP Code/PO Box',
      value: details.addresses?.[0]?.zipcode || '-',
    },
    {
      id: 'contacts.general.city.label',
      defaultMessage: 'City',
      value: details.addresses?.[0]?.city || '-',
    },
    {
      id: 'contacts.general.streetandnumber.label',
      defaultMessage: 'Street + Street Number',
      value: `${details.addresses?.[0]?.street || '-'} ${
        details.addresses?.[0]?.number || '-'
      }`,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={
        <ActionRenderer
          path={`/app/${ContactsAppConfig.todixId}/${id}`}
          options={optionsMapper(['open', 'edit', 'delete'], intl)}
          handleDelete={() => {
            deleteContact(id);
          }}
          intl={intl}
          appConfig={ContactsAppConfig}
          ecosystemId={details?.ecosystem}
        />
      }
    >
      <TabPane
        key="1"
        tab={
          <>
            <DashboardOutlined />{' '}
            <FormattedMessage
              id="contacts.tab.base"
              defaultMessage="Base Tab"
            />
          </>
        }
      >
        {details === null && <SpinContainer />}
        {!!details && (
          <Form
            initialValues={details}
            colon={false}
            labelAlign="left"
            {...formLayout}
          >
            <Text level={4} style={{ display: 'block', marginBottom: 36 }}>
              {details.firstName} {details.lastName}
            </Text>
            <Form.Item
              name="ecosystem"
              label={
                <FormattedMessage
                  id="contacts.general.ecosystem.label"
                  defaultMessage="Ecosystem"
                />
              }
            >
              <Select
                options={getEcosystemsOptions()}
                showArrow={false}
                bordered={false}
              />
            </Form.Item>
            {information &&
              information.map((field) => (
                <Form.Item
                  key={field.id}
                  label={
                    <FormattedMessage
                      id={field.id}
                      defaultMessage={field.defaultMessage}
                    />
                  }
                >
                  <Input value={field.value} />
                </Form.Item>
              ))}
            {details.bankAccounts?.[0] && (
              <>
                <FormattedMessage
                  id="contacts.general.bankaccount.title"
                  defaultMessage="Bank Account"
                />
                <Form.Item
                  name="bankAccounts.0.label"
                  label={
                    <FormattedMessage
                      id="contacts.general.bankaccount.label.label"
                      defaultMessage="Label"
                    />
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="bankAccounts.0.iban"
                  label={
                    <FormattedMessage
                      id="contacts.general.bankaccount.iban.label"
                      defaultMessage="IBAN"
                    />
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="bankAccounts.0.swift"
                  label={
                    <FormattedMessage
                      id="contacts.general.bankaccount.swift.label"
                      defaultMessage="BIC Swift"
                    />
                  }
                >
                  <Input />
                </Form.Item>
              </>
            )}
          </Form>
        )}
      </TabPane>
    </Tabs>
  );
};
