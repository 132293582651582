import React, { FC, useRef, useState } from 'react';
import { Collapse } from 'antd';
import { useCustomEventListener } from '@core/services';
import {
  ON_FINISH_FAILED_EVENT,
  OnFinishFailedEvent,
} from '@components/AppEntityForm/AppEntityForm';
import {
  PhoneOutlined,
  PhoneTwoTone,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import ArrayOfFields from '@apps/contacts/mainMenu/components/DetailContactForm/ArrayOfFields';
import { getPhonesLabels } from '@services/dictionary';
import Warning from '@apps/contacts/mainMenu/components/DetailContactForm/Warning';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import ContentIndicator from '@apps/contacts/mainMenu/components/DetailContactForm/ContentIndicator';

const { Panel } = Collapse;

type Props = {};

const PhoneNumber: FC<Props> = () => {
  const intl = useIntl();
  const { readOnly, getFieldValue } = useFormContext();
  const phonesOptions = getPhonesLabels(intl);
  const [hasError, setHasError] = useState(false);
  const addPhoneRef = useRef<any>(() => {});
  const activePanel = useRef<string[]>([]);

  useCustomEventListener<'onFinishFailed', OnFinishFailedEvent>(
    ON_FINISH_FAILED_EVENT,
    (data) => {
      if (data) {
        const addressError =
          data.errorInfo.errorFields.filter((err) =>
            err.name.includes('phones'),
          ).length > 0;
        if (addressError) {
          setHasError(true);
        } else {
          setHasError(false);
        }
      }
    },
  );

  return (
    <Collapse
      onChange={(keys) => {
        activePanel.current = keys as string[];
      }}
      expandIcon={() =>
        hasError ? (
          <div>
            <Warning />
            <ContentIndicator
              emptyIcon={<PhoneOutlined />}
              contentIcon={<PhoneTwoTone />}
              emptyIndicator={() =>
                getFieldValue('phones')
                  ? (getFieldValue('phones') as []).length < 1
                  : true
              }
            />
          </div>
        ) : (
          <div>
            <ContentIndicator
              emptyIcon={<PhoneOutlined />}
              contentIcon={<PhoneTwoTone />}
              emptyIndicator={() =>
                getFieldValue('phones')
                  ? (getFieldValue('phones') as []).length < 1
                  : true
              }
            />
          </div>
        )
      }
    >
      <Panel
        header={
          <FormattedMessage
            id="detailcontactform.generalinformation.phone.secondplaceholder"
            defaultMessage="Phone number"
          />
        }
        key="1"
        extra={
          !readOnly ? (
            <PlusSquareOutlined
              onClick={(event) => {
                if (activePanel.current.length) {
                  event.stopPropagation();
                }
                addPhoneRef.current();
              }}
            />
          ) : undefined
        }
        forceRender
      >
        <ArrayOfFields
          label={intl.formatMessage({
            id: 'detailcontactform.generalinformation.phone.label',
            defaultMessage: 'Phone#',
          })}
          options={phonesOptions}
          arrayName="phones"
          firstInputNameField="label"
          secondInputNameField="number"
          firstInputPlaceholder={intl.formatMessage({
            id: 'detailcontactform.generalinformation.phone.firstplaceholder',
            defaultMessage: 'Phone label',
          })}
          secondInputPlaceholder={intl.formatMessage({
            id: 'detailcontactform.generalinformation.phone.secondplaceholder',
            defaultMessage: 'Phone number',
          })}
          secondInputRules={[
            {
              required: true,
              message: 'Phone number must be defined',
            },
          ]}
          addNextButtonLabel={intl.formatMessage({
            id: 'detailcontactform.generalinformation.phone.add',
            defaultMessage: 'Add phone number',
          })}
          standardFieldName="standardPhone"
          defaultValue={
            phonesOptions
              ? phonesOptions[0]
              : {
                  label: 'primary',
                  value: 'primary',
                  standard: true,
                }
          }
          hideButton
          addEntryRef={addPhoneRef}
        />
      </Panel>
    </Collapse>
  );
};

export default PhoneNumber;
