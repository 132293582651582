import React, { CSSProperties, FC } from 'react';
import { PageViewElementProps } from '@apps/purchases/mainMenu/operationSteps/commonTypes';
import DeliveredPositionsTable from './DeliveredPositionsTable';

const DeliveredPositions: FC<PageViewElementProps> = (props) => {
  const { style } = props;
  return (
    <div style={{ ...(style as CSSProperties) }}>
      <DeliveredPositionsTable {...props} />
    </div>
  );
};

export default DeliveredPositions;
