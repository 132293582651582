import {
  WAREHOUSE_STORAGE,
  WarehouseStorage,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';
import moment from 'moment';

const createWarehouseStorage = (warehouseStorage: WarehouseStorage) => {
  const { ecosystem } = warehouseStorage;
  return firebase.db
    ?.ref(`${WAREHOUSE_STORAGE}/${ecosystem}`)
    .push({
      ...warehouseStorage,
      timestamp: moment().valueOf(),
    })
    .then((res) => res.key);
};

export default createWarehouseStorage;
