import React from 'react';
import { PaymentEntity } from '@apps/documents/service';
import { Col, Form, Row, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Text } from '@todix/ui-components';
import { TaxCategory } from '@apps/finance/services/taxCategories';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import styled from 'styled-components';
import MoneyInput from '@components/MoneyInput';

const StyledCol = styled(Col)`
  label {
    width: 100%;
  }
`;

type SummaryProps = {
  taxRates: TaxCategory[];
};

const Summary: React.FC<SummaryProps> = () => {
  const { getFieldsValue } = useFormContext();
  const { payments, totalGrossAmount } = getFieldsValue();
  if (!payments || !payments.length) {
    return null;
  }
  const sumGrossAmount = totalGrossAmount;
  const paidTotal = (payments as PaymentEntity[])?.reduce((acc, it) => {
    if (!it.payAmount) {
      return acc;
    }
    return acc + Number(it.payAmount);
  }, 0);

  const unpaidLabel = (
    <FormattedMessage
      id={'documents.processinginvoice.payments.unpaid.label'}
      defaultMessage="Unpaid"
    />
  );
  const grossAmountLabel = (
    <FormattedMessage
      id={'documents.processinginvoice.payments.grossamount.label'}
      defaultMessage="Gross amount"
    />
  );
  const paidTotalLabel = (
    <FormattedMessage
      id={'documents.processinginvoice.payments.paidtotal.label'}
      defaultMessage="Paid total"
    />
  );

  return (
    <Form.Item noStyle shouldUpdate>
      {() => {
        return (
          <Row gutter={[1, 1]}>
            <StyledCol span={8}>
              <Form.Item
                label={
                  <Tooltip title={unpaidLabel}>
                    <Text level={2} strong ellipsis>
                      {unpaidLabel}
                    </Text>
                  </Tooltip>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <MoneyInput
                  value={sumGrossAmount ? sumGrossAmount - paidTotal : 0}
                  readOnly
                />
              </Form.Item>
            </StyledCol>
            <StyledCol span={8}>
              <Form.Item
                label={
                  <Tooltip title={grossAmountLabel}>
                    <Text level={2} strong ellipsis>
                      {grossAmountLabel}
                    </Text>
                  </Tooltip>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <MoneyInput value={sumGrossAmount || 0} readOnly />
              </Form.Item>
            </StyledCol>
            <StyledCol span={8}>
              <Form.Item
                label={
                  <Tooltip title={paidTotalLabel}>
                    <Text level={2} strong ellipsis>
                      {paidTotalLabel}
                    </Text>
                  </Tooltip>
                }
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <MoneyInput value={paidTotal || 0} readOnly />
              </Form.Item>
            </StyledCol>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default Summary;
