import { DocumentType } from '@apps/documents/service';

const getLayout = async (type?: DocumentType) => {
  try {
    if (type) {
      return import(`./${type}`)
        .then(({ default: mod }) => {
          return mod;
        })
        .catch((err) => {
          console.warn(err);
        });
    }
    console.warn('Missing param: type');
    return null;
  } catch (e) {
    console.warn('No layout file for ', type);
    return null;
  }
};

export default getLayout;
