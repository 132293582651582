import { FieldType, LayoutField } from '@apps/documents/service/namespace';
import {
  CommonFieldsLayout,
  SideViewCommonFieldsLayout,
} from '@apps/documents/service/commonFields.layouts';
import { LayoutDestination } from '@apps/documents/service/layouts';

export const ProcessingQuotationLayout: LayoutField[] = [
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.processingquotation.docnumber.label',
    rules: [
      {
        required: true,
        message: 'documents.processingquotation.docnumber.error',
      },
    ],
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
    rules: [
      { required: true, message: 'documents.commonfields.issuingDate.error' },
    ],
    noFutureDate: true,
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    label: 'documents.processingquotation.receivingdate.label',
    type: 'date',
    placeholder: 'documents.processingquotation.receivingdate.placeholder',
    rules: [
      {
        required: true,
        message: 'documents.processinginvoice.receivingdate.error',
      },
    ],
    noFutureDate: true,
  },
  {
    fieldName: 'serviceDate',
    fieldType: 'datepicker',
    label: 'documents.processingquotation.servicedate.label',
    type: 'date',
    placeholder: 'documents.processingquotation.servicedate.placeholder',
  },
  /* {
    fieldName: 'productName',
    fieldType: 'input',
    label: 'documents.processingquotation.productname.label',
    placeholder: 'documents.processingquotation.productname.placeholder',
  } */
  /* {
    fieldName: 'productNumber',
    fieldType: 'input',
    label: 'documents.processingquotation.productnumber.label',
    placeholder: 'documents.processingquotation.productnumber.placeholder',
  }, */
  {
    fieldName: 'documentDueDate',
    fieldType: 'datepicker',
    label: 'documents.processingquotation.documentduedate.label',
    type: 'date',
    placeholder: 'documents.processingquotation.documentduedate.placeholder',
  },
  /*{
    fieldName: 'currency',
    fieldType: 'input',
    label: 'documents.processinginvoice.currency.label',
    rules: [
      {
        required: true,
        message: 'documents.processinginvoice.currency.error',
      },
    ],
  },*/
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.processingquotation.referencenumber.label',
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    placeholder: 'documents.commonfields.sideview.notes',
    wrapperStyle: {
      gridColumn: '1 / span 2',
    },
  },
];

export const ProcessingSummaryQuotationLayout: LayoutField[] =
  ProcessingQuotationLayout.map((field) => ({
    ...field,
    fieldType: ['datepicker'].includes(field.fieldType)
      ? (field.fieldType as FieldType)
      : 'input',
    placeholder: '',
    style: {
      border: 0,
    },
  }));

export const QuotationLayout: LayoutField[] = [
  ...CommonFieldsLayout,
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.quotation.docnumber.label',
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.quotation.receivingdate.label',
    type: 'date',
    placeholder: 'documents.quotation.receivingdate.placeholder',
  },
  {
    fieldName: 'serviceDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.quotation.servicedate.label',
    type: 'date',
    placeholder: 'documents.quotation.servicedate.placeholder',
  },
  /* {
    fieldName: 'productName',
    fieldType: 'input',
    label: 'documents.quotation.productname.label',
    placeholder: 'documents.quotation.productname.placeholder',
  }, */
  /* {
    fieldName: 'productNumber',
    fieldType: 'input',
    label: 'documents.quotation.productnumber.label',
    placeholder: 'documents.quotation.productnumber.placeholder',
  }, */
  {
    fieldName: 'documentDueDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.quotation.documentduedate.label',
    type: 'date',
    placeholder: 'documents.quotation.documentduedate.placeholder',
  },
  /* {
    fieldName: 'currency',
    fieldType: 'input',
    label: 'documents.invoice.currency.label',
  }, */
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.quotation.referencenumber.label',
  },
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];

export const SideViewQuotationLayout: LayoutField[] = [
  ...SideViewCommonFieldsLayout,
  {
    fieldName: 'docNumber',
    fieldType: 'input',
    label: 'documents.quotation.sideview.docnumber.label',
    readOnly: true,
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.quotation.sideview.receivingdate.label',
    type: 'date',
  },
  {
    fieldName: 'serviceDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.quotation.sideview.servicedate.label',
    type: 'date',
  },
  /* {
    fieldName: 'productName',
    fieldType: 'input',
    label: 'documents.quotation.sideview.productname.label',
    readOnly: true,
  }, */
  /* {
    fieldName: 'productNumber',
    fieldType: 'input',
    label: 'documents.quotation.sideview.productnumber.label',
    readOnly: true,
  }, */
  {
    fieldName: 'documentDueDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.quotation.sideview.documentduedate.label',
    type: 'date',
  },
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.quotation.sideview.referencenumber.label',
    readOnly: true,
  },
  /* {
    fieldName: 'currency',
    fieldType: 'input',
    label: 'documents.invoice.sideview.currency.label',
    readOnly: true,
  }, */
  {
    fieldName: 'notes',
    fieldType: 'textarea',
    label: 'documents.commonfields.sideview.notes',
    readOnly: true,
  },
];

const quotationLayouts: Record<LayoutDestination, LayoutField[]> = {
  detailedForm: QuotationLayout,
  sideView: SideViewQuotationLayout,
  processing: ProcessingQuotationLayout,
  processingSummary: ProcessingSummaryQuotationLayout,
};

export default quotationLayouts;
