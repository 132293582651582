import React, { FC } from 'react';
import { Form, Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { SelectProps } from 'antd/lib/select';

const { Item } = Form;

type StorageSelectProps = SelectProps & {
  readOnly?: boolean;
  options: { value: string; label: string }[];
};

const StorageSelect: FC<StorageSelectProps> = ({
  readOnly,
  options,
  ...rest
}) => {
  return (
    <Item
      name="storageId"
      label={
        <FormattedMessage
          id="warehousetab.storage.form.storage.label"
          defaultMessage="Storage"
        />
      }
    >
      <Select
        {...rest}
        placeholder={
          <FormattedMessage
            id="warehousetab.storage.form.storage.select.placeholder"
            defaultMessage="Select storage..."
          />
        }
        options={options}
        disabled={readOnly}
      />
    </Item>
  );
};

export default StorageSelect;
