import React, { FC } from 'react';
import { SaleCreatorSettingsWrapper } from '@apps/sales/mainMenu/components/SaleCreator/styles.sc';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

type Props = {
  onCollapseClick(): void;
};

const SaleCreatorSettings: FC<Props> = ({ onCollapseClick }) => {
  return (
    <SaleCreatorSettingsWrapper>
      <Button
        onClick={onCollapseClick}
        icon={<ArrowLeftOutlined />}
        type="primary"
      ></Button>
    </SaleCreatorSettingsWrapper>
  );
};

export default SaleCreatorSettings;
