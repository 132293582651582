export enum LicencesActionTypes {
  UPDATE_LICENCE = 'UPDATE_LICENCE',
  DELETE_LICENCE = 'DELETE_LICENCE',
}

type LicencePayload = Record<string, Licence>;

export const updateLicence = (payload: LicencePayload) =>
  ({
    type: LicencesActionTypes.UPDATE_LICENCE,
    payload,
  } as const);

export type UpdateLicenceAction = ReturnType<typeof updateLicence>;

export const deleteLicence = (payload: LicencePayload) =>
  ({
    type: LicencesActionTypes.DELETE_LICENCE,
    payload,
  } as const);

export type DeleteLicenceAction = ReturnType<typeof deleteLicence>;

export type LicencesAction = UpdateLicenceAction | DeleteLicenceAction;
