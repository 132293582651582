import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  Tabs,
  Spin,
  message,
  Form,
  Select,
  DatePicker,
  Input,
  Table,
  Avatar,
  Space,
} from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { Project } from '@apps/projects/services/namespace';
import ProjectsAppConfig from '@apps/projects';
import optionsMapper from '@core/helpers/optionsMapper';
import { ActionRenderer } from '@components/AppView/ActionRenderer';
import deleteProject from '@apps/projects/services/deleteProject';
import getProjectById from '@apps/projects/services/getProjectById';
import { fetchUser } from '@services/users';
import moment from 'moment';
import { getEcosystemsOptions } from '@apps/documents/mainMenu/Details/Form/formInjectors';
import { StyledSectionTitle } from '@apps/projects/mainMenu/ProjectsDetails.sc';

const { TabPane } = Tabs;

const formLayout = {
  wrapperCol: {
    span: 12,
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 10,
    },
    xxl: {
      span: 6,
    },
    offset: 0,
  },
};

type UserWithRole = User & {
  role: string;
};

const SideContent: FC<{ id: string }> = ({ id }) => {
  const intl = useIntl();
  const [project, setProject] = useState<Project | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState<UserWithRole[]>([]);
  const [form] = Form.useForm();

  const fetchProjectDeps = useCallback(
    async (fetchedProject) => {
      const { creator, managerId, membersIds } = fetchedProject;
      const allMembers = membersIds
        ? [...membersIds, creator, managerId]
        : [creator, managerId];
      const membersToFetch = [...new Set(allMembers)];
      const creatorIsManager = managerId && managerId === creator;
      const roles = {
        [creator]: 'creator',
        [managerId]: 'manager',
      };
      const membersPromises = membersToFetch.map((id) =>
        fetchUser(id).then((user) => ({
          ...user,
          uid: id,
          role:
            creatorIsManager && id === creator
              ? 'creator,manager'
              : roles[id] || 'member',
        })),
      );
      await Promise.all(membersPromises)
        .then((users) => {
          setMembers(users);
          setProject(fetchedProject);
          form.setFieldsValue({
            ...fetchedProject,
            startDate: moment(fetchedProject.startDate, moment.defaultFormat),
            endDate: moment(fetchedProject.endDate, moment.defaultFormat),
          });
        })
        .finally(() => setIsLoading(false));
    },
    [form],
  );

  const fetchProject = useCallback(
    async (projectId) => {
      const fetchedProject = await getProjectById(projectId);
      setProject(fetchedProject);
      await fetchProjectDeps(fetchedProject);
    },
    [fetchProjectDeps],
  );

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      fetchProject(id).finally(() => {
        setIsLoading(false);
      });
    }
  }, [fetchProject, id]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
      render: (text: string, record: UserWithRole) => (
        <Space direction="horizontal" size="small">
          <Avatar src={record.photoURL} size="small" />
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text: string) => {
        const roles = text.split(',');
        return (
          <Space direction="horizontal" size="small">
            {roles.map((role, id) => (
              <FormattedMessage
                key={id}
                id={`app.projects.role.${role}`}
                defaultMessage={`app.projects.role.${role}`}
              />
            ))}
          </Space>
        );
      },
    },
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      tabBarExtraContent={
        <ActionRenderer
          path={`/app/${ProjectsAppConfig.todixId}/${id}`}
          options={optionsMapper(['edit', 'delete'], intl)}
          handleDelete={() => {
            deleteProject(id)?.catch(() => {
              message.error(
                intl.formatMessage({
                  id: 'app.projects.delete.error',
                  defaultMessage: 'Error while deleting project',
                }),
              );
            });
          }}
          intl={intl}
        />
      }
    >
      <TabPane
        key="1"
        tab={
          <>
            <DashboardOutlined />{' '}
            <FormattedMessage
              id="tasks.sidecontent.basetab"
              defaultMessage="Base Tab"
            />
          </>
        }
      >
        <Spin spinning={isLoading}>
          {project && (
            <>
              <Form form={form} colon={false} labelAlign="left" {...formLayout}>
                <Form.Item
                  name="ecosystem"
                  label={
                    <FormattedMessage
                      id="tasks.sidecontent.generalinformation.ecosystem.label"
                      defaultMessage="Ecosystem"
                    />
                  }
                >
                  <Select
                    options={getEcosystemsOptions()}
                    showArrow={false}
                    bordered={false}
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  name="name"
                  label={
                    <FormattedMessage
                      id="app.projects.form.name.label"
                      defaultMessage="Name"
                    />
                  }
                >
                  <Input readOnly />
                </Form.Item>
                <Form.Item
                  name="startDate"
                  label={
                    <FormattedMessage
                      id="app.projects.form.startDate.label"
                      defaultMessage="Start date"
                    />
                  }
                >
                  <DatePicker format={moment.defaultFormat} disabled />
                </Form.Item>
                <Form.Item
                  name="endDate"
                  label={
                    <FormattedMessage
                      id="app.projects.form.endDate.label"
                      defaultMessage="End date"
                    />
                  }
                >
                  <DatePicker format={moment.defaultFormat} disabled />
                </Form.Item>
              </Form>
              <StyledSectionTitle>
                <FormattedMessage
                  id="app.projects.members.label"
                  defaultMessage="Project members"
                />
              </StyledSectionTitle>
              <Table size="small" columns={columns} dataSource={members} />
            </>
          )}
        </Spin>
      </TabPane>
    </Tabs>
  );
};

export default SideContent;
