import React from 'react';
import {
  EditOutlined,
  UnorderedListOutlined,
  CheckOutlined,
  DropboxOutlined,
  EuroOutlined,
  WalletOutlined,
  MailOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import { DocumentType } from '@apps/documents/service';

const stepTypeIconMapper: Record<DocumentType, React.FC> = {
  quotation: EditOutlined,
  order: UnorderedListOutlined,
  orderConfirmation: CheckOutlined,
  deliveryNote: DropboxOutlined,
  performaInvoice: ContainerOutlined,
  invoice: EuroOutlined,
  creditNote: WalletOutlined,
  dunningLetter: MailOutlined,
};

export default stepTypeIconMapper;
