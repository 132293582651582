import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  height: 100%;
  max-height: calc(100vh - 200px);
  width: 700px;
  padding: 10px;
  border: solid 1px #e8e8e8;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
`;

export const ImagePreview: FC<{
  imageSrc: string;
  imageAlt: string;
}> = ({ imageSrc, imageAlt }) => {
  if (!imageSrc || !imageAlt) {
    return null;
  }

  return (
    <Container>
      <Image src={imageSrc} alt={imageAlt} />
    </Container>
  );
};
