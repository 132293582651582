import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const WeekPlanSettings = lazy(
  () => import(/* webpackChunkName: "weekPlanApp" */ './WeekPlanSettings'),
);

export default {
  appSubmenuName: 'app.weekplan.mainMenu.name',
  icon: Icon.CalendarOutlined,
  sections: [
    {
      link: 'weekplan_settings',
      title: 'settings.weekplan.manage',
      component: WeekPlanSettings,
    },
  ],
};
