import { Purchase } from '@apps/purchases/services/namespace';
import { Document, getDocumentsByIds } from '@apps/documents/service';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';

const getDocumentsRelatedWithPurchase = async (
  purchase: Purchase,
): Promise<Document[]> => {
  const purchaseDocumentRelations = await getChildrenForParent({
    parentId: purchase.id as string,
    ecosystem: purchase.ecosystem,
    childType: 'document',
  });

  const docIds = purchaseDocumentRelations.map((el) => el.childId);
  return await getDocumentsByIds(docIds);
};

export default getDocumentsRelatedWithPurchase;
