import React from 'react';
import { Avatar } from 'antd';
import { SelectLayoutField } from '@apps/documents/service';
import { rootStore } from '@core/store';

export const getEcosystemsOptions = () => {
  const { ecosystems } = rootStore.getState();
  return ecosystems.map((ecosystem: Ecosystem) => ({
    value: ecosystem.id,
    label: (
      <div>
        <Avatar src={ecosystem.avatarUrl} size="small" /> {ecosystem.name}
      </div>
    ),
  }));
};

export const injectEcosystemsOptions = (
  ecosystems: Ecosystem[] | undefined,
  field: SelectLayoutField,
): SelectLayoutField => {
  return {
    ...field,
    options: ecosystems
      ? ecosystems.map((ecosystem: Ecosystem) => ({
          value: ecosystem.id,
          label: (
            <div>
              <Avatar src={ecosystem.avatarUrl} size="small" /> {ecosystem.name}
            </div>
          ),
        }))
      : [],
  } as SelectLayoutField;
};
