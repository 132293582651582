import React, { useCallback, useState, FC } from 'react';
import { Spin, message } from 'antd';
import { useHistory } from 'react-router-dom';

import { Product, postProduct } from '@apps/products/services';
import { DetailedProduct } from '../../components/DetailedProduct';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import ProductsAppConfig, { PRODUCTS_ACTION } from '@apps/products/index';
import createRelation from '@services/entityRelations/createRelation';
import { Document } from '@apps/documents/service/namespace';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';

type ProductCreationType = {
  shouldRedirect?: boolean;
  showExtraContent?: boolean;
  onSubmit?: (product: Product) => void;
  onCancel?: () => void;
};

const Create: FC<ProductCreationType> = ({
  shouldRedirect = true,
  showExtraContent = true,
  onSubmit,
  onCancel,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: user } = useSelector(getUser);
  const history = useHistory();
  const intl = useIntl();
  const firstActiveEcosystemId = useFirstActiveEcosystemId(
    ProductsAppConfig.todixId,
    'create-products' as PRODUCTS_ACTION,
  );

  const handleSubmit = useCallback(
    (product: Product, awaitingDocs) => {
      if (!user) {
        return;
      }

      setIsSubmitting(true);
      postProduct({
        ...product,
        creator: user,
      })
        ?.then((productId) => {
          if (awaitingDocs) {
            const docsPromises = awaitingDocs.map((doc: Document) =>
              createRelation({
                childId: doc.id as string,
                childType: 'document',
                childRefCollection: 'documents',
                parentId: productId,
                parentType: 'product',
                parentRefCollection: 'products',
                ecosystem: product.ecosystem,
              }),
            );

            Promise.all(docsPromises).then(() => {
              setIsSubmitting(false);
              if (shouldRedirect) {
                history.push(`/app/${ProductsAppConfig.todixId}`);
              }
              if (onSubmit) {
                onSubmit({
                  ...product,
                  id: productId,
                });
              }
            });
          } else {
            setIsSubmitting(false);
            if (shouldRedirect) {
              history.push(`/app/${ProductsAppConfig.todixId}`);
            }
            if (onSubmit) {
              onSubmit({
                ...product,
                id: productId,
              });
            }
          }
        })
        .catch(() => {
          setIsSubmitting(false);
          message.error(
            intl.formatMessage({
              id: 'products.create.error',
              defaultMessage: 'Error while creating Product',
            }),
          );
        });
    },
    [history, intl, onSubmit, shouldRedirect, user],
  );

  return (
    <Spin spinning={isSubmitting}>
      <DetailedProduct
        readOnly={false}
        shouldRedirect={shouldRedirect}
        onSubmit={handleSubmit}
        onCancel={onCancel}
        initialValues={{
          ecosystem: firstActiveEcosystemId,
        }}
        showExtraContent={showExtraContent}
        action="create-products"
      />
    </Spin>
  );
};

export default Create;
