import React, { FC, useEffect } from 'react';
import { Form, Input } from 'antd';
import moment from 'moment';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { FormattedMessage, useIntl } from 'react-intl/lib';

const InputField: FC<FieldProps> = ({ field, ...rest }) => {
  const { readOnly, getFieldValue, setFields } = useFormContext();
  const {
    fieldName,
    label,
    rules,
    className,
    style,
    placeholder,
    readOnly: fieldReadOnly,
    type,
  } = field;
  const value = getFieldValue(fieldName);
  const intl = useIntl();
  useEffect(() => {
    if (type === 'date' && value) {
      const stringFromMoment = moment(value).format();
      setFields([
        {
          name: fieldName,
          value: stringFromMoment.includes('Invalid')
            ? value
            : stringFromMoment,
        },
      ]);
    }
  }, [fieldName, setFields, type, value]);

  return (
    <Form.Item
      name={fieldName}
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      rules={rules?.map((r) => {
        r.message =
          r.message && typeof r.message === 'string' ? (
            <FormattedMessage
              id={`${r.message}`}
              defaultMessage={`${r.message}`}
            />
          ) : (
            r.message
          );
        return r;
      })}
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <Input
        tabIndex={0}
        name={fieldName}
        placeholder={
          placeholder
            ? intl.formatMessage({
                id: placeholder,
                defaultMessage: placeholder,
              })
            : placeholder
        }
        readOnly={fieldReadOnly ? fieldReadOnly : readOnly}
        className={className}
        style={style}
      />
    </Form.Item>
  );
};

export default InputField;
