import { firebase } from '@services/Firebase';
import {
  PURCHASES_PAYMENT_TERMS_COLLECTION_NAME,
  PaymentTerm,
} from '@apps/purchases/services/paymentTerms/namespace';
import { getWithId, post, patch } from '@services/api';
import moment from 'moment/moment';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig, { PURCHASES_ACTION } from '@apps/purchases';
import { removeUndefinedProperties } from '@services/utils';
export * from './namespace';

export const createPaymentTerm = (term: PaymentTerm): Promise<string> =>
  post(`${PURCHASES_PAYMENT_TERMS_COLLECTION_NAME}`, {
    ...removeUndefinedProperties(term),
    timestamp: moment().valueOf(),
  });

export const updatePaymentTerm = (id: string, term: PaymentTerm) =>
  patch(`${PURCHASES_PAYMENT_TERMS_COLLECTION_NAME}/${id}`, {
    ...removeUndefinedProperties(term),
    timestamp: moment().valueOf(),
  });

export const getPaymentTermById = (id: string) => {
  return getWithId(
    `${PURCHASES_PAYMENT_TERMS_COLLECTION_NAME}/${id}`,
  ) as Promise<PaymentTerm>;
};

export const getActivePaymentTerms = (
  ecosystemId: string,
): Promise<any | null> | undefined => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-purchases' as PURCHASES_ACTION,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve([]);
  }

  return firebase.firestore
    ?.collection(PURCHASES_PAYMENT_TERMS_COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .where('isActive', '==', true)
    .get()
    .then(getArrayWithIds) as Promise<PaymentTerm[]>;
};

export const getPaymentTerms = (
  ecosystemId: string,
): Promise<any | null> | undefined => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-purchases' as PURCHASES_ACTION,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve([]);
  }

  return firebase.firestore
    ?.collection(PURCHASES_PAYMENT_TERMS_COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs ? (docs as PaymentTerm[]) : [];
    });
};
