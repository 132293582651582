import {
  FilterAddressObject,
  FilterNumberObject,
} from '@services/filtering/index';
import get from 'lodash.get';

const numberMatcher = (
  filterObject: FilterNumberObject | FilterAddressObject,
  row: any,
  key: string,
) => {
  const value = Number(get(row, key));
  const filterValue = Number(filterObject.filter);

  if (filterObject.type === 'notEqual') {
    return value !== filterValue;
  }

  if (filterObject.type === 'equals') {
    return value === filterValue;
  }

  if (filterObject.type === 'lessThan') {
    return value < filterValue;
  }

  if (filterObject.type === 'lessThanOrEqual') {
    return value <= filterValue;
  }

  if (filterObject.type === 'greaterThan') {
    return value > filterValue;
  }

  if (filterObject.type === 'greaterThanOrEqual') {
    return value >= filterValue;
  }

  if (filterObject.type === 'inRange') {
    const filterTo = Number((filterObject as FilterNumberObject).filterTo);
    return value >= filterValue && value <= filterTo;
  }

  return false;
};

export default numberMatcher;
