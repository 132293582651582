import { firebase } from '@services/Firebase';

type FetchResourcePayload = {
  collection: string;
  id: string;
  relationId?: string;
};

const fetchResource = ({
  collection,
  id,
  relationId,
}: FetchResourcePayload) => {
  return firebase.firestore
    ?.collection(collection)
    .doc(id)
    .get()
    .then((data) => {
      if (data.exists) {
        return {
          ...data.data(),
          id,
          collection,
          relationId,
        };
      } else return null;
    });
};

export default fetchResource;
