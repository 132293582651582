import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/sales';
import { get as apiGet } from '@services/api';
import getFilteredResults from '@core/helpers/getFilteredResults';
import {
  GetApiResponse,
  Sale,
  SALES_COLLECTION_NAME,
} from '@apps/sales/services/namespace';

const getSales: GetApiResponse = (passedParams, action) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 1000,
    fields: [
      'id',
      'ecosystem',
      'contact',
      'contactSnapshot',
      'paymentStatus',
      'shipmentStatus',
      'products',
      'timestamp',
    ],
    filters: {},
    sort: [],
    ...passedParams,
  };

  // @ts-ignore
  return apiGet(SALES_COLLECTION_NAME)?.then((data: Sale[]) => {
    return getFilteredResults({
      data,
      params,
      activeEcosystemsIds,
    });
  });
};

export default getSales;
