import React, { FC, useEffect, useState } from 'react';
import { FormAutoCompleteContact } from '@apps/documents/widgets/FormAutoCompleteContact';

import SelectedEcosystem from '@components/SelectedEcosystem';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import AppConfig from '@apps/sales';
import { FormContactDetails } from '@apps/documents/widgets/FormContactDetails';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormAutoCompleteProducts } from '@apps/products/widgets/FormAutoCompleteProducts';
import { DatePicker, Form as aForm, Input } from 'antd';
import { addSaleOperation } from '@apps/sales/services/saleOperation';
import { useHistory } from 'react-router-dom';
import { StepSelect } from '@apps/sales/mainMenu/components/StepSelect';
import { QuotationTemplate } from '@apps/sales/mainMenu/components/pdfTemplates/QuotationTemplate';
import { usePDF } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import { firebase } from '@services/Firebase';
import storage from '@services/Firebase/Storage';
import { blobToFile } from '@apps/utils';
import { postDocument, Document } from '@apps/documents/service';
import moment from 'moment';
import {
  addSalesRangeOfNumbers,
  getRangeOfNumbersByEcosystem,
} from '@services/rangeOfNumbers';
import { DeliveryTerms } from '@apps/sales/mainMenu/components/DelvieryTerms';
import {
  getDocumentNumber,
  svgString2Image,
} from '@apps/sales/mainMenu/components/utils';
const { Item } = aForm;

type Props = {
  initialValues?: object;
};

export const Form: FC<Props> = () => {
  const intl = useIntl();
  const [formValues, setFormValues] = useState<any>(null);
  const [avatarBase64, setAvatarBase64] = useState<string | null>(null);
  const [savePdf, setSavePdf] = useState(false);
  const [rangeOfNumbers, setRangeOfNumbers] = useState<any>(null);
  const history = useHistory();

  const ecosystem = useSelector(
    getEcosystemById(formValues?.ecosystem || ''),
  ) as Ecosystem;

  useEffect(() => {
    if (!formValues?.ecosystem) {
      return;
    }

    getRangeOfNumbersByEcosystem(formValues?.ecosystem)?.then((el) => {
      setRangeOfNumbers(el);
    });
  }, [formValues?.ecosystem]);

  useEffect(() => {
    if (!ecosystem) {
      return;
    }

    fetch(ecosystem.avatarUrl as string)
      .then((r: any) => r.text())
      .then((text: any) => {
        svgString2Image(text, 24, 24, 'png', (pngData: string) => {
          setAvatarBase64(pngData);
        });
      });
  }, [ecosystem]);

  const [instance, update] = usePDF({
    document: QuotationTemplate(
      formValues,
      ecosystem,
      firebase.auth.currentUser,
      rangeOfNumbers,
      getDocumentNumber(rangeOfNumbers, formValues?.type),
      avatarBase64,
    ),
  });

  useEffect(() => {
    if (savePdf && !instance.loading && instance.blob) {
      setSavePdf(false);

      const fileName = `${formValues.ecosystem}-${moment().format(
        'YYYY-MM-DD',
      )}-${getDocumentNumber(rangeOfNumbers, formValues?.type)}`;

      const fileToUpload = {
        file: blobToFile(instance.blob, fileName),
        ecosystem: formValues.ecosystem,
        fileDetails: {
          format: 'pdf',
          size: instance.blob.size,
        },
      };

      const user = firebase.auth.currentUser;
      storage
        .upload(`documents/${fileName}`, fileToUpload.file as unknown as Blob)
        ?.then((snapshot) => snapshot.ref.getDownloadURL())
        .then((documentUrl) => {
          postDocument(
            {
              documentUrl,
              docNumber: fileName,
              ecosystem: fileToUpload.ecosystem,
              fileDetails: fileToUpload.fileDetails as Document['fileDetails'],
              type: formValues.type,
              uploadingUser: user?.uid,
              uploadingUserId: user?.uid,
              creatingUser: user?.uid,
              uploadDate: new Date().toISOString(),
              receivingDate: formValues.deliveryDate,
              issuingDate: formValues.deliveryDate,
              creationDate: moment(),
              relatedContact: formValues.contact.id,
              relatedContactSnapshot: formValues.contactSnapshot,
              isDraft: false,
              category: 'outgoing',
            },
            {
              message: false,
            },
          );
          addSalesRangeOfNumbers(
            {
              ...rangeOfNumbers,
              fileTypes: {
                ...rangeOfNumbers?.fileTypes,
                [formValues.type]: {
                  ...rangeOfNumbers?.fileTypes?.[formValues.type],
                  nextNumber: rangeOfNumbers?.fileTypes?.[formValues.type]
                    ?.nextNumber
                    ? Number(
                        rangeOfNumbers?.fileTypes?.[formValues.type]
                          ?.nextNumber,
                      ) + 1
                    : 1,
                },
              },
            },
            fileToUpload.ecosystem,
            {
              message: false,
            },
          );
        });
    }
  }, [instance, savePdf, rangeOfNumbers]);

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong:</div>;

  return (
    <AppEntityForm
      initialValues={{}}
      appId={AppConfig.todixId}
      readOnly={false}
      onSubmit={(values) => {
        update();
        setFormValues(values);
        // to save pdf after pdf update
        setSavePdf(true);
        const docName = getDocumentNumber(rangeOfNumbers, formValues?.type);
        const user = firebase.auth.currentUser;
        addSaleOperation({
          ...values,
          docName,
          creatorSnapshot: {
            displayName: user?.displayName as string,
            photoURL: user?.photoURL as string,
            email: user?.email as string,
          },
          creationDate: new Date().toISOString(),
          contact: values.contact.id,
        }).then(() => {
          history.push(`/app/${AppConfig.todixId}`);
        });
      }}
      onFieldsChange={(oldFormValues: any) => {
        setFormValues(oldFormValues);
      }}
    >
      {(form) => {
        return (
          <>
            <SelectedEcosystem
              appId={AppConfig.todixId}
              onChange={() => {
                form.setFieldsValue({
                  ...form.getFieldsValue(),
                  products: [],
                  contact: null,
                });
              }}
            />
            <FormAutoCompleteContact
              form={form}
              disabled={false}
              label={
                <FormattedMessage
                  id="sales.createNew.contact"
                  defaultMessage="Contact"
                />
              }
              required
              contactFormName="contact"
              contactSnapshotFormName="contactSnapshot"
            />

            <FormContactDetails
              form={form}
              label={' '}
              contactFormName="contact"
              contactSnapshotFormName="contactSnapshot"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="sales.createNew.contact.error"
                      defaultMessage="Missing contact"
                    />
                  ),
                },
              ]}
            />
            <Item
              name="deliveryDate"
              label={
                <FormattedMessage
                  id="sales.createForm.deliveryDate.label"
                  defaultMessage="Delivery Date"
                />
              }
            >
              <DatePicker
                placeholder={intl.formatMessage({
                  id: 'tasks.form.duedate.placeholder',
                  defaultMessage: 'Select date',
                })}
              />
            </Item>
            {form.getFieldsValue().ecosystem && (
              <Item
                label={intl.formatMessage({
                  id: 'sales.createNew.type.label',
                  defaultMessage: 'Type',
                })}
                name="type"
                required
                dependencies={['ecosystem']}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="sales.createNew.type.error"
                        defaultMessage="Missing document type"
                      />
                    ),
                  },
                ]}
              >
                <StepSelect ecosystemId={form.getFieldsValue().ecosystem} />
              </Item>
            )}
            <DeliveryTerms form={form} />
            <Item
              label={intl.formatMessage({
                id: 'sales.createNew.reference.label',
                defaultMessage: 'Reference',
              })}
              name="reference"
            >
              <Input
                name={`reference`}
                placeholder={intl.formatMessage({
                  id: 'sales.createNew.reference.label',
                  defaultMessage: 'Reference',
                })}
              />
            </Item>
            <FormAutoCompleteProducts
              form={form}
              disabled={false}
              formValues={formValues}
              label={
                <FormattedMessage
                  id="sales.createNew.product"
                  defaultMessage="Products"
                />
              }
            />
          </>
        );
      }}
    </AppEntityForm>
  );
};
