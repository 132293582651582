import { styled } from '@styles/themes';
import { Card } from '@todix/ui-components';

export const UploadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const UploadCard = styled(Card)`
  width: 100%;
  max-width: 1208px;

  && {
    .ant-card-actions {
      padding: 0 24px;
      display: flex;
      justify-content: flex-end;

      > * {
        flex: 1;
        max-width: 120px;
        margin-left: -28px;
        border: 0;
      }
    }
  }
`;

export const InboxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  height: 480px;
`;
