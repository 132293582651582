import React, { FC, useRef, useEffect, useState } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';

type PdfViewerProps = {
  documentUrl: string;
  height?: string;
  width?: string;
};

const PdfViewer: FC<PdfViewerProps> = ({ documentUrl, height, width }) => {
  const pdfViewerContainer = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const webViewerApi = useRef<any>(null);

  useEffect(() => {
    if (documentUrl && !isLoading) {
      fetch(documentUrl)
        .then((response) => response.blob())
        .then((blob) => {
          if (webViewerApi.current) {
            const instance = webViewerApi.current;
            instance.UI.loadDocument(blob, { filename: 'myfile.pdf' });
          }
        });
    }
  }, [documentUrl, isLoading]);

  useEffect(() => {
    if (pdfViewerContainer && pdfViewerContainer.current) {
      WebViewer(
        {
          path: '/pdfViewer', // point to where the files you copied are served from
          licenseKey: process.env.PDFVIEWER_LICENSE_KEY,
        },
        pdfViewerContainer.current,
      ).then((instance: any) => {
        webViewerApi.current = instance;
        const docViewer = instance.docViewer;
        docViewer.setFitMode(docViewer.FitMode.FitWidth);
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <div
      ref={pdfViewerContainer}
      style={{
        height: height || '100vh',
        width: width || '60vw',
      }}
    />
  );
};

export default PdfViewer;
