import React, { FC, useEffect, useState } from 'react';
import { Form } from 'antd';
import { LayoutField, PaymentEntity, Document } from '@apps/documents/service';
import { getLayout } from '@apps/documents/service/layouts';
import moment from 'moment';
import LayoutRenderer from '@apps/documents/mainMenu/LayoutRenderer';

const { Item } = Form;

type Props = {
  type: string;
  form: any;
  initialValues?: Partial<Document>;
};

const DocumentSpecificFields: FC<Props> = ({ type, form, initialValues }) => {
  const [layout, setLayout] = useState<LayoutField[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type && form) {
      try {
        setLoading(true);
        const processingLayout = getLayout(type, false, 'processing');

        processingLayout.forEach((field) => {
          const fieldName = field.fieldName;
          const fieldValue = form.getFieldValue(fieldName);
          if (field.type === 'date' && fieldValue) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              [fieldName]: moment(fieldValue as string, moment.defaultFormat),
            });
          }
          /*  if (field.fieldType === 'costStructure' && fieldValue) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              [fieldName]: (fieldValue as CostEntity[])?.map((cost) => ({
                ...cost,
                netAmount: cost.netAmount,
              })),
            });
          }*/
          if (field.fieldType === 'payments' && fieldValue) {
            form.setFieldsValue({
              ...form.getFieldsValue(),
              [fieldName]: (fieldValue as PaymentEntity[])?.map(
                (payment: PaymentEntity) => ({
                  ...payment,
                  payAmount: payment.payAmount,
                  payDate: moment(
                    payment.payDate as string,
                    moment.defaultFormat,
                  ),
                }),
              ),
            });
          }
        });

        // automatically added values:
        form.setFieldsValue({
          ...form.getFieldsValue(),
          receivingDate: form.getFieldValue('receivingDate'),
          issuingDate: initialValues?.issuingDate
            ? moment(form.getFieldValue('issuingDate'))
            : undefined,
        });

        setLayout(processingLayout);
        setLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
  }, [type, loading, form, initialValues?.issuingDate]);

  return <LayoutRenderer layoutFields={layout} />;
};

type DefaultProps = {
  initialValues?: Partial<Document>;
};

export default ({ initialValues }: DefaultProps) => {
  return (
    <Item
      shouldUpdate={(prevValues, nextValues) =>
        prevValues.type !== nextValues.type
      }
      noStyle
    >
      {(formInstance) => {
        const type = formInstance.getFieldValue('type');
        return (
          <DocumentSpecificFields
            type={type}
            form={formInstance}
            initialValues={initialValues}
          />
        );
      }}
    </Item>
  );
};
