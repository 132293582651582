import moment from 'moment';
import { patch } from '@services/api';
import { PRODUCT_PRICES } from '@apps/products/services/namespace';

const archiveProductPrice = (id: string) =>
  patch(`${PRODUCT_PRICES}/${id}`, {
    isArchived: true,
    timestamp: moment().valueOf(),
  });

export default archiveProductPrice;
