import React, { FC, useEffect, useState } from 'react';
import { Divider, Form, Input } from 'antd';
import { useIntl } from 'react-intl';
import StepForm from '@apps/documents/mainMenu/DocumentProcessing/StepForm';
import { ChildrenPassedProps } from '@apps/documents/mainMenu/DocumentProcessing/Step';
import { Document, LayoutField } from '@apps/documents/service';
import { getLayout } from '@apps/documents/service/layouts';
import LayoutRenderer from '@apps/documents/mainMenu/LayoutRenderer';
import {
  Contact,
  RelatedDocuments,
} from '@apps/documents/mainMenu/components/FormFields';
import moment from 'moment';

const Step5: FC<ChildrenPassedProps> = ({
  document,
  triggerValidator,
  ...rest
}) => {
  const [layout, setLayout] = useState<LayoutField[]>([]);
  const [type, setType] = useState(document.type);
  const [category, setCategory] = useState(document.category);
  const [parsedDocument, setParsedDocument] =
    useState<Partial<Document> | null>(null);
  const intl = useIntl();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (document.type !== type) {
      setType(document.type);
      setLoading(true);
    }
  }, [document.type, type]);

  useEffect(() => {
    if (document.category) {
      setCategory(document.category);
    }
  }, [document.category]);

  useEffect(() => {
    if (type && document && loading) {
      try {
        const processingLayout = getLayout(type, true, 'processingSummary');
        let doc = Object.assign({}, document);

        processingLayout.forEach((field) => {
          if (field.type === 'date' && doc[field.fieldName]) {
            doc = {
              ...doc,
              [field.fieldName]: moment(
                doc[field.fieldName] as string,
                moment.defaultFormat,
              ),
            };
          }
        });

        setLayout(processingLayout);
        setParsedDocument(doc);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }
  }, [document, loading, type]);

  return !loading && parsedDocument ? (
    <StepForm {...rest} initialValues={parsedDocument}>
      {type && (
        <Form.Item
          label={intl.formatMessage({
            id: 'documentprocessing.step5.type.label',
            defaultMessage: 'Document type',
          })}
          colon={false}
          labelAlign="left"
        >
          <Input
            value={intl.formatMessage({
              id: `documents.documentTypes.${type}`,
              defaultMessage: type,
            })}
            readOnly
            style={{
              border: 0,
            }}
          />
        </Form.Item>
      )}
      {category && (
        <Form.Item
          label={intl.formatMessage({
            id: 'documentprocessing.step5.category.label',
            defaultMessage: 'Document category',
          })}
          colon={false}
          labelAlign="left"
        >
          <Input
            value={intl.formatMessage({
              id: `documents.documentTypes.category.${category}`,
              defaultMessage: `documents.documentTypes.category.${category}`,
            })}
            readOnly
            style={{
              border: 0,
            }}
          />
        </Form.Item>
      )}
      <Divider type="horizontal" />
      <Contact
        field={{
          fieldName: 'relatedContact',
          fieldType: 'contact',
          label: intl.formatMessage({
            id: 'documentprocessing.step5.relatedcontact.label',
            defaultMessage: 'Related contact',
          }),
        }}
        initialValue={document.relatedContact}
      />
      <Divider type="horizontal" />
      <RelatedDocuments
        field={{
          fieldName: 'relatedDocumentsSnapshot',
          fieldType: 'relatedDocuments',
          label: intl.formatMessage({
            id: 'documentprocessing.step5.relateddocuments.label',
            defaultMessage: 'Related documents',
          }),
          readOnly: true,
          style: {
            border: 0,
          },
        }}
        initialValue={document.relatedDocumentsSnapshot}
      />
      <Divider type="horizontal" />
      <div
        style={{
          display: rest.hidden || !parsedDocument ? 'none' : 'block',
        }}
      >
        <LayoutRenderer layoutFields={layout} />
      </div>
    </StepForm>
  ) : null;
};

export default Step5;
