import { CartEntry } from '@services/cart/namespace';
import { CartAction, CartActionTypes } from '@core/store/cart/actions';
import isCartEntryEqual from '@services/cart/isCartEntryEqual';

export type CartState = {
  data: CartEntry[];
  isLoading: boolean;
};

export const initial: CartState = {
  data: [],
  isLoading: false,
};

export const cartReducer = (state = initial, action: CartAction) => {
  switch (action.type) {
    case CartActionTypes.INIT_CART: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case CartActionTypes.REMOVE_ENTRY_CART: {
      const cartEntries = state.data;
      const entryToRemove = action.payload;

      const foundedEntryIndex = cartEntries.findIndex((entry) =>
        isCartEntryEqual(entry, entryToRemove),
      );

      if (foundedEntryIndex !== -1) {
        const newEntries = cartEntries.filter(
          (_entry, index) => index !== foundedEntryIndex,
        );

        return {
          ...state,
          data: [...newEntries],
        };
      } else {
        return state;
      }
    }
    case CartActionTypes.UPDATE_CART: {
      let cartEntries = state.data;
      const newCartEntry = action.payload.cartEntry;
      const { override } = action.payload;
      if (cartEntries.length < 1) {
        return {
          ...state,
          data: [newCartEntry],
        };
      } else {
        const foundedEntryIndex = cartEntries.findIndex((entry) =>
          isCartEntryEqual(entry, newCartEntry),
        );

        if (foundedEntryIndex !== -1) {
          cartEntries[foundedEntryIndex] = {
            ...cartEntries[foundedEntryIndex],
            quantity: override
              ? newCartEntry.quantity
              : cartEntries[foundedEntryIndex].quantity + newCartEntry.quantity,
          };
        } else {
          cartEntries = [...cartEntries, newCartEntry];
        }

        return {
          ...state,
          data: [...cartEntries],
          isLoading: false,
        };
      }
    }
    case CartActionTypes.REMOVE_CART: {
      return {
        ...state,
        data: [],
      };
    }
    default:
      return state;
  }
};
