import * as React from 'react';
import { Avatar, Statistic } from 'antd';
import { Text } from '@todix/ui-components';
import ExampleAvatar from '@assets/images/icon_avatar_example.png';
import {
  AuthorSection,
  SectionTitle,
  Section,
  TextDescriptionSection,
  UserName,
  UserPart,
  Wrapper,
} from '@apps/contacts/TopBar.sc';
import SparklineChart from '@components/SparklineChart';

//TODO: work on that component has been frozen (by design) - components from this place will be used later inside form

const TopBar: React.FC = () => {
  return (
    <Wrapper>
      <AuthorSection>
        <SectionTitle level={2}>Author</SectionTitle>
        <UserPart>
          <Avatar src={ExampleAvatar} />
          <UserName level={2}>@username</UserName>
        </UserPart>
      </AuthorSection>
      <TextDescriptionSection>
        <SectionTitle level={2}>Test description</SectionTitle>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
          lobortis rutrum ipsum, in malesuada turpis accumsan a. Morbi tristique
          mi dolor, eget rhoncus.
        </Text>
      </TextDescriptionSection>
      <Section>
        <SectionTitle level={2}>Sample statistics</SectionTitle>
        <Statistic value={112634} />
      </Section>
      <Section>
        <SectionTitle level={2}>Sparkline chart</SectionTitle>
        <SparklineChart data={[0, 2, 1, 3]} autoFit />
      </Section>
    </Wrapper>
  );
};

export default TopBar;
