import moment from 'moment';
import { put } from '@services/api';
import {
  PRODUCTS_COLLECTION_NAME,
  Product,
} from '@apps/products/services/namespace';

const updateProduct = (id: string, product: Product) =>
  put(`${PRODUCTS_COLLECTION_NAME}/${id}`, {
    ...product,
    timestamp: moment().valueOf(),
  });

export default updateProduct;
