import React, { CSSProperties, FC } from 'react';
import { IntlShape } from 'react-intl';
import { DocumentType } from '@apps/documents/service';
import { Style } from '@react-pdf/types';
import { Text, View } from '@react-pdf/renderer';

export type DocNumberProps = {
  style?: Style;
  intl: IntlShape;
  type: DocumentType;
  docNumber: string | number;
  forPdf: boolean;
};

const DocNumber: FC<DocNumberProps> = ({
  intl,
  style,
  type,
  docNumber,
  forPdf,
}) => {
  return forPdf ? (
    <View style={style}>
      <Text>
        {intl.formatMessage({
          id: `${type}`,
          defaultMessage: type,
        })}
        {': '}
        {docNumber}
      </Text>
    </View>
  ) : (
    <div style={style as CSSProperties}>
      {intl.formatMessage({
        id: `${type}`,
        defaultMessage: type,
      })}
      {': '}
      {docNumber}
    </div>
  );
};

export default DocNumber;
