import { Consultant } from '@apps/directSales/mainMenu/Consultants/services';
import { firebase } from '@services/Firebase';

type Payload = {
  period: string;
  ecosystemId: string;
};

export const generateCommissionsExport = async (payload: Payload) => {
  const document = await getCommissionPeriodStats(payload);

  return buildCsvFile(document);
};

interface CommissionPeriodStats {
  [key: string]: {
    commissionBonus: number;
    consultantId: string;
    consultantName: string;
    consultantRecruitingPeriod: string;
    ecosystemId: string;
    memberBonus: number;
    members: [];
    netSales: number;
    newJoiners: number;
    parentId: string;
    productBonus: number;
    recruitingBonus: number;
    relatedLevel: string;
    sales: number;
    teamNetSales: number;
    teamSales: number;
    teamSize: number;
    yearlyJoker: number;
  };
}

const getCommissionPeriodStats = async (payload: Payload) => {
  const commissionPeriodStatsRef = firebase.firestore?.collection(
    'commissionPeriodStats',
  )!;
  const querySnapshot = await commissionPeriodStatsRef.get();

  const matchingDocument = querySnapshot.docs.find(
    (doc) =>
      doc.id.endsWith(`_${payload.period}`) &&
      doc.id.startsWith(payload.ecosystemId),
  );

  if (!matchingDocument) {
    throw new Error(`No document found for period: ${payload.period}`);
  }

  return matchingDocument.data() as CommissionPeriodStats;
};

const buildCsvFile = async (data: CommissionPeriodStats): Promise<string> => {
  const header = [
    'Berater Nummer',
    'Vorname',
    'Nachname',
    'Dabei seit',
    'Vorname Team Partner (Sponsor)',
    'Nachname Team Partner (Sponsor)',
    'Eigenumsatz',
    'Team-Umsatz ohne Eigenumsatz',
    'Qualifikation Team-Umsatz mit Eigenumsatz',
    'Qualifikation Anzahl Berater*innen 1. Ebene',
    'Erreichter Karrieretitel',
    'Provision % Eigenumsatz',
    'Provision € Eigenumsatz',
    'Provision € Teamumsatz',
    'Produkt Bonus',
    'Rekrutierungen',
    'Rekrutierungs-Bonus',
    'Gesamt-verdienst',
    'Joker',
    'IBAN',
    'BIC',
  ] as const;

  const getConsultant = async (consultantId?: string) => {
    if (!consultantId) {
      return null;
    }

    const commissionPeriodStatsRef =
      firebase.firestore?.collection('consultants')!;
    const documentSnapshot = await commissionPeriodStatsRef
      .doc(consultantId)
      .get();

    if (!documentSnapshot.exists) {
      return null;
    }

    return documentSnapshot.data() as Consultant;
  };

  const commissionsStats = [];
  for (const [, value] of Object.entries(data)) {
    const [consultant, parent] = await Promise.all([
      getConsultant(value.consultantId),
      getConsultant(value.parentId),
    ]);

    const parseFractionalNumber = (num?: number | string) => {
      if (!num) {
        return '';
      }

      return (Math.round(Number(num) * 100) / 100).toString();
    };

    const commissionStat = {
      'Berater Nummer': consultant?.consultantNo,
      Vorname: consultant?.consultantName,
      Nachname: consultant?.consultantSurname,
      'Dabei seit': value.consultantRecruitingPeriod,
      'Vorname Team Partner (Sponsor)': parent?.consultantName || ' ',
      'Nachname Team Partner (Sponsor)': parent?.consultantSurname || ' ',
      Eigenumsatz: parseFractionalNumber(value.sales),
      'Team-Umsatz ohne Eigenumsatz': parseFractionalNumber(value.teamSales),
      'Qualifikation Team-Umsatz mit Eigenumsatz': ' ',
      'Qualifikation Anzahl Berater*innen 1. Ebene': `${value.teamSize}`,
      'Erreichter Karrieretitel': `${value.relatedLevel}`,
      'Provision % Eigenumsatz': parseFractionalNumber(value.commissionBonus),
      'Provision € Eigenumsatz': ' ',
      'Provision € Teamumsatz': parseFractionalNumber(value.memberBonus),
      'Produkt Bonus': parseFractionalNumber(value.productBonus),
      Rekrutierungen: `${value.newJoiners}`,
      'Rekrutierungs-Bonus': parseFractionalNumber(value.recruitingBonus),
      'Gesamt-verdienst': ' ',
      Joker: `${value.yearlyJoker}`,
      IBAN: consultant?.iban,
      BIC: consultant?.bic,
    } as const;

    commissionsStats.push(commissionStat);
  }

  const csvContent = [
    header.join(','),
    ...commissionsStats.map((row) => Object.values(row).join(',')),
  ].join('\n');
  return csvContent;
};
