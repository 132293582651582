import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Form } from 'antd';

const { Item } = Form;

const NumberDeliveryNote: FC = () => {
  const intl = useIntl();
  return (
    <Item
      className="animate__animated animate__slideInLeft animate__faster"
      name="deliveryNote"
      label={
        <FormattedMessage
          id="warehouse.create.number.delivery.note"
          defaultMessage="Number delivery note"
        />
      }
      rules={[
        {
          type: 'string',
          max: 80,
          message: (
            <FormattedMessage
              id="warehouse.create.number.delivery.note.error"
              defaultMessage="Delivery note cannot be longer than 80 characters"
            />
          ),
        },
      ]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'warehouse.create.number.delivery.note.placeholder',
          defaultMessage: 'Number delivery note...',
        })}
        autoComplete="nope"
      />
    </Item>
  );
};

export default NumberDeliveryNote;
