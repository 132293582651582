import React, { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { Card, Tree } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { UseParamsType } from '@pages/settings';
import { UnitGroupFormModal } from './UnitGroupFormModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUnitGroups } from '@core/layout/store/unitGroupsSaga/actions';
import { getUnitGroupsForEcosystemSelector } from '@core/layout/store/unitGroupsSaga/selectors';
import { StyledSwitch } from '@pages/settings/components';
import { UnitFormModal } from './UnitFormModal';
import { Unit, UnitGroup } from '@services/unitGroups/namespace';
import { deleteUnit, deleteUnitGroup, updateUnit } from '@services/unitGroups';
import styled, { css } from 'styled-components';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import intl from '../../../../../intl';

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
`;
const StyledTree = styled(Tree)`
  && {
    .ant-tree-treenode {
      height: 40px;
    }

    .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: transparent;
    }
  }
`;

export const ActionIconStyled = css`
  font-size: 18px;
  margin-left: 8px;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.main.primary};
  }
`;

export const StyledPlusCircleOutlined = styled(PlusCircleOutlined)`
  ${ActionIconStyled}
`;

export const StyledEditIcon = styled(EditOutlined)`
  ${ActionIconStyled}
`;

export const StyledDeleteIcon = styled(DeleteOutlined)`
  ${ActionIconStyled}
`;

// @ts-ignore
export const UnitSwitch = styled(StyledSwitch)`
  margin-left: 8px;
`;

const UnitGroups: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [selectedUnitGroup, setSelectedUnitGroup] = useState<
    UnitGroup | undefined
  >(undefined);
  const [selectedUnit, setSelectedUnit] = useState<Unit | undefined>(undefined);
  const [isUnitGroupModalVisible, setIsUnitGroupModalVisible] = useState(false);
  const [isUnitModalVisible, setIsUnitModalVisible] = useState(false);
  const dispatch = useDispatch();
  const unitGroups = useSelector(getUnitGroupsForEcosystemSelector(uid));

  //todo: do in dashboard initialization
  // also handle update in every place where data are changed -> can be done with refreshProductGroups
  useEffect(() => {
    dispatch(fetchUnitGroups());
  }, [uid]);

  const handleUnitGroupsDeletion = useCallback(
    async (unitGroupName: string) => {
      await deleteUnitGroup(unitGroups, unitGroupName, uid);
      dispatch(fetchUnitGroups());
    },
    [unitGroups, uid],
  );

  const handleUnitDeletion = useCallback(
    async (unitName: string, unitGroup: UnitGroup) => {
      await deleteUnit(unitName, uid, unitGroups, unitGroup);
      dispatch(fetchUnitGroups());
    },
    [unitGroups, uid],
  );

  const handleUnitStatusToggle = useCallback(
    async (unit: Unit, unitGroup: UnitGroup) => {
      await updateUnit(
        {
          ...unit,
          isActive: !unit.isActive,
        },
        unit.name,
        uid,
        unitGroups,
        unitGroup,
      );
      dispatch(fetchUnitGroups());
    },
    [unitGroups, uid],
  );

  const treeData = unitGroups?.groups?.map((unitGroup) => ({
    key: unitGroup.name,
    title: intl.formatMessage({
      id: `products.group.${unitGroup.name.toLowerCase()}`,
      defaultMessage: unitGroup.name,
    }),
    isDefault: unitGroup.isDefault,
    isLeaf: false,
    children: unitGroup.units?.map((unit) => ({
      key: unit.name,
      title: intl.formatMessage({
        id: `products.unit.${unit.name}`,
        defaultMessage: unit.name,
      }),
      isDefault: unit.isDefault,
      isActive: unit.isActive,
      unitGroup: unitGroup,
    })),
  }));

  const activated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.activated"
        defaultMessage="Activated"
      />
    ),
    [],
  );
  const deactivated = useMemo(
    () => (
      <FormattedMessage
        id="settings.status.deactivated"
        defaultMessage="Deactivated"
      />
    ),
    [],
  );

  return (
    <Card
      title={
        <FormattedMessage
          id="settings.products.units.cardTitle"
          defaultMessage="Units"
        />
      }
      /*extra={
        <AddNewButton onClick={() => setIsUnitGroupModalVisible(true)}>
          <FormattedMessage
            id="settings.products.units.addNew.button.text"
            defaultMessage="Add new unit group"
          />
        </AddNewButton>
      }*/
    >
      {treeData && (
        <StyledTree
          blockNode
          showLine={{
            showLeafIcon: false,
          }}
          treeData={treeData}
          defaultExpandAll
          titleRender={(el) => {
            return (
              <StyledTitle>
                <span
                  style={{
                    fontSize: (el as any).isActive === undefined ? 20 : '1em',
                  }}
                >
                  {(el as any).title}
                </span>
                {(el as any).isActive === undefined ? (
                  /*<StyledPlusCircleOutlined
                    onClick={() => {
                      setSelectedUnitGroup({
                        name: (el as any).key as unknown as string,
                        units: (el as any).children as unknown as Unit[],
                      });
                      setIsUnitModalVisible(true);
                    }}
                  />*/
                  <div />
                ) : (
                  <UnitSwitch
                    onChange={() =>
                      handleUnitStatusToggle(
                        {
                          name: (el as any).key as unknown as string,
                          // @ts-ignore
                          isActive: el.isActive,
                        },
                        // @ts-ignore
                        el.unitGroup,
                      )
                    }
                    checkedChildren={activated}
                    unCheckedChildren={deactivated}
                    checked={(el as any).isActive}
                  />
                )}
                {!(el as any).isDefault && (
                  <StyledEditIcon
                    onClick={() => {
                      if ((el as any).isActive === undefined) {
                        setSelectedUnitGroup({
                          name: (el as any).key as unknown as string,
                          units: (el as any).children as unknown as Unit[],
                        });
                        setIsUnitGroupModalVisible(true);
                      } else {
                        setSelectedUnitGroup(
                          // @ts-ignore
                          (el as any).unitGroup as unknown as UnitGroup,
                        );
                        setSelectedUnit({
                          name: (el as any).key as unknown as string,
                          // @ts-ignore
                          isActive: (el as any).isActive,
                        });
                        setIsUnitModalVisible(true);
                      }
                    }}
                  />
                )}
                {!(el as any).isDefault && (
                  <StyledDeleteIcon
                    onClick={async () => {
                      // groups
                      if ((el as any).isActive === undefined) {
                        await handleUnitGroupsDeletion(
                          (el as any).key as unknown as string,
                        );
                      }
                      // unit
                      else {
                        await handleUnitDeletion(
                          (el as any).key as unknown as string,
                          // @ts-ignore
                          (el as any).unitGroup as unknown as UnitGroup,
                        );
                      }
                    }}
                  />
                )}
              </StyledTitle>
            );
          }}
        />
      )}
      <UnitFormModal
        selectedUnit={selectedUnit}
        unitGroups={unitGroups}
        selectedUnitGroup={selectedUnitGroup}
        setSelectedUnitGroup={setSelectedUnitGroup}
        setSelectedUnit={setSelectedUnit}
        setVisible={setIsUnitModalVisible}
        visible={isUnitModalVisible}
        ecosystemId={uid}
      />
      <UnitGroupFormModal
        unitGroups={unitGroups}
        selectedUnitGroup={selectedUnitGroup}
        setSelectedUnitGroup={setSelectedUnitGroup}
        setVisible={setIsUnitGroupModalVisible}
        visible={isUnitGroupModalVisible}
        ecosystemId={uid}
      />
    </Card>
  );
};

export default UnitGroups;
