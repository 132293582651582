import { styled } from '@styles/themes';
import { Button } from 'antd';

const StyledButton = styled(Button)`
  && {
    flex-direction: row-reverse;

    .anticon {
      margin-left: 4px;
    }
  }
`;

export default StyledButton;
