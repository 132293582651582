import moment from 'moment';
import { post } from '@services/api';
import {
  PRODUCTS_COLLECTION_NAME,
  Product,
} from '@apps/products/services/namespace';

const postProduct = (product: Product) =>
  post(PRODUCTS_COLLECTION_NAME, { ...product, timestamp: moment().valueOf() });

export default postProduct;
