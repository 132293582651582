import { styled } from '@styles/themes';
import { Card } from 'antd';

const WidgetCard = styled(Card)`
  width: 20vw;

  & .ant-card-head-title {
    > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export default WidgetCard;
