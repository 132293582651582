export const supportedFileTypes = {
  quotation: 'QT',
  orderConfirmation: 'ORC',
  performaInvoice: 'PI',
  deliveryNote: 'DN',
  invoice: 'INV',
  creditNote: 'CN',
  order: 'OR',
  dunningLetter: 'DL',
};

export const MIN_LENGTH_SELECT: Record<string, number> = {
  '2-digits': 2,
  '3-digits': 3,
  '4-digits': 4,
  '5-digits': 5,
  '6-digits': 6,
};
