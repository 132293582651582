import { styled } from '@styles/themes';
import { Drawer, Layout, Table, Form } from 'antd';

const { Item } = Form;

export const TermsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PageWrapper = styled.div`
  width: calc(70vw);
  display: flex;
  flex-direction: column;
  min-height: calc(297mm * 0.8);
  padding: 40px;
  border: 1px solid lightgray;
  background: rgb(255 255 255);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%),
    0 2px 4px 0 rgb(0 0 0 / 2%);

  && {
    .ant-form-item-hidden {
      display: none !important;
    }
    .ant-form-item {
      display: grid;
    }

    .ant-form-item-control {
      width: 100%;
      max-width: 100% !important;
    }
    .ant-picker {
      width: 100%;
    }
    .ant-form-item-label {
      max-width: 100% !important;
    }

    .ant-input-affix-wrapper-disabled {
      background: #fff;
    }
  }
`;

export const PageViewDrawer = styled(Drawer)`
  min-width: 92px !important;
  position: absolute;

  & {
    .ant-layout-sider-trigger {
      max-width: 80px !important;
    }
  }
`;

export const ViewLayout = styled(Layout)`
  display: flex;
  position: relative;
  padding: 20px;
`;

export const RightColumn = styled.div`
  && {
    .ant-form-item-hidden {
      display: none !important;
    }
    .ant-form-item {
      display: grid;
    }
  }

  && {
    .ant-form-item-control {
      width: 100%;
      max-width: 100% !important;
    }
    .ant-picker {
      width: 100%;
    }
    .ant-form-item-label {
      max-width: 100% !important;
    }
  }
`;

export const FlexJustifyEnd = styled.div`
  display: flex;
  justify-content: end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SaveButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 8px;
`;

export const GridSection = styled.section`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 10px;

  @media (max-width: 1600px) {
    && {
      .ant-form-item-control {
        min-width: 100%;
      }

      .ant-form-item-label {
        min-width: fit-content;
      }
    }
  }

  && {
    .ant-form-item-hidden {
      display: none !important;
    }
    .ant-form-item {
      display: grid;
    }
    .ant-form-item-control {
      width: 100%;
      max-width: 100% !important;
    }
    .ant-picker {
      width: 100%;
    }
    .ant-form-item-label {
      max-width: 100% !important;
    }
  }
`;

export const PurchaseCreatorSettingsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin: 20px;
  z-index: 9999;
`;

export const PDFPreviewTrigger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  z-index: 999;
`;

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns:
    clamp(110px, 20%, 230px) clamp(110px, 20%, 230px) clamp(110px, 20%, 230px)
    clamp(110px, 20%, 230px);
  column-gap: clamp(5px, 8%, 120px);
  font-size: 11px;

  > * {
    word-break: break-word;
  }
`;

export const Hoverable = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px transparent;
  transition: all 0.3s linear;

  &:hover {
    cursor: pointer;
    border: solid 1px #0e7d40;
  }
`;

export const ProductTable = styled(Table)`
  max-width: 100%;
  overflow: auto;
  && {
    .ant-table {
      font-size: 12px !important;
    }
    td {
      text-align: center;
      padding: 8px 8px;
    }
    .ant-table-thead > tr > th {
      padding: 8px 8px;
    }
  }
`;

export const SummaryContainer = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SummaryRowFinal = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fafafa;
`;

export const SummaryRowCell = styled.div`
  color: #262626;
  font-weight: 500;
  padding: 8px 8px;
  line-height: 32px;
`;

export const SummaryRowCellFinal = styled.div`
  color: #262626;
  font-weight: 700;
  padding: 8px 8px;
  line-height: 32px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex: 1 1;
  max-width: 50%;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
`;

export const PriceContainer = styled.div`
  position: relative;

  & {
    .ant-tag-warning {
      top: -10px !important;
    }
  }
`;

export const ButtonContainerModal = styled.div`
  display: flex;
  justify-content: end;
`;

export const TopContainerPushTo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

export const PushToInnerContainer = styled.div`
  input[disabled] {
     {
      cursor: default !important;
    }
  }
`;

export const DescriptionContainer = styled.div`
  position: relative;

  & {
    .ant-tag-warning {
      top: -10px !important;
    }
  }
`;

export const ContactLabel = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
`;

export const InvoiceDataContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

export const ConnectionAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GridFormItem = styled(Item)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  & {
    .ant-form-item-control {
      min-width: 100%;
    }
  }
`;

export const InvoiceStatus = styled.div`
  display: flex;
`;
