import { lazy } from 'react';
import { Icon } from '@todix/ui-components';

const DocumentTypes = lazy(
  () => import(/* webpackChunkName: "documentsApp" */ './DocumentTypes'),
);

const InboxesManagement = lazy(
  () => import(/* webpackChunkName: "documentsApp" */ './inbox'),
);

export default {
  appSubmenuName: 'app.documents.mainMenu.name',
  icon: Icon.FileOutlined,
  sections: [
    {
      link: 'inboxes',
      title: 'settings.documents.inboxesmanagement.title',
      component: InboxesManagement,
    },
    {
      link: 'document_types',
      title: 'settings.documents.documentTypes.title',
      component: DocumentTypes,
    },
  ],
};
