import { FieldType, LayoutField } from '@apps/documents/service/namespace';
import {
  CommonFieldsLayout,
  SideViewCommonFieldsLayout,
} from '@apps/documents/service/commonFields.layouts';
import { LayoutDestination } from '@apps/documents/service/layouts';

export const ProcessingLetterLayout: LayoutField[] = [
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.processinginvoice.referencenumber.label',
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
    rules: [
      { required: true, message: 'documents.commonfields.issuingDate.error' },
    ],
    noFutureDate: true,
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    label: 'documents.processinginvoice.receivingdate.label',
    type: 'date',
    placeholder: 'documents.processinginvoice.receivingdate.placeholder',
    rules: [
      {
        required: true,
        message: 'documents.processinginvoice.receivingDate.error',
      },
    ],
    noFutureDate: true,
  },
  {
    fieldName: 'memo',
    fieldType: 'input',
    label: 'documents.letter.memo.label',
  },
];

export const ProcessingSummaryLetterLayout: LayoutField[] =
  ProcessingLetterLayout.map((field) => ({
    ...field,
    fieldType: ['datepicker'].includes(field.fieldType)
      ? (field.fieldType as FieldType)
      : 'input',
    placeholder: '',
    style: {
      border: 0,
    },
  }));

export const LetterLayout: LayoutField[] = [
  ...CommonFieldsLayout,
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.invoice.referencenumber.label',
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'datepicker',
    readOnly: true,
    label: 'documents.invoice.receivingdate.label',
    type: 'date',
    placeholder: 'documents.invoice.receivingdate.placeholder',
  },
  {
    fieldName: 'memo',
    fieldType: 'input',
    label: 'documents.letter.memo.label',
  },
];

export const SideViewLetterLayout: LayoutField[] = [
  ...SideViewCommonFieldsLayout,
  {
    fieldName: 'referenceNumber',
    fieldType: 'input',
    label: 'documents.invoice.sideview.referencenumber.label',
    readOnly: true,
  },
  {
    fieldName: 'issuingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.commonfields.issuingDate.label',
    type: 'date',
    placeholder: 'documents.commonfields.dateField.placeholder',
  },
  {
    fieldName: 'receivingDate',
    fieldType: 'input',
    readOnly: true,
    label: 'documents.invoice.sideview.receivingdate.label',
    type: 'date',
  },
  {
    fieldName: 'memo',
    fieldType: 'input',
    label: 'documents.letter.memo.label',
    readOnly: true,
  },
];

const letterLayouts: Record<LayoutDestination, LayoutField[]> = {
  detailedForm: LetterLayout,
  sideView: SideViewLetterLayout,
  processing: ProcessingLetterLayout,
  processingSummary: ProcessingSummaryLetterLayout,
};

export default letterLayouts;
