import { FilterCityObject } from '@services/filtering/index';
import get from 'lodash.get';
import { AddressEntry } from '@apps/contacts/services';

const cityMatcher = (filterObject: FilterCityObject, row: any, key: string) => {
  if (filterObject.type === 'contains') {
    const addresses = get(row, key) as AddressEntry[];
    return addresses
      ? addresses.filter((address) =>
          address.city
            .toLowerCase()
            .includes(filterObject?.filter.toLowerCase() || ''),
        ).length > 0
      : false;
  }

  return false;
};

export default cityMatcher;
