import React, { FC } from 'react';
import { Form, Input } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Item } = Form;

type EANCodeProps = {
  readOnly: boolean;
  namePrefix?: string | number;
};

const EANCode: FC<EANCodeProps> = ({ namePrefix, readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name={namePrefix !== undefined ? [namePrefix, `eanCode`] : `eanCode`}
      label={
        <FormattedMessage
          id="products.form.eancode.label"
          defaultMessage="EAN Code"
        />
      }
      rules={[
        {
          type: 'string',
          max: 40,
          message: (
            <FormattedMessage
              id="products.form.eancode.error"
              defaultMessage="EAN Code cannot be longer than 40 characters"
            />
          ),
        },
      ]}
    >
      <Input
        placeholder={intl.formatMessage({
          id: 'products.form.eancode.placeholder',
          defaultMessage: 'EAN Code...',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default EANCode;
