import { styled } from '@styles/themes';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.scrollbar.track};
  line-height: normal;
`;

export const SmallWrapper = styled.div`
  line-height: normal;
`;

export const MoreIndicator = styled.div<{ small?: boolean }>`
  width: ${({ small }) => (small ? 24 : 32)}px;
  height: ${({ small }) => (small ? 24 : 32)}px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.scrollbar.track};
  padding: ${({ small }) => (small ? 2 : 5)}px;
  margin-left: -5px;
  color: ${({ theme }) => theme.colors.background.light};
  background-color: ${({ theme }) => theme.colors.text.light};
  z-index: 1;
  font-size: ${({ small }) => (small ? 12 : 14)}px;
`;

export const IconsWrapper = styled.div<{
  isActive: boolean;
  small?: boolean;
}>`
  display: flex;
  border-radius: 50px;
  align-items: center;
  border: 1px solid
    ${(props) =>
      props.isActive
        ? props.theme.colors.main.primary
        : props.theme.colors.scrollbar.track};
  min-width: ${({ small }) => (small ? 32 : 40)}px;
  padding: 5px;

  :hover {
    cursor: pointer;
  }

  img {
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.background.light};
  }
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
`;
