import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';
import AppView from '@components/AppView';
import appConfig, { PURCHASES_ACTION } from '@apps/purchases';
import { IGetRowsParams } from 'ag-grid-community';
import { FilterTextObject } from '@services/filtering';
import {
  getIndividualPurchaseRequests,
  updateIndividualPurchaseRequest,
} from '@apps/purchases/services/individualPurchaseRequests';
import columns from './columns';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import optionsMapper from '@core/helpers/optionsMapper';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import { message } from 'antd';
import { emitCustomEvent } from '@core/services';
import { ListEvents } from '@components/List';

const IndividualRequestsList: FC = () => {
  const { path } = useRouteMatch();
  const intl = useIntl();
  const { data: user, isLoading } = useSelector(getUser);
  const getRows = (
    params: IGetRowsParams & { ecosystems?: string[]; query?: string },
  ) => {
    const filtersEntries = Object.entries(params.filterModel).map(
      ([key, filterObj]) => [
        key,
        // @ts-ignore
        filterObj,
      ],
    );
    if (!params.filterModel.ecosystem && params.ecosystems) {
      filtersEntries.push([
        'ecosystem',
        {
          filter: params.ecosystems.join('|'),
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject,
      ]);
    }
    getIndividualPurchaseRequests(
      {
        query: params.query,
        offset: params.startRow,
        limit: params.endRow - params.startRow,
        fields: [
          'id',
          'productName',
          'ecosystem',
          'needDate',
          'needAmount',
          'description',
          'creator',
          'takeBy',
        ],
        filters: Object.fromEntries(filtersEntries),
        sort: params.sortModel.map(
          (model: { colId: string; sort: string }) =>
            `${model.sort === 'desc' ? '-' : ''}${model.colId}`,
        ),
      },
      'view-purchases-requests',
    )?.then((response) => {
      params.successCallback(response.results, response.info.results);
    });
  };

  return (
    <AppView
      hideQuickFilter
      breadcrumbItems={[
        [
          path,
          intl.formatMessage({
            id: 'app.purchases.createNewLink.new.purchase.request',
            defaultMessage: 'Purchase request',
          }),
        ],
      ]}
      columns={useMemo(() => columns(intl), [intl])}
      getRows={getRows}
      sideContent={() => <div />}
      path={path}
      onCellClickPath={path}
      tabs={['list']}
      options={optionsMapper([], intl)}
      appConfig={appConfig}
      getExtraOptions={(item: IndividualPurchaseRequest) => {
        const ecosystemId = item?.ecosystem || '';
        const canEdit = checkUserPermissions(
          rootStore.getState(),
          appConfig.todixId,
          'update-purchases-requests' as PURCHASES_ACTION,
          ecosystemId,
        );

        return canEdit
          ? [
              {
                children: intl.formatMessage({
                  id: 'take',
                  defaultMessage: 'Take',
                }),
                onClick: async () => {
                  if (!isLoading && item.id && user) {
                    try {
                      await updateIndividualPurchaseRequest(item.id, {
                        ...item,
                        takeBy: user,
                      });
                      emitCustomEvent<ListEvents>('refreshList');
                    } catch (e) {
                      message.error(
                        intl.formatMessage({
                          id: 'error.updating',
                          defaultMessage: 'Error while updating entry',
                        }),
                      );
                    }
                  }
                },
              },
              {
                children: intl.formatMessage({
                  id: 'transfer.to.product',
                  defaultMessage: 'Transfer to Product',
                }),
                disabled: true,
                onClick: () => {
                  emitCustomEvent<ListEvents>('refreshList');
                },
              },
            ]
          : [];
      }}
    />
  );
};

export default IndividualRequestsList;
