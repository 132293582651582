import { User as FirebaseUser } from 'firebase/app';

const getFirebaseUser = (user: FirebaseUser): Partial<User> => ({
  uid: user.uid,
  displayName: user.displayName as string,
  photoURL: user.photoURL as string,
  email: user.email as string,
  emailVerified: user.emailVerified,
  ecosystemsUids: [],
});

export default getFirebaseUser;
