import React, { FC, useCallback, MouseEvent, useState } from 'react';
import { Button } from 'antd';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { Project } from '@apps/projects/services';
import { removeUndefinedProperties } from '@services/utils';
import updateProject from '../services/updateProject';

type FavProjectCellRendererProps = {
  data: Project;
  value?: boolean;
};

const FavProjectCellRenderer: FC<FavProjectCellRendererProps> = ({
  data,
  value = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFav, setIsFav] = useState(value);
  const icon = isFav ? (
    <StarFilled style={{ color: '#F2C94C' }} />
  ) : (
    <StarOutlined />
  );
  const handleRate = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.persist();
      const { id } = data;
      if (id) {
        setIsLoading(true);

        updateProject(id, {
          ...removeUndefinedProperties(data),
          isFav: !isFav,
        }).finally(() => {
          setIsLoading(false);
          setIsFav(!isFav);
        });
      }
    },
    [data, isFav],
  );

  return (
    <Button
      shape="circle"
      icon={icon}
      onClick={handleRate}
      loading={isLoading}
    />
  );
};

export default FavProjectCellRenderer;
