import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@styles/themes';
import { Form, Row, Col, Collapse } from 'antd';
import {
  DeleteOutlined,
  PlusSquareOutlined,
  SubnodeOutlined,
} from '@ant-design/icons';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage, useIntl } from 'react-intl';
import { getCostTypes, LocalisedValue } from '@services/dictionary';
import Summary from '@apps/documents/mainMenu/components/FormFields/CostStructure/Summary';
import SelectWithSearch from '@components/SelectWithSearch';
import InnerInput from '@apps/documents/mainMenu/components/InnerInput';
import {
  getEcosystemTaxCategories,
  TaxCategory,
} from '@apps/finance/services/taxCategories';

const { Panel } = Collapse;

const TwoColumnsCollapse = styled(Collapse)`
  min-width: 100%;
  grid-column-start: span 2;
  margin-bottom: 8px;
`;

const CostStructure: React.FC<FieldProps> = ({ field }) => {
  const intl = useIntl();
  const [costTypes, setCostTypes] = useState<LocalisedValue[]>([]);
  const [taxRates, setTaxRates] = useState<TaxCategory[]>([]);
  const { readOnly, getFieldValue } = useFormContext();
  const { fieldName, readOnly: fieldReadOnly, wrapperStyle } = field;
  const ecosystemId = getFieldValue('ecosystem');
  const addCostRef = useRef<any>(() => {});
  const activePanel = useRef<string[]>([]);

  useEffect(() => {
    if (ecosystemId) {
      Promise.all([getCostTypes(), getEcosystemTaxCategories(ecosystemId)])
        .then((result) => {
          setCostTypes(result[0] as LocalisedValue[]);
          setTaxRates(result[1] as TaxCategory[]);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ecosystemId]);

  return (
    <TwoColumnsCollapse
      onChange={(keys) => {
        activePanel.current = keys as string[];
      }}
      expandIcon={() => (
        <div>
          <SubnodeOutlined />
        </div>
      )}
    >
      <Panel
        header={
          <FormattedMessage
            id="documents.processinginvoice.coststructure.label"
            defaultMessage="Cost structure"
          />
        }
        key="1"
        extra={
          !readOnly && !fieldReadOnly ? (
            <PlusSquareOutlined
              onClick={(event) => {
                if (activePanel.current.length) {
                  event.stopPropagation();
                }
                addCostRef.current();
              }}
            />
          ) : undefined
        }
        forceRender
      >
        <Form.List name={fieldName}>
          {(fields, { add, remove }) => {
            addCostRef.current = () =>
              add({
                costTypeId: costTypes.length ? costTypes[0].id : '',
                taxRateId: taxRates.length ? taxRates[0].id : '',
              });
            return (
              <div style={wrapperStyle}>
                {fields.map(({ key, ...field }) => (
                  <Form.Item key={key} noStyle>
                    <Row style={{ position: 'relative' }} gutter={[1, 1]}>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          wrapperCol={{ span: 24 }}
                          name={[field.name, 'costTypeId']}
                        >
                          <SelectWithSearch
                            disabled={fieldReadOnly ? fieldReadOnly : readOnly}
                            showArrow={!fieldReadOnly || !readOnly}
                            bordered={!fieldReadOnly || !readOnly}
                            options={
                              costTypes.length
                                ? costTypes.map((cost) => ({
                                    value: cost.id,
                                    label: cost[intl.locale],
                                  }))
                                : []
                            }
                            placeholder={
                              <FormattedMessage
                                id="documents.processinginvoice.coststructure.costtype.label"
                                defaultMessage="Cost type"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...field}
                          wrapperCol={{ span: 24 }}
                          name={[field.name, 'taxRateId']}
                        >
                          <SelectWithSearch
                            disabled={fieldReadOnly ? fieldReadOnly : readOnly}
                            showArrow={!fieldReadOnly || !readOnly}
                            bordered={!fieldReadOnly || !readOnly}
                            options={
                              taxRates.length
                                ? taxRates.map((rate, index) => ({
                                    key: index,
                                    value: rate.id,
                                    label: rate.label[intl.locale],
                                  }))
                                : []
                            }
                            placeholder={
                              <FormattedMessage
                                id="documents.processinginvoice.taxrate.label"
                                defaultMessage="Tax rate"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'netAmount']}
                          required
                        >
                          <InnerInput
                            placeholder="Nettobetrag in &euro;"
                            tabIndex={0}
                            readOnly={fieldReadOnly ? fieldReadOnly : readOnly}
                          />
                        </Form.Item>
                      </Col>
                      {!readOnly && !fieldReadOnly && (
                        <DeleteOutlined
                          style={{
                            position: 'absolute',
                            right: '0px',
                          }}
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      )}
                    </Row>
                  </Form.Item>
                ))}
                <Form.Item noStyle shouldUpdate>
                  {() => <Summary taxRates={taxRates} costTypes={costTypes} />}
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
      </Panel>
    </TwoColumnsCollapse>
  );
};

export default CostStructure;
