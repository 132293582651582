import React, { CSSProperties } from 'react';
import { Image, Button, Tooltip } from 'antd';
import { Text } from '@todix/ui-components';
import {
  IconsWrapper,
  MoreIndicator,
  SmallWrapper,
  Title,
  Wrapper,
} from '@components/EcosystemIndicator/EcosystemIndicator.sc';
import {
  DispatchProps,
  StateProps,
} from '@components/EcosystemIndicator/index';
import EcosystemSwitches from '@components/EcosystemSwitches';
import PopoverWithFullScreenMode from '@components/PopoverWithFullScreenMode';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getEcosystemRelations } from '@core/store/ecosystemRelations';

export type OwnProps = {
  onClick?(): void;
  small?: boolean;
  style?: CSSProperties;
};

type Props = StateProps & DispatchProps;

export const EcosystemIndicator: React.FC<Props & OwnProps> = ({
  ecosystems,
  onClick,
  small = false,
  ...rest
}) => {
  const ecosystemsRelations = useSelector(getEcosystemRelations);
  const history = useHistory();
  const [isActive, setIsActive] = React.useState(false);
  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick();
    }
  }, [onClick]);

  const filteredElements: Ecosystem[] = ecosystems.filter((ecosystem) => {
    const relation = ecosystemsRelations.find(
      (relation) => relation.ecosystem === ecosystem.id,
    );
    if (!relation) {
      return false;
    }
    return relation.isActive;
  });

  const elements: Ecosystem[] =
    filteredElements.length > 3
      ? filteredElements.filter((_v: Ecosystem, ind: number) => ind <= 1)
      : filteredElements;
  const more = filteredElements.length - elements.length;

  const title = () => (
    <Title>
      <Text level={3}>
        <FormattedMessage
          id="ecosystemindicator.title"
          defaultMessage="Ecosystems"
        />
      </Text>
      <Button
        onClick={() => {
          history.push('/settings');
          setIsActive(false);
        }}
      >
        <Text type="secondary">
          <FormattedMessage
            id="ecosystemindicator.manage"
            defaultMessage="Manage ecosystems"
          />
        </Text>
      </Button>
    </Title>
  );

  return onClick ? (
    <PopoverWithFullScreenMode
      title={title}
      trigger="click"
      content={() => (
        <EcosystemSwitches
          onClickCreate={() => {
            setIsActive(false);
          }}
        />
      )}
      visible={isActive}
      onVisibleChange={() => setIsActive(!isActive)}
    >
      <Wrapper>
        <IconsWrapper isActive={isActive} onClick={handleClick} small={small}>
          {elements.map((ecosystem, index) => (
            <Tooltip
              key={ecosystem.id}
              placement="bottom"
              title={ecosystem.name}
            >
              <Image
                preview={false}
                src={ecosystem.avatarUrl}
                width={small ? 24 : 34}
                height={small ? 24 : 34}
                style={{ marginLeft: index > 0 ? -5 : 0 }}
              />
            </Tooltip>
          ))}
          {more ? <MoreIndicator small={small}>+{more}</MoreIndicator> : null}
        </IconsWrapper>
      </Wrapper>
    </PopoverWithFullScreenMode>
  ) : (
    <SmallWrapper {...rest}>
      <IconsWrapper isActive={isActive} onClick={handleClick} small={small}>
        {elements.map((ecosystem, index) => (
          <Image
            preview={false}
            key={ecosystem.id}
            src={ecosystem.avatarUrl}
            width={small ? 24 : 32}
            height={small ? 24 : 32}
            style={{ marginLeft: index > 0 ? -5 : 0 }}
          />
        ))}
        {more ? <MoreIndicator small={small}>+{more}</MoreIndicator> : null}
      </IconsWrapper>
    </SmallWrapper>
  );
};
