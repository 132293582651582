import React, { FC, useEffect, useState } from 'react';
import { Result } from 'antd';
import { FormattedMessage } from 'react-intl';
import RelationsFilter from '@components/Relations/RelationsFilter';
import {
  RelationsColumn,
  RelationsContainer,
} from '@components/Relations/Relations.sc';
import CurrentRelations from '@components/Relations/CurrentRelations';
import RelationsSelect from '@components/Relations/RelationsSelect';
import { useAllActivatedFileAppsForEcosystem } from '@core/helpers/apps';
import { EntityRelation } from '@services/entityRelations/namespace';
import getAnyChildrenForParent from '@services/entityRelations/getAnyChildrenForParent';
import getAnyParentsForChild from '@services/entityRelations/getAnyParentsForChild';
import DirectSalesConfig from '@apps/directSales';
import DirectSalesClientConfig from '@apps/directSalesClient';

type RelationsProps = {
  entityId: string;
  ecosystem: string;
  entityType: string;
};

const Relations: FC<RelationsProps> = ({ ecosystem, entityId, entityType }) => {
  const availableApps = useAllActivatedFileAppsForEcosystem(ecosystem);
  const filteredAvailableApps = availableApps.filter(
    (app) =>
      ![DirectSalesConfig.todixId, DirectSalesClientConfig.todixId].includes(
        app.todixId,
      ),
  );
  const [currentRelations, setCurrentRelations] = useState<EntityRelation[]>(
    [],
  );
  const [parentsRelations, setParentsRelations] = useState<EntityRelation[]>(
    [],
  );

  useEffect(() => {
    if (filteredAvailableApps.length > 0) {
      const availableAppsNames = filteredAvailableApps.map((app) => app.name);
      const childrenPromise = getAnyChildrenForParent({
        parentId: entityId,
        ecosystem,
      }).then((entities) => {
        const filteredChildrenRelations = entities.filter((entity) =>
          availableAppsNames.includes(entity.childRefCollection),
        );

        setCurrentRelations(filteredChildrenRelations);
      });

      const parentPromise = getAnyParentsForChild({
        childId: entityId,
        ecosystem,
      }).then((entities) => {
        const filteredParentsRelations = entities.filter((entity) =>
          availableAppsNames.includes(entity.parentRefCollection),
        );

        setParentsRelations(filteredParentsRelations);
      });
      Promise.all([childrenPromise, parentPromise]);
    }
  }, []);

  const [relationFilter, setRelationFilter] = useState('');
  if (!entityId) {
    return (
      <Result
        status="warning"
        title={
          <FormattedMessage
            id="error.missing.entityId"
            defaultMessage="You need to create entity to manage relations"
          />
        }
      />
    );
  }
  if (!ecosystem) {
    return (
      <Result
        status="warning"
        title={
          <FormattedMessage
            id="error.missing.ecosystem"
            defaultMessage="There is no ecosystem selected"
          />
        }
      />
    );
  }

  return (
    <RelationsContainer>
      <RelationsColumn>
        <RelationsFilter
          availableApps={filteredAvailableApps}
          onRelationFilterChange={setRelationFilter}
        />
        <RelationsSelect
          currentRelations={currentRelations}
          parentRelations={parentsRelations}
          setCurrentRelations={setCurrentRelations}
          availableApps={filteredAvailableApps}
          relationFilter={relationFilter}
          ecosystem={ecosystem}
          parentId={entityId}
          parentType={entityType}
        />
      </RelationsColumn>
      <RelationsColumn>
        <CurrentRelations
          currentRelations={currentRelations}
          availableApps={filteredAvailableApps}
          setCurrentRelations={setCurrentRelations}
        />
      </RelationsColumn>
      <RelationsColumn>
        <CurrentRelations
          currentRelations={parentsRelations}
          availableApps={filteredAvailableApps}
          asParentRelations={true}
        />
      </RelationsColumn>
    </RelationsContainer>
  );
};

export default React.memo(Relations);
