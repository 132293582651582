import React, {
  FC,
  useEffect,
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import { FlexSelect } from '@components/Relations/Relations.sc';
import fetchResourcesFromCollection, {
  Resource,
} from '@components/Relations/fetchResourcesFromCollection';
import { FileApp } from '@apps/AppTypes';
import { Select, Space } from 'antd';
import { ApiOutlined } from '@ant-design/icons';
import WidgetResolver from '@components/WidgetResolver';
import createRelation from '@services/entityRelations/createRelation';
import { EntityRelation } from '@services/entityRelations/namespace';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

type RelationsSelectProps = {
  currentRelations: EntityRelation[];
  parentRelations: EntityRelation[];
  availableApps: FileApp[];
  relationFilter: string;
  ecosystem: string;
  parentId: string;
  parentType: string;
  setCurrentRelations: Dispatch<SetStateAction<EntityRelation[]>>;
};

const RelationsSelect: FC<RelationsSelectProps> = ({
  relationFilter,
  availableApps,
  ecosystem,
  parentId,
  parentType,
  currentRelations,
  parentRelations,
  setCurrentRelations,
}) => {
  const [selectedRes, setSelectedRes] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [collection, setCollection] = useState(relationFilter);
  const [resources, setResources] = useState<Resource[]>([]);
  useEffect(() => {
    if (relationFilter !== collection) {
      setCollection(relationFilter);
      setResources([]);
      setSelectedRes('');
      const entityIds = [
        ...currentRelations.map((relation) => relation.childId),
        ...parentRelations.map((relation) => relation.parentId),
      ];
      let resources: Resource[] = [];
      if (parentRelations.length) {
        setIsFetching(true);
        const promises = parentRelations.map((relation) => {
          const { ecosystem, parentRefCollection, parentId } = relation;
          return fetchResourcesFromCollection({
            collection: parentRefCollection,
            ecosystem,
            parentId,
          }).then((results) => {
            resources = [...resources, ...results];
          });
        });
        Promise.all(promises).then(() => {
          fetchResourcesFromCollection({
            collection: relationFilter,
            ecosystem,
            parentId,
          })
            .then((results) => {
              resources = [...resources, ...results];
              setResources(
                resources.filter((res) => !entityIds.includes(res.id)),
              );
            })
            .finally(() => {
              setIsFetching(false);
            });
        });
      } else {
        setIsFetching(true);
        fetchResourcesFromCollection({
          collection: relationFilter,
          ecosystem,
          parentId,
        })
          .then((results) => {
            setResources(results.filter((res) => !entityIds.includes(res.id)));
          })
          .finally(() => {
            setIsFetching(false);
          });
      }
    }
  }, [
    collection,
    currentRelations,
    ecosystem,
    parentId,
    parentRelations,
    relationFilter,
  ]);
  const options = useMemo(() => {
    return resources.map((resource) => {
      const app = availableApps.find(
        (app) => app.collectionName === resource?.collection,
      );
      const Icon = app ? app.mainMenu.icon : ApiOutlined;
      const title = app ? (
        <WidgetResolver
          appName={app.name}
          widgetName="TreeNode"
          injectedProps={{ resource }}
        />
      ) : (
        <span>{resource?.id}</span>
      );

      return (
        <Option value={resource.id} key={resource.id}>
          <Space direction="horizontal" size="small">
            <span role="img">
              <Icon />
            </span>
            {title}
          </Space>
        </Option>
      );
    });
  }, [availableApps, resources]);

  const handleOnchange = useCallback(
    (resId: unknown) => {
      setSelectedRes(resId as string);
      const resource = resources.find((res) => res.id === resId);
      if (resource) {
        const app = availableApps.find(
          (app) => app.collectionName === resource?.collection,
        );
        const parentApp = availableApps.find(
          (app) => app.entityType === parentType,
        );
        if (app && parentApp) {
          const newRelation = {
            ecosystem,
            childId: resource.id,
            childRefCollection: resource.collection,
            childType: app.entityType,
            parentId,
            parentType,
            parentRefCollection: parentApp.collectionName,
          };
          createRelation(newRelation).then((id) => {
            const relation = {
              id,
              ...newRelation,
            };
            setCurrentRelations([...currentRelations, relation]);
            setResources(resources.filter((res) => res.id !== resId));
          });
        }
      }
    },
    [
      availableApps,
      currentRelations,
      ecosystem,
      parentId,
      parentType,
      resources,
      setCurrentRelations,
    ],
  );

  return (
    <FlexSelect
      disabled={!relationFilter || isFetching}
      value={selectedRes}
      onChange={handleOnchange}
      placeholder={
        <FormattedMessage
          id="relations.relations.select.placeholder"
          defaultMessage="Select resource"
        />
      }
    >
      {options}
    </FlexSelect>
  );
};

export default RelationsSelect;
