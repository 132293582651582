import { useContext } from 'react';
import { FormContext, FormContextType } from './context';

export function useFormContext(): FormContextType {
  const form = useContext(FormContext);
  if (!form) {
    throw new Error('Missing FormContextProvider in its parent.');
  }
  return form;
}
