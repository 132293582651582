import React, { useEffect, useState } from 'react';
import { Avatar, Form, Input, Space } from 'antd';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { fetchUser } from '@services/users';
import { FormattedMessage, useIntl } from 'react-intl/lib';

const UserField: React.FC<FieldProps> = ({
  field,
  children,
  initialValue,
  ...rest
}) => {
  const intl = useIntl();
  const [user, setUser] = useState<User | null>(null);
  const { getFieldValue } = useFormContext();
  const { fieldName, label, className, style, placeholder } = field;
  const id = getFieldValue(fieldName) || initialValue;

  useEffect(() => {
    if (!id) {
      return;
    }
    if (id.photoURL) {
      setUser(id);
      return;
    }
    fetchUser(id).then((user: User) => {
      setUser(user);
    });
  }, [id]);

  if (!user) {
    return null;
  }

  return (
    <Form.Item
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <Space direction="horizontal" className={className} style={style}>
        <Avatar shape="circle" src={user.photoURL} />
        <Input
          placeholder={
            placeholder
              ? intl.formatMessage({
                  id: placeholder,
                  defaultMessage: placeholder,
                })
              : placeholder
          }
          readOnly={true}
          value={user.displayName || ''}
          style={{ border: 0 }}
        />
      </Space>
    </Form.Item>
  );
};

export default UserField;
