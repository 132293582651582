import currency from 'currency.js';
import currencySigns from './currencySigns';

const getValueWithCurrency = (value: number | string, currencyCode: string) =>
  currency(value, {
    symbol: currencySigns[currencyCode] || currencySigns['EUR'],
    separator: '.',
    decimal: ',',
    precision: 2,
    pattern: '!#',
  });

export default getValueWithCurrency;
