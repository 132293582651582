import {
  LicencesAction,
  LicencesActionTypes,
} from '@core/store/licences/actions';
import filteredByPredicate from '@core/helpers/filteredByPredicate';

export type LicencesState = {
  data: Record<string, Licence>;
  isLoading: boolean;
};

export const initial: LicencesState = {
  data: {},
  isLoading: false,
};

export const licencesReducer = (state = initial, action: LicencesAction) => {
  switch (action.type) {
    case LicencesActionTypes.UPDATE_LICENCE: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    case LicencesActionTypes.DELETE_LICENCE: {
      const licenceIds = Object.keys(action.payload);
      return {
        ...state,
        data: filteredByPredicate(state.data, (licenceArray: any[], _index) => {
          const licenceId = licenceArray[0];
          return !licenceIds.includes(licenceId);
        }),
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
