import {
  ProductGroupActions,
  ProductGroupsActionsTypes,
} from '@core/store/productGroups/actions';
import { ProductGroup } from '@services/productGroups/namespace';

export type ProductGroupState = ProductGroup[];

const initial: ProductGroupState = [];

export const productGroupsReducer = (
  state = initial,
  action: ProductGroupActions,
) => {
  switch (action.type) {
    case ProductGroupsActionsTypes.SET_PRODUCT_GROUPS: {
      return [...action.payload];
    }
    case ProductGroupsActionsTypes.SET_ECOSYSTEM_PRODUCT_GROUP: {
      if (action.payload.length === 0) {
        return [...state];
      }

      const ecosystemId = action.payload[0].ecosystemId;

      // remove all ecosystemId instances
      state = state.filter(
        (productGroups) => productGroups.ecosystemId !== ecosystemId,
      );

      // combine with new one
      return [...state, ...action.payload];
    }
    default: {
      return state;
    }
  }
};
