import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'antd';
import WidgetResolver from '@components/WidgetResolver';

const { Item } = Form;

type FollowersProps = {
  readOnly: boolean;
};

const Followers: FC<FollowersProps> = ({ readOnly }) => {
  return (
    <Item shouldUpdate noStyle>
      {({ getFieldValue, getFieldsValue, setFieldsValue }) => {
        return (
          <Item
            name="followers"
            label={
              <FormattedMessage
                id="tasks.followers.label"
                defaultMessage="Followers"
              />
            }
          >
            <WidgetResolver
              appName="projects"
              widgetName="MemberSelector"
              injectedProps={{
                placeholder: (
                  <FormattedMessage
                    id="tasks.follower.addNew.button"
                    defaultMessage="Add new follower"
                  />
                ),
                ecosystem: getFieldValue('ecosystem'),
                onChange: (followers: string[]) =>
                  setFieldsValue({
                    ...getFieldsValue(),
                    followers,
                  }),
                value: getFieldValue('followers'),
                mode: 'multiple',
                readOnly,
              }}
            />
          </Item>
        );
      }}
    </Item>
  );
};

export default Followers;
