export const NEWS_COLLECTION_NAME = 'news';

export type NewFeed = {
  ecosystem: string;
  id?: string;
  isActive: boolean;
  newsHeader: string;
  newsContent: string;
  newsDate: string;
  newsPhotoUrl: string;
};
