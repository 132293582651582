import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import {
  ENTITY_RELATIONS_COLLECTION,
  EntityRelation,
} from '@services/entityRelations/namespace';

type GetParentsForChildPayload = {
  childId: string;
  parentType: string;
  ecosystem: string;
};

const getParentsForChild = ({
  childId,
  parentType,
  ecosystem,
}: GetParentsForChildPayload) => {
  return firebase.firestore
    ?.collection(ENTITY_RELATIONS_COLLECTION)
    .where('childId', '==', childId)
    .where('parentType', '==', parentType)
    .where('ecosystem', '==', ecosystem)
    .get()
    .then(getArrayWithIds) as Promise<EntityRelation[]>;
};

export default getParentsForChild;
