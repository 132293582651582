import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  Image as PDFImage,
} from '@react-pdf/renderer';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { styles } from '@apps/sales/mainMenu/components/pdfTemplates/styleSheet';

const EmptyPage = (
  <Document>
    <Page size="A4" style={styles.page} />
  </Document>
);

export const QuotationTemplate = (
  formValues: any,
  ecosystem: Ecosystem,
  user: any,
  _rangeOfNumbers: any,
  docName: string,
  avatarBase64: string | null,
) => {
  const intl = useIntl();
  if (!formValues) {
    return EmptyPage;
  }

  if (!ecosystem) {
    return EmptyPage;
  }

  const contactAddress =
    formValues?.contactSnapshot?.addresses?.[
      formValues?.contactSnapshot?.standardInvoiceAddress
    ];

  let tableProducts = [{ tableType: 'header' }, ...formValues.products].reduce(
    (acc, product, index) => {
      if (index === 0) {
        acc.push({ tableType: 'header' });
      } else {
        acc.push({ ...product, tableType: 'product', productNo: index });
        if (product.description || product.deliveryDate) {
          let height = 0;
          if (product.description) {
            height += 24;
          }
          if (product.deliveryDate) {
            height += 12;
          }
          acc.push({
            tableType: 'description',
            description: product.description,
            deliveryDate: product.deliveryDate,
            styles: {
              height,
            },
          });
        }
      }
      return acc;
    },
    [],
  );

  const netPrice = formValues.products.reduce((acc: number, product: any) => {
    acc += product.amount * product.price;
    return acc;
  }, 0);

  const discountedNetPrice = formValues.products.reduce(
    (acc: number, product: any) => {
      const calcPrice =
        Number(product.price) - (product.discount / 100) * product.price;
      acc += isNaN(calcPrice)
        ? Number(product.price) * product.amount
        : Number(calcPrice) * product.amount;
      return acc;
    },
    0,
  );

  const totalPrice = formValues.products.reduce((acc: number, product: any) => {
    const discountedPrice =
      Number(product.price) - (product.discount / 100) * product.price;
    const taxedPrice = discountedPrice * ((100 + Number(product.tax)) / 100);
    acc += isNaN(taxedPrice)
      ? Number(product.price) * product.amount
      : Number(taxedPrice) * product.amount;
    return acc;
  }, 0);

  const discount = (100 - (discountedNetPrice * 100) / netPrice).toFixed(2);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {ecosystem.address ? (
            <Text
              style={styles.companyAddress}
            >{`${ecosystem.address.street}, ${ecosystem.address.number}, ${ecosystem.address.city}, ${ecosystem.address.zipcode}, ${ecosystem.address.country}`}</Text>
          ) : (
            // for spacing reasons
            <Text> </Text>
          )}
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {avatarBase64 && (
              <PDFImage src={avatarBase64} style={{ width: 24, height: 24 }} />
            )}
            <Text style={{ marginLeft: 8, fontSize: '11px' }}>
              {ecosystem.name}
            </Text>
          </View>
        </View>
        <View style={styles.secondRow}>
          <View style={styles.contactDetails}>
            <Text>{formValues.contactSnapshot?.companyName}</Text>
            <Text>
              {contactAddress?.street} {contactAddress?.number}
            </Text>
            <Text>
              {contactAddress?.city} {contactAddress?.zipcode}
            </Text>
            <Text>{contactAddress?.country}</Text>
          </View>
          <View>
            <View style={styles.docDataRow}>
              <Text style={styles.docDataLabel}>DOC DATE</Text>
              <Text style={styles.docDataValue}>
                {moment().format('YYYY-MM-DD')}
              </Text>
            </View>
            <View style={styles.docDataRow}>
              <Text style={styles.docDataLabel}>DATE OF DELIVERY</Text>
              <Text style={styles.docDataValue}>
                {formValues.deliveryDate?.format('YYYY-MM-DD')}
              </Text>
            </View>
            <View style={styles.docDataRow}>
              <Text style={styles.docDataLabel}>CREATOR</Text>
              <Text style={styles.docDataValue}>{user.displayName}</Text>
            </View>
            <View style={styles.docDataRow}>
              <Text style={styles.docDataLabel}>YOUR REFERENCE</Text>
              <Text style={styles.docDataValue}>{formValues.reference}</Text>
            </View>
          </View>
        </View>
        <View style={styles.productInfoWrapper}>
          <Text>
            {intl.formatMessage({
              id: `settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${formValues.type}`,
              defaultMessage: `settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${formValues.type}`,
            })}{' '}
            #{docName}
          </Text>
          <Text style={styles.salesDescription}>
            {formValues.headerDescription}
          </Text>
        </View>
        <View style={styles.productTable}>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View style={{ ...product.styles }}>
                    {product.description && (
                      <View style={styles.description}>
                        <Text>{product.description}</Text>
                      </View>
                    )}
                    {product.deliveryDate && (
                      <View style={styles.deliveryDateWrapper}>
                        <Text>
                          Delivery date:{' '}
                          {product.deliveryDate?.format('YYYY-MM-DD')}
                        </Text>
                      </View>
                    )}
                  </View>
                );
              }
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>No</Text>
                </View>
              ) : (
                <View style={styles.productRow}>
                  <Text>{product.productNo}</Text>
                </View>
              );
            })}
          </View>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View
                    style={{ ...styles.productRow, ...product.styles }}
                  ></View>
                );
              }
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>Name &</Text>
                  <Text>Number</Text>
                </View>
              ) : (
                <View style={{ ...styles.productRow, fontSize: 7 }}>
                  <Text>{product.productName}</Text>
                  <Text>{product.productNumber}</Text>
                </View>
              );
            })}
          </View>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View
                    style={{ ...styles.productRow, ...product.styles }}
                  ></View>
                );
              }
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>Quantity</Text>
                </View>
              ) : (
                <View style={styles.productRow}>
                  <Text>{product.amount}</Text>
                </View>
              );
            })}
          </View>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View
                    style={{ ...styles.productRow, ...product.styles }}
                  ></View>
                );
              }
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>Net price</Text>
                </View>
              ) : (
                <View style={styles.productRow}>
                  <Text>
                    {Number(product.price).toFixed(2)} {ecosystem.currency}
                  </Text>
                </View>
              );
            })}
          </View>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View
                    style={{ ...styles.productRow, ...product.styles }}
                  ></View>
                );
              }
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>Tax</Text>
                </View>
              ) : (
                <View style={styles.productRow}>
                  <Text>{product.tax}</Text>
                </View>
              );
            })}
          </View>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View
                    style={{ ...styles.productRow, ...product.styles }}
                  ></View>
                );
              }
              const sum =
                (Number(product.price) * (100 + Number(product.tax))) / 100;
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>Gross price</Text>
                </View>
              ) : (
                <View style={styles.productRow}>
                  <Text>
                    {sum ? sum.toFixed(2) : '-'} {ecosystem.currency}
                  </Text>
                </View>
              );
            })}
          </View>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View
                    style={{ ...styles.productRow, ...product.styles }}
                  ></View>
                );
              }
              const sum = Number(product.price) * Number(product.amount);
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>Sum net</Text>
                </View>
              ) : (
                <View style={styles.productRow}>
                  <Text>
                    {sum ? sum.toFixed(2) : '-'} {ecosystem.currency}
                  </Text>
                </View>
              );
            })}
          </View>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View
                    style={{ ...styles.productRow, ...product.styles }}
                  ></View>
                );
              }
              const sum =
                (Number(product.price) *
                  Number(product.amount) *
                  (100 + Number(product.tax))) /
                100;
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>Sum gross</Text>
                </View>
              ) : (
                <View style={styles.productRow}>
                  <Text>
                    {sum ? sum.toFixed(2) : '-'} {ecosystem.currency}
                  </Text>
                </View>
              );
            })}
          </View>
          <View style={styles.productCol}>
            {tableProducts.map((product: any) => {
              if (product.tableType === 'description') {
                return (
                  <View
                    style={{ ...styles.productRow, ...product.styles }}
                  ></View>
                );
              }
              return product.tableType === 'header' ? (
                <View style={styles.productHeaderRow} fixed>
                  <Text>Discount</Text>
                </View>
              ) : (
                <View style={styles.productRow}>
                  <Text>{product.discount}</Text>
                </View>
              );
            })}
          </View>
        </View>
        <View>
          <View style={styles.productsSummaryRow}>
            <Text>Net price sum</Text>
            <Text style={styles.priceText}>
              {netPrice} {ecosystem.currency}
            </Text>
          </View>
          <View style={styles.productsSummaryRow}>
            <Text>Discounted net price</Text>
            <Text style={styles.priceText}>
              {discountedNetPrice} {ecosystem.currency}
            </Text>
          </View>
          <View style={styles.productsSummaryRow}>
            <Text>Discount</Text>
            <Text style={styles.priceText}>
              {Number(discount) ? discount : 0} %
            </Text>
          </View>
          <View style={styles.productsSummaryRow}>
            <Text>Discount sum</Text>
            <Text style={styles.priceText}>
              {netPrice - discountedNetPrice} {ecosystem.currency}
            </Text>
          </View>
        </View>
        <View style={styles.totalWrapper}>
          <View>
            <Text>Total</Text>
          </View>
          <View>
            <Text>
              {totalPrice.toFixed(2)} {ecosystem.currency}
            </Text>
          </View>
        </View>
        <View style={styles.deliveryDetailsWrapper}>
          <View style={styles.deliveryDetailsCol}>
            <Text>Delivery terms </Text>
            <Text style={styles.deliveryDetail}>{formValues.delivery}</Text>
          </View>
          <View style={styles.deliveryDetailsCol}>
            <Text>Payment terms </Text>
            <Text style={styles.deliveryDetail}>{formValues.paymentTerms}</Text>
          </View>
        </View>
        <Text style={styles.salesDescription}>
          {formValues.footerDescription}
        </Text>
        <View style={styles.footer} fixed>
          <View style={styles.companyDetails}>
            <View>
              <Text>
                {ecosystem.name} {ecosystem.companyType}
              </Text>
              {ecosystem.address && (
                <>
                  <Text>{`${ecosystem.address.street}, ${ecosystem.address.number}, ${ecosystem.address.city}`}</Text>
                  <Text>{`${ecosystem.address.zipcode}, ${ecosystem.address.country}`}</Text>
                </>
              )}
            </View>
            <View>
              {ecosystem.phone && <Text>{ecosystem.phone.number}</Text>}
              <Text>{ecosystem.email}</Text>
            </View>
            <View>
              {ecosystem.bankAccount && (
                <>
                  <Text>{ecosystem.bankAccount.bankName}</Text>
                  <Text>{ecosystem.bankAccount.iban}</Text>
                  <Text>Swift: {ecosystem.bankAccount.swift}</Text>
                </>
              )}
            </View>
            <View>
              <Text>{ecosystem.registerCourt}</Text>
              <Text>{ecosystem.crn}</Text>
              <Text>{ecosystem.taxNumber}</Text>
              {formValues.vatStatus === 'vat' ? (
                <Text>{formValues.vatNumber}</Text>
              ) : (
                <Text>No VAT</Text>
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
