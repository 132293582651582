import React from 'react';

type Params = {
  callback(): void;
  capture?: boolean;
};

const useOutsideClick = ({ callback, capture = false }: Params) => {
  const ref = React.useRef<any>();

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('click', handleClick, capture);

    return () => {
      document.removeEventListener('click', handleClick, capture);
    };
  }, [callback, ref, capture]);

  return ref;
};

export default useOutsideClick;
