import React, { FC } from 'react';
import { Form, Row, Select, Col, Button, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Email } from '@apps/contacts/services';
import { FormattedMessage, useIntl } from 'react-intl';
import { getEmailsLabels } from '@services/dictionary';

type SelectWrappedProps = {
  value?: Email[];
  onChange?: (value?: Email[]) => void;
};

const SelectWrapped: FC<SelectWrappedProps> = ({
  value = [],
  onChange,
  ...props
}) => {
  const intl = useIntl();
  const emailsOptions = getEmailsLabels(intl);
  const options = value.map(({ label, email }) => ({
    label: `${email} - ${label}`,
    value: email,
  }));
  return (
    <Form.List name="emails">
      {(_fields, { add }) => {
        return (
          <>
            <Row gutter={16}>
              <Col flex="auto">
                <Select
                  options={options}
                  value={options[0]?.value}
                  onChange={() => {
                    if (onChange) {
                      onChange(value);
                    }
                  }}
                  {...props}
                />
              </Col>
              <Col flex="32px">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    add({
                      label: emailsOptions[0].value,
                      email: '',
                      standard: true,
                    })
                  }
                />
              </Col>
            </Row>
          </>
        );
      }}
    </Form.List>
  );
};

const EmailPick = () => {
  const intl = useIntl();
  const emailsOptions = getEmailsLabels(intl);

  return (
    <>
      <Form.Item
        name="emails"
        label={
          <FormattedMessage
            id="documentprocessing.step2.emailpick.label"
            defaultMessage="E-mail"
          />
        }
      >
        <SelectWrapped />
      </Form.Item>
      <Form.List
        name="emails"
        rules={[
          {
            message: intl.formatMessage({
              id: 'documentprocessing.step2.emailpick.error',
              defaultMessage: "E-mail can't be empty",
            }),
            validator: async (rule, value) => {
              if (rule) {
                const result = value.filter((v: Email) => !v.email.length);

                if (result.length) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { remove }, { errors }) => {
          return (
            <>
              {(fields as any).map(({ key, ...field }: any, index: any) => {
                return index === fields.length - 1 ? (
                  <Row style={{ marginBottom: '24px' }} key={key}>
                    <Col flex={1}>
                      <Form.Item
                        {...field}
                        noStyle
                        name={[field.name, 'label']}
                        fieldKey={[field.fieldKey, 'label']}
                      >
                        <Select
                          placeholder={intl.formatMessage({
                            id: 'detailcontactform.generalinformation.email.firstplaceholder',
                            defaultMessage: 'Email label',
                          })}
                          options={emailsOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item
                        noStyle
                        name={[field.name, 'email']}
                        fieldKey={[field.fieldKey, 'email']}
                        rules={[
                          {
                            type: 'email',
                            required: true,
                            message: intl.formatMessage({
                              id: 'detailcontactform.generalinformation.email.error',
                              defaultMessage: 'Must be valid email address',
                            }),
                          },
                        ]}
                      >
                        <Input
                          autoComplete="nope"
                          placeholder={intl.formatMessage({
                            id: 'detailcontactform.generalinformation.email.secondplaceholder',
                            defaultMessage: 'E-mail address',
                          })}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <MinusCircleOutlined
                        style={{
                          position: 'absolute',
                          right: '0px',
                          top: '-20px',
                        }}
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </Col>
                  </Row>
                ) : null;
              })}
              <Form.ErrorList errors={errors} />
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default EmailPick;
