import React, { FC, useCallback, useEffect, useState } from 'react';
import ExistingContact from '@apps/documents/mainMenu/DocumentProcessing/Step2/ExistingContact';
import { FormattedMessage, useIntl } from 'react-intl';
import NewContact from '@apps/documents/mainMenu/DocumentProcessing/Step2/NewContact';
import Modal from '@components/Modal';
import { Button, Collapse } from 'antd';
import { Contact, getContact } from '@apps/contacts/services';
import { useSelector } from 'react-redux';
import { getEcosystemById } from '@components/EcosystemIndicator/store';
import { CloseSquareOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { Form as aForm } from 'antd';
const { Panel } = Collapse;
const { Item } = aForm;

type ContractDetails = {
  defaultContact?: Contact;
  ecosystemId: string;
  defaultContactSnapshot: Contact;
  form: any;
  contactFormName: string;
  contactSnapshotFormName: string;
};

type ExtraButtonProps = {
  onClick: () => void;
};

const handleContactChange = (
  setFunction: (c: Contact) => void,
  contact: Contact,
) => {
  if (setFunction) {
    setFunction(contact);
  }
};

const debouncedHandleContactChange = debounce(handleContactChange, 500);

const DeleteButton: FC<ExtraButtonProps> = ({ onClick }) => (
  <Button
    type="text"
    size="small"
    icon={<CloseSquareOutlined />}
    onClick={onClick}
  />
);

export const ContactDetails: FC<ContractDetails> = ({
  ecosystemId,
  defaultContact,
  defaultContactSnapshot,
  form,
  contactFormName,
  contactSnapshotFormName,
}) => {
  const intl = useIntl();
  const [contact, setContact] = useState<Contact | null>(
    defaultContact || null,
  );
  const [details, setDetails] = useState<Contact | null>(
    defaultContactSnapshot || null,
  );

  const [isExistedContact, setIsExistedContact] = useState(
    !!defaultContactSnapshot,
  );

  const handleDelete = useCallback(() => {
    setContact(null);
    setDetails(null);
    if (form) {
      form.setFields([
        {
          name: contactFormName,
          value: '',
        },
        {
          name: contactSnapshotFormName,
          value: null,
        },
      ]);
    }
  }, [form]);

  const handleContactChange = useCallback((passedContact) => {
    const newContact = {
      ...passedContact,
      foundationDate:
        passedContact.foundationDate && passedContact.foundationDate.format(),
      id: contact?.id ? contact?.id : passedContact.id,
    };

    debouncedHandleContactChange(setContact, newContact);
  }, []);

  useEffect(() => {
    if (defaultContact) {
      if (defaultContact.existed) {
        getContact(defaultContact.id as string).then((r) => {
          setDetails(r as Contact);
          setContact(r as Contact);
        });
      } else {
        setContact(defaultContact);
        setDetails(defaultContact);
        setIsExistedContact(false);
      }
    }
  }, [defaultContact]);

  useEffect(() => {
    setDetails(defaultContactSnapshot);
    setIsExistedContact(!!defaultContactSnapshot);
    form.getFieldsValue()[contactSnapshotFormName] = details;
  }, [defaultContactSnapshot]);

  const handleSelect = (passedContact: Contact, existed: boolean) => {
    setContact(passedContact);
    getContact(passedContact?.id as string).then((details) => {
      setDetails(details);
      setIsExistedContact(existed);
    });

    if (form) {
      form.setFields([
        {
          name: contactFormName,
          value: passedContact.id,
        },
        {
          name: contactSnapshotFormName,
          value: details,
        },
      ]);
    }
  };

  const ecosystem = useSelector(getEcosystemById(ecosystemId)) as Ecosystem;

  return (
    <Collapse>
      <Item name={contactSnapshotFormName} />
      {contact && contact.id && details && isExistedContact && (
        <Panel
          key="edit"
          header={
            details.type === 'person'
              ? `${details.firstName || ''} ${details.lastName || ''}`
              : details.companyName || ''
          }
          extra={<DeleteButton onClick={handleDelete} />}
          collapsible="header"
        >
          <ExistingContact
            details={{
              ...details,
              id: contact.id,
            }}
            onValuesChange={handleContactChange}
            handleCancel={() => {}}
            onFinish={() => {}}
          />
        </Panel>
      )}
      {contact && !isExistedContact && (
        <Panel
          key="create"
          header={
            contact ? (
              contact.type === 'person' ? (
                `${contact.firstName || ''} ${contact.lastName || ''}`
              ) : (
                contact.companyName || ''
              )
            ) : (
              <FormattedMessage
                id="documentprocessing.step2.relatedcontact.header"
                defaultMessage="New contact"
              />
            )
          }
          extra={<DeleteButton onClick={handleDelete} />}
        >
          <NewContact
            details={contact}
            handleCancel={() => {
              Modal.confirm({
                content: intl.formatMessage({
                  id: 'documentprocessing.step2.relatedcontact.confirm',
                  defaultMessage: 'You really want to quit?',
                }),
                onOk: handleDelete,
              });
            }}
            ecosystems={[ecosystem]}
            onValuesChange={handleContactChange}
            onFinish={(id) => {
              setDetails(null);
              handleSelect(
                {
                  id,
                },
                true,
              );
            }}
          />
        </Panel>
      )}
    </Collapse>
  );
};
