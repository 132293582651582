import React, { FC } from 'react';
import { Commission } from '@apps/directSales/services/commisions';
import { currencySigns } from '@services/currency';
import { useIntl } from 'react-intl';

type Props = {
  value: Commission['productBonus'];
};

const ProductBonusRenderer: FC<Props> = ({ value }) => {
  const intl = useIntl();
  if (value) {
    return (
      <div>
        {`${intl.formatMessage(
          {
            id: 'commissions.productBonus.couponPerCommission',
            defaultMessage: 'commissions.productBonus.couponPerCommission',
          },
          {
            coupon: `${value.coupon}${currencySigns['EUR']}`,
            commission: `${value.perEachCommission}${currencySigns['EUR']}`,
          },
        )} ${
          value.maxCoupon
            ? intl.formatMessage(
                {
                  id: 'commissions.max.coupons',
                  defaultMessage: `(Max ${value.maxCoupon})`,
                },
                {
                  maxCoupon: value.maxCoupon,
                },
              )
            : ''
        }`}
      </div>
    );
  }
  return <div />;
};

export default ProductBonusRenderer;
