import React, { FC, useCallback, useEffect, useState } from 'react';
import { AppEntityForm } from '@components/AppEntityForm/AppEntityForm';
import AppConfig, { PROJECTS_ACTION } from '@apps/projects';
import {
  ProjectDetailsFieldContainer,
  StyledSectionTitle,
} from '@apps/projects/mainMenu/ProjectsDetails.sc';
import SelectedEcosystem from '@components/SelectedEcosystem';
import { DatePicker, Form, Input, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ColorPicker from '@components/ColorPicker';
import MemberSelector from '@apps/projects/widgets/MemberSelector';
import getProjectById from '@apps/projects/services/getProjectById';
import moment from 'moment';
import {
  createProject,
  Project,
  updateProject,
  ProjectForm as ProjectFormType,
} from '@apps/projects/services';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import { useHistory } from 'react-router-dom';
import appConfig from '@apps/projects';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';

type ProjectFormProps = {
  id?: string;
  ecosystem: string;
  onEcosystemChange(ecosystem: string): void;
  action: PROJECTS_ACTION;
};

const ProjectForm: FC<ProjectFormProps> = ({
  id,
  ecosystem,
  onEcosystemChange,
  action,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const { data: user } = useSelector(getUser);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const presetColors = ['#cd9323', '#1a53d8', '#9a2151', '#0d6416', '#8d2808'];

  const initialValues: Partial<ProjectFormType> = {
    ecosystem,
    color: presetColors[0],
    startDate: moment(),
    endDate: moment(),
    isFav: false,
  };

  const canProceed = initialValues
    ? checkUserPermissions(
        rootStore.getState(),
        AppConfig.todixId,
        'update-projects' as PROJECTS_ACTION,
        initialValues?.ecosystem as string,
      )
    : false;

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      getProjectById(id)
        .then((project) => {
          onEcosystemChange(project.ecosystem);
          form.setFieldsValue({
            ...form.getFieldsValue(),
            ...project,
            startDate: moment(project.startDate, moment.defaultFormat),
            endDate: moment(project.endDate, moment.defaultFormat),
          } as ProjectFormType);
        })
        .finally(() => setIsLoading(false));
    }
  }, [form, id, onEcosystemChange]);

  const handleOnFinish = useCallback(
    (values: ProjectFormType) => {
      if (canProceed) {
        setIsLoading(true);

        const payload: Project = {
          ...values,
          creator: user?.uid as string,
          startDate: values.startDate.format(),
          endDate: values.endDate.format(),
        };

        if (id) {
          updateProject(id, payload).finally(() => {
            setIsLoading(false);
            history.push(`/app/${appConfig.todixId}`);
          });
        } else {
          createProject(payload).finally(() => {
            setIsLoading(false);
            history.push(`/app/${appConfig.todixId}`);
          });
        }
      }
    },
    [canProceed, history, id, user?.uid],
  );

  return (
    <Spin spinning={isLoading}>
      <AppEntityForm
        providedForm={form}
        initialValues={initialValues}
        appId={AppConfig.todixId}
        onSubmit={handleOnFinish}
        readOnly={!canProceed}
        disabledEditButton={!canProceed}
        disabledSubmitButton={!canProceed}
      >
        {() => {
          return (
            <ProjectDetailsFieldContainer>
              <div>
                <div>
                  <SelectedEcosystem
                    appId={AppConfig.todixId}
                    onChange={(id: string) => {
                      onEcosystemChange(id);
                      form.setFieldsValue({
                        ...form.getFieldsValue(),
                        managerId: undefined,
                      });
                    }}
                    action={action}
                  />
                  <Form.Item
                    label={intl.formatMessage({
                      id: 'app.projects.form.name.label',
                      defaultMessage: 'Name',
                    })}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormattedMessage
                            id="app.projects.form.name.required"
                            defaultMessage="Missing name"
                          />
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={intl.formatMessage({
                        id: 'app.weekplan.form.name.label',
                        defaultMessage: 'Name',
                      })}
                    />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={true}>
                    {({ getFieldValue }) => (
                      <Form.Item
                        label={intl.formatMessage({
                          id: 'app.projects.form.color.label',
                          defaultMessage: 'Color',
                        })}
                        name="color"
                      >
                        <ColorPicker
                          presetColors={presetColors}
                          initialColor={getFieldValue('color')}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    name="startDate"
                    label={
                      <FormattedMessage
                        id="app.projects.form.startDate.label"
                        defaultMessage="Start date"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormattedMessage
                            id="app.projects.form.startDate.required"
                            defaultMessage="Missing start date"
                          />
                        ),
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder={intl.formatMessage({
                        id: 'app.projects.form.date.placeholder',
                        defaultMessage: 'Select date',
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    name="endDate"
                    label={
                      <FormattedMessage
                        id="app.projects.form.endDate.label"
                        defaultMessage="End date"
                      />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormattedMessage
                            id="app.projects.form.endDate.required"
                            defaultMessage="Missing end date"
                          />
                        ),
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder={intl.formatMessage({
                        id: 'app.projects.form.date.placeholder',
                        defaultMessage: 'Select date',
                      })}
                    />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.ecosystem !== curValues.ecosystem
                    }
                  >
                    {() => {
                      const { ecosystem } = form.getFieldsValue();
                      return (
                        <Form.Item
                          name="managerId"
                          label={
                            <FormattedMessage
                              id="app.projects.manager.selector.label"
                              defaultMessage="Project manager"
                            />
                          }
                        >
                          <MemberSelector
                            ecosystem={ecosystem}
                            placeholder={
                              <FormattedMessage
                                id="app.projects.manager.selector.placeholder"
                                defaultMessage="Select manager"
                              />
                            }
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <StyledSectionTitle>
                    <FormattedMessage
                      id="app.projects.members.label"
                      defaultMessage="Project members"
                    />
                  </StyledSectionTitle>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.ecosystem !== curValues.ecosystem
                    }
                  >
                    {() => {
                      const { ecosystem } = form.getFieldsValue();
                      return (
                        <Form.Item name="membersIds">
                          <MemberSelector
                            mode="multiple"
                            ecosystem={ecosystem}
                            placeholder={
                              <FormattedMessage
                                id="app.projects.members.placeholder"
                                defaultMessage="Select members"
                              />
                            }
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
              </div>
              <div>
                <Form.Item name="isFav" hidden />
              </div>
            </ProjectDetailsFieldContainer>
          );
        }}
      </AppEntityForm>
    </Spin>
  );
};

export default ProjectForm;
