import { styled } from '@styles/themes';
// @ts-ignore
import { Text } from '@todix/ui-components';

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled(Text as any)`
  &&& {
    margin-left: 8px;
  }
`;
