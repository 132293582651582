import { UserPreferences } from '@services/userPreferences/namespace';
import {
  UserPreferencesAction,
  UserPreferencesActionTypes,
} from '@core/store/userPreferences/actions';

export type UserPreferencesState = {
  data: UserPreferences;
  isLoading: boolean;
};

export const initial: UserPreferencesState = {
  data: {},
  isLoading: true,
};

export const userPreferencesReducer = (
  state = initial,
  action: UserPreferencesAction,
) => {
  switch (action.type) {
    case UserPreferencesActionTypes.UPDATE_USER_PREFERENCES: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
