import React, { FC } from 'react';
import { Form, Radio } from 'antd';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { RadioGroupLayoutField } from '@apps/documents/service';
import { FormattedMessage } from 'react-intl/lib';

const RadioGroupField: FC<FieldProps> = ({ field, children, ...rest }) => {
  const { readOnly } = useFormContext();
  const {
    fieldName,
    label,
    rules,
    className,
    style,
    readOnly: fieldReadOnly,
    options,
  } = field as RadioGroupLayoutField;

  return (
    <Form.Item
      name={fieldName}
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      rules={rules?.map((r) => {
        r.message =
          r.message && typeof r.message === 'string' ? (
            <FormattedMessage
              id={`${r.message}`}
              defaultMessage={`${r.message}`}
            />
          ) : (
            r.message
          );
        return r;
      })}
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <Radio.Group disabled={fieldReadOnly ? fieldReadOnly : readOnly}>
        {options &&
          options.map((option, index) => (
            <Radio
              key={index}
              className={className}
              style={style}
              value={option.value}
            >
              {label ? (
                <FormattedMessage
                  id={`${option.label}`}
                  defaultMessage={`${option.label}`}
                />
              ) : (
                label
              )}
            </Radio>
          ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioGroupField;
