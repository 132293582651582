import React, { FC, useState, useEffect } from 'react';
import { Modal, Input, Button, Form, Row, Col, Typography } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import getSerialNumber from '@apps/warehouse/services/getSerialNumber';
import getSerialNumbers from '@apps/warehouse/services/getSerialNumbers';
import { FlowOption } from '@apps/warehouse/services/namespace';

const { Text } = Typography;

const SerialNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;

  & {
    .ant-row {
      margin-bottom: 10px !important;
    }
  }
`;

const StyledModal = styled(Modal)`
  top: 24px !important;
  padding-bottom: 0 !important;

  & {
    .ant-modal-body {
      height: 80vh;
      overflow: auto;
    }
  }
`;

const TotalAmount = styled(Text)<{ isComplete: boolean }>`
  font-weight: bold;
  color: ${(props) => (props.isComplete ? '#52c41a' : '#ff4d4f')};
  margin-bottom: 16px;
  display: block;
`;

interface SerialNumbersModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (serialNumbers: string[]) => void;
  productId?: string;
  positionId?: string;
  ecosystem: string;
  quantity: number;
  initialSerialNumbers?: string[];
  flowType?: FlowOption | null;
}

const SerialNumbersModal: FC<SerialNumbersModalProps> = ({
  visible,
  onCancel,
  onSave,
  productId,
  ecosystem,
  quantity,
  positionId,
  initialSerialNumbers,
  flowType,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [serialNumbers, setSerialNumbers] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<number, string>>({});
  const [existingSerialNumbers, setExistingSerialNumbers] = useState<
    Set<string>
  >(new Set());
  const [selectedSerialNumbers, setSelectedSerialNumbers] = useState<
    Set<string>
  >(new Set());

  useEffect(() => {
    if (visible) {
      const emptySerialNumbers = Array(quantity).fill('');
      setLoading(true);
      setSelectedSerialNumbers(new Set());

      if (initialSerialNumbers && initialSerialNumbers.length > 0) {
        const newSerialNumbers = emptySerialNumbers.map((_, index) =>
          index < initialSerialNumbers.length
            ? initialSerialNumbers[index]
            : '',
        );

        setSerialNumbers(newSerialNumbers);
        setExistingSerialNumbers(new Set());
        setLoading(false);
      } else {
        getSerialNumbers({
          ecosystem,
          positionId: flowType === 'manual_take_out' ? undefined : positionId,
          productId,
        })
          .then((entries) => {
            if (entries?.length) {
              const fetchedSerialNumbers = entries.map((el) => el.serialNumber);
              setExistingSerialNumbers(new Set(fetchedSerialNumbers));

              if (flowType === 'manual_take_out') {
                setSerialNumbers(fetchedSerialNumbers);
              } else {
                if (entries.length > quantity) {
                  setSerialNumbers(fetchedSerialNumbers.slice(0, quantity));
                } else {
                  setSerialNumbers(
                    Array(quantity)
                      .fill('')
                      .map((el, index) => {
                        if (fetchedSerialNumbers[index]) {
                          return fetchedSerialNumbers[index];
                        }
                        return el;
                      }),
                  );
                }
              }
            } else {
              setSerialNumbers(emptySerialNumbers);
              setExistingSerialNumbers(new Set());
            }
            setLoading(false);
          })
          .catch(() => {
            setSerialNumbers(emptySerialNumbers);
            setExistingSerialNumbers(new Set());
            setLoading(false);
          });
      }

      setErrors({});
    }
  }, [
    visible,
    quantity,
    ecosystem,
    positionId,
    productId,
    initialSerialNumbers,
    flowType,
  ]);

  const handleSerialNumberChange = (index: number, value: string) => {
    if (existingSerialNumbers.has(serialNumbers[index])) {
      return;
    }

    const newSerialNumbers = [...serialNumbers];
    newSerialNumbers[index] = value;
    setSerialNumbers(newSerialNumbers);

    if (errors[index]) {
      const newErrors = { ...errors };
      delete newErrors[index];
      setErrors(newErrors);
    }

    if (value) {
      const duplicateIndices = newSerialNumbers
        .map((sn, idx) => {
          if (existingSerialNumbers.has(sn)) return -1;
          return sn === value && idx !== index ? idx : -1;
        })
        .filter((idx) => idx !== -1);

      if (duplicateIndices.length > 0) {
        const newErrors = { ...errors };

        newErrors[index] = intl.formatMessage({
          id: 'warehouse.serial.numbers.input.error.duplicate',
          defaultMessage: 'This serial number is already in use',
        });

        setErrors(newErrors);
      }
    }
  };

  const handleSerialNumberSelect = (
    serialNumber: string,
    selected: boolean,
  ) => {
    const newSelectedSerialNumbers = new Set(selectedSerialNumbers);

    if (selected) {
      newSelectedSerialNumbers.add(serialNumber);
    } else {
      newSelectedSerialNumbers.delete(serialNumber);
    }

    setSelectedSerialNumbers(newSelectedSerialNumbers);
  };

  const validateSerialNumbers = async () => {
    setLoading(true);
    const newErrors: Record<number, string> = {};
    let hasErrors = false;

    const newSerialNumbersToValidate = serialNumbers.filter(
      (sn, _index) => sn.trim() !== '' && !existingSerialNumbers.has(sn),
    );

    if (
      newSerialNumbersToValidate.length === 0 &&
      serialNumbers.filter((sn) => sn.trim() !== '').length === 0
    ) {
      setLoading(false);
      return false;
    }

    const seen = new Set<string>();
    const duplicates = new Set<string>();

    serialNumbers.forEach((sn, _index) => {
      if (!sn || existingSerialNumbers.has(sn)) return;

      if (seen.has(sn)) {
        duplicates.add(sn);
      } else {
        seen.add(sn);
      }
    });

    serialNumbers.forEach((sn, index) => {
      if (sn && duplicates.has(sn) && !existingSerialNumbers.has(sn)) {
        newErrors[index] = intl.formatMessage({
          id: 'warehouse.serial.numbers.input.error.duplicate',
          defaultMessage: 'This serial number is already in use',
        });
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setErrors(newErrors);
      setLoading(false);
      return false;
    }

    for (let i = 0; i < serialNumbers.length; i++) {
      const serialNumber = serialNumbers[i];
      if (!serialNumber || existingSerialNumbers.has(serialNumber)) continue;

      try {
        const serialNumberId = `${ecosystem}_${serialNumber}`;
        const existingSerialNumber = await getSerialNumber(serialNumberId);

        if (existingSerialNumber) {
          newErrors[i] = intl.formatMessage({
            id: 'warehouse.serial.numbers.input.error.duplicate',
            defaultMessage: 'This serial number is already in use',
          });
          hasErrors = true;
        }
      } catch (error) {}
    }

    setErrors(newErrors);
    setLoading(false);
    return !hasErrors;
  };

  const handleSave = async () => {
    const isValid = await validateSerialNumbers();

    if (isValid) {
      if (flowType === 'manual_take_out') {
        onSave(Array.from(selectedSerialNumbers));
      } else {
        onSave(serialNumbers.filter((sn) => sn));
      }
    }
  };

  const isComplete =
    flowType === 'manual_take_out'
      ? selectedSerialNumbers.size === quantity
      : serialNumbers.filter((sn) => sn).length === quantity;

  return (
    <StyledModal
      title={intl.formatMessage({
        id: 'warehouse.serial.numbers.modal.title',
        defaultMessage: 'Serial Numbers',
      })}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {intl.formatMessage({
            id: 'button.cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>,
        <Button
          key="save"
          type="primary"
          loading={loading}
          onClick={handleSave}
        >
          {intl.formatMessage({
            id: 'button.save',
            defaultMessage: 'Save',
          })}
        </Button>,
      ]}
    >
      {flowType === 'manual_take_out' && (
        <TotalAmount isComplete={isComplete}>
          {intl.formatMessage(
            {
              id: 'warehouse.serial.numbers.total.amount.remove',
              defaultMessage:
                'Total amount to remove: {total} (Selected: {selected})',
            },
            {
              total: quantity,
              selected: selectedSerialNumbers.size,
            },
          )}
        </TotalAmount>
      )}

      <Form form={form} layout="vertical">
        <SerialNumbersContainer>
          {serialNumbers.map((serialNumber, index) => {
            const isExisting = existingSerialNumbers.has(serialNumber);

            return (
              <Form.Item
                key={index}
                validateStatus={errors[index] ? 'error' : ''}
                help={errors[index]}
              >
                {flowType === 'manual_take_out' && isExisting ? (
                  <Row align="middle">
                    <Col span={20}>
                      <Input
                        value={serialNumber}
                        readOnly
                        disabled
                        style={{ backgroundColor: '#f5f5f5', color: '#666' }}
                      />
                    </Col>
                    <Col span={4} style={{ textAlign: 'center' }}>
                      <input
                        type="checkbox"
                        checked={selectedSerialNumbers.has(serialNumber)}
                        onChange={(e) =>
                          handleSerialNumberSelect(
                            serialNumber,
                            e.target.checked,
                          )
                        }
                      />
                    </Col>
                  </Row>
                ) : (
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'warehouse.serial.numbers.input.placeholder',
                      defaultMessage: 'Enter serial number...',
                    })}
                    value={serialNumber}
                    onChange={(e) =>
                      handleSerialNumberChange(index, e.target.value)
                    }
                    readOnly={isExisting}
                    disabled={isExisting}
                    style={
                      isExisting
                        ? { backgroundColor: '#f5f5f5', color: '#666' }
                        : {}
                    }
                  />
                )}
              </Form.Item>
            );
          })}
        </SerialNumbersContainer>
      </Form>
    </StyledModal>
  );
};

export default SerialNumbersModal;
