export const layout = {
  wrapperCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 12,
    },
    xxl: {
      span: 12,
    },
  },
  labelCol: {
    lg: {
      span: 12,
    },
    xl: {
      span: 8,
    },
    xxl: {
      span: 8,
    },
    offset: 0,
  },
};
