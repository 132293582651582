import { FilterActiveStatusObject } from '@services/filtering/index';
import get from 'lodash.get';

const activeStatusMatcher = (
  filterObject: FilterActiveStatusObject,
  row: any,
  key: string,
) => {
  if (filterObject.type === 'contains') {
    return filterObject.filter !== null
      ? get(row, key) === filterObject.filter
      : true;
  }

  return false;
};

export default activeStatusMatcher;
