import { styled } from '@styles/themes';
import { Table } from 'antd';

export const Hoverable = styled.div`
  min-height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px transparent;
  transition: all 0.3s linear;

  &:hover {
    cursor: pointer;
    border: solid 1px #0e7d40;
  }
`;

export const ProductTable = styled(Table)`
  max-width: 100%;
  overflow: auto;
  && {
    .ant-table {
      font-size: 12px !important;
    }
    td {
      text-align: center;
      padding: 8px 8px;
    }
    .ant-table-thead > tr > th {
      padding: 8px 8px;
    }
  }
`;

export const SummaryContainer = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SummaryRowFinal = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fafafa;
`;

export const SummaryRowCell = styled.div`
  color: #262626;
  font-weight: 500;
  padding: 8px 8px;
  line-height: 32px;
`;

export const SummaryRowCellFinal = styled.div`
  color: #262626;
  font-weight: 700;
  padding: 8px 8px;
  line-height: 32px;
`;
