import SettingsConfig from '@apps/warehouse/settings';
import MainMenuConfig from '@apps/warehouse/mainMenu';
import { FileApp } from '@apps/AppTypes';

export type WAREHOUSE_ACTION =
  | 'view-warehouses'
  | 'create-warehouses'
  | 'update-warehouses'
  | 'delete-warehouses';

export type WAREHOUSE_PERMISSION = 'all' | 'none';

export default {
  todixId: 'warehouse',
  link: '/app/warehouse',
  name: 'warehouse',
  entityType: 'warehouse',
  collectionName: 'warehouse',
  localizationPath: 'warehouse/i18n',
  settings: SettingsConfig,
  mainMenu: MainMenuConfig,
  createNewLinks: [
    {
      link: 'create',
      title: 'app.warehouse',
    },
  ],
  widgets: [
    {
      name: 'AppRolesForm',
      path: 'warehouse/widgets/AppRolesForm',
    },
  ],
  actions: [
    'view-warehouses',
    'create-warehouses',
    'update-warehouses',
    'delete-warehouses',
  ] as WAREHOUSE_ACTION[],
  permissions: ['all', 'none'] as WAREHOUSE_PERMISSION[],
} as FileApp;
