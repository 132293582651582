import React, {
  FC,
  CSSProperties,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { Tooltip, Space, Spin } from 'antd';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { PurchaseOperationStatus } from '../mainMenu/purchaseStatusColors';
import { PositionMonitor } from '@apps/purchases/services/positionMonitor';
import { getDocument } from '@apps/documents/service';
import { Document } from '@apps/documents/service';
import DocumentAppConfig, { DOCUMENTS_ACTION } from '@apps/documents';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import moment from 'moment';
import { emitCustomEvent } from '@core/services';
import {
  DocumentPreviewEventName,
  DocumentPreviewEventPayload,
} from './DocumentPreviewModal';

const StatusIconContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & {
    .material-symbols-outlined {
      font-size: 20px !important;
      color: #ffffff !important;
    }
  }
`;

interface PurchaseStepProps {
  stepStatus: PurchaseOperationStatus;
  stepType: string;
  color: string;
  StepIcon: FC<{ style: CSSProperties }>;
  index: number;
  monitor: PositionMonitor | null;
}

const PurchaseStep: FC<PurchaseStepProps> = ({
  stepStatus,
  stepType,
  color,
  StepIcon,
  index,
  monitor,
}) => {
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState<Document | undefined>();
  const [title, setTitle] = useState(
    <FormattedMessage id={stepType} defaultMessage={stepType} />,
  );

  useEffect(() => {
    if (loading) {
      setTitle(<Spin spinning={loading} />);
    }
  }, [loading]);

  const onVisibleChange = useCallback(
    async (visible: boolean, step: string) => {
      if (visible && monitor && monitor[step] && document === undefined) {
        try {
          const { relatedDocumentId } = monitor[step];
          if (relatedDocumentId) {
            setLoading(true);
            const doc = await getDocument(relatedDocumentId);
            if (doc) {
              setDocument(doc);
              const newTitle = (
                <Space direction="vertical" size="small">
                  <FormattedMessage id={stepType} defaultMessage={stepType} />
                  <div>
                    <FormattedMessage
                      id="document.number"
                      defaultMessage="Document number"
                    />
                    : {doc.docNumber || doc.documentNumber || ''}
                  </div>
                  <div>
                    <FormattedMessage
                      id="documents.commonfields.creationdate.label"
                      defaultMessage="Creation Date"
                    />
                    : {doc.creationDate || ''}
                  </div>
                </Space>
              );
              setTitle(newTitle);
            }
            setLoading(false);
          } else {
            const { timestamp } = monitor[step];
            const newTitle = (
              <Space direction="vertical" size="small">
                <FormattedMessage id={stepType} defaultMessage={stepType} />
                <div>
                  <FormattedMessage
                    id="documents.commonfields.creationdate.label"
                    defaultMessage="Creation Date"
                  />
                  : {moment(timestamp).format(moment.defaultFormat) || ''}
                </div>
              </Space>
            );
            setTitle(newTitle);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    },
    [monitor, document, stepType],
  );
  const onClick = useCallback(() => {
    if (document) {
      const canSee = checkUserPermissions(
        rootStore.getState(),
        DocumentAppConfig.todixId,
        'view-documents' as DOCUMENTS_ACTION,
        document.ecosystem,
      );
      if (canSee) {
        emitCustomEvent<DocumentPreviewEventName, DocumentPreviewEventPayload>(
          'showDocumentPreview',
          {
            document,
          },
        );
      }
    }
  }, [document]);
  return stepStatus !== 'empty' ? (
    <Tooltip
      title={title}
      onVisibleChange={(visible) => onVisibleChange(visible, stepType)}
    >
      <StatusIconContainer
        key={index}
        style={{ backgroundColor: color }}
        onClick={onClick}
      >
        <StepIcon style={{ color: '#fff' }} />
      </StatusIconContainer>
    </Tooltip>
  ) : (
    <StatusIconContainer key={index} style={{ backgroundColor: color }}>
      <StepIcon style={{ color: '#fff' }} />
    </StatusIconContainer>
  );
};

export default PurchaseStep;
