import React, { useCallback, useState, FC } from 'react';
import { Spin, message } from 'antd';
import { useHistory } from 'react-router-dom';

import { postTask, Task } from '@apps/tasks/services';
import { DetailedTask } from '../../components/DetailedTask';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getUser } from '@core/store/user';
import AppConfig, { TASKS_ACTION } from '@apps/tasks';
import useFirstActiveEcosystemId from '@core/hooks/useFirstActiveEcosystemId';

export const Create: FC<{}> = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: user } = useSelector(getUser);
  const history = useHistory();
  const intl = useIntl();
  const firstActiveEcosystemId = useFirstActiveEcosystemId(
    AppConfig.todixId,
    'create-tasks' as TASKS_ACTION,
  );

  const handleSubmit = useCallback(
    (values: Task) => {
      if (!user) {
        return;
      }

      setIsSubmitting(true);
      postTask({
        ...values,
        creator: user,
      })
        ?.then(() => {
          // message.success(
          //   intl.formatMessage({
          //     id: 'tasks.create.success',
          //     defaultMessage: 'Task created',
          //   }),
          // );
          setIsSubmitting(false);
          history.push(`/app/${AppConfig.todixId}`);
        })
        .catch(() => {
          setIsSubmitting(false);
          message.error(
            intl.formatMessage({
              id: 'tasks.create.error',
              defaultMessage: 'Error while creating Task',
            }),
          );
        });
    },
    [history, intl, user],
  );

  return (
    <Spin spinning={isSubmitting}>
      <DetailedTask
        readOnly={false}
        onSubmit={handleSubmit}
        ecosystemId={firstActiveEcosystemId}
        action="create-tasks"
      />
    </Spin>
  );
};
