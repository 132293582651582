import storage from '@services/Firebase/Storage';
import { Document } from '@apps/documents/service';
import * as pdfjs from 'pdfjs-dist/webpack';

const createThumbnailForPDFFile = async (
  doc: Partial<Document>,
  uid: string,
) => {
  const { documentUrl } = doc;
  const loadingTask = pdfjs.getDocument(documentUrl);
  return loadingTask.promise.then(async (pdfDocument: any) => {
    const page = await pdfDocument.getPage(1);
    const viewport = page.getViewport({ scale: 0.5 });
    const canvas = document.createElement('canvas');
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const context = canvas.getContext('2d');
    const renderContext = {
      canvasContext: context,
      viewport,
      canvasFactory: null,
    };
    const renderTask = page.render(renderContext);
    await renderTask.promise;
    // Convert the canvas to an image buffer.
    return new Promise((resolve) => {
      canvas.toBlob(async (blob) => {
        if (blob) {
          const snapshot = await storage.upload(
            `documents/thumbnails/${uid}`,
            blob,
          );
          const thumbnailUrl = await snapshot?.ref.getDownloadURL();
          doc = {
            ...doc,
            thumbnailUrl,
          };
        }
        page.cleanup();
        resolve(doc);
      });
    });
  });
};

export default createThumbnailForPDFFile;
