import React, { FC } from 'react';
import { Form } from 'antd';
import EditableContext from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/context';

type EditableRowProps = {
  index: number;
};

const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
        <Form.Item name="order" hidden={true} />
      </EditableContext.Provider>
      <Form.Item name="amount" hidden={true} />
      <Form.Item name="unitAmount" hidden={true} />
      <Form.Item name="id" hidden={true} />
    </Form>
  );
};

export default EditableRow;
