import React, { FC } from 'react';
import { Product } from '@apps/products/services';
import { IndividualPurchaseRequest } from '@apps/purchases/services';
import { rootStore } from '@core/store';
import { Provider } from 'react-redux';
import { RawIntlProvider } from 'react-intl';
import intl from '../../../../intl';
import { BrowserRouter } from 'react-router-dom';
import { Form } from '@apps/products/mainMenu/components/Form';
import { checkUserPermissions } from '@components/EcosystemIndicator/store';
import ProductsAppConfig, { PRODUCTS_ACTION } from '@apps/products';
import moment from 'moment/moment';

export type RequestFormProductModalProps = {
  initialValues: Partial<IndividualPurchaseRequest>;
  onSubmit: (product: Product) => void;
  onCancel?: () => void;
  user: User | null | undefined;
};

const RequestFormProductModal: FC<RequestFormProductModalProps> = ({
  onSubmit,
  user,
  initialValues: initialValuesProp,
  onCancel,
}) => {
  const action = 'create-products' as PRODUCTS_ACTION;

  const initialValues = {
    ecosystem: initialValuesProp.ecosystem,
    productName: initialValuesProp.productName,
    productNumber: initialValuesProp.productNumber,
    shortDescription: initialValuesProp.shortDescription,
    description: initialValuesProp.description,
    unit: initialValuesProp.unit,
    isActive: true,
    canBeBought: true,
  };
  const canCreate = initialValues
    ? checkUserPermissions(
        rootStore.getState(),
        ProductsAppConfig.todixId,
        action,
        initialValues?.ecosystem as string,
      )
    : false;

  const parseValues = (product: Product) => {
    const parsedValues = {
      ...initialValues,
      ...product,
      isActive: !!product.isActive,
      canBeBought: !!product.canBeBought,
      productCreationDate: moment(product.productCreationDate).format(),
      creator: user,
    } as Product;

    if (onSubmit) {
      onSubmit(parsedValues);
    }
  };

  return (
    <Provider store={rootStore}>
      <RawIntlProvider value={intl}>
        <BrowserRouter>
          <Form
            name="productBasics"
            initialValues={initialValues}
            onSubmit={(values: any) => parseValues(values)}
            onCancel={onCancel}
            readOnly={!canCreate}
            shouldRedirect={true}
            action={action}
            canUpdate={canCreate}
            hideButtons
          />
        </BrowserRouter>
      </RawIntlProvider>
    </Provider>
  );
};

export default RequestFormProductModal;
