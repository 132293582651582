import * as React from 'react';
import { Form } from 'antd';
import { Select } from '@components/FormFields';
import { StyledSectionTitle } from '@apps/contacts/mainMenu/components/DetailContactForm/DetailContactForm.sc';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';

export const initialTags = {
  tags: [],
};

const { Option } = Select;

const children: React.ReactNodeArray = [];
for (let i = 0; i < 5; i++) {
  children.push(
    <Option value={`Tag ${i}`} key={`Tag${i}`} label={`Tag ${i}`}>
      {`Tag ${i}`}
    </Option>,
  );
}

const SelectedTags: React.FC = () => {
  const { readOnly } = useFormContext();
  return (
    <>
      <StyledSectionTitle level={5}>
        <FormattedMessage
          id="detailcontactform.selectedtags.title"
          defaultMessage="Tags"
        />
      </StyledSectionTitle>
      <Form.Item name="tags">
        <Select
          disabled={readOnly}
          mode="multiple"
          allowClear
          optionLabelProp="label"
          name="tags"
          placeholder={
            <FormattedMessage
              id="detailcontactform.selectedtags.placeholder"
              defaultMessage="Click to select tags..."
            />
          }
        >
          {children}
        </Select>
      </Form.Item>
    </>
  );
};

export default SelectedTags;
