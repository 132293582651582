import React, { useState, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Icon, Text } from '@todix/ui-components';
import {
  Sider,
  StyledSubmenu,
  StyledMenu,
  MenuDivider,
  StyledMenuLink,
  OldAppMenuWrapper,
  StyledCollapsedMenu,
  StyledCollapsedSubMenu,
} from '@core/layout/components/LeftMenu/LeftMenu.sc';
import {
  StateProps,
  DispatchProps,
} from '@core/layout/components/LeftMenu/index';
import { EcosystemIndicator } from '@components/EcosystemIndicator/EcosystemIndicator';
import {
  EcosystemState,
  getOwnerEcosystem,
} from '@components/EcosystemIndicator/store';
import { Menu } from 'antd';
import SpinContainer from '@components/SpinContainer';
import { FormattedMessage } from 'react-intl';
import { getAppsMainMenu } from '@apps/AppsMainMenuRouting';
import { RegisteredFileApp } from '@apps/AppTypes';
import { getActivatedFileApps } from '@core/helpers/apps';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getEcosystemRelations } from '@core/store/ecosystemRelations';
import { getUserPreferences } from '@core/store/userPreferences';
import { DashboardRegisteredFileApp } from '@core/services';
import { getUser } from '@core/store/user';

const { Item: AntItem } = Menu;

type ItemProps = {
  ecosystems: EcosystemState;
  appEntry: RegisteredFileApp;
  asMoreApp?: boolean;
  collapsed: boolean;
};

const Item: React.FC<ItemProps> = ({
  ecosystems,
  appEntry,
  asMoreApp,
  collapsed,
  ...props
}) => {
  if (appEntry.mainMenu && appEntry.mainMenu.sections) {
    return (
      <StyledCollapsedSubMenu
        icon={<appEntry.mainMenu.icon />}
        collapsed={collapsed}
        title={
          <>
            {!collapsed && (
              <EcosystemIndicator
                style={{
                  float: 'right',
                }}
                ecosystems={ecosystems}
                small
              />
            )}
            {!collapsed && (
              <FormattedMessage
                id={`app.${appEntry.name}`}
                defaultMessage={appEntry.name}
              />
            )}
          </>
        }
        {...props}
      >
        {appEntry.mainMenu.sections?.map((item) => (
          <AntItem key={item.link}>
            <StyledMenuLink to={`/app/${appEntry.todixId}/${item.link}`}>
              <span>
                {item.icon && <item.icon style={{ marginRight: 8 }} />}
                <FormattedMessage id={item.title} defaultMessage={item.title} />
              </span>
            </StyledMenuLink>
          </AntItem>
        ))}
      </StyledCollapsedSubMenu>
    );
  }
  return (
    <StyledCollapsedMenu collapsed={collapsed} {...props}>
      <StyledMenuLink
        to={appEntry.link}
        style={{ marginLeft: asMoreApp ? -24 : 0 }}
      >
        <OldAppMenuWrapper>
          <appEntry.mainMenu.icon style={{ marginRight: 8 }} />
          {!collapsed && (
            <FormattedMessage
              id={`app.${appEntry.name}`}
              defaultMessage={appEntry.name}
            />
          )}
        </OldAppMenuWrapper>
        {!collapsed && <EcosystemIndicator ecosystems={ecosystems} small />}
      </StyledMenuLink>
    </StyledCollapsedMenu>
  );
};

type Props = StateProps & DispatchProps;

const entryElementsNumber = 11;

export const LeftMenu: React.FC<Props> = ({
  isLoading,
  applications,
  ecosystems,
  licences,
}) => {
  const { data: user } = useSelector(getUser);
  const userEcosystems = useSelector(getOwnerEcosystem(user?.uid as string));
  const [collapsed, setCollapsed] = useState(false);
  const ecosystemRelations = useSelector(getEcosystemRelations);
  const menuAppsOrder =
    useSelector(getUserPreferences).data.favouriteApplications || [];
  const menuAppsOrderLoader = useSelector(getUserPreferences).isLoading;
  const handleCollapse = useCallback((v) => {
    setCollapsed(v);
  }, []);

  // setting selected item inside of item is not working so we need to find it before Menu renders
  const location = useLocation();
  const todixId =
    location.pathname.split('/')[1] === 'app'
      ? location.pathname.split('/')[2]
      : null;

  let activeEcosystemsApps: RegisteredFileApp[] = ecosystems.reduce(
    (acc: any, ecosystem: Ecosystem) => {
      const relation = ecosystemRelations.find(
        (relation) => relation.ecosystem === ecosystem.id,
      );
      if (!relation || !relation?.isActive) {
        return acc;
      }
      const activatedFileApps = getActivatedFileApps(
        ecosystem,
        licences.data,
      ).filter((app) => {
        return !app.hidden;
      });
      activatedFileApps.forEach((app) => {
        acc.push(app);
      });
      return acc;
    },
    [],
  );

  const uniqueActiveEcosystemsApps = [
    DashboardRegisteredFileApp,
    ..._.uniqBy(activeEcosystemsApps, 'appId'),
  ];
  const showCollapsed = entryElementsNumber < uniqueActiveEcosystemsApps.length;

  let selectedAppKeys = uniqueActiveEcosystemsApps
    .map((app) => {
      if (app.mainMenu?.sections) {
        let sectionKey = '';
        app.mainMenu.sections.forEach((section) => {
          if (location.pathname.indexOf(`${app.link}/${section.link}`) === 0) {
            sectionKey = '';
          }
        });
        return sectionKey;
      }
      if (location.pathname.indexOf(app.link) === 0) {
        return todixId as string;
      }
      return '';
    })
    .filter((key) => !!key);

  if (selectedAppKeys.length < 1 && !location.pathname.includes('settings')) {
    selectedAppKeys = ['dashboard'];
  }

  if (todixId) {
    // get submenus
    const chosenApp = uniqueActiveEcosystemsApps.filter(
      (el) => el.todixId === todixId,
    )[0];

    if (
      chosenApp &&
      'sections' in
        uniqueActiveEcosystemsApps.filter((el) => el.todixId === todixId)[0]
          .mainMenu
    ) {
      selectedAppKeys = [
        ...selectedAppKeys,
        `${todixId}/${location.pathname.split('/')[3]}`,
      ];
    }
    // get direct Menu Items
    else {
      selectedAppKeys = [...selectedAppKeys, todixId];
    }
  }
  const selectedSettingsKey = location.pathname.includes('/settings')
    ? ['settings']
    : [];
  const defaultOpenKeys = uniqueActiveEcosystemsApps
    .filter((app) => !!app.mainMenu)
    .map((app) => app.appId);

  const licencesData = Object.values(licences.data);
  const getFilteredEcosystems = (appId: string): Ecosystem[] =>
    appId === 'dashboard'
      ? ecosystems
      : ecosystems.filter((ecosystem) => {
          const allowedApps = licencesData.filter(
            (licence) =>
              licence.ecosystem === ecosystem.id &&
              licence.status === 'active' &&
              licence.appId === appId,
          );
          return allowedApps.length > 0;
        });

  const allApps: ({ appId: string; component: Element } | null)[] = [
    ...uniqueActiveEcosystemsApps.map((appEntry, index) =>
      index <= entryElementsNumber - 1
        ? {
            appId: appEntry.appId,
            component: (
              <Item
                collapsed={collapsed}
                key={appEntry.todixId}
                ecosystems={getFilteredEcosystems(appEntry.appId)}
                appEntry={appEntry}
              />
            ),
          }
        : null,
    ),
    ...getAppsMainMenu(
      collapsed,
      uniqueActiveEcosystemsApps,
      ecosystems,
      licences.data,
    ),
  ];

  const isAppsLoading = isLoading || licences.isLoading || menuAppsOrderLoader;
  const firstEcosystemId = userEcosystems ? userEcosystems[0]?.id : '';

  return (
    <Sider
      width={304}
      collapsible
      onCollapse={handleCollapse}
      collapsedWidth={90}
    >
      {isAppsLoading && <SpinContainer />}
      {!isAppsLoading && (
        <StyledMenu
          style={{ width: 'auto' }}
          mode="inline"
          expandIcon={' '}
          selectedKeys={selectedAppKeys}
          defaultOpenKeys={defaultOpenKeys}
        >
          {menuAppsOrder.map((appId: string) => {
            const app = allApps.find((app) => app?.appId === appId);
            return app ? app.component : null;
          })}
          {menuAppsOrder.length < 1 &&
            Object.values(allApps).map((app) => app?.component)}
        </StyledMenu>
      )}
      {!isAppsLoading && (
        <StyledMenu
          style={{ width: 'auto' }}
          mode="inline"
          selectedKeys={[...selectedAppKeys, ...selectedSettingsKey]}
        >
          {showCollapsed ? (
            <StyledSubmenu
              title={
                <Text level={2} type="secondary">
                  <FormattedMessage
                    id="leftmenu.text"
                    defaultMessage="Show all installed apps {count}"
                    values={{ count: applications.length }}
                  />
                </Text>
              }
            >
              {uniqueActiveEcosystemsApps.map((appEntry, index) =>
                index > entryElementsNumber - 1 ? (
                  <Item
                    collapsed={collapsed}
                    key={appEntry.appId}
                    ecosystems={getFilteredEcosystems(appEntry.appId)}
                    appEntry={appEntry}
                    asMoreApp
                  />
                ) : null,
              )}
            </StyledSubmenu>
          ) : null}
          <MenuDivider />
          <StyledCollapsedMenu key="settings" collapsed={collapsed}>
            <Link to="/settings">
              <Icon.SettingOutlined />
              {!collapsed && (
                <Text level={3}>
                  <FormattedMessage
                    id="leftmenu.menuitem3"
                    defaultMessage="Settings"
                  />
                </Text>
              )}
            </Link>
          </StyledCollapsedMenu>
          {firstEcosystemId && (
            <StyledCollapsedMenu key="store" collapsed={collapsed}>
              <Link to={`/settings/${firstEcosystemId}/applications/store`}>
                <Icon.AppstoreOutlined />
                {!collapsed && (
                  <Text level={3}>
                    <FormattedMessage
                      id="settings.submenu.store.link"
                      defaultMessage="Store"
                    />
                  </Text>
                )}
              </Link>
            </StyledCollapsedMenu>
          )}
        </StyledMenu>
      )}
    </Sider>
  );
};
