import { Document } from '@apps/documents/service/namespace';
import moment from 'moment';
import removeUndefined from '@core/helpers/removeUndefined';
import { updateDocument } from '@apps/documents/service/index';
import { emitCustomEvent } from '@core/services';

const processingProductPictures = async (docs: Partial<Document>[]) => {
  let newRelatedDocs: Document[] = [];
  return Promise.all(
    docs.map((doc) => {
      const newDoc = {
        ...removeUndefined(doc),
        creatingUser: doc.uploadingUser,
        timestamp: moment().valueOf(),
        type: 'productPicture',
        receivingDate: moment().format(moment.defaultFormat),
        issuingDate: moment().format(moment.defaultFormat),
        creationDate: moment().format(moment.defaultFormat),
        category: 'none',
        relatedContact: '',
        relatedContactSnapshot: {},
        isDraft: false,
      } as Document;
      newRelatedDocs = [...newRelatedDocs, newDoc];
      return updateDocument(doc.id as string, newDoc);
    }),
  ).then(() => {
    emitCustomEvent('documentsSaved', newRelatedDocs);
  });
};

export default processingProductPictures;
