import getArrayChunks from '@core/helpers/getArrayChunks';
import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';

type Payload = {
  ecosystems: Ecosystem[];
  collection: string;
};

const getStatsForEcosystems = async ({ ecosystems, collection }: Payload) => {
  if (!ecosystems.length) {
    return Promise.resolve(0);
  }

  const ecosystemsChunk = getArrayChunks(ecosystems, 10);

  const contactsPromises = ecosystemsChunk.map((chunk) => {
    return chunk.length
      ? firebase.firestore
          ?.collection(collection)
          .where('ecosystem', 'in', chunk)
          .limit(100)
          .get()
          .then(getArrayWithIds)
      : Promise.resolve([]);
  });

  const contactsPromise = await Promise.all(contactsPromises);

  return contactsPromise.reduce((acc, it) => {
    if (it) {
      return acc + it.length;
    }
    return acc;
  }, 0);
};

export default getStatsForEcosystems;
