import React, { FC } from 'react';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import { IntlShape } from 'react-intl';
import ProductsSummaryPDF from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsSummaryPDF';
import {
  SummaryContainer,
  SummaryRow,
  SummaryRowFinal,
  SummaryRowCell,
  SummaryRowCellFinal,
} from '@apps/sales/mainMenu/components/SaleCreator/components/styles.sc';
import { StyledMoneyInput } from '@apps/sales/mainMenu/components/SaleCreator/components/ProductsWithPrices/styles.sc';
import calculateTotalsForProducts from '@apps/sales/mainMenu/components/SaleCreator/calculateTotalsForProducts';
import { DocumentType } from '@apps/documents/service';

type Props = {
  products: SalesFormValues['products'];
  intl: IntlShape;
  forPdf?: boolean;
  documentType: DocumentType;
};

const ProductsSummary: FC<Props> = ({
  forPdf,
  intl,
  products,
  documentType,
}) => {
  if (products?.length < 1) {
    return null;
  }
  const totals = calculateTotalsForProducts(products);
  const { total, taxes, totalDiscountedPrice } = totals;
  if (forPdf) {
    return <ProductsSummaryPDF intl={intl} totals={totals} />;
  }

  return documentType !== 'deliveryNote' ? (
    <SummaryContainer>
      <div />
      <div>
        <SummaryRow>
          <SummaryRowCell>
            {intl.formatMessage({
              id: 'sales.createForm.sumNet.label',
              defaultMessage: 'Sum net',
            })}
          </SummaryRowCell>
          <SummaryRowCell>
            <StyledMoneyInput value={totalDiscountedPrice} disabled={true} />
          </SummaryRowCell>
        </SummaryRow>
        {Object.keys(taxes)
          .sort()
          .map((taxKey, index) => {
            return (
              <SummaryRow key={index}>
                <SummaryRowCell>
                  {`${intl.formatMessage({
                    id: 'sales.salesTab.pricePreview.tax',
                    defaultMessage: 'Tax',
                  })} (${taxKey} %)`}
                </SummaryRowCell>
                <SummaryRowCell>
                  <StyledMoneyInput value={taxes[taxKey]} disabled={true} />
                </SummaryRowCell>
              </SummaryRow>
            );
          })}
        <SummaryRowFinal>
          <SummaryRowCellFinal>
            {intl.formatMessage({
              id: 'sales.salesTab.pricePreview.total',
              defaultMessage: 'Total',
            })}
          </SummaryRowCellFinal>
          <SummaryRowCell>
            <StyledMoneyInput
              value={total}
              disabled={true}
              style={{ fontSize: '14px', fontWeight: 'bold' }}
            />
          </SummaryRowCell>
        </SummaryRowFinal>
      </div>
    </SummaryContainer>
  ) : null;
};

export default ProductsSummary;
