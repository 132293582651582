import React, { FC, useState, useEffect } from 'react';
import { ProductEntry, FlowOption } from '@apps/warehouse/services/namespace';
import SerialNumbersModal from '@apps/warehouse/mainMenu/components/FormComponents/ProductEntries/SerialNumbersModal';
import getSerialNumbers from '@apps/warehouse/services/getSerialNumbers';
import { StyledButton } from './ButtonSerialNumber.sc';

interface ButtonSerialNumberProps {
  record: ProductEntry;
  ecosystem: string | null;
  onSerialNumbersChange?: (positionId: string, serialNumbers: string[]) => void;
  serialNumbers?: string[];
  onValidationChange?: (positionId: string, isValid: boolean) => void;
  flowType?: FlowOption | null;
}

const ButtonSerialNumber: FC<ButtonSerialNumberProps> = ({
  record,
  ecosystem,
  onSerialNumbersChange,
  serialNumbers: existingSerialNumbers,
  onValidationChange,
  flowType,
}) => {
  const [serialNumbersModalVisible, setSerialNumbersModalVisible] =
    useState(false);
  const [buttonType, setButtonType] = useState<
    'danger' | 'warning' | 'primary'
  >('danger');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (record.hasSerialNumber && ecosystem && record.productId && record.id) {
      setLoading(true);

      if (existingSerialNumbers && existingSerialNumbers.length > 0) {
        const count = existingSerialNumbers.length;

        if (flowType === 'manual_take_out') {
          if (count === 0) {
            setButtonType('danger');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          } else if (count < record.deliveredAmount) {
            setButtonType('warning');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          } else if (count === record.deliveredAmount) {
            setButtonType('primary');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, true);
            }
          } else {
            setButtonType('danger');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          }
        } else {
          if (count === 0) {
            setButtonType('danger');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          } else if (count < record.deliveredAmount) {
            setButtonType('warning');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, false);
            }
          } else {
            setButtonType('primary');
            if (onValidationChange && record.id) {
              onValidationChange(record.id, true);
            }
          }
        }

        setLoading(false);
        return;
      }

      getSerialNumbers({
        ecosystem,
        productId: record.productId,
        positionId: flowType === 'manual_take_out' ? undefined : record.id,
      })
        .then((entries) => {
          const count = entries?.length || 0;

          if (flowType === 'manual_take_out') {
            if (count === 0) {
              setButtonType('danger');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            } else if (count < record.deliveredAmount) {
              setButtonType('warning');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            } else if (count === record.deliveredAmount) {
              setButtonType('primary');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, true);
              }
            } else {
              setButtonType('danger');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            }
          } else {
            if (count === 0) {
              setButtonType('danger');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            } else if (count < record.deliveredAmount) {
              setButtonType('warning');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, false);
              }
            } else {
              setButtonType('primary');
              if (onValidationChange && record.id) {
                onValidationChange(record.id, true);
              }
            }
          }
        })
        .catch(() => {
          setButtonType('danger');
          if (onValidationChange && record.id) {
            onValidationChange(record.id, false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (!record.hasSerialNumber && onValidationChange && record.id) {
      onValidationChange(record.id, true);
    }
  }, [
    record,
    ecosystem,
    serialNumbersModalVisible,
    existingSerialNumbers,
    onValidationChange,
    flowType,
  ]);

  const handleSerialNumbersClick = () => {
    setSerialNumbersModalVisible(true);
  };

  const handleSerialNumbersSave = (serialNumbers: string[]) => {
    if (onSerialNumbersChange && record.id) {
      onSerialNumbersChange(record.id, serialNumbers);
    }
    setSerialNumbersModalVisible(false);
  };

  return (
    <>
      <StyledButton
        shape="circle"
        size="small"
        disabled={!record.hasSerialNumber}
        buttonType={buttonType}
        loading={loading}
        onClick={handleSerialNumbersClick}
      >
        S
      </StyledButton>
      {serialNumbersModalVisible && (
        <SerialNumbersModal
          visible={serialNumbersModalVisible}
          onCancel={() => setSerialNumbersModalVisible(false)}
          onSave={handleSerialNumbersSave}
          productId={record.productId}
          positionId={record.id}
          ecosystem={ecosystem || ''}
          quantity={record.deliveredAmount}
          initialSerialNumbers={existingSerialNumbers}
          flowType={flowType}
        />
      )}
    </>
  );
};

export default ButtonSerialNumber;
