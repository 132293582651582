import * as React from 'react';
// import { Search } from '@todix/ui-components';
import { FullScreenHandle } from 'react-full-screen';
import {
  ActionsWrapper,
  SearchWrapper,
  TopActionsWrapper,
} from './ActionsBar.sc';
import {
  FullScreenMode,
  AvatarHandler,
  CreateButtonPlatform,
  // TaskHandler,
  // HelpHandler,
  // NotificationsHandler,
} from '@core/layout/components';
import EcosystemIndicator from '@components/EcosystemIndicator';
import Cart from '@components/Cart';
import AppViewNavigation from '@core/layout/components/AppViewNavigation';
// import { useIntl } from 'react-intl';

type Props = {
  fullScreenHandler: FullScreenHandle;
};

export const ActionsBar: React.FC<Props> = ({ fullScreenHandler }) => {
  // const intl = useIntl();
  return (
    <ActionsWrapper>
      <SearchWrapper>
        {/* <Search
          placeholder={intl.formatMessage({
            id: 'actionsbar.search.placeholder',
            defaultMessage: 'Search',
          })}
          bordered={false}
          type="text"
        /> */}
        <AppViewNavigation />
      </SearchWrapper>
      <TopActionsWrapper>
        {/* <NotificationsHandler newNotificationsCount={22} />
        <HelpHandler />
        <TaskHandler showDots /> */}
        <CreateButtonPlatform />
        <EcosystemIndicator onClick={() => {}} />
        <AvatarHandler />
        <Cart />
        <FullScreenMode fullScreenHandler={fullScreenHandler} />
      </TopActionsWrapper>
    </ActionsWrapper>
  );
};
