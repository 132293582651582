import React, { FC } from 'react';
import { Document, Page } from '@react-pdf/renderer';
import { styles } from '@apps/sales/mainMenu/components/pdfTemplates/styleSheet';

const EmptyPage: FC = () => (
  <Document>
    <Page size="A4" style={styles.page} />
  </Document>
);

export default EmptyPage;
