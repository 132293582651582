import { Select } from 'antd';
import React, { FC } from 'react';
import { useRangeOfNumbers } from '@apps/sales/settings/RangeOfNumbers/useRangeOfNumbers';
import SpinContainer from '@components/SpinContainer';
import { FormattedMessage } from 'react-intl';
import { supportedFileTypes } from '@apps/sales/settings/RangeOfNumbers/consts';

type StepSelectProps = {
  ecosystemId: string;
};

export const StepSelect: FC<StepSelectProps> = ({ ecosystemId, ...rest }) => {
  const [isLoading, rangeOfNumbers] = useRangeOfNumbers(ecosystemId);

  if (isLoading) {
    return <SpinContainer />;
  }

  const fileTypeKeys = Object.keys(
    rangeOfNumbers ? rangeOfNumbers.fileTypes : supportedFileTypes,
  );

  return (
    <Select {...rest}>
      {fileTypeKeys.map((fileTypeKey) => {
        return (
          <Select.Option value={fileTypeKey} key={fileTypeKey}>
            <FormattedMessage
              id={`settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${fileTypeKey}`}
              defaultMessage={`settings.sales.rangeOfNumbers.supportedFileTypes.fullName.${fileTypeKey}`}
            />
          </Select.Option>
        );
      })}
    </Select>
  );
};
