import React from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { FilterTextObject } from '@services/filtering';
import { Gender } from '@apps/contacts/services';
import { FilterWrapper } from '@components/List/CustomsFilters/styles';

const { Option } = Select;

type State = {
  gender: string;
};

type Props = {
  filterChangedCallback: any;
};

const genders: Gender[] = ['diverse', 'female', 'male'];

// TODO: change it to the forwardRef FC
class GenderFilter extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      gender: '',
    };
  }

  onValueChange = (value: any) => {
    this.setState(
      {
        gender: value,
      },
      this.props.filterChangedCallback,
    );
  };

  isFilterActive = () => {
    return !!this.state.gender;
  };

  getModelAsString = () => {
    return '';
  };

  getModel = () => {
    return this.state.gender
      ? ({
          filter: this.state.gender,
          filterType: 'text',
          type: 'contains',
        } as FilterTextObject)
      : null;
  };

  setModel = (model: any) => {
    this.setState({
      gender: model?.category || null,
    });
  };

  render() {
    return (
      <FilterWrapper>
        <Select
          id="genders"
          onChange={this.onValueChange}
          allowClear
          placeholder={
            <FormattedMessage
              id="contacts.general.gender.label"
              defaultMessage="Gender"
            />
          }
          style={{ width: 200 }}
        >
          {genders.map((gender) => (
            <Option key={gender} id={gender} value={gender}>
              <FormattedMessage
                id={`detailcontactform.personinfo.gender.${gender}`}
                defaultMessage={`detailcontactform.personinfo.gender.${gender}`}
              />
            </Option>
          ))}
        </Select>
      </FilterWrapper>
    );
  }
}

export default GenderFilter;
