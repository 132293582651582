import React, { FC } from 'react';
import { Tag } from 'antd';
import { Sale } from '@apps/sales/services';
import { useIntl } from 'react-intl';

type Props = {
  value: Sale['shipmentStatus'];
};

const colors: Record<NonNullable<Sale['shipmentStatus']>, string> = {
  delivered: '#0e7d40',
  error: '#f5222d',
  refused: '#f5222d',
  pending: '#1890ff',
  cancelled: '#faad14',
  delayed: '#faad14',
};
const SaleShipmentStatusCellRenderer: FC<Props> = ({ value }) => {
  const intl = useIntl();
  if (!value) {
    return null;
  }
  return (
    <Tag color={colors[value] || colors.pending}>
      {intl.formatMessage({
        id: `sales.shipment.${value}`,
        defaultMessage: value,
      })}
    </Tag>
  );
};

export default SaleShipmentStatusCellRenderer;
