import React, { CSSProperties, FC, useState } from 'react';
import { IntlShape } from 'react-intl';
import { Style } from '@react-pdf/types';
import { Text, View } from '@react-pdf/renderer';
import useOutsideClick from '@core/hooks/useOutsideClick';
import { Hoverable } from '@apps/sales/mainMenu/components/SaleCreator/components/styles.sc';
import { SalesFormValues } from '@apps/sales/mainMenu/components/SaleCreator/commonTypes';
import DeliveryTermsSearch from '@apps/sales/mainMenu/components/SaleCreator/components/DeliveryTermsSearch';
import { Form } from 'antd';

type Props = {
  style?: Style;
  intl: IntlShape;
  forPdf: boolean;
  terms?: SalesFormValues['deliveryTerms'];
};

const DeliveryTerms: FC<Props> = ({ style, forPdf, terms, intl }) => {
  const [editMode, setEditMode] = useState(false);
  const ref = useOutsideClick({
    callback: () => setEditMode(false),
  });

  if (forPdf) {
    return (
      <View style={style}>
        <Text>
          {`${intl.formatMessage({
            id: 'sales.settings.deliveryterms.header',
            defaultMessage: 'Delivery terms',
          })}: ${terms?.shortage || ''}`}
        </Text>
      </View>
    );
  }

  if (!terms || editMode) {
    return (
      <div ref={ref} style={style as CSSProperties}>
        <Form.Item
          name="deliveryTerms"
          label={intl.formatMessage({
            id: 'sales.settings.deliveryterms.header',
            defaultMessage: 'Delivery terms',
          })}
          className="animate__animated animate__fadeIn animate__faster"
        >
          <DeliveryTermsSearch onDeliveryTermsSet={() => setEditMode(false)} />
        </Form.Item>
      </div>
    );
  }

  return (
    <Form.Item
      name="deliveryTerms"
      label={intl.formatMessage({
        id: 'sales.settings.deliveryterms.header',
        defaultMessage: 'Delivery terms',
      })}
      style={style as CSSProperties}
    >
      <Hoverable>
        <div onClick={() => setEditMode(true)}>
          {terms.shortage ? `(${terms.shortage})` : ''}
          {terms.longName || ''}
        </div>
      </Hoverable>
    </Form.Item>
  );
};

export default DeliveryTerms;
