import React, { FC } from 'react';
import { Form, Input } from 'antd';

const { Item } = Form;

type ProductStatProps = {
  label: string;
  placeholder: string;
  value?: null | number;
};

const ProductStat: FC<ProductStatProps> = ({ label, placeholder, value }) => {
  return (
    <Item label={label}>
      <Input
        width="100%"
        placeholder={placeholder}
        autoComplete="nope"
        readOnly={true}
        value={value || ''}
      />
    </Item>
  );
};

export default ProductStat;
