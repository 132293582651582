import {
  WAREHOUSE_STORAGE,
  WarehouseStorageEntry,
} from '@apps/warehouse/services/namespace';
import { firebase } from '@services/Firebase';
import moment from 'moment';

const createWarehouseStorageEntry = (
  ecosystem: string,
  entry: WarehouseStorageEntry,
) => {
  const { id, storageId, ...rest } = entry;
  return firebase.db
    ?.ref(`${WAREHOUSE_STORAGE}/${ecosystem}/${storageId}/entries`)
    .push({
      ...rest,
      timestamp: moment().valueOf(),
    })
    .then((res) => ({
      ...entry,
      id: res.key as string,
    }));
};

export default createWarehouseStorageEntry;
