import React, { FC, useEffect, useState } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import { Icon } from '@todix/ui-components';
import { Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import EcosystemAvatar from '@components/EcosystemAvatar';
import { Product, getProducts } from '@apps/products/services';
import { StyledDashboardCard } from '@pages/dashboard';
import ProductsAppConfig from '@apps/products';
import HugeButton from '@components/HugeButton';
import WidgetCard from '@components/WidgetCard';
import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';

const { Text } = Typography;

const DashboardWidget: FC = () => {
  const [spinning, setSpinning] = useState(true);
  const [products, setProducts] = useState<Product[]>([]);
  const activeEcosystems = useSelector(getActiveEcosystems);
  const intl = useIntl();

  useEffect(() => {
    if (activeEcosystems.length) {
      setSpinning(true);
      getProducts(
        {
          fields: [
            'id',
            'ecosystem',
            'isActive',
            'productCreationDate',
            'productGroup',
            'productName',
            'productNumber',
            'tags',
          ],
          limit: 3,
        },
        'view-products',
      )
        ?.then((response) => {
          setProducts(response.results as Product[]);
          setSpinning(false);
        })
        .catch((e) => {
          console.error(e);
          setSpinning(false);
        });
    }
  }, [activeEcosystems.length, setProducts]);

  return (
    <StyledDashboardCard bordered={false}>
      <Spin spinning={spinning} style={{ height: '100%' }} />
      {products.length ? (
        <ReactCardCarousel autoplay spread="narrow" alignment="horizontal">
          {products.map((product, index) => (
            <WidgetCard
              key={index}
              title={
                <div>
                  <EcosystemAvatar id={product.ecosystem} />{' '}
                  {product.productName}
                </div>
              }
              bordered={false}
              extra={
                <Link to={`app/${ProductsAppConfig.todixId}`}>
                  {intl.formatMessage({
                    id: 'dashboard.products.link',
                    defaultMessage: 'Go to products',
                  })}
                </Link>
              }
            >
              <div>
                {intl.formatMessage({
                  id: 'products.columns.productgroup.header',
                  defaultMessage: 'Product Group',
                })}
                : <Text strong>{product.productGroup}</Text>
              </div>
              <div>
                {intl.formatMessage({
                  id: 'products.columns.productNumber.header',
                  defaultMessage: 'Product Number',
                })}
                : <Text strong>{product.productNumber}</Text>
              </div>
            </WidgetCard>
          ))}
        </ReactCardCarousel>
      ) : (
        !spinning && (
          <Link to={`app/${ProductsAppConfig.todixId}/create`}>
            <HugeButton type="dashed" icon={<Icon.ShoppingOutlined />}>
              <FormattedMessage
                id="dashboard.empty.createproduct"
                defaultMessage="Create your first Product now"
              />
            </HugeButton>
          </Link>
        )
      )}
    </StyledDashboardCard>
  );
};

export default DashboardWidget;
