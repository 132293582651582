import React, { FC, ReactNode } from 'react';
import { StepFormProps } from '@apps/documents/mainMenu/DocumentProcessing/StepForm';
import { Divider, Space } from 'antd';
import { ButtonsWrapper } from '@components/FormWithReminder/FormWithReminder.sc';
import {
  ButtonSectionLeft,
  ButtonSectionRight,
  StepLabel,
} from '@apps/documents/mainMenu/DocumentProcessing/DocumentProcessing.sc';
import { Button } from '@todix/ui-components';
import { Document } from '@apps/documents/service';
import { ButtonConfig } from '@components/FormWithReminder/FormWithButtons';
import { FormattedMessage } from 'react-intl';

export type ChildrenPassedProps = StepFormProps & {
  document: Partial<Document>;
  triggerValidator(name: string): void;
};

export type StepProps = {
  formProps: StepFormProps;
  children?: (props: ChildrenPassedProps) => ReactNode;
  document: Partial<Document>;
  submitButton: ButtonConfig;
  cancelButton: ButtonConfig;
  submitDraftButton: ButtonConfig;
  step: number;
  steps: number;
  readOnly?: boolean;
  triggerValidator(name: string): void;
};

const Step: FC<StepProps> = ({ children, formProps, ...rest }) => {
  const {
    cancelButton,
    submitDraftButton,
    submitButton,
    step,
    steps,
    triggerValidator,
  } = rest;

  return (
    <>
      {children &&
        children({ ...formProps, document: rest.document, triggerValidator })}
      {!formProps.hidden && (
        <>
          <Divider type="horizontal" />
          <ButtonsWrapper>
            <ButtonSectionLeft>
              <Button
                onClick={cancelButton.onClick}
                size="middle"
                disabled={cancelButton.disabled}
              >
                {cancelButton.label}
              </Button>
              <StepLabel>
                <FormattedMessage
                  id="documentprocessing.step.label"
                  defaultMessage="Step {step}/{steps}"
                  values={{ step, steps }}
                />
              </StepLabel>
            </ButtonSectionLeft>
            <ButtonSectionRight>
              <Space direction="horizontal" style={{ display: 'flex' }}>
                <Button
                  onClick={submitDraftButton.onClick}
                  size="middle"
                  disabled={submitDraftButton.disabled}
                >
                  {submitDraftButton.label}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  form={formProps.name}
                  onClick={submitButton.onClick}
                  size="middle"
                  disabled={submitButton.disabled}
                >
                  {submitButton.label}
                </Button>
              </Space>
            </ButtonSectionRight>
          </ButtonsWrapper>
        </>
      )}
    </>
  );
};

export default Step;
