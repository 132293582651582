import {
  Commission,
  COMMISSIONS_SETTINGS_COLLECTION_NAME,
} from '@apps/directSales/services/commisions/namespace';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/directSales';
import { firebase } from '@services/Firebase';

export const getCommissions = (
  ecosystemId: string,
): Promise<Commission[]> | undefined => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    '',
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystemId)) {
    return Promise.resolve([] as Commission[]);
  }

  return firebase.firestore
    ?.collection(COMMISSIONS_SETTINGS_COLLECTION_NAME)
    .where('ecosystemId', '==', ecosystemId)
    .get()
    .then((querySnapshot) => {
      const docs: object[] = [];
      // querySnapshot do not support map
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return docs ? (docs as Commission[]) : [];
    });
};
