import {
  get as apiGet,
  getWithId,
  post,
  patch,
  deleteRequest,
} from '@services/api';
import {
  BUCKETS_COLLECTION,
  Bucket,
  BucketStep,
  BucketStatus,
} from '@apps/purchases/services/buckets/namespace';
import { removeUndefinedProperties } from '@services/utils';
import moment from 'moment/moment';
import { GetApiResponse } from '@apps/purchases/services';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig, { PURCHASES_ACTION } from '@apps/purchases';
import get from 'lodash.get';
import { getFilteredData } from '@services/filtering';
import set from 'lodash.set';
import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';

import { Document, getDocumentsByIds } from '@apps/documents/service';
import getChildrenForParent from '@services/entityRelations/getChildrenForParent';
import intl from '../../../../../intl';

export * from './namespace';

export const getBucket = (id: string) =>
  getWithId(`${BUCKETS_COLLECTION}/${id}`) as Promise<Bucket>;

export const createBucket = (bucket: Bucket) =>
  post(
    `${BUCKETS_COLLECTION}`,
    {
      ...removeUndefinedProperties(bucket),
      timestamp: moment().valueOf(),
    },
    {
      message: true,
      messageContent: intl.formatMessage({
        id: 'bucket.created',
        defaultMessage: 'Bucket has been created',
      }),
    },
  );

export const updateBucket = (id: string, bucket: Partial<Bucket>) =>
  patch(
    `${BUCKETS_COLLECTION}/${id}`,
    {
      ...removeUndefinedProperties(bucket),
      timestamp: moment().valueOf(),
    },
    {
      message: false,
    },
  );

export const removeBucket = (id: string) =>
  deleteRequest(`${BUCKETS_COLLECTION}/${id}`, {
    message: false,
  });

type GetBucketsPayload = {
  ecosystem: string;
  relatedContact?: string;
  stepType?: BucketStep;
  status?: BucketStatus;
  relatedDocumentId?: string;
};

export const getBucketsPerDetails = ({
  ecosystem,
  relatedContact,
  stepType,
  status,
  relatedDocumentId,
}: GetBucketsPayload) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-purchases' as PURCHASES_ACTION,
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds?.includes(ecosystem)) {
    return Promise.resolve([] as Bucket[]);
  }

  let query = (firebase.firestore as firebase.firestore.Firestore)
    .collection(BUCKETS_COLLECTION)
    .where('ecosystem', '==', ecosystem);

  if (stepType) {
    query = query.where('stepType', '==', stepType);
  }

  if (status) {
    query = query.where('status', '==', status);
  }

  if (relatedContact) {
    query = query.where('relatedContact', '==', relatedContact);
  }

  if (relatedDocumentId) {
    query = query.where('relatedDocumentId', '==', relatedDocumentId);
  }

  return query.get().then(getArrayWithIds) as Promise<Bucket[]>;
};

export const getBuckets: GetApiResponse = (passedParams, action) => {
  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    action,
  )?.map((eco) => eco.id);
  const params = {
    query: '',
    offset: 0,
    limit: 1000,
    fields: ['id', 'ecosystem'],
    filters: {},
    sort: [],
    ...passedParams,
  };
  // @ts-ignore
  return apiGet(BUCKETS_COLLECTION)?.then(
    // @ts-ignore
    (data: Bucket[]) => {
      // filter, sort, limit (need info about all records)
      const filterEntries = Object.entries(params.filters);
      const shouldFilter = filterEntries.length;
      const shouldSort = !!params.sort.length;
      const filteredByEcosystems = data.filter((row) =>
        activeEcosystemsIds.includes(row.ecosystem as string),
      );
      const filteredByQuery = filteredByEcosystems.filter((row) =>
        params.fields.some((path) => {
          const fieldValue = get(row, path);
          if (typeof fieldValue !== 'string') {
            return false;
          }
          const queryLower = params.query.toLowerCase();
          return fieldValue.toLowerCase().includes(queryLower);
        }),
      );
      const filtered = shouldFilter
        ? getFilteredData(filteredByQuery, filterEntries)
        : filteredByQuery;
      const sorted = shouldSort
        ? [...filtered].sort((rowA, rowB) => {
            const sortKey = params.sort[0];
            const desc = sortKey.split('').includes('-');
            const path = sortKey
              .split('')
              .filter((c) => c !== '-')
              .join('');
            const valueA = get(rowA, path);
            const valueB = get(rowB, path);
            if (valueA === valueB) {
              return 0;
            }
            if (valueA < valueB) {
              return desc ? 1 : -1;
            } else {
              return desc ? -1 : 1;
            }
          })
        : filtered;
      const choosenFields = sorted.map((row) => {
        const newRow = {};
        params.fields.forEach((path: string): void => {
          const value = get(row, path);
          set(newRow, path, value);
        });
        return newRow;
      });
      const results = choosenFields.length;
      const page = choosenFields.slice(
        params.offset,
        params.offset + params.limit,
      );
      return {
        results: page,
        info: {
          results,
        },
      };
    },
  );
};

export const getDocumentsRelatedWithBucket = async (
  bucket: Bucket,
): Promise<Document[]> => {
  const bucketDocumentRelations = await getChildrenForParent({
    parentId: bucket.id as string,
    ecosystem: bucket.ecosystem,
    childType: 'document',
  });

  const docIds = bucketDocumentRelations.map((el) => el.childId);
  return await getDocumentsByIds(docIds);
};
