import * as React from 'react';
import { Avatar, Button, Upload as AntdUpload } from 'antd';
import { UploadProps as AntdUploadProps } from 'antd/lib/upload';
import { UploadOutlined } from '@ant-design/icons';

export type UploadProps = AntdUploadProps & {
  src?: string;
  value?: any;
  showAvatar: boolean;
};

const Upload: React.FC<UploadProps> = ({
  src,
  value,
  showAvatar,
  ...restProps
}) => {
  return (
    <>
      {showAvatar && (
        <Avatar src={src} style={{ marginRight: 8, marginBottom: 8 }} />
      )}
      <AntdUpload {...restProps}>
        <Button icon={<UploadOutlined />} disabled={restProps.disabled}>
          Click to Upload
        </Button>
      </AntdUpload>
    </>
  );
};

export default Upload;
