import * as React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import { FullScreenHandle } from 'react-full-screen';
import {
  Logo,
  Header,
  LogoWrapper,
} from '@core/layout/components/DashboardLayout/DashboardLayout.sc';
import { DispatchProps, StateProps } from '@core/layout';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import AdminLeftMenu from '@core/layout/components/DashboardAdminLayout/AdminLeftMenu';

const MainLayout = styled(Layout)`
  height: 100vh;
  overflow: hidden;
`;

const InnerLayout = styled(Layout)`
  padding: 24px;
  height: calc(100vh - 64px);
  overflow: auto;
`;

export type OwnAdminProps = {
  fullScreenHandler: FullScreenHandle;
  children?: React.ReactNode;
};

type Props = StateProps & DispatchProps;

export const DashboardAdminLayout: React.FC<Props & OwnAdminProps> = ({
  children,
}) => {
  return (
    <MainLayout>
      <Header>
        <Link to="/">
          <LogoWrapper>
            <Logo className="todix-logo" />
            <FormattedMessage
              id="dashboardlayout.logo"
              defaultMessage="Todix"
            />
          </LogoWrapper>
        </Link>
      </Header>
      <Layout hasSider={true}>
        <AdminLeftMenu />
        <InnerLayout>{children}</InnerLayout>
      </Layout>
    </MainLayout>
  );
};
