import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input, Form } from 'antd';

const { Item } = Form;

type ProgressProps = {
  isProgressEnabled: boolean;
  readOnly: boolean;
};

const Progress: FC<ProgressProps> = ({ isProgressEnabled, readOnly }) => {
  const intl = useIntl();
  return (
    <Item
      name="progress"
      label={
        <FormattedMessage
          id="tasks.form.progress.label"
          defaultMessage="Progress"
        />
      }
      required
      rules={[
        {
          type: 'string',
          pattern: /^[0-9]$|^[1-9][0-9]$|^(100)$/,
          message: (
            <FormattedMessage
              id="tasks.form.progress.error"
              defaultMessage="Progress must be a number between 0 and 100"
            />
          ),
        },
      ]}
    >
      <Input
        name="progress"
        disabled={!isProgressEnabled}
        type={'number'}
        min={'0'}
        step={'1'}
        pattern={'[0-9]*'}
        max={'100'}
        placeholder={intl.formatMessage({
          id: 'tasks.form.progress.placeholder',
          defaultMessage: 'Progress',
        })}
        autoComplete="nope"
        readOnly={readOnly}
      />
    </Item>
  );
};

export default Progress;
