import React, { FC } from 'react';
import { useSlate } from 'slate-react';
import isMarkActive from '@components/RichTextEditor/helpers/isMarkActive';
import toggleMark from '@components/RichTextEditor/helpers/toggleMark';
import { ButtonWithMargins } from '@components/RichTextEditor/styles.sc';

type Props = {
  format: string;
  icon: any;
};
const MarkButton: FC<Props> = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ButtonWithMargins
      type={isMarkActive(editor, format) ? 'primary' : 'default'}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      icon={icon}
    />
  );
};

export default MarkButton;
