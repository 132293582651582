import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import { ColSpanParams } from 'ag-grid-community/dist/lib/entities/colDef';

const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="productName"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'products.columns.productname.header',
        defaultMessage: 'Product Name',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="ecosystem"
      headerName={intl.formatMessage({
        id: 'products.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      cellRenderer="ecosystemRenderer"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      filter="ecosystemFilter"
      lockPosition="left"
      lockVisible={true}
    />
    <AgGridColumn
      field="productNumber"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'products.columns.productNumber.header',
        defaultMessage: 'Product Number',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="standardReorderTime"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'standard.reorder.time',
        defaultMessage: 'Standard Reorder time (days)',
      })}
      sortable
      minWidth={200}
    />
    <AgGridColumn
      field="amountAvailable"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'amount.available',
        defaultMessage: 'Amount available',
      })}
      sortable
      minWidth={120}
    />
    <AgGridColumn
      field="total"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'total',
        defaultMessage: 'Total',
      })}
      sortable
      minWidth={100}
    />
    <AgGridColumn
      field="reorderStock"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'reorder.stock',
        defaultMessage: 'Reorder Stock',
      })}
      sortable
      minWidth={120}
    />
    <AgGridColumn
      field="avgNeedPerMonth"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'average.need.per.month',
        defaultMessage: 'Average need per Month',
      })}
      sortable
      minWidth={120}
    />
    <AgGridColumn
      cellRenderer="actionRenderer"
      maxWidth={70}
      lockPosition="right"
    />
  </>
);

export default columns;
