import React from 'react';
import debounce from 'lodash.debounce';
import { searchUserByDisplayName } from '@services/users';
import ResourceLabel from '@apps/weekplan/mainMenu/WeekPlan/ResourceLabel';

export type Option = {
  key: string;
  value: any;
  label: any;
};

type HandleSearchPayload = {
  setSearching: (flag: boolean) => void;
  setUsersOptions: (options: Option[]) => void;
  value: string;
  ecosystem: string;
  activeEcosystems: Ecosystem[];
  members: EcosystemMember[];
};

const handleSearch = ({
  setSearching,
  setUsersOptions,
  value,
  ecosystem,
  activeEcosystems,
}: HandleSearchPayload) => {
  setSearching(true);

  const selectedEcosystem = activeEcosystems.find(
    (eco) => eco.id === ecosystem,
  );

  if (!selectedEcosystem) {
    setSearching(false);
    return;
  }

  let restrictedUserIds = [selectedEcosystem.ownerUid];

  if ((selectedEcosystem as CompanyEcosystem).membersUids) {
    restrictedUserIds = [
      ...restrictedUserIds,
      ...(selectedEcosystem as CompanyEcosystem).membersUids,
    ];
  }

  searchUserByDisplayName(value)
    ?.then((users) => {
      const options: Option[] = users
        .filter((user) => restrictedUserIds.includes(user.id))
        .map((user) => ({
          key: user.id,
          value: user.id,
          label: <ResourceLabel resource={user} />,
        }));

      setUsersOptions(options);
    })
    ?.finally(() => {
      setSearching(false);
    });
};

export const debounceSearch = debounce(handleSearch, 500);
