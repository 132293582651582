export interface IUserCommission {
  order_id: number;
  order_type_id: number;
  paid: Date;
  total_cleaned_provision_price: number;
  customer_id: number;
  firstname: string;
  lastname: string;
  street: string;
  street_number: string;
  zip: string;
  city: string;
  country_id: number;
  sponsor_id: number;
  sponsor_firstname: string;
  sponsor_lastname: string;
}

export const isUserCommission = (obj: any): obj is IUserCommission => {
  return (
    typeof obj?.order_id === 'number' &&
    typeof obj?.order_type_id === 'number' &&
    typeof obj?.paid === 'string' &&
    typeof obj?.total_cleaned_provision_price === 'number' &&
    typeof obj?.customer_id === 'number' &&
    typeof obj?.firstname === 'string' &&
    typeof obj?.lastname === 'string' &&
    typeof obj?.street === 'string' &&
    typeof obj?.street_number === 'string' &&
    typeof obj?.zip === 'string' &&
    typeof obj?.city === 'string' &&
    typeof obj?.country_id === 'number' &&
    typeof obj?.sponsor_id === 'number' &&
    typeof obj?.sponsor_firstname === 'string' &&
    typeof obj?.sponsor_lastname === 'string'
  );
};
