import { styled } from '@styles/themes';
import { List } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 438px;
`;

export const LiteItemMeta = styled(List.Item.Meta)`
  align-items: center;
`;
