import React from 'react';
import { Avatar } from 'antd';

export type AvatarRendererProps = {
  data: {
    avatar: string;
  };
};

const AvatarRenderer = ({ data }: AvatarRendererProps) => {
  if (!data) {
    return '';
  }
  return <Avatar src={data.avatar} size="small" />;
};

export default AvatarRenderer;
