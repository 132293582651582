import React, { FC, useEffect } from 'react';
import { styled } from '@styles/themes';
import { Button, Card, Form, Input, Switch } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Inbox } from '@apps/documents/service';

const StyledButton = styled(Button)`
  float: right;
  margin: 0 12px;
`;

const { TextArea } = Input;

type Props = {
  loading: boolean;
  details: Inbox;
  onFinish: (inbox: Inbox) => void;
};
const Details: FC<Props> = ({ loading, details, onFinish }) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  useEffect(() => {
    form.setFieldsValue({
      //@ts-ignore
      description: '',
      ...details,
    });
  }, [form, details]);

  return (
    <Form
      form={form}
      autoComplete="off"
      colon={false}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      labelAlign="left"
      initialValues={details}
      onFinish={onFinish}
    >
      <Card
        title={
          <FormattedMessage
            id="settings.inbox.edit.title"
            defaultMessage="Edit inbox"
          />
        }
        loading={loading}
        actions={[
          <StyledButton type="primary" htmlType="submit">
            <FormattedMessage
              id="settings.ecosystemform.submitbutton"
              defaultMessage="Save"
            />
          </StyledButton>,
        ]}
      >
        <Form.Item
          label={intl.formatMessage({
            id: 'settings.documents.inboxesmanagement.list.header.inboxname',
            defaultMessage: 'Inbox name',
          })}
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'settings.list.header.status',
            defaultMessage: 'Status',
          })}
          name="isActive"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'settings.documents.inboxesmanagement.list.header.description',
            defaultMessage: 'Description',
          })}
          name="description"
        >
          <TextArea />
        </Form.Item>
      </Card>
    </Form>
  );
};

export default Details;
