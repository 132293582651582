import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DatePicker, Form } from 'antd';

const { Item } = Form;

const CreationDate: FC = () => {
  const intl = useIntl();
  return (
    <Item
      name="creationDate"
      label={
        <FormattedMessage
          id="tasks.columns.creationDate.header"
          defaultMessage="Creation Date"
        />
      }
    >
      <DatePicker
        disabled
        placeholder={intl.formatMessage({
          id: 'tasks.form.duedate.placeholder',
          defaultMessage: 'Select date',
        })}
      />
    </Item>
  );
};

export default CreationDate;
