import { Form, Input } from 'antd';
import * as React from 'react';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FieldProps } from '@apps/documents/mainMenu/components/FormFields';
import { FormattedMessage, useIntl } from 'react-intl/lib';

const SeparatedValues: React.FC<FieldProps> = ({ field, ...rest }) => {
  const { getFieldValue } = useFormContext();
  const intl = useIntl();
  const { fieldName, label, className, style, placeholder } = field;
  const values = getFieldValue(fieldName);
  const value = Array.isArray(values)
    ? values.join(', ')
    : values
    ? Object.values(values).join(', ')
    : '';

  return (
    <Form.Item
      label={
        label ? <FormattedMessage id={label} defaultMessage={label} /> : label
      }
      colon={false}
      labelAlign="left"
      {...rest}
    >
      <Input
        placeholder={
          placeholder
            ? intl.formatMessage({
                id: placeholder,
                defaultMessage: placeholder,
              })
            : placeholder
        }
        readOnly={true}
        className={className}
        style={style}
        value={value}
      />
    </Form.Item>
  );
};

export default SeparatedValues;
