import { styled } from '@styles/themes';
import { Tag } from 'antd';
import { Text } from '@todix/ui-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const PageTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.text.normal};
  margin-right: 30px;
  font-weight: bold;
  span {
    font-weight: normal;
  }
`;

export const CountTag = styled(Tag)`
  margin-left: 10px;
`;
