import { Project } from './namespace';
import { firebase } from '@services/Firebase';
import getArrayWithIds from '@core/helpers/getArrayWithIds';
import moment from 'moment';
import { getActiveEcosystemsForApp } from '@components/EcosystemIndicator/store';
import { rootStore } from '@core/store';
import appConfig from '@apps/projects';

type GetProjectsPayload = {
  ecosystem: string;
};

export const getProjectsPerDetails = ({ ecosystem }: GetProjectsPayload) => {
  if (!ecosystem) {
    return Promise.resolve([] as Project[]);
  }

  const activeEcosystemsIds = getActiveEcosystemsForApp(
    rootStore.getState(),
    appConfig.todixId,
    'view-projects',
  )?.map((eco) => eco.id);

  if (!activeEcosystemsIds.includes(ecosystem)) {
    return Promise.resolve([]);
  }

  const query = (firebase.firestore as firebase.firestore.Firestore)
    .collection('projects')
    .where('ecosystem', '==', ecosystem)
    .where('endDate', '>=', moment().format('YYYY-MM-DD'));

  return query.get().then(getArrayWithIds) as Promise<Project[]>;
};
