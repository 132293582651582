import React, { useState, FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Product, getProduct } from '@apps/products/services';
import { DetailedProduct } from '../../components/DetailedProduct';

const Preview: FC<{}> = () => {
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    getProduct(id).then(setProduct);
  }, [id]);

  if (!product) return null;

  return (
    <DetailedProduct
      id={id}
      initialValues={product}
      readOnly
      action="view-products"
    />
  );
};

export default Preview;
