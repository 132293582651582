import { styled } from '@styles/themes';
import { Drawer, Layout } from 'antd';

export const TermsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PageWrapper = styled.div`
  width: calc(70vw);
  display: flex;
  flex-direction: column;
  min-height: calc(297mm * 0.8);
  padding: 40px;
  border: 1px solid lightgray;
  background: rgb(255 255 255);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%),
    0 2px 4px 0 rgb(0 0 0 / 2%);

  && {
    .ant-form-item-hidden {
      display: none !important;
    }
    .ant-form-item {
      display: grid;
    }

    .ant-form-item-control {
      width: 100%;
      max-width: 100% !important;
    }
    .ant-picker {
      width: 100%;
    }
    .ant-form-item-label {
      max-width: 100% !important;
    }

    .ant-input-affix-wrapper-disabled {
      background: #fff;
    }
  }
`;

export const PageViewDrawer = styled(Drawer)`
  min-width: 92px !important;
  position: absolute;

  & {
    .ant-layout-sider-trigger {
      max-width: 80px !important;
    }
  }
`;

export const ViewLayout = styled(Layout)`
  display: flex;
  position: relative;
  padding: 20px;
`;

export const RightColumn = styled.div`
  && {
    .ant-form-item-hidden {
      display: none !important;
    }
    .ant-form-item {
      display: grid;
    }
  }

  && {
    .ant-form-item-control {
      width: 100%;
      max-width: 100% !important;
    }
    .ant-picker {
      width: 100%;
    }
    .ant-form-item-label {
      max-width: 100% !important;
    }
  }
`;

export const FlexJustifyEnd = styled.div`
  display: flex;
  justify-content: end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SaveButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 8px;
`;

export const GridSection = styled.section`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 10px;

  @media (max-width: 1600px) {
    && {
      .ant-form-item-control {
        min-width: 100%;
      }

      .ant-form-item-label {
        min-width: fit-content;
      }
    }
  }

  && {
    .ant-form-item-hidden {
      display: none !important;
    }
    .ant-form-item {
      display: grid;
    }
    .ant-form-item-control {
      width: 100%;
      max-width: 100% !important;
    }
    .ant-picker {
      width: 100%;
    }
    .ant-form-item-label {
      max-width: 100% !important;
    }
  }
`;

export const SaleCreatorSettingsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  margin: 20px;
  z-index: 9999;
`;

export const PDFPreviewTrigger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 20px;
  z-index: 999;
`;

export const FooterGrid = styled.div`
  display: grid;
  grid-template-columns:
    clamp(110px, 20%, 230px) clamp(110px, 20%, 230px) clamp(110px, 20%, 230px)
    clamp(110px, 20%, 230px);
  column-gap: clamp(5px, 8%, 120px);
  font-size: 11px;

  > * {
    word-break: break-word;
  }
`;
