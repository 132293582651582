/*
data : {
  ecosystemId: {
  companyRole: 'idOfCompanyRole',
    contacts: {
      view-contacts: true/false,
      create-contacts: true/false,
      ...
    },
    documents: {
      view-documents: true/false,
      create-documents: true/false
    }
  }
}
 */

import {
  PermissionActionTypes,
  PermissionsAction,
} from '@core/store/permissions/actions';

export type PermissionState = {
  isLoading: boolean;
  data: Record<string, Record<string, Record<string, string>>>;
};

export const initial: PermissionState = {
  data: {},
  isLoading: false,
};

export const permissionsReducer = (
  state = initial,
  action: PermissionsAction,
) => {
  switch (action.type) {
    case PermissionActionTypes.INIT_PERMISSION: {
      const { ecosystemId, permissions } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [ecosystemId]: permissions,
        },
      };
    }
    case PermissionActionTypes.UPDATE_PERMISSION: {
      const { ecosystemId, permissions } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          [ecosystemId]: permissions,
        },
        isLoading: false,
      };
    }
    case PermissionActionTypes.DELETE_PERMISSION: {
      const { ecosystemId, appId } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [ecosystemId]: {
            [appId]: undefined,
          },
        },
      };
    }
    default:
      return state;
  }
};
