import React, { FC, useState, useEffect } from 'react';
import { IntlShape } from 'react-intl';
import { Form, Select } from 'antd';
import { getActiveConsultantLevels } from '@apps/directSales/services/consultantLevel';

const { Item } = Form;

type Props = {
  intl: IntlShape;
  ecosystemId?: string;
};

type Option = {
  value: string;
  label: string;
};

const RelatedLevelSelect: FC<Props> = ({ intl, ecosystemId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (ecosystemId) {
      setIsLoading(true);
      getActiveConsultantLevels(ecosystemId)
        ?.then((levels) => {
          setOptions(
            levels.map((level) => ({
              value: level.id as string,
              label: level.level,
            })),
          );
        })
        ?.finally(() => {
          setIsLoading(false);
        });
    }
  }, [ecosystemId]);

  return (
    <Item
      label={intl.formatMessage({
        id: 'commissions.related.level',
        defaultMessage: 'Related level',
      })}
      name="relatedLevel"
      required
      rules={[{ required: true }]}
    >
      <Select
        loading={isLoading}
        placeholder={intl.formatMessage({
          id: 'commissions.related.level',
          defaultMessage: 'Related level',
        })}
        options={options}
      />
    </Item>
  );
};

export default RelatedLevelSelect;
