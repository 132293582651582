import React from 'react';
import { Form, Select } from 'antd';
import { useCurrencies } from '@pages/settings/components/useCurrencies';
import intl from '../../../../../../../../intl';
const { Item } = Form;

type CurrencyProps = {
  disabled?: boolean;
};

const Currency: React.FC<CurrencyProps> = ({ disabled = true }) => {
  const [areCurrenciesLoading, currencies] = useCurrencies();
  return (
    <Item
      name="currency"
      label={intl.formatMessage({
        id: 'documents.processinginvoice.currency.label',
        defaultMessage: 'Currency',
      })}
    >
      <Select
        disabled={disabled}
        style={{
          minWidth: '100px',
        }}
        loading={areCurrenciesLoading}
        showSearch
        options={currencies.map((el) => ({
          label: el,
          value: el,
        }))}
      />
    </Item>
  );
};

export default Currency;
