import React, { FC, useState, useMemo } from 'react';
import MediaViewer from '@components/MediaViewer';
import { StackedViewSider } from '@components/AppView/AppView.sc';
import { usePDF, PDFViewer } from '@react-pdf/renderer';
import PdfTemplate from '@apps/sales/mainMenu/components/pdfTemplates/PdfTemplate';
import { useSelector } from 'react-redux';
import { getActiveEcosystems } from '@components/EcosystemIndicator/store';
import { useCustomEventListener } from '@core/services';
import { svgString2Image } from '@apps/sales/mainMenu/components/utils';

const DocPreview: FC = () => {
  const activeEcosystems = useSelector(getActiveEcosystems);
  const [collapsed, setCollapsed] = useState(false);
  const [ecosystem, setEcosystem] = useState<Ecosystem | undefined>(undefined);
  const [avatarBase64, setAvatarBase64] = useState<string | null>(null);

  useCustomEventListener('changeEcosystem', (id) => {
    if (id) {
      const eco = activeEcosystems.find((eco) => eco.id === id);
      setEcosystem(eco);
      if (eco) {
        fetch(eco.avatarUrl as string)
          .then((r: any) => r.text())
          .then((text: any) => {
            svgString2Image(text, 24, 24, 'png', (pngData: string) => {
              setAvatarBase64(pngData);
            });
          });
      }
    }
  });

  const document = useMemo(
    () => <PdfTemplate ecosystem={ecosystem} avatarBase64={avatarBase64} />,
    [avatarBase64, ecosystem],
  );

  const [instance] = usePDF({
    document,
  });

  return (
    <StackedViewSider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      width={'calc(100vw - 60%)'}
    >
      <MediaViewer
        items={[
          {
            url: instance.url || '',
            thumbnailUrl: 'placeholder',
            format: 'pdf',
          },
        ]}
        width={'100vw'}
        height={'100vh'}
        showPlayButton={false}
        showNav={false}
        thumbnailPosition="left"
        lazyLoad={true}
      />
      <PDFViewer
        style={{ position: 'absolute', zIndex: '999', top: 0, left: 0 }}
        showToolbar={true}
      >
        <PdfTemplate ecosystem={ecosystem} avatarBase64={avatarBase64} />
      </PDFViewer>
    </StackedViewSider>
  );
};

export default DocPreview;
