import { RootStore } from '@core/store';
import { StripeProductsState } from '@core/store/stripeProducts/reducers';
import { StripeProduct } from '@services/stripe/namespace';

export const getStripeProducts = (state: RootStore): StripeProductsState => {
  return state.stripeProducts;
};

export const getStripeProductByTodixId =
  (todixId: string) =>
  (state: RootStore): StripeProduct | undefined =>
    state.stripeProducts.find(
      (product) => product?.metadata?.todixId === todixId,
    );
