import { connect } from 'react-redux';
import { compose } from 'redux';

import { RootStore } from '@core/store';

import {
  getLoading,
  getStripeProductsAsApps,
} from '@core/layout/store/selectors';
import { LeftMenu } from '@core/layout/components/LeftMenu/LeftMenu';
import { getEcosystems } from '@components/EcosystemIndicator/store';
import { getLicences } from '@core/store/licences';

const mapState = (state: RootStore) => ({
  isLoading: getLoading(state),
  applications: getStripeProductsAsApps(state),
  ecosystems: getEcosystems(state),
  licences: getLicences(state),
});

const mapDispatch = {};

export type StateProps = ReturnType<typeof mapState>;

export type DispatchProps = typeof mapDispatch;

export default compose(connect(mapState, mapDispatch))(LeftMenu);
