import { createContext } from 'react';
import { Product } from '@apps/products/services';
import { TaxCategory } from '@apps/finance/services/taxCategories';

type Context = {
  ecosystem: Ecosystem | null;
  productId?: string;
  product?: Partial<Product>;
  readOnly?: boolean;
  taxRates: TaxCategory[];
};

const PurchasesTabContext = createContext<Context>({
  ecosystem: null,
  taxRates: [],
});

export default PurchasesTabContext;
