import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Input } from 'antd';
import { useIntl } from 'react-intl';
import { FilterDocumentNameObject } from '@services/filtering';
import { FilterWrapper } from '@components/List/CustomsFilters/styles';

type Props = {
  filterChangedCallback: any;
};

export default forwardRef((props: Props, ref) => {
  const [input, setInput] = useState('');
  const intl = useIntl();

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return !!input;
      },

      getModel() {
        return input
          ? ({
              filter: input,
              filterType: 'documentName',
              type: 'contains',
            } as FilterDocumentNameObject)
          : null;
      },

      getModelAsString() {
        return '';
      },

      setModel(model: any) {
        setInput(model?.input || '');
      },
    };
  });

  const onValueChange = (event: any) => {
    setInput(event.target.value);
    props.filterChangedCallback({
      input,
    });
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [input]);

  return (
    <FilterWrapper>
      <Input
        id="documentNameFilter"
        onChange={onValueChange}
        placeholder={intl.formatMessage({
          id: 'placeholder.documentname',
          defaultMessage: 'Type document name...',
        })}
      />
    </FilterWrapper>
  );
});
