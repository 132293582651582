import React, { FC, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Spin, Select } from 'antd';
import Modal from '@components/Modal';
import { Button } from '@todix/ui-components';
import { SKRSetupContent } from '@apps/finance/styles';
import saveSKR from '@apps/finance/services/skr/saveSKR';
import { UseParamsType } from '@pages/settings';
import intl from '../../../../../intl';

const SKRSetup: FC = () => {
  const { uid } = useParams<UseParamsType>();
  const [value, setValue] = useState<string | undefined>(undefined);
  const onChange = (newValue: string) => {
    setValue(newValue);
  };
  const [spinning, setSpinning] = useState(false);
  const saveSkrInEcosystem = useCallback(() => {
    if (value) {
      Modal.confirm({
        content: (
          <div>
            {intl.formatMessage(
              {
                id: 'settings.finance.skr.confirm',
                defaultMessage: 'settings.finance.skr.confirm',
              },
              {
                skr: value,
              },
            )}
            {intl.formatMessage({
              id: 'settings.finance.skr.confirm.end',
              defaultMessage: 'settings.finance.skr.confirm.end',
            })}
          </div>
        ),
        onOk: () => {
          if (value) {
            setSpinning(true);
            saveSKR({
              skrCode: `${value}`,
              ecosystemId: uid,
            }).finally(() => {
              setSpinning(false);
            });
          }
        },
      });
    }
  }, [uid, value]);
  return (
    <Spin spinning={spinning} style={{ height: '100%' }}>
      <SKRSetupContent>
        <FormattedMessage
          id="settings.finance.first.time"
          defaultMessage="settings.finance.first.time"
        />
        <Select
          placeholder={
            <FormattedMessage
              id="settings.finance.skr.select"
              defaultMessage="settings.finance.skr.select"
            />
          }
          loading={spinning}
          disabled={spinning}
          onChange={onChange}
          options={[
            {
              value: 'skr03',
              label: 'SKR03',
            },
            {
              value: 'skr04',
              label: 'SKR04',
            },
          ]}
        />
        <div>
          <Button
            type="primary"
            disabled={spinning || !value}
            onClick={saveSkrInEcosystem}
          >
            <FormattedMessage id="button.save" defaultMessage="Save" />
          </Button>
        </div>
      </SKRSetupContent>
    </Spin>
  );
};

export default SKRSetup;
