import React, { FC, useState, useEffect } from 'react';
import { Avatar, Spin } from 'antd';
import { AvatarWrapper, UserName } from './UserAvatar.sc';
import { fetchUser } from '@services/users';

type UserAvatarProps = {
  user: CommonUserData | string;
};

const UserAvatar: FC<UserAvatarProps> = ({ user }) => {
  const [avatarData, setAvatarData] = useState<CommonUserData | null>(
    typeof user === 'string' ? null : user,
  );
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (typeof user === 'string') {
      setFetching(true);
      fetchUser(user)
        .then(setAvatarData)
        .finally(() => {
          setFetching(false);
        });
    }
  }, [user]);

  return (
    <AvatarWrapper>
      <Spin spinning={fetching}>
        <Avatar src={avatarData?.photoURL} />
        <UserName level={2}>{avatarData?.displayName}</UserName>
      </Spin>
    </AvatarWrapper>
  );
};

export default UserAvatar;
