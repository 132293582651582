import { styled } from '@styles/themes';
import { Tree, Select } from 'antd';

export const RelationsContainer = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.4fr 0.4fr;
  grid-column-gap: 20px;
  grid-template-rows: 65vh;
`;

export const RelationsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
`;

export const RelationsTree = styled(Tree)`
  .ant-tree-switcher-noop {
    visibility: hidden;
  }
`;

export const FlexSelect = styled(Select)`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TaskTitleContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  > span[role='img'] {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  :hover {
    > span[role='img'] {
      color: #cf1322;
      opacity: 1;
    }
  }
`;
