import React, { FC } from 'react';
import { Empty } from 'antd';
import { FormattedMessage } from 'react-intl';

const EmptyResult: FC = () => (
  <Empty
    description={<FormattedMessage id="no.results" description="No results" />}
  />
);

export default EmptyResult;
