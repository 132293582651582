import React, { FC } from 'react';
import { Form } from 'antd';

import { Select } from '@components/FormFields';
import { useFormContext } from '@components/FormWithReminder/useFormContext';
import { FormattedMessage } from 'react-intl';

export const initialTags = {
  tags: [] as string[],
};

const { Option } = Select;
const { Item } = Form;

const children: React.ReactNodeArray = [];
for (let i = 0; i < 5; i++) {
  children.push(
    <Option value={`Tag ${i}`} key={`Tag${i}`} label={`Tag ${i}`}>
      {`Tag ${i}`}
    </Option>,
  );
}

const SelectedTags: FC = () => {
  const { readOnly } = useFormContext();
  return (
    <Item
      name="tags"
      label={
        <FormattedMessage
          id="products.selectedtags.label"
          defaultMessage="Tags"
        />
      }
    >
      <Select
        disabled={readOnly}
        mode="multiple"
        allowClear
        optionLabelProp="label"
        name="tags"
        placeholder={
          <FormattedMessage
            id="products.selectedtags.placeholder"
            defaultMessage="Select tags..."
          />
        }
      >
        {children}
      </Select>
    </Item>
  );
};

export default SelectedTags;
