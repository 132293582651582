import React from 'react';
import { AgGridColumn } from 'ag-grid-react';
import { IntlShape } from 'react-intl';
import { ColSpanParams } from 'ag-grid-community/dist/lib/entities/colDef';

const columns = (intl: IntlShape) => (
  <>
    <AgGridColumn
      field="productName"
      filterParams={{
        filterOptions: ['contains'],
        defaultOption: 'contains',
      }}
      headerName={intl.formatMessage({
        id: 'products.columns.productname.header',
        defaultMessage: 'Product Name',
      })}
      sortable
      minWidth={160}
    />
    <AgGridColumn
      field="ecosystem"
      minWidth={160}
      headerName={intl.formatMessage({
        id: 'tasks.columns.ecosystem.header',
        defaultMessage: 'Ecosystem',
      })}
      cellRenderer="ecosystemRenderer"
      colSpan={({ data }: ColSpanParams) => {
        if (data) {
          return 1;
        }
        return 99;
      }}
      filter="ecosystemFilter"
      lockPosition="left"
    />
    <AgGridColumn
      field="creator"
      headerName={intl.formatMessage({
        id: 'products.form.creator.label',
        defaultMessage: 'Creator',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
    />
    <AgGridColumn
      field="needDate"
      headerName={intl.formatMessage({
        id: 'need.date',
        defaultMessage: 'Need Date',
      })}
      cellRenderer="dateRenderer"
      minWidth={160}
    />
    <AgGridColumn
      field="needAmount"
      headerName={intl.formatMessage({
        id: 'need.amount',
        defaultMessage: 'Need amount',
      })}
    />
    <AgGridColumn
      field="description"
      headerName={intl.formatMessage({
        id: 'products.form.description.label',
        defaultMessage: 'Description',
      })}
    />
    <AgGridColumn
      field="takeBy"
      headerName={intl.formatMessage({
        id: 'take.by',
        defaultMessage: 'Take by',
      })}
      cellRenderer="userRenderer"
      minWidth={140}
      filter={false}
    />
    <AgGridColumn
      cellRenderer="actionRenderer"
      maxWidth={70}
      filter={false}
      lockPosition="right"
    />
  </>
);

export default columns;
